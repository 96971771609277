import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteActivity } from "../../../redux/users/userActions";
import { IoPencil } from "react-icons/io5";
import { IoMdTrash } from "react-icons/io";
import OnboardingActivity from "./OnboardingActivity";
import { AiFillEdit } from "react-icons/ai";

const Activity = ({ activity, template, refetchTem }) => {
  const dispatch = useDispatch();
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promptdel, setPromptDel] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const activityHandler = (e, activity) => {
    setShowActivityModal(!showActivityModal);
  };

  const showHandler = () => {
    setShowActions(!showActions);
  };

  const handleDelete = (e, activity) => {
    setLoading(true);
    Modal.destroyAll();
    const creds = {
      type: "onboard",
      id: activity.id,
    };
    deleteActivity(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") refetchTem();
    });
  };

  function deleteActivityPrompt(e, activity) {
    // alert("here");
    return Modal.confirm({
      title: "Delete Activity",
      content: "Do you want to delete this Activity?",
      onOk: (e) => handleDelete(e, activity),
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => setPromptDel(false),
      open: promptdel,
      closable: true,
    });
  }

  return (
    <li key={activity.id} className="col-md-12 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <p className="h6">{activity.activity}</p>
        <div className="d-flex template-activity">
          <Button
            type="link"
            size="small"
            onClick={(e) => activityHandler(e, activity)}
            title="Edit Activity"
          >
            <AiFillEdit style={{ fontSize: 18, color: "var(--clr-primary)" }} />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={(e) => deleteActivityPrompt(e, activity)}
            loading={loading}
            title="Delete Activity"
          >
            <IoMdTrash style={{ fontSize: 18, color: "var(--clr-red)" }} />
          </Button>
        </div>
      </div>

      <Modal
        open={showActivityModal}
        onCancel={activityHandler}
        footer={null}
        centered
        width={600}
      >
        <h3>Edit Activity</h3>
        <OnboardingActivity
          template={template}
          refetchTem={refetchTem}
          activity={activity}
          closeModal={setShowActivityModal}
          edit
        />
      </Modal>
    </li>
  );
};

export default Activity;
