import { useState } from "react";
import {
  Switch,
  Input,
  InputNumber,
  Form,
  Radio,
  Checkbox,
  Select,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { TextArea } = Input;

const ExternalSurveyReadOnly = ({ survey }) => {
  const [isAnonymous, setIsAnonymous] = useState(false);



  const sharedInputProps = (props) => {
    return {
      style: {
        maxWidth: "100%",
        border: "1px solid black",
        borderRadius: props === "short" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
      }}
    >
      <div
        className=""
        style={{
          width: "100%",
          borderTop: "5rem solid var(--clr-primary)",
          borderLeft: "4rem solid transparent",
          borderRight: "4rem solid transparent",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{
            borderBottom: "0.2rem solid black",
            padding: "0.5rem 0",
            height: "7rem",
          }}
        >
          <h3 className="fw-semibold">{survey?.surveyName}</h3>
          <img
            src={"/assets/companyblue.jpg"}
            alt="Organization Logo"
            width={200}
            height={100}
          />
        </div>

        <Form name="validate_other" className="d-flex flex-column gap-3 pb-5">
          {survey?.canBeAnonymous && (
            <div
              className=""
              style={{
                background: "rgba(217, 217, 217, 0.23)",
                padding: "1rem",
                margin: "1rem 0",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <h5 className="fw-semibold">Make Your Survey Anonymous</h5>
              <p className="">
                This will hide Email Address, IP Address, Link parameters and
                any other piece of information that might allow us identify a
                respondent
              </p>
              <div className="d-flex align-items-end gap-2">
                <Switch
                  defaultChecked={isAnonymous}
                  onChange={(checked) => {
                    setIsAnonymous(checked);
                  }}
                />
                <span>Anonymous Survey</span>
              </div>
            </div>
          )}

          {!isAnonymous && (
            <div className="mt-5">
              <h4>Personal Information:</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Form.Item
                  name="personalInfo.name"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please type your name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Name"
                    size="large"
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                    disabled
                  />
                </Form.Item>
                <div className="email__phone__external__survey">
                  <Form.Item
                    name="personalInfo.email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please type your email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      size="large"
                      type="email"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                      }}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="personalInfo.phone"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please type your phone number",
                        type: "number",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Phone Number"
                      size="large"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                      }}
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}

          <div
            className="mt-5 d-flex flex-column gap-3 "
            style={{
              borderBottom: "0.1rem solid black",
            }}
          >
            {survey?.respondentInfo.map((question, index) => (
              <div key={index}>
                <div className="d-flex flex-column gap-1">
                  <h6 className="">
                    {question.questionTitle}
                    {question.required && (
                      <span className="text-danger">*</span>
                    )}
                  </h6>
                  {question?.isQuestionDescription && (
                    <small>{question.questionDescription}</small>
                  )}
                </div>

                <Form.Item
                  name={`${question.title}${question.id}3`}
                  hasFeedback
                  rules={[
                    {
                      required: question?.required,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Radio.Group className="d-flex gap-3" disabled>
                    {question?.questionOptions.map((option, index) => (
                      <Radio key={index} value={option} className=" fs-6">
                        {option}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            ))}
          </div>

          <div className="mt-5 d-flex flex-column gap-3 ">
            <h4>Survey Questions:</h4>
            {survey?.questionsItems.map((question, index) => (
              <div key={index}>
                <div className="d-flex flex-column gap-1">
                  <h6 className="">
                    {question.questionTitle}
                    {question.required && (
                      <span className="text-danger">*</span>
                    )}
                  </h6>
                  {question?.isQuestionDescription && (
                    <small>{question.questionDescription}</small>
                  )}
                </div>

                <div>
                  {question?.questionType === "radio" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Radio.Group className="d-flex gap-3" disabled>
                        {question?.questionOptions.map((option, index) => (
                          <Radio key={index} value={option} className=" fs-6">
                            {option}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  ) : question?.questionType === "checkbox" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Checkbox.Group className="d-flex gap-3" disabled>
                        {question?.questionOptions.map((option, index) => (
                          <Checkbox
                            key={index}
                            value={option}
                            className=" fs-6"
                          >
                            {option}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  ) : question?.questionType === "paragraph" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please input your answer",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder={"Paragraph Answer Here"}
                        disabled
                        {...sharedInputProps("paragraph")}
                      />
                    </Form.Item>
                  ) : question?.questionType === "short" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please input your answer",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"Short Answer Here"}
                        disabled
                        {...sharedInputProps("short")}
                      />
                    </Form.Item>
                  ) : question?.questionType === "dropdown" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        size="large"
                        placeholder="Select an option"
                        optionFilterProp="children"
                        disabled
                        {...sharedInputProps("short")}
                        options={
                          question?.questionOptions &&
                          question?.questionOptions.length > 0
                            ? question?.questionOptions.map(
                                (option, index) => ({
                                  label: option,
                                  value: option,
                                })
                              )
                            : []
                        }
                      />
                    </Form.Item>
                  ) : question?.questionType === "file" ? (
                    <Form.Item
                      name="dragger"
                      valuePropName="fileList"
                      //   getValueFromEvent={normFile}
                      rules={[
                        {
                          required: question?.required,
                          message: "Please upload a file",
                        },
                      ]}
                    >
                      <Dragger disabled >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload. Strictly
                          prohibited from uploading company data or other banned
                          files.
                        </p>
                      </Dragger>
                    </Form.Item>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ExternalSurveyReadOnly;
