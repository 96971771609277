import styles from "../../styles/ModuleCard.module.css";
import { Tooltip } from "antd";
import { BsFillInfoCircleFill } from "react-icons/bs";

function ModuleCard({ image, name, url, description }) {
  return (
    <div className={`${styles.module} bg-light`}>
      <Tooltip title={description}>
        <BsFillInfoCircleFill
          style={{
            color: "var(--clr-primary)",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          size={20}
        />
      </Tooltip>

      <div className={styles.moduleImage}>
        <img src={image} alt={name} />
      </div>
      <div className={styles.moduleInfo}>
        <h4 className={styles.heading}>{name}</h4>
      </div>
    </div>
  );
}

export default ModuleCard;
