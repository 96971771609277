import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Drawer, Popconfirm } from "antd";
import CreateTemplate from "./CreateTemplate";
import TemTable from "./TemTable";
import { useDispatch } from "react-redux";
import { deleteDeductionTemplate } from "../../../redux/users/userActions";

function TemplateTable({ refetch, table_data }) {
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  console.log({ data });
  const is_open = useRef(false);

  // const params = queryString.parse(useLocation().search);

  function handleSelected(record) {
    setSelected(record);
    if (!is_open.current) {
      is_open.current = true;
    }
  }

  function closeDrawer() {
    setSelected(null);
    if (is_open.current) {
      is_open.current = false;
    }
  }

  useEffect(() => {
    setData(table_data);
  }, [table_data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "tem_name",
      key: "tem_name",
    },
    {
      title: "Created date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Updated date",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Min Taxable",
      dataIndex: "band_0",
      key: "band_0",
    },
    {
      title: "Min Rate (%)",
      dataIndex: "band_0_per",
      key: "band_0_per",
    },
    {
      title: "Employee Pension (%)",
      dataIndex: "pen_employee",
      key: "pen_employee",
    },
    {
      title: "Employer Pension (%)",
      dataIndex: "pen_employer",
      key: "pen_employer",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <EachAction
          record={record}
          handleSelected={handleSelected}
          refetch={refetch}
        />
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} />

      {selected && is_open ? (
        <Drawer
          title={selected.name}
          onClose={closeDrawer}
          open={is_open}
          destroyOnClose={true}
          extra={
            <Space>
              <Button danger size="large" onClick={closeDrawer}>
                Close
              </Button>
            </Space>
          }
          width={"90%"}
        >
          <div>
            <CreateTemplate
              update={true}
              selected={selected}
              refetch={refetch}
            />
            <hr />
            <TemTable
              handleSelected={handleSelected}
              dataSource={data}
              refetch={refetch}
            />
          </div>
        </Drawer>
      ) : null}
    </>
  );
}

function EachAction({ record, handleSelected, refetch }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const confirm = () => {
    console.log({ record });

    setLoading(true);

    deleteDeductionTemplate(dispatch, { id: record.id }).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  };

  return (
    <Space size="middle">
      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-success`}
        view
        onClick={() => handleSelected(record)}
      >
        View
      </Button>

      <Popconfirm
        title="Delete the Deduction template"
        description="Are you sure you want to perform this action?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
          loading={loading}
        >
          Delete
        </Button>
      </Popconfirm>
    </Space>
  );
}

export default TemplateTable;
