import React, { useEffect, useState } from "react";
import HocHeader from "../ui/HocHeader";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { Button, Modal } from "antd";
import { useShallowEqualSelector, useUserCleanUp } from "../../util/hooks";
import { useGetCompensationsSettings } from "../../util/usershookActions";
import { com_settings } from "../../util/selectors/userSelectors";
import SkeletonUI from "../ui/Skeleton";
import { updateComSettings } from "../../redux/users/userActions";
import { useDispatch } from "react-redux";

function CompesationSettings() {
  const dispatch = useDispatch();
  const is_mobible = useMediaQuery({ query: "(max-width: 768px)" });
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [high_band, setHighBand] = useState("");
  const [middle_band, setMiddleBand] = useState("");
  const [low_band, setLowBand] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoading, refetch } = useGetCompensationsSettings(
    enabled,
    setEnabled
  );
  const comSettings = useShallowEqualSelector(com_settings);

  function handleSubmit(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Update Settings",
      content: "Do you want to perform this action?",
      onOk: updateSettingFunc,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function updateSettingFunc() {
    const creds = {
      high_band,
      low_band,
      middle_band,
    };
    setLoading(true);
    updateComSettings(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        //   refetch();
      }
    });
  }

  useEffect(() => {
    console.log({ comSettings });

    if (comSettings?.length) {
      comSettings.forEach((item) => {
        if (item.name === "high_band") {
          setHighBand(item.value);
        } else if (item.name === "middle_band") {
          setMiddleBand(item.value);
        } else if (item.name === "low_band") {
          setLowBand(item.value);
        }
      });
    }
  }, [comSettings]);

  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Settings"
          module_title="Compensation & Benefits"
          module_description=" Manage all benefit and benefit related categories. You can create, rename, remove benefits on the human resource management system."
          module_img="/assets/benefits.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4">
            <div className="d-flex">
              <h4>General</h4>
            </div>

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : (
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="row mt-3 align-items-center">
                  <div className="col-md-6">
                    <h6>High band</h6>
                    <small>
                      This helps to define the maximum amount for staffs in this
                      category.
                    </small>
                  </div>
                  <div className="col-md-4 offset-md-2  ">
                    <div className="d-flex justify-content-end">
                      <input
                        placeholder="2,000,000"
                        value={high_band}
                        className={classNames("w-50", { "w-100": is_mobible })}
                        onChange={(e) => setHighBand(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-md-6">
                    <h6>Middle band</h6>
                    <small>
                      This helps to define the maximum amount for staffs in this
                      category.
                    </small>
                  </div>
                  <div className="col-md-4 offset-md-2  ">
                    <div className="d-flex justify-content-end">
                      <input
                        placeholder="2,000,000"
                        className={classNames("w-50", { "w-100": is_mobible })}
                        value={middle_band}
                        onChange={(e) => setMiddleBand(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-md-6">
                    <h6>Low band</h6>
                    <small>
                      This helps to define the maximum amount for staffs in this
                      category.
                    </small>
                  </div>
                  <div className="col-md-4 offset-md-2  ">
                    <div className="d-flex justify-content-end">
                      <input
                        placeholder="2,000,000"
                        className={classNames("w-50", { "w-100": is_mobible })}
                        value={low_band}
                        onChange={(e) => setLowBand(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row  align-items-end mt-4">
                  <div className="d-flex justify-content-end">
                    <Button
                      className="myBtn"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </section>
        </div>
      </main>
    </>
  );
}

export default CompesationSettings;
