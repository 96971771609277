import React from "react";
import { MdMessage } from "react-icons/md";

const InductionSupervisor = ({ image, name, role }) => {
  return (
    <li className="supervisor">
      <div className="image">
        <img src={image} alt={name} />
      </div>
      <div className="name">
        <h6 className="mb-0">{name}</h6>
        <small>{role}</small>
      </div>
      <div className="cta">
        <label>Send a message</label>
        <MdMessage />
      </div>
    </li>
  );
};

export default InductionSupervisor;
