import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { useGetSystemSettings } from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { settings } from "../../../util/selectors/userSelectors";
import { Switch } from "antd";
import { setSettings } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";

const loop = [
  { name: "Mail", key: "mail" },
  // { name: "Text", key: "text" },
];

function Notifications() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled_settings, setEnabledSettings] = useState(true);

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState([]);

  const { refetch } = useGetSystemSettings(
    enabled_settings,
    setEnabledSettings
  );
  const all_settings = useShallowEqualSelector(settings);

  const onChange = (check, e, type) => {
    if (check) {
      const new_arry = [...current];
      if (!new_arry.includes(type)) {
        setCurrent([...new_arry, type]);
        const to_update = [...new_arry, type];
        setCurrent(to_update);
        handleupdate(to_update);
      }
    } else {
      const to_update = [...current].filter((item) => item !== type);
      setCurrent(to_update);
      handleupdate(to_update);
    }
  };

  function handleupdate(to_update) {
    setLoading(true);
    const creds = {
      type: "delivery_channel",
      value: JSON.stringify(to_update),
    };
    setSettings(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
      // if (res?.status === "success") {
      // }
    });
  }

  useEffect(() => {
    if (Array.isArray(all_settings?.other_settings?.delivery_channel?.value)) {
      setCurrent(all_settings?.other_settings?.delivery_channel?.value);
    }
  }, [all_settings]);

  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Notifications</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <div className="d-flex justify-content-between">
                <h4>Delivery Channel</h4>
                {loading ? <LoadingOutlined /> : null}
              </div>
              <small>
                Select the channel for delivering notifications on the
                application aside in-app
              </small>
            </div>
            <div className="col-md-3 d-flex flex-column align-items-end align-self-center">
              {loop?.map((item) => (
                <div
                  key={item.key}
                  className="d-flex gap-2 align-items-center justify-content-between mb-3 w-100"
                >
                  <p className="fonnt">{item.name}</p>
                  <div className="d-flex justify-content-end">
                    <Switch
                      checked={current.includes(item.key)}
                      onChange={(check, e) => onChange(check, e, item.key)}
                      disabled={item.key === "mail"}
                      className="w-50"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
