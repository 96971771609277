import { useState, useEffect } from "react";
import { Modal, Input, Table, Popconfirm, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  addPerformanceMeasurementUnit,
  deletePerformanceMeasurementUnit,
} from "../../../redux/users/userActions";
import { useGetPerformanceMeasurementUnits } from "../../../util/usershookActions";

const UnitOfMeasure = ({ isModalOpen, handleViewModal }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [addUnitLoading, setAddUnitLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [units, setUnits] = useState([]);

  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useGetPerformanceMeasurementUnits(
    enabled,
    setEnabled
  );

  useEffect(() => {
    if (data) {
      const new_array = data?.payload?.map((unit, index) => {
        return {
          key: unit.id,
          unitName: unit.name,
          unit,
        };
      });
      setUnits(new_array);
    }
  }, [data]);

  const columns = [
    {
      title: "Unit of Measure Category",
      dataIndex: "unitName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, records) => <EachAction item={records} refetch={refetch} />,
    },
  ];

  const handleAddMeasurementUnit = (e) => {
    e.preventDefault();
    setAddUnitLoading(true);
    const creds = {
      name,
      description,
    };
    addPerformanceMeasurementUnit(dispatch, creds).then(() => {
      setAddUnitLoading(false);
      setName("");
      setDescription("");
      refetch();
    });
  };

  return (
    <Modal
      title="Units of Measure Categories"
      centered
      open={isModalOpen}
      onOk={handleViewModal}
      onCancel={handleViewModal}
      footer={null}
    >
      <div className="unit__measure__wrapper">
        <form
          className="unit__measure__form"
          onSubmit={handleAddMeasurementUnit}
        >
          <div className="unit__measure__form__group">
            <label>Units</label>
            <Input
              placeholder="Unit name"
              size="large"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="unit__measure__form__group">
            <label>Description</label>
            <Input
              placeholder="Description"
              size="large"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>

          <Button
            className="d-flex justify-content-center text-white "
            type="primary"
            size="large"
            htmlType="submit"
            loading={addUnitLoading}
          >
            Add
          </Button>
        </form>

        <div className="unit__measure__table performance_table">
          <Table columns={columns} dataSource={units} pagination={false} />
        </div>
      </div>
    </Modal>
  );
};

function EachAction({ item, refetch }) {
  console.log("item", item.unit.id);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteSection = (e) => {
    e.preventDefault();
    setDeleteLoading(true);
    deletePerformanceMeasurementUnit(dispatch, item.unit.id).then(() => {
      setDeleteLoading(false);
      refetch();
    });
  };
  return (
    <>
      <div className="filter">
        <Popconfirm
          title="Delete unit"
          description="Do you want to perform this action?"
          onConfirm={handleDeleteSection}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="d-flex justify-content-center bg-danger text-white "
            shape="round"
            type="text"
            size="medium"
            loading={deleteLoading}
          >
            Delete
          </Button>
        </Popconfirm>
      </div>
    </>
  );
}
export default UnitOfMeasure;
