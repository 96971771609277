import React, { useEffect, useState, useMemo } from "react";
import { Table, Pagination } from "antd";
import { useUserCleanUp } from "../../../util/hooks";
import { useGetMyReportHis } from "../../../util/usershookActions";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import dayjs from "dayjs";

function RequestHistory() {
  useUserCleanUp();

  const columns = [
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (value) => {
        return value ? dayjs(value).format("YYYY-MM-DD") : "N/A";
      },
    },
    {
      title: "Reason for Request",
      dataIndex: "reason_for_request",
      key: "reason_for_request",
      render: (value) => {
        return value ? value : "N/A";
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approval date",
      key: "approval date",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Date of Collection",
      dataIndex: "date_of_collection",
      key: "date_of_collection",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
  ];

  const [enabled_req, setEnabledReq] = useState(true);
  const [page_req, setPageReq] = useState(1);
  const [req_show, setReqShow] = useState([]);

  const {
    data: req,
    isLoading,
    refetch,
  } = useGetMyReportHis(enabled_req, setEnabledReq, page_req);

  const req_data = useMemo(() => req?.payload?.data, [req]);
  console.log({ req_show });

  useEffect(() => {
    if (req_data?.length) {
      const new_arry = req_data.map((re, ind) => {
        return {
          ...re,
          key: re.user_id,
          index: ind + 1,
        };
      });

      setReqShow(new_arry);
    } else {
      setReqShow([]);
    }
  }, [req_data]);

  function handlePaginationEmp(page) {
    setPageReq(page);
    setEnabledReq(true);
  }
  return (
    <main className="leaveMain">
      <section className="mx-auto ">
        <div className="idContainer mx-auto py-5 ">
          <h2 className="newLeaveHead mb-5">Request History</h2>

          {isLoading ? (
            <SkeletonUI number={2} />
          ) : req_show.length ? (
            <>
              <Table
                columns={columns}
                dataSource={req_show}
                bordered
                scroll={{ x: "768px" }}
                pagination={false}
              />

              <div className="mt-3 d-flex justify-content-end">
                {req?.payload?.meta?.last_page > 1 ? (
                  <Pagination
                    total={req?.payload?.meta?.last_page}
                    pageSize={1}
                    onChange={handlePaginationEmp}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <NoContent width={"400px"} />
          )}
        </div>
      </section>
    </main>
  );
}

export default RequestHistory;
