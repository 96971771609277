import { useSelector } from "react-redux";
import { useShallowEqualSelector } from "../../../util/hooks";
import { selected_template_activities } from "../../../util/selectors/userSelectors";
import EachActivity from "./EachActivity";
import { Tabs } from "antd";

const ModifyActivities = ({ users, handleApply }) => {
  const activities = useSelector(selected_template_activities);

  // console.log({ activities });
  return (
    <div className="container">
      <div
        style={{
          maxHeight: 450,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Tabs
          tabPosition="left"
          items={activities.map((act, index) => {
            return {
              label: act.activity,
              key: act.id,
              children: (
                <EachActivity
                  activity={act}
                  users={users}
                  index={index}
                  handleApply={handleApply}
                />
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default ModifyActivities;
