import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

const MakeRequest = () => {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Request Management"
          module_class="performance-header"
          module_name={
            childPath === "request-history"
              ? "Request History"
              : "Make a Request"
          }
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default MakeRequest;