import React, { useEffect, useState } from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";
import BasicInfo from "./BasicInfo";
import { useDispatch } from "react-redux";
import data from "../../../../util/util.json";
import { useGetUserAutoID } from "../../../../util/usershookActions";
import { user_errors } from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { createUserPersonalInfo } from "../../../../redux/users/userActions";

const { employment } = data;

const personal_arry = [
  "nationality",
  "state_of_origin",
  "province_lga",
  "religion",
  "gender",
  "date_of_birth",
  "title",
  "first_name",
  "last_name",
  "other_names",
  "marital_status",
  "employee_id",
  "employment_type",
  "employment_date",
  "status",
  "department",
  "company_id",
  "branch_id",
  "secondary_line_manager",
  "primary_line_manager",
  "team_lead",
  "on_probation",
  "probation_end_date",
  // "job_role",
];

const contact_arry = [
  "mobile_number",
  "primary_email",
  "secondary_email",
  "home_address",
  "telephone",
];
const grading_arry = ["job_role_id", "step_id", "grade_id"];

export default function NewEmployee() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [auto_gen, setAutoGen] = useState({});
  const [personal, setPersonal] = useState({});
  const [contact, setContact] = useState({});
  const [grading, setGrading] = useState({});
  const [save_spinner, setSaveSpinner] = useState(false);

  const { data: auto_id, refetch } = useGetUserAutoID(enabled, setEnabled);
  const back_errors = useShallowEqualSelector(user_errors);

  useEffect(() => {
    if (auto_id?.payload) {
      setAutoGen(auto_id.payload);
    }
  }, [auto_id]);

  const init_details = {
    nationality: "",
    state_of_origin: "",
    province_lga: "",
    religion: "",
    gender: "",
    date_of_birth: "",
    title: "",
    first_name: "",
    last_name: "",
    other_names: "",
    marital_status: "",
    company_id: "",
    branch_id: "",
    employee_id: "",
    employment_type: "",
    employment_date: "",
    status: "",
    mobile_number: "",
    primary_email: "",
    secondary_email: "",
    home_address: "",
    telephone: "",
    department: "",
    job_role_id: "",
    step_id: "",
    grade_id: "",
    primary_line_manager: "",
    secondary_line_manager: "",
    team_lead: "",
    on_probation: "0",
    probation_end_date: "",
  };

  const callbackFn = () => {
    setSaveSpinner(true);
    personal.employee_id = `${auto_gen?.available_employee_number}`;

    if (personal.on_probation == "0") {
      personal.probation_end_date = "";
    }
    const creds = {
      personal: [{ on_probation: "0", ...personal }],
      contact: [{ ...contact }],
      grade_level: [{ ...grading }],
    };
    // clearForm();
    // console.log({ creds });
    createUserPersonalInfo(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        clearForm();
        refetch();
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  function firstOnchange(e, action, name, value) {
    if (action) {
      if (personal_arry.includes(name)) {
        setPersonal((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (contact_arry.includes(name)) {
        setContact((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (grading_arry.includes(name)) {
        setGrading((prevState) => {
          return { ...prevState, [name]: value };
        });
      }
    } else {
      if (personal_arry.includes(e.target.name)) {
        setPersonal((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (contact_arry.includes(e.target.name)) {
        setContact((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (grading_arry.includes(e.target.name)) {
        setGrading((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      }
    }

    handleChange(e, action, name, value);
  }
  return (
    <>
      <div className="container-fluid ">
        <div className="d-flex flex-column white pb-3">
          <div className={styles.basicInfo}>
            <BasicInfo
              values={values}
              errors={errors}
              handleChange={firstOnchange}
              handleSubmit={handleSubmit}
              clearForm={clearForm}
              back_errors={back_errors}
              save_spinner={save_spinner}
              auto_gen={auto_gen}
              employment={employment}
              onboarding={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
