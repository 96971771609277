import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Pagination, Space, Table, Tag, Popconfirm } from "antd";
import { useRef, useState, useEffect, useMemo } from "react";

import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { convertToCapitalize } from "../../../util/helpers";
import { Link, useNavigate } from "react-router-dom";

import { Dropdown } from "antd";
import { AiOutlineEllipsis, AiOutlineSearch } from "react-icons/ai";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import {
  activateDeactivateUser,
  toggleUserLock,
} from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

const EmployeeTable = ({ handleModal }) => {
  const [enabled_emp, setEnabledEmp] = useState(true);
  const [page_emp, setPageEmp] = useState(1);
  const [emp_show, setEmpShow] = useState([]);

  const {
    data: emp,
    isLoading,
    refetch,
  } = useGetAllEmployee(enabled_emp, setEnabledEmp, page_emp);
  const emp_data = useMemo(() => emp?.payload?.all_employees?.data, [emp]);

  useEffect(() => {
    if (emp_data?.length) {
      const new_arry = emp_data.map((em) => {
        return {
          ...em,
          key: em.employee_id,
          name: `${convertToCapitalize(em.fullname)} ${convertToCapitalize(
            em.SName
          )}`,
          gender: convertToCapitalize(em.gender) || "N/A",
          status: convertToCapitalize(em.status) || "N/A",
          department: em.department?.name || "N/A",
          roles: em.roles || "N/A",
          job_role: em?.job_role?.job_role?.role || "N/A",
        };
      });

      setEmpShow(new_arry);
    } else if (!emp_data?.length) {
      setEmpShow([]);
    }
  }, [emp_data]);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  const columns = handleModal
    ? [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",

          ...getColumnSearchProps("name"),
        },

        {
          title: "Job role",
          dataIndex: "job_role",
          key: "job_role",
          ...getColumnSearchProps("job_role"),
        },

        {
          title: "Department",
          dataIndex: "department",
          key: "department",
          ...getColumnSearchProps("department"),
        },
        {
          title: "Actions",
          fixed: "right",
          render: (_, record) => {
            return (
              <EachAction
                record={record}
                handleModal={handleModal}
                refetch={refetch}
                setEnabledEmp={setEnabledEmp}
              />
            );
          },
        },
      ]
    : [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",

          ...getColumnSearchProps("name"),
        },
        {
          title: "Gender",
          dataIndex: "gender",
          key: "gender",

          ...getColumnSearchProps("gender"),
        },
        {
          title: "Job role",
          dataIndex: "job_role",
          key: "job_role",
          ...getColumnSearchProps("job_role"),
        },
        {
          title: "On probation",
          dataIndex: "ongoing_probation",
          key: "ongoing_probation",
          render: (value) => {
            return value === "yes" ? "Yes" : "No";
          },
        },
        // {
        //   title: "On disciplinary",
        //   dataIndex: "ongoing_disciplinary_action",
        //   key: "ongoing_disciplinary_action",
        //   render: (value) => {
        //     return value === "yes" ? "Yes" : "No";
        //   },
        // },
        {
          title: "Nationality",
          dataIndex: "nationality",
          key: "nationality",
          render: (value) => {
            return value ? value : "N/A";
          },
        },
        {
          title: "Marital status",
          dataIndex: "marital_status",
          key: "marital_status",
          render: (value) => {
            return value ? value : "N/A";
          },
        },
        {
          title: "Confirmed",
          dataIndex: "is_confirmed",
          key: "is_confirmed",
          render: (value) => {
            return value === true ? "Yes" : "No";
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        // {
        //   title: "Last login",
        //   dataIndex: "last_login",
        //   key: "last_login",
        // },
        {
          title: "Department",
          dataIndex: "department",
          key: "department",
          ...getColumnSearchProps("department"),
        },
        {
          title: "Actions",
          fixed: "right",
          render: (_, record) => {
            return (
              <EachAction
                record={record}
                handleModal={handleModal}
                refetch={refetch}
                setEnabledEmp={setEnabledEmp}
              />
            );
          },
        },
      ];

  function handlePaginationEmp(page) {
    setPageEmp(page);
    setEnabledEmp(true);
  }
  return (
    <>
      {isLoading ? (
        <SkeletonUI />
      ) : emp_show?.length ? (
        <>
          <Table
            columns={columns}
            dataSource={emp_show}
            pagination={false}
            scroll={handleModal ? { x: "568px" } : { x: "768px" }}
          />
          <div className="mt-3 d-flex justify-content-end">
            {emp?.payload?.all_employees?.meta?.last_page > 1 ? (
              <Pagination
                total={emp?.payload?.all_employees?.meta?.last_page}
                pageSize={1}
                onChange={handlePaginationEmp}
              />
            ) : null}
          </div>
        </>
      ) : (
        <NoContent />
      )}
    </>
  );
};
export default EmployeeTable;

function EachAction({ record, handleModal, setEnabledEmp, refetch }) {
  // console.log({ record });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isopen, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTog, setLoadingTog] = useState(false);
  // console.log({ record });
  const items = [
    {
      key: "1",
      label: (
        <Button
          type="text"
          className="pointer w-100 flex justify-content-start "
          onClick={handleClick}
        >
          <span
            style={{
              width: "100%",
            }}
          >
            View
          </span>
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Popconfirm
          title={record?.is_locked ? "Unlock Account" : "Lock Account"}
          description="Are you sure to perform this action?"
          onConfirm={() => handleConfirmToggle()}
          onCancel={() => setOpen(null)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="text"
            className="pointer w-100 flex justify-content-start"
            loading={loadingTog}
            onClick={() => setOpen(true)}
          >
            {record?.is_locked ? "Unlock Account" : "Lock Account"}
          </Button>
        </Popconfirm>
      ),
    },

    {
      key: "5",
      label: (
        <Popconfirm
          title={
            record.status === "Inactive"
              ? "Activate account "
              : "Deactivate account"
          }
          description="Are you sure to perform this action?"
          onConfirm={() => handleConfirm(record)}
          onCancel={() => setOpen(null)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="text"
            className="pointer w-100 flex justify-content-start "
            loading={loading}
            onClick={() => setOpen(true)}
          >
            <span
              style={{
                width: "100%",
              }}
            >
              {record.status === "Inactive"
                ? "Activate account "
                : "Deactivate account"}
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  function handleClick() {
    navigate(`/modules/hris/employee/view/${record.id}`);
    if (handleModal) {
      handleModal();
    }
  }
  function activateDeactive(record) {
    // console.log({ record });
    const creds = {
      id: record.id,
      action: record.status === "Inactive" ? "activate" : "deactivate",
    };

    setLoading(true);
    activateDeactivateUser(dispatch, creds).then((res) => {
      setLoading(false);
      setOpen(null);
      if (res?.status === "success") {
        // alert("here");
        refetch();
      }
    });

    // console.log({ creds });
  }

  function handleConfirm(record) {
    setOpen(true);
    activateDeactive(record);
  }
  function handleConfirmToggle() {
    setOpen(true);
    // alert("here");
    handleToggleLock();
  }

  function handleToggleLock() {
    setOpen(true);
    const creds = {
      id: record.id,
      status: record.is_locked ? false : true,
    };

    setLoadingTog(true);
    toggleUserLock(dispatch, creds).then((res) => {
      setLoadingTog(false);
      setOpen(null);
      if (res?.status === "success") {
        // alert("here");
        refetch();
      }
    });
  }

  return (
    <Dropdown
      menu={{
        items,
      }}
      // trigger={["click"]}
      arrow
      open={isopen}
      // onVisibleChange={() => alert("here")}
    >
      <button className="table-btn">
        <AiOutlineEllipsis />
      </button>
    </Dropdown>
  );
}
