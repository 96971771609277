import React, { useState } from "react";
// import { FaUserTie } from "react-icons/fa";
import styles from "../../../styles/employee/Profile.module.css";
import { rejectUserInfo } from "../../../redux/users/userActions";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import { MdOutlineClose } from "react-icons/md";
import { GoPrimitiveDot } from "react-icons/go";

function EmploymentCard({ info, setEnabled }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const confirm = () => {
    setLoading(true);
    setShowModal(false);
    rejectUserInfo(dispatch, {
      ...info,
      info_type: "employment_history",
    }).then((res) => {
      setLoading(false);
      //   setShowModal(false);

      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  };

  const modalTrigger = () => {
    Modal.confirm({
      title: "Remove Employment History",
      content: "Are you sure you want to remove this information?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  return (
    <div className="col-md-4">
      <div className="card bg-white">
        <div className="card-body position-relative">
          <div className={styles.cardDetails}>
            {/* <FaUserTie className={styles.cardIcon} /> */}
            <div className="d-flex align-items-center gap-2">
              <h5>{info.company_name}</h5>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <p className="d-flex align-items-center">{info.position}</p>
                <p>{info.job_description}</p>
              </div>
              <div>
                <p className="d-flex align-items-center gap-2">
                  <GoPrimitiveDot className="text-success" />
                  {info.start_date}
                </p>
                <p className="d-flex align-items-center gap-2">
                  <GoPrimitiveDot className="text-danger" />
                  {info.end_date}
                </p>
              </div>
            </div>
          </div>
          <button
            className="myBtn-close"
            loading={loading}
            onClick={modalTrigger}
          >
            <MdOutlineClose />
          </button>

          {/* <Modal
            title={`Remove Employment History`}
            centered
            onOk={confirm}
            onCancel={modalHandler}
            open={showModal}
            cancelText="No"
            okText="Yes"
          >
            <p className="h6">
              Are you sure you want to remove this information?
            </p>
          </Modal> */}
        </div>
      </div>
    </div>
  );
}

export default EmploymentCard;
