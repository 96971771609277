import { Link } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import SearchAddTask from "./../components/SearchAddTask";
import { Flex, Popover } from "antd";
import { BsChevronDown } from "react-icons/bs";
import UpcomingTasksList from "../components/UpcomingTasksList";

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { Calendar, Col, Radio, Row, Select, theme, Typography } from "antd";
import dayLocaleData from "dayjs/plugin/localeData";

const Upcoming = () => {
  const months = ["January", "February", "March", "April", "May"];

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const content = (
    <div
      style={{
        width: 300,
        height: 500,
        overflowY: "auto",
      }}
      className="d-flex flex-column gap-2"
    >
      {months.map((item) => (
        <div className="d-flex flex-column gap-2" key={item}>
          <Link to={`/to-do/upcoming/${item}`} className="h4">
            {item}
          </Link>
          <Calendar
            fullscreen={false}
            // headerRender={({ value, type, onChange, onTypeChange }) => {
            //   const start = 0;
            //   const end = 12;
            //   const monthOptions = [];
            //   let current = value.clone();
            //   const localeData = value.localeData();
            //   const months = [];
            //   for (let i = 0; i < 12; i++) {
            //     current = current.month(i);
            //     months.push(localeData.monthsShort(current));
            //   }
            //   for (let i = start; i < end; i++) {
            //     monthOptions.push(
            //       <Select.Option key={i} value={i} className="month-item">
            //         {months[i]}
            //       </Select.Option>
            //     );
            //   }
            //   const year = value.year();
            //   const month = value.month();
            //   const options = [];
            //   for (let i = year - 10; i < year + 10; i += 1) {
            //     options.push(
            //       <Select.Option key={i} value={i} className="year-item">
            //         {i}
            //       </Select.Option>
            //     );
            //   }
            //   return (
            //     <div
            //       style={{
            //         padding: 8,
            //       }}
            //     >
            //       <Typography.Title level={4}>Custom header</Typography.Title>
            //       <Row gutter={8}>
            //         <Col>
            //           <Radio.Group
            //             size="small"
            //             onChange={(e) => onTypeChange(e.target.value)}
            //             value={type}
            //           >
            //             <Radio.Button value="month">Month</Radio.Button>
            //             <Radio.Button value="year">Year</Radio.Button>
            //           </Radio.Group>
            //         </Col>
            //         <Col>
            //           <Select
            //             size="small"
            //             popupMatchSelectWidth={false}
            //             className="my-year-select"
            //             value={year}
            //             onChange={(newYear) => {
            //               const now = value.clone().year(newYear);
            //               onChange(now);
            //             }}
            //           >
            //             {options}
            //           </Select>
            //         </Col>
            //         <Col>
            //           <Select
            //             size="small"
            //             popupMatchSelectWidth={false}
            //             value={month}
            //             onChange={(newMonth) => {
            //               const now = value.clone().month(newMonth);
            //               onChange(now);
            //             }}
            //           >
            //             {monthOptions}
            //           </Select>
            //         </Col>
            //       </Row>
            //     </div>
            //   );
            // }}
            onPanelChange={onPanelChange}
          />
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div>
        <HocHeader
          module_title="TODOS"
          module_class="performance-header"
          module_name={"Upcoming"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="">
                <h3 className="h4">Upcoming</h3>
                <p>See lists of all Upcoming tasks</p>
              </div>

              <div className="d-flex gap-2">
                <p>Go Back</p>
              </div>
            </div>

            <Flex justify="space-between" vertical gap={"3rem"}>
              <SearchAddTask />
              <div
                className="d-flex flex-column gap-5"
                style={{
                  overflowX: "hidden",
                }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <h3 className="h3">February 2024</h3>
                  <Popover
                    content={content}
                    title="Months"
                    trigger="click"
                    placement="bottomRight"
                  >
                    <BsChevronDown size={20} cursor={"pointer"} />
                  </Popover>
                </div>

                <div
                  className="d-flex gap-5"
                  style={{
                    overflowX: "scroll",
                    width: "100vw",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",

                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <UpcomingTasksList />
                  <UpcomingTasksList />
                  <UpcomingTasksList />
                  <UpcomingTasksList />
                  <UpcomingTasksList />
                  <UpcomingTasksList />
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </main>
    </>
  );
};

export default Upcoming;
