import { Avatar, QRCode } from "antd";
import React from "react";
import TemplateA from "../../hrms/id-card/id-templates/TemplateA";
import { useOutletContext } from "react-router-dom";

function MyIDCardPage() {
  const [profile] = useOutletContext();

  const iframeInstance =
    ' <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.62283124574!2d3.28395955!3d6.548055099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1692177515087!5m2!1sen!2sng" width="100%" height="450"style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>';
  const iframe = () => {
    return {
      __html: iframeInstance,
    };
  };

  return (
    <div className="id__card_section">
      <h2 className="newLeaveHead mb-5">Employee Profile</h2>

      <div className="row">
        <div className="col-md-6">
          <div className="id__card_profile">
            <Avatar
              src={profile?.personal?.avatar || "assets/man.png"}
              alt=""
              className="img__profile"
            />
          </div>

          <div className="id__card_profile">
            <section className="mb-3">
              <span className="id__card__title">Name:</span>
              <span className="id__card__info ">
                {profile?.personal?.fullname || "N/A"}
              </span>
            </section>
            <section className="mb-3">
              <span className="id__card__title">Job role: </span>
              <span className="id__card__info ">
                {profile?.job_role?.job_role?.role || "N/A"}
              </span>
            </section>
            <section className="mb-3">
              <span className="id__card__title">Department: </span>
              <span className="id__card__info ">
                {profile?.personal?.department?.name || "N/A"}
              </span>
            </section>
          </div>

          <div className="id__card_profile">
            <section className="mb-3">
              <span className="id__card__title">Profile:</span>
              <span className="id__card__info ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum magni, dolorem sunt rerum iusto aliquam cumque
                officiis doloremque. Id dolorem sapiente nostrum impedit!
              </span>
            </section>
          </div>
          <div className="id__card_profile">
            <section className="mb-3">
              <span className="id__card__title">Job description:</span>
              <span className="id__card__info ">
                <ul>
                  <li>
                    {" "}
                    You have a degree in Finance, accounting, business
                    administration, or a similar field of study.
                  </li>
                  <li>
                    {" "}
                    You're tech savvy and have expert-level computer skills.
                  </li>
                  <li>
                    {" "}
                    You have a good handle on tools like SAP, QuickBooks,
                    Tableau, Xero Accounting Software, and HP TRIM.
                  </li>
                </ul>
              </span>
            </section>
          </div>
        </div>
        <div className="col-md-6">
          <div className="id__card__right">
            <div className="id__card_profile">
              <img src="/assets/verify.png" alt="" />
            </div>

            <div className="id__card_profile">
              <QRCode size={205} value={"https://www.google.com/"} />
            </div>

            <div className="id__card_profile">
              <TemplateA
                selectedEmp={{
                  name: profile?.personal?.fullname,
                  job_role: profile.job_role?.job_role,
                  employee_id: profile?.personal?.employee_id,
                  signature: profile?.personal?.signature,
                  avatar: profile?.personal?.avatar,
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div dangerouslySetInnerHTML={iframe()}></div>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.62283124574!2d3.28395955!3d6.548055099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1692177515087!5m2!1sen!2sng"
              width="600"
              height="450"
              style="border:0;"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>  */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyIDCardPage;
