import { useEffect, useState } from "react";
import NoContent from "../../ui/NoContent";
import { Button, Drawer, Space } from "antd";
import TemplateTable from "./TemplateTable";

import CreateTemplate from "./CreateTemplate";
import { useGetDeductionMasters } from "../../../util/usershookActions";
import dayjs from "dayjs";
import SkeletonUI from "../../ui/Skeleton";

function DeductionsMaster() {
  const [table_data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const {
    data: deduction_master,
    isLoading,
    refetch,
  } = useGetDeductionMasters(enabled, setEnabled);

  useEffect(() => {
    if (deduction_master) {
      const new_data = deduction_master.payload.map((data) => {
        return {
          key: data?.id,
          id: data?.id,
          tem_name: data?.band_name,
          description: data?.description,
          band_0: data?.band0.amount,
          band_1: data?.band1.amount,
          band_2: data?.band2.amount,
          band_3: data?.band3.amount,
          band_4: data?.band4.amount,
          band_5: data?.band5.amount,
          band_6: data?.band6.amount,
          band_0_per: data?.band0.tax_applied,
          band_1_per: data?.band1.tax_applied,
          band_2_per: data?.band2.tax_applied,
          band_3_per: data?.band3.tax_applied,
          band_4_per: data?.band4.tax_applied,
          band_5_per: data?.band5.tax_applied,
          band_6_per: data?.band6.tax_applied,
          pen_employee: data?.pension_employee,
          pen_employer: data?.pension_employer || "N/A",
          created_at: dayjs(data?.created_at).format("DD/MM/YYYY"),
          updated_at: dayjs(data?.updated_at).format("DD/MM/YYYY"),
        };
      });
      setData(new_data);
    }
  }, [deduction_master]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log({ table_data });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 min-vh-100">
            <section className="app__section py-4">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  padding: "1rem",
                }}
              >
                <div>
                  <h5 className="mb-0">Deductions Master</h5>
                </div>
                <Button className="myBtn" onClick={showModal}>
                  {" "}
                  New Template
                </Button>
              </div>
              {isLoading ? (
                <SkeletonUI />
              ) : table_data?.length ? (
                <TemplateTable refetch={refetch} table_data={table_data} />
              ) : (
                <div className="">
                  <NoContent width={"400px"} />

                  <div className="d-flex justify-content-center my-3">
                    <Button className="myBtn" onClick={showModal}>
                      Add new template
                    </Button>
                  </div>
                </div>
              )}

              <Drawer
                open={isModalOpen}
                onClose={handleCancel}
                extra={
                  <Space>
                    <Button danger size="large" onClick={handleCancel}>
                      Close
                    </Button>
                  </Space>
                }
                width={"90%"}
              >
                <>
                  <div>
                    <h5 className="">New Deduction Template</h5>
                    <div className="">
                      <CreateTemplate refetch={refetch} />
                    </div>
                  </div>
                </>
              </Drawer>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeductionsMaster;
