import { Button, Modal } from "antd";
import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { CloudUploadOutlined, FileFilled } from "@ant-design/icons";
import { HiUpload } from "react-icons/hi";
import { FaFolder } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { uploadProfileDocumentEmployee } from "../../../redux/users/userActions";
import { NavLink, Outlet } from "react-router-dom";
import { FolderOutlined } from "@ant-design/icons";
import { useGetUserDocument } from "../../../util/usershookActions";
import { useGetSystemSettingsUtils } from "../../../util/usershookActions";
import { MdOutlineAdd, MdAddCircleOutline } from "react-icons/md";
import Warning from "../../ui/Warning";

function Documents() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [save_spinner, setSaveSpinner] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [local_file, setLocalFile] = useState(null);

  const [enabled_uti, setEnabledUtil] = useState(true);
  useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const init_details = {
    channel: "Cloudinary",
    name: "",
    expiry_date: "",
    file: "",
    category: "",
  };

  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);
  console.log({ userProfile });

  const { data: documents_info, refetch } = useGetUserDocument(
    enabled,
    setEnabled,
    userProfile?.id
  );

  const callbackFn = () => {
    values.user_id = userProfile?.id;

    setSaveSpinner(true);

    uploadProfileDocumentEmployee(dispatch, values).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        clearForm();
        setShowModal(false);
        refetch();
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const handleFileChange = (e) => {
    e.preventDefault();
    const { files } = e.target;
    values.file = files[0];
    setLocalFile(files[0]);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`${styles.title} greeting_2`}>
          <div>
            <h4>Documents</h4>
            <p>Review and update your documents</p>
          </div>
          <FaFolder />
        </div>
      </div>
      <Modal
        okText={"Add"}
        open={showModal}
        onCancel={modalHandler}
        footer={null}
        centered
      >
        <h5 className="text-dark mb-4 d-flex align-items-center gap-2">
          {<MdOutlineAdd />} Add Document
        </h5>
        {Object.keys(utils).length && utils?.others["Approval"].length
          ? utils.others["Approval"].map((item) => {
              if (item.name === "Documents" && item.deleted_at === null) {
                return <Warning />;
              } else {
                return null;
              }
            })
          : null}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label htmlFor="name">Document Name</label>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="document_expiration">Document Expiration</label>
              <input
                type="date"
                name="expiry_date"
                value={values.expiry_date}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label className="w-100">
                Attach Document
                <div className="d-flex gap-3">
                  <div className={styles.fileUpload}>
                    <input
                      type="file"
                      name="document"
                      className={styles.upload}
                      onChange={handleFileChange}
                    />
                    <CloudUploadOutlined />
                  </div>
                  {local_file && (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                      <FileFilled className="h1" />
                      <p className="text-center">{local_file?.name}</p>
                    </div>
                  )}
                </div>
              </label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.file || (back_errors.file && back_errors.file[0])}
                </small>
              </div>
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="category">Category</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.category ||
                    (back_errors.category && back_errors.category[0])}
                </small>
              </div>
              <select
                name="category"
                id="category"
                onChange={handleChange}
                value={values.category}
              >
                <option value="">--</option>
                {Object.keys(utils).length &&
                utils?.system_inputs["Documents"].length
                  ? utils.system_inputs["Documents"].map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="myBtn mt-3"
              loading={save_spinner}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>
      <div className="col-md-12">
        {/* {!documents_info?.payload?.length && (
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <img
                    src="/assets/no-document.jpg"
                    alt=""
                    className={styles.noDocumentImage}
                  />
                  <p className="text-center">
                    You currently do not have any documents uploaded.
                  </p>
                  <button className={styles.uploadBtn} onClick={modalHandler}>
                    <HiUpload />
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {documents_info?.payload?.length ? (
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-2">
                <ul className="px-3 documents mb-0">
                  {documents_info?.payload.map((doc, index) => {
                    return (
                      <li key={index} className="document-category">
                        <NavLink
                          to={doc.category}
                          className={({ isActive }) =>
                            isActive ? styles.folderactive : ""
                          }
                        >
                          <FolderOutlined />
                          <p>{doc.category}</p>
                        </NavLink>
                      </li>
                    );
                  })}
                  {documents_info?.payload?.length ? (
                    <li
                      className="document-category card"
                      style={{ cursor: "pointer" }}
                      onClick={modalHandler}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{
                          height: "3rem",
                        }}
                      >
                        <MdAddCircleOutline
                          className={styles.addEntryBtn}
                          style={{ fontSize: "2rem" }}
                        />
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="col-md-10 pe-5">
                <Outlet
                  context={{
                    modal: modalHandler,
                    refetch,
                    list: documents_info?.payload,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <img
                    src="/assets/no-document.jpg"
                    alt=""
                    className={styles.noDocumentImage}
                  />
                  <p className="text-center">
                    You currently do not have any documents uploaded.
                  </p>
                  <button className={styles.uploadBtn} onClick={modalHandler}>
                    <HiUpload />
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Documents;
