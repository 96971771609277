import React from "react";
import { useShallowEqualSelector } from "../../../util/hooks";
import { selected_template } from "../../../util/selectors/userSelectors";

const Preview = ({ activities }) => {
  const selectedTemp = useShallowEqualSelector(selected_template);

  return (
    <div className="container">
      <div>
        <h4>Template: {selectedTemp.name}</h4>
        <div className="row mt-3">
          <h5>Activities:</h5>
          {activities.map((act, index) => (
            <div className="col-md-6 mb-4" key={act.id}>
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Activity {index + 1}:</label>
                      <h6>{act.activity}</h6>
                    </div>
                    <div className="col-md-12">
                      <label>Description:</label>
                      <h6>{act.description}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Start Time:</label>
                      <h6>{act.start_time}</h6>
                    </div>
                    <div className="col-md-6">
                      <label>End Time:</label>
                      <h6>{act.end_time}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Start Date:</label>
                      <h6>{act.start_date}</h6>
                    </div>
                    <div className="col-md-6">
                      <label>End Date:</label>
                      <h6>{act.end_date}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Preview;
