import React from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const Sidebar = () => {

  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            to={"#"}
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>

        <ul className="performance__navlinks ">
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"today"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Today</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"inbox"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Inbox</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"Upcoming"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Upcoming</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"labels"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Labels</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"priority"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Priority</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"projects"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Projects</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default React.memo(Sidebar);
