import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

function AnimationLayout() {
  const { pathname } = useLocation();
  
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: 'easeInOut',
    duration: .8,
  };

  return (
    <motion.div
      key={pathname}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Outlet />
    </motion.div>
  );
}

export default AnimationLayout;
