import React from "react";
import {
  AiFillAppstore,
  AiOutlineFileText,
  AiFillPlusSquare,
} from "react-icons/ai";
import { MdMonitor } from "react-icons/md";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { GoTools } from "react-icons/go";
import { NavLink, Link } from "react-router-dom";
import CanAccess from "../hoc/CanAccess";

function Sidebar() {
  return (
    <nav className="onboarding__nav d-flex flex-column justify-content-between">
      <div>
        <div className="d-flex flex-column gap-5 my-3 ps-4 pt-3">
          <h3 className="mb-0">Induction & Onboarding</h3>
          <Link
            to={"/subscriptions"}
            className="d-flex align-items-center gap-2 back"
          >
            <BsArrowLeftCircleFill />
            Return to Modules
          </Link>
        </div>
        <ul className="onboarding__navlinks">
          <CanAccess
            can_access={"view-induction-onboarding-overview"}
            isRoute={false}
          >
            <li>
              <NavLink
                to={""}
                end
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <AiFillAppstore />
                Overview
              </NavLink>
            </li>
          </CanAccess>

          <li>
            <NavLink
              to={"pre-employment"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <AiFillPlusSquare />
              Hire
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"induction"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <MdMonitor />
              Induction
            </NavLink>
          </li>

          <CanAccess
            can_access={"view-induction-onboarding-overview"}
            isRoute={false}
          >
            <li>
              <NavLink
                to={"onboarding"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <AiOutlineFileText />
                Onboarding
              </NavLink>
            </li>
          </CanAccess>
          <li>
            <NavLink
              to={"uploads"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <GoTools />
              Uploads
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="attr text-center mb-3">
        <small>All rights reserved.</small>
        <br />
        <small>CloudletHR</small>
      </div>
    </nav>
  );
}

export default Sidebar;
