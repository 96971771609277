import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import Sidebar from "./sidebar";
import { useUsersNotification } from "../../util/hooks";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";

export default function LeaveManagement() {
  useUsersNotification();
  const is_mobible = useMediaQuery({ query: "(max-width: 768px)" });

  function handleToggle(e) {
    if (is_mobible) {
      const sidebar = document.querySelector(".performance__nav");
      if (sidebar) {
        sidebar.classList.remove("mobile-show-sidebar");
      }
    } else {
      const sidebar = document.querySelector(".admin_layout");

      sidebar.classList.toggle("small__sidebar");
    }
  }

  return (
    <>
      <AdminDashboardLayout sidebar={<Sidebar handleToggle={handleToggle} />}>
        <Outlet />
      </AdminDashboardLayout>
    </>
  );
}
