import {
  FolderOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import styles from "../../styles/NotificationCard.module.css";
import { useState } from "react";
import { Space } from "antd";

function NotificationCard({ title, message, date, year, markAsRead, id }) {
  const [readmore, setReadMore] = useState(false);
  function toggleReadMore() {
    setReadMore((prevstate) => !prevstate);
  }

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <FolderOutlined />
        <p>{date}</p>
        <p>{year}</p>
      </div>
      <div className={styles.right}>
        <div className={styles.notification_header}>
          <h3>{title}</h3>
          <span>
            <Space size="small">
              {readmore ? (
                <MinusCircleOutlined
                  onClick={toggleReadMore}
                  className={`${styles.icon} ${styles.red}`}
                  title="Show less"
                />
              ) : (
                <PlusCircleOutlined
                  onClick={toggleReadMore}
                  className={`${styles.icon} ${styles.red}`}
                  title="Show more"
                />
              )}

              <CheckCircleOutlined
                className={`${styles.icon} ${styles.green}`}
                title="Mark as read"
                onClick={(e) => markAsRead(e, id)}
              />
            </Space>
          </span>
        </div>

        <p>{readmore ? message : message.substr(0, 100) + "..."}</p>
      </div>
    </div>
  );
}

export default NotificationCard;
