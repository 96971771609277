import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function LeaveHr() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Leave Management"
          module_class="performance-header"
          module_name={
            childPath === "teamannualleave_hr"
              ? "All Annual Leave Plan"
              : childPath === "requestforemployee_hr"
              ? "Request for Employee"
              : childPath === "all_employee_on_leave"
              ? "Employee on Leave"
              : "All Leave History"
          }
          module_description="Manage and track employee leaves, including vacation, sick days, and other time-off requests within an organization's human resources or workforce management system."
          module_img="/assets/landing-page/Leave Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default LeaveHr;
