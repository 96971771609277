import { useState } from "react";
import { useDispatch } from "react-redux";
import "../../../styles/pre-fill.css";
import { getURLSubdomain } from "../../../util/helpers";
import {
  useShallowEqualSelector,
  useUserCleanUp,
  useUsersNotification,
} from "../../../util/hooks";
import { company_details } from "../../../util/selectors/userSelectors";
import { useGetCompanyDetails } from "../../../util/usershookActions";
import { Modal, Button } from "antd";
import { acceptReject } from "../../../redux/users/userActions";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function ProvisionalOffer() {
  useUserCleanUp();
  useUsersNotification();
  const params = queryString.parse(useLocation().search);
  const [enabled, setEnabled] = useState(true);
  const company_url = getURLSubdomain();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingdel, setLoadingdel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModaldel, setShowModaldel] = useState(false);
  const [submit, setSubmit] = useState(false);

  useGetCompanyDetails(enabled, setEnabled, company_url);

  const companyDetails = useShallowEqualSelector(company_details);

  const company_logo = companyDetails?.settings.filter(
    (setting) => setting.setting === "CompLogo"
  )[0].value;
  const company_name = companyDetails?.business?.company_name;

  // console.log(params);

  const modalHandler = () => {
    setShowModal(!showModal);
  };
  const modalHandlerdel = () => {
    setShowModaldel(!showModaldel);
  };

  const confirm = (id) => {
    setLoading(true);
    Modal.destroyAll();
    const creds = {
      response: "APPROVE",
      hire_id: params?.hire_id,
    };
    acceptReject(dispatch, creds).then((res) => {
      setLoading(false);

      if (res?.status === "success") {
        setSubmit(true);
      }
    });
  };
  const confirmdel = (id) => {
    setLoadingdel(true);
    Modal.destroyAll();
    const creds = {
      response: "REJECT",
      hire_id: params?.hire_id,
    };
    acceptReject(dispatch, creds).then((res) => {
      setLoadingdel(false);

      if (res?.status === "success") {
        // refetch(true);
        setSubmit(true);
      }
    });
  };
  const accept = () => {
    Modal.confirm({
      title: "Accept offer",
      content: "Do you want to accept this offer?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const reget = () => {
    Modal.confirm({
      title: "Reject offer",
      content: "Do you want to reject this offer?",
      onOk: confirmdel,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerdel,
      open: showModaldel,
      closable: true,
    });
  };

  return (
    <div className="offer-letter d-flex justify-content-center align-items-center">
      <div className="offer-letter-content">
        {!submit ? (
          <div className="offer-letter-container">
            <div className="company-logo d-flex justify-content-center">
              <img src={company_logo} alt={company_name} width={100} />
              <h2>{company_name}</h2>
            </div>
            <div className="letter-content my-3">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea nemo
              dolores consectetur sequi id obcaecati porro ipsum adipisci
              dolorum, est at vel qui numquam, animi soluta minus? Quisquam quos
              officiis deleniti, dignissimos minima deserunt necessitatibus sunt
              unde exercitationem. Praesentium et at aperiam pariatur
              aspernatur? Maiores, quisquam sint dicta debitis amet sed rerum
              natus suscipit, ducimus obcaecati corrupti nihil quasi modi
              veritatis. Omnis rerum officiis veniam iusto nihil doloribus, quis
              ratione minus quae neque consequuntur adipisci debitis incidunt
              modi, tenetur maxime! Amet nisi similique accusantium et error
              itaque quasi porro animi, inventore quaerat cum atque voluptatum
              velit vel possimus repellat nam dignissimos incidunt expedita
              architecto delectus tempore dolor. Numquam voluptates beatae
              distinctio molestiae, accusamus vitae laborum magni corporis ex in
              necessitatibus sed voluptatem nulla, ratione perspiciatis itaque
              voluptas iure quam ut est illum libero? Itaque amet molestias
              necessitatibus laudantium quo voluptates eos officiis, ut dolorem
              libero dolor, illo omnis mollitia in rerum vero facere alias
              ducimus blanditiis. Sed sunt in aperiam tempore nam aspernatur,
              repudiandae quas nisi quod. Vitae, illum at accusantium id saepe,
              ducimus quam voluptatum obcaecati itaque numquam animi ipsum
              ratione nobis aperiam sed nesciunt incidunt? Cupiditate accusamus
              est odit, non, quibusdam, numquam eveniet hic in animi at vel
              inventore ratione. Consequatur magnam ullam officiis quis eius,
              amet fugit hic nemo in voluptatum quibusdam vitae natus ex. Cumque
              sequi in ipsum illo quae dolor ut inventore nisi adipisci
              delectus, error mollitia? Laboriosam, eum facere tempora qui
              commodi, quos at, nobis deleniti sequi porro harum accusamus nemo
              sit iusto numquam blanditiis facilis quae voluptate. Iste illum
              beatae qui, nam repellat corrupti, excepturi, odio earum id neque
              accusantium ipsam similique delectus. Eligendi aperiam culpa
              ullam, voluptatibus nihil consequatur consectetur, corrupti labore
              unde doloremque dolores odio. Tempore aliquid temporibus
              consectetur corporis? Facilis sed maiores, ab rerum quam eligendi,
              nihil ex dolor odio quibusdam necessitatibus vero error ducimus
              veritatis aliquid quidem nisi! Fuga quidem excepturi, iure iusto
              unde, nulla culpa esse maiores alias eaque dolor ab perferendis
              nobis iste harum omnis beatae quibusdam facilis quis eos doloribus
              provident repellendus? Eius nihil, quis esse consequuntur,
              provident unde quae hic amet earum quo a. Commodi magni qui,
              temporibus quos eos neque incidunt doloremque non quaerat fugiat
              voluptatem distinctio inventore molestias, modi, eum maiores
              perspiciatis ullam nemo dolorem autem velit a. Asperiores
              architecto quo, voluptates fugiat error, debitis voluptatem
              adipisci ab exercitationem quia consequuntur dignissimos ipsam
              minima velit ad vitae! Rerum alias, molestias numquam neque saepe,
              ipsa modi commodi dignissimos ut aspernatur maiores debitis
              tempore eos, omnis assumenda voluptatibus quaerat minima vitae
              illo cumque! Illo dolores nisi aliquam natus facere labore dolor
              inventore vero eaque illum omnis, eum totam! Obcaecati ipsa cumque
              minus reprehenderit? Dolores nobis ipsum commodi aliquam magni
              maxime ea accusamus doloremque iure, totam velit expedita culpa
              quis quisquam dolor fugiat fugit nulla maiores excepturi eligendi,
              neque modi officiis? Molestias sit possimus, saepe quasi,
              asperiores distinctio deleniti voluptatem aut corrupti beatae
              ipsam fuga adipisci, eum dolores repellendus quibusdam sapiente
              atque vero sunt repudiandae? Quasi dolore accusantium libero
              consequuntur facere at est reprehenderit beatae, quisquam, sequi
              possimus harum natus voluptatem minus soluta tempore. Voluptates
              beatae vitae iure vel ut? Temporibus atque reiciendis aliquid iure
              similique quia omnis quod cum natus at beatae perferendis minima
              iusto nulla, earum, doloribus tempora. Ipsum numquam asperiores
              ipsam tempore aliquid aperiam autem aspernatur nobis, quod
              corporis doloribus. Corrupti aspernatur eveniet, eligendi
              reprehenderit voluptate illo, consequatur harum illum repellendus
              quidem dicta quaerat ratione libero. Inventore nobis delectus
              nesciunt dolore ex, eos assumenda sunt aspernatur molestiae. Atque
              tenetur possimus rerum, obcaecati neque provident dolore
              perspiciatis dolor eos ea vel eius tempore eveniet accusantium
              minima eligendi. Qui vel, laborum repellendus accusamus ut labore
              animi cum ipsa hic iusto! Possimus veniam ipsa ad, expedita sit
              suscipit dolorum quae ea. Non soluta cupiditate et quae esse unde
              praesentium est dolorum quibusdam adipisci neque sequi harum
              consequatur voluptas error autem ipsam, at rerum maxime omnis
              corrupti eum placeat nemo excepturi. Ex nihil accusantium atque
              quae dolorum, repellat eligendi. Laudantium dolores quibusdam
              velit aut deserunt debitis assumenda omnis odit fugiat rem laborum
              expedita atque consequatur inventore harum quia eos qui similique
              numquam ut quidem dicta, soluta cum quis. Explicabo eius
            </div>
            <div className="letter-cta mt-5 d-flex justify-content-end gap-3">
              <Button
                className="myBtn-back py-0"
                loading={loadingdel}
                onClick={reget}
              >
                Reject Offer
              </Button>
              <Button className="myBtn" loading={loading} onClick={accept}>
                Accept Offer
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <img src="/assets/okay.png" alt="ok" className="mb-5" width={300} />
            <h3>Response Submitted Successfully </h3>
            <p> Our HR team will get back to you soon.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProvisionalOffer;
