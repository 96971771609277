import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import { Button, Input, Select, Modal, InputNumber } from "antd";
import {
  useGetSystemSalaryStructureOthers,
  useGetSystemSalaryStructure,
} from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { updateSalaryComponentOther } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import HocHeader from "../../ui/HocHeader";

const mL = [
  {
    value: "January",
    label: "January",
  },
  {
    value: "February",
    label: "February",
  },

  {
    value: "March",
    label: "March",
  },
  {
    value: "April",
    label: "April",
  },
  {
    value: "May",
    label: "May",
  },

  {
    value: "June",
    label: "June",
  },

  {
    value: "July",
    label: "July",
  },
  {
    value: "August",
    label: "August",
  },
  {
    value: "September",
    label: "September",
  },
  {
    value: "October",
    label: "October",
  },
  {
    value: "November",
    label: "November",
  },
  {
    value: "December",
    label: "December",
  },
  {
    value: "Anniversary",
    value: "Anniversary",
  },
  {
    value: "Annual Leave",
    value: "Annual Leave",
  },
];

function OtherItemsEdit() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [enabled_wage, setEnabled_wage] = useState(true);
  const {
    data: sal_data,
    isLoading,
    refetch,
  } = useGetSystemSalaryStructureOthers(enabled, setEnabled);
  const { data: sal_data_wage } = useGetSystemSalaryStructure(
    enabled_wage,
    setEnabled_wage
  );

  const [option_data, setOptionData] = useState([]);
  const [percent, setPercent] = useState(0);

  const [secondary_data, setSecondaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function handleDynChangeSec(e, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    affected[e.target.name] = e.target.value;
    setSecondaryData(new_arry);
    calPercent();
    // if ([e.target.name] === "percentage") {
    //   calPercent();
    // }
  }

  function addNewComponent(e) {
    const new_arry = [...secondary_data];
    new_arry.splice(new_arry.length, 1, {
      name: "",
      description: "",
      is_taxable: false,
      percentage: "",
      category: "secondary",
      components: [],
    });
    setSecondaryData(new_arry.reverse());
  }

  function removeComponent(e, index) {
    const new_arry = [...secondary_data];
    new_arry.splice(index, 1);
    setSecondaryData(new_arry);
  }

  function confirmFunc(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Update component",
      content: "Do you want to perform this action?",
      onOk: updateComponent,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function updateComponent() {
    const creds = {
      salary_structure_others: [...secondary_data],
    };
    setLoading(true);
    updateSalaryComponentOther(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }

  function handleChangeSec(value, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    affected.wage_items = value;
    setSecondaryData(new_arry);
  }

  function handleChangeSecMonth(value, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    affected.when_to_pay = value;
    setSecondaryData(new_arry);
  }
  function setAmount(value, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    affected.amount = value;
    setSecondaryData(new_arry);
  }

  function calPercent() {
    let total_percentage = 0;

    secondary_data?.forEach((item) => {
      total_percentage += parseFloat(item.percentage || 0);
    });

    setPercent(total_percentage.toFixed(2));
    // if (parseInt(percent) === parseInt(100)) {
    //   setDisabled(false);
    // } else {
    //   setDisabled(true);
    // }
  }

  useEffect(() => {
    if (sal_data?.payload && Object.keys(sal_data?.payload).length) {
      let total_percentage = 0;
      sal_data?.payload?.forEach((item) => {
        total_percentage += parseFloat(item.percentage);
      });

      setPercent(total_percentage.toFixed(2));
      calPercent();

      // console.log({ combined });
    }
  }, [sal_data]);

  useEffect(() => {
    if (sal_data_wage?.payload && Object.keys(sal_data_wage?.payload).length) {
      const new_arr = sal_data_wage?.payload?.basic.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      const new_arr_1 = sal_data_wage?.payload?.secondary?.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });

      const combined = [...new_arr, ...(new_arr_1 || [])];

      setOptionData(combined);
    }
  }, [sal_data_wage]);

  useEffect(() => {
    let new_arr_1;
    if (sal_data?.payload && Object.keys(sal_data?.payload).length) {
      if (sal_data?.payload?.length) {
        new_arr_1 = sal_data?.payload?.map(
          ({
            name,
            description,
            is_taxable,
            percentage,
            wage_items,
            account_code,
            payment_type,
            status,
            when_to_pay,
            formula,
            formula_patternized,
            amount,
          }) => {
            return {
              name,
              description,
              is_taxable,
              percentage,
              wage_items: wage_items || [],
              account_code,
              payment_type,
              status,
              when_to_pay: when_to_pay,
              formula,
              formula_patternized,
              amount,
            };
          }
        );
      } else {
        new_arr_1 = [
          {
            name: "",
            description: "",
            is_taxable: false,
            percentage: "0",
            wage_items: [],
            account_code: "",
            payment_type: "CR",
            status: 0,
            when_to_pay: "",
            formula: "",
            formula_patternized: "",
            amount: "",
          },
        ];
      }

      setSecondaryData(new_arr_1);
    }
  }, [sal_data]);

  useEffect(() => {
    calPercent();
  }, [secondary_data, calPercent]);

  console.log({ secondary_data });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <section className="app__section py-4">
            <div className="d-flex justify-content-end align-items-center w-100 mb-0 gap-5 goal__nav__links">
              <Link to={`/modules/compensation/pay-roll`}>
                <p
                  className="performance__bagde2 backBtn align-items-center d-flex gap-2"
                  style={{
                    float: "right",
                  }}
                >
                  <MdOutlineArrowBack />
                  Back to list
                </p>
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-between ">
              <div>
                <h5 className="mb-0">Other payroll components</h5>
                <small className=" d-none d-sm-block">
                  All statutory components of the salary structure for the
                  organization.
                </small>
              </div>

              <div className="d-flex gap-3 align-items-center fixed bg-light p-2">
                <h6 className="mb-0 fw-bold"> Total</h6>
                <h6 className="fw-bold mb-0 ">{percent}%</h6>
              </div>
            </div>

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : Object.keys(sal_data?.payload)?.length ||
              secondary_data.length ? (
              <>
                <div className="performance-form">
                  <div className="row mt-5">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>
                        {/* <h5 className="mb-0">Secondary components</h5>
                        <small className=" d-none d-sm-block">
                          All statutory components of the salary structure for
                          the organization.
                        </small> */}
                      </div>

                      <small
                        className="performance__bagde2 bg-primary"
                        onClick={addNewComponent}
                      >
                        {" "}
                        Add new component
                      </small>
                    </div>
                  </div>
                  {secondary_data?.map((item, index) => (
                    <>
                      <div className="row">
                        <div className="group col-8">
                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="name">Name</label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={item.name}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"Name"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Taxable</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="is_taxable"
                                value={item.is_taxable}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={true}> Yes</option>
                                <option value={false}> No</option>
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Amount</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <InputNumber
                                className="w-100 ant__number"
                                style={{ border: "none" }}
                                value={item.amount}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={(val) => setAmount(val, index)}
                                placeholder={"20,000"}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap mb-2">
                                <label htmlFor="description">When to pay</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <Select
                                // mode="multiple"
                                size="large"
                                allowClear
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Please select"
                                value={item?.when_to_pay}
                                onChange={(val) =>
                                  handleChangeSecMonth(val, index)
                                }
                                //   data-index={index}
                                options={mL}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="payment_type">
                                  Payment type
                                </label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="payment_type"
                                value={item.payment_type}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value="CR"> Credit item</option>
                                <option value="DR"> Debit item</option>
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="status">Status</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="status"
                                value={item.status}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}> Active</option>
                                <option value={0}> Inactive</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Description</label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <input
                                type="text"
                                name="description"
                                id="description"
                                value={item.description}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"Description"}
                                style={{ height: "48px" }}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap mb-2">
                                <label htmlFor="description">Wage items</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <Select
                                mode="multiple"
                                allowClear
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Please select"
                                value={item?.wage_items}
                                onChange={(val) => handleChangeSec(val, index)}
                                //   data-index={index}

                                options={option_data}
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">
                                  Formula Pattern
                                </label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <textarea
                                type="text"
                                name="formula_patternized"
                                id="formula_patternized"
                                value={item.formula_patternized}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"formula pattern"}
                                style={{ height: "48px" }}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Formula</label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <textarea
                                type="text"
                                name="formula"
                                id="formula"
                                value={item.formula}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"formula"}
                                style={{ height: "48px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="row mt-2">
                            <div className="d-flex justify-content-end">
                              <div className="form-group col-md-3 ">
                                <div className="d-flex justify-content-between flex-wrap mb-2">
                                  <label htmlFor="percentage">Percentage</label>

                                  <small className="text-danger">
                                    {/* {back_errors.name && back_errors.name[0]} */}
                                  </small>
                                </div>

                                <Input
                                  type="number"
                                  min={0}
                                  value={item.percentage}
                                  name="percentage"
                                  id="percentage"
                                  addonBefore={"%"}
                                  data-my="red"
                                  onChange={(e) => handleDynChangeSec(e, index)}
                                  placeholder="30"
                                  className="re_border"
                                />

                                {/* <div className="d-flex justify-content-end"> */}
                                {secondary_data.length > 1 ? (
                                  <small
                                    className="fw-bold text-danger mt-5 pointer d-block "
                                    onClick={(e) => removeComponent(e, index)}
                                  >
                                    {" "}
                                    Remove
                                  </small>
                                ) : null}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {secondary_data.length - 1 !== index ? (
                        <hr className="mt-3" />
                      ) : null}
                    </>
                  ))}
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    className="myBtn"
                    onClick={confirmFunc}
                    loading={loading}
                    // disabled={disabled}
                  >
                    Update component
                  </Button>
                </div>
              </>
            ) : (
              // <NoContent message={"No salary structure yet"} width={"400px"} />
              <>
                <NoContent width={"400px"} />

                <small
                  className="performance__bagde2 bg-primary"
                  onClick={addNewComponent}
                >
                  {" "}
                  Add new component
                </small>
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default OtherItemsEdit;
