import React from "react";
import { Space, Table, Button } from "antd";

function TemTable({ handleSelected, dataSource, refetch }) {
  // const params = queryString.parse(useLocation().search);

  const columns = [
    {
      title: "Name",
      dataIndex: "tem_name",
      key: "tem_name",
    },
    {
      title: "Created date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Updated date",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Min Taxable",
      dataIndex: "band_0",
      key: "band_0",
    },
    {
      title: "Min Rate (%)",
      dataIndex: "band_0_per",
      key: "band_0_per",
    },
    {
      title: "Employee Pension (%)",
      dataIndex: "pen_employee",
      key: "pen_employee",
    },
    {
      title: "Employer Pension (%)",
      dataIndex: "pen_employer",
      key: "pen_employer",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <EachAction
          record={record}
          handleSelected={handleSelected}
          refetch={refetch}
        />
      ),
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={dataSource} />
    </>
  );
}

function EachAction({ record, handleSelected, refetch }) {
  // const [open, setOpen] = useState(false);
  // const showDrawer = () => {
  //   setOpen(true);
  // };
  // const onClose = () => {
  //   setOpen(false);
  // };
  return (
    <Space size="middle">
      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-success`}
        view
        onClick={() => handleSelected(record)}
      >
        View
      </Button>

      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
      >
        Delete
      </Button>
    </Space>
  );
}

export default TemTable;
