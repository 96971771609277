import { setPrivateRequest } from "../../util/requestMethods";

const preferenceCleanUp = async (dispatch) => {
  dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
};
const addPermission = async (dispatch, id) => {
  dispatch({ type: "ADD_PERMISSION", payload: id });
};
const removePermission = async (dispatch, id) => {
  dispatch({ type: "REMOVE_PERMISSION", payload: id });
};

const addEmployeeGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().post(
        "hrm/preferences/role",
        creds
      );
      result = result.data;

      console.log(result);

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "ADD_GROUP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_GROUP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const addEmployeeToGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().post(
        "hrm/preferences/role/add-user",
        creds
      );
      result = result.data;

      // console.log(result);

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "ADD_GROUP_EMP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "ADD_GROUP_EMP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const RemoveEmployeeFromGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/preferences/role/${creds.role_id}/users/${creds.user_id}/delete`
      );
      result = result.data;

      // console.log(result);

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "REMOVE_GROUP_EMP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "REMOVE_GROUP_EMP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const RemoveEmployeeFromGroupMultiple = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().post(
        `hrm/preferences/role/${creds.role_id}/users/delete`,

        { user_ids: creds.user_ids }
      );
      result = result.data;

      // console.log(result);

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "REMOVE_GROUP_EMP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "REMOVE_GROUP_EMP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

const editEmployeeGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().post(
        // `hrm/preferences/role?role_id=${creds.role_id}`,
        `hrm/preferences/role`,
        creds
      );
      result = result.data;

      // console.log(result);

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "EDIT_GROUP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "EDIT_GROUP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};
const deleteEmployeeGroup = async (dispatch, creds) => {
  if (!window.navigator.onLine) {
    dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
    setTimeout(() => {
      dispatch({ type: "USER_OFFLINE_PREFRENCE" });
    }, 500);
  } else {
    try {
      dispatch({ type: "CLEAR_PREFRENCE_ERRORS" });
      dispatch({ type: "START_PREFRENCE_SPINNER" });
      let result = await setPrivateRequest().delete(
        `hrm/preferences/role/${creds}/delete`
      );
      result = result.data;

      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "DELETE_GROUP_SUCCESS", payload: result });

      return result;
    } catch (error) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      let resMessage = error?.response?.data;

      dispatch({ type: "DELETE_GROUP_ERROR", payload: resMessage });
      return resMessage;
    }
  }
};

export {
  preferenceCleanUp,
  addPermission,
  removePermission,
  addEmployeeGroup,
  deleteEmployeeGroup,
  editEmployeeGroup,
  addEmployeeToGroup,
  RemoveEmployeeFromGroup,
  RemoveEmployeeFromGroupMultiple,
};
