import React from "react";

import { NavLink } from "react-router-dom";

function LeaveActionsDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"leave-actions"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">New Leave Request Form</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"leave-actions/annualLeave"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">My Annual Leave Plan</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"leave-actions/teamleaveplan"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Team Annual Leave Plan</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"leave-actions/leaveHistory"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">My Leave History</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"leave-actions/teamAnnual"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">My Team Leave History</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default LeaveActionsDropdown;
