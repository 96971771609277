import { useState } from "react";
import { uploadMultiple } from "../../../redux/users/userActions";
import InsightsBar from "../InsightsBar";
import { useUserCleanUp } from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { NavLink, Route, Routes } from "react-router-dom";
import Home from "./Home";
import PreEmploymentDeletedList from "./PreEmploymentDeletedList";
import NewHireModal from "./NewHireModal";
import UploadExcel from "./UploadExcel";
import Modal from "antd/es/modal/Modal";
import { Tabs } from "antd";
import PageNotFound from "../../PageNotFound";

function Hire() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState();

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  function handleUpload(e) {
    e.preventDefault();
    const url = "hrm/onboarding/pre-employment/new-hire/excel-upload";
    setLoading(true);
    const creds = { file };
    uploadMultiple(dispatch, url, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        setFile(null);
      }
    });
  }

  const items = [
    {
      key: "1",
      label: `Add a Hire`,
      children: <NewHireModal modalHandler={modalHandler} />,
    },
    {
      key: "2",
      label: `Upload Multiple Hires`,
      children: (
        <UploadExcel
          file={file}
          setFile={setFile}
          loading={loading}
          handleUpload={handleUpload}
        />
      ),
    },
  ];

  return (
    <div>
      <header className="onboarding-header d-flex align-items-center justify-content-between py-5">
        <div className="w-50">
          <small>Welcome to</small>
          <h2>Hire Stage</h2>
          <p>
            HR Managers and privileged administrators can view all your new
            hires, monitor their stages and manage their activities here.
          </p>
        </div>
        <img src="/assets/image 11 (1).png" alt="onboarding and induction" />
      </header>
      <nav style={{ backgroundColor: "var(--clr-primary)" }}>
        <ul className="onboarding-nav" style={{ marginBottom: 0 }}>
          <li>
            <NavLink
              to={""}
              className={({ isActive }) => (isActive ? "active" : null)}
              end
            >
              All Hires
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"archive"}
              className={({ isActive }) => (isActive ? "active" : null)}
              end
            >
              Archive
            </NavLink>
          </li>
          <li>
            <p
              onClick={modalHandler}
              style={{ color: "var(--clr-white)", cursor: "pointer" }}
            >
              New Hire
            </p>
          </li>
        </ul>
      </nav>
      <InsightsBar />
      <Modal open={showModal} onCancel={modalHandler} footer={null} centered>
        <h3>New Hire</h3>
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
      <Routes>
        <Route index element={<Home />} />
        <Route path="archive" element={<PreEmploymentDeletedList />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default Hire;
