import React from "react";
import { BiIdCard } from "react-icons/bi";
import { FaList } from "react-icons/fa";

import {
  MdCancelPresentation,
  MdMoney,
  MdOutlineFactCheck,
  MdPendingActions,
} from "react-icons/md";
import { NavLink } from "react-router-dom";

function IDCardDropdown({ showDropdown }) {
  return showDropdown ? (
    <ul className="performance__navlinks dropdown_links mt-3 px-0">
      <li>
        <NavLink
          to={"pending-id-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MdPendingActions title="Pending request" />

          <span className="sidebar_text">Pending request</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"rejected-id-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MdCancelPresentation title="Rejected request" />

          <span className="sidebar_text">Rejected request</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"approved-id-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MdOutlineFactCheck title="Approved request" />

          <span className="sidebar_text">Approved request</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"all-id-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <FaList title="All request" />

          <span className="sidebar_text">All request</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"print-id-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <BiIdCard title="Print ID Card" />

          <span className="sidebar_text">Print ID Card</span>
        </NavLink>
      </li>
    </ul>
  ) : null;
}

export default IDCardDropdown;
