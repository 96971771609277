import React from "react";
import "../../styles/Performance.css";
import Sidebar from "./Sidebar";
import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import { Outlet } from "react-router-dom";
import { useUserCleanUp, useUsersNotification } from "../../util/hooks";

const Performance = () => {
  useUsersNotification();
  useUserCleanUp();

  return (
    <AdminDashboardLayout sidebar={<Sidebar />}>
        <Outlet />
    </AdminDashboardLayout>
  );
};

export default Performance;
