import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function PerformanceReports() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Performance Management"
          module_class="performance-header"
          module_name={
            childPath === "teamannualleave_hr"
              ? "All Annual Leave Plan"
              : childPath === "requestforemployee_hr"
              ? "Request for Employee"
              : "Reports"
          }
          module_description="Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
          module_img="/assets/landing-page/Payroll managementpng.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <div className="app__section py-4 mt-3">
            <h6>Reports</h6>
            {/* <Outlet /> */}
          </div>
        </div>
      </main>
    </>
  );
}

export default PerformanceReports;
