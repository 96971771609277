import { useState, useEffect, useRef, useCallback } from "react";
import { Table, Space, Button, Popconfirm, Drawer, Select, Input } from "antd";
import {
  useGetUserProfile,
  useGetPerformanceKPITargets,
  useGetSystemDepartments,
  useGetCascadeByTarget,
} from "../../../../util/usershookActions";
import { updateCascade, deleteCascade } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import NoContent from "../../../ui/NoContent";

import { useLocation } from "react-router-dom";

const CascadeTable = ({ cascadeTableData, refetch }) => {
  const location = useLocation();

  console.log(location.pathname);
  const groupedData = {};

  // Group the data by department
  cascadeTableData?.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Goal",
      dataIndex: "goal",
    },
    {
      title: "Objectives",
      dataIndex: "objective",
    },
    {
      title: "KPIs",
      dataIndex: "kpi",
    },
    {
      title: "Staff",
      dataIndex: "employee",
    },
    {
      title: "Department Target (unit)",
      dataIndex: "target",
      key: "target",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Individual Target (unit)",
      dataIndex: "individual_target",
      key: "individual_target",
      render: (text, record) => (
        <span>
          {record.individual_target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
    },
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      render: (_, record) => (
        <EachAction selectedCascade={record} pathname={location.pathname} refetch={refetch} />
      ),
    },
  ];

  return (
    <div className="performance_table">
      <Table
        columns={columns}
        dataSource={transformedData}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

function EachAction({ selectedCascade, pathname, refetch }) {
  const [enabled, setEnabled] = useState(true);
  const [cascadeByTargetEnabled, setCascadeByTargetEnabled] = useState(false);

  const [departmentId, setDepartmentId] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [targetsDataState, setTargetsDataState] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [targetAvailableWeight, setTargetAvailableWeight] = useState(null);
  const [exceedsMax, setExceedsMax] = useState(false);

  const openEdit = useRef(false);

  const [deleteCascadeIsLoading, setDeleteCascadeIsLoading] = useState(false);
  const [updateCascadeIsLoading, setUpdateCascadeIsLoading] = useState(false);

  const [cascades, setCascades] = useState([]);

  const dispatch = useDispatch();

  const [editCascadeTableData, setEditCascadeTableData] = useState(null);

  const { data: userProfile } = useGetUserProfile(enabled, setEnabled);

  const { data: systemsDepartments } = useGetSystemDepartments(
    enabled,
    setEnabled
  );

  const {
    data: cascadeByTarget,
    isLoading: isCascadeByTargetLoading,
    refetch: refetchCascadeByTarget,
  } = useGetCascadeByTarget(
    cascadeByTargetEnabled,
    setCascadeByTargetEnabled,
    targetId,
    departmentId
  );

  const { data: targetsData, refetch: refetchTargets } =
    useGetPerformanceKPITargets(enabled, setEnabled);

  useEffect(() => {
    if (targetId) {
      setTotalWeight(
        targetsDataState.filter((target) => target.id === targetId)?.[0]?.weight
      );
    } else {
      setTargetAvailableWeight(null);
      setTotalWeight(0);
    }
  }, [targetId, targetsDataState]);

  useEffect(() => {
    if (targetId !== null && departmentId !== null) {
      setCascadeByTargetEnabled(true);
    } else {
      setCascadeByTargetEnabled(false);
    }
  }, [targetId, departmentId]);

  useEffect(() => {
    if (targetId === null) {
      setCascadeByTargetEnabled(false);
    }
  }, [targetId]);

  const handleOpenEditDrawer = () => {
    if (!openEdit.current) {
      openEdit.current = true;
      setDepartmentId(selectedCascade?.department_id);
      setTargetId(selectedCascade?.target_id);
    } else {
      openEdit.current = false;
      setDepartmentId(null);
      setTargetId(null);
      setCascadeByTargetEnabled(false);
    }
  };

  const handleDeleteCascade = (e) => {
    e.preventDefault();

    setDeleteCascadeIsLoading(true);
    deleteCascade(dispatch, selectedCascade?.id).then(() => {
      setDeleteCascadeIsLoading(false);
      refetch();
    });
  };

  const cancelDeleteCascade = () => {};

  const handleUpdateCascade = () => {
    const creds = {
      target_id: targetId,
      cascades,
    };
    setUpdateCascadeIsLoading(true);

    updateCascade(dispatch, creds).then(() => {
      setUpdateCascadeIsLoading(false);
      refetchCascadeByTarget();
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleDepartmentChange = (value) => {
    setDepartmentId(value);
    setTargetId(null);
  };

  useEffect(() => {
    if (targetsData) {
      setTargetsDataState(targetsData);
    }
  }, [targetsData, departmentId]);

  useEffect(() => {
    if (cascadeByTarget?.payload.length) {
      setCascades(
        cascadeByTarget?.payload?.map((cascade) => ({
          id: cascade.id,
          individual_target: cascade.individual_target,
          weight: cascade.weight,
        }))
      );
    }
  }, [cascadeByTarget]);

  useEffect(() => {
    if (cascades.length) {
      const usedWeight = cascades.reduce(
        (total, cascade) => total + Number(cascade?.weight),
        0
      );
      setTargetAvailableWeight(totalWeight - usedWeight);
    } else {
      setTargetAvailableWeight(null);
    }
  }, [cascades, totalWeight]);

  console.log("targetId", targetId);

  const handleIndividualCascadeChange = useCallback(
    (id, value) =>
      setCascades(
        cascades.map((cascade) =>
          cascade.id === id ? { ...cascade, individual_target: value } : cascade
        )
      ),
    [cascades]
  );

  const handleWeightChange = useCallback(
    (id, newWeight) => {
      const currentCascade = cascades.find((cascade) => cascade.id === id);
      const maxAllowedWeight = targetAvailableWeight + currentCascade.weight;

      if (newWeight <= maxAllowedWeight) {
        const updatedCascades = cascades.map((cascade) =>
          cascade.id === id
            ? { ...cascade, weight: Number(newWeight) }
            : cascade
        );
        setCascades(updatedCascades);

        const usedWeight = updatedCascades.reduce(
          (total, cascade) => total + Number(cascade.weight),
          0
        );
        setTargetAvailableWeight(totalWeight - usedWeight);
        setExceedsMax(false);
      } else {
        setExceedsMax(true);
        console.log("Weight exceeds available weight");
      }
    },
    [cascades, targetAvailableWeight, totalWeight]
  );

  useEffect(() => {
    if (cascadeByTarget && cascades.length > 0) {
      const new_array = cascadeByTarget?.payload?.map((cascade, index) => {
        return {
          key: cascade.id,
          sn: index + 1,
          employee: cascade.employee,
          goal: cascade.goal,
          objective: cascade.objective,
          department_target: `${cascade.department_target} (${cascade.unit})`,
          individual_target: (
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                size="large"
                id={`target-${cascade.id}`}
                defaultValue={cascade.individual_target}
                placeholder="Set target here"
                style={{
                  cursor: "pointer",
                  border: "1px solid black",
                  borderColor: "#d9d9d9",
                  minWidth: "6rem",
                }}
                bordered
                onChange={(e) => {
                  handleIndividualCascadeChange(cascade.id, e.target.value);
                }}
              />
              <Button
                type="primary"
                size="large"
                style={{
                  width: "fit-content",
                  backgroundColor: "var(--clr-primary)",
                  color: "white",
                  cursor: "auto",
                }}
              >
                {cascade.unit}
              </Button>
            </Space.Compact>
          ),
          weight: (
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                type="number"
                size="large"
                id={`target-${cascade.id}`}
                defaultValue={cascade.weight}
                placeholder="Set weight here"
                min={1}
                max={targetAvailableWeight}
                style={{
                  cursor: "pointer",
                  border: "1px solid black",
                  borderColor: "#d9d9d9",
                }}
                bordered
                onChange={(e) => {
                  handleWeightChange(cascade.id, Number(e.target.value));
                }}
                onWheel={(e) => e.target.blur()}
              />
              <Button
                type="primary"
                size="large"
                style={{
                  width: "fit-content",
                  backgroundColor: "var(--clr-primary)",
                  color: "white",
                  cursor: "auto",
                }}
              >
                %
              </Button>
            </Space.Compact>
          ),
          cascade,
        };
      });
      setEditCascadeTableData(new_array);
    }
  }, [
    cascadeByTarget,
    cascades,
    handleIndividualCascadeChange,
    handleWeightChange,
    targetAvailableWeight,
  ]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      align: "center",
      width: "4rem",
    },
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "Department Target (Unit)",
      dataIndex: "department_target",
      align: "center",
    },
    {
      title: "Individual Target",
      align: "center",
      dataIndex: "individual_target",
      width: "14rem",
      fixed: "right",
    },
    {
      title: "Weight",
      align: "center",
      dataIndex: "weight",
      width: "8rem",
      fixed: "right",
    },
  ];

  return (
    <>
      <Space>
        <Button
          type="primary"
          shape="round"
          className=""
          onClick={handleOpenEditDrawer}
        >
          Edit
        </Button>
        <Popconfirm
          title="Delete Cascade"
          description="Are you sure you want to delete this Cascade?"
          onConfirm={handleDeleteCascade}
          onCancel={cancelDeleteCascade}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            className=""
            style={{
              backgroundColor: "red",
            }}
            loading={deleteCascadeIsLoading}
          >
            Delete
          </Button>
        </Popconfirm>
      </Space>

      <Drawer
        title={
          <div className="d-flex flex-column gap-1">
            <h5>Edit Cascade</h5>
            <p>
              Cascading targets can be edited and updated provided the
              respective weightage isn't exceeded
            </p>
          </div>
        }
        width={800}
        onClose={handleOpenEditDrawer}
        open={openEdit.current}
        closeIcon={false}
        extra={
          <Space>
            <Button danger size="large" onClick={handleOpenEditDrawer}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        className="cascade__edit__drawer"
      >
        <div className="flex flex-col gap-4 w-100">
          <div className="d-flex gap-2">
            <div className="w-100">
              {pathname === "/modules/performance/planning/cascade-team" && userProfile?.data?.personal?.is_hod?.length ? (
                userProfile?.data?.personal?.is_hod?.length > 1 ? (
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">Department Category</label>
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select a department"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      style={{
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                      value={departmentId}
                      onChange={handleDepartmentChange}
                      options={userProfile?.data?.personal?.is_hod?.map(
                        (department) => ({
                          value: department?.id,
                          label: department?.name,
                        })
                      )}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">Department Category</label>
                    <Input
                      size="large"
                      defaultValue={
                        userProfile?.data?.personal?.is_hod[0]?.name
                      }
                      className="disabled__input"
                      style={{
                        border: "1px solid black",
                        borderRadius: "10px",
                        padding: "0.535rem",
                      }}
                      disabled
                    />
                  </div>
                )
              ) : (
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">Department Category</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a department"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                    value={departmentId}
                    onChange={handleDepartmentChange}
                    options={systemsDepartments?.payload?.map((department) => ({
                      value: department?.id,
                      label: department?.name,
                    }))}
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-2 w-100">
              <label className="fw-bold fs-6">Targets</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a target"
                optionFilterProp="children"
                value={targetId}
                onChange={(value, option) => {
                  setTargetId(value);
                  setTargetAvailableWeight(option?.items?.available_weight);
                }}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                  width: "100%",
                }}
                options={targetsDataState
                  ?.filter((target) => target.department_id === departmentId)
                  .map((target) => ({
                    value: target?.id,
                    label: `${target?.target} ${target.unit}`,
                    items: target,
                  }))}
              />
            </div>
            {targetId && cascades.length && targetAvailableWeight !== null ? (
              <div className="d-flex align-items-end">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    minWidth: "10rem",
                    cursor: "default",
                  }}
                >
                  {`Available Weight: ${targetAvailableWeight}%`}
                </Button>
              </div>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-4 mt-5">
            {isCascadeByTargetLoading ? (
              <SkeletonUI />
            ) : cascadeByTarget && targetId ? (
              <div className="performance_table">
                <h5>Edit Cascades</h5>
                {exceedsMax && (
                  <span className="text-danger d-flex justify-content-end my-2">
                    {`You cannot cascade more than the available weight: ${targetAvailableWeight}%`}
                  </span>
                )}
                <Table
                  columns={columns}
                  dataSource={editCascadeTableData}
                  bordered
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />

                <div className="d-flex align-items-start mt-3">
                  <Button
                    type="primary"
                    size="large"
                    style={{
                      backgroundColor: "var(--clr-primary)",
                    }}
                    disabled={exceedsMax}
                    loading={updateCascadeIsLoading}
                    onClick={handleUpdateCascade}
                  >
                    Update
                  </Button>
                </div>
              </div>
            ) : (
              <NoContent />
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default CascadeTable;
