import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Switch,
  Input,
  InputNumber,
  Form,
  Radio,
  Checkbox,
  Select,
  message,
  Upload,
  Button,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { TextArea } = Input;

const ExternalSurveyRenderer = () => {
  const [isLoading, setIsLoading] = useState();
  const [survey, setSurvey] = useState();
  const [isAnonymous, setIsAnonymous] = useState(false);

  const { survey_id, survey_title } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `http://localhost:8000/survey/${survey_id}`
        );
        const data = await response.json();
        setSurvey(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [survey_id]);

  console.log("survey", survey);

  const handleSetQuestionItemsAnswer = (id, answer) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      questionsItems: prevSurvey.questionsItems.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            answer: answer,
          };
        }
        return question;
      }),
    }));
  };

  const handleSetRespondentAnswer = (id, answer) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      respondentInfo: prevSurvey.respondentInfo.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            answer: answer,
          };
        }
        return question;
      }),
    }));
  };

  const handleSetPersonalInfo = (key, value) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      personalInfo: {
        ...prevSurvey.personalInfo,
        [key]: value,
      },
    }));
  };

  const sharedInputProps = (props) => {
    return {
      style: {
        maxWidth: "100%",
        border: "1px solid black",
        borderRadius: props === "short" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = (maxNoOfFiles, maxSize, questionID) => ({
    name: "file",
    multiple: true,
    beforeUpload(file, fileList) {
      let isLessThanMaxSize = file.size / 1024 / 1024 < maxSize;
      if (!isLessThanMaxSize) {
        message.error(`File must be smaller than ${maxSize} MB!`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange(info) {
      if (info.fileList.length > maxNoOfFiles) {
        message.error(`You can only upload ${maxNoOfFiles} files at a time!`);
        // Remove the extra file from the list
        info.fileList.pop();
      }
      handleSetQuestionItemsAnswer(questionID, info.fileList);
    },
    onDownload(file) {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    },
  });

  const filledRequestForm = async (filledForm) => {
    const response = await fetch(`http://localhost:8000/responses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filledForm),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleSubmitFilledRequestForm = async () => {
    try {
      const result = await filledRequestForm({
        ...survey,
        id: `${Date.now()}`,
        isAnonymous: isAnonymous,
        templateId: survey_id,
      });
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
      }}
    >
      <div
        className=""
        style={{
          width: "80%",
          maxWidth: "80rem",
          margin: "0 auto",
          borderTop: "5rem solid var(--clr-primary)",
          borderLeft: "4rem solid transparent",
          borderRight: "4rem solid transparent",
          height: 0,
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{
            borderBottom: "0.2rem solid black",
            padding: "0.5rem 0",
            height: "7rem",
          }}
        >
          <h3 className="fw-semibold">{survey?.surveyName}</h3>
          <img
            src={"/assets/companyblue.jpg"}
            alt="Organization Logo"
            width={200}
            height={100}
          />
        </div>

        <Form
          name="validate_other"
          onFinish={handleSubmitFilledRequestForm}
          className="d-flex flex-column gap-3 pb-5"
        >
          {survey?.canBeAnonymous && (
            <div
              className=""
              style={{
                background: "rgba(217, 217, 217, 0.23)",
                padding: "1rem",
                margin: "1rem 0",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <h5 className="fw-semibold">Make Your Survey Anonymous</h5>
              <p className="">
                This will hide Email Address, IP Address, Link parameters and
                any other piece of information that might allow us identify a
                respondent
              </p>
              <div className="d-flex align-items-end gap-2">
                <Switch
                  defaultChecked={isAnonymous}
                  onChange={(checked) => {
                    setIsAnonymous(checked);
                  }}
                />
                <span>Anonymous Survey</span>
              </div>
            </div>
          )}

          {!isAnonymous && (
            <div className="mt-5">
              <h4>Personal Information:</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Form.Item
                  name="personalInfo.name"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please type your name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Name"
                    size="large"
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                    value={survey?.personalInfo?.name}
                    onChange={(e) => {
                      handleSetPersonalInfo("name", e.target.value);
                    }}
                  />
                </Form.Item>
                <div className="email__phone__external__survey">
                  <Form.Item
                    name="personalInfo.email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please type your email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      size="large"
                      type="email"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                      }}
                      value={survey?.personalInfo?.email}
                      onChange={(e) => {
                        handleSetPersonalInfo("email", e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="personalInfo.phone"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please type your phone number",
                        type: "number",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Phone Number"
                      size="large"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                      }}
                      value={survey?.personalInfo?.phone}
                      onChange={(value) => {
                        handleSetPersonalInfo("phone", value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}

          <div
            className="mt-5 d-flex flex-column gap-3 "
            style={{
              borderBottom: "0.1rem solid black",
            }}
          >
            {survey?.respondentInfo.map((question, index) => (
              <div key={index}>
                <div className="d-flex flex-column gap-1">
                  <h6 className="">
                    {question.questionTitle}
                    {question.required && (
                      <span className="text-danger">*</span>
                    )}
                  </h6>
                  {question?.isQuestionDescription && (
                    <small>{question.questionDescription}</small>
                  )}
                </div>

                <Form.Item
                  name={`${question.title}${question.id}3`}
                  hasFeedback
                  rules={[
                    {
                      required: question?.required,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleSetRespondentAnswer(question.id, e.target.value);
                    }}
                    className="d-flex gap-3"
                    value={question?.answer}
                  >
                    {question?.questionOptions.map((option, index) => (
                      <Radio key={index} value={option} className=" fs-6">
                        {option}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            ))}
          </div>

          <div className="mt-5 d-flex flex-column gap-3 ">
            <h4>Survey Questions:</h4>
            {survey?.questionsItems.map((question, index) => (
              <div key={index}>
                <div className="d-flex flex-column gap-1">
                  <h6 className="">
                    {question.questionTitle}
                    {question.required && (
                      <span className="text-danger">*</span>
                    )}
                  </h6>
                  {question?.isQuestionDescription && (
                    <small>{question.questionDescription}</small>
                  )}
                </div>

                <div>
                  {question?.questionType === "radio" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          handleSetQuestionItemsAnswer(
                            question.id,
                            e.target.value
                          );
                        }}
                        className="d-flex gap-3"
                        value={question?.answer}
                      >
                        {question?.questionOptions.map((option, index) => (
                          <Radio key={index} value={option} className=" fs-6">
                            {option}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  ) : question?.questionType === "checkbox" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        onChange={(e) => {
                          handleSetQuestionItemsAnswer(question.id, e);
                        }}
                        className="d-flex gap-3"
                        value={question?.answer}
                      >
                        {question?.questionOptions.map((option, index) => (
                          <Checkbox
                            key={index}
                            value={option}
                            className=" fs-6"
                          >
                            {option}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  ) : question?.questionType === "paragraph" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please input your answer",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder={"Paragraph Answer Here"}
                        value={question?.answer}
                        onChange={(e) => {
                          handleSetQuestionItemsAnswer(
                            question.id,
                            e.target.value
                          );
                        }}
                        {...sharedInputProps("paragraph")}
                      />
                    </Form.Item>
                  ) : question?.questionType === "short" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please input your answer",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"Short Answer Here"}
                        value={question?.answer}
                        onChange={(e) => {
                          handleSetQuestionItemsAnswer(
                            question.id,
                            e.target.value
                          );
                        }}
                        {...sharedInputProps("short")}
                      />
                    </Form.Item>
                  ) : question?.questionType === "dropdown" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      hasFeedback
                      rules={[
                        {
                          required: question?.required,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        size="large"
                        placeholder="Select an option"
                        optionFilterProp="children"
                        value={question?.answer}
                        onChange={(value) => {
                          handleSetQuestionItemsAnswer(question.id, value);
                        }}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        {...sharedInputProps("short")}
                        options={
                          question?.questionOptions &&
                          question?.questionOptions.length > 0
                            ? question?.questionOptions.map(
                                (option, index) => ({
                                  label: option,
                                  value: option,
                                })
                              )
                            : []
                        }
                      />
                    </Form.Item>
                  ) : question?.questionType === "file" ? (
                    <Form.Item
                      name={`${question.title}${question.id}`}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: question?.required,
                          message: "Please upload a file",
                        },
                      ]}
                    >
                      <Dragger
                        {...props(
                          question?.file?.maximum_no_of_files,
                          question?.file?.file_size,
                          question?.id
                        )}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload. Strictly
                          prohibited from uploading company data or other banned
                          files.
                        </p>
                      </Dragger>
                    </Form.Item>
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{
              backgroundColor: "var(--clr-primary)",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ExternalSurveyRenderer;
