import React from "react";
import { Routes, Route } from "react-router-dom";
import Overview from "./Overview";
import Setup from "./setup";
import Employment from "./hire-stage/Employment";
import Hire from "./hire-stage";
import Sidebar from "./Sidebar";
import OnboardingStage from "./onboarding-stage";
import { useUsersNotification } from "../../util/hooks";
import PreEmploymentDeletedList from "./hire-stage/PreEmploymentDeletedList";
import Induction from "./induction-stage";

import "../../styles/onboarding.css";
import PageNotFound from "../PageNotFound";
import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import CanAccess from "../hoc/CanAccess";

function Onboarding() {
  useUsersNotification();
  return (
    <AdminDashboardLayout sidebar={<Sidebar />}>
      <main>
        <Routes>
          <Route
            index
            element={
              <CanAccess
                can_access={"view-induction-onboarding-overview"}
                isRoute={false}
                navigateTo={"/onboarding/pre-employment"}
              >
                <Overview />
              </CanAccess>
            }
          />
          <Route path="pre-employment/*" element={<Hire />} />
          <Route
            path="pre-employment/delete-list"
            element={<PreEmploymentDeletedList />}
          />
          <Route path="employment" element={<Employment />} />
          <Route
            path="induction/*"
            element={
              <CanAccess
                can_access={"view-induction-onboarding-overview"}
                isRoute={false}
                navigateTo={"/onboarding/pre-employment"}
              >
                <Induction />
              </CanAccess>
            }
          />
          <Route
            path="onboarding/*"
            element={
              <CanAccess
                can_access={"view-induction-onboarding-overview"}
                isRoute={false}
                navigateTo={"/onboarding/pre-employment"}
              >
                <OnboardingStage />
              </CanAccess>
            }
          />
          <Route path="uploads/*" element={<Setup />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
    </AdminDashboardLayout>
  );
}

export default Onboarding;
