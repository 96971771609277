import React from "react";

const Department = ({ hireProfile }) => {
  return (
    <div className="my-5">
      <h4>Department & Job Role</h4>
      <div className="row mt-3">
        <div className="col-6 ">
          <label>Department Name:</label>
          <p className="h6 mb-0">{hireProfile?.payload?.department?.name}</p>
        </div>
        <div className="col-6 ">
          <label>Head of Department:</label>
          {hireProfile?.payload?.department?.hod_id ? (
            hireProfile?.payload?.department?.hod_id.map((id) => (
              <p key={id.id} className="mb-0">
                {id?.fullname}
              </p>
            ))
          ) : (
            <p className="h6 mb-0">N/A</p>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 ">
          <label>Line Manager:</label>
          {hireProfile?.payload?.department?.line_manager_id ? (
            hireProfile?.payload?.department?.line_manager_id.map((id) => (
              <p key={id.id} className="mb-0">
                {id?.fullname}
              </p>
            ))
          ) : (
            <p className="h6 mb-0">N/A</p>
          )}
        </div>
        <div className="col-6 ">
          <label>Designation role:</label>
          <p className="h6 mb-0">
            {hireProfile?.payload?.designation_role?.role || "N/A"}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label>Employment type:</label>
          <p className="h6">{hireProfile?.payload?.employment_type || "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default Department;
