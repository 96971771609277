"use client";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import store from "./redux/store";

import "./reset.css";
import "./index.css";
import "./styles/ant-reset.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { request } from "./util/requestMethods";

import { storage_type } from "./config";

const { REACT_APP_TOKEN: TOKEN, REACT_APP_AUTH_DETAILS: AUTH_DETAILS } =
  process.env;

const client = new QueryClient();

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err = error.response.data.message;

    if (err?.toLowerCase() === "unauthenticated.") {
      const resMessage = {
        status: "error",
        // message: err,
        message: "Session has expired. Sign in again to continue",
      };
      storage_type.removeItem(TOKEN);
      storage_type.removeItem(AUTH_DETAILS);

      store.dispatch({ type: "LOGOUT_USER", payload: resMessage });
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={client}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
);
