import React, { useState } from "react";
import { NavLink } from "react-router-dom";
function CreateRequestDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"new-request-template"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Create New Request</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"new-request-template/edit-request"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Edit Request</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(CreateRequestDropdown);
