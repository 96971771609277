import styles from "../../styles/Modal.module.css";

export default function Modal({ onClose, children, className }) {
  return (
    <div className={styles.backdrop} onClick={onClose}>
      <div
        className={`${styles.modal} ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

