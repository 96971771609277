import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function HRMDepartment() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Human Resource Information System"
          module_class="performance-header"
          module_name={
            childPath === "upload-departments"
              ? "Upload Department"
              : "Department List"
          }
          module_description="Manage the requests of employees for modifications to their data."
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default HRMDepartment;
