import styles from "../../../styles/employee/Profile.module.css";
import ContactDetails from "./ContactDetails";
import { MdPermContactCalendar } from "react-icons/md";
import { user_profile } from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import ProfilePicture from "./ProfilePicture";
import ManageSignature from "./ManageSignature";
import Biodata from "./Biodata";
import EmploymentInfo from "./EmploymentInfo";

function EditProfile() {
  const userProfile = useShallowEqualSelector(user_profile);

  const { personal, contact, job_role } = userProfile;

  // console.log("red", personal);

  return (
    <div className="pb-5 ps-3">
      <div className={`${styles.title} greeting_2`}>
        <div>
          <h4>Edit Profile</h4>
          <p>Review and update your profile information</p>
        </div>
        <MdPermContactCalendar />
      </div>
      <div className={styles.editContent}>
        <ProfilePicture data={userProfile?.personal} />
        <div className="cards mt-5">
          <div className="row">
            <div className="col-md-6">
              <Biodata personal={personal} />
            </div>
            <div className="col-md-6">
              <EmploymentInfo
                personal={personal}
                id={userProfile?.id}
                job_role={job_role}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <ContactDetails contactData={contact} />
            </div>
            <div className="col-md-6">
              <ManageSignature
                signature={personal?.signature}
                first_name={personal?.first_name}
                last_name={personal?.last_name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
