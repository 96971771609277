import React, { useEffect, useState } from "react";
import styles from "../../styles/HomeLayout.module.css";
import navStyles from "../../styles/Navbar.module.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Dropdown } from "antd";

const HomeLayoutRightSidebar = () => {
  const [pathName, setPathName] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    if (path) {
      setPathName(path);
    } else {
      setPathName("");
    }
  }, [location]);

  //card drop down
  const idcardItems = [
    {
      key: "1",
      label: (
        <div className={navStyles.dropleave}>
          <section className={navStyles.leftDropLeave}>
            <img src="/assets/id-card.png"></img>
          </section>
          <section className={navStyles.middleDropLeave}>
            <article>
              <h4>Action</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"id-card/new-request"}
                    className="leave__dropdown"
                  >
                    New Request
                  </NavLink>
                </li>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"id-card/replacement-request"}
                    className="leave__dropdown"
                  >
                    Replacement Request
                  </NavLink>
                </li>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"id-card/my-id-card"}
                    className="leave__dropdown"
                  >
                    My Id card
                  </NavLink>
                </li>
              </ul>
            </article>
            <article>
              <h4>History</h4>
              <ul className={navStyles.actionLists}>
                <li className={navStyles.actionLists}>
                  <NavLink
                    to={"id-card/request-history"}
                    className="leave__dropdown"
                  >
                    Request history
                  </NavLink>
                </li>
              </ul>
            </article>
          </section>
          <section className={navStyles.rightDropLeave}>
            <article></article>
            <article className={navStyles.faq}>FAQ</article>
          </section>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.rightsidebar}>
      <Dropdown menu={{ items: idcardItems }} className="dropdown__action">
        <div
          className={`${styles.externalLinks} ${
            pathName === "id-card" ? styles.selected : null
          }`}
        >
          <img src="/assets/landing-page/IDCard.png" alt="idCard" />
          <NavLink
            to={"#"}
            className={pathName === "id-card" ? styles.selected : null}
          >
            ID card
          </NavLink>
        </div>
      </Dropdown>

      {/* <a className={styles.externalLinks} href="#">
        <img src="/assets/landing-page/selfservice.png" alt="selfservice" />
        Self Service
      </a>
      <a className={styles.externalLinks} href="#">
        <img src="/assets/landing-page/project.png" alt="project" />
        Project
      </a> */}
      {/* <a className={styles.externalLinks} href="#">
        <img src="/assets/landing-page/mylearning.png" alt="mylearning" />
        My Learning
      </a> */}
      <Link className={styles.externalLinks} to={"/tool/todo"}>
        <img src="/assets/landing-page/task.png" alt="task" />
        Todo
      </Link>
      {/* <a className={styles.externalLinks} href="#">
        <img src="/assets/landing-page/policy.png" alt="policy" />
        Policy & Procedures
      </a> */}
    </div>
  );
};

export default HomeLayoutRightSidebar;
