import {
  Button,
  DatePicker,
  Select,
  Space,
  Drawer,
  Card,
  Popconfirm,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  useGetCompanies,
  useGetUserBatchPaymentTemplate,
} from "../../../util/usershookActions";
import {
  loadBatchPayment,
  createBatchPayment,
  deleteBatchPayment,
} from "../../../redux/users/userActions";
import { LoadingOutlined } from "@ant-design/icons";
import { formatMoney } from "../../../util/helpers";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";

function BatchPayment() {
  useUserCleanUp();
  const [pay_month, setPayMonth] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [batch_template, setBatchTemplate] = useState(null);
  const [loadBatchPaymentLoading, setLoadBatchPaymentLoading] = useState(false);
  const [createBatchPaymentLoading, setCreateBatchPaymentLoading] =
    useState(false);
  const back_errors = useShallowEqualSelector(user_errors);
  const dispatch = useDispatch();

  const { data: com_data, isLoading: companyLoading } = useGetCompanies(
    enabled,
    setEnabled
  );

  useEffect(() => {
    if (batch_template) {
      const new_array = batch_template?.map((template, index) => {
        return {
          key: template.id,
          user_name: template.user_name,
          company: template.company,
          branch: template.branch,
          ...template.payroll_items.reduce((acc, item) => {
            acc[item.payroll_item_name] = item.amount;
            return acc;
          }, {}),
          paye: template.paye,
          pension_employee: template.pension_employee,
          pension_employer: template.pension_employer,
          pay_month: template.pay_month,
          template,
        };
      });
      setTableData(new_array);
    }
  }, [batch_template]);

  const handleLoadBatchPayment = () => {
    // e.preventDefault();
    const creds = {
      pay_month,
      company_id,
    };
    setLoadBatchPaymentLoading(true);
    loadBatchPayment(dispatch, creds).then((res) => {
      setLoadBatchPaymentLoading(false);
      if (res?.status === "success") {
        setBatchTemplate(res.payload);
        console.log("resLoad", res);
      }
    });
  };

  const handleCreateBatchPayment = (e) => {
    e.preventDefault();
    const creds = {
      pay_month,
      company_id,
    };
    setCreateBatchPaymentLoading(true);
    createBatchPayment(dispatch, creds).then((res) => {
      setCreateBatchPaymentLoading(false);
      if (res?.status === "success") {
        console.log("resCreate", res);
      }
    });
  };

  const columns = [
    {
      title: "Employee name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (value) => (value === null ? "N/A" : value),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (value) => (value === null ? "N/A" : value),
    },
    {
      title: "Pay Month",
      dataIndex: "pay_month",
      key: "pay_month",
      render: (value) => (value === null ? "N/A" : value),
    },
    {
      title: "Payroll Items",
      children: batch_template?.[0]?.payroll_items.map((item) => ({
        title: item.payroll_item_name,
        dataIndex: item.payroll_item_name,
        align: "center",
        render: (value) => (value === null ? "N/A" : formatMoney(value)),
      })),
    },
    {
      title: "Paye",
      dataIndex: "paye",
      key: "paye",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employee",
      dataIndex: "pension_employee",
      key: "pension_employee",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employer",
      dataIndex: "pension_employer",
      key: "pension_employer",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <EachRecord
          record={record}
          handleLoadBatchPayment={handleLoadBatchPayment}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 min-vh-100">
          <section className="app__section py-4">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                padding: "1rem",
              }}
            >
              <div>
                <h5 className="mb-0">Batch Payment</h5>
                <small className=" d-none d-sm-block">
                  Create batch payment or load previously created batch payment
                  for the selected company.
                </small>
              </div>
            </div>

            <div className="w-75 bg-light mx-auto p-3 my-5 shadow-sm">
              <form className="">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                      <label htmlFor="">Pay Month</label>

                      <small className="text-danger">
                        {back_errors.pay_month && back_errors.pay_month[0]}
                      </small>
                    </div>
                    <DatePicker
                      onChange={(date, dateString) =>
                        setPayMonth(date.format("YYYY-MM"))
                      }
                      picker="month"
                      className="w-100 batch-month"
                      value={pay_month ? dayjs(pay_month, "YYYY-MM") : null}
                      size="large"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                      <label htmlFor="">Company</label>

                      <small className="text-danger">
                        {back_errors.company_id && back_errors.company_id[0]}
                      </small>
                    </div>
                    <div className="d-flex gap-2">
                      <Select
                        showSearch
                        className="w-100 batch-select"
                        id="company"
                        filterOption={(input, option) => {
                          return (option?.value ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        value={company_id}
                        onChange={(value) => setCompany_id(value)}
                        options={com_data?.payload[0]?.map((company) => ({
                          value: company?.id,
                          label: company?.name,
                        }))}
                        placeholder="company"
                      />
                      {companyLoading ? <LoadingOutlined /> : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex justify-content-end  ">
                    <Space>
                      <Button
                        onClick={handleCreateBatchPayment}
                        loading={createBatchPaymentLoading}
                        className="myBtn d-flex align-items-center"
                        disabled={!pay_month || !company_id}
                      >
                        Create
                      </Button>
                      <Button
                        onClick={handleLoadBatchPayment}
                        loading={loadBatchPaymentLoading}
                        disabled={!pay_month || !company_id}
                        className="myBtn d-flex align-items-center"
                      >
                        Load
                      </Button>
                    </Space>
                  </div>
                </div>
              </form>
            </div>

            <div
              style={{
                padding: "1rem",
              }}
              className="row"
            >
              {loadBatchPaymentLoading ? (
                <SkeletonUI number={2} />
              ) : batch_template?.length ? (
                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: "768px" }}
                />
              ) : (
                <NoContent
                  width={"400px"}
                  message={"Load a batch payment"}
                  illustration={"/assets/selected.png"}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function EachRecord({ record, handleLoadBatchPayment }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (record.template.user_id && open) {
      setEnabled(true);
    }
  }, [open, record.template.user_id]);

  const {
    data: user_batch_payment_template,
    isLoading: user_batch_payment_template_loading,
  } = useGetUserBatchPaymentTemplate(enabled, setEnabled, record.template.id);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const formatSubKey = (subKey) => {
    if (subKey.includes("_")) {
      return subKey
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    } else {
      // If the string is not camel cased, just capitalize it
      return subKey.charAt(0).toUpperCase() + subKey.slice(1);
    }
  };

  const confirm = () => {
    setLoading(true);
    deleteBatchPayment(dispatch, record?.template?.id).then((res) => {
      setLoading(false);
      handleLoadBatchPayment();
    });
  };

  return (
    <Space wrap>
      <Space>
        <Button
          onClick={showDrawer}
          className="rounded p-1 px-2 border border-1 text-white me-1 bg-success"
        >
          View
        </Button>

        <Popconfirm
          title="Delete wage item settings"
          description="Are you sure want to perform this action?"
          onConfirm={confirm}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="rounded p-1 px-2 border border-1 text-white me-1 bg-danger"
            loading={loading}
          >
            Delete
          </Button>
        </Popconfirm>
      </Space>

      <Drawer
        title={record.user_name}
        onClose={onClose}
        open={open}
        destroyOnClose={true}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        width={"90%"}
      >
        <div>
          <div className="row">
            <h5>
              Payment Template for {record.user_name} &nbsp;{" "}
              <i>{record.pay_month}</i>{" "}
            </h5>

            <div className="d-flex gap-2  flex-wrap row  mx-auto justify-content-between shadow-lg mt-3 p-3 bg-light">
              {user_batch_payment_template_loading && <SkeletonUI number={2} />}
              {user_batch_payment_template?.payload
                ? Object.keys(user_batch_payment_template?.payload).map((key) =>
                    key === "user_name" || key === "pay_month" ? null : (
                      <Card
                        title={key}
                        key={key}
                        className="mb-2"
                        bordered={false}
                        style={{
                          width: "49%",
                        }}
                      >
                        {Array.isArray(
                          user_batch_payment_template?.payload[key]
                        ) &&
                        user_batch_payment_template?.payload[key].length > 0 ? (
                          user_batch_payment_template?.payload[key].map(
                            (item) => (
                              <div className="d-flex justify-content-between flex-nowrap">
                                <p>{item.payroll_item_name}:</p>
                                <p>{formatMoney(item.amount)} (NGN)</p>
                              </div>
                            )
                          )
                        ) : Object.keys(
                            user_batch_payment_template?.payload[key]
                          ).length > 0 ? (
                          Object.keys(
                            user_batch_payment_template?.payload[key]
                          ).map((subKey) => (
                            <div className="d-flex justify-content-between flex-nowrap">
                              <p>{formatSubKey(subKey)}:</p>
                              <p>
                                {formatMoney(
                                  user_batch_payment_template?.payload[key][
                                    subKey
                                  ]
                                )}{" "}
                                (NGN)
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No item available</p>
                        )}
                      </Card>
                    )
                  )
                : null}
            </div>
          </div>
        </div>
      </Drawer>
    </Space>
  );
}

export default BatchPayment;
