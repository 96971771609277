import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import DashboardCard from "../../dashboard/DashboardCard";
import { FaUserTie, FaLock } from "react-icons/fa";
import { Button } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { user_errors } from "../../../util/selectors/userSelectors";
import { updateUserPersonalInfo } from "../../../redux/users/userActions";
import options from "../../../util/util.json";
import { convertToCapitalize } from "../../../util/helpers";
import Warning from "../../ui/Warning";

function EmploymentInfo({ personal, id, job_role }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const back_errors = useShallowEqualSelector(user_errors);

  const { employment } = options;
  console.log({ personal });

  // console.log({ personal });

  let init_details;

  if (personal && Object.keys(personal).length) {
    init_details = { ...personal };
  } else {
    init_details = {
      employee_id: "",
      employment_type: "",
      employment_date: "",
      designation: "",
      department: "",
    };
  }

  const callbackFn = () => {
    setSaveSpinner(true);
    const dataToSubmit = {
      employee_id: values.employee_id,
      employment_type: values.employment_type,
      employment_date: values.employment_date,
      job_role: values.designation,
      department: values.department,
      user_id: id,
      title: personal.title,
      first_name: personal.first_name,
      last_name: personal.last_name,
    };
    updateUserPersonalInfo(dispatch, dataToSubmit).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        clearForm();
        setEditMode(!editMode);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="p-3 rounded">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Employment Information</h5>
        <FaLock className="h6" />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Employee Number</label>
            <p>{personal?.employee_id || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Employment Type</label>
            <p>{convertToCapitalize(personal?.employment_type) || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Employment/Resumption Date</label>
            <p>{personal?.employment_date || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>Designation</label>
            <p>{convertToCapitalize(job_role?.job_role?.role) || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Department</label>
            <p>{convertToCapitalize(personal?.department?.name) || "N/A"}</p>
          </div>

          <div className="col-md-4">
            <label>Company</label>
            <p>{convertToCapitalize(personal?.company) || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>Branch</label>
            <p>{convertToCapitalize(personal?.branch) || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard title={"Edit Employment Information"} icon={<FaUserTie />}>
      <Warning />
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="employee_id">Employee Number</label>
            <small className="text-danger">
              {errors.employee_id ||
                (back_errors.employee_id && back_errors.employee_id[0])}
            </small>
            <input
              type="text"
              name="employee_id"
              id="employee_id"
              onChange={handleChange}
              value={values?.employee_id}
              readOnly
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="employment_type">Employment Type</label>
            <small className="text-danger">
              {errors.employment_type ||
                (back_errors.employment_type && back_errors.employment_type[0])}
            </small>
            <select
              name="employment_type"
              id="employment_type"
              onChange={handleChange}
              value={values.employment_type}
            >
              <option value="">--</option>
              {employment.type.map((emp_type) => (
                <option key={emp_type.id} value={emp_type.key}>
                  {emp_type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="employment_date">Employed On</label>
            <small className="text-danger">
              {errors.employment_date ||
                (back_errors.employment_date && back_errors.employment_date[0])}
            </small>
            <input
              type="date"
              name="employment_date"
              id="employment_date"
              onChange={handleChange}
              value={values.employment_date}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="designation">Designation</label>
            <small className="text-danger">
              {errors.job_role ||
                (back_errors.job_role && back_errors.job_role[0])}
            </small>
            <input
              type="text"
              name="designation"
              id="designation"
              onChange={handleChange}
              value={values.designation}
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="department">Department</label>
            <small className="text-danger">
              {errors.department ||
                (back_errors.department && back_errors.department[0])}
            </small>
            <input
              type="text"
              name="department"
              id="department"
              onChange={handleChange}
              value={values.department}
            />
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger "
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn" loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}

export default EmploymentInfo;
