import React, { useEffect, useState } from "react";
import DashboardCard from "./DashboardCard";
import { FaUsers } from "react-icons/fa";
import { Skeleton, Modal } from "antd";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { colorArray } from "../../util/helpers";
import NoContent from "../ui/NoContent";

function TeamMembers({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pie_label, setPieLabel] = useState([]);
  const [pie_data, setPieData] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  Chart.register(...registerables);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const dataPie = {
    labels: pie_label,
    datasets: [
      {
        data: pie_data,
        backgroundColor: colorArray,
      },
    ],
  };
  const imageStyle = {
    width: 50,
    height: 50,
    borderRadius: "50%",
    objectFit: "contain",
  };

  // console.log({ data });

  useEffect(() => {
    if (data?.chart?.length) {
      setPieLabel(Object.keys(data.chart));
      setPieData(Object.values(data.chart));
    }
  }, [data]);

  return (
    <div className="mb-3">
      <DashboardCard
        title={"Team Members"}
        icon={<FaUsers />}
        team={data?.members}
        showModal={showModal}
      >
        <>
          <div className="container">
            {data.members?.length ? (
              <div className="row">
                <div className="col-md-12 team-members">
                  {data.members
                    .sort((a, b) => {
                      let fa = a.first_name.toLowerCase(),
                        fb = b.first_name.toLowerCase();

                      if (fa < fb) {
                        return -1;
                      }
                      if (fa > fb) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item) => (
                      <div
                        key={item.id}
                        className="d-flex align-items-center gap-3 mb-3 team-member"
                      >
                        <img
                          style={imageStyle}
                          src={
                            item.avatar || item.gender === "Male"
                              ? "/assets/man-placeholder.png"
                              : "/assets/woman-placeholder.png"
                          }
                          alt={item.first_name}
                        />
                        <div>
                          <h4 className="mb-0">
                            {item.first_name} {item.last_name}
                          </h4>
                          <p>{item.designation || "N/A"}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <NoContent
                illustration="/assets/team.jpg"
                width="150"
                message={"No team member available"}
              />
            )}
          </div>

          {isModalOpen ? (
            <Modal
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              maskClosable={false}
              width="763px"
              className="over"
            >
              <>
                <div>
                  <h5 className="birthday__title">Team members</h5>
                  <div className="team__modal__body">
                    <div className="row">
                      <div
                        className={data?.members?.length ? "col-8" : "col-12"}
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                        }}
                      >
                        <small className="team__body_title">
                          The list shows your team members and there state of
                          residence, grade levels
                        </small>

                        <table className="table mt-4 team" id="team">
                          <tbody>
                            {data?.members?.length ? (
                              data.members.map((item) => (
                                <tr key={item.id}>
                                  {/* <th scope="row">1</th> */}
                                  <td>
                                    {" "}
                                    <div className="d-flex align-items-center gap-2">
                                      <img
                                        src={
                                          item.avatar ||
                                          "/assets/man-placeholder.png"
                                        }
                                        alt=""
                                        className="team__img"
                                        width={34}
                                      />
                                      <div className="team__name">
                                        {item?.fullname || "N/A"}
                                        <span>2yrs 1 month</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p className="team__fullname">
                                      {item?.employment_type || "N/A"}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="team__place">Lagos</p>
                                  </td>
                                  <td>
                                    <p className="team__grade">
                                      {item?.job_role?.grade?.name || "N/A"}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="team__step">
                                      {" "}
                                      {item?.job_role?.step?.name || "N/A"}
                                    </p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <NoContent message="You have no team member(s) available" />
                            )}
                          </tbody>
                        </table>
                      </div>

                      {Object.keys(data?.chart).length ? (
                        <div className="col-4">
                          <h6>
                            Gender <br />
                            distribution
                          </h6>
                          <Pie options={options} data={dataPie} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            </Modal>
          ) : null}
        </>
      </DashboardCard>
    </div>
  );
}

export default TeamMembers;
