import React, { useEffect, useState, useMemo } from "react";
import { Button, Space, Table, Input, Pagination, Modal } from "antd";

import { useGetReportPending } from "../../../util/usershookActions";
import { useUserCleanUp } from "../../../util/hooks";

import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import { AiOutlineSearch } from "react-icons/ai";

import HocHeader from "../../ui/HocHeader";
import { resovleIDCardAction } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

function PendingReportRequest() {
  useUserCleanUp();
  const dispatch = useDispatch();
  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
      render: (value) => {
        return value ? value : "N/A";
      },
      ...getColumnSearchProps("employee_id", "Employee ID"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Attachment",
      dataIndex: "police_report",
      key: "police_report",
      render: (value) => {
        return <EachAttachment value={value} />;
      },
    },
    {
      title: "Reason for request",
      dataIndex: "reason_for_request",
      key: "reason_for_request",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Date of Loss",
      dataIndex: "date_of_loss",
      key: "date_of_loss",
      render: (value) => {
        return value ? value : "N/A";
      },
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <EachAction record={record} performAction={performAction} />
      ),
    },
  ];

  const [enabled_req, setEnabledReq] = useState(true);
  const [page_req, setPageReq] = useState(1);
  const [req_show, setReqShow] = useState([]);

  const {
    data: req,
    isLoading,
    refetch,
  } = useGetReportPending(enabled_req, setEnabledReq, page_req);

  const req_data = useMemo(() => req?.payload?.data, [req]);
  console.log({ req_show });

  useEffect(() => {
    if (req_data?.length) {
      const new_arry = req_data.map((re, ind) => {
        return {
          ...re,
          key: re.user_id,
          index: ind + 1,
        };
      });

      setReqShow(new_arry);
    } else {
      setReqShow([]);
    }
  }, [req_data]);

  function handlePaginationEmp(page) {
    setPageReq(page);
    setEnabledReq(true);
  }

  function performAction(creds) {
    return resovleIDCardAction(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        refetch();
        setReqShow(
          req_show.filter((item) => parseInt(item.id) !== parseInt(creds.id))
        );
      }
    });
  }

  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Pending Report"
          module_title="(ID) Identity Card Management"
          module_description="lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip"
        />
      </div>
      <div className="container-fluid py-3 padding_3rem">
        <section className="app__section mt-3 py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available pending reports</h5>
              {/* <small className="w-75 d-none d-sm-block">
                Every employee belongs to at least one job role/grade. You can
                add and view all job roles with other details like gross salary
                range, title, benefits and category.
              </small> */}
            </div>
            {/* <Link to={"add"}>
            <p className="performance__bagde2">Add Job Role/Grade</p>
          </Link> */}
          </div>
          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center">
              <div className="circle__bagde fw-bold">{req_show.length}</div>
              <h6 className="mb-0">
                {" "}
                {req_show.length > 1 ? "Reports" : "Report"}
              </h6>
            </div>
          </div>

          <div
            className="row"
            style={{
              padding: "1rem",
            }}
          >
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : req_show.length ? (
              <>
                <Table
                  columns={columns}
                  dataSource={req_show}
                  bordered
                  scroll={{ x: "768px" }}
                  pagination={false}
                />

                <div className="mt-3 d-flex justify-content-end">
                  {req?.payload?.meta?.last_page > 1 ? (
                    <Pagination
                      total={req?.payload?.meta?.last_page}
                      pageSize={1}
                      onChange={handlePaginationEmp}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <NoContent
                width={"400px"}
                message="No pending report available"
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
}

function EachAction({ record, performAction }) {
  const [loading_approve, setLoadingApprove] = useState(false);

  function confirmRequestApprove(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Resolve ID Card Request",
      content: "Do you want to perform this action?",
      onOk: approveIDCard,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function approveIDCard() {
    // e.preventDefault();
    setLoadingApprove(true);

    const creds = {
      action: "resolve",
      id: record.id,
    };

    performAction(creds).then(() => {
      setLoadingApprove(false);
    });
  }

  return (
    <Space wrap>
      <Button
        type="primary"
        onClick={confirmRequestApprove}
        loading={loading_approve}
      >
        Resolve
      </Button>
    </Space>
  );
}

function EachAttachment({ value }) {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  return (
    <>
      {value ? (
        <Button type="default" onClick={handleModal}>
          View
        </Button>
      ) : (
        "N/A"
      )}
      <Modal open={showModal} footer={null} onCancel={handleModal}>
        <img src={value} alt={"Attachments picture"} className="rpt_img" />
        {/* <div className="d-flex justify-content-end">
          <Button className="myBtn">Download</Button>
        </div> */}
      </Modal>
    </>
  );
}

export default PendingReportRequest;
