import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Table } from "antd";

const SurveyReportRenderer = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const dataPie = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "% of Votes",
        data: [12, 19, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const columns = [
    {
      title: "*",
      dataIndex: "title",
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Male",
      dataIndex: "male",
      key: "male",
    },
    {
      title: "Female",
      dataIndex: "female",
      key: "female",
    },
  ];

  const data = [
    {
      key: "1",
      title: "Yes",
      male: "20%",
      female: "80%",
    },
    {
      key: "2",
      title: "No",
      male: "40%",
      female: "60%",
    },
    {
      key: "3",
      title: "Maybe",
      male: "50%",
      female: "50%",
    },
  ];

  return (
    <>
      <div className="make__request__form__item">
        <div className="d-flex flex-column gap-1">
          <h5 className="">What is the best football club in the world?</h5>
        </div>

        <div className="d-flex align-items-center gap-5 w-100 mt-2">
          <div
            className=""
            style={{
              width: "fit-content",
            }}
          >
            <Pie options={options} data={dataPie} />
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </div>

      <div className="make__request__form__item">
        <div className="d-flex flex-column gap-1">
          <h5 className="">What is the best football club in the world?</h5>
        </div>

        <div className="d-flex align-items-center gap-5 w-100 mt-2">
          <div
            className=""
            style={{
              width: "fit-content",
            }}
          >
            <Pie options={options} data={dataPie} />
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </div>

      <div className="make__request__form__item">
        <div className="d-flex flex-column gap-1">
          <h5 className="">What is the best football club in the world?</h5>
        </div>

        <div className="d-flex align-items-center gap-5 w-100 mt-2">
          <div
            className=""
            style={{
              width: "fit-content",
            }}
          >
            <Pie options={options} data={dataPie} />
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyReportRenderer;
