import React, { useState } from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import RequestDropdown from "./dropDowns/requestDropDowns";
import LeavePlanDropdown from "./dropDowns/leavePlanDropdown";
import LeaveGroupDown from "./dropDowns/LeaveGroupDown";
import GradeDistributionDropdown from "./dropDowns/GradeDistributionDropdown";
import LeaveActionsDropdown from "./dropDowns/leaveActionsDropdown";
import CanAccess from "../hoc/CanAccess";
import { FaHome } from "react-icons/fa";
const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const parentPath = location.pathname.split("/")[3];

  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            to={"#"}
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>
        <ul className="performance__navlinks ">
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"leave-group"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Leave Type</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"leave-grade"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Leave Grade Distribution</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"approve-request"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Approve Request</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"request-hr"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Request HR</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"request-team"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Request Team</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"leave-actions"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Leave Actions</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"settings"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Settings</span>
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="performance__nav__subnav">
        {parentPath === "leave-group" ? (
          <LeaveGroupDown />
        ) : parentPath === "leave-grade" ? (
          <GradeDistributionDropdown />
        ) : parentPath === "request-hr" ? (
          <RequestDropdown />
        ) : parentPath === "request-team" ? (
          <LeavePlanDropdown />
        ) : parentPath === "leave-actions" ? (
          <LeaveActionsDropdown />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
