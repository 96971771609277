import React, { useState } from "react";
import DetachedModules from "../ui/DetachedModules";
import { canAccess } from "../../util/helpers";
import styles from "../../styles/subscriptions/Modules.module.css";
import {
  active_modules,
  detached_modules,
  company_details,
} from "../../util/selectors/userSelectors";
import {
  useGetActiveModules,
  useGetDetachedModules,
} from "../../util/usershookActions";
import { useShallowEqualSelector } from "../../util/hooks";
import ModuleCard from "../ui/ModuleCard";
import apps from "../../util/apps.json";

function Landing() {
  const [enabled_det_mod, setEnabledDeTMod] = useState(true);
  const [enabled_act_mod, setEnabledActMod] = useState(true);

  const activeModules = useShallowEqualSelector(active_modules);
  const detachedModules = useShallowEqualSelector(detached_modules);
  const companyDetails = useShallowEqualSelector(company_details);

  useGetActiveModules(enabled_act_mod, setEnabledActMod);
  useGetDetachedModules(enabled_det_mod, setEnabledDeTMod);

  return (
    <div className={styles.subscription}>
      <div>
        <div className={styles.modules}>
          <h3>Modules</h3>
          <p className="mb-3" style={{ fontSize: "1rem" }}>
            All modules subscribed to.
          </p>
          {activeModules && (
            <div className={styles.detachedCards}>
              {activeModules
                .sort((a, b) => (a.flag != null && b.flag == null ? -1 : 1))
                .map(({ module_on_subscription }) => (
                  <ModuleCard
                    key={module_on_subscription.id}
                    url={module_on_subscription?.path}
                    image={module_on_subscription.image}
                    name={module_on_subscription.name}
                    description={module_on_subscription.description}
                  />
                ))}
            </div>
          )}
        </div>

        <div className={styles.detachedModules}>
          <h3>Apps</h3>
          <p style={{ fontSize: "1rem" }} className="mb-3">
            Quick access to applications
          </p>
          <div className={styles.detachedCards}>
            {activeModules && (
            <ModuleCard
              url={"/permissions"}
              image={"/assets/permissions.png"}
              name={"Permissions Management"}
              description={"Permission Management"}
            />
            )}
            <ModuleCard
              key={"id-card"}
              url={"/identity-card-management"}
              image={
                "/assets/idcards.jpg"
              }
              name={"(ID) Identity Card Management"}
              description={"Manage your identity cards"}
            />
          </div>
        </div>
        {detachedModules && (
          <div className={styles.detachedModules}>
            <h3>Detached Modules</h3>
            <p style={{ fontSize: "1rem" }} className="mb-3">
              These are other modules you could get for your organization along
              with the current modules you are subscribed to.
            </p>
            <div className={styles.detachedCards}>
              {detachedModules.map(({ id, name, image, short_name, description }) => (
                <DetachedModules
                  key={id}
                  image={image}
                  name={name}
                  url={companyDetails.business.id}
                  short_name={short_name}
                  description={description}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Landing;
