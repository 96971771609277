import React, { useRef, useState } from "react";
import styles from "../../styles/DragDrop.module.css";
import { CloudUploadOutlined } from "@ant-design/icons";
import { BsFileExcelFill, BsFilePdfFill } from "react-icons/bs";

function FileUpload({ file, setFile, accept, supportedFormats, type }) {
  const [dragActive, setDragActive] = useState(false);
  const fileUploadRef = useRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const { files } = e.dataTransfer;
    setFile(files[0]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { files } = e.target;

    setFile(files[0]);
  };

  const buttonHandler = () => {
    fileUploadRef.current.click();
  };

  return (
    <form
      onDragEnter={handleDrag}
      className={
        file
          ? `${styles.fileUpload} ${styles.uploaded}`
          : `${styles.fileUpload}`
      }
    >
      <input
        type="file"
        id="input-file-upload"
        ref={fileUploadRef}
        accept={accept}
        onChange={handleChange}
      />
      <label
        htmlFor="input-file-upload"
        className={dragActive ? styles["drag-active"] : ""}
      >
        <div>
          <span className={styles.uploadIcon}>
            <CloudUploadOutlined />
          </span>
          <p>
            Drag & drop file or{" "}
            <span>
              <button
                type="button"
                onClick={buttonHandler}
                className={styles.uploadBtn}
              >
                Browse
              </button>
            </span>
          </p>
          <small>Supported formats: {supportedFormats}</small>
        </div>
      </label>
      {file && (
        <div className={styles.preview}>
          {type === "excel" ? (
            <BsFileExcelFill />
          ) : type === "pdf" ? (
            <BsFilePdfFill />
          ) : (
            <div className={styles.previewImage}>
              <img src={URL.createObjectURL(file)} alt="" />
            </div>
          )}
          <p className="mt-3">{file.name}</p>
        </div>
      )}
      {dragActive && (
        <div
          className={styles.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default FileUpload;
