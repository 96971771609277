import React from "react";
import ProfileCompletenessBar from "./ProfileCompletenessBar";

const ProfileCompleteness = ({ hireProfile }) => {
  return (
    <div className="my-5">
      <h4>Profile Completeness</h4>
      <div className="row mt-3">
        <div className="col-6 ">
          <label>Profile Completion:</label>
          <div className="d-flex align-items-center gap-2">
            <div className="progress mt-2">
              <div
                className="progressBar"
                style={{
                  width:
                    100 -
                    (100 -
                      hireProfile?.payload?.profile_completeness
                        ?.earned_point_percentage),
                }}
              ></div>
            </div>
            <p className="mb-0">
              {`${Math.floor(
                hireProfile?.payload?.profile_completeness
                  ?.earned_point_percentage
              )}% `}
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label>Earned Points:</label>
          <p className="h6">
            {hireProfile?.payload?.profile_completeness?.earned_point}
          </p>
        </div>
        <div className="col-6">
          <label>Total Attainable Points</label>
          <p className="h6">
            {hireProfile?.payload?.profile_completeness?.attainable_point}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        {hireProfile?.payload?.profile_completeness?.earned_weight_data.map(
          (data, index) => (
            <ProfileCompletenessBar
              key={index}
              title={Object.keys(data)[0]}
              maxValue={data?.actuals}
              value={data[Object.keys(data)[0]]}
              // color={"var(--clr-primary)"}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ProfileCompleteness;
