import HocHeader from "../../ui/HocHeader";
import SearchAddTask from "./../components/SearchAddTask";
import TasksList from "./../components/TasksList";
import { Flex } from "antd";

const Today = () => {
  return (
    <>
      <div>
        <HocHeader
          module_title="TODOS"
          module_class="performance-header"
          module_name={"Today"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="">
                <h3 className="h4">Today</h3>
                <p>See lists of all present day tasks and take actions</p>
              </div>

              <div className="d-flex gap-2">
                <p>Go Back</p>
              </div>
            </div>

            <Flex justify="space-between" vertical gap={"3rem"}>
              <SearchAddTask />
              <div
                className="d-flex flex-column gap-5"
                style={{
                  overflowY: "scroll",
                  height: "80vh",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",

                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <TasksList categoryTitle="overdue" addTask={false} />
                <TasksList categoryTitle="Today" addTask={true} />
              </div>
            </Flex>
          </div>
        </div>
      </main>
    </>
  );
};

export default Today;
