import React, { useState, useEffect } from "react";
import styles from "../../styles/Dashboard.module.css";
import Management from "./Management";
import Avatar from "react-avatar";
import { Button, Skeleton, Modal, Progress } from "antd";
import ProfileCompletenessBar from "../onboarding/newHire/ProfileCompletenessBar";
import { user_profile } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import { useGetUserLeaveGroup } from "../../util/usershookActions";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";
import { userAbilities } from "../../util/helpers";
import CanAccess from "../hoc/CanAccess";

function Sidebar({ data }) {
  const {
    personal,
    service_years,
    primary_line_manager,
    profile_completeness,
  } = data;
  const [enabled, setEnabled] = useState(false);
  const [userId, setUserId] = useState("");

  const profile = useShallowEqualSelector(user_profile);
  // console.log({ data });

  useEffect(() => {
    if (Object.keys(profile).length) {
      setUserId(profile?.id);
      setEnabled(true);
    }
  }, [profile]);

  const { data: leaveDistribution, isLoading } = useGetUserLeaveGroup(
    enabled,
    setEnabled,
    userId
  );
  // console.log({ leaveDistribution });

  const abilities = userAbilities();

  useEffect(() => {
    if (!abilities.includes("view-leave-breakdown")) {
      setEnabled(false);
    } else {
      if (userId) {
        setEnabled(true);
      }
    }
  }, [enabled, abilities, userId]);

  const [showDetails, setShowDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const detailsHandler = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.profile}>
        <div className={styles.profileImage}>
          {personal?.avatar ? (
            <img src={personal?.avatar} alt={personal?.fullname} />
          ) : personal?.first_name ? (
            <Avatar
              name={`${personal?.first_name} ${personal?.last_name || ""}`}
              size={300}
              round={"8"}
            />
          ) : (
            <Skeleton avatar />
          )}
        </div>
        <h5 className="mb-1 text-center">
          {personal?.first_name} {personal?.last_name}
        </h5>
        <p className="text-center h6">{personal?.employee_id || "N/A"}</p>
      </div>

      {data ? (
        <>
          <div className={styles.profile_completeness}>
            <h6>Profile Completeness</h6>
            <div className="row">
              <div className="col-12 ">
                <div className="d-flex align-items-center gap-2">
                  <div className="progress">
                    <div
                      className="progressBar"
                      style={{
                        width:
                          100 -
                          (100 - profile_completeness?.earned_point_percentage),
                      }}
                    ></div>
                  </div>
                  <p className="h6">
                    {`${Math.floor(
                      profile_completeness?.earned_point_percentage
                    )}% `}
                  </p>
                </div>
              </div>
            </div>

            <Button type="text" className="mt-2" onClick={detailsHandler}>
              {showDetails ? "Hide" : "See"} Details
            </Button>
            {showDetails && (
              <div className="container mt-3" style={{ textAlign: "start" }}>
                <div className="row">
                  <div className="col-6">
                    <label>Earned Points:</label>
                    <p className="h6">{profile_completeness?.earned_point}</p>
                  </div>
                  <div className="col-6">
                    <label>Attainable Points</label>
                    <p className="h6">
                      {profile_completeness?.attainable_point}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  {profile_completeness?.earned_weight_data.map(
                    (data, index) => (
                      <ProfileCompletenessBar
                        key={index}
                        title={Object.keys(data)[0]}
                        maxValue={data?.actuals}
                        value={data[Object.keys(data)[0]]}
                        dashboard={true}
                        // color={"var(--clr-primary)"}
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.workYears}>
            <p>At work for:</p>
            <p>{service_years?.period_of_stay}</p>
          </div>
          <div className={styles.breakdowns}>
            <div className={styles.breakdown}>
              <p>0/28</p>
              <p>ATTENDANCE</p>
            </div>

            {/* <CanAccess can_access={"view-leave-breakdown"} isRoute={false}> */}
            <div className={styles.breakdown} onClick={showModal}>
              <p className="pointer">0/48</p>
              <p className="pointer">LEAVES</p>
            </div>
            {/* </CanAccess> */}
            <div className={styles.breakdown}>
              <p>0</p>
              <p>AWARDS</p>
            </div>
          </div>
          <Management
            line={primary_line_manager}
            hod={personal?.head_of_department}
          />

          {isModalOpen ? (
            <Modal
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              maskClosable={false}
              width="763px"
              className="over"
            >
              <>
                <div>
                  <h5 className="birthday__title">Leave breakdown</h5>

                  <div className="leave__modal_body align-items-start">
                    <div className="leave__left my-auto">
                      <img src="/assets/leave.png" alt="" />
                    </div>
                    {/* <div className="leave__right">
                      <div className="progress_container">
                        <div className="progress_number">
                          <span>Sick leave</span>
                          <span className=""> 3 of 15 days</span>
                        </div>

                        <div className="progress_details gap-4">
                          <Progress
                            percent={25}
                            showInfo={false}
                            size={50}
                            id="ppp"
                          /> */}
                    {/* <span className="dgg">34</span> */}
                    {/* </div>
                      </div>
                      <div className="progress_container">
                        <div className="progress_number">
                          <span>Maternity leave / Paternity</span>
                          <span className=""> 1 of 2 days</span>
                        </div>

                        <div className="progress_details gap-4">
                          <Progress
                            percent={50}
                            showInfo={false}
                            size={50}
                            id="ppp"
                            status="exception"
                          /> */}
                    {/* <span className="dgg">1</span> */}
                    {/* </div>
                      </div>
                      <div className="progress_container">
                        <div className="progress_number">
                          <span>Study leave</span>
                          <span className="">15 of 15 days</span>
                        </div>

                        <div className="progress_details gap-4">
                          <Progress
                            percent={100}
                            showInfo={false}
                            size={50}
                            id="ppp"
                          /> */}
                    {/* <span className="dgg">15</span> */}
                    {/* </div>
                      </div>
                      <div className="progress_container">
                        <div className="progress_number">
                          <span>Depression leave</span>
                          <span className=""> 1 of 5 days</span>
                        </div>

                        <div className="progress_details gap-4">
                          <Progress
                            percent={5}
                            showInfo={false}
                            size={50}
                            id="ppp"
                          /> */}
                    {/* <span className="dgg">45</span> */}
                    {/* </div>
                      </div>
                    </div> */}

                    <CanAccess
                      can_access={"view-leave-breakdown"}
                      isRoute={false}
                    >
                      <div className="leave__right">
                        {isLoading ? (
                          <SkeletonUI number={1} />
                        ) : leaveDistribution ? (
                          leaveDistribution?.payload[0].map((leavetypes) => {
                            return (
                              <div className="progress_container">
                                <div className="progress_number">
                                  <span>
                                    {leavetypes.leave_group.title} leave
                                  </span>
                                  <span className="">
                                    {leavetypes.used} of {leavetypes.available}{" "}
                                    days
                                  </span>
                                </div>

                                <div className="progress_details gap-4">
                                  <Progress
                                    percent={Math.round(
                                      (leavetypes.used / leavetypes.available) *
                                        100
                                    )}
                                    showInfo={false}
                                    size={50}
                                    id="ppp"
                                    status={
                                      Math.round(
                                        (leavetypes.used /
                                          leavetypes.available) *
                                          100
                                      ) > 50 &&
                                      Math.round(
                                        (leavetypes.used /
                                          leavetypes.available) *
                                          100
                                      ) < 100
                                        ? "exception"
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <NoContent
                            message={"no leave distribution for this user"}
                            width={"300px"}
                          />
                        )}
                      </div>
                    </CanAccess>
                  </div>
                </div>
              </>
            </Modal>
          ) : null}
        </>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
}

export default Sidebar;
