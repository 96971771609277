import { useState, useEffect, useRef } from "react";

import {
  Input,
  Button,
  Flex,
  Table,
  Space,
  Select,
  Modal,
  Popconfirm,
} from "antd";

import { useDispatch } from "react-redux";
import {
  addBranch,
  editBranch,
  deleteBranch,
} from "../../../../redux/users/userActions";
import countries from "../../../../util/countries.json";
import stateData from "../../../../util/nigeria-state-and-lgas.json";
import {
  useGetCompanies,
  useGetBranches,
  useGetBranchDetails,
} from "../../../../util/usershookActions";
import Draggable from "react-draggable";
import NoContent from "../../../ui/NoContent";
import SkeletonUI from "../../../ui/Skeleton";

export default function AddBranch() {
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [branches, setBranches] = useState("");

  const [branchData, setBranchData] = useState({
    company_id: "",
    name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    rc_number: "",
    phone_number: "",
    mobile_number: "",
  });

  const dispatch = useDispatch();

  const { data, isLoading: companyLoading, refetch: refetchCompanies } = useGetCompanies(
    enabled,
    setEnabled
  );

  const {
    data: branchDetails,
    refetch,
    isLoading: branchDetailsLoading,
  } = useGetBranches(enabled, setEnabled);

  // console.log("branchdeet", branchDetails);

  useEffect(() => {
    if (branchDetails) {
      const new_array = branchDetails?.payload[0]?.map((branch, index) => {
        return {
          key: branch.id,
          branchName: branch.name,
          companyName: branch.company,
          usersCount: branch.userCount,
          branch,
          index,
        };
      });
      setBranches(new_array);
    }
  }, [branchDetails]);

  const columns = [
    {
      title: "Branches",
      dataIndex: "branchName",
      key: "branchName",
      render: (_, record) => {
        return <span>{record.branchName}</span>;
      },
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, record) => {
        return <span>{record.companyName}</span>;
      },
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (_, record) => {
        return <span>{record.usersCount}</span>;
      },
    },
    {
      title: "Actions",
      width: 150,
      fixed: "right",
      render: (_, records) => (
        <EachAction item={records} refetch={refetch} data={data} />
      ),
    },
  ];

  const handleCompanyChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      company_id: value,
    }));
  };

  const handleCountryChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      country: value,
    }));
  };

  const handleStateChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      state: value,
    }));
  };

  const handleBranchDataChange = (e) => {
    setBranchData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddBranch = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const creds = branchData;
    addBranch(dispatch, creds).then(() => {
      setIsLoading(false);
      setBranchData({
        company_id: "",
        name: "",
        country: "",
        state: "",
        city: "",
        address: "",
        rc_number: "",
        phone_number: "",
        mobile_number: "",
      });
      refetch();
      refetchCompanies();
    });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Flex wrap="wrap" vertical={true} gap={"large"}>
      <Flex
        wrap="wrap"
        vertical={true}
        gap={"large"}
        className="py-3 pb-5 border-bottom border-4"
      >
        <div className="">
          <h4>Add New Branch</h4>
          <p>Add branches under preffered company and update it</p>
        </div>

        <form onSubmit={handleAddBranch}>
          <Flex wrap="wrap" vertical gap={"large"} className="">
            <Flex wrap="wrap" gap={80}>
              <Flex gap={"large"} vertical>
                <Flex gap={2} vertical>
                  <label htmlFor="company" className="fw-bold fs-6">
                    Select Company
                  </label>

                  <Select
                    showSearch
                    id="company"
                    size="large"
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    filterOption={filterOption}
                    value={branchData?.company_id}
                    onChange={handleCompanyChange}
                    options={data?.payload[0]?.map((company) => ({
                      value: company?.id,
                      label: company?.name,
                    }))}
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="country" className="fw-bold fs-6">
                    Country
                  </label>

                  <Select
                    showSearch
                    id="country"
                    size="large"
                    filterOption={filterOption}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    onChange={handleCountryChange}
                    options={countries
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((country) => ({
                        value: country?.name,
                        label: country?.name,
                      }))}
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label
                    htmlFor="state_province_region"
                    className="fw-bold fs-6"
                  >
                    State/Province/Region
                  </label>

                  <Select
                    showSearch
                    size="large"
                    id="state_province_region"
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    filterOption={filterOption}
                    onChange={handleStateChange}
                    options={stateData.map((state) => ({
                      value: state.alias,
                      label: state.state.toUpperCase(),
                    }))}
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="rc_number" className="fw-bold fs-6">
                    RC Number
                  </label>
                  <Input
                    size="large"
                    id="rc_number"
                    name="rc_number"
                    value={branchData?.rc_number}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="mobile_number" className="fw-bold fs-6">
                    Mobile Number
                  </label>
                  <Input
                    size="large"
                    id="mobile_number"
                    name="mobile_number"
                    value={branchData?.mobile_number}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
              </Flex>
              <Flex gap={"large"} vertical>
                <Flex gap={2} vertical>
                  <label htmlFor="branch_name" className="fw-bold fs-6">
                    Branch Name
                  </label>
                  <Input
                    size="large"
                    id="branch_name"
                    name="name"
                    value={branchData?.name}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="city" className="fw-bold fs-6">
                    City
                  </label>
                  <Input
                    size="large"
                    id="city"
                    name="city"
                    value={branchData?.city}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="address" className="fw-bold fs-6">
                    Address
                  </label>
                  <Input
                    size="large"
                    id="address"
                    name="address"
                    value={branchData?.address}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
                <Flex gap={2} vertical>
                  <label htmlFor="phone_number" className="fw-bold fs-6">
                    Phone Number
                  </label>
                  <Input
                    size="large"
                    id="phone_number"
                    name="phone_number"
                    value={branchData?.phone_number}
                    onChange={handleBranchDataChange}
                    style={{
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                    required
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex justify="center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="px-4"
                loading={isLoading}
              >
                Add
              </Button>
            </Flex>
          </Flex>
        </form>
      </Flex>
      <div>
        {branchDetailsLoading ? (
          <SkeletonUI />
        ) : (
          <Table
            dataSource={branches}
            columns={columns}
            bordered
            pagination={false}
          />
        )}
      </div>
    </Flex>
  );
}

function EachAction({ item, refetch, data }) {
  const [enabled, setEnabled] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [branchId, setBranchId] = useState();
  const [isBranchModalOpen, setIsBranchModalOpen] = useState(false);
  const [branchEmployeesList, setBranchEmployeesList] = useState();

  const [branchData, setBranchData] = useState({
    company_id: "",
    name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    rc_number: "",
    phone_number: "",
    mobile_number: "",
  });

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const dispatch = useDispatch();

  const { data: singleBranchDetails, isLoading } = useGetBranchDetails(
    enabled,
    setEnabled,
    branchId
  );

  useEffect(() => {
    setBranchId(item.branch.id);
  }, [item]);

  useEffect(() => {
    if (isModalOpenEdit || isBranchModalOpen) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [isModalOpenEdit, isBranchModalOpen]);

  useEffect(() => {
    if (singleBranchDetails) {
      setBranchData({
        company_id: singleBranchDetails.payload[0]?.company_id,
        name: singleBranchDetails.payload[0]?.name,
        country: singleBranchDetails.payload[0]?.country,
        state: singleBranchDetails.payload[0]?.state,
        city: singleBranchDetails.payload[0]?.city,
        address: singleBranchDetails.payload[0]?.address,
        rc_number: singleBranchDetails.payload[0]?.rc_number,
        phone_number: singleBranchDetails.payload[0]?.phone_number,
        mobile_number: singleBranchDetails.payload[0]?.mobile_number,
      });
    }
  }, [singleBranchDetails]);

  // console.log("item", item);

  useEffect(() => {
    if (singleBranchDetails) {
      const new_array = singleBranchDetails?.payload[0]?.users?.map(
        (employee, index) => {
          return {
            key: index,
            sn: index + 1,
            name: employee.name,
            employeeID: employee.employeeId,
          };
        }
      );
      setBranchEmployeesList(new_array);
    }
  }, [singleBranchDetails]);

  // console.log("single", branchEmployeesList);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      render: (_, record) => {
        return <span>{record.sn}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: "Employee ID",
      dataIndex: "employeeID",
      key: "employeeID",
      render: (_, record) => {
        return <span>{record.employeeID}</span>;
      },
    },
  ];

  const handleCompanyChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      company_id: value,
    }));
  };

  const handleCountryChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      country: value,
    }));
  };

  const handleStateChange = (value) => {
    setBranchData((prev) => ({
      ...prev,
      state: value,
    }));
  };

  const handleBranchDataChange = (e) => {
    setBranchData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleModalOpenEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit);
  };

  const handleBranchModal = () => {
    setIsBranchModalOpen(!isBranchModalOpen);
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    setIsLoadingDelete(true);

    deleteBranch(dispatch, branchId).then((res) => {
      setIsLoadingDelete(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    setIsLoadingEdit(true);

    const creds = branchData;
    editBranch(dispatch, creds, branchId).then(() => {
      setIsLoadingEdit(false);
      setBranchData({
        company_id: "",
        name: "",
        country: "",
        state: "",
        city: "",
        address: "",
        rc_number: "",
        phone_number: "",
        mobile_number: "",
      });
      refetch();
      handleModalOpenEdit();
    });
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <Space>
        <Popconfirm
          title="Delete Company"
          description="Do you want to perform this action?"
          onConfirm={handleSubmitDelete}
          okText="Yes"
          cancelText="No"
          disabled={item?.index === 0}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            danger={true}
            disabled={item?.index === 0}
            loading={isLoadingDelete}
          >
            {isLoadingDelete ? "Deleting" : "Delete"}
          </Button>
        </Popconfirm>

        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={handleModalOpenEdit}
          disabled={item?.index === 0}
        >
          Edit
        </Button>

        <Button shape="round" size="large" onClick={handleBranchModal}>
          View
        </Button>
      </Space>

      <Modal
        open={isModalOpenEdit}
        footer={null}
        centered
        onCancel={handleModalOpenEdit}
      >
        <h5 className="card-title">Edit Branch</h5>

        <div className="row mt-3">
          <form onSubmit={handleSubmitEdit}>
            <Flex wrap="wrap" vertical gap={"large"} className="">
              <Flex wrap="wrap" justify="space-between" gap={"large"}>
                <Flex
                  gap={"large"}
                  vertical
                  style={{
                    width: "45%",
                  }}
                >
                  <Flex gap={2} vertical>
                    <label htmlFor="company" className="fw-bold fs-6">
                      Select Company
                    </label>

                    <Select
                      showSearch
                      id="company"
                      name="company_id"
                      filterOption={filterOption}
                      value={branchData?.company_id}
                      onChange={handleCompanyChange}
                      options={data?.payload[0]?.map((company) => ({
                        value: company?.id,
                        label: company?.name,
                      }))}
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="country" className="fw-bold fs-6">
                      Country
                    </label>

                    <Select
                      showSearch
                      id="country"
                      filterOption={filterOption}
                      value={branchData?.country}
                      onChange={handleCountryChange}
                      options={countries
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((country) => ({
                          value: country?.name,
                          label: country?.name,
                        }))}
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label
                      htmlFor="state_province_region"
                      className="fw-bold fs-6"
                    >
                      State/Province/Region
                    </label>

                    <Select
                      showSearch
                      id="state_province_region"
                      value={branchData?.state}
                      filterOption={filterOption}
                      onChange={handleStateChange}
                      options={stateData.map((state) => ({
                        value: state.alias,
                        label: state.state.toUpperCase(),
                      }))}
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="rc_number" className="fw-bold fs-6">
                      RC Number
                    </label>
                    <Input
                      size="large"
                      id="rc_number"
                      name="rc_number"
                      value={branchData?.rc_number}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="mobile_number" className="fw-bold fs-6">
                      Mobile Number
                    </label>
                    <Input
                      size="large"
                      id="mobile_number"
                      name="mobile_number"
                      value={branchData?.mobile_number}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                </Flex>
                <Flex
                  gap={"large"}
                  vertical
                  style={{
                    width: "45%",
                  }}
                >
                  <Flex gap={2} vertical>
                    <label htmlFor="branch_name" className="fw-bold fs-6">
                      Branch Name
                    </label>
                    <Input
                      size="large"
                      id="branch_name"
                      name="name"
                      value={branchData?.name}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="city" className="fw-bold fs-6">
                      City
                    </label>
                    <Input
                      size="large"
                      id="city"
                      name="city"
                      value={branchData?.city}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="address" className="fw-bold fs-6">
                      Address
                    </label>
                    <Input
                      size="large"
                      id="address"
                      name="address"
                      value={branchData?.address}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                  <Flex gap={2} vertical>
                    <label htmlFor="phone_number" className="fw-bold fs-6">
                      Phone Number
                    </label>
                    <Input
                      size="large"
                      id="phone_number"
                      name="phone_number"
                      value={branchData?.phone_number}
                      onChange={handleBranchDataChange}
                      required
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex justify="center">
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="px-4"
                  loading={isLoadingEdit}
                >
                  Add
                </Button>
              </Flex>
            </Flex>
          </form>
        </div>
      </Modal>

      <Modal
        open={isBranchModalOpen}
        footer={null}
        onCancel={handleBranchModal}
        centered
      >
        <h5 className="card-title">{singleBranchDetails?.payload[0]?.name}</h5>

        <div className="row mt-3">
          {isLoading ? (
            <SkeletonUI />
          ) : singleBranchDetails &&
            Object.keys(singleBranchDetails?.payload[0]?.users).length > 0 ? (
            <Table
              dataSource={branchEmployeesList}
              columns={columns}
              bordered
              pagination={false}
            />
          ) : (
            <NoContent message={"There are no employess under this branch"} />
          )}
        </div>
      </Modal>
    </>
  );
}
