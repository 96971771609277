import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Drawer,
  Input,
  Select,
  DatePicker,
  Space,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  authorizeOrUnautorizeAppraisal,
  updateAppraisal,
} from "../../../../redux/users/userActions";
import {
  useGetAppraisalsPending,
  useGetPerformancePlans,
  useGetAppraisalSettings,
} from "../../../../util/usershookActions";
import { useDispatch } from "react-redux";
import NoContent from "../../../ui/NoContent";
import SkeletonUI from "../../../ui/Skeleton";

dayjs.extend(utc);

const AppraisalPendingTable = ({
  appraisalPendingTableData,
  appraisalPendingRefetch,
  appraisalApprovedRefetch,
  isLoading,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys?.length > 0;

  useEffect(() => {
    if (appraisalPendingTableData) {
      const new_array = appraisalPendingTableData?.payload?.map(
        (appraisal, index) => {
          return {
            key: appraisal.id,
            appraisalName: appraisal.appraisal_name,
            planTitle: appraisal.plan_title,
            appraisalStartDate: dayjs
              .utc(appraisal.start_date)
              .format("YYYY-MM-DD"),
            appraisalEndDate: dayjs
              .utc(appraisal.end_date)
              .format("YYYY-MM-DD"),
            reviewPeriod: `${appraisal.review_period_date.from} - ${appraisal.review_period_date.to}`,
            reviewPeriodName: appraisal.review_period_date.review_period,
            // iteration: appraisal.iteration,
            status: appraisal.status,
            appraisal,
          };
        }
      );
      setTableData(new_array);
    }
  }, [appraisalPendingTableData]);

  const columns = [
    {
      title: "Appraisal Name",
      dataIndex: "appraisalName",
    },
    {
      title: "Plan Title",
      dataIndex: "planTitle",
    },
    {
      title: "Appraisal Start Date",
      dataIndex: "appraisalStartDate",
    },
    {
      title: "Appraisal End Date",
      dataIndex: "appraisalEndDate",
    },
    {
      title: "Review Period",
      dataIndex: "reviewPeriod",
    },
    {
      title: "Review Period Name",
      dataIndex: "reviewPeriodName",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, records) => <EachAction item={records} />,
    },
  ];

  const handleAuthorizeAppraisal = (e) => {
    e.preventDefault();
    setLoading(true);
    const creds = {
      appraisal_ids: selectedRowKeys,
      authorize: true,
    };
    authorizeOrUnautorizeAppraisal(dispatch, creds).then(() => {
      setLoading(false);
      setSelectedRowKeys([]);
      appraisalPendingRefetch();
      appraisalApprovedRefetch();
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonUI />
      ) : appraisalPendingTableData?.payload?.length > 0 ? (
        <div className="performance_table">
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: 1500 }}
          />

          <div className="d-flex justify-content-center mt-5">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={handleAuthorizeAppraisal}
              loading={loading}
              disabled={!hasSelected}
            >
              Authorize Selection
            </Button>
          </div>
        </div>
      ) : (
        <NoContent />
      )}
    </>
  );
};

function EachAction({ item }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  console.log("items", item);

  const [updateAppraisalLoading, setUpdateAppraisalLoading] = useState(false);
  const [enabled, setEnabled] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState(
    item?.appraisal?.review_period_date?.review_period
      ?.split(" ")
      .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
      .join("_")
  );
  const [creds, setCreds] = useState({
    appraisal_name: item?.appraisal?.appraisal_name,
    plan_id: item?.appraisal?.plan_id,
    start_date: dayjs(item?.appraisal?.start_date).format("DD-MM-YYYY"),
    end_date: dayjs(item?.appraisal?.end_date).format("DD-MM-YYYY"),
    notice_before_start: item?.appraisal?.notice_before_start,
    notice_before_end: item?.appraisal?.notice_before_end,
    review_period: {
      review_period: item?.appraisal?.review_period_date?.review_period,
      to: item?.appraisal?.review_period_date?.to,
      from: item?.appraisal?.review_period_date?.from,
    },
  });

  console.log("creds", creds);
  const dispatch = useDispatch();

  const {
    data: appraisalPendingTableData,
    refetch: appraisalPendingRefetch,
    isLoading: isLoadingPending,
  } = useGetAppraisalsPending(enabled, setEnabled);

  const { data: performancePlans } = useGetPerformancePlans(
    enabled,
    setEnabled
  );

  const { data: appraisalSettings } = useGetAppraisalSettings(
    enabled,
    setEnabled
  );

  const reviewPeriods = appraisalSettings?.payload?.review_period;
  console.log("reviewPeriods", reviewPeriods);

  if (selectedPeriod) {
    const rev = Object.entries(reviewPeriods)
      .filter(([period]) => period === selectedPeriod)
      .map(([, value]) => value);
    if (
      rev[0].to !== creds.review_period.to ||
      rev[0].from !== creds.review_period.from
    ) {
      setCreds((prevState) => ({
        ...prevState,
        review_period: {
          ...prevState.review_period,
          to: rev[0].to,
          from: rev[0].from,
        },
      }));
    }
  }

  const handleChange = (name, value) => {
    setCreds((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedPeriod(value);
    setCreds((prevState) => ({
      ...prevState,
      review_period: {
        ...prevState.review_period,
        review_period: value
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      },
    }));
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleUpdateAppraisal = (e) => {
    e.preventDefault();
    setUpdateAppraisalLoading(true);
    updateAppraisal(dispatch, creds, item?.appraisal?.id).then(() => {
      setUpdateAppraisalLoading(false);
      appraisalPendingRefetch();
    });
  };

  return (
    <>
      <div className="filter d-flex gap-1 flex-wrap">
        <Button
          className="d-flex justify-content-center text-white "
          shape="round"
          type="primary"
          size="medium"
          onClick={handleModalOpen}
        >
          View More
        </Button>
        <Button
          className="d-flex justify-content-center text-white bg-success"
          shape="round"
          type="primary"
          size="medium"
          onClick={handleEditModalOpen}
        >
          Edit
        </Button>
      </div>
      <Modal
        title={item.appraisal.appraisal_name}
        open={isModalOpen}
        onCancel={handleModalOpen}
        footer={null}
        centered={true}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <span className="fw-bold">Plan Title:</span>
            <span className="">{item.appraisal.plan_title}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Status:</span>
            <span className="">{item.appraisal.status}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Appraisal Start Date:</span>
            <span className="">
              {dayjs.utc(item.appraisal.start_date).format("YYYY-MM-DD")}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Appraisal End Date:</span>
            <span className="">
              {dayjs.utc(item.appraisal.end_date).format("YYYY-MM-DD")}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">
              No of Days for advance notice before START of appraisal:
            </span>
            <span className="">{item.appraisal.notice_before_start}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">
              No of Days for advance notice before END of appraisal:
            </span>
            <span className="">{item.appraisal.notice_before_end}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Review Period:</span>
            <span className="">
              {`${dayjs
                .utc(item.appraisal.review_period_date.from)
                .format("YYYY-MM-DD")} -
                ${dayjs
                  .utc(item.appraisal.review_period_date.to)
                  .format("YYYY-MM-DD")}`}
            </span>
          </div>
        </div>
      </Modal>

      <Drawer
        title={"Edit Template"}
        width={"60%"}
        onClose={handleEditModalOpen}
        open={isEditModalOpen}
        extra={
          <Space>
            <Button danger size="large" onClick={handleEditModalOpen}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        {item ? (
          <div>
            <form className="d-flex flex-column gap-2">
              <div className="create__appraisal__form">
                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Appraisal Name</label>
                  <Input
                    value={creds?.appraisal_name}
                    size="large"
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    onChange={(e) =>
                      handleChange("appraisal_name", e.target.value)
                    }
                  />
                </div>
                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Plan Title</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a title"
                    optionFilterProp="children"
                    value={creds?.plan_id}
                    onChange={(value) => handleChange("plan_id", value)}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={performancePlans?.payload?.map((plan) => ({
                      value: plan?.id,
                      label: plan?.name,
                    }))}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Review Period</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a period"
                    optionFilterProp="children"
                    value={selectedPeriod}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={
                      reviewPeriods &&
                      Object.keys(reviewPeriods).map((period) => ({
                        value: period,
                        label: period
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" "),
                      }))
                    }
                  />
                </div>

                <div className="d-flex flex-column gap-2 create__date">
                  <label className="fw-bold fs-6">Start Date</label>
                  <DatePicker
                    size="large"
                    value={
                      creds?.start_date
                        ? dayjs(creds?.start_date, "DD-MM-YYYY")
                        : null
                    }
                    onChange={(date, dateString) =>
                      handleChange("start_date", date.format("DD-MM-YYYY"))
                    }
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                <div className="d-flex gap-5 justify-content-center">
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">From</label>
                    <DatePicker
                      size="large"
                      value={
                        creds?.review_period.from
                          ? dayjs(creds?.review_period.from)
                          : null
                      }
                      disabled
                    />
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">To</label>{" "}
                    <DatePicker
                      size="large"
                      value={
                        creds?.review_period.to
                          ? dayjs(creds?.review_period.to)
                          : null
                      }
                      disabled
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-2 create__date">
                  <label className="fw-bold fs-6">End Date</label>
                  <DatePicker
                    size="large"
                    value={
                      creds?.end_date
                        ? dayjs(creds?.end_date, "DD-MM-YYYY")
                        : null
                    }
                    onChange={(date, dateString) =>
                      handleChange("end_date", date.format("DD-MM-YYYY"))
                    }
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">
                    No of Days for advance notice before START of appraisal
                  </label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select an option"
                    optionFilterProp="children"
                    value={creds?.notice_before_start}
                    onChange={(value) =>
                      handleChange("notice_before_start", value)
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={Array.from(
                      { length: 30 },
                      (_, index) => index + 1
                    ).map((notice_before_start) => ({
                      value: notice_before_start,
                      label: notice_before_start,
                    }))}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">
                    No of Days for advance notice before END of appraisal
                  </label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select an option"
                    optionFilterProp="children"
                    value={creds?.notice_before_end}
                    onChange={(value) =>
                      handleChange("notice_before_end", value)
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={Array.from(
                      { length: 30 },
                      (_, index) => index + 1
                    ).map((notice_before_end) => ({
                      value: notice_before_end,
                      label: notice_before_end,
                    }))}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                  }}
                  onClick={handleUpdateAppraisal}
                  loading={updateAppraisalLoading}
                  disabled={
                    Object.values(creds).some((value) => value === "") ||
                    creds.review_period.review_period === "" ||
                    creds.review_period.from === "" ||
                    creds.review_period.to === ""
                  }
                >
                  Update Appraisal
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <NoContent
            width={"400px"}
            message={"No Appraisal Found"}
            illustration={"/assets/selected.png"}
          />
        )}
      </Drawer>
    </>
  );
}

export default AppraisalPendingTable;
