import React from "react";
import { NavLink } from "react-router-dom";

function PayScaleDropdown() {
  return (
    <ul className="dropdown_links mt-3 px-0">
      <li>
        <NavLink
          to={"pay-grades"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">List grades </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-grades/create-step"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">List Steps/Bands </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-grades/list-pay-grade"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">List Pay Grades </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-grades/pay-grades-with-notches"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Pay Grades With Notches </span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          to={"pay-grades/pay-grades-without-notches"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Pay Grades Without Notches </span>
        </NavLink>
      </li> */}
    </ul>
  )
}

export default PayScaleDropdown;
