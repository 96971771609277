import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

function LeaveActions() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Leave Management"
          module_class="performance-header"
          module_name={
            childPath === "teamleaveplan"
              ? "Team Annual Leave Plan"
              : childPath === "leaveHistory"
              ? "My Leave History"
              : childPath === "teamAnnual"
              ? "My Team Leave History"
              : childPath === "annualLeave"
              ? "My Annual Leave Plan"
              : "New Leave Request Form"
          }
          module_img="/assets/landing-page/Leave Management.png"
          module_description="Manage and track employee leaves, including vacation, sick days, and other time-off requests within an organization's human resources or workforce management system."
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default LeaveActions;
