import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";
import {
  // useGetSystemSettingsUtils,
  useGetSystemRoleDesignation,
  useGetSystemDepartments,
  useGetCompanies,
} from "../../../../util/usershookActions";
import {
  department,
  designation_role,
  // settings_util,
} from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { useGetAllEmployee } from "../../../../util/preferenceshookActions";
import { all_groups } from "../../../../util/selectors/preferenceSelectors";
import { Select } from "antd";

function Designation({
  handleChange,
  values,
  errors,
  back_errors,
  onboarding,
}) {
  const [enabled_role, setEnabledRole] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [users, setUsers] = useState([]);
  const [enabled_dep, setEnabledDep] = useState(true);
  const [selectedBranch, setSelectedBranch] = useState();

  useGetSystemDepartments(enabled_dep, setEnabledDep);
  useGetAllEmployee(enabled, setEnabled, "all"); //need for employee

  useGetSystemRoleDesignation(enabled_role, setEnabledRole);
  const designation_roles = useShallowEqualSelector(designation_role);
  const sys_departments = useShallowEqualSelector(department);
  const all_users = useShallowEqualSelector(all_groups); // need

  const { data, isLoading: companyLoading } = useGetCompanies(
    enabled,
    setEnabled
  );

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  const handleSelect = (value, name) => {
    handleChange("_", true, name, value);
  };

  useEffect(() => {
    if (values.company_id) {
      const companyArray = data?.payload[0].find(
        (company) => company.id === values.company_id
      );
      setSelectedBranch(companyArray);
    }
  }, [values.company_id, data]);

  const err_text = "personal.0.";
  return (
    <div className={onboarding ? "col-md-12 mt-5" : "col-md-6 mt-5"}>
      <div className="card b0">
        <div className="card-body py-4">
          <h4 className={styles.subheading4}>Designation Information</h4>
          <div className={styles.hr}></div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="company" className="">
                    Company
                  </label>
                  <small className="text-danger">
                    {errors.company_id ||
                      (back_errors[`${err_text}company_id`] &&
                        back_errors[`${err_text}company_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  showSearch
                  id="company"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  value={values.company_id}
                  onChange={(value) => handleSelect(value, "company_id")}
                  options={data?.payload[0]?.map((company) => ({
                    value: company?.id,
                    label: company?.name,
                  }))}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="branch" className="">
                    Branch
                  </label>
                  <small className="text-danger">
                    {errors.branch_id ||
                      (back_errors[`${err_text}branch_id`] &&
                        back_errors[`${err_text}branch_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  showSearch
                  id="branch"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  value={values.branch_id}
                  onChange={(value) => handleSelect(value, "branch_id")}
                  options={selectedBranch?.branches?.map((branch) => ({
                    value: branch.id,
                    label: branch.name,
                  }))}
                />
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="branch">Branch</label>
                  <small className="text-danger">
                    {errors.branch ||
                      (back_errors[`${err_text}branch`] &&
                        back_errors[`${err_text}branch`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <input
                  type="text"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                  placeholder={"Branch Office"}
                />
              </div>
            </div> */}

            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="department">Department</label>
                  <small className="text-danger">
                    {errors.department ||
                      (back_errors[`${err_text}department`] &&
                        back_errors[`${err_text}department`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="department"
                  id="department"
                  value={values.department}
                  showSearch
                  onChange={(value) => handleSelect(value, "department")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {sys_departments?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* <div className="row"> */}
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="team_lead">Team Lead</label>
                  <small className="text-danger">
                    {errors.team_lead ||
                      (back_errors[`${err_text}team_lead`] &&
                        back_errors[`${err_text}team_lead`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="team_lead"
                  id="team_lead"
                  value={values.team_lead}
                  showSearch
                  onChange={(value) => handleSelect(value, "team_lead")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // console.log({ input, option });
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {users.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.fullname}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="primary_line_manager">
                    Primary Line Manager
                  </label>
                  <small className="text-danger">
                    {errors.primary_line_manager ||
                      (back_errors[`${err_text}primary_line_manager`] &&
                        back_errors[
                          `${err_text}primary_line_manager`
                        ][0]?.replace(err_text, " "))}
                  </small>
                </div>

                <Select
                  name="primary_line_manager"
                  id="primary_line_manager"
                  value={values.primary_line_manager}
                  showSearch
                  onChange={(value) =>
                    handleSelect(value, "primary_line_manager")
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {users.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.fullname}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="secondary_line_manager">
                    Secondary Line Manager
                  </label>
                  <small className="text-danger">
                    {errors.secondary_line_manager ||
                      (back_errors[`${err_text}secondary_line_manager`] &&
                        back_errors[
                          `${err_text}secondary_line_manager`
                        ][0]?.replace(err_text, " "))}
                  </small>
                </div>

                <Select
                  name="secondary_line_manager"
                  id="secondary_line_manager"
                  value={values.secondary_line_manager}
                  showSearch
                  onChange={(value) =>
                    handleSelect(value, "secondary_line_manager")
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      (option?.children ?? "")
                        // .toLowerCase()
                        .includes(input.toLowerCase())
                    );
                  }}
                >
                  <option value="">--</option>
                  {users.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.fullname}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Designation;
