import React, { useState } from "react";
import PrefillProfile from "./PrefillProfile";
import "../../../styles/onboarding.css";
import ProfileCompleteness from "./ProfileCompleteness";
import NoContent from "../../ui/NoContent";
import Department from "./Department";
import HireActivity from "./HireActivity";
import { Switch } from "antd";

const NewHireBasicProfile = ({ hireProfile }) => {
  // console.log(hireProfile?.payload["pre-fill-form"]);
  const [showPrefill, setShowPrefill] = useState(false);

  const handleToggle = () => {
    setShowPrefill(!showPrefill);
  };

  return (
    <div className="my-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-4">
          <h4 className="mb-0">
            New Hire's {!showPrefill ? "Profile" : "Prefill Details"}
          </h4>

          <div
            className="profile-badge"
            style={{
              backgroundColor: `${
                hireProfile?.payload?.stage === "INDUCTION"
                  ? "green"
                  : hireProfile?.payload?.stage === "ONBOARDING"
                  ? "purple"
                  : ""
              }`,
            }}
          >
            {hireProfile?.payload?.stage}
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <label style={{ fontWeight: `${showPrefill ? "" : 700}` }}>
            Profile Details
          </label>
          <Switch onChange={handleToggle} />

          <label style={{ fontWeight: `${showPrefill ? 700 : ""}` }}>
            Prefill Details
          </label>
        </div>
        {/* <Button className="myBtn" onClick={handleToggle}>
          Show {showPrefill ? "Profile" : "Prefill Form"}
        </Button> */}
      </div>
      {!showPrefill ? (
        <>
          <div className="row mt-3">
            <div className="col-6">
              <label>Fullname:</label>
              <p className="h6">{hireProfile?.payload?.name || "N/A"}</p>
            </div>
            <div className="col-6 ">
              <label>Email Address:</label>
              <p className="h6">{hireProfile?.payload?.email || "N/A"}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <label>Batch:</label>
              <p className="h6">{hireProfile?.payload?.batch || "N/A"}</p>
            </div>
            <div className="col-6 ">
              <label>Hire status:</label>
              <p
                className="h6"
                style={{
                  color:
                    hireProfile?.payload?.status === "PENDING"
                      ? "orange"
                      : "green",
                }}
              >
                {hireProfile?.payload?.status === "PENDING"
                  ? "Pending"
                  : "Completed"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 ">
              <label>Provisional offer:</label>
              <p
                className="h6"
                style={{
                  color:
                    hireProfile?.payload?.approval_status !== "APPROVE"
                      ? "orange"
                      : "green",
                }}
              >
                {hireProfile?.payload?.approval_status !== "APPROVE"
                  ? "Pending"
                  : "Accepted"}
              </p>
            </div>
          </div>
        </>
      ) : hireProfile?.payload["pre-fill-form"] ? (
        <PrefillProfile hireProfile={hireProfile} />
      ) : (
        <NoContent message={"Applicant has not submitted their prefill form"} />
      )}

      <ProfileCompleteness hireProfile={hireProfile} />
      <Department hireProfile={hireProfile} />
      <HireActivity hireProfile={hireProfile} />
    </div>
  );
};

export default NewHireBasicProfile;
