import React, { useEffect } from "react";
import DashboardLayout from "../ui/AdminDashboardLayout";
import { Routes, Route } from "react-router-dom";
import CreateGroup from "./CreateGroup";
import AssignGroup from "./AssignGroup";
import ViewGroups from "./ViewGroups";
import Group from "./Group";
import { usePreferenceNotification } from "../../util/hooks";
import EditGroup from "./EditGroup";
import AnimationLayout from "../ui/AnimationLayout";
import { convertToCapitalize, getURLSubdomain } from "../../util/helpers";
import Welcome from "./Welcome";
import CanAccess from "../hoc/CanAccess";
import { useMediaQuery } from "react-responsive";
import PermissionsDashboardSidebar from "./PermissionsDashboardSidebar";
import { Outlet } from "react-router-dom";

export default function Permissions() {
  const is_mobible = useMediaQuery({ query: "(max-width: 768px)" });

  function handleToggle(e) {
    if (is_mobible) {
      const sidebar = document.querySelector(".performance__nav");
      if (sidebar) {
        sidebar.classList.remove("mobile-show-sidebar");
      }
    } else {
      const sidebar = document.querySelector(".admin_layout");

      sidebar.classList.toggle("small__sidebar");
    }
  }
  usePreferenceNotification();
  useEffect(() => {
    const companyName = getURLSubdomain();
    document.title = `${convertToCapitalize(companyName)} - Permissions`;
  });
  return (
    <DashboardLayout
      sidebar={<PermissionsDashboardSidebar handleToggle={handleToggle} />}
    >
      <Outlet />
    </DashboardLayout>
  );
}
