import { useState, useEffect } from "react";
import styles from "../../styles/DragDrop.module.css";
import { useDispatch } from "react-redux";
import { user_errors, quick_setup } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import FileUpload from "../ui/FileUpload";

function UploadLogo() {
  const company = useShallowEqualSelector(quick_setup);

  const companyLogo = company.find((details) => details.name === "Logo Upload");
  const { field } = companyLogo;
  const [file, setFile] = useState(field.file);

  const dispatch = useDispatch();

  const errors = useShallowEqualSelector(user_errors);

  useEffect(() => {
    dispatch({ type: "QUICK_SETUP_LOGO_UPLOAD", payload: file });
  }, [file, dispatch]);

  return (
    <>
      <h3 className={styles.heading}>Logo Upload</h3>
      <small>
        Upload preferred company logo{" "}
        <span>
          (<em>required</em>)
        </span>
      </small>
      {errors.file && (
        <div className={styles.error}>
          <p>{errors.file}</p>
        </div>
      )}
      <div style={{ marginBlock: "20px 50px" }}>
        <FileUpload
          file={file}
          setFile={setFile}
          supportedFormats={"JPEG, PNG, JPG"}
          accept={"image/png, image/jpeg, image/jpg"}
        />
      </div>
    </>
  );
}

export default UploadLogo;
