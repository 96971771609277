import React from "react";
import { FaBirthdayCake } from "react-icons/fa";
import styles from "../../styles/Dashboard.module.css";
import DashboardCard from "./DashboardCard";
import { useState } from "react";
import { useGetSystemBirthdays } from "../../util/usershookActions";
import { useShallowEqualSelector } from "../../util/hooks";
import { birthdays } from "../../util/selectors/userSelectors";
import { Modal } from "antd";
import NoContent from "../ui/NoContent";
import dayjs from "dayjs";

function Birthdays() {
  const [enabled, setEnabled] = useState(true);
  useGetSystemBirthdays(enabled, setEnabled);
  const data = useShallowEqualSelector(birthdays);

  return (
    <div className="mb-3">
      <DashboardCard title={"Birthdays"} icon={<FaBirthdayCake />}>
        <div className="row">
          <div className="col-md-12">
            <div className={styles.birthdays}>
              <ul>
                {data.length ? (
                  data.map((item, index) => (
                    <li key={index} className={styles.bCard}>
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={item.avatar || `/assets/man-placeholder.png`}
                          alt={item.first_name}
                        />
                        <div>
                          <EmployeeModal item={item} />
                          <p>{`${item.birth_month} ${item.birth_day}`}</p>
                        </div>
                      </div>
                      <div className={styles.bCountdown}>
                        <h4>{item.number_of_days_left} DAYS</h4>
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <NoContent
                      illustration={"/assets/3071335.jpg"}
                      message={"No birthday"}
                      width={"150px"}
                    />
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function EmployeeModal({ item }) {
  // console.log({ item });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <h3
        className="pointer"
        onClick={showModal}
      >{`${item.first_name} ${item.last_name}`}</h3>

      <div id="over">
        {isModalOpen ? (
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            width="763px"
            className="over"
          >
            {/* <DashboardCard title={"Birthdays"}> */}
            <>
              <div>
                <h5 className="birthday__title">Birthday</h5>
                <div className="header__info">
                  <div className="header__title">
                    <img
                      src={item.avatar || `/assets/man-placeholder.png`}
                      alt={item.first_name}
                      className="header__title_img"
                    />
                    <img src="/assets/cheers.png" alt="" className="cheers" />
                  </div>

                  <div className="header_body">
                    <h6> {`${item.first_name} ${item.last_name}`}</h6>
                    <h3>
                      {item.job_role
                        ? item?.job_role?.job_role.role || "N/A"
                        : "N/A"}
                    </h3>
                    <p>{dayjs(item.date_of_birth).format("MMM DD")}</p>
                  </div>
                </div>
                {item.family_birthdates.length ? (
                  <div className="header__body">
                    <p className="">Dependants / Family:</p>

                    <div className="header__body_family dependent">
                      {item.family_birthdates.map((item) => {
                        return (
                          <div className="family__member">
                            <img
                              src="/assets/man-placeholder.png"
                              alt=""
                              className="cheers"
                            />

                            <p>{item.name}</p>
                            <p>{item.relationship}</p>
                            <p>{dayjs(item.date_of_birth).format("MMM DD")}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                {/* {console.log({ item })} */}
              </div>
            </>
            {/* </DashboardCard> */}
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default Birthdays;
