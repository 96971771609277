import React, { useEffect, useState } from "react";
import { Input, DatePicker, Table, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import CURRENCIES from "../../../../util/word-currency.json";

const ReadOnlyExpenseReportTemplate = ({
  expenseReportAnswers,
  templatePreview,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let new_array;
    if (templatePreview) {
      new_array = {
        expensePeriod: {
          start_date: "",
          end_date: "",
        },
        expenseItems: [
          {
            id: 1,
            expense_date: "",
            description: "",
            expense_type: "",
            expense_evidence: "",
            amount: "",
          },
          {
            id: 2,
            expense_date: "",
            description: "",
            expense_type: "",
            expense_evidence: "",
            amount: "",
          },
        ],
        currency: {
          name: "NGN",
          symbol: "₦",
        },
        total_expenses: 0.0,
        advance_collected: 0.0,
        balance_amount: 0.0,
      }.expenseItems.map((item, index) => {
        return {
          key: item.id,
          sn: item.id,
          date: (
            <DatePicker
              size="large"
              value={item.expense_date ? dayjs(item.expense_date) : null}
              style={{
                padding: "0.5rem 0.5rem",
              }}
              disabled
            />
          ),
          description: (
            <Input
              placeholder="Description"
              size="large"
              value={item.description}
              disabled
            />
          ),
          expenseType: (
            <Input
              placeholder="Expense Type"
              size="large"
              value={item.expense_type}
              disabled
            />
          ),
          evidence: (
            <a href={item?.expense_evidence} download>
              Download File
            </a>
          ),
          amount: (
            <InputNumber
              placeholder="Amount"
              size="large"
              value={item.amount}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              disabled
            />
          ),
        };
      });
    } else {
      new_array = expenseReportAnswers?.answers?.expenseItems.map(
        (item, index) => {
          return {
            key: item.id,
            sn: item.id,
            date: (
              <DatePicker
                size="large"
                value={item.expense_date ? dayjs(item.expense_date) : null}
                style={{
                  padding: "0.5rem 0.5rem",
                }}
                disabled
              />
            ),
            description: (
              <Input
                placeholder="Description"
                size="large"
                value={item.description}
                disabled
              />
            ),
            expenseType: (
              <Input
                placeholder="Expense Type"
                size="large"
                value={item.expense_type}
                disabled
              />
            ),
            evidence: (
              <a href={item?.expense_evidence} download>
                Download File
              </a>
            ),
            amount: (
              <InputNumber
                placeholder="Amount"
                size="large"
                value={item.amount}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\s?|(,*)/g, "")}
                disabled
              />
            ),
          };
        }
      );
    }
    setTableData(new_array);
  }, [templatePreview, expenseReportAnswers?.answers?.expenseItems]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Expense Type",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
    },
    {
      title: "Evidence",
      dataIndex: "evidence",
      key: "evidence",
      align: "center",
    },
    {
      title: `Amount (${expenseReportAnswers?.answers?.currency?.symbol})`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
  ];

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="predefined__template__container">
      <div className="predefined__template__header">
        <div
          className="d-flex flex-column gap-2"
          style={{
            gridColumn: "1/3",
          }}
        >
          <label className="fw-bold fs-6">Request Subject:</label>
          <Input
            placeholder="Request Subject"
            size="large"
            value={expenseReportAnswers?.answers?.requestSubject}
            style={{
              border: "2px solid black",
            }}
            disabled
          />
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">From</label>
          <DatePicker
            size="large"
            value={
              expenseReportAnswers?.answers?.expensePeriod?.start_date
                ? dayjs(
                    expenseReportAnswers?.answers?.expensePeriod?.start_date
                  )
                : null
            }
            style={{
              padding: "0.6rem 0.5rem",
              border: "2px solid black",
            }}
            disabled
          />
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">To</label>
          <DatePicker
            size="large"
            value={
              expenseReportAnswers?.answers?.expensePeriod?.end_date
                ? dayjs(expenseReportAnswers?.answers?.expensePeriod?.end_date)
                : null
            }
            style={{
              padding: "0.6rem 0.5rem",
              border: "2px solid black",
            }}
            disabled
          />
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fw-bold fs-6">Select Currency:</label>
          <Select
            showSearch
            size="large"
            placeholder="Select currency"
            optionFilterProp="children"
            value={expenseReportAnswers?.answers?.currency?.name}
            onSearch={onSearch}
            filterOption={filterOption}
            style={{
              border: "2px solid black",
              borderRadius: "10px",
            }}
            options={CURRENCIES.map((currency) => ({
              value: currency.code,
              label: `${currency.symbol} ${currency.name}`,
              symbol: currency.symbol,
            }))}
            disabled
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 ">
        <Table
          columns={columns}
          dataSource={tableData}
          // scroll={{ x: 3200 }}
          bordered
          pagination={false}
          style={{
            width: "100%",
          }}
        />

        <div className="d-flex flex-column gap-4 align-items-end">
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Total Expenses ({expenseReportAnswers?.answers?.currency?.symbol}
              ):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={parseFloat(expenseReportAnswers?.answers?.total_expenses)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              style={{
                width: "13rem",
              }}
              disabled
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Advance Collected (
              {expenseReportAnswers?.answers?.currency?.symbol}):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={expenseReportAnswers?.answers?.advance_collected}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              style={{
                width: "13rem",
              }}
              disabled
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <label className="fw-bold fs-6">
              Balance ({expenseReportAnswers?.answers?.currency?.symbol}):
            </label>
            <InputNumber
              placeholder="0.00"
              size="large"
              prefix={expenseReportAnswers?.answers?.currency?.symbol}
              value={expenseReportAnswers?.answers?.balance_amount}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\s?|(,*)/g, "")}
              style={{
                width: "13rem",
              }}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyExpenseReportTemplate;
