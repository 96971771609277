import React from "react";

import { NavLink } from "react-router-dom";

function LeavePlanDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"request-team"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Team Leave History</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-team/teamannualleave_team"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Team Annual Leave Plan</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-team/requestforemployee_team"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Request for Employee</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"request-team/employee_on_leave"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Employee on Leave</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default LeavePlanDropdown;
