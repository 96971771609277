import React from "react";
import TeamLeavePlanTeam from "../../leave-management/teamLeavePlanTeam/teamLeavePlanTeam";

function teamLeavePlan() {
  return (
    <div>
      <TeamLeavePlanTeam employee={true} />
    </div>
  );
}

export default teamLeavePlan;
