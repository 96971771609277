// import React, { useEffect, useState } from "react";
// import { MdOutlineArrowBack } from "react-icons/md";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useGetOneAvailableJobGrade } from "../../../util/usershookActions";
// import { useShallowEqualSelector } from "../../../util/hooks";
// import { one_avail_job } from "../../../util/selectors/userSelectors";
// import SkeletonUI from "../../ui/Skeleton";
// import NoContent from "../../ui/NoContent";
// import { formatMoney } from "../../../util/helpers";
// import { Button } from "antd";
// import { ArrowLeftOutlined } from "@ant-design/icons";

// function ViewJobGreades() {
//   const params = useParams();
//   const navigate = useNavigate();
//   const [enabled, setEnabled] = useState(false);

//   const { isLoading } = useGetOneAvailableJobGrade(
//     enabled,
//     setEnabled,
//     params.id
//   );
//   const one_job = useShallowEqualSelector(one_avail_job);

//   useEffect(() => {
//     if (params.id && typeof parseInt(params.id) === "number") {
//       // console.log({ params });
//       setEnabled(true);
//     } else {
//       navigate(-1);
//     }
//   }, [params]);

//   console.log({one_job});
//   return (
//     <>
//       <div className="row">
//       <div className="col-md-12">
//         {one_job.length ?
//         (
//           <section className="app__section mt-3 py-4">
//             {isLoading ? (
//               <SkeletonUI number={2} />
//             ) : Object.keys(one_job).length ? (
//               <>
//                 <div className="d-flex align-items-center justify-content-between">
//                   <h5 className="mb-0">{one_job?.role}</h5>
//                   <Link to={`/compensation/job-grading/edit/${params.id}`}>
//                     <p className="performance__bagde2">Edit Grade Level</p>
//                   </Link>
//                 </div>

//                 <div className="d-flex flex-column gap-2 mt-3">
//                   <small>{one_job?.category}</small>
//                   <p className="fw-bold">
//                     {" "}
//                     {formatMoney(one_job?.minimum_salary)} -{" "}
//                     {formatMoney(one_job?.maximum_salary)}
//                     {one_job?.currency?.code}
//                   </p>
//                 </div>

//                 <div className="row mt-4">
//                   <h6 className="mb-3">Job levels</h6>
//                   {one_job.job_role_levels.length ? (
//                     one_job.job_role_levels.map((item, index) => (
//                       <div className="grade_level-group mb-5" key={item.id}>
//                         <div className="row">
//                           <div className="col-md-5 mb-3">
//                             <label htmlFor="job_title">Grade / Level</label>
//                             <p>{item?.grade?.name}</p>
//                           </div>
//                           <div className="col-md-5 mb-3">
//                             <label htmlFor="job_title">
//                               Band / Notch / Step
//                             </label>
//                             <p>{item?.step?.name}</p>
//                           </div>

//                           <div className="col-md-2 mb-3">
//                             <label htmlFor="job_grade">Salary (Gross)</label>

//                             <p>{formatMoney(item?.salary)}</p>
//                           </div>

//                           <div className="col-md-5 mb-3">
//                             <label className="mb-1" htmlFor="job_grade">
//                               Benefits in kind
//                             </label>
//                             <div className="d-flex gap-2">
//                               {item?.benefits?.length ? (
//                                 item?.benefits?.map((ind) => (
//                                   <small className="performance__bagde2 no-cursor bg-light  rounded-pill">
//                                     {ind.benefit}
//                                   </small>
//                                 ))
//                               ) : (
//                                 <p>No benefits yet</p>
//                               )}
//                             </div>
//                           </div>
//                         </div>
//                         {one_job.job_role_levels.length - 1 !== index ? (
//                           <div className="col-md-12 mb-3">
//                             <hr className="ruler" />
//                           </div>
//                         ) : null}
//                       </div>
//                     ))
//                   ) : (
//                     <NoContent message={"No job levels yet"} width={"400px"} />
//                   )}
//                 </div>
//               </>
//             ) : (
//               <NoContent message={"No data yet"} width={"400px"} />
//             )}
//           </section>
//         ) : (
//           <div className="d-flex flex-column align-items-center text-center" style={{
//             marginTop: "5rem"
//           }}>
//             <NoContent illustration={"/assets/job.png"} message={`No grading found for `+one_job?.role+` role`} width={"700px"} />
//             <br />
//             <Link style={{

//             }} to={`/compensation/job-grading/edit/${params.id}`}>
//               <p className="performance__bagde2">Add Grade Level</p>
//             </Link>

//             <Link onClick={() => navigate(-1)}>
//               <Button type="link" size="large" className="backBtn">
//                 <ArrowLeftOutlined />
//                 Go back
//               </Button>
//             </Link>
//           </div>
//         )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default ViewJobGreades;

import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOneAvailableJobGrade } from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { one_avail_job } from "../../../util/selectors/userSelectors";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { formatMoney } from "../../../util/helpers";

function ViewJobGreades() {
  const params = useParams();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  useUserCleanUp();

  const { isLoading } = useGetOneAvailableJobGrade(
    enabled,
    setEnabled,
    params.id
  );
  const one_job = useShallowEqualSelector(one_avail_job);

  useEffect(() => {
    if (params.id && typeof parseInt(params.id) === "number") {
      // console.log({ params });
      setEnabled(true);
    } else {
      navigate(-1);
    }
  }, [params]);

  // console.log({ one_job });
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <section className="app__section py-4">
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : Object.keys(one_job).length ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0">{one_job?.role}</h5>
                  <div className="d-flex gap-2">
                    <Link
                      to={`/modules/compensation/pay-structure/job-grading/edit/${params.id}`}
                    >
                      <p className="performance__bagde2">Edit Grade Level</p>
                    </Link>
                    <Link
                      to={`/modules/compensation/pay-structure/job-grading`}
                    >
                      <p
                        className="performance__bagde2 backBtn align-items-center d-flex gap-2"
                        style={{
                          float: "right",
                        }}
                      >
                        <MdOutlineArrowBack />
                        Back to list
                      </p>
                    </Link>
                  </div>
                </div>

                <div className="d-flex flex-column gap-2 mt-3">
                  <small>{one_job?.category}</small>
                  <p className="fw-bold">
                    {" "}
                    {formatMoney(one_job?.maximum_salary)} -{" "}
                    {formatMoney(one_job?.minimum_salary)}{" "}
                    {one_job?.currency?.code}
                  </p>
                </div>

                <div className="row mt-4">
                  <h6 className="mb-3">Job levels</h6>
                  {one_job.job_role_levels.length ? (
                    one_job.job_role_levels.map((item, index) => (
                      <div className="grade_level-group mb-5" key={item.id}>
                        <div className="row">
                          <div className="col-md-5 mb-3">
                            <label htmlFor="job_title">Grade / Level</label>
                            <p>{item?.grade?.name || "N/A"}</p>
                          </div>
                          <div className="col-md-5 mb-3">
                            <label htmlFor="job_title">
                              Band / Notch / Step
                            </label>
                            <p>{item?.step?.name || "N/A"}</p>
                          </div>

                          <div className="col-md-2 mb-3">
                            <label htmlFor="job_grade">Salary (Gross)</label>

                            <p>{formatMoney(item?.salary)}</p>
                          </div>

                          <div className="col-md-5 mb-3">
                            <label className="mb-1" htmlFor="job_grade">
                              Benefits
                            </label>
                            <div className="d-flex gap-2">
                              {item?.benefits?.length ? (
                                item?.benefits?.map((ind) => (
                                  <small className="performance__bagde2 no-cursor bg-light rounded">
                                    {ind.benefit} <br />
                                    <span className="small_bold">
                                      {ind.value}
                                    </span>
                                  </small>
                                ))
                              ) : (
                                <p>No benefits yet</p>
                              )}
                            </div>
                          </div>
                        </div>
                        {one_job.job_role_levels.length - 1 !== index ? (
                          <div className="col-md-12 mb-3">
                            <hr className="ruler" />
                          </div>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <>
                      <div
                        className="d-flex flex-column align-items-center text-center"
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <NoContent
                          illustration={"/assets/job.png"}
                          message={
                            `No grading found for ` + one_job?.role + ` role`
                          }
                          width={"700px"}
                        />

                        <br />
                        <Link
                          style={{}}
                          to={`/modules/compensation/pay-structure/job-grading/edit/${params.id}`}
                        >
                          <p className="performance__bagde2">Add Grade Level</p>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              // <NoContent message={"No data yet"} width={"400px"} />
              <NoContent width={"400px"} />
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewJobGreades;
