import React, { useState, useEffect } from "react";

import { Button, Modal, List, Select } from "antd";

import { useDispatch } from "react-redux";
import HocHeader from "../../ui/HocHeader";
import {
  useGetSystemDeptCategory,
  useGetSystemRoleDesignation,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  designation_role,
  user_errors,
} from "../../../util/selectors/userSelectors";
import { addEditRoleDesignation } from "../../../redux/users/userActions";
import RoleDesgEachList from "../settings/designation-role/RoleDesgEachList";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

function JobRoleList() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [addRoleDesg, setAddRoleDesg] = useState(false);
  const [file, setFile] = useState();
  const [enabled_cat, setEnabledCat] = useState(true);
  const [values, setValues] = useState({
    role: "",
    code: "",
    category: "",
  });

  const { refetch, isLoading } = useGetSystemRoleDesignation(
    enabled,
    setEnabled
  );
  const back_errors = useShallowEqualSelector(user_errors);
  const sys_role_designation = useShallowEqualSelector(designation_role);
  const { data: cat_data } = useGetSystemDeptCategory(
    enabled_cat,
    setEnabledCat
  );
  const handleAddRoleDesg = () => {
    clearSelected();
    setAddRoleDesg(!addRoleDesg);
  };

  const handleSelected = (item) => {
    setSelected(item);
    setValues(item);
    setAddRoleDesg(true);
  };
  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const handleSelect = (value, name) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  useEffect(() => {
    if (sys_role_designation.length) {
      setData(sys_role_designation);
    } else {
      setData([]);
    }
  }, [sys_role_designation]);

  const clearSelected = () => {
    setSelected({});
    setValues({
      role: "",
      code: "",
      category: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditRoleDesignation(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          role: "",
          code: "",
          category: "",
        });

        if (Object.keys(selected).length) {
          dispatch({
            type: "UPDATE_TO_DESIGNATION_ROLE",
            payload: res.payload.job_role,
          });
        } else {
          dispatch({
            type: "ADD_TO_DESIGNATION_ROLE",
            payload: res.payload.job_role,
          });
        }
        setAddRoleDesg(false);
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available job roles</h5>
              <small className="w-75 d-none d-sm-block">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                magnam qui perspiciatis animi doloribus velit aperiam excepturi
                tempora culpa consequatur laboriosam pariatur dicta eius totam,
                minus natus praesentium. Exercitationem, non?
              </small>
            </div>
          </div>

          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center mb-4">
              <div className="circle__bagde fw-bold">{data?.length}</div>
              <h6 className="mb-0"> Job roles</h6>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}

                {addRoleDesg ? (
                  <form className="onboarding-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="role">Role</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.role && back_errors.role[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Role Title"
                            type="text"
                            id="role"
                            name="role"
                            value={values.role}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="code">Code</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.code && back_errors.code[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            type="text"
                            id="code"
                            name="code"
                            value={values.code || ""}
                            onChange={handleChange}
                            placeholder="Role code"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="form-group ">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <label htmlFor="category">Category</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.category &&
                                  back_errors.category[0]}
                              </small>
                            </div>
                          </div>

                          <Select
                            name="category"
                            id="category"
                            value={values.category || ""}
                            onChange={(value) =>
                              handleSelect(value, "category")
                            }
                            placeholder="Role category"
                            className="w-100"
                          >
                            <option value=""> select category</option>

                            {cat_data?.length &&
                              cat_data?.map((item) => (
                                <option value={item.category} key={item.id}>
                                  {" "}
                                  {item.category}
                                </option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      {/* {Object.keys(selected).length ? (
                    
                  ) : null} */}
                      <div>
                        <Button
                          className="myBtn  mt-3 mx-2"
                          onClick={() => {
                            clearSelected();
                            handleAddRoleDesg();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="myBtn  mt-3"
                          htmlType="submit"
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div
                    style={{
                      maxHeight: 500,
                      overflowY: "auto",
                    }}
                    className="my-3"
                  >
                    {isLoading ? (
                      <SkeletonUI number={2} />
                    ) : data.length ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                          <RoleDesgEachList
                            item={item}
                            handleSelected={handleSelected}
                            refetch={refetch}
                          />
                        )}
                      />
                    ) : (
                      <NoContent
                        message={"No system department available."}
                        width={"400px"}
                      />
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  {!addRoleDesg && (
                    <Button className="myBtn mt-3" onClick={handleAddRoleDesg}>
                      Add Role
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default JobRoleList;
