import React, { useEffect, useMemo, useState } from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";
import BasicInfo from "./BasicInfo";
import { useDispatch } from "react-redux";
import data from "../../../../util/util.json";
import {
  useGetUserAutoID,
  useGetUserProfileAny,
} from "../../../../util/usershookActions";
import {
  user_errors,
  user_profile,
} from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { createUserPersonalInfo } from "../../../../redux/users/userActions";
import HocHeader from "../../../ui/HocHeader";
import { useNavigate, useParams } from "react-router-dom";
import { FloatButton, Modal } from "antd";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import EmployeeTable from "../EmployeeTable";

const { employment } = data;

const personal_arry = [
  "nationality",
  "state_of_origin",
  "province_lga",
  "religion",
  "gender",
  "date_of_birth",
  "title",
  "first_name",
  "last_name",
  "other_names",
  "marital_status",
  "employee_id",
  "employment_type",
  "employment_date",
  "status",
  "department",
  "company_id",
  "branch_id",
  "secondary_line_manager",
  "primary_line_manager",
  "team_lead",
  "on_probation",
  "probation_end_date",
  // "job_role",
];

const contact_arry = [
  "mobile_number",
  "primary_email",
  "secondary_email",
  "home_address",
  "telephone",
];
const grading_arry = ["job_role_id", "step_id", "grade_id", "salary"];

export default function ViewEmployee() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();

  const [enabled, setEnabled] = useState(true);
  const [enabledPro, setEnabledPro] = useState(false);
  const [id, setID] = useState("");
  const [auto_gen, setAutoGen] = useState({});
  const [personal, setPersonal] = useState({});
  const [contact, setContact] = useState({});
  const [grading, setGrading] = useState({});
  const [save_spinner, setSaveSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [view, setView] = useState(true);
  const [search, setSearch] = useState(false);

  // const { data: auto_id, refetch } = useGetUserAutoID(enabled, setEnabled);

  const back_errors = useShallowEqualSelector(user_errors);

  const { data, refetch } = useGetUserProfileAny(enabledPro, setEnabledPro, id);

  const u_profile = useMemo(() => data?.data, [data]);

  const handleModal = () => {
    // e.preventDefault();
    setShowModal(!showModal);
  };

  useEffect(() => {
    console.log({ u_profile });

    if (u_profile && Object.keys(u_profile).length) {
      values.title = u_profile.personal.title;
      values.first_name = u_profile.personal.first_name;
      values.last_name = u_profile.personal.last_name;
      values.nationality = u_profile.personal.nationality;
      values.other_names = u_profile.personal.other_names;
      values.religion = u_profile.personal.religion;
      values.gender = u_profile.personal.gender;
      values.date_of_birth = u_profile.personal.date_of_birth;
      values.employment_type = u_profile.personal.employment_type;
      values.marital_status = u_profile.personal.marital_status;
      values.employment_date = u_profile.personal.employment_date;
      values.employment_date = u_profile.personal.employment_date;
      values.employee_id = u_profile.personal.employee_id;
      values.state_of_origin = u_profile.personal.state_of_origin;
      values.province_lga = u_profile.personal.province_lga;
      values.on_probation = u_profile.personal.on_probation;
      values.probation_end_date = u_profile.personal.probation_end_date;

      values.mobile_number = u_profile.contact.mobile_number;
      values.primary_email = u_profile.contact.primary_email;
      values.secondary_email = u_profile.contact.secondary_email;
      values.home_address = u_profile.contact.home_address;
      values.telephone = u_profile.contact.telephone;

      values.job_role_id = u_profile?.job_role?.job_role.id;
      values.step_id = u_profile?.job_role?.step?.id;
      values.grade_id = u_profile?.job_role?.grade?.id;
      values.salary = u_profile?.job_role?.salary;

      //others

      values.primary_line_manager = u_profile?.primary_line_manager?.id;
      values.secondary_line_manager = u_profile?.secondary_line_manager?.id;
      values.team_lead = u_profile?.team_lead?.id;
      values.status = u_profile?.personal?.status;
      values.department = u_profile?.personal?.department?.id;
      values.company = u_profile.personal.company;
      values.branch = u_profile.personal.branch;
      values.branch_id = u_profile.personal.branch?.id;
      values.company_id = u_profile.personal.company?.id;

      //set personal information
      setPersonal({
        nationality: u_profile.personal.nationality,
        state_of_origin: u_profile.personal.state_of_origin,
        province_lga: u_profile.personal.province_lga,
        religion: u_profile.personal.religion,
        gender: u_profile.personal.gender,
        date_of_birth: u_profile.personal.date_of_birth,
        title: u_profile.personal.title,
        first_name: u_profile.personal.first_name,
        last_name: u_profile.personal.last_name,
        other_names: u_profile.personal.other_names,
        marital_status: u_profile.personal.marital_status,
        employee_id: auto_gen,
        employment_type: u_profile.personal.employment_type,
        employment_date: u_profile.personal.employment_date,
        status: u_profile.personal.status,
        department: u_profile.personal.department?.id,
        on_probation: u_profile.personal?.on_probation || "0",
        probation_end_date: u_profile.personal?.probation_end_date || "",
      });

      //set contact information
      setContact({
        mobile_number: u_profile.contact.mobile_number,
        primary_email: u_profile.contact.primary_email,
        secondary_email: u_profile.contact.secondary_email,
        home_address: u_profile.contact.home_address,
        telephone: u_profile.contact.telephone,
      });

      setGrading({
        job_role_id: u_profile?.job_role?.job_role.id,
        step_id: u_profile?.job_role?.step?.id,
        grade_id: u_profile?.job_role?.grade?.id,
        salary: u_profile?.job_role?.salary,
      });
    }
  }, [u_profile]);

  useEffect(() => {
    if (parseInt(param.id) > 0) {
      setID(param.id);
      setEnabledPro(true);
    } else {
      navigate("/modules/hris/employee");
    }
  }, [param]);

  useEffect(() => {
    if (u_profile?.personal?.employee_id) {
      // console.log(u_profile.personal.employee_id);
      setAutoGen(u_profile.personal.employee_id);
    }
  }, [u_profile?.personal?.employee_id]);

  const init_details = {
    nationality: "",
    state_of_origin: "",
    province_lga: "",
    religion: "",
    gender: "",
    date_of_birth: "",
    title: "",
    first_name: "",
    last_name: "",
    other_names: "",
    marital_status: "",
    employee_id: "",
    employment_type: "",
    status: "",
    employment_date: "",
    mobile_number: "",
    primary_email: "",
    secondary_email: "",
    home_address: "",
    telephone: "",
    department: "",
    salary: "",
    job_role_id: "",
    step_id: "",
    grade_id: "",
    on_probation: "0",
    probation_end_date: "",
  };

  const callbackFn = () => {
    setSaveSpinner(true);
    personal.employee_id = `${auto_gen?.available_employee_number}`;
    if (personal.on_probation == "0") {
      personal.probation_end_date = "";
    }

    const creds = {
      personal: [{ on_probation: "0", ...personal, employee_id: auto_gen }],
      contact: [{ ...contact }],
      grade_level: [{ ...grading }],
    };
    // clearForm();
    createUserPersonalInfo(dispatch, {
      ...creds,
      action_type: "update",
    }).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        // clearForm();
        refetch();
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  function firstOnchange(e, action, name, value) {
    if (action) {
      if (personal_arry.includes(name)) {
        setPersonal((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (contact_arry.includes(name)) {
        setContact((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (grading_arry.includes(name)) {
        setGrading((prevState) => {
          return { ...prevState, [name]: value };
        });
      }
    } else {
      if (personal_arry.includes(e.target.name)) {
        setPersonal((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (contact_arry.includes(e.target.name)) {
        setContact((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (grading_arry.includes(e.target.name)) {
        setGrading((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      }
    }

    // console.log("here");


    handleChange(e, action, name, value);
  }

  return (
    <>
      <div>
        <HocHeader
          module_title="Human Resource Information System"
          module_class="performance-header"
          module_name={view ? `View Employee` : `Edit Employee`}
          module_description=" Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>
      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <div className="app__section d-flex flex-column white">
            <div className={styles.basicInfo}>
              <BasicInfo
                values={values}
                errors={errors}
                handleChange={firstOnchange}
                handleSubmit={handleSubmit}
                clearForm={clearForm}
                back_errors={back_errors}
                save_spinner={save_spinner}
                auto_gen={auto_gen}
                employment={employment}
                onboarding={true}
                view={view}
              />
            </div>
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
              <FloatButton icon={<BsSearch />} onClick={handleModal} />
              <FloatButton
                icon={view ? <AiOutlineEdit /> : <AiOutlineEye />}
                type="primary"
                onClick={(e) => setView(!view)}
                title={view ? "Edit employee" : "View employee"}
              />
            </FloatButton.Group>
            {showModal ? (
              <Modal
                open={showModal}
                footer={null}
                onCancel={handleModal}
                width={700}
                className="over"
              >
                <h5 className="birthday__title">All Employees</h5>
                <div
                  style={{
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                >
                  <EmployeeTable handleModal={handleModal} />
                </div>
              </Modal>
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
}
