import { Button, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useShallowEqualSelector } from "../../../util/hooks";
import {
  onb_step,
  selected_template,
} from "../../../util/selectors/userSelectors";
import { selected_template_activities } from "../../../util/selectors/userSelectors";
import { Pagination } from "antd";

const AssignToTemplate = ({
  steps,
  title,
  submit,
  submitNotify,
  loading,
  notifyLoading,
  template_show,
  handlepagination,
  isLoading_tem,
}) => {
  // console.log({ template_show });
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [ok, setOk] = useState(false);
  let dyn_btn = null;

  const selectedTemplate = useShallowEqualSelector(selected_template);
  const all_activities = useSelector(selected_template_activities);
  const real_step = useShallowEqualSelector(onb_step);

  const next = () => {
    setCurrent((prv) => {
      dispatch({ type: "ONB_CURRENT_STEP", payload: prv + 1 });
      return prv + 1;
    });
  };

  const prev = () => {
    setCurrent((prv) => {
      dispatch({ type: "ONB_CURRENT_STEP", payload: prv - 1 });
      return prv - 1;
    });
  };

  useEffect(() => {
    setCurrent(real_step);
  }, [real_step]);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  // console.log({ items });

  const contentStyle = {
    marginBlock: 30,
    minHeight: 400,
    maxHeight: 450,
    overflowY: "auto",
  };
  const buttonsStyle = {
    marginTop: 20,
    display: "flex",
    justifyContent: "end",
    gap: 8,
  };

  if (real_step === 0) {
    dyn_btn = (
      <div className="d-flex justify-content-between w-100">
        {template_show?.last_page > 1 ? (
          <Pagination
            total={template_show?.last_page}
            pageSize={1}
            onChange={handlepagination}
          />
        ) : (
          <span>&nbsp;</span>
        )}

        <Button
          className="myBtn"
          onClick={() => next()}
          disabled={Object.keys(selectedTemplate).length ? false : true}
          loading={isLoading_tem}
        >
          Next
        </Button>
      </div>
    );
  } else if (real_step === 1) {
    dyn_btn = (
      <Button className="myBtn" onClick={() => next()} disabled={!ok}>
        Next
      </Button>
    );
  }

  // console.log({ all_activities });

  useEffect(() => {
    if (all_activities?.length) {
      setOk(true);
      all_activities.forEach((element) => {
        if (
          element.start_time === null ||
          element.end_time === null ||
          element.start_date === null ||
          element.end_date === null
        ) {
          setOk(false);
        }
      });
    }
  }, [all_activities]);

  return (
    <>
      <h3 className="text-center mt-3 mb-5">{title}</h3>
      <div id="my-stepper">
        <Steps current={current} items={items} type="navigation" size="small" />
      </div>
      <div className="container">
        <div style={contentStyle}>{steps[current].content}</div>
      </div>
      <div style={buttonsStyle}>
        {current > 0 && (
          <Button type="text" onClick={() => prev()}>
            Previous
          </Button>
        )}
        {/* {current < steps.length - 1 && (
          <Button
            className="myBtn"
            onClick={() => next()}
            disabled={Object.keys(selectedTemplate).length ? false : true}
          >
            Next
          </Button>
        )} */}

        {dyn_btn}

        {current === steps.length - 1 && (
          <Button className="myBtn" onClick={submit} loading={loading}>
            Assign
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            className="myBtn"
            onClick={submitNotify}
            loading={notifyLoading}
          >
            Assign & Notify
          </Button>
        )}
      </div>
    </>
  );
};

export default AssignToTemplate;
