import { useState } from "react";

import {
  useGetHRManagementSummary,
  useGetHRManagementAnalytics,
  useGetHRManagementDeptAnalytics,
  useGetHRManagementSubscriptionUsage,
} from "../../../util/usershookActions";

import HocHeader from "../../ui/HocHeader";
import { RiPulseLine } from "react-icons/ri";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "../../../util/helpers";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CanAccess from "../../hoc/CanAccess";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function HRMOverview() {
  const [enabled, setEnabled] = useState(true);

  const { data: summary, isLoading: isLoadingSummary } =
    useGetHRManagementSummary(enabled, setEnabled);

  const { data: analytics, isLoading: isLoadingAnalytics } =
    useGetHRManagementAnalytics(enabled, setEnabled);

  const { data: deptAnalytics, isLoading: isLoadingDeptAnalytics } =
    useGetHRManagementDeptAnalytics(enabled, setEnabled);

  const { data: subscriptionUsage, isLoading: isLoadingSubscriptionUsage } =
    useGetHRManagementSubscriptionUsage(enabled, setEnabled);

  const cardBgColors = [
    "#D7FFEC94",
    "#CD602330",
    "#62D9FE38",
    "#21231E14",
    "#FE626238",
    "#D7E2FF",
    "#E8F5FF",
    "#FFECEC",
  ];

  const employeeUsageData = {
    labels: [
      `Free Accounts: ${analytics?.payload?.employee_usage?.free_account}`,
      `Used Accounts: ${analytics?.payload?.employee_usage?.used_account}`,
    ],
    datasets: [
      {
        label: "No. of employees",
        data: [
          analytics?.payload?.employee_usage?.free_account,
          analytics?.payload?.employee_usage?.used_account,
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const subscriptionUsageData = {
    labels: [
      `Days left to expiry: ${subscriptionUsage?.payload?.subscription_usage?.days_left_to_expiry}`,
      `Days used: ${subscriptionUsage?.payload?.subscription_usage?.days_used}`,
    ],
    datasets: [
      {
        label: "No. of employees",
        data: [
          subscriptionUsage?.payload?.subscription_usage?.days_left_to_expiry,
          subscriptionUsage?.payload?.subscription_usage?.days_used,
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const deptGenderDistData = {
    labels: [
      `Male: ${deptAnalytics?.payload?.department_distribution["Male"]}`,
      `Female: ${deptAnalytics?.payload?.department_distribution["Female"]}`,
    ],
    datasets: [
      {
        label: "No. of employees",
        data: [
          deptAnalytics?.payload?.department_distribution["Male"],
          deptAnalytics?.payload?.department_distribution["Female"],
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const allDeptDistoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  const allDeptDistData = {
    labels:
      analytics?.payload?.all_department_distribution?.map(
        (dept) => dept.department_name
      ) || [],
    datasets:
      analytics?.payload?.gender?.map((gen, index) => {
        return {
          label: gen,
          data: analytics?.payload?.all_department_distribution?.map(
            (dept) => dept?.gender_count[gen] || 0
          ),
          borderColor: colorArray[index],
          backgroundColor: colorArray[index],
          borderWidth: 2,
          borderRadius: 10,
          borderSkipped: false,
        };
      }) || [],
  };

  const allQualificationDistData = {
    labels:
      analytics?.payload?.all_qualification_distribution?.map(
        (qualification) => qualification.qualification_name
      ) || [],
    datasets: [
      {
        label: "Qualification Distribution",
        data:
          analytics?.payload?.all_qualification_distribution?.map(
            (qualification) => qualification.qualification_count
          ) || [],
        backgroundColor: colorArray[0],
        borderColor: colorArray[0],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  const deptQualificationDistData = {
    labels:
      deptAnalytics?.payload?.department_qualification_distribution?.map(
        (qualification) => qualification.qualification_name
      ) || [],
    datasets: [
      {
        label: "Qualification Distribution",
        data:
          deptAnalytics?.payload?.department_qualification_distribution?.map(
            (qualification) => qualification.qualification_count
          ) || [],
        backgroundColor: colorArray[0],
        borderColor: colorArray[0],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  const qualificationDistOptions = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div>
        <HocHeader
          module_title="Human Resource Information System"
          module_class="performance-header"
          module_name="Overview"
          module_description=" Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <CanAccess isRoute={false} can_access={"view-menu-hris-dashboard"}>
            <section className="app__section py-4 mb-4">
              <h5>Summary</h5>

              {isLoadingSummary ? (
                <SkeletonUI />
              ) : (
                <div className="row mt-4">
                  {summary?.payload &&
                    Object.keys(summary?.payload).map((key, index) => {
                      const randomIndex = Math.floor(
                        Math.random() * cardBgColors.length
                      );
                      const randomColor = cardBgColors[randomIndex];

                      return (
                        <div className="col-md-3 mb-4" key={index}>
                          <div
                            className="insight"
                            style={{ backgroundColor: randomColor }}
                          >
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <RiPulseLine />
                              </div>
                              <div>
                                <div className="insight-title">
                                  <h6 className="text-capitalize">
                                    {key.split("_").join(" ")}
                                  </h6>
                                </div>
                                <div className="insight-content">
                                  <h2 className="mb-0">
                                    {summary?.payload[key]}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </section>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Subscription Usage</h5>
                  {isLoadingSubscriptionUsage ? (
                    <SkeletonUI />
                  ) : subscriptionUsage?.payload?.subscription_usage
                      ?.days_left_to_expiry > 0 ||
                    subscriptionUsage?.payload?.subscription_usage?.days_used >
                      0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut
                          options={options}
                          data={subscriptionUsageData}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Employee Usage</h5>
                  {isLoadingAnalytics ? (
                    <SkeletonUI />
                  ) : analytics?.payload?.employee_usage?.free_account > 0 ||
                    analytics?.payload?.employee_usage?.used_account > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={employeeUsageData} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">All Department Distribution</h5>
                  <spa>The gender categories based on a departmental basis</spa>
                  {isLoadingAnalytics ? (
                    <SkeletonUI />
                  ) : analytics?.payload?.all_department_distribution.length ? (
                    <Bar options={allDeptDistoptions} data={allDeptDistData} />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">All Qualification Distribution</h5>
                  <spa>Employees most updated qualification</spa>
                  {isLoadingAnalytics ? (
                    <SkeletonUI />
                  ) : analytics?.payload?.all_qualification_distribution
                      .length ? (
                    <Bar
                      options={qualificationDistOptions}
                      data={allQualificationDistData}
                    />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
          <CanAccess
            isRoute={false}
            can_access={"view-menu-hris-dept-overview"}
          >
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Department Distribution</h5>
                  <span>
                    The gender categories based on your departmental basis
                  </span>
                  {isLoadingDeptAnalytics ? (
                    <SkeletonUI />
                  ) : deptAnalytics?.payload?.department_distribution["Male"] >
                      0 ||
                    deptAnalytics?.payload?.department_distribution["Female"] >
                      0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={deptGenderDistData} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    Department Qualification Distribution
                  </h5>
                  <spa>Your department most updated qualification</spa>
                  {isLoadingDeptAnalytics ? (
                    <SkeletonUI />
                  ) : deptAnalytics?.payload
                      ?.department_qualification_distribution.length ? (
                    <Bar
                      options={qualificationDistOptions}
                      data={deptQualificationDistData}
                    />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
        </div>
      </main>
    </>
  );
}
