import axios from "axios";
import { dehashData } from "./hash";
import { getURLSubdomain } from "./helpers";

const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_TOKEN: TOKEN,
  REACT_APP_COMPANY_URL: COMPANY_URL,
} = process.env;
const company_subdomain = getURLSubdomain();

export const request = axios.create({
  baseURL: BASE_URL + `/${company_subdomain}/api`,
  // baseURL: BASE_URL + `/branch/api`,
  headers: {
    // "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
  },
});

export const requestMedia = axios.create({
  baseURL: BASE_URL + `/${company_subdomain}/api`,
  // baseURL: BASE_URL + `/branch/api`,
  headers: {
    "Content-Type": "multipart/form-data",
    // "Content-Type": "application/json",
  },
});

export const requestCompany = axios.create({
  baseURL: COMPANY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setPrivateRequest = () => {
  const dehash = dehashData(TOKEN);
  request.defaults.headers.common["Authorization"] = `Bearer ${dehash}`;
  return request;
};

export const setPrivateRequestMedia = () => {
  const dehash = dehashData(TOKEN);
  requestMedia.defaults.headers.common["Authorization"] = `Bearer ${dehash}`;
  return requestMedia;
};
