import React from "react";

import { NavLink } from "react-router-dom";

function SystemGradesDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"grades"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Grades List</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"grades/upload-grades"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload Grades</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default SystemGradesDropdown;
