import { useState, useEffect } from "react";
import { Table, Checkbox, DatePicker, InputNumber, Select, Button } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  useGetPerformanceSettings,
  useGetAppraisalSettings,
} from "../../../../util/usershookActions";
import { setAppraisalsSettings } from "../../../../redux/users/userActions";
import SkeletonUI from "../../../ui/Skeleton";

const AppraisalSettings = () => {
  const [enabled, setEnabled] = useState(true);
  const [appraisalsLoading, setAppraisalsLoading] = useState(false);

  const dispatch = useDispatch();

  const { data: performanceSettings, refetch } = useGetPerformanceSettings(
    enabled,
    setEnabled
  );

  const {
    data: appraisalSettings,
    refetch: appraisalSettingsRefetch,
    isLoading,
  } = useGetAppraisalSettings(enabled, setEnabled);

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const response = appraisalSettings?.payload;

  const abilities = [
    { title: "Appraise Employee", key: "appraise_employee" },
    { title: "Comment on Deliverable", key: "comment_on_deliverable" },
    { title: "Summary Comment", key: "summary_comment" },
    // { title: "Section Comment", key: "section_comment" },
  ];

  const appraisers = [
    "first_appraiser",
    "second_appraiser",
    "third_appraiser",
    "fourth_appraiser",
    "fifth_appraiser",
    "sixth_appraiser",
  ];

  const [enforcement, setEnforcement] = useState({
    employee_supervisor_submit_back: false,
    employee_comment: false,
    line_manager_comment: false,
    supervisor_summary_comment: false,
  });

  const [no_of_appraiser, setNumOfPeriods] = useState(1);
  const [reviewPeriods, setReviewPeriods] = useState({});
  const [final_score_setup, setFinalScoreSetup] = useState();

  const [selectedValues, setSelectedValues] = useState({});

  const [approvedAppraisers, setApprovedAppraisers] = useState([]);

  // console.log("approvedAppraisers", approvedAppraisers);
  // console.log("remainig", approvedAppraisers);

  useEffect(() => {
    const defaultValues = appraisers.reduce((obj, appraiser) => {
      obj[appraiser] = abilities.reduce((abilitiesObj, ability) => {
        if (response && response[appraiser]) {
          abilitiesObj[ability.key] = response[appraiser][ability.key];
        } else {
          // This portion sets the 'appraise_employee' ability of the 'first_appraiser' to true by default
          abilitiesObj[ability.key] =
            appraiser === "first_appraiser" &&
            ability.key === "appraise_employee"
              ? true
              : false;
        }
        return abilitiesObj;
      }, {});
      return obj;
    }, {});

    if (response) {
      setNumOfPeriods(response.no_of_appraiser);
      setFinalScoreSetup(response.final_score_setup);

      if (response.review_period) {
        setReviewPeriods(response.review_period);
      }

      if (response.enforcement) {
        setEnforcement(response.enforcement);
      }

      // Populate the approvedAppraisers array
      const approvedAppraisers = appraisers.reduce((arr, appraiser) => {
        if (response[appraiser] && response[appraiser].appraise_employee) {
          arr.push({
            appraiser,
            appraiserLabel: appraiser
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
          });
        }
        return arr;
      }, []);
      approvedAppraisers.shift();
      setApprovedAppraisers(approvedAppraisers);
    }

    setSelectedValues(defaultValues);
  }, [response]);

  // console.log("approvedAppraisers, ", approvedAppraisers);
  console.log("final_score_setup", final_score_setup);

  const onChangeCheckBox = (appraiser, key, checked = false) => {
    // Prevent changes to the 'appraise_employee' ability of the 'first_appraiser'
    if (appraiser === "first_appraiser" && key === "appraise_employee") {
      return;
    }

    setSelectedValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [appraiser]: {
          ...prevValues[appraiser],
          [key]: checked,
        },
      };

      if (key === "appraise_employee") {
        setApprovedAppraisers((prevAppraisers) => [
          ...prevAppraisers,
          {
            appraiser,
            appraiserLabel: appraiser
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
          },
        ]);
      }

      if (key === "appraise_employee" && !checked) {
        setApprovedAppraisers((prevAppraisers) =>
          prevAppraisers.filter((a) => a.appraiser !== appraiser)
        );
      }

      return newValues;
    });
  };

  useEffect(() => {
    if (!selectedValues) {
      setApprovedAppraisers([]);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (performanceSettings?.payload.performance_planning_window_from) {
      const date = dayjs(
        performanceSettings?.payload.performance_planning_window_from
      );
      setStartDate(date.format("DD-MM-YYYY"));
    }
    if (performanceSettings?.payload.performance_planning_window_to) {
      const date = dayjs(
        performanceSettings?.payload.performance_planning_window_to
      );
      setEndDate(date.format("DD-MM-YYYY"));
    }
  }, [
    performanceSettings?.payload.performance_planning_window_from,
    performanceSettings?.payload.performance_planning_window_to,
  ]);

  useEffect(() => {
    const creds = {
      first_appraiser: { ...selectedValues.first_appraiser },
      second_appraiser: { ...selectedValues.second_appraiser },
      third_appraiser: { ...selectedValues.third_appraiser },
      fourth_appraiser: { ...selectedValues.fourth_appraiser },
      fifth_appraiser: { ...selectedValues.fifth_appraiser },
      sixth_appraiser: { ...selectedValues.sixth_appraiser },
      start_date,
      end_date,
      no_of_appraiser,
      review_period: reviewPeriods,
      enforcement,
      final_score_setup,
    };
    // console.log("creds", creds);
  }, [
    selectedValues,
    approvedAppraisers,
    reviewPeriods,
    enforcement,
    no_of_appraiser,
    final_score_setup,
    start_date,
    end_date,
  ]);

  const onChangeSelect = (value) => {
    setFinalScoreSetup(value);
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeInputNumber = (value) => {
    setNumOfPeriods(value);
  };

  const onChangeDate = (date, dateString, period, type) => {
    setReviewPeriods((prevPeriods) => ({
      ...prevPeriods,
      [period]: {
        ...prevPeriods[period],
        [type]: dateString,
      },
    }));
  };

  const periods = [...Array(no_of_appraiser)].map((_, i) => {
    const periodKey =
      [
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
        "tenth",
      ][i] + "_period";
    const period = reviewPeriods[periodKey];
    return (
      <div className="d-flex flex-column gap-2" key={i}>
        <span className="fw-bold fs-6">{`${periodKey
          .split("_")[0]
          .toUpperCase()} Review Period`}</span>
        <div className="d-flex gap-4 w-100">
          <div className="d-flex flex-column gap-2 w-100">
            <label className="fw-bold fs-6">From</label>
            <DatePicker
              size="large"
              value={period ? dayjs(period.from) : null}
              onChange={(date, dateString) =>
                onChangeDate(date, dateString, periodKey, "from")
              }
            />
          </div>

          <div className="d-flex flex-column gap-2 w-100">
            <label className="fw-bold fs-6">To</label>
            <DatePicker
              size="large"
              value={period ? dayjs(period.to) : null}
              onChange={(date, dateString) =>
                onChangeDate(date, dateString, periodKey, "to")
              }
            />
          </div>
        </div>
      </div>
    );
  });

  const generateCheckbox = (appraiser, ability) => (
    <Checkbox
      onChange={(e) =>
        onChangeCheckBox(appraiser, ability.key, e.target.checked)
      }
      value={selectedValues[appraiser]?.[ability.key]}
      checked={selectedValues[appraiser]?.[ability.key]}
    />
  );

  const columns = [
    {
      title: "Number of Appraisal level",
      dataIndex: "appraisalLevel",
    },
    ...abilities.map((ability) => ({
      title: ability.title,
      dataIndex: ability.key,
      render: (text) => (
        <div className="appraisal__checkbox__wrapper">{text}</div>
      ),
    })),
  ];

  const data = [
    {
      key: "1",
      appraisalLevel: "1st Appraiser (appraised employee)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("first_appraiser", ability);
        return obj;
      }, {}),
    },
    {
      key: "2",
      appraisalLevel: "2nd Appraiser (1st appraisal manager)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("second_appraiser", ability);
        return obj;
      }, {}),
    },
    {
      key: "3",
      appraisalLevel: "3rd Appraiser (2nd appraisal manager)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("third_appraiser", ability);
        return obj;
      }, {}),
    },
    {
      key: "4",
      appraisalLevel: "4th Appraiser (3rd appraisal manager)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("fourth_appraiser", ability);
        return obj;
      }, {}),
    },
    {
      key: "5",
      appraisalLevel: "5th Appraiser (4th appraisal manager)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("fifth_appraiser", ability);
        return obj;
      }, {}),
    },
    {
      key: "6",
      appraisalLevel: "6th Appraiser (5th appraisal manager)",
      ...abilities.reduce((obj, ability) => {
        obj[ability.key] = generateCheckbox("sixth_appraiser", ability);
        return obj;
      }, {}),
    },
  ];

  const options = [
    {
      label:
        "Employee’s supervisor to submit back to the employee before having the option to do final submission",
      value: "employee_supervisor_submit_back",
    },
    {
      label: "Enforce the Employee to make a comment on each Deliverable",
      value: "employee_comment",
      disabled: !selectedValues?.first_appraiser?.comment_on_deliverable,
    },
    {
      label: "Enforce Line Manager to make a comment on each Deliverable",
      value: "line_manager_comment",
      disabled: !selectedValues?.second_appraiser?.comment_on_deliverable,
    },
    {
      label: "Enforce Supervisor’s summary comment",
      value: "supervisor_summary_comment",
      disabled: !selectedValues?.second_appraiser?.summary_comment,
    },
  ];

  const onChangeGroup = (checkedValues) => {
    // Update enforcement state based on checkedValues
    const newEnforcement = options.reduce((obj, option) => {
      obj[option.value] = checkedValues.includes(option.value);
      return obj;
    }, {});

    setEnforcement(newEnforcement);
  };

  const handleSetAppraisalsSettings = (e) => {
    e.preventDefault();
    setAppraisalsLoading(true);
    const creds = {
      first_appraiser: { ...selectedValues.first_appraiser },
      second_appraiser: { ...selectedValues.second_appraiser },
      third_appraiser: { ...selectedValues.third_appraiser },
      fourth_appraiser: { ...selectedValues.fourth_appraiser },
      fifth_appraiser: { ...selectedValues.fifth_appraiser },
      sixth_appraiser: { ...selectedValues.sixth_appraiser },
      start_date,
      end_date,
      no_of_appraiser,
      review_period: reviewPeriods,
      enforcement,
      final_score_setup,
    };
    setAppraisalsSettings(dispatch, creds).then(() => {
      setAppraisalsLoading(false);
      refetch();
      appraisalSettingsRefetch();
    });
  };

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className=""
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "2rem",
          }}
        >
          <h3 className="h4">Appraisal Settings</h3>
          <p>Set your employee appraisal</p>
        </div>

        {isLoading ? (
          <SkeletonUI />
        ) : (
          <form className="">
            <div className="py-5 appraisal__settings__table performance_table">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
              />
            </div>

            <div className="appraisal__settings__form__header">
              <h5 className="">Performance Planning Period</h5>
              <p className="">
                This helps to define when performance plan can be initiated by
                administrator and the latest time for setting of key performance
                indicators by other actors within the financial year
              </p>
            </div>

            <div className="appraisal__settings__form__grid">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Start Date</label>
                <DatePicker
                  size="large"
                  value={
                    performanceSettings?.payload
                      .performance_planning_window_from
                      ? dayjs(
                          performanceSettings?.payload
                            .performance_planning_window_from
                        )
                      : null
                  }
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">End Date</label>
                <DatePicker
                  size="large"
                  value={
                    performanceSettings?.payload.performance_planning_window_to
                      ? dayjs(
                          performanceSettings?.payload
                            .performance_planning_window_to
                        )
                      : null
                  }
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">
                  No of Appraiser in a year
                </label>
                <InputNumber
                  min={1}
                  max={10}
                  value={no_of_appraiser}
                  size="large"
                  style={{
                    border: "2px solid black",
                    width: "100%",
                  }}
                  onChange={onChangeInputNumber}
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item">
                <label className="fw-bold fs-6">Final Score Setup</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select an Appraiser"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                  value={final_score_setup}
                  options={approvedAppraisers
                    ?.filter(
                      (appraiser, index) =>
                        index === 0 || index === approvedAppraisers.length - 1
                    )
                    .map((appraiser, index) => ({
                      value: `${appraiser.appraiser.split("_")[0]}_level`,
                      label:
                        appraiser.appraiser === "second_appraiser"
                          ? "Supervisor/Line Manager"
                          : "Last Appraiser",
                    }))}
                />
              </div>

              <div className="d-flex flex-column gap-3">{periods}</div>
            </div>

            <div className="appraisal__settings__form__enforcement">
              <div className="">
                <h5>Enforcement</h5>
                <p>
                  Pick or select actions that should be made mandatory for
                  respective users
                </p>
              </div>
              <div className="">
                <Checkbox.Group
                  options={options}
                  value={Object.keys(enforcement).filter(
                    (key) => enforcement[key]
                  )}
                  onChange={onChangeGroup}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                }}
                onClick={handleSetAppraisalsSettings}
                loading={appraisalsLoading}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AppraisalSettings;
