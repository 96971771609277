import React, { useState, useEffect } from "react";
import { useGetOnboardUserProfile } from "../../../util/usershookActions";
import { useUserCleanUp } from "../../../util/hooks";
import InductionAct from "./InductionAct";
import OnboardAct from "./OnboardAct";
import NewHireBasicProfile from "./NewHireBasicProfile";
import { Steps } from "antd";
import NoContent from "../../ui/NoContent";
import { SmileOutlined } from "@ant-design/icons";
import InductionActivity from "./InductionActivity";
import OnboardingActivity from "./OnboardingActivity";
import OnboardingDocuments from "./OnboardingDocuments";

const HireProfile = ({ id, admin }) => {
  // console.log(admin);
  useUserCleanUp();
  const [enabled, setEnabled] = useState(false);
  const [current, setCurrent] = useState(0);

  let { data: hireProfile, refetch } = useGetOnboardUserProfile(
    enabled,
    setEnabled,
    id,
    admin
  );

  const handleChange = (value) => {
    setCurrent(value);
  };

  const steps = [
    {
      title: "Overview",
      description:
        "A general summary of your information and status of your application",
      status: "wait",
      icon: <SmileOutlined />,
      content: <NewHireBasicProfile hireProfile={hireProfile} />,
    },
    {
      title: "Documents",
      description: "All the documents you're required to read for induction and onboarding process",
      status: "wait",
      icon: <SmileOutlined />,
      content: (
        <OnboardingDocuments
          hireProfile={hireProfile}
          refetch={refetch}
          admin={false}
        />
      ),
    },
    {
      title: "Induction",
      description: "Review all your activities for the induction process",
      status: "wait",
      icon: <SmileOutlined />,
      content: hireProfile?.payload?.hire_activities?.induction.length ? (
        <div className="row mt-3">
          <InductionActivity hireProfile={hireProfile} />
        </div>
      ) : (
        <div className="mb-5">
          <NoContent
            message={"Induction not activated for Applicant"}
            width={400}
          />
        </div>
      ),
    },

    {
      title: "Onboarding",
      description: "Review all your activities for the onboarding process",
      status: "wait",
      icon: <SmileOutlined />,
      content: hireProfile?.payload?.hire_activities?.onboarding.length ? (
        <div className="row mt-3">
          {hireProfile?.payload?.hire_activities?.onboarding.map((act) => (
            <OnboardingActivity hireProfile={hireProfile} />
          ))}
        </div>
      ) : (
        <div className="mb-5">
          <NoContent
            message={"Onboarding not activated for new hire"}
            width={400}
          />
        </div>
      ),
    },
  ];

  const adminSteps = [
    {
      title: "New hire Overview",
      description:
        "A general summary of the hire's information and status of their application",
      status: "finish",
      icon: <SmileOutlined />,
      content: <NewHireBasicProfile hireProfile={hireProfile} />,
    },
    {
      title: "Induction",
      description: "Manage the hire's induction activity",
      status: "wait",
      icon: <SmileOutlined />,
      content: hireProfile?.payload?.hire_activities?.induction.length ? (
        <div className="row mt-3">
          <h4 className="mb-3">Induction Activities</h4>
          <div className="row">
            {hireProfile?.payload?.hire_activities?.induction.map((act) => (
              <div className="col-md-6 my-2">
                <div className="card">
                  <div className="card-body">
                    <h6>{act.activity}</h6>
                    <InductionAct
                      act={act}
                      hire_id={id}
                      refetch={refetch}
                      setEnabled={setEnabled}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mb-5">
          <NoContent
            message={"Induction not activated for Applicant"}
            width={400}
          />
        </div>
      ),
    },

    {
      title: "Onboarding",
      description: "Manage the hire's onboarding activity",
      status: "wait",
      icon: <SmileOutlined />,
      content: hireProfile?.payload?.hire_activities?.onboarding.length ? (
        <div className="row mt-3 mb-5">
          <h4 className="mb-3">Onboarding Activities</h4>
          {hireProfile?.payload?.hire_activities?.onboarding.map((act) => (
            <div className="my-2">
              <p>{act.activity}</p>
              <OnboardAct
                act={act}
                hire_id={id}
                refetch={refetch}
                setEnabled={setEnabled}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="mb-5">
          <NoContent
            message={"Onboarding not activated for Applicant"}
            width={400}
          />
        </div>
      ),
    },

    {
      title: "Documents",
      description: "Review hire's activities on the documents uploaded.",
      status: "wait",
      icon: <SmileOutlined />,
      content: <OnboardingDocuments hireProfile={hireProfile} admin={true} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
    description: item.description,
  }));

  const adminItems = adminSteps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
    description: item.description,
  }));

  useEffect(() => {
    setEnabled(true);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3 my-5">
          <Steps
            current={current}
            onChange={handleChange}
            items={admin ? adminItems : items}
            direction="vertical"
          />
        </div>
        <div
          className="col-md-9"
          style={{
            height: admin ? "70vh" : "100vh",
            paddingBlock: admin ? "1rem" : "3rem",
            overflowY: "auto",
          }}
        >
          {admin ? adminSteps[current].content : steps[current].content}
        </div>
      </div>
    </div>
  );
};

export default HireProfile;
