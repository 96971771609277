import React from "react";
import { NavLink } from "react-router-dom";
function CreateSurveyDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"new-survey-template"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Create New Survey</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"new-survey-template/edit-survey"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Edit Survey</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(CreateSurveyDropdown);
