import React, { useState, useEffect } from "react";
import { List } from "antd";
import { Link } from "react-router-dom";
import { settings_util } from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
// import { convertToCapitalize } from "../../../../util/helpers";

function ParentList({ handleCatSelected }) {
  const settingsUtil = useShallowEqualSelector(settings_util);
  const [data, setData] = useState([]);

  console.log({ settingsUtil });

  // Object.keys(settingsUtil).forEach(function (key) {
  //   // console.log(key, settingsUtil[key]);
  // });

  useEffect(() => {
    if (Object.keys(settingsUtil?.system_inputs).length) {
      const new_arry = [];
      const exclude = ["System HR"];
      Object.keys(settingsUtil?.system_inputs).forEach(function (key) {
        // console.log(key, settingsUtil?.system_inputs[key]);
        if (!exclude.includes(key)) {
          new_arry.push({
            title: key,
            count: settingsUtil?.system_inputs[key].length,
            input: settingsUtil?.system_inputs[key],
          });
        }
      });

      setData(new_arry);
    }
  }, [settingsUtil]);

  return (
    <div
      style={{
        height: 500,
        overflowY: "scroll",
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Link
                key="list-loadmore-more"
                className="text-info"
                onClick={() => handleCatSelected(item)}
              >
                Details
              </Link>,
            ]}
          >
            <List.Item.Meta
              title={item.title}
              description={`${item.count} entries`}
            />
          </List.Item>
        )}
      />
    </div>
  );
}

export default ParentList;
