import styles from "../../../../styles/hrms/NewEmployee.module.css";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { settings_util } from "../../../../util/selectors/userSelectors";
import { useGetSystemSettingsUtils } from "../../../../util/usershookActions";
import { useState } from "react";
import { Select, Modal } from "antd";
import { AiFillPlusCircle } from "react-icons/ai";
import countries from "../../../../util/countries.json";
import InputCategories from "../../settings/input_entries/InputCategories";

const STATE_DATA = require("../../../../util/nigeria-state-and-lgas.json");

export default function Biodata({
  handleChange,
  values,
  errors,
  back_errors,
  onboarding,
}) {
  const [showCatpage, setShowCatpage] = useState(false);
  const [selected, setSelected] = useState({});
  let selectedState, lgasInState;

  if (values.state_of_origin) {
    selectedState = values.state_of_origin;

    const lgaToFind = STATE_DATA.filter(
      (state) => state.alias === selectedState
    );

    lgasInState = lgaToFind[0].lgas;
  }

  const [enabled_uti, setEnabledUtil] = useState(true);
  const { refetch } = useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);
  const utils = useShallowEqualSelector(settings_util);

  const handleSelect = (value, name) => {
    handleChange("_", true, name, value);
  };
  const err_text = "personal.0.";

  const handleCatpage = () => {
    setShowCatpage(!showCatpage);
  };

  const handleCatSelected = (item) => {
    console.log(item);
    setSelected(item);
    setShowCatpage(true);
  };

  return (
    <div className={onboarding ? "col-md-12" : "col-md-6"}>
      <div className="card b0">
        <div className="card-body py-4">
          <h4 className={styles.subheading4}>Personal Information</h4>
          <div className={styles.hr}></div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="title">Title</label>

                  <small className="text-danger">
                    {errors.title ||
                      (back_errors[`${err_text}title`] &&
                        back_errors[`${err_text}title`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <div className="hris_form_group">
                  <Select
                    name="title"
                    id="title"
                    value={values.title}
                    showSearch
                    onChange={(value) => handleSelect(value, "title")}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value="">--</option>
                    {Object.keys(utils).length &&
                    utils?.system_inputs["Title"].length
                      ? utils.system_inputs["Title"].map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                  <span className="d-flex align-items-center px-2">
                    <AiFillPlusCircle
                      size={25}
                      color={"green"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCatSelected({
                          title: "Title",
                          input: utils?.system_inputs?.["Title"],
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="last_name">
                    Surname/Last Name <span className="text-danger">*</span>
                  </label>

                  <small className="text-danger">
                    {errors.last_name ||
                      (back_errors[`${err_text}last_name`] &&
                        back_errors[`${err_text}last_name`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  onChange={handleChange}
                  value={values.last_name}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap">
                  <label htmlFor="first_name">
                    First Name/Given Name <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.first_name ||
                      (back_errors[`${err_text}first_name`] &&
                        back_errors[`${err_text}first_name`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={handleChange}
                  value={values.first_name}
                  placeholder="First Name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="other_names">Other Names</label>
                  <small className="text-danger">
                    {errors.other_names ||
                      (back_errors[`${err_text}other_names`] &&
                        back_errors[`${err_text}other_names`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="text"
                  name="other_names"
                  id="other_names"
                  onChange={handleChange}
                  value={values.other_names}
                  placeholder="Other Names"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="religion">Religion</label>
                  <small className="text-danger">
                    {errors.religion ||
                      (back_errors[`${err_text}religion`] &&
                        back_errors[`${err_text}religion`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <div className="hris_form_group">
                  <Select
                    name="religion"
                    id="religion"
                    value={values.religion}
                    showSearch
                    onChange={(value) => handleSelect(value, "religion")}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value="">--</option>
                    {Object.keys(utils).length &&
                    utils?.system_inputs["Religion"].length
                      ? utils.system_inputs["Religion"].map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Select>

                  <span className="d-flex align-items-center px-2">
                    <AiFillPlusCircle
                      size={25}
                      color={"green"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCatSelected({
                          title: "Religion",
                          input: utils?.system_inputs?.["Religion"],
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="gender">Gender</label>

                  <small className="text-danger">
                    {errors.gender ||
                      (back_errors[`${err_text}gender`] &&
                        back_errors[`${err_text}gender`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <div className="hris_form_group">
                  <Select
                    name="gender"
                    id="gender"
                    value={values.gender}
                    showSearch
                    onChange={(value) => handleSelect(value, "gender")}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value="">--</option>
                    {Object.keys(utils).length &&
                    utils?.system_inputs["Gender"].length
                      ? utils.system_inputs["Gender"].map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                  <span className="d-flex align-items-center px-2">
                    <AiFillPlusCircle
                      size={25}
                      color={"green"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCatSelected({
                          title: "Gender",
                          input: utils?.system_inputs?.["Gender"],
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="date_of_birth">Date Of Birth</label>
                  <small className="text-danger">
                    {errors.date_of_birth ||
                      (back_errors[`${err_text}date_of_birth`] &&
                        back_errors[`${err_text}date_of_birth`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="date"
                  name="date_of_birth"
                  value={values.date_of_birth}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="nationality">Nationality</label>
                  <small className="text-danger">
                    {errors.nationality ||
                      (back_errors[`${err_text}nationality`] &&
                        back_errors[`${err_text}nationality`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                {/* <input
                  type="text"
                  name="nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  placeholder="Nationality"
                /> */}
                <Select
                  name="nationality"
                  showSearch
                  onChange={(value) => handleSelect(value, "nationality")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  value={values.nationality}
                >
                  <option value="">--</option>
                  {countries
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((country, index) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="state_of_origin">State Of Origin</label>
                  <small className="text-danger">
                    {errors.state_of_origin ||
                      (back_errors[`${err_text}state_of_origin`] &&
                        back_errors[`${err_text}state_of_origin`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="state_of_origin"
                  showSearch
                  onChange={(value) => handleSelect(value, "state_of_origin")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  value={values.state_of_origin}
                >
                  <option value="">--</option>
                  {STATE_DATA.map((state, index) => (
                    <option key={index} value={state.alias}>
                      {state.state}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="province_lga">Province/LGA</label>
                  <small className="text-danger">
                    {errors.province_lga ||
                      (back_errors[`${err_text}province_lga`] &&
                        back_errors[`${err_text}province_lga`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="province_lga"
                  value={values.province_lga}
                  showSearch
                  onChange={(value) => handleSelect(value, "province_lga")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {lgasInState &&
                    lgasInState.map((lga, index) => (
                      <option key={index} value={lga}>
                        {lga}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="marital_status">Marital Status</label>
                  <small className="text-danger">
                    {errors.marital_status ||
                      (back_errors[`${err_text}marital_status`] &&
                        back_errors[`${err_text}marital_status`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <div className="hris_form_group">
                  <Select
                    name="marital_status"
                    id="marital_status"
                    value={values.marital_status}
                    showSearch
                    onChange={(value) => handleSelect(value, "marital_status")}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value="">--</option>
                    {Object.keys(utils).length &&
                    utils?.system_inputs["Marital Status"].length
                      ? utils.system_inputs["Marital Status"].map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Select>
                  <span className="d-flex align-items-center px-2">
                    <AiFillPlusCircle
                      size={25}
                      color={"green"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCatSelected({
                          title: "Marital Status",
                          input: utils?.system_inputs?.["Marital Status"],
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showCatpage} footer={null} centered onCancel={handleCatpage}>
        <InputCategories
          handleCatpage={handleCatpage}
          selected={selected}
          refetch={refetch}
        />
      </Modal>
    </div>
  );
}
