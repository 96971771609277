import React, { useEffect, useState } from "react";
import "../../../styles/leaveHistory.css";
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Space,
  Steps,
  Table,
} from "antd";
import {
  useGetAnnualLeaveHistory,
  useGetLeaveHistory,
  useGetTrackLeave,
} from "../../../util/usershookActions";

import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  annual_leave_history_employee,
  department,
  leave_history_employee,
} from "../../../util/selectors/userSelectors";
import { AiOutlineSearch } from "react-icons/ai";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { cancelMyLeaveRequest } from "../../../redux/users/userActions";

export default function LeaveHistory() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [annualEnabled, setAnnualEnabled] = useState(true);
  const [leaveHistoryData, setLeaveHistoryData] = useState([]);
  const [annualLeaveHistoryData, setAnnualLeaveHistoryData] = useState([]);
  const [pageLHstory, setPageLHstory] = useState(1);
  const [pageAnnualLHstory, setPageAnnualLHstory] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [tfrom, setTFrom] = useState("");
  const [teamto, setTeamTo] = useState("");
  const [tdates, setTDates] = useState({
    from: "",
    to: "",
  });

  const onStart = (date) => {
    console.log({ date });
    console.log(dayjs(date.Sd).format("YYYY-MM-DD"));
    setDates(() => ({
      ...dates,
      from: dayjs(date?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ from });
  };
  const onEnd = (date_end) => {
    console.log({ date_end });
    setDates(() => ({
      ...dates,
      to: dayjs(date_end?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ to, from });
  };
  useEffect(() => {
    if (dates.from && dates.to) {
      setFrom(dates.from);
      setTo(dates.to);
    }
  }, [dates]);

  useEffect(() => {
    if (from && to) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
    console.log({ to, from });
  }, [from, to]);

  const onTStart = (date) => {
    console.log({ date });
    console.log(dayjs(date.Sd).format("YYYY-MM-DD"));
    setTDates(() => ({
      ...tdates,
      from: dayjs(date?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ from });
  };
  const onTEnd = (date_end) => {
    console.log({ date_end });
    setTDates(() => ({
      ...tdates,
      to: dayjs(date_end?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ to, from });
  };
  useEffect(() => {
    if (tdates.from && tdates.to) {
      setTFrom(dates.from);
      setTeamTo(dates.to);
    }
  }, [tdates]);

  useEffect(() => {
    if (tfrom && teamto) {
      setAnnualEnabled(true);
    } else {
      setAnnualEnabled(false);
    }
    console.log({ to, from });
  }, [tfrom, teamto, to, from]);

  const departments = useShallowEqualSelector(department);
  console.log({ departments });

  const { refetch, isLoading } = useGetLeaveHistory(
    enabled,
    setEnabled,
    pageLHstory,
    from,
    to
  );
  const leave_history = useShallowEqualSelector(leave_history_employee);
  console.log({ leave_history });

  const { refetch: refetchTeam } = useGetAnnualLeaveHistory(
    annualEnabled,
    setAnnualEnabled,
    pageAnnualLHstory,
    tfrom,
    teamto
  );
  const annual_leave_history = useShallowEqualSelector(
    annual_leave_history_employee
  );

  useEffect(() => {
    if (Object.keys(leave_history)?.length) {
      const new_array = leave_history?.payload?.data.map((leave) => {
        return {
          ...leave,
          key: leave.id,
          ["leave type"]: leave.leave_group.title,
          ["start date"]: leave.start_date,
          ["end date"]: leave.end_date,
          status: leave.status,
          ["approval date"]: leave.approval_date || "N/A",
          id: leave.id,
          // batch: leave.batch,
        };
      });
      setLeaveHistoryData(new_array);
      console.log({ new_array });
    }

    console.log({ leaveHistoryData });
  }, [leave_history]);

  useEffect(() => {
    if (Object.keys(annual_leave_history)?.length) {
      const new_array = annual_leave_history?.payload?.data?.map((leave) => {
        return {
          ...leave,
          key: leave.id,
          ["leave type"]: leave?.leave_group?.title,
          ["start date"]: leave?.start_date,
          ["end date"]: leave?.end_date,
          ["no of days"]: leave?.duration,
          ["no of available days"]: leave?.approval_date || "N/A",
          id: leave?.id,

          // batch: leave.batch,
        };
      });
      console.log({ new_array_annual: new_array });
      setAnnualLeaveHistoryData(new_array);
    }
  }, [annual_leave_history]);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${dataIndex}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  const leaveHistory = [...leaveHistoryData];

  const leaveHistoryColumns = [
    {
      title: "LEAVE TYPE",
      dataIndex: "leave type",
      key: "leave type",
      ...getColumnSearchProps("leave type"),
    },
    {
      title: "START DATE",
      dataIndex: "start date",
      key: "start date",
      ...getColumnSearchProps("start date"),
    },
    {
      title: "END DATE",
      dataIndex: "end date",
      key: "end date",
      ...getColumnSearchProps("end date"),
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      key: "duration",
      ...getColumnSearchProps("duration"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
    },
    {
      title: "APPROVAL DATE",
      dataIndex: "approval date",
      key: "approval date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, record) => (
        <EachAction
          record={record}
          // NugdeNext={NugdeNext}
          // ApproveregetRequest={ApproveregetRequest}
          refetch={refetch}
        />
      ),
    },
  ];
  // annualLeave
  const annualLeave = [...annualLeaveHistoryData];

  const annualLeaveColumns = [
    {
      title: "LEAVE TYPE",
      dataIndex: "leave type",
      key: "leave type",
      ...getColumnSearchProps("leave type"),
    },
    {
      title: "START DATE",
      dataIndex: "start date",
      key: "start date",
      ...getColumnSearchProps("start date"),
    },
    {
      title: "END DATE",
      dataIndex: "end date",
      key: "end date",
      ...getColumnSearchProps("end date"),
    },
    {
      title: "No of days",
      dataIndex: "no of days",
      key: "no of days",
      ...getColumnSearchProps("no of days"),
    },
    {
      title: "no of available days",
      dataIndex: "no of available days",
      key: "no of available days",
      ...getColumnSearchProps("no of available days"),
    },
  ];
  function handlePaginationLHistory(page) {
    setPageLHstory(page);
    setEnabled(true);
  }

  function handlePaginationAnnualLHistory(page) {
    setPageAnnualLHstory(page);
    setAnnualEnabled(true);
  }
  return (
    <div className="container-fluid">
      <section className="app__section py-4">
        <main className="leaveHistory_main">
          <div className="d-flex justify-content-between">
            <h4 className="" style={{ width: "300px" }}>
              My leave history
            </h4>
            {leaveHistoryData.length > 0 && (
              <div
                className="mb-2 text-end d-flex justify-content-end"
                style={{ width: "100%" }}
              >
                <DatePicker
                  onChange={onStart}
                  className="me-2"
                  allowClear={true}
                />
                <DatePicker onChange={onEnd} allowClear={true} />
              </div>
            )}
          </div>
          <hr className="leaveHistory_line" />

          {isLoading ? (
            <SkeletonUI />
          ) : leaveHistoryData.length > 0 ||
            annualLeaveHistoryData.length > 0 ? (
            <>
              <div className="leaveTable mx-auto">
                {leaveHistoryData.length > 0 ? (
                  <div>
                    <div className=" ">
                      <Table
                        dataSource={leaveHistory}
                        pagination={false}
                        // pagination={leaveHistory.length > 12 ? { pageSize: 12 } : false}
                        columns={leaveHistoryColumns}
                        className="leaveHistory_table"
                      />
                    </div>
                    <div className="mt-3 d-flex justify-content-end">
                      {leave_history?.payload?.meta?.last_page > 1 ? (
                        <Pagination
                          total={leave_history?.payload?.meta?.last_page}
                          pageSize={1}
                          onChange={handlePaginationLHistory}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="w-100 mx-auto">
                    <NoContent width={"250px"} />
                  </div>
                )}
              </div>
              <div className="annualLeavePlanTabelDesc">
                <div className="d-flex justify-content-between">
                  <h4 style={{ width: "300px" }}>Annual Leave Plan</h4>
                  {leaveHistoryData.length > 0 && (
                    <div
                      className="mb-2 text-end d-flex justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <DatePicker
                        onChange={onTStart}
                        className="me-2"
                        allowClear={true}
                      />
                      <DatePicker onChange={onTEnd} allowClear={true} />
                    </div>
                  )}
                </div>
                {/* <p>Employees cannot exceed 15 days for their annual leave </p> */}
                <hr className="leaveHistory_line" />
              </div>
              <div className="annualLeavePlanTable mx-auto">
                {annualLeaveHistoryData.length > 0 ? (
                  <>
                    <Table
                      dataSource={annualLeave}
                      columns={annualLeaveColumns}
                      pagination={false}
                      className="annualLeave_table"
                      // pagination={annualLeave.length > 12 ? { pageSize: 12 } : false}
                    />
                    <div className="mt-3 d-flex justify-content-end">
                      {annual_leave_history?.payload?.meta?.last_page > 1 ? (
                        <Pagination
                          total={annual_leave_history?.payload?.meta?.last_page}
                          pageSize={1}
                          onChange={handlePaginationAnnualLHistory}
                        />
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="w-100 mx-auto">
                    <NoContent width={"250px"} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <NoContent
              illustration="/assets/leaveimage.png"
              width="320"
              message="No leave application"
            />
          )}
        </main>
      </section>
    </div>
  );
}

function EachAction({ record, refetch }) {
  const [showModalTrack, setShowModalTrack] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [track_details, setTrackDetails] = useState({});
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  console.log({ record });

  const { isLoading, data } = useGetTrackLeave(enabled, setEnabled, record.id);

  // console.log({ track_details });

  useEffect(() => {
    if (data) {
      setTrackDetails(data?.payload || {});
      if (data?.payload?.awaiting) {
        setCurrent(data?.payload?.awaiting.approval_level - 1);
      } else {
        setCurrent(0);
      }
    }
  }, [data]);

  const handleModalTrack = (e) => {
    e.preventDefault();
    setShowModalTrack(!showModalTrack);
  };

  function getStep(id) {
    if (id === 1) {
      return (
        <Steps
          current={current}
          labelPlacement="vertical"
          items={[
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.first_approver?.first_name}{" "}
                    {track_details?.first_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.first_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.first_approver?.avatar || "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
          ]}
        />
      );
    } else if (id === 2) {
      return (
        <Steps
          current={current}
          labelPlacement="vertical"
          items={[
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.first_approver?.first_name}{" "}
                    {track_details?.first_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.first_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.first_approver?.avatar || "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.second_approver?.first_name}{" "}
                    {track_details?.second_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.second_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.second_approver?.avatar ||
                      "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
          ]}
        />
      );
    } else {
      return (
        <Steps
          current={current}
          labelPlacement="vertical"
          items={[
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.first_approver?.first_name}{" "}
                    {track_details?.first_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.first_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.first_approver?.avatar || "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.second_approver?.first_name}{" "}
                    {track_details?.second_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.second_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.second_approver?.avatar ||
                      "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
            {
              title: (
                <>
                  <p className="text-nowrap mt-3 ">
                    {" "}
                    {track_details?.third_approver?.first_name}{" "}
                    {track_details?.third_approver?.last_name}{" "}
                  </p>
                </>
              ),
              description: (
                <>
                  {track_details?.third_approver_status === "PENDING" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    <span className="text-success">Approved</span>
                  )}
                  <Avatar
                    size={64}
                    src={
                      track_details?.third_approver?.avatar || "/assets/man.png"
                    }
                  />
                  ,
                </>
              ),
            },
          ]}
        />
      );
    }
  }

  function confirm() {
    console.log({ record });
    const creds = {
      batch: record.batch,
    };
    setLoading(true);
    cancelMyLeaveRequest(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }
  return (
    <>
      {record.status === "PENDING" ? (
        <>
          <Button
            className={`rounded p-1 px-2 border border-1 text-white me-1 bg-primary`}
            onClick={handleModalTrack}
          >
            Track Request
          </Button>
          <Modal
            open={showModalTrack}
            footer={null}
            onCancel={handleModalTrack}
            maskClosable={false}
            width={900}
          >
            <h5>Leave Tracker</h5>
            <hr />

            {isLoading ? (
              <SkeletonUI number={1} />
            ) : Object.keys(track_details).length ? (
              <div className="mt-5 mb-4">
                {data && getStep(data?.payload?.leave_approval_workflow_id)}
              </div>
            ) : (
              <NoContent message={"No track record available"} />
            )}
          </Modal>
        </>
      ) : null}

      <Popconfirm
        title="Cancel Leave Request"
        description="Are you sure want to perform this action?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
          loading={loading}
        >
          Cancel
        </Button>
      </Popconfirm>
    </>
  );
}
