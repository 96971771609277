import React from "react";

import { NavLink } from "react-router-dom";

function PayStructureDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"pay-structure"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Pay Structure</span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          to={"pay-structure/job-grading"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Job Grading</span>
        </NavLink>
      </li> */}
    </ul>
  );
}

export default PayStructureDropdown;
