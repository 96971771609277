import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";

import { user_errors } from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserDependentInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import { useOutletContext } from "react-router-dom";

export default function ApproveDependant() {
  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();

  const back_errors = useShallowEqualSelector(user_errors);

  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingApp, setLoadingApp] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);
  const [details, setEnabled] = useOutletContext();

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserDependentInfo(dispatch, {
        ...values,
        notify: false,
        except: exempt,
      }).then((res) => {
        setLoadingAppN(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  }

  function handleApproveNotify() {
    setLoadingApp(true);
    approveUserDependentInfo(dispatch, values).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  }

  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  if (details[0]) {
    init_details = { ...details[0], except: [], notify: true };
  } else {
    init_details = {
      name: "",
      genotype: "",
      blood_group: "",
      telephone: "",
    };
  }

  const callbackFn = () => {
    if (details[0]) {
      approveandnotify();
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };
  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };
  const confirm = (id) => {
    setLoading(true);
    rejectUserInfo(dispatch, { ...values, info_type: "dependant" }).then((res) => {
      setLoading(false);
      setShowModal(false);
      Modal.destroyAll();
      
      if (res.status === "success") {
        setEnabled(true);
      }
    });
  };

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    // alert("here");
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <DashboardCard
      title={"Dependant Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="name">
              {" "}
              Name <span>*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.name || (back_errors.name && back_errors.name[0])}
              </small>
            </div>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              id="name"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="name"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="genotype"> Genotype</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.genotype ||
                  (back_errors.genotype && back_errors.genotype[0])}
              </small>
            </div>
            <input
              type="text"
              name="genotype"
              onChange={handleChange}
              value={values.genotype}
              id="genotype"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="genotype"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="blood_group">Blood Group </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.blood_group ||
                  (back_errors.blood_group && back_errors.blood_group[0])}
              </small>
            </div>
            <input
              type="text"
              name="blood_group"
              onChange={handleChange}
              value={values.blood_group}
              id="blood_group"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="blood_group"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-12  approval_side_checkbox d-flex flex-column">
            <label htmlFor="telephone">Telephone </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.telephone ||
                  (back_errors.telephone && back_errors.telephone[0])}
              </small>
            </div>
            <input
              type="text"
              name="telephone"
              onChange={handleChange}
              value={values.telephone}
              id="telephone"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="telephone"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn "
            loading={loadingApp}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>

          <Button
            size="large"
            className="myBtn "
            loading={loadingAppN}
            onClick={approve}
            disabled={disabled}
          >
            Approve
          </Button>

          <Button
            style={{ backgroundColor: "red", color: "white" }}
            onClick={warning}
            size="large"
            className="myBtn "
            loading={loading}
          >
            Reject
          </Button>
        </div>

        <Modal
          title={`Reject request`}
          centered
          onOk={() => confirm(values?.id)}
          onCancel={modalHandler}
          open={showModal}
          cancelText="No"
          okText="Yes"
        >
          <p className="h6">Are you sure to reject this request?</p>
        </Modal>
      </form>
    </DashboardCard>
  );
}
