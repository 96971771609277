import { Flex } from "antd";
import { CiCircleCheck } from "react-icons/ci";
import Task from "./Task";
import AddTaskButton from "./AddTaskButton";

const TasksList = ({ categoryTitle, tasks, setTasks, addTask }) => {
  return (
    <Flex vertical gap="2rem" className="todo__tasks__container">
      <div>
        <h2 className="h3">{categoryTitle}</h2>
        <span className="text-muted fs-6 d-flex gap-2 align-items-center">
          <CiCircleCheck size={20} /> <span>4 Tasks</span>
        </span>
      </div>
      <div className="d-flex flex-column gap-5">
        <Task />
        <Task />
      </div>
      {addTask && <AddTaskButton noBackground={true} />}
    </Flex>
  );
};

export default TasksList;
