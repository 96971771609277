import React from "react";
import HireProfile from "./HireProfile";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

const TrackHire = () => {
  const params = queryString.parse(useLocation().search);
  return (
    <div className="container">
      <div className="row">
        <HireProfile id={params.hire_id} admin={false} />
      </div>
    </div>
  );
};

export default TrackHire;
