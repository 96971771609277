import React, { useEffect, useRef, useState } from "react";
import "../../../styles/requestEmployee.css";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import {
  useMyTeamMembers,
  useGetSystemHolidays,
  useGetUserLeaveGroup,
} from "../../../util/usershookActions";
import {
  all_leavegroups,
  holiday,
  user_errors,
  user_profile,
} from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import { Button, Select, DatePicker } from "antd";
import { addUserLeaveRequestHr } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { FaFileAlt } from "react-icons/fa";
import dayjs from "dayjs";

export default function RequestForEmployeeTeam() {
  useUserCleanUp();
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  useUserCleanUp();

  const [enabled, setEnabled] = useState(true);
  const [enableLeaveGroup, setEnableLeaveGroup] = useState(false);

  const [users, setUsers] = useState({
    employee_name: "",
    employee_id: "",
    user_id: "",
    start_date: "",
    end_date: "",
    comment: "",
    file: "",
    leave_group_id: "",
  });
  const [backUp, setBackUp] = useState({
    backUp_name: "",
    backUp_id: "",
  });
  const [selectedFile, setSelectedFile] = useState("");
  const [backUpImage, setBackUpImage] = useState("");
  const [animeLoadSave, setAnimeLoadSave] = useState(false);
  const [activeText, setActiveText] = useState("");
  const [enabledhol, setEnabledhol] = useState(true);
  const [hol, setHol] = useState([]);

  // const {
  //   data,
  //   isLoading: employeeLoading,
  //   refetch: refetchEmployee,
  // } = useMyTeamMembers(enabled, setEnabled);

  const {
    data: datad,
    isLoading: employeeLoading,
    refetch: refetchEmployee,
  } = useGetAllEmployee(enabled, setEnabled, "all");

  const { data: data } = useMyTeamMembers(enabled, setEnabled);

  console.log({ data, datad });

  const all_users = useShallowEqualSelector(all_groups);

  const profile = useShallowEqualSelector(user_profile);
  const back_errors = useShallowEqualSelector(user_errors);
  // console.log({ back_errors });

  useGetSystemHolidays(enabledhol, setEnabledhol);
  const holidays = useShallowEqualSelector(holiday);

  const handleEmployeeSelect = (value, name) => {
    if (name == "employee_name") {
      setUsers((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setBackUp({
        backUp_name: name,
        backUp_id: value,
      });
    }
  };

  useEffect(() => {
    if (users.employee_name) {
      const user = data?.payload?.members?.find(
        (user) => user.fullname === users.employee_name
      );
      setUsers((prev) => ({
        ...prev,
        employee_id: user.employee_id || "",
        user_id: parseInt(user.id),
      }));
    }
    if (users?.user_id !== "") {
      setEnableLeaveGroup(true);
    }
  }, [data?.payload?.members, users.employee_name, users?.user_id]);

  const {
    data: userLeaveGroup,
    isLoading,
    refetch,
  } = useGetUserLeaveGroup(
    enableLeaveGroup,
    setEnableLeaveGroup,
    users.user_id
  );

  useEffect(() => {
    const selectedBackUp = data?.payload?.members?.find(
      (user) => user.id === backUp.backUp_id
    );
    setBackUpImage(selectedBackUp?.avatar);
  }, [backUp, data?.payload?.members]);

  const handleLeaveType = (leavetype) => {
    setActiveText(leavetype.leave_group.title);
    setUsers((prev) => ({
      ...prev,
      leave_group_id: parseInt(leavetype.leave_group_id),
    }));
  };

  const handleDate = (e) => {
    e.preventDefault();
    setUsers((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFile = (e) => {
    e.preventDefault();
    const { files } = e.target;
    setUsers((prev) => ({
      ...prev,
      file: files[0],
    }));
    setSelectedFile(files[0].name);
  };
  const handleClear = () => {
    setUsers((prev) => ({
      ...prev,
      file: null,
    }));
    setSelectedFile("");
  };

  const buttonHandler = () => {
    uploadRef.current.click();
    uploadRef.current.value = "";
  };

  const handleComent = (e) => {
    const comment = e.target.value;
    setUsers((prev) => ({
      ...prev,
      comment,
    }));
  };

  const creds = {
    start_date: users.start_date,
    end_date: users.end_date,
    comment: users.comment,
    file: users.file,
    user_id: users.user_id,
    leave_group_id: users.leave_group_id,
    backup_employee_id: backUp.backUp_id,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAnimeLoadSave(true);
    addUserLeaveRequestHr(dispatch, {
      ...creds,
      batch:
        Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    }).then((res) => {
      setAnimeLoadSave(false);
      if (res?.status === "success") {
        setUsers({
          start_date: "",
          end_date: "",
          file: "",
          comment: "",
          backup_employee_id: "",
          employee_name: "",
          employee_id: "",
          leave_group_id: "",
        });
        setBackUp({
          backUp_name: "",
          backUp_id: "",
        });
      }
      dispatch({
        type: "ADD_LEAVE_REQUEST_HR",
        payload: res,
      });
    });
  };

  useEffect(() => {
    // console.log({ users });
    // console.log({ backUp });
  }, [users, backUp]);

  const disabledDate = (current) => {
    // Check if the day is Saturday or Sunday

    return (
      (current && current.day() === 0) ||
      current.day() === 6 ||
      current < dayjs().startOf("day") ||
      hol.includes(current.format("MM/DD"))
    );
  };

  useEffect(() => {
    if (holidays.length) {
      const newholidays = holidays.map((holiday) =>
        dayjs(holiday.date).format("MM/DD")
      );
      setHol(newholidays);
    }
  }, [holidays, holidays.length]);

  const handleDateAnt = (date, dateString, name) => {
    // console.log({ date, dateString, name });

    setUsers((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  };
  return (
    <div className="container-fluid request_employee_body">
      <section className="app__section py-4">
        <div className="request_emplyee_head">New Leave Request</div>
        <section className="app__section_no_border mt-3 py-3">
          <article>
            <h6>1. Select Employee</h6>
          </article>
          <article>
            <form action="" className="performance-form leaveForm_allrequest">
              <div className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between flex-md-wrap">
                  <label
                    htmlFor="eName"
                    className=""
                    id="label__leave_allrequest"
                  >
                    Employee Name
                  </label>
                  {/* <small className="text-danger error">{Object.keys(back_errors).length > 0 &&  back_errors?.employee_name[0]}</small> */}
                </div>
                {/* <input name="eName" type="text" className='input_leave' value='Isreal Enterprise' disabled/> */}
                <Select
                  name="employee_name"
                  id="employee_name"
                  value={users.employee_name}
                  className="employee_select w-100"
                  showSearch
                  onChange={(value) =>
                    handleEmployeeSelect(value, "employee_name")
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {data?.payload?.members?.map((user) => (
                    <option value={user.fullname} key={user.id}>
                      {user.fullname}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="eId"
                  className="label__leave"
                  id="label__leave_allrequest"
                >
                  Employee ID
                </label>
                <input
                  name="eId"
                  type="text"
                  className="input_leave w-100"
                  value={users.employee_id}
                  disabled
                />
              </div>
            </form>
          </article>
          <hr className="allrequest_line my-5" />
          <article className="mb-5">
            <div className="d-flex justify-content-between flex-md-wrap">
              <h6 className="mb-5">2. Select a leave category</h6>
              <small className="text-danger error">
                {back_errors?.leave_group_id && back_errors?.leave_group_id[0]}
              </small>
              {/* <small className="text-danger error">{Object.keys(back_errors).length > 0 &&  back_errors?.leave_group_id[0]}</small> */}
            </div>
            <div className="newLeaveCatWrapper_allrequest">
              {/* <article className='active__box_allrequest'>
          <p><span className='allrequest_no'>2/5</span>Sick <span className='newLeaveCattext_allrequest' >leave</span></p>
          <span className='newLeaveCatBox_allrequest active_box_allrequest'></span>
        </article>
        <article >
          <p><span className='allrequest_no'>2/5</span>Maternity <span className='newLeaveCattext_allrequest'>leave</span></p>
          <span className='newLeaveCatBox'></span>
        </article>
        <article>
          <p><span className='allrequest_no'>2/5</span>Paternity <span className='newLeaveCattext_allrequest'>leave</span></p>
          <span className='newLeaveCatBox'></span>
        </article>
        <article>
          <p><span className='allrequest_no'>2/5</span>Vacation <span className='newLeaveCattext_allrequest'>leave</span></p> 
          <span className='newLeaveCatBox'></span>
        </article>
        <article>
          <p><span className='allrequest_no'>2/5</span>Study <span className='newLeaveCattext_allrequest'>leave</span></p> 
          <span className='newLeaveCatBox'></span>
        </article>
        <article>
          <p><span className='allrequest_no'>2/5</span>Wedding <span className='newLeaveCattext_allrequest'>leave</span></p> 
          <span className='newLeaveCatBox'></span>
        </article> */}
              {isLoading ? (
                <SkeletonUI number={1} />
              ) : users.user_id && userLeaveGroup ? (
                userLeaveGroup.payload[0].map((leavetype) => {
                  return (
                    <article
                      className={`${
                        activeText === leavetype.leave_group.title
                          ? "active__box"
                          : ""
                      }`}
                      key={leavetype.leave_group.id}
                      onClick={() => handleLeaveType(leavetype)}
                    >
                      <p>
                        <span className="newrequest_no mb-1">
                          <span>{leavetype.used}</span>/{leavetype.available}
                        </span>
                        {leavetype.leave_group.title}{" "}
                        <span className="newLeaveCattext mt-1">leave</span>
                      </p>
                      <span
                        className={`newLeaveCatBox ${
                          activeText === leavetype.leave_group.title
                            ? "active_box"
                            : ""
                        }`}
                      ></span>
                    </article>
                  );
                })
              ) : (
                <div className="w-100 mx-auto">
                  <NoContent
                    width={"250px"}
                    message={"No leave grade distribution found"}
                  />
                </div>
              )}
            </div>
          </article>
        </section>
      </section>
      <section className="app__section py-4">
        <section className="app__section_no_border mt-3 py-3">
          <article>
            <h6>3. Select backup employee and provide information</h6>

            <form action="" className="performance-form leaveForm_allrequest">
              <div className="Bemployee mb-3">
                <div className="d-flex justify-content-between flex-md-wrap">
                  <label htmlFor="bEmploy" id="label__leave_allrequest">
                    Back up Employee
                  </label>
                  <small className="text-danger error">
                    {back_errors?.backup_employee_id &&
                      back_errors?.backup_employee_id[0]}
                  </small>
                </div>
                {/* <input name="bEmploy" type="text" className="input_leave" value='Ebenezer Babalola-SSL-2023-001' disabled/>*/}
                <Select
                  name="backup_employee"
                  id="backup_employee"
                  value={users.backUp_employee_name}
                  className="backup_select"
                  showSearch
                  onChange={(value) =>
                    handleEmployeeSelect(value, "backUp_employee_name")
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {data?.payload?.members?.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.fullname}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="leaveImage mb-3">
                <span className="avatar">
                  <img
                    src={
                      backUpImage
                        ? backUpImage
                        : "/assets/woman-placeholder.png"
                    }
                  />
                </span>
              </div>
              <div className="row mb-5">
                <div className="col-6">
                  <div className="d-flex justify-content-between flex-md-wrap">
                    <label
                      htmlFor="start_date"
                      className="label__leave "
                      id="label__leave_allrequest"
                    >
                      Start date
                    </label>
                    <small className="text-danger error">
                      {back_errors?.start_date && back_errors?.start_date[0]}
                    </small>
                  </div>
                  <DatePicker
                    disabledDate={disabledDate}
                    className="input_leave data-my"
                    onChange={(date, dateString) =>
                      handleDateAnt(date, dateString, "start_date")
                    }
                  />
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-between flex-md-wrap">
                    <label
                      htmlFor="end_date"
                      className=""
                      id="label__leave_allrequest"
                    >
                      End date
                    </label>
                    <small className="text-danger error">
                      {back_errors?.end_date && back_errors?.end_date[0]}
                    </small>
                  </div>
                  <DatePicker
                    disabledDate={disabledDate}
                    className="input_leave data-my"
                    onChange={(date, dateString) =>
                      handleDateAnt(date, dateString, "end_date")
                    }
                  />
                </div>
              </div>
              <div className="leaveAttachFile_allrequest mb-5">
                <h4>Attachment</h4>
                <p>
                  You can import evidences or files that would help your request
                  better
                </p>
                <div className="leaveInputFile">
                  <img
                    src="/assets/uploadToCloud.png"
                    onClick={buttonHandler}
                  />
                  <p className="leaveInputeFileText">upload file</p>
                  <p className="w-100 text-start">
                    {selectedFile && (
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <FaFileAlt
                            // className="h1"
                            style={{ color: "var(--clr-primary)" }}
                          />{" "}
                          <span>{selectedFile}</span>
                        </div>
                        <small
                          className="pointer text-danger"
                          onClick={handleClear}
                        >
                          cancel
                        </small>
                      </div>
                    )}
                  </p>
                  <input
                    type="file"
                    className="leaveFileUpload"
                    ref={uploadRef}
                    onChange={handleFile}
                  />
                </div>
              </div>
              <div className="leaveDescription mb-5">
                <label
                  htmlFor="description_allrequest"
                  id="label__leave_allrequest"
                >
                  Comment
                </label>
                <p className="descriptionText_allrequest">
                  {" "}
                  You can provide information to help your request better
                </p>
                <textarea
                  rows="6"
                  placeholder="i am not feeling fine"
                  className="input_leave"
                  onChange={handleComent}
                />
              </div>
              <div className="d-flex justify-content-end mb-3">
                <Button
                  className="myBtn py-2 mb-3 px-3"
                  htmlType="submit"
                  loading={animeLoadSave}
                  onClick={(e) => handleSubmit(e)}
                >
                  Request Leave
                </Button>
              </div>
            </form>
          </article>
        </section>
      </section>
    </div>
  );
}
