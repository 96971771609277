import React from "react";
import { RiPulseLine } from "react-icons/ri";

const InsightsBar = () => {
  return (
    <div className="d-flex flex-nowrap insightBar">
      <div
        className="insightBox"
        style={{ backgroundColor: "rgba(248, 248, 251, 1)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Hires</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgba(215, 226, 255, 1)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Hires</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgba(255, 220, 231, 1)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Batches</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgba(232, 255, 244, 1)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Hire Cycles</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgba(167, 216, 129, 1)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Hires</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgb(221, 255, 187)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Hire Cycles</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgb(173, 228, 219)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Batches</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="insightBox"
        style={{ backgroundColor: "rgb(109, 169, 228)" }}
      >
        <div className="d-flex align-items-center gap-4">
          <div>
            <RiPulseLine />
          </div>
          <div>
            <div className="">
              <h4>Total Hires</h4>
            </div>
            <div className="">
              <h2 className="mb-0">{5}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightsBar;
