import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { Button, Modal } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { updateUserDependentInfo } from "../../../redux/users/userActions";
import data from "../../../util/util.json";
import DependantCard from "./DependantCard";
import { useGetUserProfile } from "../../../util/usershookActions";
import { FaHospitalUser } from "react-icons/fa";
import { MdAddCircleOutline, MdOutlineAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import Warning from "../../ui/Warning";

const { medical } = data;

function Dependants() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useGetUserProfile(enabled, setEnabled);
  const dispatch = useDispatch();
  const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);
  const dependant_info = userProfile?.dependant;
  console.log("userprofile----", userProfile);
  const init_details = {
    name: "",
    genotype: "",
    blood_group: "",
    telephone: "",
    date_of_birth: "",
  };

  const callbackFn = () => {
    values.user_id = userProfile?.id;
    console.log({ values: values });
    updateUserDependentInfo(dispatch, values).then((res) => {
      if (res?.status === "success") {
        clearForm();
        setEnabled(true);
        setShowModal(false);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`${styles.title} greeting_2`}>
          <div>
            <h4>Dependants</h4>
            <p>Review and update your dependants information</p>
          </div>
          <FaHospitalUser />
        </div>
      </div>
      <Modal
        okText={"Add"}
        open={showModal}
        onCancel={modalHandler}
        footer={null}
        centered
      >
        <h5 className="text-dark mb-4 d-flex align-items-center gap-2">
          {<MdOutlineAdd />} Add Dependant
        </h5>
        {Object.keys(utils).length && utils?.others["Approval"].length
          ? utils.others["Approval"].map((item) => {
              if (item.name === "Dependant" && item.deleted_at === null) {
                return <Warning />;
              } else {
                return null;
              }
            })
          : null}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label htmlFor="name">Name</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.name || (back_errors.name && back_errors.name[0])}
                </small>
              </div>

              <input
                type="text"
                name="name"
                onChange={handleChange}
                value={values.name}
                id="name"
              />
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
              <label htmlFor="genotype">Genotype</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.genotype ||
                    (back_errors.genotype && back_errors.genotype[0])}
                </small>
              </div>
              <select
                name="genotype"
                onChange={handleChange}
                id="genotype"
                value={values.genotype}
              >
                <option value="">--</option>
                {medical.genotype.map(({ id, key, name }) => (
                  <option key={id} value={key}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
              <label htmlFor="blood_group">Blood Group</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.blood_group ||
                    (back_errors.blood_group && back_errors.blood_group[0])}
                </small>
              </div>
              <select
                name="blood_group"
                id="blood_group"
                onChange={handleChange}
                value={values.blood_group}
              >
                <option value="">--</option>
                {medical.blood_group.map(({ id, key, name }) => (
                  <option key={id} value={key}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
              <label htmlFor="telephone">Telephone</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.telephone ||
                    (back_errors.telephone && back_errors.telephone[0])}
                </small>
              </div>
              <input
                type="text"
                name="telephone"
                onChange={handleChange}
                value={values.telephone}
                id="telephone"
              />
            </div>
            <div className="col-12 mt-3 d-flex flex-column">
              <label htmlFor="date_of_birth">Date of birth</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.date_of_birth ||
                    (back_errors.date_of_birth && back_errors.date_of_birth[0])}
                </small>
              </div>
              <input
                type="date"
                name="date_of_birth"
                onChange={handleChange}
                value={values.date_of_birth}
                id="date_of_birth"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="myBtn  mt-3"
              loading={userSpinner}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>
      <div className="col-md-12 p-5">
        <div className="row">
          {dependant_info?.length ? (
            dependant_info.map((dependant) => (
              <DependantCard key={dependant.id} info={dependant} />
            ))
          ) : (
            <div className="no-content-box">
              <img src="/assets/dependants.jpg" alt="no content illustration" />
              <p className="mb-3">No Dependant Added!</p>
              <Button onClick={modalHandler} className={styles.uploadBtn}>
                <HiUpload />
                Add Dependant
              </Button>
            </div>
          )}
          {dependant_info?.length ? (
            <div className="col-md-4 mb-5">
              <div
                className="card bg-white h-100"
                style={{ cursor: "pointer" }}
                onClick={modalHandler}
              >
                <div
                  className="card-body d-flex justify-content-center align-items-center"
                  style={{ height: 230 }}
                >
                  <MdAddCircleOutline className={styles.addEntryBtn} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Dependants;
