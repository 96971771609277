import React, { useState, useEffect } from "react";

// import { Button, List, Popconfirm } from "antd";

import { useDispatch } from "react-redux";
import { Button, Popconfirm, Table, Input, Space } from "antd";

import { useGetAllLeaveGroup } from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  leave_group,
  user_errors,
} from "../../../util/selectors/userSelectors";
import {
  addEditLeaveGroup,
  deleteLeaveGroup,
} from "../../../redux/users/userActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { AiOutlineSearch } from "react-icons/ai";
const avail_days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
function GroupList() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const [addDept, setAddDept] = useState(false);

  const [values, setValues] = useState({
    title: "",
    description: "",
    notice_period_days: "",
    notification_interval: "",
    allow_multiple_team_member_applications: 1,

    schedule_announcement: 1,
    application_start_date: "",
    application_end_date: "",
  });

  const { refetch, isLoading } = useGetAllLeaveGroup(enabled, setEnabled);

  const back_errors = useShallowEqualSelector(user_errors);

  const sys_leave_group = useShallowEqualSelector(leave_group);

  const clearSelected = () => {
    setSelected({});
    setValues({
      title: "",
      description: "",
      notice_period_days: "",
      notification_interval: "",
      allow_multiple_team_member_applications: 1,
      schedule_announcement: 1,
      application_start_date: "",
      application_end_date: "",
    });
  };

  const handleAddDept = () => {
    clearSelected();
    setAddDept(!addDept);
  };

  const handleSelected = (item) => {
    // console.log({ item });
    setSelected(item);
    setValues(item);
    setAddDept(true);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };
  const handleCheck = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: 1 };
    });
  };
  const handleCheckfalse = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: 0 };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log({ ok: values });

    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };

    delete creds.key;
    delete creds.index;
    addEditLeaveGroup(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          title: "",
          description: "",
          notice_period_days: "",
          notification_interval: "",
          allow_multiple_team_member_applications: 1,

          schedule_announcement: 1,
          application_start_date: "",
          application_end_date: "",
        });

        if (Object.keys(selected).length) {
          // change steps to grades later when it has been corrected form the back end
          //   dispatch({
          //     type: "UPDATE_TO_LEAVE_GROUP",
          //     payload: res.payload.grade,
          //   });
          dispatch({
            type: "UPDATE_TO_LEAVE_GROUP",
            payload: {
              ...values,
              id: Object.keys(selected).length ? selected.id : null,
            },
          });
        } else {
          //   dispatch({
          //     type: "ADD_TO_LEAVE_GROUP",
          //     payload: res.payload.grade,
          //   });
          dispatch({
            type: "ADD_TO_LEAVE_GROUP",
            payload: values,
          });
        }
        setAddDept(false);
      }
      // console.log({ back_errors });
    });
  };

  useEffect(() => {
    if (sys_leave_group.length) {
      const improved = sys_leave_group.map((item, ind) => {
        return {
          ...item,
          key: item.id,
          index: ind + 1,
        };
      });
      setData(improved);
    } else {
      setData([]);
    }
  }, [sys_leave_group]);
  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("title", "Title"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("description", "Description"),
    },
    {
      title: "Notice Days Period",
      dataIndex: "notice_period_days",
      key: "notice_period_days",
      render: (value) => {
        return value ? value : "N/A";
      },
      ...getColumnSearchProps("notice_period_days", "Notice Period"),
    },
    {
      title: "Notification interval",
      dataIndex: "notification_interval",
      key: "notification_interval",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Application start date",
      dataIndex: "application_start_date",
      key: "application_start_date",
      ...getColumnSearchProps(
        "application_start_date",
        "Application start date"
      ),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Application end date",
      dataIndex: "application_end_date",
      key: "application_end_date",
      ...getColumnSearchProps("application_end_date", "Application end date"),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Schedule announcement",
      dataIndex: "schedule_announcement",
      key: "schedule_announcement",

      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Allow multiple team members applications",
      dataIndex: "allow_multiple_team_member_applications",
      key: "allow_multiple_team_member_applications",

      render: (value) => {
        return value === 1 ? "True" : "False";
      },
    },
    {
      title: "Action",

      render: (_, item) => {
        return (
          <GroupEachList
            item={item}
            handleSelected={handleSelected}
            refetch={refetch}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4 ">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available leave type</h5>
              <small className="w-75 d-none d-sm-block">
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                magnam qui perspiciatis animi doloribus velit aperiam excepturi
                tempora culpa consequatur laboriosam pariatur dicta eius totam,
                minus natus praesentium. Exercitationem, non? */}
              </small>
            </div>
          </div>

          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center mb-4">
              <div className="circle__bagde fw-bold">{data?.length}</div>
              <h6 className="mb-0"> Leave type</h6>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}
                {addDept ? (
                  <form className="onboarding-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="name">Title</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.title && back_errors.title[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Vacation"
                            type="text"
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="description">Description</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.description &&
                                  back_errors.description[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            type="text"
                            id="description"
                            name="description"
                            value={values.description || ""}
                            onChange={handleChange}
                            placeholder=" leave description"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="notice_period_days">
                              {" "}
                              Notice Days Period
                            </label>
                            <div className=" text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.notice_period_days &&
                                  back_errors.notice_period_days[0]}
                              </small>
                            </div>
                          </div>

                          <select
                            type="text"
                            id="notice_period_days"
                            name="notice_period_days"
                            value={values.notice_period_days || ""}
                            onChange={handleChange}
                            placeholder="Notice Period"
                          >
                            <option value=""> --select--</option>
                            {avail_days.map((day) => (
                              <option value={day}>{day}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="notification_interval">
                              {" "}
                              Notification interval
                            </label>
                            <div className=" text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.notification_interval &&
                                  back_errors.notification_interval[0]}
                              </small>
                            </div>
                          </div>

                          <select
                            type="text"
                            id="notification_interval"
                            name="notification_interval"
                            value={values.notification_interval || ""}
                            onChange={handleChange}
                            placeholder="Notice Period"
                          >
                            <option value=""> --select--</option>
                            {avail_days.map((day) => (
                              <option value={day}>{day}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="application_start_date">
                              {" "}
                              Application start date
                            </label>
                            <div className=" text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.application_start_date &&
                                  back_errors.application_start_date[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Vacation"
                            type="date"
                            id="application_start_date"
                            name="application_start_date"
                            value={values.application_start_date}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="application_end_date">
                              {" "}
                              Application end date
                            </label>
                            <div className=" text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.application_end_date &&
                                  back_errors.application_end_date[0]}
                              </small>
                            </div>
                          </div>
                          <input
                            placeholder="Vacation"
                            type="date"
                            id="application_end_date"
                            name="application_end_date"
                            value={values.application_end_date}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="">
                            <div className="d-flex justify-content-between flex-wrap">
                              <label
                                htmlFor="allow_multiple_team_member_applications"
                                className="mb-2"
                              >
                                Allow multiple team members applications
                              </label>

                              <small className="text-danger">
                                {back_errors.allow_multiple_team_member_applications &&
                                  back_errors
                                    .allow_multiple_team_member_applications[0]}
                              </small>
                            </div>
                            <div className="d-flex gap-5 ">
                              <div className="d-flex  gap-5 align-items-center">
                                <label htmlFor="yes">Yes</label>{" "}
                                <input
                                  type="radio"
                                  value={true}
                                  className="mt-0"
                                  id="yes"
                                  name="allow_multiple_team_member_applications"
                                  checked={
                                    values.allow_multiple_team_member_applications ===
                                    1
                                  }
                                  onChange={handleCheck}
                                />
                              </div>
                              <div className="d-flex gap-5 align-items-center ">
                                <label htmlFor="no">No</label>{" "}
                                <input
                                  type="radio"
                                  value={false}
                                  className="mt-0"
                                  id="no"
                                  name="allow_multiple_team_member_applications"
                                  checked={
                                    values.allow_multiple_team_member_applications ===
                                    0
                                  }
                                  onChange={handleCheckfalse}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="">
                            <div className="d-flex justify-content-between flex-wrap">
                              <label
                                htmlFor="schedule_announcement"
                                className="mb-2"
                              >
                                Schedule announcement
                              </label>

                              <small className="text-danger ">
                                {back_errors.schedule_announcement &&
                                  back_errors.schedule_announcement[0]}
                              </small>
                            </div>
                            <div className="d-flex gap-5 ">
                              <div className="d-flex  gap-5 align-items-center">
                                <label htmlFor="yes">Yes</label>{" "}
                                <input
                                  type="radio"
                                  value={true}
                                  className="mt-0"
                                  id="yes"
                                  name="schedule_announcement"
                                  checked={values.schedule_announcement === 1}
                                  onChange={handleCheck}
                                />
                              </div>
                              <div className="d-flex gap-5 align-items-center ">
                                <label htmlFor="no">No</label>{" "}
                                <input
                                  type="radio"
                                  value={false}
                                  className="mt-0"
                                  id="no"
                                  name="schedule_announcement"
                                  checked={values.schedule_announcement === 0}
                                  onChange={handleCheckfalse}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mt-2">
                      {/* {Object.keys(selected).length ? (
                    
                  ) : null} */}
                      <div>
                        <Button
                          className="myBtn mt-3 mx-2"
                          onClick={() => {
                            clearSelected();
                            handleAddDept();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="myBtn  mt-3"
                          htmlType="submit"
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div
                    // style={{
                    //   maxHeight: 500,
                    //   overflowY: "auto",
                    // }}
                    className="my-3"
                  >
                    {isLoading ? (
                      <SkeletonUI number={2} />
                    ) : data.length ? (
                      <Table
                        columns={columns}
                        dataSource={data}
                        bordered
                        scroll={{ x: "768px" }}
                        pagination={data.length > 10 ? true : false}
                      />
                    ) : (
                      <NoContent
                        // message={"No system grade available."}
                        width={"400px"}
                      />
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  {!addDept && (
                    <Button className="myBtn  mt-3" onClick={handleAddDept}>
                      Add Group
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const GroupEachList = ({ item, handleSelected, refetch }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const confirm = (item) => {
    setLoading(true);
    deleteLeaveGroup(dispatch, item).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        dispatch({ type: "REMOVE_FROM_LEAVE_GROUP", payload: item.id });
        refetch();
      }
    });
  };
  return (
    <Space>
      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-warning`}
        onClick={() => handleSelected(item)}
      >
        Edit
      </Button>
      <Popconfirm
        title="Delete entries"
        description="Do you what to delete this entry?"
        onConfirm={() => confirm(item)}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
          loading={loading}
        >
          Delete
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default GroupList;
