import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function NOKHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: em.name,
          relationship: em.relationship || "--",
          email: em.email || "--",
          telephone: em.telephone || "--",
          mobile: em.mobile_number || "--",
          address: em.home_address || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "RELATIONSHIP",
        dataIndex: "relationship",
        key: "relationship",
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "TELEPHONE NUMBER",
        dataIndex: "telephone",
        key: "telephone",
      },
      {
        title: "MOBILE NUMBER",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "HOME ADDRESS",
        dataIndex: "address",
        key: "address",
      },
  ];
  return <Table columns={columns} dataSource={emp_history} />;
}

export default NOKHistory;
