import { Button } from "antd";
import { useState } from "react";
import {
  settings_util,
  user_errors,
  department,
  designation_role,
} from "../../../util/selectors/userSelectors";
import { addNewHire } from "../../../redux/users/userActions";
import { useGetNewHireList } from "../../../util/usershookActions";
import { useForm, useShallowEqualSelector } from "../../../util/hooks";
import { useDispatch } from "react-redux";

const NewHireModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const designation_roles = useShallowEqualSelector(designation_role);
  const settingsUtil = useShallowEqualSelector(settings_util);

  const back_errors = useShallowEqualSelector(user_errors);

  const emp_type = Object.keys(settingsUtil).length
    ? settingsUtil?.system_inputs["Employment Types"]
    : [];

  const { refetch } = useGetNewHireList(enabled, setEnabled, "PRE");
  const sys_departments = useShallowEqualSelector(department);

  const init_details = {
    name: "",
    email: "",
    batch: "",
    department_id: "",
    job_role: "",
    employment_type: "",
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const callbackFn = () => {
    setLoading(true);
    addNewHire(dispatch, values).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        clearForm();
        dispatch({ type: "NEW_HIRE_ADDED_MAN", payload: res.payload });
        setShowModal(false);
        refetch();
      }
    });
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  return (
    <form className="onboarding-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="d-flex justify-content-between">
              <label htmlFor="name">Name</label>
              <small className="text-danger">
                {errors?.name || (back_errors?.name && back_errors?.name[0])}
              </small>
            </div>

            <input
              type="text"
              placeholder="New Hire's Name"
              name="name"
              id="name"
              onChange={handleChange}
              value={values.name}
            />
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="email">Email</label>
              <small className="text-danger">
                {errors?.email || (back_errors?.email && back_errors?.email[0])}
              </small>
            </div>
            <input
              type="text"
              placeholder="New Hire's Email"
              name="email"
              id="email"
              onChange={handleChange}
              value={values.email}
            />
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="batch">Batch</label>
              <small className="text-danger">
                {errors?.batch || (back_errors?.batch && back_errors?.batch[0])}
              </small>
            </div>
            <input
              type="text"
              placeholder="Internship-2023"
              name="batch"
              id="batch"
              onChange={handleChange}
              value={values.batch}
            />
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="designation_role">Designation</label>
              <small className="text-danger">
                {errors?.designation_role ||
                  (back_errors?.designation_role &&
                    back_errors?.designation_role[0])}
              </small>
            </div>
            <select
              name="job_role"
              id="job_role"
              onChange={handleChange}
              value={values.job_role}
            >
              <option value="">--</option>
              {designation_roles?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.role}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="department_id">Department</label>
              <small className="text-danger">
                {errors?.department_id ||
                  (back_errors?.department_id && back_errors?.department_id[0])}
              </small>
            </div>
            <select
              name="department_id"
              id="department_id"
              onChange={handleChange}
              value={values.department_id}
            >
              <option value="">--</option>
              {sys_departments?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="employment_type">Employment Type</label>
              <small className="text-danger">
                {errors?.employment_type ||
                  (back_errors?.employment_type &&
                    back_errors?.employment_type[0])}
              </small>
            </div>
            <select
              name="employment_type"
              id="employment_type"
              onChange={handleChange}
              value={values.employment_type}
            >
              <option value="">--</option>
              {emp_type?.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex justify-content-end gap-3 mt-5">
            <Button className="myBtn" htmlType="submit" loading={loading}>
              Add Hire
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewHireModal;
