import { useEffect, useState } from "react";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { new_hire } from "../../../util/selectors/userSelectors";
import {
  useGetNewHireList,
  useGetSystemSettingsUtils,
  useGetSystemRoleDesignation,
  useGetSystemDepartments,
} from "../../../util/usershookActions";
import Moment from "react-moment";
import "moment-timezone";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import EachRender from "./EachRender";
import { Table, Button, Input, Space, Tag, Skeleton } from "antd";

import NoContent from "../../ui/NoContent";

const Home = () => {
  const columns = [
    {
      title: "S/N",
      dataIndex: "index",
      width: 70,
      fixed: "left",
    },
    {
      title: "Fullname",
      dataIndex: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div className="p-1">
              <Input
                autoFocus
                placeholder="Filter Action"
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
              ></Input>
              <Space className="my-1">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm({ closeDropdown: false });
                  }}
                  icon={<RedoOutlined />}
                >
                  Reset
                </Button>
              </Space>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
      width: 200,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      width: 100,
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Batch",
      dataIndex: "batch",
    },
    {
      title: "Creation Time",
      dataIndex: "creation_time",
      render: (value, record) => {
        return (
          <Moment tz="Africa/Lagos" format="MMMM DD YYYY, h:mm:ss a">
            {value}
          </Moment>
        );
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",

      render: (value, record) => {
        return (
          <Space wrap>
            <Tag color="blue">
              {`Pre-fill link sent : ${
                record.analysis.pre_fill_link_sent ? "Yes" : "No"
              }`}
            </Tag>
            <Tag color="green">
              {`Pre-fill form filled : ${
                record.analysis["pre-fill-form_filled"] ? "Yes" : "No"
              }`}
            </Tag>
            <Tag color="red">
              {`Provisional offer sent : ${
                record.analysis["mail_offer_link_sent"] ? "Yes" : "No"
              }`}
            </Tag>
          </Space>
        );
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, record) => {
        return <EachRender value={value} record={record} refetch={refetch} />;
      },
      fixed: "right",
    },
  ];

  useUserCleanUp();
  const new_hire_list = useShallowEqualSelector(new_hire);

  const [data, setData] = useState([]);
  const [enabled, setEnabled] = useState(true);
  const [enabled_uti, setEnabledUtil] = useState(true);
  const [enabled_role, setEnabledRole] = useState(true);
  const [enabled_dep, setEnabledDep] = useState(true);
  const [stage] = useState("PRE");

  const { refetch, isLoading } = useGetNewHireList(enabled, setEnabled, stage);

  useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  useGetSystemRoleDesignation(enabled_role, setEnabledRole);

  useGetSystemDepartments(enabled_dep, setEnabledDep);

  useEffect(() => {
    if (new_hire_list?.length >= 0) {
      const new_arry = new_hire_list.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          key: item.id,
          department: item?.department?.name,
          designation_role: item?.job_role?.name,
          name: item.name,
          batch: item.batch,
          email: item.email,
          creation_time: item.creation_time,
          status: item.status,
        };
      });

      setData(new_arry);
    }
  }, [new_hire_list]);

  return (
    <div className="container-fluid">
      <div className="row">
        {data.length ? (
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <div>
                <h4>Hire List</h4>
                <p className="mb-5">
                  You can see all the profiled hires, monitor stages and manage
                  at the stages of pre-employment, employment, induction and
                  onboarding
                </p>
              </div>
            </div>
            {isLoading ? (
              <div>
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  className="mt-3"
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  className="mt-3"
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                  className="mt-3"
                />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1400, y: 550 }}
              />
            )}
          </div>
        ) : (
          <div className="col-md-12">
            <NoContent
              width={400}
              message={`You have not profiled any hire for 
              induction and onboarding`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
