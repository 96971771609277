import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/CustomizeDisplay.module.css";
import { updateClientTheme } from "../../../redux/users/userActions";
import { useGetCompanyDetails } from "../../../util/usershookActions";
import {
  company_url,
  company_details,
} from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";

function Theme() {
  useUserCleanUp();
  const companyDetails = useShallowEqualSelector(company_details);
  const colorTheme = companyDetails?.settings.find(
    (setting) => setting.setting === "PrimaryColor"
  );

  const dispatch = useDispatch();
  const initPrimaryColor = { primary_color: colorTheme?.value };
  const [showModal, setShowModal] = useState(false);
  const [showModalres, setShowModalRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingre, setLoadingRe] = useState(false);
  const [color, setColor] = useState(initPrimaryColor);
  const [enabled_cop_det, setEnabledComDet] = useState(false);

  const companyUrl = useSelector(company_url);

  const { refetch } = useGetCompanyDetails(
    enabled_cop_det,
    setEnabledComDet,
    companyUrl
  );
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const handleChange = (e) => {
    setColor((prevState) => {
      dispatch({
        type: "QUICK_SETUP_THEME",
        payload: { [e?.target.name]: e?.target.value },
      });
      return { ...prevState, primary_color: e?.target.value };
    });
  };

  useEffect(
    () =>
      setColor((prevState) => ({
        ...prevState,
        primary_color: colorTheme?.value,
      })),
    []
  );

  const handleupdate = () => {
    setLoading(true);
    updateClientTheme(dispatch, color).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  };
  const handleReset = () => {
    setLoadingRe(true);
    Modal.destroyAll();
    updateClientTheme(dispatch, { primary_color: "#0065AB" }).then((res) => {
      setLoadingRe(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  };

  const modalHandlerApprove = () => {
    setShowModalRes(false);
  };
  function resetTtheme() {
    // alert("here");
    return Modal.confirm({
      title: "Reset Theme",
      content: "Do you want to reset your theme?",
      onOk: handleReset,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalres,
      closable: true,
    });
  }
  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Theme</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <small>Customize your HRIS application to suit your brand</small>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="d-flex gap-1">
                  <Button onClick={resetTtheme} loading={loadingre} type="text">
                    Default
                  </Button>
                  <Button type="text" onClick={handleModal}>
                    Customize
                  </Button>
                </div>
              </div>

              <Modal
                open={showModal}
                footer={null}
                centered
                onCancel={handleModal}
                width={700}
              >
                <div className={styles.customization}>
                  <div className="row">
                    <div className="col-md-4">
                      <h3 className="mb-3">Select Theme</h3>

                      <div className={styles.selectColors}>
                        <div
                          className={styles.colorPreview}
                          style={{ backgroundColor: color.primary_color }}
                        >
                          <h3 style={{ color: "white" }}>
                            {color.primary_color}
                          </h3>
                        </div>
                        <div className={styles.palette}>
                          <form className={styles.colorOptions}>
                            <label htmlFor="blue">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#0065AB"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#0065AB" }}></div>
                            </label>
                            <label htmlFor="green">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#008000"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#008000" }}></div>
                            </label>
                            <label htmlFor="red">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#CD0404"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#CD0404" }}></div>
                            </label>

                            <label htmlFor="purple">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#6F1AB6"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#6F1AB6" }}></div>
                            </label>
                            <label htmlFor="orange">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#E14D2A"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#E14D2A" }}></div>
                            </label>
                            <label htmlFor="navy_blue">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#1C315E"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#1C315E" }}></div>
                            </label>
                            <label htmlFor="black">
                              <input
                                type="radio"
                                name="primary_color"
                                value={"#000000"}
                                onChange={handleChange}
                              />
                              <div style={{ backgroundColor: "#000000" }}></div>
                            </label>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h3 className="mb-3">Preview</h3>
                      <div className={styles.preview}>
                        <div
                          className={styles.header}
                          style={{ background: color.primary_color }}
                        >
                          <h2 style={{ color: "white" }}>Hello World!</h2>
                          <small>This is your dashboard header</small>
                        </div>
                        <div className={styles.body}>
                          <h4 style={{ color: color.primary_color }}>
                            This is a heading
                          </h4>
                          <p style={{ color: color.primary_color }}>
                            This is a text
                          </p>
                          <Button
                            className="py-0 mt-2"
                            htmlType="button"
                            style={{ background: color.primary_color }}
                          >
                            Click me
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <div className="">
                      <Button
                        className="myBtn  mb-2"
                        loading={loading}
                        onClick={handleupdate}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Theme;
