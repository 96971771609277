import React from "react";

const OnboardingActivity = ({ hireProfile }) => {
  return (
    <>
      <h4 className="mb-5">Onboarding Activities</h4>
      <div className="row">
        {hireProfile?.payload?.hire_activities?.onboarding &&
          hireProfile?.payload?.hire_activities?.onboarding.map((ind) => (
            <div key={ind.id} className="col-4 mb-4">
              <div className="template">
                <label>Activity Name</label>
                <h5 className="mb-0">{ind.activity}</h5>
                <label className="mt-3">Activity Description</label>
                <p className="h6">{ind.description}</p>
                <label className="mt-3">Activity Duration</label>
                <p className="h6">{ind.duration}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default OnboardingActivity;
