import React from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import PlanningDropdown from "./dropdowns/PlanningDropdown";
import CanAccess from "../hoc/CanAccess";
import MonitoringDropdown from "./dropdowns/MonitoringDropdown";
import AppraisalsDropdown from "./dropdowns/AppraisalsDropdown";
import { FaHome } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();

  const parentPath = location.pathname.split("/")[3];
  const navigate = useNavigate();


  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            to={"#"}
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>

        <ul className="performance__navlinks ">
          {/* <CanAccess
            can_access={"view-performance-mgt-overview"}
            isRoute={false}
          > */}
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>
          {/* </CanAccess> */}

          <li>
            <NavLink
              to={"planning"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Planning</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"appraisals"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Appraisals</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"monitoring"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Monitoring</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"reports"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Reports</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"settings"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Settings</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="performance__nav__subnav">
        {parentPath === "planning" ? (
          <PlanningDropdown />
        ) : parentPath === "monitoring" ? (
          <MonitoringDropdown />
        ) : parentPath === "appraisals" ? (
          <AppraisalsDropdown />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
