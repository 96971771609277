import { Table, Button, Input, Space } from "antd";
import { useState, useEffect, useMemo } from "react";
import NoContent from "../../ui/NoContent";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { new_hire } from "../../../util/selectors/userSelectors";
import {
  useGetInductionTemplate,
  useGetNewHireList,
} from "../../../util/usershookActions";
import "moment-timezone";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import EachAction from "./EachAction";
import TableSkeleton from "../../ui/TableSkeleton";
import IndOnbSide from "../IndOnbSide";

const InductionHome = () => {
  useUserCleanUp();
  const new_hire_list = useShallowEqualSelector(new_hire);
  const [data, setData] = useState([]);

  const [enabled, setEnabled] = useState(true);
  const [enabledemp, setEnabledemp] = useState(true);
  const [stage] = useState("INDUCTION");
  const { refetch: refetchList, isLoading } = useGetNewHireList(
    enabled,
    setEnabled,
    stage
  );
  const [users, setUsers] = useState([]);
  const [template_show, setTemplateShow] = useState([]);
  const [page, setPage] = useState(1);
  useGetAllEmployee(enabledemp, setEnabledemp, "all");
  const {
    data: temp,
    refetch: refetchTem,
    isLoading: isLoading_tem,
  } = useGetInductionTemplate(enabled, setEnabled, page);

  // console.log({ temp });
  const temp_data = useMemo(() => temp?.payload, [temp]);
  const all_users = useShallowEqualSelector(all_groups);

  useEffect(() => {
    if (new_hire_list?.length >= 0) {
      console.log({ new_hire_list });

      const new_arry = new_hire_list.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          key: item.id,
          department: item?.department?.name,
          designation_role: item?.job_role?.name,
          name: item.name,
          batch: item.batch,
          email: item.email,
          creation_time: item.creation_time,
          status: item.status,
        };
      });

      setData(new_arry);
    }
  }, [new_hire_list]);

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  useEffect(() => {
    if (temp_data?.data?.length) {
      // setTemplateShow(temp_data?.data);
      setTemplateShow(temp_data);
    }
  }, [temp_data]);

  // console.log({ users, template_show });
  const handlepagination = (page) => {
    setPage(page);
    setEnabled(true);
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "index",
      width: 70,
      fixed: "left",
    },
    {
      title: "Fullname",
      dataIndex: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div className="p-1">
              <Input
                autoFocus
                placeholder="Filter Action"
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
              ></Input>
              <Space className="my-1">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm({ closeDropdown: false });
                  }}
                  icon={<RedoOutlined />}
                >
                  Reset
                </Button>
              </Space>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
      width: 250,
      fixed: "left",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      // render: (value, record) => {
      //   const ans = value === 'PRE'? "PRE-EMPLOYMENT" : value;
      //   return (

      //    {ans}
      //   );
      // },
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Batch",
      dataIndex: "batch",
    },
    {
      title: "Progress",
      dataIndex: "progress",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, record) => {
        return (
          <EachAction
            users={users}
            template_show={template_show}
            record={record}
            refetchList={refetchList}
            isLoading_tem={isLoading_tem}
            handlepagination={handlepagination}
          />
        );
      },
      fixed: "right",
    },
  ];

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        {data.length ? (
          <div className="col-md-9">
            <h4>Induction List</h4>
            <p className="mb-5">
              You can see all the profiled hires, monitor stages and manage at
              the stages of pre-employment, employment, induction and onboarding
            </p>
            {isLoading ? (
              <div>
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1400, y: 550 }}
              />
            )}
          </div>
        ) : (
          <div className="col-md-9">
            <NoContent
              width={400}
              message={`No data for induction. Add a new hire to the induction group.`}
            />
          </div>
        )}
        <div className="col-md-3">
          <IndOnbSide />
        </div>
      </div>
    </div>
  );
};

export default InductionHome;
