import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Select, Modal } from "antd";
import {
  useGetAllBenefitsInKind,
  useGetOneAvailableJobGrade,
  useGetSystemCurrency,
  useGetSystemGrade,
  useGetSystemStep,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  all_benefits,
  one_avail_job,
  user_errors,
} from "../../../util/selectors/userSelectors";
import SkeletonUI from "../../ui/Skeleton";
import { useDispatch } from "react-redux";
import { updateJobGrading } from "../../../redux/users/userActions";

function EditJobGreades() {
  useUserCleanUp();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapse, setCollapse] = useState(false);
  const summary = useRef(null);
  const [enabled, setEnabled] = useState(false);
  const [edit_data, setEditData] = useState({
    role: "",
    code: "",
    category: "",
    currency_id: "",
    minimum_salary: "",
    maximum_salary: "",
  });

  const [enabled_curr, setEnabledCurr] = useState(true);
  const [enabled_grade, setEnabledGrade] = useState(true);
  const [enabled_step, setEnabledStep] = useState(true);
  const [enabled_ben, setEnabledBen] = useState(true);
  const [loading, setLoading] = useState(false);

  const [job_levels, setJobLevels] = useState([]);
  const [benefits_data, setBenefitData] = useState([]);

  useGetAllBenefitsInKind(enabled_ben, setEnabledBen, "");

  const allBenefits = useShallowEqualSelector(all_benefits);

  const { data: currency_data } = useGetSystemCurrency(
    enabled_curr,
    setEnabledCurr
  );

  const { data: grade_data } = useGetSystemGrade(
    enabled_grade,
    setEnabledGrade
  );

  const { data: step_data } = useGetSystemStep(enabled_step, setEnabledStep);
  const { isLoading, refetch } = useGetOneAvailableJobGrade(
    enabled,
    setEnabled,
    params.id
  );
  const one_job = useShallowEqualSelector(one_avail_job);
  const back_errors = useShallowEqualSelector(user_errors);

  function handleToggle() {
    const element = summary.current;
    if (element) {
      element.classList.toggle("slide__down");
      setCollapse(!collapse);
    }
  }

  function handleChange(value, index) {
    const new_arry = [...job_levels];
    const affected = new_arry[index];
    affected.benefit_ids = value;
    setJobLevels(new_arry);
  }

  function handleGradeChange(e) {
    setEditData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function addNewGrading(e) {
    const new_arry = [...job_levels];
    new_arry.splice(new_arry.length, 1, {
      grade_id: "",
      step_id: "",
      salary: "",
      benefit_ids: [],
    });
    setJobLevels(new_arry.reverse());
  }

  function removeGrading(e, index) {
    const new_arry = [...job_levels];
    new_arry.splice(index, 1);
    setJobLevels(new_arry);
  }

  function handleDynChange(e, index) {
    const new_arry = [...job_levels];
    const affected = new_arry[index];
    affected[e.target.name] = e.target.value;
    setJobLevels(new_arry);
  }

  function confirmFunc(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Update job grading",
      content: "Do you want to perform this action?",
      onOk: updateJob,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function updateJob() {
    const creds = {
      ...edit_data,
      job_role_levels: job_levels,
      id: params.id,
    };

    // console.log({ creds });
    setLoading(true);
    updateJobGrading(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        navigate("/compensation/job-grading");
      }
    });
  }

  useEffect(() => {
    if (params.id && typeof parseInt(params.id) === "number") {
      // console.log({ params });
      setEnabled(true);
    } else {
      navigate(-1);
    }
  }, [params, navigate]);

  useEffect(() => {
    if (Object.keys(one_job).length) {
      setEditData({
        role: one_job?.role,
        code: one_job?.code,
        category: one_job?.category,
        minimum_salary: one_job?.minimum_salary,
        maximum_salary: one_job?.maximum_salary,
        currency_id: one_job?.currency?.id,
      });
      if (one_job?.job_role_levels?.length) {
        const filtered = one_job?.job_role_levels?.map(
          ({ grade_id, step_id, salary, benefit_ids }) => {
            return {
              grade_id,
              step_id,
              salary,
              benefit_ids,
            };
          }
        );

        setJobLevels(filtered);
      } else {
        setJobLevels([
          {
            grade_id: "",
            step_id: "",
            salary: "",
            benefit_ids: [],
          },
        ]);
      }
    }
  }, [one_job]);

  useEffect(() => {
    // console.log({ allBenefits });
    if (allBenefits?.length) {
      const modData = allBenefits?.map((item) => ({
        value: item.id,
        label: item.benefit,
      }));
      setBenefitData(modData);
    }
  }, [allBenefits]);

  // console.log({ job_levels, benefits_data });
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <section
            className="app__section py-4"
            style={{
              minHeight: "auto",
            }}
          >
            <h5 className="">
              Edit Job Grade
              <Link to={`/modules/compensation/pay-structure/job-grading`}>
                <p
                  className="performance__bagde2 backBtn"
                  style={{
                    float: "right",
                  }}
                >
                  <MdOutlineArrowBack />
                  Back to list
                </p>
              </Link>
            </h5>
            <small className="w-75 d-none d-sm-block">
              Update job role or grading to the human resource management system
              module. Add notches and levels to job as required.
            </small>

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : (
              <>
                {" "}
                <div className="row mt-3">
                  <form className="performance-form">
                    <div className="col-md-12 mb-3">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="role">Job Title</label>

                        <small className="text-danger">
                          {back_errors.role && back_errors.role[0]}
                        </small>
                      </div>

                      <input
                        type="text"
                        name="job_title"
                        id="job_title"
                        value={edit_data.role}
                        placeholder="Senior Software Developer"
                        readOnly
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="job_grade">Grade Title / Category</label>

                      <input
                        type="text"
                        name="job_grade"
                        id="job_grade"
                        value={edit_data.category}
                        placeholder="Engineering"
                        readOnly
                      />
                    </div>
                    <div className="col-md-12 mb-4 mt-4">
                      <label htmlFor="job_grade">Salary</label>

                      <hr />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="currency_id">Currency</label>

                        <select
                          name="currency_id"
                          id="currency_id"
                          value={edit_data.currency_id}
                          onChange={handleGradeChange}
                          placeholder="Nigerian Naira #"
                        >
                          <option value=""> select currency</option>

                          {currency_data?.length &&
                            currency_data?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {" "}
                                {item.code}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="minimum_salary">Minimum Salary</label>

                        <input
                          type="number"
                          name="minimum_salary"
                          id="minimum_salary"
                          value={edit_data.minimum_salary}
                          onChange={handleGradeChange}
                          placeholder="300,000"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="maximum_salary">Maximum Salary</label>

                        <input
                          type="number"
                          name="maximum_salary"
                          id="maximum_salary"
                          value={edit_data.maximum_salary}
                          placeholder="2,000,000"
                          onChange={handleGradeChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </section>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12">
          <section
            className="app__section mt-3 py-4 "
            ref={summary}
            style={{
              minHeight: "auto",
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className="">Job level settings</h5>
                <small className=" d-none d-sm-block">
                  Define the various various job levels and bands or notches
                  that apply to this job role or grade.
                </small>
              </div>

              <div className="d-flex gap-2 align-items-center">
                <p onClick={handleToggle} className="pointer">
                  {" "}
                  {collapse ? "Show more" : "Show less"}{" "}
                </p>
                {!collapse ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
            </div>

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : (
              <div className="slider__others">
                <div className="my-3 d-flex justify-content-end">
                  <p className="performance__bagde2" onClick={addNewGrading}>
                    Add New Grade Level
                  </p>
                </div>
                <div className="row mt-3">
                  <form className="performance-form">
                    {job_levels?.map((item, index) => (
                      <div className="grade_level-group mb-5" key={item.id}>
                        <div className="row">
                          <div className="col-md-5 mb-3">
                            <label htmlFor="job_title">Grade / Level</label>
                            <select
                              name="grade_id"
                              id="grade_id"
                              placeholder="Level 1"
                              value={item.grade_id}
                              onChange={(e) => handleDynChange(e, index)}
                            >
                              <option value="">Select level</option>

                              {grade_data?.length &&
                                grade_data?.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {" "}
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-5 mb-3">
                            <label htmlFor="job_title">
                              Band / Notch / Step
                            </label>
                            <select
                              name="step_id"
                              id="step_id"
                              placeholder="Band 1"
                              value={item.step_id}
                              onChange={(e) => handleDynChange(e, index)}
                            >
                              <option value="">Select band</option>

                              {step_data?.length &&
                                step_data?.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {" "}
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="col-md-2 mb-3">
                            <label htmlFor="job_grade">Salary (Gross)</label>

                            <input
                              type="number"
                              name="salary"
                              id="salary"
                              value={item.salary}
                              placeholder="200,000"
                              onChange={(e) => handleDynChange(e, index)}
                            />
                          </div>

                          <div className="col-md-5 mb-3">
                            <label className="mb-1" htmlFor="job_grade">
                              Benefits in kind
                            </label>

                            <Select
                              mode="multiple"
                              allowClear
                              style={{
                                width: "100%",
                              }}
                              placeholder="Please select"
                              value={item?.benefit_ids}
                              onChange={(e) => handleChange(e, index)}
                              data-index={index}
                              options={benefits_data}
                            />
                            <small className="">
                              Additional benefits that are non financial in
                              nature e.g services or privileges
                            </small>
                          </div>
                          {job_levels.length > 1 ? (
                            <div className="col-md-7 mb-3 ">
                              <div className="d-flex justify-content-end mt-sm-4">
                                <small
                                  className="fw-bold text-danger pointer"
                                  onClick={(e) => removeGrading(e, index)}
                                >
                                  Remove
                                </small>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {job_levels.length - 1 !== index ? (
                          <div className="col-md-12 mb-3">
                            <hr className="ruler" />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </form>
                </div>
                <div className="row mt-3">
                  <div className="d-flex justify-content-end">
                    <Button
                      className="myBtn"
                      onClick={confirmFunc}
                      loading={loading}
                    >
                      {" "}
                      Update Job Role
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default EditJobGreades;
