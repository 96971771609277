import React from "react";
import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import { useUsersNotification } from "../../util/hooks";
import { useMediaQuery } from "react-responsive";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

function Benefits() {
  const is_mobible = useMediaQuery({ query: "(max-width: 768px)" });
  useUsersNotification();

  function handleToggle(e) {
    if (is_mobible) {
      const sidebar = document.querySelector(".performance__nav");
      if (sidebar) {
        sidebar.classList.remove("mobile-show-sidebar");
      }
    } else {
      const sidebar = document.querySelector(".admin_layout");

      sidebar.classList.toggle("small__sidebar");
    }
  }

  return (
    <AdminDashboardLayout sidebar={<Sidebar handleToggle={handleToggle} />}>
      <Outlet />
    </AdminDashboardLayout>
  );
}

export default Benefits;
