import React from "react";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function BenefitList() {
  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Benefits Administration"
          module_title="Compensation & Benefits"
          module_description=" Manage all benefit and benefit related categories. You can create, rename, remove benefits on the human resource management system."
          module_img="/assets/benefits.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default BenefitList;
