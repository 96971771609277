import { NavLink, Outlet } from "react-router-dom";

const PlanningApproval = () => {
  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="approval__header">
          <h5 className="">List of targets to be Approved</h5>

          <div className="approval__nav">
            <ul className="dropdown_links px-0">
              <li>
                <NavLink
                  to={""}
                  end
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <span className="sidebar_text">Annual</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"halfyear"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <span className="sidebar_text">Half Year</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"quarter"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <span className="sidebar_text">Quarterly</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"monthly"}
                  end
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <span className="sidebar_text">Monthly</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PlanningApproval;
