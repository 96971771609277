import React, { useState, useEffect, useMemo } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useGetOverview } from "../../util/usershookActions";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";

function Overview() {
  useUserCleanUp();
  const [chart, setChart] = useState([0, 0, 0, 0]);
  Chart.register(...registerables);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [
    "Pending Offers",
    "Approved Offers",
    "Pending Onboarding",
    "Completed Onboarding",
  ];

  const dataLine = {
    labels,
    datasets: [
      {
        label: " overview",
        data: chart,
        backgroundColor: ["blue", "red", "green", "yellow"],
      },
    ],
  };

  const [enabled, setEnabled] = useState(true);

  const { data } = useGetOverview(enabled, setEnabled);

  const memo = useMemo(() => data?.payload, [data]);
  useEffect(() => {
    setChart([
      memo?.non_responsive_hires || 0,
      memo?.accepted_offer || 0,
      memo?.pending_onboarding || 0,
      memo?.completed_onboarding || 0,
    ]);
  }, [memo]);

  return (
    <div className="py-md-3 px-md-5">
      <header className="d-flex align-items-center justify-content-between mb-5 py-5">
        <div className="w-50">
          <small>Welcome to</small>
          <h2>Induction & Onboarding</h2>
          <p>
            You can manage all your processes from start of hiring to onboarding
            here. Frontend engineers I need more explanation here so we can
            reclaim our stolen mandate.
          </p>
        </div>
        <img src="/assets/image 11.png" alt="onboarding and induction" />
      </header>
      <div className="how-it-works">
        <p className="mb-3">How it works</p>
        <div className="row">
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number">1</div>
              <p>Start</p>
            </div>
          </div>
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number">2</div>
              <p>Setup Process</p>
            </div>
            <small>
              Upload required documents for new hires to read and get accustom
              to ...
            </small>
          </div>
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number">3</div>
              <p>Profile New Hires</p>
            </div>
            <small>
              Add new hires, manage hire stages, send prefill form, provisional
              offer, create employee account ...
            </small>
          </div>
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number">4</div>
              <p>Manage Hire Induction Process</p>
            </div>
            <small>
              Manager new hires induction activities, stages and templates
            </small>
          </div>
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number">5</div>
              <p>Manage Hire Onboarding Process</p>
            </div>
            <small>
              Manager new hires onboarding activities, stages and templates
            </small>
          </div>
          <div className="col-2 d-flex flex-column justify-content-between gap-4">
            <div>
              <div className="number last">6</div>
              <p>Finish</p>
            </div>
          </div>
        </div>
      </div>
      <main className="mt-5">
        <div className="row">
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(205, 96, 35, 0.19)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Hires</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.accepted_offer || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(33, 35, 30, 0.08)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Documents</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.non_responsive_hires || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(98, 217, 254, 0.22)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Male / Female</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">
                      {memo?.pending_onboarding || 0} /{" "}
                      {memo?.pending_onboarding || 0}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(254, 98, 98, 0.22)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Managers</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.completed_onboarding || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(215, 226, 255, 1)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Approved Offers</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.accepted_offer || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(215, 255, 236, 0.58)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Pending Offers</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.non_responsive_hires || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(254, 98, 146, 0.22)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Pending Onboarding</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.pending_onboarding || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="insight"
              style={{ backgroundColor: "rgba(148, 24, 8, 0.13)" }}
            >
              <div className="d-flex align-items-center gap-4">
                <div>
                  <RiPulseLine />
                </div>
                <div>
                  <div className="insight-title">
                    <h4>Completed Onboarding</h4>
                  </div>
                  <div className="insight-content">
                    <h2 className="mb-0">{memo?.completed_onboarding || 0}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <div className="insight big" style={{ height: 500 }}>
              <div className="insight-title mb-3">
                <h4>Gender Distribution</h4>
              </div>
              <Bar options={options} data={dataLine} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="insight big d-flex" style={{ height: 500 }}>
              <div className="insight-title mb-3">
                <h4>Onboarding Insight</h4>
              </div>
              <Doughnut options={options} data={dataLine} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Overview;
