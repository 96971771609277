import { useState } from "react";
import { Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useGetLineManagerSubordinates } from "../../../../util/usershookActions";
import SkeletonUI from "../../../ui/Skeleton";

const SelectTeamMember = () => {
  const [enabled, setEnabled] = useState(true);
  const [employee_id, setEmployeeId] = useState("");
  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState();
  const navigate = useNavigate();

  const { data: myTeamMembers, isLoading } = useGetLineManagerSubordinates(
    enabled,
    setEnabled
  );


  const onChange = (value) => {
    setEmployeeId(value);
    setSelectedEmployeeDetails(
      myTeamMembers?.payload?.filter((member) => member?.id === value)
    );
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {isLoading ? (
        <SkeletonUI />
      ) : (
        <div className="d-flex flex-column gap-5 py-5">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Employee Name</label>
            <Select
              showSearch
              size="large"
              placeholder="Select a team member"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{
                border: "2px solid black",
                borderRadius: "10px",
                width: "20rem",
              }}
              options={myTeamMembers?.payload?.map((member) => ({
                value: member?.id,
                label: member?.name,
              }))}
            />
          </div>

          <div className="">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={() =>
                navigate(`${employee_id}`, {
                  state: { selectedEmployeeDetails },
                })
              }
              disabled={!employee_id}
            >
              Proceed
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectTeamMember;
