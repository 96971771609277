import { useState } from "react";
import { Button, Modal, Input, Select } from "antd";
import { PlusOutlined, PlusCircleFilled } from "@ant-design/icons";
import { IoIosFlag } from "react-icons/io";
import { TiTags } from "react-icons/ti";
import { GoInbox } from "react-icons/go";

const { TextArea } = Input;

const AddTaskButton = ({ noBackground }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  return (
    <>
      {!noBackground ? (
        <Button
          size="large"
          icon={<PlusCircleFilled size={30} />}
          onClick={showModal}
        >
          Add Task
        </Button>
      ) : (
        <Button
          size="large"
          icon={<PlusOutlined size={30} />}
          type="text"
          style={{
            width: "fit-content",
          }}
          onClick={showModal}
        >
          Add Task
        </Button>
      )}

      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        className="add__task__modal"
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "4rem",
              width: "100%",
              padding: "0 1rem",
              borderTop: "2px solid #d9d9d9",
            }}
          >
            <div>
              <Select
                size="medium"
                placeholder="Tags"
                className="add__task__tags"
                value="inbox"
                options={[
                  {
                    value: "inbox",
                    label: (
                      <span>
                        <GoInbox size={20} /> Inbox
                      </span>
                    ),
                  },
                  {
                    value: "tag_two",
                    label: (
                      <span>
                        <GoInbox size={20} /> 
                      </span>
                    ),
                  },
                ]}
              />
            </div>
            <div>
              <Button key="back" onClick={() => setOpen(false)}>
                Return
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => setOpen(false)}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      >
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.3rem",
          }}
        >
          <Input
            size="large"
            placeholder="Task Title"
            className="add__task__input"
          />

          <TextArea
            size="large"
            placeholder="Task Description"
            className="add__task__description"
            rows={1}
          />

          <div className="d-flex gap-2 align-items-center">
            <Select
              size="medium"
              placeholder="Task Priority"
              className="add__task__priority"
              options={[
                {
                  value: "priority_one",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 1
                    </span>
                  ),
                },
                {
                  value: "priority_two",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 2
                    </span>
                  ),
                },
                {
                  value: "priority_three",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 3
                    </span>
                  ),
                },
              ]}
            />
            <Select
              size="medium"
              placeholder="Task Priority"
              className="add__task__priority"
              options={[
                {
                  value: "priority_one",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 1
                    </span>
                  ),
                },
                {
                  value: "priority_two",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 2
                    </span>
                  ),
                },
                {
                  value: "priority_three",
                  label: (
                    <span>
                      <IoIosFlag size={20} /> Priority 3
                    </span>
                  ),
                },
              ]}
            />
            <TiTags size={23} cursor={"pointer"} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTaskButton;
