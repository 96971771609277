import React, { useEffect, useState } from "react";
import DashboardCard from "./DashboardCard";
import { MdOutlineTimeToLeave } from "react-icons/md";
import NoContent from "../ui/NoContent";
import { useGetEmployeesOnLeave } from "../../util/usershookActions";
import SkeletonUI from "./../ui/Skeleton";

function TeamMembersOnLeave() {
  const [enabled, setEnabled] = useState(true);

  const { data, isLoading } = useGetEmployeesOnLeave(enabled, setEnabled);

  const imageStyle = {
    width: 50,
    height: 50,
    borderRadius: "50%",
    objectFit: "contain",
  };

  return (
    <DashboardCard title="Employees on leave" icon={<MdOutlineTimeToLeave />}>
      <div className="row">
        <div className="col-md-12 team-members">
          {isLoading ? (
            <SkeletonUI />
          ) : data?.payload.length > 0 ? (
            data?.payload?.map((item) => (
              <div
                key={item.id}
                className="d-flex align-items-center gap-3 mb-3 team-member"
              >
                <img
                  style={imageStyle}
                  src={
                    item.avatar || item.gender === "Male"
                      ? "/assets/man-placeholder.png"
                      : "/assets/woman-placeholder.png"
                  }
                  alt={item.first_name}
                />
                <div>
                  <h4 className="mb-0">
                    {item.first_name} {item.last_name}
                  </h4>
                  <p>{item.department || "N/A"}</p>
                </div>
              </div>
            ))
          ) : (
            <NoContent
              illustration="assets/leaveimage.png"
              width="150"
              message={"No employee on leave"}
            />
          )}
        </div>
      </div>
    </DashboardCard>
  );
}

export default TeamMembersOnLeave;
