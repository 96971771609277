import { Button } from "antd";
import { toggleActivies } from "../../../redux/users/userActions";
import { useState } from "react";
import { useDispatch } from "react-redux";

function InductionAct({ act, hire_id, refetch, setEnabled }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setLoading(true);
    const creds = {
      type: "induction",
      hire_id,
      activity_id: act.id,
    };

    toggleActivies(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        setEnabled(true);
        refetch();
      }
    });
  };
  return (
    <div className="d-flex align-items-center justify-content-between">
      <p>
        {act.hire_induction_activity[0].status === "PENDING"
          ? "Pending"
          : "Completed"}
      </p>
      <Button size="small" loading={loading} onClick={handleToggle}>
        Mark as{" "}
        {act.hire_induction_activity[0].status === "PENDING"
          ? " Completed"
          : " Pending"}
      </Button>
    </div>
  );
}

export default InductionAct;
