import { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import {
  useGetUserProfile,
  useGetAppraisalsApproved,
} from "../../../../util/usershookActions";
import { beginAppraisal } from "../../../../redux/users/userActions";
import { MdOutlineArrowBack } from "react-icons/md";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import NoContent from "../../../ui/NoContent";

const BeginAppraisal = () => {
  const [enabled, setEnabled] = useState(true);
  const [beginAppraisalLoading, setBeginAppraisalLoading] = useState(false);
  const [beginAppraisalStatus, setBeginAppraisalStatus] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamMemberID } = useParams();

  const { data: userProfile, isLoading } = useGetUserProfile(
    enabled,
    setEnabled
  );

  const { data: inProgressAppraisal, isLoading: isLoadingInProgressAppraisal } =
    useGetAppraisalsApproved(enabled, setEnabled);

  let inProgressReview;

  if (inProgressAppraisal && inProgressAppraisal.payload?.[0]) {
    inProgressReview = inProgressAppraisal?.payload?.filter(
      (appraisal) => appraisal.status === "in-progress"
    );
  }

  const handleBeginAppraisal = (e) => {
    e.preventDefault();
    setBeginAppraisalLoading(true);
    beginAppraisal(dispatch).then(() => {
      setBeginAppraisalLoading(false);
      setBeginAppraisalStatus(true);
    });
  };

  useEffect(() => {
    if (beginAppraisalStatus) {
      navigate(
        `/modules/performance/appraisals/begin-appraisal/${userProfile?.data?.id}`
      );
    }
  }, [beginAppraisalStatus, navigate, userProfile?.data?.id]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "2rem",
          }}
        >
          <div className="">
            <h3 className="h4">Score Card</h3>
            <p>This displays user department current score card information</p>
          </div>

          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
            className="myBtn d-flex align-items-center gap-2"
          >
            <MdOutlineArrowBack />
            Back
          </Button>
        </div>

        {inProgressAppraisal && !inProgressAppraisal.payload?.length ? (
          <NoContent
            width={"400px"}
            message={"There is no appraisal going on currently"}
          />
        ) : isLoadingInProgressAppraisal || isLoading ? (
          <SkeletonUI />
        ) : (
          <>
            <div className="begin__appraisal__details">
              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Employee Name</label>
                <Input
                  size="large"
                  id="employeeName"
                  value={userProfile?.data?.personal?.fullname}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Position</label>
                <Input
                  size="large"
                  id="position"
                  value={userProfile?.data?.job_role?.job_role?.role}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Department</label>
                <Input
                  size="large"
                  id="department"
                  value={userProfile?.data?.personal?.department?.name}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Supervisor Name</label>
                <Input
                  size="large"
                  id="supervisorName"
                  value={userProfile?.data?.primary_line_manager?.fullname}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Review Period</label>
                <Input
                  size="large"
                  id="reviewPeriod"
                  value={`${dayjs(
                    inProgressReview?.[0]?.review_period_date?.from
                  ).format("DD/MM/YYYY")} - ${dayjs(
                    inProgressReview?.[0]?.review_period_date?.to
                  ).format("DD/MM/YYYY")}`}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">
                  Date of Performance Review
                </label>
                <Input
                  size="large"
                  id="dateOfReview"
                  value={`${dayjs(inProgressReview?.[0]?.start_date).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(inProgressReview?.[0]?.end_date).format(
                    "DD/MM/YYYY"
                  )}`}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>
            </div>

            {!teamMemberID && (
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    marginTop: "2rem",
                  }}
                  onClick={handleBeginAppraisal}
                  loading={beginAppraisalLoading}
                >
                  Proceed
                </Button>
              </div>
            )}
          </>
        )}

        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BeginAppraisal;
