import React, { useState } from "react";
import SubscriptionNavbar from "./SubscriptionNavbar";
import { Outlet } from "react-router-dom";
import { useGetUserProfile } from "../../util/usershookActions";

const SubscriptionLayout = () => {
  const [enabled, setEnabled] = useState(true);

  useGetUserProfile(enabled, setEnabled);
  
  return (
    <div
      style={{
        backgroundColor: "#EBE9E8",
        height: "100%",
        position: "relative",
        bottom: 0,
      }}
    >
      <SubscriptionNavbar />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default SubscriptionLayout;
