import React, { useState, useEffect } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { AiFillExclamationCircle, AiOutlineSearch } from "react-icons/ai";
import {
  useUserCleanUp,
  useShallowEqualSelector,
  useDebounce,
} from "../../../util/hooks";
import { one_benefit } from "../../../util/selectors/userSelectors";
import { useGetOneBenefitsInKind } from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

function ViewBenefits() {
  useUserCleanUp();
  const param = useParams();

  const [enabled, setEnabled] = useState(false);
  const [benefit, setBenefit] = useState("");
  const [text, setText] = useState("");
  const [display_beneficiaries, setDisplayBeneficiaries] = useState([]);

  const { isLoading } = useGetOneBenefitsInKind(enabled, setEnabled, benefit);
  const benefit_data = useShallowEqualSelector(one_benefit);

  useDebounce(() => filterBeneficiary(text), 1000, [text]);
  function filterBeneficiary(text) {
    const filtered =
      benefit_data.beneficiaries &&
      benefit_data.beneficiaries.filter((ben) => {
        return (
          ben.first_name.toLowerCase().includes(text.toLowerCase()) ||
          ben.last_name.toLowerCase().includes(text.toLowerCase())
        );
      });

    if (text === "") {
      setDisplayBeneficiaries(benefit_data.beneficiaries);
    } else if (filtered.length) {
      setDisplayBeneficiaries(filtered);
    } else {
      // to be set to no content later
      setDisplayBeneficiaries([]);
    }
  }

  useEffect(() => {
    // console.log(param);
    if (param?.id?.length) {
      setBenefit(param.id);
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    setDisplayBeneficiaries(benefit_data.beneficiaries);
  }, [benefit_data]);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <section className="app__section py-4 ">
            <div className="d-flex justify-content-between">
              <h5 className=""> {benefit_data?.benefit} Details</h5>
              <div className="d-flex gap-3 ">
                <Link to={`/modules/compensation/list`}>
                  <p className="performance__bagde2 backBtn">
                    <MdOutlineArrowBack />
                    Back to list
                  </p>
                </Link>
                <Link to={`/modules/compensation/list/edit/${benefit}`}>
                  <p className="performance__bagde2">Edit Benefit</p>
                </Link>
              </div>
            </div>

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : (
              <>
                {" "}
                {/* <div className="row my-4">
                  <div className="col-md-6">
                    <img src="/assets/hello_benefit.png" />
                  </div>
                </div> */}
                <div className="row my-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h6> Value</h6>
                        <p>{benefit_data.value || "N/A"}</p>
                      </div>
                      <div className="col-md-6 mb-3">
                        <h6> Description</h6>
                        <p>{benefit_data.description || "N/A"}</p>
                      </div>

                      <div className="col-md-6  mb-3">
                        <h6> Status</h6>
                        <p>
                          {benefit_data.status ? (
                            <p className="text-success">Active</p>
                          ) : (
                            <p className="text-danger">Inactive</p>
                          )}
                        </p>
                      </div>
                      <div className="col-md-6  mb-3">
                        <h6> Type</h6>
                        <p>{benefit_data.type || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between  align-items-center mb-3 ">
                      <h6 className="mb-0">Beneficiaries</h6>
                      <div className=" filter_search">
                        <input
                          placeholder="search"
                          onChange={(e) => setText(e.target.value)}
                        />{" "}
                        <AiOutlineSearch />{" "}
                      </div>
                    </div>

                    <div className="d-flex gap-3 flex-wrap justify-content-between">
                      {display_beneficiaries?.length === 0 && text !== 0 ? (
                        <div className="d-flex justify-content-center w-100">
                          <NoContent />
                        </div>
                      ) : (
                        display_beneficiaries?.map((item) => (
                          <p className="bg-light short__bagdes">
                            {item.first_name || ""} {item.last_name || ""}
                          </p>
                        ))
                      )}
                    </div>
                  </div>
                </div>
                {/* )} */}
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewBenefits;
