import React from "react";
import styles from "../../styles/SubscriptionNavbar.module.css";
import { dehashData } from "../../util/hash";
import { useNavigate } from "react-router-dom";
import { useShallowEqualSelector } from "../../util/hooks";
import { company_details } from "../../util/selectors/userSelectors";
import { user_profile } from "../../util/selectors/userSelectors";

const SubscriptionNavbar = () => {
  const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;

  const { logo } = dehashData(AUTH_DETAILS);
  const profile = useShallowEqualSelector(user_profile);
  const companyDetails = useShallowEqualSelector(company_details);

  const { personal } = profile;

  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate("/profile");
  };
  // console.log("boye", personal);

  return (
    <div className={styles.container}>
      <div className={styles.companyInfo}>
        <div className={styles.companyLogo}>
          <img src={logo || "/assets/office-building.png"} alt="user-company" />
        </div>
        <h5 className="">{companyDetails?.business.company_name}</h5>
      </div>

      <div className={styles.topbarAvatar}>
        <div onClick={navigateToProfile}>
          {personal?.avatar && (
            <img src={personal?.avatar} alt={personal?.fullname} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionNavbar;
