import React from "react";
import { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import HocHeader from "../../ui/HocHeader";
import ApproveRequestsTable from "./ApproveRequestsTable";

import { LoadingOutlined } from "@ant-design/icons";

import {
  useGetAllEmployeesRequestHistory,
  useGetRequestTemplates,
  useGetUserProfile,
} from "../../../util/usershookActions";
import dayjs from "dayjs";

import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

const ApproveRequests = () => {
  const [enabled, setEnabled] = useState(true);
  const [singleTemplateEnabled, setSingleTemplateEnabled] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const { data: userProfile, isLoading } = useGetUserProfile(
    enabled,
    setEnabled
  );


  const {
    data: requestTemplates,
    isLoading: isLoadingRequestTemplates,
    refetch: refetchRequestTemplates,
  } = useGetRequestTemplates(enabled, setEnabled);

  const {
    data: allEmployeesRequestHistory,
    refetch: refetchAllEmployeesRequestHistory,
    isLoading: isLoadingAllEmployeesRequestHistory,
  } = useGetAllEmployeesRequestHistory(
    singleTemplateEnabled,
    setSingleTemplateEnabled,
    selectedTemplateId,
    from,
    to
  );

  const onChange = (value) => {
    setSelectedTemplateId(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (selectedTemplateId) {
      setSingleTemplateEnabled(true);
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (from && to) {
      refetchAllEmployeesRequestHistory();
    }
  }, [from, refetchAllEmployeesRequestHistory, to]);

  useEffect(() => {
    setFrom("");
    setTo("");
  }, [selectedTemplateId]);

  return (
    <>
      <div>
        <HocHeader
          module_title="Request Management"
          module_class="performance-header"
          module_name={"Approve Requests"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "1.5rem",
              }}
            >
              <div className="">
                <h3 className="h4">Approve/Decline Request</h3>
                <p>
                  You can take actions by either accepting or rejecting
                  employees request here
                </p>
              </div>

              <div className="d-flex gap-2 justify-content-end align-items-center">
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a template"
                  optionFilterProp="children"
                  value={selectedTemplateId}
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    width: 250,
                  }}
                  options={requestTemplates?.payload?.map((template) => ({
                    label: template.template_name,
                    value: template.id,
                  }))}
                />
                {isLoadingRequestTemplates ? <LoadingOutlined /> : null}
              </div>
            </div>

            {selectedTemplateId && (
              <div className="d-flex gap-5 mt-5">
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">From</label>
                  <DatePicker
                    size="large"
                    onChange={(date, dateString) =>
                      setFrom(date?.format("YYYY-MM-DD"))
                    }
                    value={from ? dayjs(from, "YYYY-MM-DD") : null}
                    allowClear
                  />
                </div>

                <div className="d-flex flex-column gap-2">
                  <label className="fs-6 fw-bold">To</label>{" "}
                  <DatePicker
                    size="large"
                    onChange={(date, dateString) =>
                      setTo(date?.format("YYYY-MM-DD"))
                    }
                    value={to ? dayjs(to, "YYYY-MM-DD") : null}
                    allowClear
                  />
                </div>
              </div>
            )}

            <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
              {isLoadingAllEmployeesRequestHistory ? (
                <SkeletonUI number={5} />
              ) : allEmployeesRequestHistory &&
                allEmployeesRequestHistory.payload ? (
                <div className="d-flex flex-column gap-3">
                  <h5 className="mt-3">
                    {allEmployeesRequestHistory?.payload?.template_name}
                  </h5>

                  <ApproveRequestsTable
                    allEmployeesRequestHistory={allEmployeesRequestHistory}
                    userID={userProfile?.data?.id}
                    refetchAllEmployeesRequestHistory={
                      refetchAllEmployeesRequestHistory
                    }
                  />
                </div>
              ) : allEmployeesRequestHistory &&
                !allEmployeesRequestHistory.payload ? (
                <NoContent
                  width={"400px"}
                  message={"No Request History Found"}
                  illustration={"/assets/selected.png"}
                />
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center gap-2"
                  style={{
                    height: "70vh",
                  }}
                >
                  <img
                    src="/assets/approve-requests.png"
                    alt="approve requests"
                    width="200"
                  />
                  <h4 className="h5">Welcome to Approve Request</h4>
                  <p>
                    You can see all available request forms and select any of
                    your choice
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ApproveRequests;
