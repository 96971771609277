import React from "react";
import { useState, useRef } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";

import styles from "../../../styles/hrms/UploadEmployees.module.css";
import { uploadMultiple } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import { Button } from "antd";
import { FaFileAlt } from "react-icons/fa";

function UploadJobRole() {
  const dispatch = useDispatch();
  useUserCleanUp();
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [local_spinner, setLocalSpinner] = useState(false);
  const fileUploadRef = useRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const { files } = e.dataTransfer;
    setFile(files[0]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { files } = e.target;
    setFile(files[0]);
  };

  const buttonHandler = () => {
    fileUploadRef.current.click();
  };

  function handleSubmit(e) {
    e.preventDefault();
    const url = "hrm/job-role/excel/upload";
    setLocalSpinner(true);
    const creds = { file };
    uploadMultiple(dispatch, url, creds).then((res) => {
      setLocalSpinner(false);
      if (res?.status === "success") {
        setFile(null);
      }
    });
  }

  return (
    <>
      <div className="container-fluid ">
        <div className="app__section">
          <div className=" py-4">
            <form
              onDragEnter={handleDrag}
              className={styles.fileUpload}
              onSubmit={handleSubmit}
            >
              <input
                type="file"
                id="input-file-upload"
                ref={fileUploadRef}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleChange}
              />
              <label
                htmlFor="input-file-upload"
                className={dragActive ? styles["drag-active"] : ""}
              >
                <div>
                  <span className={styles.uploadIcon}>
                    <CloudUploadOutlined />
                  </span>
                  <p>
                    Drag & drop file or{" "}
                    <span>
                      <button
                        type="button"
                        onClick={buttonHandler}
                        className={styles.uploadBtn}
                      >
                        Browse
                      </button>
                    </span>
                  </p>
                  <small>Supported formats: xls, xlsx</small>
                </div>
              </label>
              {dragActive && (
                <div
                  className={styles.dragFileElement}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
              <div className="d-flex align-items-center justify-content-center gap-3 mt-5">
                <Button
                  htmlType="submit"
                  className="myBtn "
                  size={"large"}
                  loading={local_spinner}
                >
                  Upload Excel
                </Button>
                <Button className="myBtn " htmlType="button" size={"large"}>
                  <a href="/assets/designation_role_excel.xlsx" download>
                    Download template.
                  </a>
                </Button>
              </div>
            </form>
            {file && (
              <div className="d-flex align-items-center gap-2">
                <FaFileAlt
                  className="h1"
                  style={{ color: "var(--clr-primary)" }}
                />
                <p>{file.name}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadJobRole;
