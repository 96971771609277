import React, { useState } from "react";
import HomeLayoutSidebar from "./HomeLayoutSidebar";
import HomeLayoutTopbar from "./HomeLayoutTopbar";
import { ColorRing } from "react-loader-spinner";
import { Drawer, Space, Button } from "antd";

import AllEventCalendar from "../dashboard/AllEventCalendar";
import IdleContainer from "../IdleContainer";
import { GoCalendar } from "react-icons/go";
import { useShallowEqualSelector } from "../../util/hooks";

import { user_spinner } from "../../util/selectors/userSelectors";
import { preference_spinner } from "../../util/selectors/preferenceSelectors";

import styles from "../../styles/Dashboard.module.css";
import HomeLayoutRightSidebar from "./HomeLayoutRightSidebar";
import { Outlet } from "react-router-dom";

function Layout() {
  const [open, setOpen] = useState(false);
  const userSpinner = useShallowEqualSelector(user_spinner);
  const preferenceSpinner = useShallowEqualSelector(preference_spinner);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <HomeLayoutSidebar />
      <HomeLayoutTopbar />
      <span
        onClick={showDrawer}
        className={`${styles.twack}  ${styles.twack_dasboard}`}
      >
        <GoCalendar />
      </span>

      <Drawer
        onClose={onClose}
        open={open}
        width={"95%"}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        keyboard="true"
      >
        <AllEventCalendar />
      </Drawer>

      <div
        className={`${styles.layoutContent} "container-fluid container-fluid-reset "`}
        style={{
          paddingTop: "100px",
        }}
      >
        <Outlet />
      </div>

      <HomeLayoutRightSidebar />

      {userSpinner || preferenceSpinner ? (
        <div className={styles.loader}>
          <ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["white", "white", "white", "white", "white"]}
          />
        </div>
      ) : null}
      <IdleContainer />
    </div>
  );
}

export default Layout;
