import React from "react";
import { NavLink } from "react-router-dom";


function SurveySectionDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"survey-section"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Active Surveys</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"survey-section/inactive-surveys"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Inactive Surveys</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(SurveySectionDropdown);
