import React from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import Category from "./approvals/Category";
import Exceptions from "./approvals/Exceptions";

function Approvals() {
  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Approvals</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <Category />
        <Exceptions />
      </div>
    </div>
  );
}

export default Approvals;
