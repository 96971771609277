import React, { useEffect, useState } from "react";
import { Button, InputNumber } from "antd";

import { useShallowEqualSelector } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";
import { useDispatch } from "react-redux";
import { addDeductionTemplate } from "../../../redux/users/userActions";
function CreateTemplate({ update, refetch, selected }) {
  const dispatch = useDispatch();
  const [tem_name, setTemName] = useState("");
  const [description, setDescription] = useState("");
  const [band_0, setBand0] = useState("");
  const [band_1, setBand1] = useState("");
  const [band_2, setBand2] = useState("");
  const [band_3, setBand3] = useState("");
  const [band_4, setBand4] = useState("");
  const [band_5, setBand5] = useState("");
  const [band_6, setBand6] = useState("");
  const [band_0_per, setBand0Per] = useState("");
  const [band_1_per, setBand1Per] = useState("");
  const [band_2_per, setBand2Per] = useState("");
  const [band_3_per, setBand3Per] = useState("");
  const [band_4_per, setBand4Per] = useState("");
  const [band_5_per, setBand5Per] = useState("");
  const [band_6_per, setBand6Per] = useState("");
  const [pen_employee, setPenEmployee] = useState("");
  const [pen_employer, setPenEmployer] = useState("");
  const back_errors = useShallowEqualSelector(user_errors);
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const creds = {
      id: update ? selected.id : null,
      band_name: tem_name,
      description: description,
      band0: {
        amount: band_0,
        tax_applied: band_0_per,
      },
      band1: {
        amount: band_1,
        tax_applied: band_1_per,
      },
      band2: {
        amount: band_2,
        tax_applied: band_2_per,
      },
      band3: {
        amount: band_3,
        tax_applied: band_3_per,
      },
      band4: {
        amount: band_4,
        tax_applied: band_4_per,
      },
      band5: {
        amount: band_5,
        tax_applied: band_5_per,
      },
      band6: {
        amount: band_6,
        tax_applied: band_6_per,
      },
      pension_employee: pen_employee,
      pension_employer: pen_employer,
      leave_allowance: "1",
    };

    if (!update) {
      delete creds.id;
    }
    setLoading(true);
    addDeductionTemplate(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        resetForm();

        refetch();
      }
    });
  }

  function resetForm() {
    if (!update) {
      setTemName("");
      setDescription("");
      setBand0("");
      setBand1("");
      setBand2("");
      setBand3("");
      setBand4("");
      setBand5("");
      setBand6("");
      setBand0Per("");
      setBand1Per("");
      setBand2Per("");
      setBand3Per("");
      setBand4Per("");
      setBand5Per("");
      setBand6Per("");
      setPenEmployee("");
      setPenEmployer("");
    }
  }
  function setForm(selected) {
    setTemName(selected?.tem_name);
    setDescription(selected?.description);
    setBand0(selected?.band_0);
    setBand1(selected?.band_1);
    setBand2(selected?.band_2);
    setBand3(selected?.band_3);
    setBand4(selected?.band_4);
    setBand5(selected?.band_5);
    setBand6(selected?.band_6);

    setBand0Per(selected?.band_0_per);
    setBand1Per(selected?.band_1_per);
    setBand2Per(selected?.band_2_per);
    setBand3Per(selected?.band_3_per);
    setBand4Per(selected?.band_4_per);
    setBand5Per(selected?.band_5_per);
    setBand6Per(selected?.band_6_per);

    setPenEmployee(selected?.pen_employee);
    setPenEmployer(selected?.pen_employer);
  }

  useEffect(() => {
    if (selected) {
      setForm(selected);
    }
  }, [selected]);

  return (
    <form className="performance-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="d-flex justify-content-between flex-wrap">
            <label htmlFor="tem_name">Template Name</label>

            <small className="text-danger">
              {back_errors.tem_name && back_errors.tem_name[0]}
            </small>
          </div>

          <input
            type="text"
            name="tem_name"
            id="tem_name"
            value={tem_name}
            onChange={(e) => setTemName(e.target.value)}
            placeholder="Template Name"
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <div className="d-flex justify-content-between flex-wrap">
            <label htmlFor="description">Template Description</label>

            <small className="text-danger">
              {back_errors.description && back_errors.description[0]}
            </small>
          </div>

          <textarea
            type="text"
            name="description"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Template Description"
          />
        </div>
      </div>
      <hr />

      <div className=" w-100  ">
        <div className="d-flex gap-5">
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 0 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_0"
                id="band_0"
                value={band_0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand0(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_0_per"
                id="band_0_per"
                value={band_0_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand0Per(val)}
                placeholder="1"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 1 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_1"
                id="band_1"
                value={band_1}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand1(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_1_per"
                id="band_1_per"
                value={band_1_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand1Per(val)}
                placeholder="1"
                required
              />
            </div>

            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 2 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_2"
                id="band_2"
                value={band_2}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand2(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_2_per"
                id="band_2_per"
                value={band_2_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand2Per(val)}
                placeholder="1"
                required
              />
            </div>

            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 3 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_3"
                id="band_3"
                value={band_3}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand3(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_3_per"
                id="band_3_per"
                value={band_3_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand3Per(val)}
                placeholder="1"
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 4 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_4"
                id="band_4"
                value={band_4}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand4(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_4_per"
                id="band_4_per"
                value={band_4_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand4Per(val)}
                placeholder="1"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 5 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_5"
                id="band_5"
                value={band_5}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand5(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_5_per"
                id="band_5_per"
                value={band_5_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand5Per(val)}
                placeholder="1"
                required
              />
            </div>

            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Band 6 - Flat</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_6"
                id="band_6"
                value={band_6}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand6(val)}
                placeholder="100,000"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> % applied</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="band_6_per"
                id="band_6_per"
                value={band_6_per}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setBand6Per(val)}
                placeholder="1"
                required
              />
            </div>

            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name">Pension - Employee%</label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="pen_employee"
                id="pen_employee"
                value={pen_employee}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setPenEmployee(val)}
                placeholder="8"
                required
              />
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex justify-content-between flex-wrap">
                <label htmlFor="tem_name"> Pension - Employer% </label>

                <small className="text-danger">
                  {back_errors.tem_name && back_errors.tem_name[0]}
                </small>
              </div>

              <InputNumber
                className="w-100"
                name="pen_employer"
                id="pen_employer"
                value={pen_employer}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setPenEmployer(val)}
                placeholder="10"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        {update ? (
          <Button htmlType="submit" className="myBtn" loading={loading}>
            Update
          </Button>
        ) : (
          <Button htmlType="submit" className="myBtn" loading={loading}>
            Add
          </Button>
        )}
      </div>
    </form>
  );
}

export default CreateTemplate;
