import React, { useState, useEffect } from "react";
import HocHeader from "../../ui/HocHeader";
import { DatePicker, Button, Select } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import UnitOfMeasure from "./UnitOfMeasure";
import PlanningSection from "./PlanningSection";
import { useGetPerformanceSettings } from "../../../util/usershookActions";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { savePerformanceSettings } from "../../../redux/users/userActions";

const PerformanceSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [savePerformanceLoading, setSavePerformanceLoading] = useState(false);

  const [performance_start_date, setPerformanceStartDate] = useState("");
  const [performance_end_date, setPerformanceEndDate] = useState("");
  const [performance_tool, setPerformanceTool] = useState("");

  const { data, refetch } = useGetPerformanceSettings(enabled, setEnabled);

  // console.log("beam", data?.payload);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setPerformanceStartDate(data?.payload.performance_planning_window_from);
      setPerformanceEndDate(data?.payload.performance_planning_window_to);
      setPerformanceTool(data?.payload.performance_tool);
    }
  }, [data]);

  const onChange = (value) => {
    setPerformanceTool(value);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleViewModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleViewSectionModal = () => {
    setIsSectionModalOpen(!isSectionModalOpen);
  };

  const handleSavePerformanceSettings = (e) => {
    e.preventDefault();
    setSavePerformanceLoading(true);
    const creds = {
      performance_start_date,
      performance_end_date,
      performance_tool,
    };
    savePerformanceSettings(dispatch, creds).then(() => {
      setSavePerformanceLoading(false);
      refetch();
    });
  };

  return (
    <>
      <div>
        <HocHeader
          module_title="Performance Management"
          module_class="performance-header"
          module_name={"Settings"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3 ">
            <h5>General</h5>
            <div className="mt-5 d-flex flex-column gap-3">
              <div className="d-flex justify-content-between">
                <div className="">
                  <h6>Performance planning window</h6>
                  <p>
                    This helps to define when performance plan can be initiated
                    by administrator and the latest time for setting of key{" "}
                    <br />
                    performance indicators by other actors within the financial
                    year.
                  </p>
                </div>
                <div className="d-flex gap-5">
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">From</label>
                    <DatePicker
                      size="large"
                      onChange={(val, dateSelected) => {
                        setPerformanceStartDate(dateSelected);
                      }}
                      value={
                        performance_start_date
                          ? dayjs(performance_start_date)
                          : null
                      }
                      style={{
                        width: "12rem",
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">To</label>{" "}
                    <DatePicker
                      size="large"
                      onChange={(val, dateSelected) => {
                        setPerformanceEndDate(dateSelected);
                      }}
                      value={
                        performance_end_date
                          ? dayjs(performance_end_date)
                          : null
                      }
                      style={{
                        width: "12rem",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-end">
                <div className="">
                  <h6>Target measuring unit</h6>
                  <p>
                    What unit of measurement do you want to use in estimating
                    your performance targets.
                  </p>
                </div>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                  onClick={handleViewModal}
                >
                  <span>Add</span> <IoIosAddCircleOutline size={20} />
                </Button>
              </div>

              <UnitOfMeasure
                isModalOpen={isModalOpen}
                handleViewModal={handleViewModal}
              />

              <div className="d-flex justify-content-between align-items-end">
                <div className="">
                  <h6>Performance planning section</h6>
                  <p>Add sections.</p>
                </div>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                  onClick={handleViewSectionModal}
                >
                  <span>Add</span> <IoIosAddCircleOutline size={20} />
                </Button>
              </div>

              <PlanningSection
                isModalOpen={isSectionModalOpen}
                handleViewModal={handleViewSectionModal}
              />

              <div className="d-flex justify-content-between align-items-end">
                <div className="">
                  <h6>Performance Tool</h6>
                  <p>
                    What approach would you like to use to run your organization
                    wide performance process?
                  </p>
                </div>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a tool"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                  options={[
                    {
                      value: "MBO",
                      label: "MBO",
                    },
                    {
                      value: "BSC",
                      label: "BSC",
                    },
                    {
                      value: "OKR",
                      label: "OKR",
                    },
                  ]}
                  value={performance_tool}
                />
              </div>

              <div className="d-flex justify-content-start">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    marginTop: "2rem",
                  }}
                  shape="round"
                  loading={savePerformanceLoading}
                  onClick={handleSavePerformanceSettings}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PerformanceSettings;
