import { useEffect, useState } from "react";
import { Input, Table, Checkbox, Button, Modal, Alert } from "antd";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetAppraisalsApproved,
  useGetTeamScoreCard,
  useGetLineManagerAppraisal,
  useGetAppraisalSettings,
} from "../../../../util/usershookActions";
import {
  manageTeamMember,
  nextLevelAppraiserDeliverableComment,
} from "../../../../redux/users/userActions";
import { FaComment } from "react-icons/fa";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import dayjs from "dayjs";

const ManageTeamMember = () => {
  const { TextArea } = Input;
  const [enabled, setEnabled] = useState(true);
  const [teamEnabled, setTeamEnabled] = useState(true);
  const [tableData, setTableData] = useState();
  const [manageTeamLoading, setManageTeamLoading] = useState(false);
  const [sendBackAppraisalLoading, setSendBackAppraisalLoading] =
    useState(false);
  const { teamMemberID } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const selectedEmployeeDetails = location.state?.selectedEmployeeDetails;

  const { data: appraisalSettings } = useGetAppraisalSettings(
    enabled,
    setEnabled
  );

  const {
    data: lineManagerAppraisal,
    isLoading: isLoadingLineManagerAppraisal,
    refetch: refetchLineManagerAppraisal,
  } = useGetLineManagerAppraisal(enabled, setEnabled, teamMemberID);

  const { data: inProgressAppraisal, isLoading: isLoadingInProgressAppraisal } =
    useGetAppraisalsApproved(enabled, setEnabled);

  const {
    data: teamScoreCard,
    isLoading: isLoadingTeamScoreCard,
    refetch,
  } = useGetTeamScoreCard(teamEnabled, setTeamEnabled, teamMemberID);

  // console.log("teamMemberID", teamMemberID);

  let inProgressReview;

  if (inProgressAppraisal && inProgressAppraisal.payload[0]) {
    inProgressReview = inProgressAppraisal?.payload?.filter(
      (appraisal) => appraisal.status === "in-progress"
    );
  }

  const [cascade_ids, setCascadeIds] = useState([]);
  const [employee_score, setEmployeeScores] = useState([]);
  console.log("employee_score", employee_score);

  const [employeeStrength, setEmployeeStrength] = useState("");
  const [employeeWeakness, setEmployeeWeakness] = useState("");
  const [employeePerformanceGap, setEmployeePerformanceGap] = useState("");
  const [remedialAction, setRemedialAction] = useState("");
  const [medicalFitnessAlertness, setMedicalFitnessAlertness] = useState("");
  const [lineManagerComment, setLineManagerComment] = useState("");
  const [checkboxValues, setCheckboxValues] = useState({
    training: false,
    promotion: false,
    transfer: false,
    salary_adjustment: false,
    extension_of_probationary_period: false,
    review_of_continued_employment: false,
    confirmation_of_appointment: false,
  });

  useEffect(() => {
    if (lineManagerAppraisal?.payload == null) {
      return;
    } else {
      setEmployeeStrength(lineManagerAppraisal.payload.employee_strength);
      setEmployeeWeakness(lineManagerAppraisal.payload.employee_weakness);
      setEmployeePerformanceGap(
        lineManagerAppraisal.payload.employee_performance_gap
      );
      setRemedialAction(lineManagerAppraisal.payload.remedial_action);
      setMedicalFitnessAlertness(
        lineManagerAppraisal.payload.medical_fitness_alertness
      );
      setLineManagerComment(
        lineManagerAppraisal.payload?.summary_comments?.second_level_comment
          ?.comment
      );

      // Initialize a new state object
      let newState = {
        training: lineManagerAppraisal.payload.training === 1,
        promotion: lineManagerAppraisal.payload.promotion === 1,
        transfer: lineManagerAppraisal.payload.transfer === 1,
        salary_adjustment: lineManagerAppraisal.payload.salary_adjustment === 1,
        extension_of_probationary_period:
          lineManagerAppraisal.payload.extension_of_probationary_period === 1,
        review_of_continued_employment:
          lineManagerAppraisal.payload.review_of_continued_employment === 1,
        confirmation_of_appointment:
          lineManagerAppraisal.payload.confirmation_of_appointment === 1,
      };

      setCheckboxValues(newState);
    }
  }, [lineManagerAppraisal]);

  useEffect(() => {
    if (teamScoreCard) {
      const newCascadeIds = teamScoreCard.payload.map(
        (scoreCard) => scoreCard.id
      );
      const newEmployeeScores = teamScoreCard.payload.map(
        (scoreCard) => scoreCard.achieved.second_level
      );
      setCascadeIds(newCascadeIds);
      setEmployeeScores(newEmployeeScores);
    }
  }, [teamScoreCard]);

  const handleScoreChange = (index, value) => {
    const updatedScores = [...employee_score];
    updatedScores[index] = value;
    setEmployeeScores(updatedScores);
    // console.log("updatedScores", updatedScores);
  };

  useEffect(() => {
    if (teamScoreCard) {
      const new_array = teamScoreCard?.payload?.map((scoreCard, index) => {
        // Calculate the actual score based on user input
        const user_input = Number(employee_score[index]);
        const actual_score =
          (user_input / scoreCard.individual_target) * scoreCard.weight;

        return {
          key: scoreCard.id,
          section: scoreCard.section,
          goal: scoreCard.goal,
          objective: scoreCard.objective,
          kpi: scoreCard.kpi,
          target: `${scoreCard.individual_target} (${scoreCard.unit})`,
          weight: scoreCard.weight,
          ...Object.keys(scoreCard.achieved).reduce((acc, level) => {
            if (level !== "second_level") {
              acc[level] = scoreCard.achieved[level];
            } else {
              acc[level] = (
                <Input
                  size="large"
                  id={`score-${scoreCard.id}`}
                  defaultValue={scoreCard.achieved[level]}
                  placeholder="Type score here"
                  style={{
                    backgroundColor: scoreCard.achieved[level]
                      ? "#fff"
                      : "transparent",
                    cursor: scoreCard.achieved[level] ? "text" : "pointer",
                    border: "1px solid black",
                    borderColor: scoreCard.achieved[level]
                      ? "transparent"
                      : "#d9d9d9",
                    borderRadius: "6px",
                  }}
                  bordered={Boolean(scoreCard.achieved[level])}
                  addonAfter={scoreCard.unit}
                  value={employee_score[index]}
                  onChange={(e) => {
                    handleScoreChange(index, Number(e.target.value));
                    // Trigger a re-render to update the actual score
                    setTableData((prev) => [...prev]);
                  }}
                  disabled={
                    !appraisalSettings?.payload?.second_appraiser
                      ?.appraise_employee
                  }
                />
              );
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.actual_score).reduce((acc, level) => {
            if (level === "second_level_actual_score") {
              acc[level] = isNaN(actual_score)
                ? scoreCard.actual_score[level]
                : actual_score;
            } else {
              acc[level] = scoreCard.actual_score[level];
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.score).reduce((acc, period) => {
            acc[period] = scoreCard.score[period];
            return acc;
          }, {}),
          ...Object.keys(scoreCard.comments).reduce((acc, level) => {
            if (level !== "employee_comment") {
              acc[level] = scoreCard.comments[level]?.comment;
            } else {
              acc[level] = scoreCard.comments[level];
            }
            return acc;
          }, {}),
          scoreCard,
        };
      });
      setTableData(new_array);
    }
  }, [employee_score, teamScoreCard]);

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "center",
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "KPI",
      dataIndex: "kpi",
      align: "center",
    },
    {
      title: "Target",
      dataIndex: "target",
      align: "center",
    },
    {
      title: "Achieved Score",
      align: "center",
      children: Object.keys(teamScoreCard?.payload?.[0]?.achieved || {}).map(
        (level) => ({
          width: level === "second_level" ? "15rem" : "auto",
          title: level
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          dataIndex: level,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      align: "center",
    },
    {
      title: "Actual Score",
      children: Object.keys(
        teamScoreCard?.payload?.[0]?.actual_score || {}
      ).map((level) => ({
        title: `${level} (%)`
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        dataIndex: level,
        align: "center",
        render: (text) => (text === null ? "N/A" : text),
      })),
    },
    {
      title: "Score",
      align: "center",
      children: Object.keys(teamScoreCard?.payload?.[0]?.score || {}).map(
        (period) => ({
          title: period.toUpperCase(),
          dataIndex: period,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Deliverable Comments",
      children: Object.keys(teamScoreCard?.payload?.[0]?.comments || {}).map(
        (level) => ({
          title: level
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          dataIndex: level,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <EachAction
          item={record}
          refetch={refetch}
          teamMemberID={teamMemberID}
          deliverableCommentAbility={
            appraisalSettings?.payload?.second_appraiser?.comment_on_deliverable
          }
        />
      ),
    },
  ];

  const options = [
    { label: "Training", value: "training" },
    { label: "Promotion", value: "promotion" },
    { label: "Transfer", value: "transfer" },
    { label: "Salary Adjustment", value: "salary_adjustment" },
    {
      label: "Extension of Probationary Period",
      value: "extension_of_probationary_period",
    },
    {
      label: "Review of Continued Employment",
      value: "review_of_continued_employment",
    },
    {
      label: "Confirmation of Appointment",
      value: "confirmation_of_appointment",
    },
  ];

  const onChange = (checkedValues) => {
    // Initialize a new state object
    let newState = {
      training: false,
      promotion: false,
      transfer: false,
      salary_adjustment: false,
      extension_of_probationary_period: false,
      review_of_continued_employment: false,
      confirmation_of_appointment: false,
    };

    // Update the state object based on the checked values
    checkedValues.forEach((value) => {
      newState[value] = true;
    });

    // Set the new state
    setCheckboxValues(newState);
    // console.log("checked = ", checkedValues);
  };

  const handleManageTeamMember = (e) => {
    e.preventDefault();
    setManageTeamLoading(true);
    const creds = {
      cascade_ids,
      employee_id: teamMemberID,
      employee_achievement: employee_score,
      employee_strength: employeeStrength,
      employee_weakness: employeeWeakness,
      employee_performance_gap: employeePerformanceGap,
      remedial_action: remedialAction,
      medical_fitness_alertness: medicalFitnessAlertness,
      line_manager_comment: lineManagerComment,
      ...checkboxValues,
    };
    manageTeamMember(dispatch, creds).then(() => {
      setManageTeamLoading(false);
      refetch();
      refetchLineManagerAppraisal();
    });
  };

  const handleSendAppraisalBack = (e) => {
    e.preventDefault();
    setSendBackAppraisalLoading(true);
    const creds = {
      cascade_ids,
      employee_id: teamMemberID,
      employee_achievement: employee_score,
      employee_strength: employeeStrength,
      employee_weakness: employeeWeakness,
      employee_performance_gap: employeePerformanceGap,
      remedial_action: remedialAction,
      medical_fitness_alertness: medicalFitnessAlertness,
      line_manager_comment: lineManagerComment,
      action: "send_back",
      ...checkboxValues,
    };
    manageTeamMember(dispatch, creds).then(() => {
      setSendBackAppraisalLoading(false);
      refetch();
      refetchLineManagerAppraisal();
    });
  };

  return (
    <div className="">
      {isLoadingInProgressAppraisal ? (
        <SkeletonUI number={2} />
      ) : (
        <div className="monitoring__individual__details">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Employee Name</label>
            <Input
              size="large"
              id="employeeName"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.name
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Position</label>
            <Input
              size="large"
              id="position"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.job_role
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Department</label>
            <Input
              size="large"
              id="department"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.department
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Supervisor Name</label>
            <Input
              size="large"
              id="supervisorName"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.primary_line_manager
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Review Period</label>
            <Input
              size="large"
              id="reviewPeriod"
              value={inProgressReview?.[0] && `${dayjs(
                inProgressReview?.[0]?.review_period_date?.from
              ).format("DD/MM/YYYY")} - ${dayjs(
                inProgressReview[0]?.review_period_date?.to
              ).format("DD/MM/YYYY")}`}
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Date of Performance Review</label>
            <Input
              size="large"
              id="dateOfReview"
              value={inProgressReview?.[0] && `${dayjs(inProgressReview[0]?.start_date).format(
                "DD/MM/YYYY"
              )} - ${dayjs(inProgressReview[0]?.end_date).format(
                "DD/MM/YYYY"
              )}`}
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>
        </div>
      )}

      <div className="monitoring__individual__content__table">
        {isLoadingTeamScoreCard ? (
          <SkeletonUI number={2} />
        ) : (
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 2200 }}
            bordered
            pagination={false}
          />
        )}
      </div>

      {isLoadingLineManagerAppraisal ? (
        <SkeletonUI />
      ) : (
        <form className="">
          <div className="manage__team__member__textareas">
            <div className="d-flex flex-column align-items-center gap-2 ">
              <label className="fw-bold fs-6">Employee's Strength</label>
              <TextArea
                rows={6}
                value={employeeStrength}
                onChange={(e) => setEmployeeStrength(e.target.value)}
              />
            </div>

            <div className="d-flex flex-column align-items-center gap-2 ">
              <label className="fw-bold fs-6">Employee's Weakness</label>
              <TextArea
                rows={6}
                value={employeeWeakness}
                onChange={(e) => setEmployeeWeakness(e.target.value)}
              />
            </div>

            <div className="d-flex flex-column align-items-center gap-2 ">
              <label className="fw-bold fs-6">
                Identify Employee Performance Gap
              </label>
              <TextArea
                rows={6}
                value={employeePerformanceGap}
                onChange={(e) => setEmployeePerformanceGap(e.target.value)}
              />
            </div>

            <div className="d-flex flex-column align-items-center gap-2 ">
              <label className="fw-bold fs-6">
                State Remedial action relevant over the next FY
              </label>
              <TextArea
                rows={6}
                value={remedialAction}
                onChange={(e) => setRemedialAction(e.target.value)}
              />
            </div>
          </div>

          <div className="">
            <div className="d-flex flex-column align-items-center gap-4 ">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">
                  Medical Fitness and Alertness
                </label>
                <span className="">
                  State any factor for example health, personal difficulties,
                  organizational problems (e.g. stock out, working tools,
                  logistics, leadership style e.t.c) or any other
                  internal/external/extraneous factors which might have affected
                  the staff job performance during the period under review.
                </span>
              </div>
              <TextArea
                rows={6}
                value={medicalFitnessAlertness}
                onChange={(e) => setMedicalFitnessAlertness(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 py-5 manage__team__checkbox">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">Recommended For</label>
              <span className="">
                Recommend employee based on performance and ratings
              </span>
            </div>

            <Checkbox.Group
              options={options}
              value={Object.keys(checkboxValues).filter(
                (key) => checkboxValues[key]
              )}
              onChange={onChange}
            />
          </div>

          <div className="manage__team__member__textareas">
            {lineManagerAppraisal?.payload != null &&
              Object.entries(
                lineManagerAppraisal?.payload?.summary_comments
              ).map(([key, value]) => {
                if (key !== "second_level_comment") {
                  if (key === "employee_comment") {
                    return (
                      <div className="d-flex flex-column align-items-center gap-2 ">
                        <label className="fw-bold fs-6">
                          {key
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </label>
                        <TextArea rows={6} value={value || "N/A"} disabled />
                      </div>
                    );
                  } else {
                    return (
                      <div className="d-flex flex-column align-items-center gap-2 ">
                        <label className="fw-bold fs-6">
                          {key
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </label>
                        <TextArea
                          rows={6}
                          value={value?.comment || "N/A"}
                          disabled
                        />
                      </div>
                    );
                  }
                }
              })}
            <div className="d-flex flex-column align-items-center gap-2 ">
              <label className="fw-bold fs-6">
                Line Manager's Summary Comment
                {appraisalSettings?.payload?.enforcement
                  ?.supervisor_summary_comment && (
                  <span className="text-danger">*</span>
                )}
              </label>
              <TextArea
                rows={6}
                value={lineManagerComment}
                onChange={(e) => setLineManagerComment(e.target.value)}
                disabled={
                  !appraisalSettings?.payload?.second_appraiser?.summary_comment
                }
                required={
                  appraisalSettings?.payload?.enforcement
                    ?.supervisor_summary_comment
                }
              />
            </div>
          </div>

          {appraisalSettings?.payload?.enforcement?.line_manager_comment && (
            <Alert
              message="Warning"
              description="Note that you are required to submit deliverable comments before submitting the appraisal. Ignore this message if you have already submitted deliverable comments."
              type="warning"
              showIcon
            />
          )}
          {appraisalSettings?.payload?.enforcement
            ?.employee_supervisor_submit_back &&
            teamScoreCard?.payload[0]?.no_of_iteration === 0 && (
              <Alert
                description="You are required to submit back your appraisal back to your subordinate before your final submission."
                type="warning"
                className="mt-4"
                showIcon
              />
            )}

          <div className="mt-4 d-flex justify-content-center gap-3">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={handleManageTeamMember}
              loading={manageTeamLoading}
              disabled={
                !teamMemberID ||
                !employee_score ||
                (appraisalSettings?.payload?.enforcement
                  ?.employee_supervisor_submit_back &&
                  teamScoreCard?.payload[0]?.no_of_iteration === 0) ||
                (appraisalSettings?.payload?.enforcement
                  ?.supervisor_summary_comment &&
                  !lineManagerComment)
              }
            >
              Submit
            </Button>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "darkgreen",
              }}
              onClick={handleSendAppraisalBack}
              loading={sendBackAppraisalLoading}
              disabled={
                !teamMemberID ||
                !employee_score ||
                (appraisalSettings?.payload?.enforcement
                  ?.supervisor_summary_comment &&
                  !lineManagerComment)
              }
            >
              Send Back
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

function EachAction({
  item,
  refetch,
  teamMemberID,
  deliverableCommentAbility,
}) {
  const { TextArea } = Input;
  const [lineManagerComment, setLineManagerComment] = useState(
    item.scoreCard?.comments?.second_level_comment?.comment
  );
  const [lineManagerCommentLoading, setLineManagerCommentLoading] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleModalOpen() {
    setIsModalOpen(!isModalOpen);
  }
  const dispatch = useDispatch();

  // console.log("item", item);

  const handleAddLineManagerComment = (e) => {
    const creds = {
      cascade_id: item.key,
      employee_id: teamMemberID,
      comment: lineManagerComment,
    };
    e.preventDefault();
    setLineManagerCommentLoading(true);
    nextLevelAppraiserDeliverableComment(dispatch, creds).then(() => {
      setLineManagerCommentLoading(false);
      refetch();
    });
  };

  return (
    <>
      <div
        className="begin__appraisal__content__table__comment"
        onClick={handleModalOpen}
      >
        <FaComment
          size={30}
          color={
            Object.values(item.scoreCard.comments).some(Boolean)
              ? "blue"
              : "gray"
          }
        />
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleModalOpen}
        centered
        width={"auto"}
      >
        <div
          className="d-flex justify-content-bewteen gap-5"
          style={{
            // maxWidth: "900px",
            overflow: "auto",
            padding: "1.5rem",
          }}
        >
          <div className="w-50">
            <h6>
              Line Manager's can see comments on deliverable by other appraisers
              here
            </h6>

            {Object.entries(item.scoreCard.comments).map(([key, value]) => {
              if (key !== "second_level_comment") {
                if (key === "employee_comment") {
                  return (
                    <div
                      key={key}
                      className="d-flex flex-column align-items-start gap-2 mb-3"
                    >
                      <label className="fw-bold fs-6">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </label>
                      <div>{value || "N/A"}</div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={key}
                      className="d-flex flex-column align-items-start gap-2 mb-3"
                    >
                      <label className="fw-bold fs-6">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </label>
                      <div>{value?.comment || "N/A"}</div>
                    </div>
                  );
                }
              }
              return null;
            })}
          </div>

          <div className="begin__appraisal__content__table__comment__divider"></div>

          <div className="w-50">
            <h6>
              Line Manager can comment on deliverable here and send to the
              employee and other appraisers
            </h6>

            <div className="d-flex flex-column align-items-start gap-2 ">
              <label className="fw-bold fs-6">Line Manager's Comment</label>
              <TextArea
                rows={6}
                value={lineManagerComment}
                onChange={(e) => setLineManagerComment(e.target.value)}
                disabled={!deliverableCommentAbility}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                  marginTop: "2rem",
                }}
                onClick={handleAddLineManagerComment}
                loading={lineManagerCommentLoading}
                disabled={
                  lineManagerComment === "" || !deliverableCommentAbility
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ManageTeamMember;
