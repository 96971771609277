import React, { useEffect, useState } from "react";

import { Button, Input, Select, Modal } from "antd";
import {
  useGetDeductionMasters,
  useGetSystemSalaryStructure,
} from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import {
  createSalaryComponent,
  upadateSalaryComponent,
} from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";

const mL = [
  {
    value: "January",
    label: "January",
  },
  {
    value: "February",
    label: "February",
  },

  {
    value: "March",
    label: "March",
  },
  {
    value: "April",
    label: "April",
  },
  {
    value: "May",
    label: "May",
  },

  {
    value: "June",
    label: "June",
  },

  {
    value: "July",
    label: "July",
  },
  {
    value: "August",
    label: "August",
  },
  {
    value: "September",
    label: "September",
  },
  {
    value: "October",
    label: "October",
  },
  {
    value: "November",
    label: "November",
  },
  {
    value: "December",
    label: "December",
  },
  {
    value: "Anniversary",
    label: "Anniversary",
  },
  // {
  //   value: "Annual Leave",
  //   value: "Annual Leave",
  // },
];

function EditSalaryComponent({ selected_grade, refetch, update }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [enableded, setEnableded] = useState(true);

  const { data: data_dect, isLoading: isLoading_dect } = useGetDeductionMasters(
    enableded,
    setEnableded
  );

  // const { data: data_wage, isLoading: isLoading_wage } = useGetWageItemSettings(
  //   enableded,
  //   setEnableded,
  //   selected_grade?.id
  // )

  console.log({ data_dect });
  // console.log("data_wage", data_wage);

  const [option_data, setOptionData] = useState([]);
  const [secondary_data, setSecondaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [deduction_master_id, setDeductionMasterId] = useState("");
  const [grade_id, setGradeId] = useState("");
  const [update_flag, setUpdateFlag] = useState(false);

  // for update
  const {
    data: sal_data,
    isLoading,
    refetch: refetch_one,
  } = useGetSystemSalaryStructure(enabled, setEnabled, selected_grade?.id);
  // for update

  function handleDynChangeSec(e, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    if (e.target.value === "true") {
      affected[e.target.name] = true;
    } else if (e.target.value === "false") {
      affected[e.target.name] = false;
    } else {
      affected[e.target.name] = e.target.value;
    }
    setSecondaryData(new_arry);
  }

  function addNewComponent(e) {
    const new_arry = [...secondary_data];
    new_arry.splice(new_arry.length, 1, {
      payroll_item_name: "",
      type: "CR",
      gross_or_basic: "0",
      account_code: "",
      show_on_payslip: 1,
      taxable: 1,
      pensionable: 1,
      status: 1,
      is_annual: 0,
      is_basic: 0,
      when_to_pay: "",
    });
    setSecondaryData(new_arry);
  }

  function removeComponent(e, index) {
    const new_arry = [...secondary_data];
    new_arry.splice(index, 1);
    setSecondaryData(new_arry);
  }

  function confirmFunc(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Set up salary component",
      content: "Do you want to perform this action?",
      onOk: setupComponent,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }
  function confirmFuncUpdate(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Update salary component",
      content: "Do you want to perform this action?",
      onOk: updateComponent,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function setupComponent() {
    const creds = {
      grade_id,
      payee_id: 1,
      deduction_master_id,
      template: [...secondary_data],
    };

    // console.log({ creds });
    setLoading(true);
    createSalaryComponent(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }

  function updateComponent() {
    const creds = {
      grade_id,
      payee_id: 1,
      deduction_master_id,
      template: [...secondary_data],
    };

    // console.log({ creds });
    setLoading(true);
    upadateSalaryComponent(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }

  function handleChangeSec(value, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    if (value === "true") {
      affected.is_basic = true;
    } else if (value === "false") {
      affected.is_basic = false;
    } else {
      affected.is_basic = value;
    }
    setSecondaryData(new_arry);
  }

  function handleChangeSecMonth(value, index) {
    const new_arry = [...secondary_data];
    const affected = new_arry[index];
    affected.when_to_pay = value;
    setSecondaryData(new_arry);
  }
  function selectAfter(index) {
    return index === 0 ? (
      <Select
        onChange={(val) => handleChangeSec(val, index)}
        defaultValue={false}
      >
        <option value={false}>% of Gross</option>
      </Select>
    ) : (
      <Select
        onChange={(val) => handleChangeSec(val, index)}
        defaultValue={false}
      >
        <option value={false}>% of Gross</option>
        <option value={true}>% of Basic</option>
      </Select>
    );
  }

  useEffect(() => {
    let new_arr_1;

    new_arr_1 = [
      {
        payroll_item_name: "Basic",
        type: "CR",
        gross_or_basic: "0",
        account_code: "BASIC",
        show_on_payslip: 1,
        taxable: 1,
        pensionable: 1,
        status: 1,
        is_annual: 0,
        is_basic: 0,
        when_to_pay: "",
      },
      {
        payroll_item_name: "Housing",
        type: "CR",
        gross_or_basic: "0",
        account_code: "HOUSE",
        show_on_payslip: 1,
        taxable: 1,
        pensionable: 1,
        status: 1,
        is_annual: 0,
        is_basic: 0,
        when_to_pay: "",
      },
      {
        payroll_item_name: "Transport",
        type: "CR",
        gross_or_basic: "0",
        account_code: "TSP",
        show_on_payslip: 1,
        taxable: 1,
        pensionable: 1,
        status: 1,
        is_annual: 0,
        is_basic: 0,
        when_to_pay: "",
      },
    ];

    console.log({ sal_data });
    if (sal_data?.payload?.length) {
      new_arr_1 = sal_data?.payload?.map(
        (
          {
            payroll_item_name,
            type,
            gross_or_basic,
            account_code,
            show_on_payslip,
            taxable,
            pensionable,
            status,
            is_annual,
            is_basic,
            when_to_pay,
            id,
            deduction_master_id,
          },
          index
        ) => {
          if (deduction_master_id !== 0) {
            setDeductionMasterId(deduction_master_id);
          }
          return {
            payroll_item_name,
            type,
            gross_or_basic,
            account_code,
            show_on_payslip,
            taxable,
            pensionable,
            status,
            is_annual,
            is_basic,
            when_to_pay,
            id,
          };
        }
      );

      // setSecondaryData(new_arr_1);
    }

    setSecondaryData(new_arr_1);
  }, [sal_data]);

  useEffect(() => {
    setGradeId(selected_grade.id);
  }, [selected_grade]);

  useEffect(() => {
    setUpdateFlag(update);
  }, [update]);
  useEffect(() => {
    if (update_flag && grade_id) {
      setEnabled(true);
    }
  }, [update_flag, grade_id]);

  console.log({ secondary_data });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <section className="app__section py-4">
            <div className="d-flex justify-content-end align-items-center w-100 mb-0 gap-5 goal__nav__links">
              Deduction Masters
              <Select
                className="w-25"
                onChange={(val) => setDeductionMasterId(val)}
                placeholder="Please select"
                value={deduction_master_id}
              >
                <option value={""}> --select-- </option>
                {data_dect &&
                  data_dect.payload.map((item) => {
                    return <option value={item.id}> {item.band_name} </option>;
                  })}
              </Select>
              {isLoading_dect ? <LoadingOutlined /> : null}
            </div>
            {/* <div className="d-flex align-items-center justify-content-between ">
              <div className="d-flex gap-3 align-items-center fixed bg-light p-2">
                <h6 className="mb-0 fw-bold"> Total</h6>
                <h6 className="fw-bold mb-0 ">{percent}%</h6>
              </div>
            </div> */}

            {isLoading ? (
              <SkeletonUI number={2} />
            ) : secondary_data && secondary_data?.length ? (
              <>
                <div className="performance-form">
                  <div className="row mt-5">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>
                        <h5 className="mb-0">Wage Item components</h5>
                        <small className=" d-none d-sm-block">
                          All statutory components of the salary structure for{" "}
                          {selected_grade?.name}.
                        </small>
                      </div>

                      <FloatButton
                        icon={<PlusOutlined />}
                        type="primary"
                        style={{
                          right: 24,
                        }}
                        onClick={addNewComponent}
                        tooltip="Add new component"
                      />
                    </div>
                  </div>
                  {secondary_data?.map((item, index) => (
                    <>
                      <div className="row">
                        <div className="group col-8">
                          <div className="row mt-2">
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="payroll_item_name">Name</label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <input
                                type="text"
                                name="payroll_item_name"
                                id=""
                                value={item.payroll_item_name}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"Name"}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="payroll_item_name">
                                  Account Code
                                </label>

                                <small className="text-danger">
                                  {/* {back_errors.name && back_errors.name[0]} */}
                                </small>
                              </div>

                              <input
                                type="text"
                                name="account_code"
                                id=""
                                value={item.account_code}
                                onChange={(e) => handleDynChangeSec(e, index)}
                                placeholder={"account code"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Taxable</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="taxable"
                                value={item.taxable}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}> Yes</option>
                                <option value={0}> No</option>
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="description">Pensionable</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="pensionable"
                                value={item.pensionable}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}> Yes</option>
                                <option value={0}> No</option>
                              </select>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap mb-2">
                                <label htmlFor="description">
                                  Annual Payment
                                </label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="is_annual"
                                value={item?.is_annual}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}> Yes</option>
                                <option value={0}> No</option>
                              </select>
                            </div>

                            {secondary_data[index].is_annual == 1 ? (
                              <div className="form-group col-md-3">
                                <div className="d-flex justify-content-between flex-wrap mb-2">
                                  <label htmlFor="description">
                                    When to pay
                                  </label>

                                  <small className="text-danger">
                                    {/* {back_errors.description && back_errors.description[0]} */}
                                  </small>
                                </div>

                                <Select
                                  allowClear
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Please select"
                                  value={item?.when_to_pay}
                                  onChange={(val) =>
                                    handleChangeSecMonth(val, index)
                                  }
                                  //   data-index={index}
                                  options={mL}
                                />
                              </div>
                            ) : null}

                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="payment_type">
                                  Payment type
                                </label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="type"
                                value={item.type}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value="CR"> Credit item</option>
                                <option value="DR"> Debit item</option>
                                <option value="RL"> Relief item</option>
                                <option value="FP"> Free pay item</option>
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="status">Status</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="status"
                                value={item.status}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}> Active</option>
                                <option value={0}> Inactive</option>
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label htmlFor="show_on_payslip">Show on payslip</label>

                                <small className="text-danger">
                                  {/* {back_errors.description && back_errors.description[0]} */}
                                </small>
                              </div>

                              <select
                                name="show_on_payslip"
                                value={item.show_on_payslip}
                                onChange={(e) => handleDynChangeSec(e, index)}
                              >
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="row mt-2">
                            <div className="d-flex justify-content-end">
                              <div className="form-group col-md-6 ">
                                <div className="d-flex justify-content-between flex-wrap mb-2 w-100">
                                  <label htmlFor="percentage">Percentage</label>

                                  <small className="text-danger">
                                    {/* {back_errors.name && back_errors.name[0]} */}
                                  </small>
                                </div>

                                <Input
                                  type="number"
                                  min={0}
                                  value={item.gross_or_basic}
                                  name="gross_or_basic"
                                  id="gross_or_basic"
                                  addonAfter={selectAfter(index)}
                                  data-my="red"
                                  onChange={(e) => handleDynChangeSec(e, index)}
                                  placeholder="30"
                                  className="re_border"
                                />

                                {/* <div className="d-flex justify-content-end"> */}
                                {!["Housing", "Basic", "Transport"].includes(
                                  secondary_data[index].payroll_item_name
                                ) ? (
                                  <small
                                    className="fw-bold text-danger mt-5 pointer d-block "
                                    onClick={(e) => removeComponent(e, index)}
                                  >
                                    {" "}
                                    Remove
                                  </small>
                                ) : null}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {secondary_data.length - 1 !== index ? (
                        <hr className="mt-3" />
                      ) : null}
                    </>
                  ))}
                </div>

                <div className="d-flex justify-content-between align-items-center mt-5">
                  <Button className="myBtn" onClick={addNewComponent}>
                    {" "}
                    Add new component
                  </Button>
                  {!update ? (
                    <Button
                      className="myBtn"
                      onClick={confirmFunc}
                      loading={loading}
                      disabled={deduction_master_id === "" ? true : false}
                    >
                      Create
                    </Button>
                  ) : (
                    <Button
                      className="myBtn"
                      onClick={confirmFuncUpdate}
                      loading={loading}
                      disabled={deduction_master_id === "" ? true : false}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </>
            ) : (
              // <NoContent message={"No salary structure yet"} width={"400px"} />
              <NoContent width={"400px"} />
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default EditSalaryComponent;
