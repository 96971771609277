import React, { useState, useEffect } from "react";
import { Input, Button, List, Modal } from "antd";
import { useUserCleanUp } from "../../../util/hooks";
import { useGetSystemSettingsUtils } from "../../../util/usershookActions";
import { useShallowEqualSelector } from "../../../util/hooks";
import { settings_util } from "../../../util/selectors/userSelectors";
import { useDispatch } from "react-redux";
import { convertToCapitalize } from "../../../util/helpers";
// import { Link } from "react-router-dom";
import {
  addToCategory,
  deleteFromCategory,
} from "../../../redux/users/userActions";

function CreateUpload() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [value, setValues] = useState("");
  const [save_spinner, setSaveSpinner] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [sel, setSel] = useState({});

  const { refetch } = useGetSystemSettingsUtils(enabled, setEnabled);
  const settingsUtil = useShallowEqualSelector(settings_util);

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSaveSpinner(true);

    const creds = {
      category: "Onboarding", // remove the toLowercase later
      name: value,
    };

    addToCategory(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        if (Object.keys(sel).length) {
          //edit mode
          const new_arry = [...data].map((item) => {
            if (item.id === sel.id) {
              item.name = value;
            }
            return item;
          });
          setData(new_arry);
        } else {
          // new entry
          // const last_id = [...data].sort((a, b) => b.id - a.id)[0].id;
          setData([
            ...data,
            {
              ...res.payload,
              //   category: selected.title.toLowerCase(),
            },
          ]);
        }
        setSel({});
        setValues("");
        refetch();
      }
    });
  };

  const clearEditMode = () => {
    setSel({});
    setValues("");
  };

  useEffect(() => {
    if (settingsUtil?.others?.Onboarding?.length) {
      const filtered = settingsUtil?.others.Onboarding.filter(
        (item) => item.deleted_at === null
      );
      setData(filtered);
    }
  }, [settingsUtil]);

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <form onSubmit={handleSave}>
          <div className="col-md-3">
            {Object.keys(sel).length ? (
              <small className="text-danger mb-2">{`Editing ${sel.name}...`}</small>
            ) : null}
            <div>
              <p>Upload Document Category</p>
              <Input
                type="text"
                name="region"
                placeholder="Add new entry"
                size="large"
                value={value}
                onChange={handleChange}
                allowClear
                className="mt-2"
              />
            </div>
            <div className=" d-flex justify-content-end gap-3 my-3">
              {Object.keys(sel).length ? (
                <Button onClick={clearEditMode} className="myBtn  mb-2">
                  Cancel
                </Button>
              ) : null}
              <Button
                className="myBtn  mb-2"
                loading={save_spinner}
                disabled={!value.length}
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-6">
        <h4>All Categories</h4>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <ListItem
              item={item}
              index={index}
              setValues={setValues}
              setSel={setSel}
              data={data}
              setData={setData}
              refetch={refetch}
            />
          )}
        />
      </div>
    </div>
  );
}

export default CreateUpload;

function ListItem({ item, index, setValues, setSel, data, setData, refetch }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  function confirmdel(item) {
    // alert("here");
    return Modal.confirm({
      title: "Delete upload category?",
      content: "Do you want to delete this upload category?",
      onOk: () => {
        confirm(item);
      },
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  }

  const confirm = (item) => {
    setLoading(true);

    deleteFromCategory(dispatch, item).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        const new_arry = [...data].filter((cat) => item.id !== cat.id);
        setData(new_arry);
        refetch();
      }
    });
  };
  return (
    <List.Item
      actions={[
        // <Popconfirm
        //   title="Delete entries"
        //   description="Do you what to delete this entry?"
        //   onConfirm={() => confirm(item)}
        //   // onCancel={cancel}
        //   okText="Yes"
        //   cancelText="No"
        // >
        <Button
          key="list-loadmore-edit"
          className="text-danger"
          loading={loading}
          onClick={() => confirmdel(item)}
          type="text"
        >
          Delete
        </Button>,
        // </Popconfirm>
        // <Link
        //   key="list-loadmore-more"
        //   className="text-info"
        //   onClick={() => handleEdit(item)}
        // >
        //   edit
        // </Link>,
      ]}
    >
      <List.Item.Meta title={convertToCapitalize(item.name)} />
    </List.Item>
  );
}
