import React, { useState, useEffect } from "react";
import HocHeader from "../../ui/HocHeader";

import { Select, Button, Form } from "antd";
import TemplateRenderer from './../../request-management/make-request/make-new-request/TemplateRenderer';

const FillSurvey = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [selectedTemplateObject, setSelectedTemplateObject] = useState({});

  const onChange = (value, option) => {
    setSelectedTemplateId(value);
    setSelectedTemplateObject(option.template);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/survey?surveyType=internal")
      .then((response) => response.json())
      .then((data) => setTemplates(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const filledRequestForm = async (filledForm) => {
    const response = await fetch(`http://localhost:8000/responses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filledForm),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleSubmitFilledRequestForm = async () => {
    try {
      const result = await filledRequestForm({
        ...selectedTemplateObject,
        id: `${Date.now()}`,
        templateId: selectedTemplateId,
      });
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSetAnswer = (id, answer) => {
    setSelectedTemplateObject((prevSelectedTemplateObject) => ({
      ...prevSelectedTemplateObject,
      questionsItems: prevSelectedTemplateObject.questionsItems.map(
        (question) => {
          if (question.id === id) {
            return {
              ...question,
              answer: answer,
            };
          }
          return question;
        }
      ),
    }));
  };

  useEffect(() => {
    console.log("selectedTemplateObject", selectedTemplateObject);
  }, [selectedTemplateObject]);

  return (
    <>
      <div>
        <HocHeader
          module_title="Survey Management"
          module_class="performance-header"
          module_name={"Fill A Survey"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="">
                <h3 className="h4">Fill Survey</h3>
                <p>You can answer your survey questions here</p>
              </div>

              <div className="d-flex gap-2">
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a template"
                  optionFilterProp="children"
                  value={selectedTemplateId}
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    width: 250,
                  }}
                  options={templates?.map((template) => ({
                    label: template.surveyName,
                    value: template.id,
                    template,
                  }))}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
              {Object.keys(selectedTemplateObject).length ? (
                <Form
                  name="validate_other"
                  onFinish={handleSubmitFilledRequestForm}
                  className="make__request__form"
                >
                  <div className="make__request__form__title">
                    <h4 className="h4">
                      {selectedTemplateObject?.surveyName}
                    </h4>
                  </div>

                  {
                    selectedTemplateObject?.questionsItems &&
                    selectedTemplateObject?.questionsItems.map(
                      (question, index) => (
                        <TemplateRenderer
                          question={question}
                          handleSetAnswer={handleSetAnswer}
                          key={index}
                        />
                      )
                    )}

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      backgroundColor: "var(--clr-primary)",
                      width: "fit-content",
                      margin: "0 auto",
                    }}
                  >
                    Submit
                  </Button>
                </Form>
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center gap-2"
                  style={{
                    height: "70vh",
                  }}
                >
                  <img
                    src="/assets/fillsurvey.png"
                    alt="fillasurvey"
                    width="200"
                  />
                  <h4 className="h5">Welcome to Fill A Survey Form</h4>
                  <p>
                    You can see all available survey forms and fill any of your
                    choice
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FillSurvey;
