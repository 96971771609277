import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function IdentityHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          type: em.identity_type,
          number: em.identity_number || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "IDENTITY TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "IDENTITY NUMBER",
      dataIndex: "number",
      key: "number",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} />;
}

export default IdentityHistory;
