import { Modal, Button } from "antd";
import { useState } from "react";
import { AiFillFilePdf } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { deleteSetupDoc } from "../../../redux/users/userActions";

function UploadFile({ src, title, id, refetch, cat_info }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loadingmail, setLoadingMail] = useState(false);
  const [showModalmal, setShowModalmal] = useState(false);

  const modalHandler = () => setShowModal(!showModal);

  const modalHandlerMail = () => {
    setShowModalmal(!showModalmal);
  };

  const confirmMail = () => {
    // console.log(id);
    setLoadingMail(true);
    Modal.destroyAll();
    const creds = {
      id,
    };

    deleteSetupDoc(dispatch, creds).then((res) => {
      setLoadingMail(false);

      if (res?.status === "success") {
        dispatch({
          type: "DELETE_ONBOARDING_FILE",
          payload: { type: cat_info, id },
        });

        refetch();
      }
    });
  };
  const alertDelete = () => {
    Modal.confirm({
      title: "Delete document",
      content: "Do you want to delete this doument?",
      onOk: confirmMail,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerMail,
      open: showModalmal,
      closable: true,
    });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <AiFillFilePdf className="file-uploaded-icon" />
      <span>{title}</span>
      <div className="d-flex gap-2">
        <Button
          className="myBtn-danger"
          onClick={alertDelete}
          loading={loadingmail}
        >
          Delete
        </Button>
        <Button onClick={modalHandler} className={"myBtn"}>
          Preview
        </Button>
      </div>
      <Modal open={showModal} onCancel={modalHandler} centered footer={null}>
        <div className="pt-5 w-100">
          <object data={src} type="application/pdf" width="100%" height={500}>
            <p>
              Unable to display PDF file.{" "}
              <a href={src} download>
                Download
              </a>{" "}
              instead.
            </p>
          </object>
          {/* <iframe
            src={src}
            frameborder="0"
            title={title}
            width={"100%"}
            height={500}
          ></iframe> */}
        </div>
      </Modal>
    </div>
  );
}

export default UploadFile;
