import React, { useEffect, useState } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { Modal, Input, Switch, Button } from "antd";
import InputCategories from "./input_entries/InputCategories";
import ParentList from "./input_entries/ParentList";
import { useGetSystemSettingsUtils } from "../../../util/usershookActions";
import DisplayDashboard from "./DisplayDashboard";
import { useShallowEqualSelector } from "../../../util/hooks";
import { settings } from "../../../util/selectors/userSelectors";
import { setSettings } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
// import { Input, Space } from "antd";

const { Search } = Input;

function General() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showCatpage, setShowCatpage] = useState(false);
  const [selected, setSelected] = useState({});
  const [enabled_uti, setEnabledUtil] = useState(true);
  const [emp_prefix, setEmpPreFix] = useState("");
  const [emp_character, setEmpCharacter] = useState("");
  const [emp_pattern, setEmpPattern] = useState("");
  const [loading, setLoading] = useState(false);

  const { refetch } = useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  const all_settings = useShallowEqualSelector(settings);

  // console.log("all_settings", all_settings);
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
    setShowCatpage(false);
  };

  const handleCatpage = () => {
    setShowCatpage(!showCatpage);
  };

  const handleCatSelected = (item) => {
    console.log(item);
    setSelected(item);
    setShowCatpage(true);
  };

  const onSearch = (value) => {
    setLoading(true);
    const creds = {
      type: "employee_pattern_prefix",
      value: value,
    };
    console.log("creds", creds);

    setSettings(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
      // if (res?.status === "success") {
      // }
    });
  };

  const onSearchCha = (value) => {
    setLoading(true);
    const creds = {
      type: "employee_number_length",
      value: value,
    };
    console.log("creds", creds);

    setSettings(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
      // if (res?.status === "success") {
      // }
    });
  };
  const onChangePat = (value) => {
    setEmpPattern(!emp_pattern);
    // console.log(emp_pattern, value);
    setLoading(true);
    const creds = {
      type: "employee_pattern_year",
      value: value,
    };
    setSettings(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
      // if (res?.status === "success") {
      // }
    });
  };

  useEffect(() => {
    if (all_settings?.other_settings?.employee_pattern_prefix) {
      setEmpPreFix(all_settings?.other_settings?.employee_pattern_prefix.value);
    }
    if (all_settings?.other_settings?.employee_number_length) {
      setEmpCharacter(
        all_settings?.other_settings?.employee_number_length.value
      );
    }
    if (all_settings?.other_settings?.employee_pattern_year) {
      setEmpPattern(all_settings?.other_settings?.employee_pattern_year.value);
    }
  }, [all_settings]);

  console.log(
    "emp_pattern",
    all_settings?.other_settings?.employee_pattern_year.value
  );

  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>General</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div className="category">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Employee Number</h4>
              {loading ? <LoadingOutlined /> : null}
            </div>
            <small>Pattern to generate new employee Identifier</small>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <label htmlFor="">Fixed Character</label>
              <input
                placeholder=""
                onBlur={(e) => onSearch(e.target.value)}
                className="w-50"
                value={emp_prefix}
                onChange={(e) => setEmpPreFix(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between my-3">
              <label htmlFor="">Year (Optional)</label>
              <div className="d-flex justify-content-end">
                <Switch
                  value={emp_pattern}
                  onChange={(check, e) => onChangePat(check, e)}
                  className="w-50"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <label htmlFor="">Auto-generated Character</label>
              <input
                placeholder=""
                type="number"
                step={1}
                // enterButton="Submit"
                // size="small"
                onBlur={(e) => onSearchCha(e.target.value)}
                className="w-50"
                value={emp_character}
                onChange={(e) => setEmpCharacter(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="category">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <div>
              <h4>System Input Options</h4>
              <small>
                Add input options for various categories in the application
              </small>
            </div>
          </div>
          <div className="col-md-5 d-flex align-self-center justify-content-end">
            <Button type="text" className="w-50" onClick={handleModal}>
              Show All Options
            </Button>
            {/* <button className="option-btn">Add new option</button> */}
          </div>
        </div>
      </div>
      {/* <DisplayDashboard /> */}
      <Modal
        open={showModal}
        footer={null}
        centered
        onCancel={handleModal}
        // width={700}
      >
        <div className="row">
          <div className={` col-lg-12`}>
            <h3> System Input Options</h3>
            <div>
              {showCatpage ? (
                <InputCategories
                  handleCatpage={handleCatpage}
                  selected={selected}
                  refetch={refetch}
                />
              ) : (
                <ParentList handleCatSelected={handleCatSelected} />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default General;
