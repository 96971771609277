import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useUserCleanUp } from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { requestidcardAction } from "../../../redux/users/userActions";
import NoContent from "../../ui/NoContent";
import { useOutletContext } from "react-router-dom";

function MyIDCard() {
  useUserCleanUp();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [profile] = useOutletContext();
  // console.log({ profile });

  function confirmRequest(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Request ID Card",
      content: "Do you want to perform this action?",
      onOk: requestIDCard,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function requestIDCard() {
    setLoading(true);
    const creds = { user_id: profile.id };
    requestidcardAction(dispatch, creds).then(() => {
      setLoading(false);
    });
  }

  // console.log({ profile });
  return (
    <main className="leaveMain">
      <section className="body mx-auto">
        {/* <div className="leaveContainer mx-auto shadow pt-4">
          <h2 className="newLeaveHead mb-5">New Request</h2>
          <section className="input-forms">
            <form
              className="performance-form leaveForm"
              onSubmit={confirmRequest}
            >
              <div className="Bemployee mb-4">
                <label htmlFor="bEmploy" id="label__leave">
                  Employee name
                </label>
                <input
                  name="bEmploy"
                  type="text"
                  className="input_leave"
                  value={profile?.personal?.fullname}
                  disabled
                />
              </div>
              <div className="leaveImage mb-4">
                <span className="leaveImageText">
                  {profile?.personal?.department?.name ||
                    "Employee's department"}
                </span>
                <span className="avatar">
                  <img src={profile?.personal?.avatar} />
                </span>
              </div>
              <div className="mb-4">
                <label htmlFor="eId" className="label__leave" id="label__leave">
                  Employee ID
                </label>
                <input
                  name="eId"
                  type="text"
                  className="input_leave"
                  value={profile?.personal?.employee_id}
                  disabled
                />
              </div>
              <div className="mb-4">
                <label htmlFor="eName" className="" id="label__leave">
                  Designation
                </label>
                <input
                  name="eName"
                  type="text"
                  className="input_leave"
                  value={profile?.job_role?.job_role?.role || "N/A"}
                  disabled
                />
              </div>
              <div className="mb-4">
                <label htmlFor="eName" className="" id="label__leave">
                  Expiry Date
                </label>
                <input name="eName" type="date" className="input_leave" />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="signature"
                  className="label__leave"
                  id="label__leave"
                >
                  Signature
                </label>

                <img
                  src={profile?.personal?.signature || "/assets/20943752.jpg"}
                  width="150"
                  height="150"
                  alt=""
                />
              </div>

              <div className="d-flex justify-content-end mb-5">
                <Button
                  className="myBtn mb-4"
                  htmlType="submit"
                  loading={loading}
                >
                  Request Leave
                </Button>
              </div>
            </form>
          </section>
        </div> */}
        {profile?.personal?.has_id_card ? (
          <NoContent
            illustration={"/assets/okay.png"}
            width={"250px"}
            message={"ID card already issued"}
          />
        ) : (
          <NoContent width={"250px"} illustration={"/assets/id-card.png"} />
        )}
        {!profile?.personal?.has_id_card ? (
          <div className="d-flex justify-content-center my-5">
            <Button
              className="myBtn mb-4"
              htmlType="submit"
              loading={loading}
              onClick={confirmRequest}
            >
              Request ID Card
            </Button>
          </div>
        ) : <></>}
      </section>
    </main>
  );
}

export default MyIDCard;
