import { NavLink, Link } from "react-router-dom";
import styles from "../../../styles/employee/Profile.module.css";
import { GrLocationPin } from "react-icons/gr";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";

const active = styles.active;

function Sidebar() {
  return (
    <div className={styles.sidebar}>
      <div
        className="d-flex "
        style={{
          paddingLeft: "2rem",
        }}
      >
        <Link
          to={"/dashboard"}
          className="d-flex align-items-center gap-2 back  sidebar_text"
        >
          <FaHome title="Return Home" />
          <span className="sidebar_text">Return Home</span>
        </Link>
      </div>
      <div className="greeting">
        <h4 className="mb-0">Menu</h4>
      </div>
      <ul className={styles.nav__links}>
        <li>
          <NavLink
            to={""}
            end
            className={({ isActive }) => (isActive ? active : "")}
          >
            Edit Profile
            <GrLocationPin />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"medical"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Medical
            <GrLocationPin />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"financial"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Financial
            <GrLocationPin />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"next-of-kin"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Next Of Kin
            <GrLocationPin />
          </NavLink>
        </li>

        <li>
          <NavLink
            to={"dependants"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Dependants
            <GrLocationPin />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"educational-history"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Educational History
            <GrLocationPin />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"employment-history"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Employment History
            <GrLocationPin />
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={"references"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            References
            <GrLocationPin />
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to={"documents"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            My Documents
            <GrLocationPin />
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={"support"}
            className={({ isActive }) => (isActive ? active : "")}
          >
            Support
            <GrLocationPin />
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

export default Sidebar;
