import EmployeeTable from "./EmployeeTable";

export default function AllEmployees() {
  return (
    <div className="app__section">
      <div className="row my-4">
        <div className="col-md-12">
          <div className="card b0 fullHeight">
            <div className="card-body">
              <EmployeeTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
