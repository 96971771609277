import { useState, useEffect } from "react";
import { Select, Table, Space, Input, Button, Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useGetAppraisalsApproved,
  useGetAppraisalTracking,
} from "../../../../util/usershookActions";
import NoContent from "../../../ui/NoContent";
import SkeletonUI from "../../../ui/Skeleton";
import { AiOutlineSearch } from "react-icons/ai";

const TrackAppraisal = () => {
  const [enabled, setEnabled] = useState(true);
  const [trackEnabled, setTrackEnabled] = useState(false);
  const [appraisalId, setAppraisalId] = useState();
  const [tableData, setTableData] = useState([]);

  const { data, isLoading } = useGetAppraisalsApproved(enabled, setEnabled);

  const { data: trackedData, isLoading: trackedIsLoading } =
    useGetAppraisalTracking(trackEnabled, setTrackEnabled, appraisalId);

  useEffect(() => {
    if (trackedData) {
      const new_array = trackedData?.payload?.map((tracked, index) => {
        return {
          key: tracked.id,
          employee: tracked.employee,
          department: tracked.department,
          ...tracked.status,
          tracked,
        };
      });
      setTableData(new_array);
    }
  }, [trackedData]);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch size={20} />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  let columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      ...getColumnSearchProps("employee"),
    },
    {
      title: "Department",
      dataIndex: "department",
      ...getColumnSearchProps("department"),
    },
    ...Object.keys(trackedData?.payload[0]?.status || {}).map((key) => {
      return {
        title: key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        dataIndex: key,
        render: (value) => {
          if (value === "pending") {
            return <Tag color="orange">Pending</Tag>;
          } else {
            return <Tag color="success">Appraised</Tag>;
          }
        },
      };
    }),
  ];

  useEffect(() => {
    if (appraisalId) {
      setTrackEnabled(true);
    }
  }, [appraisalId]);

  const onChange = (value) => {
    setAppraisalId(value);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  console.log("tableData", tableData);

  return (
    <div className="container-fluid">
      <div
        className="app__section py-4 mt-3"
        style={{
          minHeight: "90vh",
          width: "100%",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Track Appraisal</h3>
            <p>
              Track any employee's appraisal to see details of the appraisal
            </p>
          </div>

          <div className="d-flex gap-2 justify-content-end align-items-center">
            <Select
              showSearch
              size="large"
              placeholder="Select an appraisal"
              optionFilterProp="children"
              value={appraisalId}
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{
                width: 250,
              }}
              options={data?.payload?.map((appraisal) => ({
                label: appraisal.appraisal_name,
                value: appraisal.id,
              }))}
            />
            {isLoading ? <LoadingOutlined /> : null}
          </div>
        </div>
        <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
          {trackedIsLoading ? (
            <SkeletonUI />
          ) : tableData?.length > 0 && appraisalId ? (
            <Table
              columns={columns}
              dataSource={tableData}
              bordered
              scroll={{ x: 800 }}
              pagination={false}
              className="w-100"
            />
          ) : !tableData?.length && !trackedData?.payload ? (
            <NoContent
              message={"Select an appraisal"}
              width={"400px"}
              illustration={"/assets/selected.png"}
            />
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center gap-2"
              style={{
                height: "70vh",
              }}
            >
              <img
                src="/assets/reqmanagement.png"
                alt="reqmanagement"
                width="200"
              />
              <h4 className="h5">Track Appraisal</h4>
              <p>
                Track any employee's appraisal to see details of the appraisal
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackAppraisal;
