/* eslint-disable array-callback-return */
import React from 'react'
import UploadPage from './UploadPage';
import { Tabs } from 'antd';

const AllUploads = ({data}) => {
  return (
    <main className="setup-main">
        <div className="container-fluid">
          <div className="row">
            {/* <ul className="onboarding-setup">
              {data.length
                ? data.map((item, ind) => {
                    if (ind === 0 && item.deleted_at === null) {
                      return (
                        <li key={item.id}>
                          <NavLink to="" end>
                            {item.name}
                          </NavLink>
                        </li>
                      );
                    } else if (item.deleted_at === null) {
                      return (
                        <li key={item.id}>
                          <NavLink to={item.slug}>{item.name}</NavLink>
                        </li>
                      );
                    }
                  })
                : null}
            </ul> */}
          <div className="col-md-12">
          <Tabs
            tabPosition="top"
            items={data.map((item) => {
              return {
                label: item.name,
                key: item.id,
                children: (
                    <UploadPage item={item} />
                ),
              };
            })}
          />
            {/* <Routes>
              {data.length
                ? data.map((item, index) => {
                    if (index === 0 && item.deleted_at === null) {
                      return (
                        <Route index element={<UploadPage item={item} />} />
                      );
                    } else if (item.deleted_at === null) {
                      return (
                        <Route
                          path={item.slug}
                          element={<UploadPage item={item} />}
                        />
                      );
                    }
                  })
                : null}
            </Routes> */}
          </div>
          </div>
        </div>
      </main>
  )
}

export default AllUploads