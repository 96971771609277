import { useEffect, useState } from "react";
import { Table } from "antd";
import { convertToCapitalize } from "../../../../../util/helpers";
import { useOutletContext } from "react-router-dom";

function PersonalHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: `${em.first_name} ${em.last_name}`,
          gender: convertToCapitalize(em.gender),
          nationality: convertToCapitalize(em.nationality),
          dob: em.date_of_birth,
          department: em.department,
          designation: em.designation,
          employment: em.employment_type,
          religion: em.religion,
          marital: em.marital_status,
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      key: "gender",
      width: 100,
    },
    {
      title: "NATIONALITY",
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "DEPARTMENT",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "DESIGNATION",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "EMPLOYMENT",
      dataIndex: "employment",
      key: "employment",
    },
    {
      title: "RELIGION",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "MARITAL STATUS",
      dataIndex: "marital",
      key: "marital",
    },
  ];
  return (
    <Table columns={columns} dataSource={emp_history} scroll={{ x: 1440 }} />
  );
}

export default PersonalHistory;
