import { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  useGetEmployeeRequestHistory,
  useGetUserRequestTemplatesBasedOnHistory,
} from "../../../../util/usershookActions";
import dayjs from "dayjs";

import RequestTemplateHistoryTable from "./RequestTemplateHistoryTable";
import SkeletonUI from "../../../ui/Skeleton";
import NoContent from "../../../ui/NoContent";

const EmployeeRequestHistory = () => {
  const [enabled, setEnabled] = useState(true);
  const [singleTemplateEnabled, setSingleTemplateEnabled] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const {
    data: requestTemplates,
    isLoading: isLoadingRequestTemplates,
    refetch: refetchRequestTemplates,
  } = useGetUserRequestTemplatesBasedOnHistory(enabled, setEnabled);

  const {
    data: employeeRequestHistory,
    refetch: refetchEmployeeRequestHistory,
    isLoading: isLoadingEmployeeRequestHistory,
  } = useGetEmployeeRequestHistory(
    singleTemplateEnabled,
    setSingleTemplateEnabled,
    selectedTemplateId,
    from,
    to
  );

  const onChange = (value) => {
    setSelectedTemplateId(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (selectedTemplateId) {
      setSingleTemplateEnabled(true);
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (from && to) {
      refetchEmployeeRequestHistory();
    }
  }, [from, refetchEmployeeRequestHistory, to]);

  useEffect(() => {
    setFrom("");
    setTo("");
  }, [selectedTemplateId]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Request History</h3>
            <p>View History of any preferred Request</p>
          </div>

          <div className="d-flex gap-2 justify-content-end align-items-center">
            <Select
              showSearch
              size="large"
              placeholder="Select a template"
              optionFilterProp="children"
              value={selectedTemplateId}
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{
                width: 250,
              }}
              options={requestTemplates?.payload?.map((template) => ({
                label: template.template_name,
                value: template.id,
              }))}
            />
            {isLoadingRequestTemplates ? <LoadingOutlined /> : null}
          </div>
        </div>

        {selectedTemplateId && (
          <div className="d-flex gap-5 mt-5">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">From</label>
              <DatePicker
                size="large"
                onChange={(date, dateString) =>
                  setFrom(date?.format("YYYY-MM-DD"))
                }
                value={from ? dayjs(from, "YYYY-MM-DD") : null}
                allowClear
              />
            </div>

            <div className="d-flex flex-column gap-2">
              <label className="fs-6 fw-bold">To</label>{" "}
              <DatePicker
                size="large"
                onChange={(date, dateString) =>
                  setTo(date?.format("YYYY-MM-DD"))
                }
                value={to ? dayjs(to, "YYYY-MM-DD") : null}
                allowClear
              />
            </div>
          </div>
        )}

        <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
          {isLoadingEmployeeRequestHistory ? (
            <SkeletonUI number={5} />
          ) : employeeRequestHistory && employeeRequestHistory.payload ? (
            <div className="d-flex flex-column gap-3">
              <h5 className="mt-3">
                {employeeRequestHistory?.payload?.template_name}
              </h5>

              <RequestTemplateHistoryTable
                employeeRequestHistory={employeeRequestHistory}
              />
            </div>
          ) : employeeRequestHistory && !employeeRequestHistory.payload ? (
            <NoContent
              width={"400px"}
              message={"No Request History Found"}
              illustration={"/assets/selected.png"}
            />
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center gap-2"
              style={{
                height: "70vh",
              }}
            >
              <img
                src="/assets/reqmanagement.png"
                alt="reqmanagement"
                width="200"
              />
              <h4 className="h5">Welcome To Request History</h4>
              <p>
                You can see all available request History and select any of your
                choice
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeRequestHistory;
