import { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";
import countries from "../../../../util/countries.json";

import {
  user_errors,
  settings_util,
} from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserPersonalInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import datas from "../../../../util/nigeria-state-and-lgas.json";
import { useOutletContext } from "react-router-dom";
import { useGetSystemSettingsUtils } from "../../../../util/usershookActions";

export default function ApprovePersonal() {
  let init_details;
  useUserCleanUp();
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const utils = useShallowEqualSelector(settings_util);
  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);

  const [selectedState, setState] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);

  const [details, setEnabled] = useOutletContext();

  const [enabled_uti, setEnabledUtil] = useState(true);
  useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserPersonalInfo(
        dispatch,
        { ...values, notify: false },
        exempt
      ).then((res) => {
        setLoadingAppN(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  }

  function handleApproveNotify() {
    setLoadingApp(true);
    approveUserPersonalInfo(dispatch, values, exempt).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  }

  const callbackFn = () => {
    if (details[0]) {
      approveandnotify();
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  if (details) {
    init_details = { ...details[0], except: [], notify: true };
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  // console.log("det", values);

  function handleFirstChange(e) {
    setState(e.target.value);
    handleChange(e);
  }
  let lgasInState;

  if (selectedState) {
    const lgaToFind = datas.filter((state) => state.alias === selectedState);

    lgasInState = lgaToFind[0].lgas;
  }
  const modalHandler = () => {
    setShowModal(!showModal);
  };
  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };

  const confirm = (id) => {
    setLoading(true);
    rejectUserInfo(dispatch, { ...values, info_type: "personal" }).then(
      (res) => {
        setLoading(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
          setShowModal(false);
        }
      }
    );
  };

  useEffect(() => {
    setState(values.state_of_origin);
  }, [values.state_of_origin]);

  console.log("values", values);

  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    // alert("here");
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <DashboardCard
      title={"Personal Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="title">Title</label>
            <small className="text-danger">
              {errors.title || (back_errors.title && back_errors.title[0])}
            </small>
            <select
              class="form-control form-control-sm "
              id="title"
              name="title"
              value={values?.title}
              onChange={handleChange}
            >
              <option value="">--</option>
              {Object.keys(utils).length && utils?.system_inputs["Title"].length
                ? utils?.system_inputs["Title"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="title"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="last_name">Last Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.last_name ||
                  (back_errors.last_name && back_errors.last_name[0])}
              </small>
            </div>
            <input
              type="text"
              name="last_name"
              id="last_name"
              onChange={handleChange}
              value={values.last_name}
            />

            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="last_name"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="first_name">First Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.first_name ||
                  (back_errors.first_name && back_errors.first_name[0])}
              </small>
            </div>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              value={values.first_name}
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="first_name"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="employee_id">Employee Number</label>
            <small className="text-danger">
              {errors.employee_id ||
                (back_errors.employee_id && back_errors.employee_id[0])}
            </small>
            <input
              type="text"
              name="employee_id"
              id="employee_id"
              onChange={handleChange}
              value={values.employee_id}
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="employee_id"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="employee_type">Employment Type</label>
            <small className="text-danger">
              {errors.employee_type ||
                (back_errors.employee_type && back_errors.employee_type[0])}
            </small>
            <select
              name="employment_type"
              id="employment_type"
              onChange={handleChange}
              value={values.employment_type}
            >
              <option value="">--</option>
              {Object.keys(utils).length &&
              utils?.system_inputs["Employment Types"].length
                ? utils.system_inputs["Employment Types"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="employment_type"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="employee_date">Employed On</label>
            <small className="text-danger">
              {errors.employee_type ||
                (back_errors.employee_type && back_errors.employee_type[0])}
            </small>
            <input
              type="date"
              name="employment_date"
              id="employment_date"
              onChange={handleChange}
              value={values.employment_date}
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="employment_date"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="other_names">Other Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.other_names ||
                  (back_errors.other_names && back_errors.other_names[0])}
              </small>
            </div>
            <input
              type="text"
              name="other_names"
              id="other_names"
              onChange={handleChange}
              value={values.other_names}
            />
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="other_names"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="marital_status">Marital status</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.religion ||
                  (back_errors.religion && back_errors.religion[0])}
              </small>
            </div>
            <select
              name="marital_status"
              id="marital_status"
              onChange={handleChange}
              value={values.marital_status}
            >
              <option value="">--</option>
              {Object.keys(utils).length &&
              utils?.system_inputs["Marital Status"].length
                ? utils.system_inputs["Marital Status"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>

            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="marital_status"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="nationality">Nationality</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.nationality ||
                  (back_errors.nationality && back_errors.nationality[0])}
              </small>
            </div>
            <select
              name="nationality"
              id="nationality"
              value={values.nationality}
              onChange={handleChange}
            >
              <option value="">--</option>
              {countries
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="nationality"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="state_of_origin">State of Origin</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.state_of_origin ||
                  (back_errors.state_of_origin &&
                    back_errors.state_of_origin[0])}
              </small>
            </div>
            <select
              name="state_of_origin"
              onChange={handleFirstChange}
              value={values.state_of_origin}
              id="state_of_origin"
            >
              {datas.map((state, index) => (
                <option key={index} value={state.alias}>
                  {state.state.toUpperCase()}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="state_of_origin"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="province_lga">Province/LGA</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.province_lga ||
                  (back_errors.province_lga && back_errors.province_lga[0])}
              </small>
            </div>

            <select
              name="province_lga"
              id="province_lga"
              value={values.province_lga?.toUpperCase()}
              onChange={handleChange}
            >
              {lgasInState ? (
                <>
                  <option value="--">--</option>
                  {lgasInState.map((lga, index) => (
                    <option key={index} value={lga?.toUpperCase()}>
                      {lga.toUpperCase()}
                    </option>
                  ))}
                </>
              ) : (
                <option value="--">--</option>
              )}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="province_lga"
              onChange={handleCheckBox}
            />
          </div>

          <div className="col-md-4 approval_side_checkbox d-flex flex-column">
            <label htmlFor="religion">Religion</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.religion ||
                  (back_errors.religion && back_errors.religion[0])}
              </small>
            </div>
            <select
              name="religion"
              id="religion"
              onChange={handleChange}
              value={values.religion}
            >
              <option value="">--</option>
              {Object.keys(utils).length &&
              utils?.system_inputs["Religion"].length
                ? utils.system_inputs["Religion"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <input
              type="checkbox"
              defaultChecked
              className="side_check"
              title="Uncheck to exempt this record from being approved"
              value="religion"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn"
            loading={loadingApp}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>

          <Button
            size="large"
            className="myBtn "
            loading={loadingAppN}
            onClick={approve}
            disabled={disabled}
          >
            Approve
          </Button>

          <Button
            size="large"
            className="myBtn "
            style={{ backgroundColor: "red", color: "white" }}
            onClick={warning}
            loading={loading}
          >
            Reject
          </Button>
        </div>

        {/* <Modal
          title={`Reject request`}
          centered
          onOk={() => confirm(values?.id)}
          onCancel={modalHandler}
          open={showModal}
          cancelText="No"
          okText="Yes"
        >
          <p className="h6">Are you sure to reject this request?</p>
        </Modal> */}
      </form>
    </DashboardCard>
  );
}
