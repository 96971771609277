import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";


function DependantHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();


  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: em.name,
          genotype: em.genotype || "--",
          blood_group: em.blood_group || "--",
          telephone: em.telephone || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "DEPENDANT NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "GENOTYPE",
      dataIndex: "genotype",
      key: "genotype",
    },
    {
      title: "BLOOD GROUP",
      dataIndex: "blood_group",
      key: "blood_group",
    },
    {
      title: "TELEPHONE NUMBER",
      dataIndex: "telephone",
      key: "telephone",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} />;
}

export default DependantHistory;
