import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteTemple } from "../../../redux/users/userActions";
import InductionActivity from "./InductionActivity";
import Activity from "./Activity";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { MdPlaylistAdd } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";

const EachTemplate = ({ template, modalHandler, refetchTem }) => {
  const [loading_tem, setLoadingTem] = useState(false);
  const [promptdel, setPromptDel] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const dispatch = useDispatch();

  const deleteTemplate = (e, template) => {
    Modal.destroyAll();

    const creds = {
      type: "induction",
      id: template.id,
    };
    setLoadingTem(true);
    deleteTemple(dispatch, creds).then((res) => {
      setLoadingTem(false);

      if (res?.status === "success") {
        // SetTemToSubmit("");
        refetchTem();
      }
    });
  };

  function deleteTemplatePrompt(e, template) {
    // alert("here");
    return Modal.confirm({
      title: "Delete Template",
      content: "Do you want to delete this template?",
      onOk: (e) => deleteTemplate(e, template),
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => setPromptDel(false),
      open: promptdel,
      closable: true,
    });
  }

  const editHandler = () => {
    setShowEditModal(!showEditModal);
    // setSelTemplate(temp);
  };
  return (
    <div key={template.id} className="col-md-4 mb-5">
      <div className="template">
        <h4 className="d-flex align-items-center gap-1 mb-0">
          <span>
            <HiTemplate className="h3 mb-0" />
          </span>
          {template?.name}
        </h4>
        <hr className="my-0" />
        <ul className="row">
          {template?.activities.length ? (
            template?.activities.map((activity) => (
              <Activity
                activity={activity}
                template={template}
                key={activity.id}
                refetchTem={refetchTem}
              />
            ))
          ) : (
            <p className="text-center h6 my-3">
              No Activity has been added to this template
            </p>
          )}
        </ul>

        <div className="d-flex justify-content-end gap-2">
          <Button
            type="link"
            size="small"
            onClick={(e) => modalHandler(e, template)}
            title="Edit Template"
          >
            <AiFillEdit style={{ fontSize: 24, color: "var(--clr-primary)" }} />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={editHandler}
            title="Add Activity"
          >
            <MdPlaylistAdd
              style={{ fontSize: 24, color: "var(--clr-primary)" }}
            />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={(e) => deleteTemplatePrompt(e, template)}
            loading={loading_tem}
            title="Delete Template"
          >
            <AiFillDelete style={{ fontSize: 20, color: "var(--clr-red)" }} />
          </Button>
        </div>
      </div>
      <Modal open={showEditModal} onCancel={editHandler} footer={null} centered>
        <h3>Add Activity to {template.name}</h3>
        <InductionActivity
          template={template}
          refetchTem={refetchTem}
          activity={null}
          closeModal={setShowEditModal}
        />
      </Modal>
    </div>
  );
};

export default EachTemplate;
