import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import styles from "../../../styles/SetPassword.module.css";
import { Alert } from "antd";

import { user_errors, settings } from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import { Button, Modal, Switch } from "antd";
import {
  useGetSystemSettings,
  useGetPasswordRequirements,
} from "../../../util/usershookActions";
import { updateClientPassDetails } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

function PasswordSettings() {
  const dispatch = useDispatch();
  const errors = useShallowEqualSelector(user_errors);
  const [password_meg, setPassMeg] = useState(null);
  const [enabled_settings, setEnabledSettings] = useState(true);
  const [enabled_req, setEnabledReq] = useState(true);
  const { refetch } = useGetSystemSettings(
    enabled_settings,
    setEnabledSettings
  );

  const { data: req_data, isLoading: isLoading_req } =
    useGetPasswordRequirements(enabled_req, setEnabledReq);
  const all_settings = useShallowEqualSelector(settings);
  const initPasswordSettings = {
    minimum_length:
      all_settings?.password_settings?.password_minimum_length?.value || "",
    lock_after_retry:
      all_settings?.password_settings?.lock_after_retry?.value || "",
    password_history:
      all_settings?.password_settings?.password_history?.value || "",
    password_strength:
      all_settings?.password_settings?.password_strength?.value || "",
    allow_self_recovery:
      all_settings?.password_settings?.allow_self_recovery?.value || "",
    allow_self_unlock:
      all_settings?.password_settings?.allow_self_lock?.value || "",
  };

  const [values, setValues] = useState(initPasswordSettings);
  const [showModal, setShowModal] = useState(false);
  const [showModalView, setShowModalView] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const selfRecoveryHandler = (checked) => {
    setValues((prevState) => {
      return {
        ...prevState,
        allow_self_recovery: checked === true ? "yes" : "no",
      };
    });
  };

  const selfUnlockHandler = (checked) => {
    setValues((prevState) => {
      return {
        ...prevState,
        allow_self_unlock: checked === true ? "yes" : "no",
      };
    });
  };

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };
  const handleModalView = (e) => {
    e.preventDefault();
    setShowModalView(!showModalView);
  };

  const handleupdate = () => {
    setLoading(true);
    console.log({ values });
    updateClientPassDetails(dispatch, values).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        // setEnabledSettings(true);

        refetch();
      }
    });
  };

  useEffect(() => {
    setValues({
      minimum_length:
        all_settings?.password_settings?.password_minimum_length?.value || "",
      lock_after_retry:
        all_settings?.password_settings?.lock_after_retry?.value || "",
      password_history:
        all_settings?.password_settings?.password_history?.value || "",
      password_strength:
        all_settings?.password_settings?.password_strength?.value || "",
      allow_self_recovery:
        all_settings?.password_settings?.allow_self_recovery?.value || "",
      allow_self_unlock:
        all_settings?.password_settings?.allow_self_lock?.value || "",
    });
  }, [all_settings]);

  useEffect(() => {
    if (req_data && req_data?.status === "success") {
      console.log({ req_data });
      setPassMeg(req_data.message);
    }
  }, [req_data]);
  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Passwords</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <small>View and edit your company password rules </small>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="d-flex gap-1">
                  <Button
                    onClick={handleModalView}
                    // loading={loadingre}
                    type="text"
                  >
                    View
                  </Button>
                  <Button type="text" onClick={handleModal}>
                    Edit
                  </Button>
                </div>
              </div>

              <Modal
                open={showModal}
                footer={null}
                centered
                onCancel={handleModal}
                maskClosable={false}
                width={700}
              >
                <div className="row">
                  <div className={`${styles.content} col-lg-12`}>
                    <h3>Password Settings</h3>

                    <Alert message={password_meg || ""} type="info" />

                    <form className={styles.form}>
                      <div className={styles.inputGroup}>
                        <label htmlFor="minimum_length">Minimum Length</label>
                        <small className="text-danger">
                          {errors.minimum_length}
                        </small>
                        <select
                          name="minimum_length"
                          value={values.minimum_length}
                          onChange={handleChange}
                        >
                          <option value="">Select...</option>
                          <option value="4">4 Characters</option>
                          <option value="5">5 Characters</option>
                          <option value="6">6 Characters</option>
                          <option value="7">7 Characters</option>
                          <option value="8">8 Characters</option>
                          <option value="9">9 Characters</option>
                          <option value="10">10 Characters</option>
                        </select>
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="lock_after_retry">
                          Lock After Retry
                        </label>
                        <small className="text-danger">
                          {errors.lock_after_retry}
                        </small>
                        <select
                          name="lock_after_retry"
                          value={values.lock_after_retry}
                          onChange={handleChange}
                        >
                          <option value="">Select...</option>
                          <option value="0">Never</option>
                          <option value="2">2 retries</option>
                          <option value="3">3 retries</option>
                          <option value="4">4 retries</option>
                          <option value="5">5 retries</option>
                          <option value="6">6 retries</option>
                        </select>
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="password_history">
                          Password History
                        </label>
                        <small className="text-danger">
                          {errors.password_history}
                        </small>
                        <select
                          name="password_history"
                          value={values.password_history}
                          onChange={handleChange}
                        >
                          <option value="">Select...</option>
                          <option value="1">Activated</option>
                          <option value="0">Deactivated</option>
                        </select>
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="password_strength">
                          Password Strength
                        </label>
                        <small className="text-danger">
                          {errors.password_strength}
                        </small>
                        <select
                          name="password_strength"
                          value={values.password_strength}
                          onChange={handleChange}
                        >
                          <option value="">Select...</option>
                          <option value="weak">Weak</option>
                          <option value="moderate">Moderate</option>
                          <option value="acceptable">Acceptable</option>
                          <option value="strong">Strong</option>
                          <option value="very strong">Very Strong</option>
                        </select>
                      </div>
                      {/* <div className="d-flex justify-content-between mt-3">
                        <div className={styles.inputToggle}>
                          <label htmlFor="allow_self_recovery">
                            Allow Self Recovery
                          </label>
                          <span>{errors.allow_self_recovery}</span>
                          <Switch
                            onChange={selfRecoveryHandler}
                            name="allow_self_recovery"
                            defaultChecked={
                              values.allow_self_recovery === "yes"
                            }
                          />
                        </div>
                        <div className={styles.inputToggle}>
                          <label htmlFor="allow_self_unlock">
                            Allow Self Unlock
                          </label>
                          <span>{errors.allow_self_unlock}</span>
                          <Switch
                            onChange={selfUnlockHandler}
                            name="allow_self_unlock"
                            defaultChecked={values.allow_self_unlock === "yes"}
                          />
                        </div>
                      </div> */}
                    </form>
                    <div className="d-flex justify-content-end mt-2">
                      <div className="">
                        <Button
                          className="myBtn  mb-2"
                          loading={loading}
                          onClick={handleupdate}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                open={showModalView}
                footer={null}
                centered
                onCancel={handleModalView}
                maskClosable={false}
                width={700}
              >
                <div className="row">
                  <div className={`${styles.content} col-lg-12 mb-2`}>
                    <h3>Password Settings</h3>
                    <Alert message={password_meg || ""} type="info" />
                    <form className={` adjust_style_password`}>
                      <div className={`${styles.inputGroup} mt-2`}>
                        <label htmlFor="minimum_length">Minimum Length</label>
                        {`${values.minimum_length} Characters` || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="lock_after_retry">
                          Lock After Retry
                        </label>
                        {`${values.lock_after_retry} retries` || "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="password_history">
                          Password History
                        </label>
                        {values.password_history
                          ? values.password_history == "0"
                            ? "Deactivated"
                            : "Activated"
                          : "N/A"}
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="password_strength">
                          Password Strength
                        </label>
                        {values.password_strength || "N/A"}
                      </div>
                      {/* <div className={styles.inputGroup}>
                        <label htmlFor="password_history">
                          Allow Self Recovery
                        </label>
                        {values.allow_self_recovery}
                      </div>
                      <div className={styles.inputGroup}>
                        <label htmlFor="password_strength">
                          Allow Self Unlock
                        </label>
                        {values.allow_self_unlock || "N/A"}
                      </div> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordSettings;
