import React, { useState } from "react";
import NoContent from "../../ui/NoContent";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { markAsRead } from "../../../redux/users/userActions";
import { useUserCleanUp, useUsersNotification } from "../../../util/hooks";
import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";

const OnboardingDocuments = ({ hireProfile, admin, refetch }) => {
  useUsersNotification();
  useUserCleanUp();
  return admin ? (
    <div className="row">
      {hireProfile?.payload?.readlist.length ? (
        hireProfile?.payload?.readlist.map((list) => (
          <div className="col-6" key={list.id}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex gap-3 align-items-center">
                  {list?.hire_read_document.length ? (
                    <AiFillFolderOpen
                      style={{ fontSize: "4rem", color: "var(--clr-primary)" }}
                    />
                  ) : (
                    <AiFillFolder
                      style={{ fontSize: "4rem", color: "var(--clr-primary)" }}
                    />
                  )}

                  <div className="d-flex flex-column mt-2">
                    <h4 className="mb-0">{list.name || "Document"}</h4>
                    <p>{list?.setting}</p>
                    <small>
                      {list?.hire_read_document.length ? "Read" : "Unread"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="mb-5">
          <NoContent message={"No documents available"} width={400} />
        </div>
      )}
    </div>
  ) : (
    <>
      <h3 className="mb-5">Onboarding Documents</h3>
      <div className="row">
        {hireProfile?.payload?.readlist.length ? (
          hireProfile?.payload?.readlist.map((list) => (
            <div className="col-3 mb-5">
              <EachModal
                list={list}
                key={list.id}
                hireProfile={hireProfile}
                admin={admin}
                refetch={refetch}
              />
            </div>
          ))
        ) : (
          <div className="mb-5">
            <NoContent message={"No documents available"} width={400} />
          </div>
        )}
      </div>
    </>
  );
};

export default OnboardingDocuments;

function EachModal({ list, hireProfile, admin, refetch }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // console.log(hireProfile?.payload?.readlist);
  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const markDocAsRead = (e, setting_id) => {
    setConfirmLoading(true);
    const creds = {
      pre_employment_hire_id: hireProfile?.payload?.id,
      setting_id,
      action: true,
    };

    markAsRead(dispatch, creds).then((res) => {
      setConfirmLoading(false);
      if (res?.status === "success") {
        dispatch({ type: "CHANGE_TO_READ", payload: creds });
        refetch();
      }
    });

    // console.log(creds);
  };
  return (
    <>
      <div
        key={list.id}
        className="card mb-3"
        onClick={modalHandler}
        style={{ cursor: "pointer" }}
      >
        <div className="card-body">
          <p key={list.id}>{list.name || "Document"}</p>
          <div>
            <small>{list?.setting}</small>{" "}
            <small>{list?.hire_read_document.length ? "Read" : "Unread"}</small>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        onCancel={modalHandler}
        onOk={(e) => markDocAsRead(e, list.id)}
        okText={"Mark as Read"}
        cancelText={"Close"}
        centered
        confirmLoading={confirmLoading}
        width={"60vw"}
      >
        <object
          data={list.value || list.default}
          type="application/pdf"
          width="100%"
          height={500}
        >
          <p>
            Unable to display PDF file.{" "}
            <a href={list.value || list.default} download>
              Download
            </a>{" "}
            instead.
          </p>
        </object>
      </Modal>
    </>
  );
}
