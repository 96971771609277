import React, { useState, useEffect } from "react";
import styles from "../../../../styles/CompanyDetails.module.css";

import { List, Popconfirm, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { convertToCapitalize } from "../../../../util/helpers";
import {
  addToCategory,
  updateCategory,
  deleteFromCategory,
} from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";

function InputCategories({ handleCatpage, selected, refetch }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [value, setValues] = useState("");
  const [save_spinner, setSaveSpinner] = useState(false);
  const [sel, setSel] = useState({});

  // const confirm = (item) => {
  //   console.log(item);
  // };

  useEffect(() => {
    setData([...selected.input]);
  }, [selected]);

  // const handleEdit = (input) => {
  //   setValues(input.name);
  //   setSel(input);
  // };
  const clearEditMode = () => {
    setSel({});
    setValues("");
  };

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSaveSpinner(true);
    const creds = {
      category: selected.title, // remove the toLowercase later
      name: value,
    };

    addToCategory(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        if (Object.keys(sel).length) {
          //edit mode
          const new_arry = [...data].map((item) => {
            if (item.id === sel.id) {
              item.name = value;
            }
            return item;
          });
          setData(new_arry);
        } else {
          // new entry
          // const last_id = [...data].sort((a, b) => b.id - a.id)[0].id;
          setData([
            ...data,
            {
              ...res.payload,
              category: selected.title.toLowerCase(),
            },
          ]);
        }
        setSel({});
        setValues("");
        refetch();
      }
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setSaveSpinner(true);
    const creds = {
      id: sel.id,
      name: value,
    };
    console.log("creds", creds);
    updateCategory(dispatch, creds, sel.id).then((res) => {
      setSaveSpinner(false);
      if (res.status === "success") {
        const new_arry = [...data].map((item) => {
          if (item.id === sel.id) {
            item.name = value;
          }
          return item;
        });
        setData(new_arry);
        setSel({});
        setValues("");
        refetch();
      }
    });
  };

  console.log("sel", sel);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mt-2">{selected?.title} (Category)</h5>
        <Button type="text" onClick={handleCatpage}>
          Back
        </Button>
      </div>
      <form
        className={` ${styles.form}  res_form`}
        onSubmit={Object.keys(sel).length ? handleUpdate : handleSave}
      >
        <div className={styles.inputGroup}>
          <div className={styles.top}>
            {/* <label htmlFor="region">State/Province/Region</label> */}
            {/* <span>{errors.region}</span> */}
          </div>
          {Object.keys(sel).length ? (
            <small className="text-danger mb-2">{`Editing ${sel.name}...`}</small>
          ) : null}

          <Input
            type="text"
            name="region"
            placeholder="Add new entry"
            size="large"
            value={value}
            onChange={handleChange}
            allowClear
          />
        </div>
        <div className=" d-flex justify-content-end gap-3">
          {Object.keys(sel).length ? (
            <Button onClick={clearEditMode} type="text">
              Cancel
            </Button>
          ) : null}

          <Button
            className="myBtn "
            loading={save_spinner}
            disabled={!value.length}
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </form>

      <div
        style={{
          height: 400,
          overflowY: "scroll",
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <ListItem
              item={item}
              index={index}
              setValues={setValues}
              setSel={setSel}
              data={data}
              setData={setData}
              refetch={refetch}
            />
          )}
        />
      </div>
    </>
  );
}

export default InputCategories;

function ListItem({ item, index, setValues, setSel, data, setData, refetch }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleEdit = (input) => {
    setValues(input.name);
    setSel(input);
  };

  const confirm = (item) => {
    setLoading(true);
    deleteFromCategory(dispatch, item).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        const new_arry = [...data].filter((cat) => item.id !== cat.id);
        setData(new_arry);
        refetch();
      }
    });
  };
  return (
    <List.Item
      actions={[
        <Link
          key="list-loadmore-more"
          className="text-info"
          onClick={() => handleEdit(item)}
        >
          Edit
        </Link>,
        <Popconfirm
          title="Delete entries"
          description="Do you what to delete this entry?"
          onConfirm={() => confirm(item)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="list-loadmore-edit"
            className="text-danger"
            loading={loading}
            type="text"
          >
            Delete
          </Button>
        </Popconfirm>,
      ]}
    >
      <List.Item.Meta title={convertToCapitalize(item.name)} />
    </List.Item>
  );
}
