import { Dropdown, Button } from "antd";
import React from "react";

const AntDropdown = ({ items, loading }) => {
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button loading={loading} className="myBtn ">
        Actions
      </Button>
    </Dropdown>
  );
};

export default AntDropdown;
