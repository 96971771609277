import React, { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { rejectUserInfo, deleteNextOfKin } from "../../../redux/users/userActions";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import Avatar from "react-avatar";
import { convertToCapitalize } from "../../../util/helpers";
import {
  MdContacts,
  MdEmail,
  MdOutlineClose,
  MdRemoveRedEye,
} from "react-icons/md";
import { TbEyeOff } from "react-icons/tb";

function NOKCard({ info }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [, setLoading] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const handleMore = () => {
    setShowMore(!showMore);
  };

  console.log("info", info);

  const confirm = () => {
    setLoading(true);
    deleteNextOfKin(dispatch, info).then(
      (res) => {
        setLoading(false);
        setShowModal(false);

        if (res?.status === "success") {
          dispatch({ type: "REMOVE_NOK_INFO", payload: info.id });
        }
      }
    );
  };

  const modalTrigger = (name) => {
    Modal.confirm({
      title: `Remove ${name}`,
      content: `Are you sure you want to remove ${name} as your next of kin?`,
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  function hideEmail(email) {
    const domain = email.split("@")[1];
    return `******@${domain}`;
  }

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-body d-flex flex-column gap-md-3 nok-card px-4">
          <Avatar
            name={info.name}
            round
            color="rgba(245, 245, 245, 1)
"
            className={styles.cardAvatar}
          />
          <div className={styles.cardDetails}>
            <h5 className="my-3">{info.name}</h5>

            <div className="row">
              <div className="col-12 mt-2">
                <label>Relationship</label>
                <p>
                  {showMore
                    ? convertToCapitalize(info.relationship)
                    : "********"}
                </p>
              </div>
              <div className="col-12 mt-2">
                <label>Home Address</label>
                <p>{showMore ? info.home_address : "********"}</p>
              </div>
              <div className="col-12 mt-2 d-flex align-items-center gap-2">
                <MdContacts className="h2 mb-0" />
                <div>
                  <label>Contact</label>
                  <p style={{ color: "var(--clr-primary)", fontWeight: 500 }}>
                    {showMore ? info.telephone : "070-354-HELLO"}
                  </p>
                  <p style={{ color: "var(--clr-primary)", fontWeight: 500 }}>
                    {showMore ? info.mobile_number : "070-354-HELLO"}
                  </p>
                </div>
              </div>
              <div className="col-12 mt-2 d-flex align-items-center gap-2">
                <MdEmail className="h2 mb-0" />
                <div>
                  <label>Email</label>
                  <p style={{ color: "var(--clr-primary)", fontWeight: 500 }}>
                    {showMore ? info.email : hideEmail(info.email)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ctaBtns}>
            <button className="myBtn-reveal" onClick={handleMore}>
              {showMore ? <MdRemoveRedEye /> : <TbEyeOff />}
            </button>
            <button
              className="myBtn-close"
              onClick={() => modalTrigger(info.name)}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NOKCard;
