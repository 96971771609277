import { useState } from "react";
import { Table, Popconfirm, Button } from "antd";

import { useGetPerformanceKPITargets } from "../../../../util/usershookActions";
import { approveOrRejectTarget } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";

const AnnualApprovalList = () => {
  const [enabled, setEnabled] = useState(true);

  const {
    data: targetsData,
    isLoading,
    refetch,
  } = useGetPerformanceKPITargets(enabled, setEnabled);

  const groupedData = {};

  // Group the data by department
  targetsData?.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Target (unit)",
      dataIndex: "target",
      key: "target",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Timeline",
      dataIndex: "timeline",
      render: (text, record) => (
        <span>
          {record.start_date} - {record.end_date}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: "right",
      render: (_, record) => <EachAction item={record} refetch={refetch} />,
    },
  ];

  return (
    <div className="monthly__approval__list performance_table">
      {isLoading ? (
          <SkeletonUI />
      ) : (
        <Table
          columns={columns}
          dataSource={transformedData}
          bordered
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      )}
    </div>
  );
};

function EachAction({ item, refetch }) {
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const dispatch = useDispatch();

  const handleApproveTarget = (e) => {
    e.preventDefault();
    setApproveLoading(true);
    approveOrRejectTarget(dispatch, item.id, "approved").then(() => {
      setApproveLoading(false);
      refetch();
    });
  };

  const handleRejectTarget = (e) => {
    e.preventDefault();
    setRejectLoading(true);
    approveOrRejectTarget(dispatch, item.id, "rejected").then(() => {
      setRejectLoading(false);
      refetch();
    });
  };
  return (
    <>
      <div className="filter d-flex gap-2">
        <Popconfirm
          title="Approve Target"
          description="Do you want to perform this action?"
          onConfirm={handleApproveTarget}
          okText="Yes"
          cancelText="No"
          disabled={item.status === "approved" || item.status === "rejected"}
        >
          <Button
            className="d-flex justify-content-center bg-success text-white "
            shape="round"
            type="text"
            size="medium"
            loading={approveLoading}
            disabled={item.status === "approved" || item.status === "rejected"}
          >
            Approve
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Reject Target"
          description="Do you want to perform this action?"
          onConfirm={handleRejectTarget}
          okText="Yes"
          cancelText="No"
          disabled={item.status === "rejected" || item.status === "approved"}
        >
          <Button
            className="d-flex justify-content-center bg-danger text-white "
            shape="round"
            type="text"
            size="medium"
            loading={rejectLoading}
            disabled={item.status === "rejected" || item.status === "approved"}
          >
            Reject
          </Button>
        </Popconfirm>
      </div>
    </>
  );
}
export default AnnualApprovalList;
