import React, { useEffect, useState } from "react";
import { Button, Space, Table, Input, Modal, Card } from "antd";
import { Link } from "react-router-dom";
import {
  useGetAvailableJobGrade,
  useGetAllGradedGrades,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { avail_job_grade } from "../../../util/selectors/userSelectors";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import { AiOutlineSearch } from "react-icons/ai";
import { formatMoney } from "../../../util/helpers";
import { useDispatch } from "react-redux";
import { gradeRole, userCleanUp } from "../../../redux/users/userActions";

function AvailableJobs() {
  useUserCleanUp();
  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  function getColumnSearchPropsNumbers(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        console.log(
          record[dataIndex].toString().replaceAll(",", "").toLowerCase()
        );
        return record[dataIndex]
          .toString()
          .replaceAll(",", "")
          .toLowerCase()
          .includes(value.toLowerCase().replaceAll(",", ""));
      },
    };
  }

  const [enabled, setEnabled] = useState(true);
  const [enabled_grd, setEnabled_grd] = useState(true);
  const [search] = useState("");
  const [group_by] = useState("");
  const [job_show, setJobShow] = useState([]);
  const [graded_data, setGradedData] = useState([]);
  const { isLoading, refetch } = useGetAvailableJobGrade(
    enabled,
    setEnabled,
    search,
    group_by
  );

  const {
    isLoading: isLoading_grd,
    refetch: refetch_grd,
    data: data_grd,
  } = useGetAllGradedGrades(enabled_grd, setEnabled_grd);

  const avail_jobs = useShallowEqualSelector(avail_job_grade);

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Job Title",
      dataIndex: "role",
      key: "role",
      render: (_, { role, key }) => (
        <>
          <Link to={`view/${key}`} style={{ fontWeight: "bold" }}>
            {role}
          </Link>
        </>
      ),
      ...getColumnSearchProps("role", "Job Title"),
    },
    {
      title: "Gross Salary Range",
      dataIndex: "sal_range",
      key: "sal_range",
      ...getColumnSearchPropsNumbers("sal_range", "Gross Salary Range"),
    },
    {
      title: "Grades",
      dataIndex: "grade",
      key: "grade",
      render: (_, { grade }) => (
        <>
          <ul>{grade.length ? grade.length : "N/A"}</ul>
        </>
      ),
    },
    {
      title: "Notch",
      dataIndex: "step",
      key: "step",
      render: (_, { step }) => (
        <>
          <ul>{step.length ? step.length : "N/A"}</ul>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Link to={`view/${record.key}`}>
            <Button type="primary">View</Button>
          </Link>
          <Link to={`edit/${record.key}`}>
            <Button type="default">Edit</Button>
          </Link>

          <EachAction
            record={record}
            refetch={refetch}
            graded_data={graded_data}
            isLoading_grd={isLoading_grd}
            refetch_grd={refetch_grd}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (avail_jobs?.length) {
      const mod_data = avail_jobs.map((item, ind) => {
        return {
          item,
          key: item.id,
          index: ind + 1,
          role: item.role,
          sal_range: `${
            item?.minimum_salary >= 0 ? item?.currency?.symbol : ""
          }  ${formatMoney(item?.minimum_salary) || "N/A"} - ${
            item?.maximum_salary >= 0 ? item?.currency?.symbol : ""
          }  ${formatMoney(item?.maximum_salary) || "N/A"} `,
          grade: item?.job_role_levels.length
            ? item.job_role_levels.filter((level) => {
                return level?.grade_id;
              })
            : [],
          step: item?.job_role_levels.length
            ? item.job_role_levels.filter((level) => {
                return level?.step_id;
              })
            : [],
        };
      });
      setJobShow(mod_data);
    }
  }, [avail_jobs]);

  useEffect(() => {
    if (data_grd && Object.keys(data_grd)?.length) {
      const modData = data_grd?.payload.map((item, index) => ({
        ...item,
        key: index + 1,
        benefit_count: item?.benefit?.length,
      }));
      setGradedData(modData);
    }
  }, [data_grd]);

  return (
    <>
      <section className="app__section py-4">
        <div
          className="row my-4"
          style={{
            padding: "1rem",
          }}
        >
          <div className="d-flex gap-3 align-items-center">
            <div className="circle__bagde fw-bold">{job_show.length}</div>
            <h6 className="mb-0"> Jobs</h6>
          </div>
        </div>

        <div
          className="row"
          style={{
            padding: "1rem",
          }}
        >
          {isLoading ? (
            <SkeletonUI number={2} />
          ) : job_show.length ? (
            <Table
              columns={columns}
              dataSource={job_show}
              bordered
              scroll={{ x: "768px" }}
              pagination={job_show.length > 12 ? { pageSize: 12 } : false}
            />
          ) : (
            <NoContent width={"400px"} message="No job position yet" />
          )}
        </div>
      </section>
    </>
  );
}

function EachAction({
  record,
  graded_data,
  refetch_grd,
  isLoading_grd,
  refetch,
}) {
  //  let default_ sel;
  //  default_ sel = Array.isArray(record?.grade) ? record.grade.map((item)=> item.id) : [];
  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      render: (value) => <>{value?.name || "N/A"}</>,
      ...getColumnSearchPropsObj("grade"),
    },
    {
      title: "Step",
      dataIndex: "step",
      key: "step",
      render: (value) => <>{value?.name || "N/A"}</>,
      ...getColumnSearchPropsObj("step"),
    },
    Table.EXPAND_COLUMN,
    {
      title: "Benefit",
      dataIndex: "benefit_count",
      key: "benefit_count",
      render: (value) => <>{value || "0"}</>,
      ...getColumnSearchProps("benefit_count"),
    },

    {
      title: "Gross Salary",
      dataIndex: "gross_salary",
      key: "gross_salary",
      ...getColumnSearchProps("gross_salary"),
      render: (value) => <>{formatMoney(value) || "N/A"}</>,
    },
  ];
  const [open_modal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(
    Array.isArray(record?.grade) ? record.grade.map((item) => item.id) : []
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  console.log({ selected, graded_data, record });

  function handleModal() {
    setOpenModal(!open_modal);
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  function getColumnSearchPropsObj(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]["name"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  const rowSelection = {
    selectedRowKeys: selected,
    onChange: (_, record) => {
      // console.log(record.map((item) => item.id));
      setSelected(record.map((item) => item.id));
      // setSelected([...selectedRowKeys]);
    },
  };

  function handleAddRole() {
    const creds = {
      role: record.role,
      grading_ids: selected,
    };

    setLoading(true);
    gradeRole(dispatch, creds).then(() => {
      setLoading(false);
      refetch_grd();
      refetch();
    });

    // console.log(creds);
  }
  return (
    <>
      <Button className="bg-info text-white" onClick={handleModal}>
        Set pay structure
      </Button>

      <Modal
        open={open_modal}
        footer={null}
        onCancel={handleModal}
        width={1000}
        className="over"
        maskClosable={false}
      >
        <h5 className="birthday__title">{record.role}</h5>
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {isLoading_grd ? (
            <SkeletonUI number={2} />
          ) : graded_data.length ? (
            <div className="p-3">
              <Table
                columns={columns}
                dataSource={graded_data}
                // bordered
                scroll={{ x: "768px" }}
                expandable={{
                  expandedRowRender: (record) => (
                    <Card
                      title={`${record.grade.name} benefit details`}
                      bordered={false}
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                      }}
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Benefit</th>
                            <th scope="col">Value</th>
                            <th scope="col">Description</th>
                            <th scope="col">Type</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.benefit.map((item, ind) => (
                            <>
                              <tr>
                                <th scope="row" key={item.id}>
                                  {ind + 1}
                                </th>
                                <td>{item.benefit || "N/A"}</td>
                                <td>{item.value || "N/A"}</td>
                                <td>
                                  {item?.description?.length > 200
                                    ? `${item?.description?.substring(
                                        0,
                                        200
                                      )}...`
                                    : item?.description}
                                </td>
                                <td>{item.type || "N/A"}</td>
                                <td>
                                  {item.status ? (
                                    <p className="text-success">Active</p>
                                  ) : (
                                    <p className="text-danger">Inactive</p>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </Card>
                  ),
                  rowExpandable: (record) => record.benefit_count,
                }}
                pagination={graded_data.length > 10 ? true : false}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              />
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="myBtn "
                  disabled={!selected.length}
                  onClick={handleAddRole}
                  loading={loading}
                >
                  Add{" "}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <NoContent width={"400px"} />
              <div className="d-flex justify-content-center mt-3">
                <Link to={"/compensation/create-grade"}>
                  <Button className="myBtn">Add new grade</Button>
                </Link>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default AvailableJobs;
