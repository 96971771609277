import { useState,  useEffect } from "react";
import styles from "../../styles/DragDrop.module.css";
import { useDispatch } from "react-redux";
import FileUpload from "../ui/FileUpload";

function UploadEmployee() {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: "QUICK_SETUP_EMP_PROFILE", payload: file });
  }, [file, dispatch]);

  return (
    <>
      <h3 className={styles.heading}>Employees Profile</h3>
      <small>
        Upload file to onboard and invite your employees{" "}
        <span>
          (<em>optional</em>)
        </span>
        .{" "}
        <a href="/assets/employee_sample_upload.xlsx" download style={{color: "var(--clr-primary)"}}>
          Download sample template.
        </a>
      </small>
      <div style={{ marginBlock: "20px 50px" }}>
      <FileUpload
        file={file}
        setFile={setFile}
        accept={
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        }
        supportedFormats={"xls, xlsx"}
      />

      </div>
      
      <div>{file && file.name}</div>
    </>
  );
}

export default UploadEmployee;
