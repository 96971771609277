import { Input, Button } from "antd";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import { forgotValidator } from "../../util/formValidations";
import {
  useForm,
  useUserCleanUp,
  useUsersNotification,
  useShallowEqualSelector,
} from "../../util/hooks";

import Sidebar from "../ui/Sidebar";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  company_details,
} from "../../util/selectors/userSelectors";
import { getResetLink } from "../../redux/users/userActions";

const Forgot = () => {
  const initForgotUser = {
    EmpID: "",
  };

  useUserCleanUp();
  useUsersNotification();
  const dispatch = useDispatch();
  const spinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const companyDetails = useShallowEqualSelector(company_details);

  const callbackFn = () => {
    getResetLink(dispatch, values).then((res) => {
      if (res?.status === "success") {
        values.EmpID = "";
      }
    });
  };

  const { errors, values, handleChange, handleSubmit } = useForm(
    callbackFn,
    initForgotUser,
    forgotValidator
  );

  return (
    <div className="row bgLogin">
      <Sidebar />
      <div className="col-12 col-md-7 p-0">
        <div className="d-flex align-items-center px-0 auth">
          <div className="row w-100 mx-0">
            <div
              className="col col-lg-7 mx-auto d-flex flex-column align-items-center p-4"
              style={{ maxWidth: 500 }}
            >
              {/* <Logo /> */}

              <h4
                className="d-md-none"
                style={{ fontSize: "27px", fontWeight: "300" }}
              >
                {companyDetails?.business.company_name}
              </h4>
              <div
                className="text-left py-4 py-lg-5 mt-4 px-3 px-lg-4 mx-lg-3 w-100 h-100 form-border text-dark"
                style={{ background: "#FFF" }}
              >
                <div>
                  <h4 className="text-left"> Forgot password</h4>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center mb-2 w-100">
                        <label htmlFor="EmpID" className="d-block w-100 mb-0">
                          Employee ID
                        </label>
                        <div className="w-100 text-right text-danger">
                          <small>
                            {errors.EmpID ||
                              (back_errors.EmpID && back_errors.EmpID[0])}
                          </small>
                        </div>
                      </div>
                      <Input
                        allowClear
                        type="text"
                        placeholder="Employee ID"
                        name="EmpID"
                        size="large"
                        onChange={handleChange}
                        value={values.EmpID}
                      />
                    </div>
                    <div className="my-2 d-flex justify-content-end align-items-center">
                      <Link to="/" className="auth-link text-black">
                        Back to Login
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Button
                        htmlType="submit"
                        size="large"
                        block
                        className="myBtn"
                        loading={spinner}
                      >
                        GET RESET CODE
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-md-none">
                <Logo size={20} />
              </div>
              <small className="mt-3">Privacy | Terms & conditions</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
