import { useState } from "react";
import { DatePicker, Select, Button, Tabs, Input } from "antd";
import AppraisalApprovedTable from "./ApprovedTable";
import AppraisalPendingTable from "./PendingTable";
import {
  useGetPerformancePlans,
  useGetAppraisalSettings,
  useGetAppraisalsPending,
  useGetAppraisalsApproved,
} from "../../../../util/usershookActions";
import { createAppraisal } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import SkeletonUI from "../../../ui/Skeleton";

const CreatePerformanceAppraisal = () => {
  const [createAppraisalLoading, setCreateAppraisalLoading] = useState(false);
  const [enabled, setEnabled] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [creds, setCreds] = useState({
    appraisal_name: "",
    plan_id: "",
    start_date: "",
    end_date: "",
    notice_before_start: "",
    notice_before_end: "",
    review_period: {
      review_period: "",
      to: "",
      from: "",
    },
  });

  const dispatch = useDispatch();

  const {
    data: appraisalApprovedTableData,
    refetch: appraisalApprovedRefetch,
    isLoading: isLoadingApproved,
  } = useGetAppraisalsApproved(enabled, setEnabled);

  const {
    data: appraisalPendingTableData,
    refetch: appraisalPendingRefetch,
    isLoading: isLoadingPending,
  } = useGetAppraisalsPending(enabled, setEnabled);

  const { data: performancePlans, isLoading } = useGetPerformancePlans(
    enabled,
    setEnabled
  );

  const { data: appraisalSettings } = useGetAppraisalSettings(
    enabled,
    setEnabled
  );

  const reviewPeriods = appraisalSettings?.payload?.review_period;

  if (selectedPeriod) {
    const rev = Object.entries(reviewPeriods)
      .filter(([period]) => period === selectedPeriod)
      .map(([, value]) => value);
    if (
      rev[0].to !== creds.review_period.to ||
      rev[0].from !== creds.review_period.from
    ) {
      setCreds((prevState) => ({
        ...prevState,
        review_period: {
          ...prevState.review_period,
          to: rev[0].to,
          from: rev[0].from,
        },
      }));
    }
  }

  const handleChange = (name, value) => {
    setCreds((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedPeriod(value);
    setCreds((prevState) => ({
      ...prevState,
      review_period: {
        ...prevState.review_period,
        review_period: value
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      },
    }));
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeTab = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: <span>APPROVED</span>,
      children: (
        <AppraisalApprovedTable
          appraisalApprovedTableData={appraisalApprovedTableData}
          appraisalApprovedRefetch={appraisalApprovedRefetch}
          appraisalPendingRefetch={appraisalPendingRefetch}
          isLoading={isLoadingApproved}
        />
      ),
    },
    {
      key: "2",
      label: <span>PENDING</span>,
      children: (
        <AppraisalPendingTable
          appraisalPendingTableData={appraisalPendingTableData}
          appraisalPendingRefetch={appraisalPendingRefetch}
          appraisalApprovedRefetch={appraisalApprovedRefetch}
          isLoading={isLoadingPending}
        />
      ),
    },
  ];

  const handleCreateAppraisal = (e) => {
    e.preventDefault();
    setCreateAppraisalLoading(true);
    createAppraisal(dispatch, creds).then(() => {
      setCreateAppraisalLoading(false);
      setCreds({
        appraisal_name: "",
        plan_id: "",
        start_date: "",
        end_date: "",
        notice_before_start: "",
        notice_before_end: "",
        review_period: {
          review_period: "",
          to: "",
          from: "",
        },
      });
      appraisalPendingRefetch();
    });
  };

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className=""
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "2rem",
          }}
        >
          <h3 className="h4">Create a new appraisal</h3>
        </div>

        {isLoading ? (
          <SkeletonUI />
        ) : (
          <>
            <form
              className="d-flex flex-column gap-5"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="create__appraisal__form">
                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Appraisal Name</label>
                  <Input
                    value={creds?.appraisal_name}
                    size="large"
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    onChange={(e) =>
                      handleChange("appraisal_name", e.target.value)
                    }
                  />
                </div>
                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Plan Title</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a title"
                    optionFilterProp="children"
                    value={creds?.plan_id}
                    onChange={(value) => handleChange("plan_id", value)}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={performancePlans?.payload?.map((plan) => ({
                      value: plan?.id,
                      label: plan?.name,
                    }))}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">Review Period</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a period"
                    optionFilterProp="children"
                    value={selectedPeriod}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={
                      reviewPeriods &&
                      Object.keys(reviewPeriods).map((period) => ({
                        value: period,
                        label: period
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" "),
                      }))
                    }
                  />
                </div>

                <div className="d-flex flex-column gap-2 create__date">
                  <label className="fw-bold fs-6">Start Date</label>
                  <DatePicker
                    size="large"
                    value={
                      creds?.start_date
                        ? dayjs(creds?.start_date, "D-M-YYYY")
                        : null
                    }
                    onChange={(date, dateString) =>
                      handleChange("start_date", date.format("D-M-YYYY"))
                    }
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                <div className="d-flex gap-5 justify-content-center">
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">From</label>
                    <DatePicker
                      size="large"
                      value={
                        creds?.review_period.from
                          ? dayjs(creds?.review_period.from)
                          : null
                      }
                      disabled
                    />
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">To</label>{" "}
                    <DatePicker
                      size="large"
                      value={
                        creds?.review_period.to
                          ? dayjs(creds?.review_period.to)
                          : null
                      }
                      disabled
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-2 create__date">
                  <label className="fw-bold fs-6">End Date</label>
                  <DatePicker
                    size="large"
                    value={
                      creds?.end_date
                        ? dayjs(creds?.end_date, "D-M-YYYY")
                        : null
                    }
                    onChange={(date, dateString) =>
                      handleChange("end_date", date.format("D-M-YYYY"))
                    }
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">
                    No of Days for advance notice before START of appraisal
                  </label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select an option"
                    optionFilterProp="children"
                    value={creds?.notice_before_start}
                    onChange={(value) =>
                      handleChange("notice_before_start", value)
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={Array.from(
                      { length: 30 },
                      (_, index) => index + 1
                    ).map((notice_before_start) => ({
                      value: notice_before_start,
                      label: notice_before_start,
                    }))}
                  />
                </div>

                <div className="d-flex flex-column gap-2 ">
                  <label className="fw-bold fs-6">
                    No of Days for advance notice before END of appraisal
                  </label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select an option"
                    optionFilterProp="children"
                    value={creds?.notice_before_end}
                    onChange={(value) =>
                      handleChange("notice_before_end", value)
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    style={{
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                    options={Array.from(
                      { length: 30 },
                      (_, index) => index + 1
                    ).map((notice_before_end) => ({
                      value: notice_before_end,
                      label: notice_before_end,
                    }))}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                  }}
                  onClick={handleCreateAppraisal}
                  loading={createAppraisalLoading}
                  disabled={
                    Object.values(creds).some((value) => value === "") ||
                    creds.review_period.review_period === "" ||
                    creds.review_period.from === "" ||
                    creds.review_period.to === ""
                  }
                >
                  Create Appraisal
                </Button>
              </div>
            </form>

            <div className="create__appraisal__table__tabs">
              <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreatePerformanceAppraisal;
