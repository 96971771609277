import React from "react";
import HocHeader from "../ui/HocHeader";
import { RiPulseLine } from "react-icons/ri";

function Welcome() {
  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Welcome to"
        module_name="Permissions Management"
        module_description="
    HR Managers and privileged administrators can view all your new
    hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4 mb-4">
            <h5>Summary</h5>

            <div className="mt-5">
              <div className="d-flex gap-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(254, 98, 98, 0.22)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h4>Total Permissions</h4>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">0</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(205, 96, 35, 0.19)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h4>Total User Groups</h4>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">0</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Welcome;
