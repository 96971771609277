import React from "react";
import { Table } from "antd";

const TargetTable = (tableData) => {
  const groupedData = {};

  // Group the data by department
  tableData?.tableData.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Target (unit)",
      dataIndex: "target",
      key: "target",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "endDate",
    },
  ];

  return (
    <div className="performance_table">
      <Table
        columns={columns}
        dataSource={transformedData}
        bordered
        pagination={false}
      />
    </div>
  );
};

export default TargetTable;
