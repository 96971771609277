import React from "react";
import { company_details } from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { QRCode } from "antd";

function TemplateA({ selectedEmp, checked }) {
  const companyDetails = useShallowEqualSelector(company_details);

  console.log({ selectedEmp });

  const companyLogo = companyDetails?.settings[0]?.value;

  return (
    <>
      <div className="id__card mx-auto">
        <div className="head__section">
          <span className="com__name">
            {companyDetails?.business?.company_name || ""}
          </span>
          <span> Staff ID Card</span>
        </div>
        <div className="body__section">
          <img
            className="img__card"
            src={selectedEmp.avatar || "/assets/man.png"}
          />
          <div className="info__section__id-card">
            <small>
              {" "}
              <span>Name :</span> {selectedEmp?.name || ""}
            </small>
            <small>
              {" "}
              <span>Designation : {selectedEmp?.job_role?.role || ""}</span>
            </small>
            <small>
              {" "}
              <span>Staff No :</span> {selectedEmp?.employee_id || ""}
            </small>
            <small className="d-flex">
              {" "}
              <span>Signature :</span>{" "}
              <img src={selectedEmp.signature || "/assets/signature.gif"} />
            </small>
          </div>
        </div>
      </div>

      <div className="id__card-back mt-2 mx-auto">
        <div className="first__section">
          <span className="disclaimer">
            This is to certify that the bearer whose name, signature and
            photograph appear overleaf is a STAFF in this organization:
          </span>
          <span className="com__name">
            {" "}
            {companyDetails?.business?.company_name || "N/A"}
          </span>
          <span className="disclaimer">
            If found please send it to the nearest police station in your
            location or(and) call +234 (0) 123 456 80 30, +234(0) 234 567 89 12
          </span>
          <div className="d-flex w-100">
            <div className='w-25'>
         {checked ? <QRCode value="https://ant.design/" size={50} className="qrcode_override" style={{backgroundColor:'none'}}/> : <div className=""></div>}

            </div>
          <div className="back__img ">
            <img src="/assets/signature.gif" width={30} height={30} />
            <p>AUTHORISED SIGNATURE</p>
          </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateA;
