import React, { useState } from "react";
import { AiFillAppstore, AiOutlineIdcard } from "react-icons/ai";

import { BsArrowLeftCircleFill } from "react-icons/bs";

import { NavLink, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import IDCardDropdown from "./dropdown/IDCardDropdown";
import { MdOutlineFindReplace } from "react-icons/md";
import ReplacementDropdown from "./dropdown/ReplacementDropdown";
import CanAccess from "../hoc/CanAccess";

function Sidebar({ handleToggle }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownrep, setShowDropdownRep] = useState(false);

  function handleDropdown(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      e.target.getAttribute("data-parent-dropdown") ||
      e.target.parentElement.getAttribute("data-parent-dropdown")
    ) {
      setShowDropdown(!showDropdown);
    }
  }

  function handleDropdownRep(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      e.target.getAttribute("data-parent-dropdown") ||
      e.target.parentElement.getAttribute("data-parent-dropdown")
    ) {
      setShowDropdownRep(!showDropdownrep);
    }
  }

  return (
    <nav
      className="performance__nav d-flex flex-column justify-content-between small__sidebar"
      id="sidebar"
    >
      <div>
        <div className="d-flex flex-column gap-5 my-3 pdr-12 pt-3">
          <span className="d-flex align-items-center justify-content-between">
            <h3 className="mb-0 sidebar_text_hidden">
              (ID) Identity Card Management
            </h3>
            <div className="sidebar__toggler" onClick={handleToggle}>
              <AiOutlineMenu className="sidebar__menu" title="Toggle sidebar" />
            </div>
          </span>
          <Link
            to={"/subscriptions"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Return to Modules" />
            <span className="sidebar_text">Return to Modules</span>
          </Link>
        </div>
        <ul className="performance__navlinks ">
          <CanAccess can_access={"view-identity-card-overview"} isRoute={false}>
            <li>
              <NavLink
                to={""}
                end
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <AiFillAppstore title="Overview" />
                <span className="sidebar_text">Overview</span>
              </NavLink>
            </li>
          </CanAccess>

          <li
            onClick={handleDropdown}
            className="dropdown"
            style={{ cursor: "pointer" }}
            data-parent-dropdown="true"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              data-parent-dropdown="true"
            >
              <span
                className="d-flex gap-2 align-items-center"
                data-parent-dropdown="true"
              >
                <AiOutlineIdcard data-parent-dropdown="true" title="ID Card" />
                <span className="sidebar_text">ID Card</span>
              </span>
              {showDropdown ? (
                <AiOutlineDown
                  data-parent-dropdown="true"
                  style={{ fontSize: 15 }}
                />
              ) : (
                <AiOutlineRight
                  data-parent-dropdown="true"
                  style={{ fontSize: 15 }}
                />
              )}
            </div>
            <IDCardDropdown showDropdown={showDropdown} />
          </li>
          <li
            onClick={handleDropdownRep}
            className="dropdown"
            style={{ cursor: "pointer" }}
            data-parent-dropdown="true"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              data-parent-dropdown="true"
            >
              <span
                className="d-flex gap-2 align-items-center"
                data-parent-dropdown="true"
              >
                <MdOutlineFindReplace
                  data-parent-dropdown="true"
                  title="Report/Replacement"
                />
                <span className="sidebar_text">Report/Replacement</span>
              </span>
              {showDropdownrep ? (
                <AiOutlineDown
                  data-parent-dropdown="true"
                  style={{ fontSize: 15 }}
                />
              ) : (
                <AiOutlineRight
                  data-parent-dropdown="true"
                  style={{ fontSize: 15 }}
                />
              )}
            </div>
            <ReplacementDropdown showDropdown={showDropdownrep} />
          </li>
        </ul>
      </div>

      <div className="attr text-center mb-3  sidebar_text">
        <small>All rights reserved.</small>
        <br />
        <small>CloudletHR</small>
      </div>
    </nav>
  );
}

export default Sidebar;
