import { Modal, Button } from "antd";
import { useState, useMemo, useEffect } from "react";
import NoContent from "../../ui/NoContent";
import { useGetInductionTemplate } from "../../../util/usershookActions";
import { Pagination } from "antd";
import { user_errors } from "../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import { createTemple, editTemple } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import EachTemplate from "./EachTemplate";

const InductionTemplates = () => {
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const [showModal, setShowModal] = useState(false);

  const [enabled, setEnabled] = useState(true);
  const [page, setPage] = useState(1);
  const [template_show, setTemplateShow] = useState([]);
  const [sel_template, setSelTemplate] = useState({});
  const [tem_to_submit, SetTemToSubmit] = useState("");
  const [loading_tem, setLoadingTem] = useState(false);

  const { data: temp, refetch: refetchTem } = useGetInductionTemplate(
    enabled,
    setEnabled,
    page
  );
  const temp_data = useMemo(() => temp?.payload, [temp]);

  console.log({ temp_data });

  useEffect(() => {
    if (temp_data?.data?.length) {
      setTemplateShow(temp_data?.data);
    }
  }, [temp_data]);

  const modalHandler = (e, template = null) => {
    setShowModal(!showModal);

    if (template) {
      setSelTemplate(template);
      SetTemToSubmit(template.name);
    }
  };
  const modalHandlerCancel = (e) => {
    setShowModal(false);
    setSelTemplate({});
    SetTemToSubmit("");
  };

  // console.log({ temp_data, template_show });
  const handlePaginationEmp = (page) => {
    setPage(page);
    setEnabled(true);
  };

  const submitTemplate = (e) => {
    e.preventDefault();
    // console.log(tem_to_submit);
    const creds = {
      type: "induction",
      name: tem_to_submit,
    };
    setLoadingTem(true);
    createTemple(dispatch, creds).then((res) => {
      setLoadingTem(false);
      if (res?.status === "success") {
        SetTemToSubmit("");
        refetchTem();
      }
      setShowModal(false);
    });
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    // console.log({ sel_template });
    const creds = {
      type: "induction",
      name: tem_to_submit,
      id: sel_template.id,
    };
    setLoadingTem(true);
    editTemple(dispatch, creds).then((res) => {
      setLoadingTem(false);
      if (res?.status === "success") {
        SetTemToSubmit("");
        refetchTem();
        setShowModal(false);
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mb-0">All Templates</h3>
        <Button className="myBtn" onClick={(e) => modalHandler(e)}>
          New Template
        </Button>
      </div>
      {template_show.length ? (
        <>
          <div className="row mt-5">
            {template_show.map((template) => (
              <EachTemplate
                template={template}
                modalHandler={modalHandler}
                refetchTem={refetchTem}
              />
            ))}
          </div>
          {temp_data?.last_page > 1 && (
            <div className="my-3 d-flex justify-content-end">
              <Pagination
                total={temp_data?.last_page}
                pageSize={1}
                onChange={handlePaginationEmp}
              />
            </div>
          )}
        </>
      ) : (
        <NoContent
          message={"You currently do not have a template. Kindly create one"}
          width={400}
        />
      )}
      <Modal
        open={showModal}
        onCancel={modalHandlerCancel}
        footer={null}
        centered
      >
        <h3>
          {Object.keys(sel_template).length
            ? "Edit Template"
            : "Create New Template"}
        </h3>
        <form className="onboarding-form" onSubmit={submitTemplate}>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <label htmlFor="template_name">Name</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {back_errors.name && back_errors.name[0]}
                  </small>
                </div>
                <input
                  type="text"
                  placeholder="Template Name"
                  name="template_name"
                  id="template_name"
                  onChange={(e) => SetTemToSubmit(e.target.value)}
                  value={tem_to_submit}
                />
              </div>
              <div className="d-flex gap-2 mt-4 justify-content-end align-items-center">
                <Button onClick={modalHandlerCancel} type="text">
                  Cancel
                </Button>
                {Object.keys(sel_template).length ? (
                  <Button
                    onClick={updateTemplate}
                    className="myBtn"
                    loading={loading_tem}
                    disabled={tem_to_submit === "" ? true : false}
                  >
                    Update Template
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    className="myBtn"
                    loading={loading_tem}
                    disabled={tem_to_submit === "" ? true : false}
                  >
                    Create Template
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default InductionTemplates;
