import React, { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { rejectUserInfo, deleteUserDependant } from "../../../redux/users/userActions";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import { MdContacts, MdOutlineClose, MdRemoveRedEye } from "react-icons/md";
import Avatar from "react-avatar";
import { TbEyeOff } from "react-icons/tb";

function DependantCard({ info }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const handleMore = () => {
    setShowMore(!showMore);
  };

  console.log({ info });

  const confirm = () => {
    setLoading(true);
    deleteUserDependant(dispatch, info).then(
      (res) => {
        setLoading(false);
        setShowModal(false);

        if (res?.status === "success") {
          dispatch({ type: "REMOVE_DEPENDANT_INFO", payload: info.id });
        }
      }
    );
  };

  const modalTrigger = (name) => {
    Modal.confirm({
      title: `Remove ${name}`,
      content: `Are you sure you want to remove ${name} as a dependant?`,
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  return (
    <div className="col-md-4 mb-5">
      <div className="card bg-white px-2">
        <div className="card-body position-relative">
          <div className={styles.cardDetails}>
            <div className="d-flex flex-column gap-2">
              <Avatar
                name={info.name}
                round
                size="80"
                color="#d9d9d9"
                className={styles.cardAvatar}
              />
              <h5 className="my-3">{info.name}</h5>
            </div>
            <div className="row mt-2">
              <div className="col-5">
                <div className="d-flex gap-2">
                  <label>Genotype:</label>
                  <p> {showMore ? info.genotype : "**"}</p>
                </div>
                <div className="d-flex gap-2">
                  <label>Blood Group:</label>
                  <p>{showMore ? info.blood_group : "**"}</p>
                </div>
              </div>
              <div className="col-7 d-flex align-items-center gap-2">
                <MdContacts className="h3 mb-0" />
                <div>
                  <label>Contact</label>
                  <p style={{ color: "var(--clr-primary)", fontWeight: 500 }}>
                    {showMore ? info.telephone : "*********"}
                  </p>
                </div>
              </div>
              <div className="col-7 d-flex align-items-center gap-2">
                <div>
                  <label>Date of birth</label>
                  <p style={{ color: "var(--clr-primary)", fontWeight: 500 }}>
                    {showMore ? info.date_of_birth : "*******"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.ctaBtns}>
            <button className="myBtn-reveal" onClick={handleMore}>
              {showMore ? <MdRemoveRedEye /> : <TbEyeOff />}
            </button>
            <button
              className="myBtn-close"
              onClick={() => modalTrigger(info.name)}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DependantCard;
