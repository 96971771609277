import React from "react";
import UpcomingTask from "./UpcomingTask";
import AddTaskButton from "./AddTaskButton";

const UpcomingTasksList = () => {
  return (
    <div
      className="d-flex flex-column gap-3"
      style={{
        width: "20rem",
        minWidth: "20rem",
      }}
    >
      <div className="d-flex gap-3 align-items-start">
        <h5 className="h5">February 18. Today</h5>
        <small className="fs-6 fw-semibold text-muted">2</small>
      </div>

      <div className="d-flex flex-column gap-3">
        <UpcomingTask />
        <UpcomingTask />
      </div>
      <AddTaskButton noBackground={true} />
    </div>
  );
};

export default UpcomingTasksList;
