import React from "react";
import InductionSupervisor from "./induction-stage/InductionSupervisor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const IndOnbSide = () => {
  return (
    <div>
      <h4 className="mb-3">Supervisors</h4>
      <ul className="supervisors">
        <InductionSupervisor
          name={"Ebenezer Babalola"}
          role={"Developer"}
          image={"/assets/3071335.jpg"}
        />
        <InductionSupervisor
          name={"Abisola Majeed"}
          role={"Sabi girl"}
          image={"/assets/3891942.jpg"}
        />
        <InductionSupervisor
          name={"Titilayo Pedro"}
          role={"Human Resource"}
          image={"/assets/20943752.jpg"}
        />
      </ul>
      <p className="text-end supr-see">Show More</p>
      <div className="progress-status d-flex justify-content-center gap-3">
        <div>
          <CircularProgressbar
            value={7}
            maxValue={35}
            text={7}
            strokeWidth={15}
            styles={buildStyles({
              pathColor: "yellow",
              textColor: "var(--clr-primary)",
            })}
          />
          <p className="text-center mt-3 h3">In Progress</p>
        </div>
        <div>
          <CircularProgressbar
            value={17}
            maxValue={35}
            text={17}
            strokeWidth={15}
            styles={buildStyles({
              pathColor: "green",
              textColor: "var(--clr-primary)",
            })}
          />
          <p className="text-center mt-3 h3">Completed</p>
        </div>
      </div>
    </div>
  );
};

export default IndOnbSide;
