import React from "react";

import { NavLink } from "react-router-dom";

function WageItemsDropdown() {
  return (
    <ul className="dropdown_links mt-3 px-0">
      <li>
        <NavLink
          to={"pay-roll/deductions-master"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Deductions Masters </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Wage Items Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll/payment-template"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Payment Template </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll/batch-payment"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Batch Payment </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll/authorize-payment"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Authorize Payment </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll/payslip"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Send Payslip </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"pay-roll/employee-payslip"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Send Your Payslip </span>
        </NavLink>
      </li>
    </ul>
  );
}

export default WageItemsDropdown;
