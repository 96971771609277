import React, { useState } from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";
import { Input, Select, Modal } from "antd";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { settings_util } from "../../../../util/selectors/userSelectors";
import { useGetSystemSettingsUtils } from "../../../../util/usershookActions";
import InputCategories from "../../settings/input_entries/InputCategories";
import { AiFillPlusCircle } from "react-icons/ai";

function Employment({
  handleChange,
  values,
  errors,
  back_errors,
  auto_gen,
  employment,
  onboarding,
}) {
  const [enabled_uti, setEnabledUtil] = useState(true);
  const { refetch } = useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);
  const settingsUtil = useShallowEqualSelector(settings_util);
  const emp_type = Object.keys(settingsUtil).length
    ? settingsUtil?.system_inputs["Employment Types"]
    : [];

  const [showCatpage, setShowCatpage] = useState(false);
  const [selected, setSelected] = useState({});

  const handleSelect = (value, name) => {
    handleChange("_", true, name, value);
  };
  const err_text = "personal.0.";

  const handleCatpage = () => {
    setShowCatpage(!showCatpage);
  };

  const handleCatSelected = (item) => {
    console.log(item);
    setSelected(item);
    setShowCatpage(true);
  };
  return (
    <div className={onboarding ? "col-md-12 mt-5" : "col-md-6 mt-5"}>
      <div className="card b0">
        <div className="card-body py-4">
          <div>
            <h4 className={styles.subheading4}>Employment Information</h4>
            <div className={styles.hr}></div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="employee_id">Employee Number</label>
                  <small className="text-danger">
                    {errors.employee_id ||
                      (back_errors[`${err_text}employee_id`] &&
                        back_errors[`${err_text}employee_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Input
                  type="text"
                  name="employee_id"
                  className={styles.employee_id}
                  disabled
                  value={
                    auto_gen?.available_employee_number
                      ? `${auto_gen?.available_employee_number}`
                      : "N/A"
                  }
                  readOnly
                  // addonBefore={}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="employee_status">Employee Status</label>
                  <small className="text-danger">
                    {errors.employee_status ||
                      (back_errors[`${err_text}employee_status`] &&
                        back_errors[`${err_text}employee_status`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="employee_status"
                  value={values.status}
                  showSearch
                  onChange={(value) => handleSelect(value, "status")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {employment.status.map((status) => (
                    <option key={status.id} value={status.key}>
                      {status.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="employment_date">
                    Employment Date <span className="text-danger">*</span>{" "}
                  </label>
                  <small className="text-danger">
                    {errors.employment_date ||
                      (back_errors[`${err_text}employment_date`] &&
                        back_errors[`${err_text}employment_date`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <small className="text-danger">
                  {errors.employment_date ||
                    (back_errors.employment_date &&
                      back_errors.employment_date[0])}
                </small>
                <input
                  type="date"
                  name="employment_date"
                  id="employment_date"
                  onChange={handleChange}
                  value={values.employment_date}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="employment_type">Employment Type</label>
                  <small className="text-danger">
                    {errors.employment_type ||
                      (back_errors[`${err_text}employment_type`] &&
                        back_errors[`${err_text}employment_type`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <div className="hris_form_group">
                  <Select
                    name="employment_type"
                    id="employment_type"
                    value={values.employment_type}
                    showSearch
                    onChange={(value) => handleSelect(value, "employment_type")}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <option value="">--</option>
                    {emp_type?.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <span className="d-flex align-items-center px-2">
                    <AiFillPlusCircle
                      size={25}
                      color={"green"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCatSelected({
                          title: "Employment Types",
                          input:
                            settingsUtil?.system_inputs?.["Employment Types"],
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {console.log({ val: values.on_probation })}
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="on_probation">On Probation</label>
                  <small className="text-danger">
                    {errors.on_probation ||
                      (back_errors[`${err_text}on_probation`] &&
                        back_errors[`${err_text}on_probation`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <Select
                  name="on_probation"
                  id="on_probation"
                  value={values.on_probation}
                  showSearch
                  onChange={(value) => handleSelect(value, "on_probation")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Select>
              </div>
            </div>
            {values.on_probation == 1 ? (
              <div className="col-md-4">
                <div className={styles.inputGroup}>
                  <div className="d-flex justify-content-between flex-wrap ">
                    <label htmlFor="probation_end_date">
                      Probation End Date <span className="text-danger">*</span>{" "}
                    </label>
                    <small className="text-danger">
                      {errors.probation_end_date ||
                        (back_errors[`${err_text}probation_end_date`] &&
                          back_errors[
                            `${err_text}probation_end_date`
                          ][0]?.replace(err_text, " "))}
                    </small>
                  </div>

                  <small className="text-danger">
                    {errors.probation_end_date ||
                      (back_errors.probation_end_date &&
                        back_errors.probation_end_date[0])}
                  </small>
                  <input
                    type="date"
                    name="probation_end_date"
                    id="probation_end_date"
                    onChange={handleChange}
                    value={values.probation_end_date}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal open={showCatpage} footer={null} centered onCancel={handleCatpage}>
        <InputCategories
          handleCatpage={handleCatpage}
          selected={selected}
          refetch={refetch}
        />
      </Modal>
    </div>
  );
}

export default Employment;
