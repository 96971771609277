import { storage_type } from "../../config";

import { SAVE_ACTIVITIES, SAVE_ACTIVITY, SAVE_TEMPLATE } from "./userTypes";

const { REACT_APP_TOKEN: TOKEN } = process.env;

const quick_setup = [
  {
    name: "Logo Upload",
    required: true,
    endpoint: "quick-setup/logo-upload",
    field: {
      file: null,
      channel: "Cloudinary",
    },
  },
  {
    name: "Company Details",
    required: true,
    endpoint: "quick-setup/company-details",
    field: {
      office_address: null,
      rc_number: null,
      phone_number: null,
      mobile_number: null,
    },
  },
  {
    name: "Password Settings",
    required: false,
    endpoint: "quick-setup/password-settings",
    field: {
      minimum_length: null,
      lock_after_retry: null,
      password_history: null,
      password_strength: null,
      allow_self_recovery: null,
      allow_self_unlock: null,
    },
  },

  {
    name: "Employees Profile",
    required: false,
    endpoint: "quick-setup/employees-profile",
    field: {
      file: null,
    },
  },

  {
    name: "Display Settings",
    required: false,
    endpoint: "quick-setup/display-settings",
    field: {
      primary_color: "#0065AB",
      secondary_color: null,
    },
  },

  {
    name: "Confirmation",
    required: false,
    endpoint: "",
  },
];

const initState = {
  is_Loggedin: storage_type.getItem(TOKEN) ? true : false,
  user_profile: {},
  company_url: null,
  message: null,
  status: null,
  spinner: false,
  errors: {},
  active_modules: [],
  quick_setup,
  current_quick_setup: {},
  in_nessages: [],
  client_permissions: {},
  client_subcription: {},
  client_auditlogs: {},
  user_profile_pending_request: [],
  user_profile_default: [],
  user_profile_pending: [],
  employee_count_det: {},
  settings_util: {},
  settings: {},
  cat_to_onboarding: {},
  new_hire: [],
  hire_profile: {},
  new_hire_del: [],
  new_hire_overview: [],
  departments: [],
  rol_del: [],
  birthdays: [],
  holidays: [],
  event_type: [],
  combined_events: [],
  anniversaries: [],
  designation_role: [],
  sel_temp: {},
  temp_activities: [],
  temp_activity: {},
  system_banks: [],
  onb_step: 0,
  sys_scheduled_event: [],
  create_goals: {
    year: "",
    goal_theme: "",
    performance_tool: "MBO",
    set_goals: [
      {
        goal: "",
        weight: "",
        description: "",
        unit: "%",
      },
    ],
  },
  create_goals_bsc: {
    year: "",
    goal_theme: "",
    performance_tool: "BSC",
    set_goals: [
      {
        goal: "Financial goals",
        weight: "",
        description: "",
        unit: "%",
      },
      {
        goal: "Customer goals",
        weight: "",
        description: "",
        unit: "%",
      },
      {
        goal: "Internal processes",
        weight: "",
        description: "",
        unit: "%",
      },
      {
        goal: "Learning and growth",
        weight: "",
        description: "",
        unit: "%",
      },
    ],
  },
  sub_create_goals: {},
  get_create_goals: {},
  performance_stats: {},
  performance_setup: {},
  performance_unit: "",
  financial_year: [],
  performance_dept_plan: {},
  performance_employee_plan: {},
  performance_preview: {},
  performance_list_work: {},
  performance_number: [],
  goals_breakdown: {},
  selected_kpi_goal: {},
  sel_sheet_year: "",
  user_nudge: [],
  compensations_overview: {},
  idcard_overview: {},
  all_benefits: [],
  one_benefit: {},
  com_settings: [],
  avail_job_grade: [],
  one_avail_job: {},
  all_system_step: [],
  all_system_grades: [],
  all_leavegroups: [],
  all_leaveRequests: [],
  leave_history_employee: [],
  annual_leave_history_employee: [],
  team_leave_history: [],
  annual_leave_plan: [],
  get_annual_leave_plan: [],
  team_annual_leave_plan: [],
  team_annual_leave_plan_team: [],
  all_leaveRequests_hr: [],
  all_leaveRequest_team: [],
  leave_group: [],
  leave_group_distribution: [],
  team_member: {},
};

const userReducer = (state = initState, { type, payload, back }) => {
  switch (type) {
    case "MY_TEAM_SUCCESS":
      return {
        ...state,
        team_member: payload.payload,
      };
    case "ALL_LEAVE_DISTRIBUTION_SUCCESS":
      return {
        ...state,
        leave_group_distribution: payload.payload,
      };
    case "ALL_LEAVE_GROUP_SUCCESS":
      return {
        ...state,
        leave_group: payload.payload,
      };
    case "ALL_SYSTEMS_GRADES_SUCCESS":
      return {
        ...state,
        all_system_grades: payload.payload,
      };
    case "ANNUAL_TEAM_LEAVE_PLAN_TEAM_SUCCESS":
      return {
        ...state,
        team_annual_leave_plan: payload.payload,
      };
    case "ADD_LEAVE_REQUEST_TEAM_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_LEAVE_REQUEST_TEAM":
      return {
        ...state,
        all_leaveRequests_team: payload.payload,
      };
    case "ADD_LEAVE_REQUEST_TEAM_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ADD_LEAVE_REQUEST_HR_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_LEAVE_REQUEST_HR":
      return {
        ...state,
        all_leaveRequests_hr: payload.payload,
      };
    case "ADD_LEAVE_REQUEST_HR_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ANNUAL_TEAM_LEAVE_PLAN_SUCCESS":
      return {
        ...state,
        team_annual_leave_plan: payload.payload,
      };
    case "ANNUAL_LEAVE_PLAN_SUCCESS":
      return {
        ...state,
        get_leave_plan: payload.payload,
      };
    case "ADD_ANNUAL_LEAVE":
      return {
        ...state,
        annual_leave_plan: payload.payload,
      };
    case "ADD_ANNUAL_LEAVE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ADD_ANNUAL_LEAVE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "TEAM_LEAVE_HISTORY_SUCCESS":
      return {
        ...state,
        team_leave_history: payload,
      };
    case "ANNUAL_LEAVE_HISTORY_SUCCESS":
      return {
        ...state,
        annual_leave_history_employee: payload,
      };
    case "LEAVE_HISTORY_SUCCESS":
      return {
        ...state,
        leave_history_employee: payload,
      };
    case "ADD_LEAVE_REQUEST_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_LEAVE_REQUEST":
      return {
        ...state,
        all_leaveRequests: payload.payload,
      };
    case "ADD_LEAVE_REQUEST_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ALL_LEAVEGROUP_SUCCESS":
      return {
        ...state,
        all_leavegroups: payload.payload,
      };
    case "ADD_EDIT_SYSTEM_STEPS_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_SYSTEM_STEPS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_EDIT_SYSTEM_STEPS": {
      const update_system_step = state.all_system_step.filter(
        (step) => step.id === payload.payload.id
      );
      const unchanged_system_step = state.all_system_step.filter(
        (step) => step.id !== payload.payload.id
      );
      if (update_system_step.length > 0) {
        return {
          ...state,
          all_system_step: [...unchanged_system_step, ...payload.payload],
        };
      } else {
        return {
          ...state,
          all_system_step: [...state.all_system_step, payload.payload],
        };
      }
    }
    case "ALLSYSTEM_STEPS": {
      return {
        ...state,
        all_system_step: payload.payload,
      };
    }
    case "DELETE_SYSTEM_STEP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "REMOVE_FROM_SYSTEM_STEP":
      const new_system_step = state.all_system_step.filter(
        (step) => step.id !== payload
      );
      return {
        ...state,
        all_system_step: [...new_system_step],
      };
    case "ONE_AVAIL_JOB_SUCCESS": {
      return {
        ...state,
        one_avail_job: payload.payload,
      };
    }
    case "AVAILABLE_JOB_GRADE_SUCCESS": {
      return {
        ...state,
        avail_job_grade: payload.payload,
      };
    }
    case "COM_SETTINGS_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        com_settings: payload.payload,
      };
    }
    case "UPDATE_COM_SETTING": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        com_settings: payload.payload,
      };
    }
    case "BENEFITS_IN_KIND_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        all_benefits: payload.payload,
      };
    }
    case "BENEFITS_ONE_KIND_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        one_benefit: payload.payload,
      };
    }
    case "REMOVE_DEL_BENEFIT": {
      const new_benefits_arry = [...state.all_benefits].filter(
        (item) => item.benefit !== payload
      );

      return {
        ...state,
        all_benefits: new_benefits_arry,
      };
    }
    case "OVERVIEW_COMPENSATIONS_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        compensations_overview: payload.payload,
      };
    }
    case "OVERVIEW_IDCARD_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        idcard_overview: payload.payload,
      };
    }
    case "GET_USER_NUDGE_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        user_nudge: payload.payload,
      };
    }
    case "NUDGE_ACTION_SUCCESS": {
      // console.log({ ko: payload.payload });
      return {
        ...state,
        user_nudge: payload.payload,
      };
    }
    case "SELECTED_GOAL_KPI": {
      return {
        ...state,
        selected_kpi_goal: payload,
      };
    }
    case "PERFORMANCE_APPROVAL_PREVIEW": {
      return {
        ...state,
        performance_preview: payload,
      };
    }
    case "SHEET_YEAR_DETAILS": {
      return {
        ...state,
        sel_sheet_year: payload,
      };
    }
    case "GOAL_BREAKDOWN_SUCCESS": {
      return {
        ...state,
        goals_breakdown: payload.payload,
      };
    }
    case "LIST_WORKFLOW_SUCCESS": {
      return {
        ...state,
        performance_list_work: payload.payload,
      };
    }
    case "CREATE_PERFORMANCE_FLOW_SUCCESS": {
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // performance_list_work: payload.payload,
      };
    }
    case "LIST_WORKFLOW_NUMBER_SUCCESS": {
      return {
        ...state,
        performance_number: payload.data,
      };
    }
    case "PERFORMANCE_WORK_FLOW_SUCCESS": {
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        performance_list_work: payload.payload,
      };
    }
    case "PEFORMANCE_OBJECTIVE_UNIT": {
      const former = { ...state.create_goals };
      const goals = former.set_goals;
      goals.forEach((item) => {
        item.unit = payload;
      });

      return {
        ...state,
        create_goals: {
          ...former,
        },
        performance_unit: payload,
      };
    }
    case "PEFORMANCE_OBJECTIVE_UNIT_BSC": {
      const former = { ...state.create_goals_bsc };
      const goals = former.set_goals;
      goals.forEach((item) => {
        item.unit = payload;
      });

      return {
        ...state,
        create_goals_bsc: {
          ...former,
        },
        performance_unit: payload,
      };
    }
    case "SAVE_COMPANY_URL":
      return {
        ...state,
        company_url: payload,
      };
    case "PEFORMANCE_OBJECTIVE_YEAR":
      // console.log(payload);
      return {
        ...state,
        create_goals: { ...state.create_goals, year: payload },
      };
    case "PLAN_DEPARTMENT_SUCCESS":
      return {
        ...state,
        performance_dept_plan: { ...payload.payload },
      };
    case "PLAN_EMPLOYEE_SUCCESS":
      return {
        ...state,
        performance_employee_plan: { ...payload.payload },
      };
    case "PEFORMANCE_OBJECTIVE_THEME":
      // console.log(payload);
      return {
        ...state,
        create_goals: { ...state.create_goals, goal_theme: payload },
      };
    case "PEFORMANCE_OBJECTIVE_YEAR_BSC":
      // console.log(payload);
      return {
        ...state,
        create_goals_bsc: { ...state.create_goals_bsc, year: payload },
      };
    case "PEFORMANCE_OBJECTIVE_THEME_BSC":
      // console.log(payload);
      return {
        ...state,
        create_goals_bsc: { ...state.create_goals_bsc, goal_theme: payload },
      };
    case "PEFORMANCE_OBJECTIVE_GOAL":
      // console.log(payload);
      return {
        ...state,
        create_goals: { ...state.create_goals, set_goals: payload },
      };
    case "PEFORMANCE_OBJECTIVE_GOAL_BSC":
      // console.log(payload);
      return {
        ...state,
        create_goals_bsc: { ...state.create_goals_bsc, set_goals: payload },
      };
    case "PEFORMANCE_OBJECTIVE_GOAL2":
      // console.log({ payload });
      return {
        ...state,
        create_goals: { ...payload },
      };

    case "PEFORMANCE_OBJECTIVE_GOAL2_BSC":
      // console.log({ payload });
      return {
        ...state,
        create_goals_bsc: { ...payload },
      };
    case "DEPARTMENT_GOAL_HOD":
      // console.log({ payload });
      return {
        ...state,
        get_create_goals: { ...payload },
      };
    case "CLEAR_CREATE_GOALS":
      return {
        ...state,
        create_goals: {
          year: "",
          performance_tool: "MBO",
          set_goals: [
            {
              goal: "",
              weight: "",
              description: "",
              unit: "",
            },
          ],
        },
      };
    case "CLEAR_CREATE_GOALS_BSC":
      return {
        ...state,
        create_goals_bsc: {
          year: "",
          performance_tool: "BSC",
          set_goals: [
            {
              goal: "Financial goals",
              weight: "",
              description: "",
              unit: "%",
            },
            {
              goal: "Customer goals",
              weight: "",
              description: "",
              unit: "%",
            },
            {
              goal: "Internal processes",
              weight: "",
              description: "",
              unit: "%",
            },
            {
              goal: "Learning and growth",
              weight: "",
              description: "",
              unit: "%",
            },
          ],
        },
      };
    case "CLEAR_USERS_ERRORS":
      return {
        ...state,
        message: null,
        status: null,
        errors: {},
      };
    case "START_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "STOP_SPINNER":
      return {
        ...state,
        spinner: false,
      };

    case "LOGOUT_USER":
      return {
        ...state,
        spinner: false,
        is_Loggedin: false,
        status: payload.status,
        message: payload.message,
      };
    case "USER_REGISTRATION_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        status: payload.status,
        business_id: payload.payload.business_id,
      };
    case "REMOVE_NOK_INFO": {
      const r_id = payload;
      const new_user_profile = { ...state.user_profile };
      const nok_info = new_user_profile.next_of_kin;
      const new_nok_info = nok_info.filter(
        (nok) => parseInt(nok.id) !== parseInt(r_id)
      );
      new_user_profile.next_of_kin = new_nok_info;

      return {
        ...state,
        user_profile: new_user_profile,
      };
    }
    case "REMOVE_DEPENDANT_INFO": {
      const r_id = payload;
      const new_user_profile = { ...state.user_profile };
      const dependate_info = new_user_profile.dependant;
      const new_dependate_info = dependate_info.filter(
        (dependate) => parseInt(dependate.id) !== parseInt(r_id)
      );
      new_user_profile.dependant = new_dependate_info;

      return {
        ...state,
        user_profile: new_user_profile,
      };
    }

    case "SET_CURRENT_SETUP": {
      if (back) {
        return {
          ...state,
          current_quick_setup: {
            ...state.quick_setup[payload],
            required: !back,
          },
        };
      } else {
        return {
          ...state,
          current_quick_setup: { ...state.quick_setup[payload] },
        };
      }
    }
    case "QUICK_SETUP_LOGO_UPLOAD": {
      let new_setup = { ...state.current_quick_setup };
      if (new_setup?.field) {
        new_setup.field.file = payload;
        new_setup.required = false;
      }
      return {
        ...state,
        current_quick_setup: { ...new_setup },
      };
    }
    case "QUICK_SETUP_COMP_DETAILS": {
      let new_setup = { ...state.current_quick_setup };

      if (new_setup?.field) {
        let tkey = Object.keys(payload)[0];
        let tvalue = Object.values(payload)[0];
        // new_setup.field[tkey] = payload[tkey];
        new_setup.field[tkey] = tvalue;

        // this part is optional
        let loop = new_setup.field;
        let required = false;
        for (var key in loop) {
          if (loop.hasOwnProperty(key)) {
            if (
              !loop.hasOwnProperty("mobile_number") &&
              (loop[key] === "" || loop[key] === null)
            ) {
              required = true;
              break;
            }
          }
        }

        new_setup.required = required;
        //--end optional

        // new_setup.required = false;
      }
      return {
        ...state,
        current_quick_setup: { ...new_setup },
      };
    }
    case "QUICK_SETUP_THEME": {
      let new_setup = { ...state.current_quick_setup };
      if (new_setup?.field) {
        let tkey = Object.keys(payload)[0];
        let tvalue = Object.values(payload)[0];
        new_setup.field[tkey] = tvalue;
      }
      return {
        ...state,
        current_quick_setup: { ...new_setup },
      };
    }
    case "QUICK_SETUP_PASS_SETTINGS": {
      let new_setup = { ...state.current_quick_setup };
      if (new_setup?.field) {
        let tkey = Object.keys(payload)[0];
        new_setup.field[tkey] = payload[tkey];
      }
      return {
        ...state,
        current_quick_setup: { ...new_setup },
      };
    }

    case "QUICK_SETUP_EMP_PROFILE": {
      let new_setup = { ...state.current_quick_setup };
      if (new_setup?.field) {
        new_setup.field.file = payload;
      }
      return {
        ...state,
        current_quick_setup: { ...new_setup },
      };
    }
    case "ACTIVE_MODULES_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        active_modules: payload.payload,
      };

    case "ACTIVE_MODULES_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "PERFORMANCE_SETTINGS_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        performance_setup: payload.payload,
      };

    case "PERFORMANCE_SETTINGS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "PERFORMANCE_STAT_SUCCESS":
      return {
        ...state,
        performance_stats: payload.payload,
      };

    case "PERFORMANCE_STAT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "PERFORMANCE_SETUP_SUCCESS":
      return {
        ...state,
        performance_setup: payload.payload,
      };

    case "PERFORMANCE_SETUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "GET_SCHEDULED_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        sys_scheduled_event: payload.payload,
      };
    case "GET_SCHEDULED_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CREATE_NEW_DEPT_GOAL_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // sys_scheduled_event: payload.payload,
      };
    case "CREATE_NEW_DEPT_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_NEW_HIRE_OVERVIEW_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        new_hire_overview: payload.payload,
      };
    case "GET_NEW_HIRE_OVERVIEW_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "LOGO_UPDATE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // active_modules: payload.payload,
      };
    case "LOGO_UPDATE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "GENERIC_ACTION_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
      };
    case "GENERIC_ACTION_ALERT_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "GENERIC_ACTION_ERROR":
      return {
        ...state,
        message: payload?.message || "Something went wrong",
        status: payload?.status || "error",
        errors: payload?.errors || {},
      };
    case "SCHEDULED_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // active_modules: payload.payload,
      };
    case "SCHEDULED_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ALL_EMPLOYEE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        employee_count_det: {
          target_employee_count: payload.payload.target_employee_count,
          current_employee_count: payload.payload.current_employee_count,
          total_active: payload.payload.total_active,
          total_inactive: payload.payload.total_inactive,
        },
      };
    case "ALL_EMPLOYEE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "SETTINGS_UTIL_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        settings_util: payload.payload,
      };
    case "SETTINGS_UTIL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "CREATE_NEW_GOAL_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        sub_create_goals: payload.payload,
      };
    case "CREATE_NEW_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ROLE_DEL_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        rol_del: payload.payload,
      };
    case "ROLE_DEL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "FINANCIAL_YEAR_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        financial_year: payload.payload,
      };
    case "FINANCIAL_YEAR_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ADD_EDIT_DEPT_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_HOLIDAY_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_EVENT_TYPE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_ANNIVERSARY_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_DESIGNATION_ROLE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // settings_util: payload.payload,
      };
    case "ADD_EDIT_HOLIDAY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GENERAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GENERAL_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ADD_EDIT_ANNIVERSARY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_EDIT_DESIGNATION_ROLE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DEPARTMENT_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        departments: payload.payload,
      };
    case "BIRTHDAY_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        birthdays: payload.payload,
      };
    case "HOLIDAY_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        holidays: payload.payload,
      };
    case "EVENT_TYPE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        event_type: payload.payload,
      };
    case "COMBINED_EVENTS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        combined_events: payload.payload,
      };
    case "ANNIVERSARY_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        anniversaries: payload.payload,
      };
    case "DESIGNATION_ROLE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        designation_role: payload.payload,
      };
    case "SYS_BANKS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        system_banks: payload.payload,
      };

    case "ADD_TO_DEPARTMENT": {
      const new_arry = [...state.departments, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        departments: new_arry,
      };
    }

    case "ADD_TO_GRADES": {
      const new_arry = [...state.all_system_grades, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_system_grades: new_arry,
      };
    }
    case "ADD_TO_LEAVE_GROUP": {
      const new_arry = [...state.leave_group, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        leave_group: new_arry,
      };
    }
    case "ADD_TO_LEAVE_GROUP_DISTRIBUTION": {
      const new_arry = [...state.leave_group_distribution, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        leave_group_distribution: new_arry,
      };
    }
    case "ADD_TO_BANK": {
      const new_arry = [...state.system_banks, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        system_banks: new_arry,
      };
    }
    case "ADD_TO_HOLIDAYS": {
      const new_arry = [...state.holidays, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        holidays: new_arry,
      };
    }
    case "ADD_TO_ANNIVERSARIES": {
      const new_arry = [...state.anniversaries, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        anniversaries: new_arry,
      };
    }
    case "ADD_TO_DESIGNATION_ROLE": {
      const new_arry = [...state.designation_role, payload];

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        designation_role: new_arry,
      };
    }
    case "UPDATE_TO_DEPARTMENT": {
      const new_arry = [...state.departments];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        departments: new_arry,
      };
    }

    case "UPDATE_TO_GRADES": {
      const new_arry = [...state.all_system_grades];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_system_grades: new_arry,
      };
    }
    case "UPDATE_TO_LEAVE_GROUP": {
      const new_arry = [...state.all_system_grades];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_system_grades: new_arry,
      };
    }
    case "UPDATE_TO_BANK": {
      const new_arry = [...state.system_banks];
      console.log({ new_arry });
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }
      // console.log({ new_arry });
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        system_banks: new_arry,
      };
    }
    case "UPDATE_TO_HOLIDAYS": {
      const new_arry = [...state.holidays];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        holidays: new_arry,
      };
    }
    case "UPDATE_TO_EVENT_TYPE": {
      const new_arry = [...state.event_type];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        event_type: new_arry,
      };
    }
    case "UPDATE_TO_ANNIVERSARIES": {
      const new_arry = [...state.anniversaries];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        anniversaries: new_arry,
      };
    }
    case "UPDATE_TO_DESIGNATION_ROLE": {
      const new_arry = [...state.designation_role];
      const index = new_arry.findIndex(
        (obj) => parseInt(obj.id) === parseInt(payload.id)
      );

      if (index) {
        new_arry[index] = payload;
      }

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        designation_role: new_arry,
      };
    }
    case "REMOVE_FROM_DEPT": {
      const new_arry = [...state.departments];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        departments: filtered,
      };
    }
    case "REMOVE_FROM_LEAVE_GROUP_DIS": {
      const new_arry = [...state.leave_group_distribution];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        leave_group_distribution: filtered,
      };
    }
    case "REMOVE_FROM_LEAVE_GROUP": {
      const new_arry = [...state.leave_group];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        leave_group: filtered,
      };
    }
    case "REMOVE_FROM_GRADE": {
      const new_arry = [...state.all_system_grades];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_system_grades: filtered,
      };
    }
    case "REMOVE_FROM_BANK": {
      const new_arry = [...state.system_banks];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        system_banks: filtered,
      };
    }
    case "REMOVE_FROM_HOLIDAYS": {
      const new_arry = [...state.holidays];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        holidays: filtered,
      };
    }
    case "REMOVE_FROM_ANNIVERSARIES": {
      const new_arry = [...state.anniversaries];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        anniversaries: filtered,
      };
    }
    case "REMOVE_FROM_DESIGNATION_ROLE": {
      const new_arry = [...state.designation_role];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        designation_role: filtered,
      };
    }
    case "DEPARTMENT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "BIRTHDAY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "HOLIDAY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "EVENT_TYPE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "COMBINED_EVENTS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ANNIVERSARY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DESIGNATION_ROLE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "SYS_BANKS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "SETTINGS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        settings: payload.payload,
      };
    case "SETTINGS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "CLIENT_PERMISSIONS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        client_permissions: payload.payload,
      };
    case "CLIENT_PERMISSIONS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "COMPANY_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        in_nessages: payload.payload,
      };
    case "COMPANY_NOTIFICATIONS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "MARK_READ_SUCCESS": {
      const read = payload.payload;
      const all_messages = [...state.in_nessages];
      const new_messages = all_messages.filter((mesg) => mesg.id !== read.id);
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        in_nessages: new_messages,
      };
    }
    case "MARK_READ_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DETACHED_MODULES_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        detached_modules: payload.payload,
      };
    case "DETACHED_MODULES_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ONBOARBING_SETUP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // detached_modules: payload.payload,
      };
    case "ONBOARBING_SETUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "GET_ONBOARDING_TO_CAT_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        cat_to_onboarding: payload.payload,
      };
    case "DOCUMENT_UPLOAD_MAN": {
      const new_obj = { ...state.cat_to_onboarding };
      const targeted = new_obj[payload.setting];
      console.log({ new_obj, targeted });
      if (targeted) {
        targeted.push(payload);
        new_obj[payload.setting] = targeted;
      } else {
        new_obj[payload.setting] = [payload];
        // console.log(new_obj);
      }
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        cat_to_onboarding: new_obj,
      };
    }
    case "GET_ONBOARDING_TO_CAT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_ONBOARDING_FILE": {
      const all_file = { ...state.cat_to_onboarding };
      const targeted = all_file[payload.type];
      let updated_targeted = targeted.filter(
        (item) => parseInt(item.id) !== parseInt(payload.id)
      );

      all_file[payload.type] = updated_targeted;

      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        cat_to_onboarding: all_file,
      };
    }

    case "ONBOARBING_ACTION_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // cat_to_onboarding: payload.payload,
      };
    case "ONBOARBING_ACTION_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_ONBOARD_PROFILE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        hire_profile: payload.payload,
      };
    case "GET_ONBOARD_PROFILE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "SUBMIT_PREFILL_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // cat_to_onboarding: payload.payload,
      };
    case "SUBMIT_PREFILL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "COMPANY_DETAILS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        company_details: payload.payload,
      };
    case "COMPANY_DETAILS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "USER_OFFLINE_USERS":
      return {
        ...state,
        message:
          "Seem you are offline! Kindly check your internet connection and try again.",
        status: "error",
      };
    case "NO_FILE_UPLOAD":
      return {
        ...state,
        message: "kindly upload a file to be upload",
        status: "error",
      };

    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        is_Loggedin: payload?.payload?.token ? true : false,
      };
    case "USER_SETUP_LOGIN_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        is_Loggedin: payload?.payload?.data?.token ? true : false,
      };
    case "USER_RESET_LOGIN_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // is_Loggedin: payload?.payload?.data?.token ? true : false,
      };

    case "USER_LOGIN_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_NEW_HIRE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // is_Loggedin: payload?.payload?.data?.token ? true : false,
      };

    case "ADD_NEW_HIRE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_NEW_HIRE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        new_hire: payload?.payload,
      };

    case "GET_NEW_HIRE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };

    case "NEW_HIRE_ADDED_MAN": {
      const new_arry = [...state.new_hire];

      return {
        ...state,
        new_hire: [...new_arry, payload],
      };
    }
    case "DEL_HIRE_LIST_MAN": {
      const new_arry = [...state.new_hire];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );
      return {
        ...state,
        new_hire: [...filtered],
      };
    }
    case "DEL_HIRE_LIST_DEL_MAN": {
      const new_arry = [...state.new_hire_del];
      const filtered = new_arry.filter(
        (obj) => parseInt(obj.id) !== parseInt(payload)
      );
      return {
        ...state,
        new_hire_del: [...filtered],
      };
    }

    case "GET_NEW_HIRE_DEL_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        new_hire_del: payload?.payload,
      };

    case "GET_NEW_HIRE_DEL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "SET_SETTINGS_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "SET_SETTINGS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };

    case "PASSWORD_CHANGE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "PASSWORD_CHANGE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CONTACT_DETAILS_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "CONTACT_DETAILS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "PROFILE_PIC_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "PROFILE_PIC_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "SIGNATURE_PIC_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "SIGNATURE_PIC_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CLIENT_SUBCRIPTION_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        client_subcription: payload.payload,
      };

    case "CLIENT_SUBCRIPTION_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };

    case "USER_QUICK_SETUP_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
      };

    case "USER_QUICK_SETUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };

    case "BULK_UPLOAD_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "BULK_UPLOAD_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        is_Loggedin: payload?.payload?.token ? true : false,
      };

    case "USER_LOGOUT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "RESET_LINK_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "RESET_LINK_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_TO_CAT_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "ADD_TO_CAT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_FROM_CAT_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_FROM_BANK_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_HOLIDAY_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_ANNIVERSARY_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_DESIGNATION_ROLE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "DELETE_FROM_CAT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_FROM_BANK_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_HOLIDAY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_ANNIVERSARY_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_DESIGNATION_ROLE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_PROFILE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        user_profile: payload.data,
      };
    case "USER_PROFILE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CLIENT_AUDITRAIL_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        client_auditlogs: payload,
      };
    case "CLIENT_AUDITRAIL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_PENDING_REQUEST_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        user_profile_pending_request: payload.payload,
      };
    case "USER_PENDING_REQUEST_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_DEFAULT_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        user_profile_default: payload.payload,
      };
    case "USER_DEFAULT_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_PENDING_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        user_profile_pending: payload.payload,
      };
    case "USER_PENDING_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ONB_CURRENT_STEP":
      return {
        ...state,
        onb_step: payload,
      };
    case "RESET_SAVE_TEMPLATE":
      return {
        ...state,
        sel_temp: {},
        temp_activities: [],
        temp_activity: {},
        onb_step: 0,
      };
    case SAVE_TEMPLATE:
      return {
        ...state,
        sel_temp: payload,
      };
    case SAVE_ACTIVITIES:
      return {
        ...state,
        temp_activities: payload,
      };
    case "INGORE_ACT": {
      const new_arry = [...state.temp_activities];
      const filtered = new_arry.filter(
        (item) => parseInt(item.id) !== parseInt(payload)
      );
      // console.log(payload, filtered);
      return {
        ...state,
        temp_activities: filtered,
      };
    }
    case SAVE_ACTIVITY: {
      console.log({ payload });
      const new_arry = [...state.temp_activities];
      const foundIndex = new_arry.findIndex(
        (act) => parseInt(act.id) === parseInt(payload.id)
      );

      new_arry[foundIndex] = payload;

      console.log({ new_arry, old: state.temp_activities });

      return {
        ...state,
        temp_activity: payload,
        temp_activities: [...new_arry],
      };
    }
    default:
      return state;
  }
};

export default userReducer;
