import { useParams } from "react-router-dom";
import { FileFilled } from "@ant-design/icons";
import { HiUpload } from "react-icons/hi";
import styles from "../../../styles/employee/Profile.module.css";
import { MdOutlineRemoveCircle } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";

import { useState } from "react";

import { Modal } from "antd";
import { rejectUserDoc } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

function DocumentLists() {
  const { category } = useParams();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { list, modal, refetch } = useOutletContext();

  const arry_list = list.filter((item) => item.category === category);

  const confirm = (id) => {
    setLoading(true);

    setShowModal(false);
    rejectUserDoc(dispatch, { doc_id: id }).then((res) => {
      setLoading(false);

      if (res?.status === "success") {
        refetch();
      }
    });
  };

  const modalTrigger = (name, id) => {
    Modal.confirm({
      title: `Remove ${name}`,
      content: `Are you sure you want to remove ${name} ?`,
      onOk: () => confirm(id),
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const previewHandler = () => {
    setPreviewModal(!previewModal);
  };

  if (category) {
    return (
      <>
        <div className="mt-3 d-flex justify-content-between">
          <div>
            <h3 className="h4">{category}</h3>
            <p className="h6 mb-0">
              {arry_list[0]?.documents?.length} Documents
            </p>
          </div>
          <button className={styles.uploadBtn} onClick={modal}>
            <HiUpload />
            Upload
          </button>
        </div>
        <hr />
        <div className="document-lists">
          {arry_list[0]?.documents.map((item) => {
            return (
              <div className="card bg-white p-2 document-item">
                <button
                  className="document-delete"
                  onClick={() => modalTrigger(item.name, item.id)}
                >
                  {loading ? <LoadingOutlined /> : <MdOutlineRemoveCircle />}
                </button>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={previewHandler}
                >
                  <FileFilled />
                </span>
                <p
                  className="mt-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={previewHandler}
                >
                  {item?.name}
                </p>
                <p
                  className="mt-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={previewHandler}
                >
                  {item?.created_at.split("T")[0]}
                </p>
                <Modal
                  open={previewModal}
                  onCancel={previewHandler}
                  footer={null}
                  centered
                >
                  {item?.document.match(/\.[^/.]*$/)[0] === ".pdf" ? (
                    <object
                      data={item.document}
                      type="application/pdf"
                      width="100%"
                      height={500}
                    >
                      <p>
                        Unable to display PDF file.{" "}
                        <a href={item.document} download>
                          Download
                        </a>{" "}
                        instead.
                      </p>
                    </object>
                  ) : (
                    <img src={item.document} alt="document" />
                  )}
                  {/* <object
                    data={item.document}
                    type="application/pdf"
                    width="100%"
                    height={500}
                  >
                    <p>
                      Unable to display PDF file.{" "}
                      <a href={item.document} download>
                        Download
                      </a>{" "}
                      instead.
                    </p>
                  </object> */}
                </Modal>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default DocumentLists;
