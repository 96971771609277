import React, { useEffect, useState, useMemo } from "react";
import { Button, Space, Table, Input, Pagination } from "antd";

import { useGetIDCardALL } from "../../../util/usershookActions";
import { useUserCleanUp } from "../../../util/hooks";

import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import { AiOutlineSearch } from "react-icons/ai";

import HocHeader from "../../ui/HocHeader";

function AllIDRequest() {
  useUserCleanUp();
  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
      render: (value) => {
        return value ? value : "N/A";
      },
      ...getColumnSearchProps("employee_id", "Employee ID"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status", "Status"),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Identity Number",
      dataIndex: "identity_number",
      key: "identity_number",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Issued Date",
      dataIndex: "date_issued",
      key: "date_issued",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
  ];

  const [enabled_req, setEnabledReq] = useState(true);
  const [page_req, setPageReq] = useState(1);
  const [req_show, setReqShow] = useState([]);

  const { data: req, isLoading } = useGetIDCardALL(
    enabled_req,
    setEnabledReq,
    page_req
  );

  const req_data = useMemo(() => req?.payload?.data, [req]);
  console.log({ req_show });

  useEffect(() => {
    if (req_data?.length) {
      const new_arry = req_data.map((re, ind) => {
        return {
          ...re,
          key: re.user_id,
          index: ind + 1,
        };
      });

      setReqShow(new_arry);
    } else {
      setReqShow([]);
    }
  }, [req_data]);

  function handlePaginationEmp(page) {
    setPageReq(page);
    setEnabledReq(true);
  }

  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="All ID Card Requests"
          module_title="(ID) Identity Card Management"
          module_description="lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip"
        />
      </div>
      <div className="container-fluid py-3 padding_3rem">
        <section className="app__section mt-3 py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available requests</h5>
              {/* <small className="w-75 d-none d-sm-block">
                Every employee belongs to at least one job role/grade. You can
                add and view all job roles with other details like gross salary
                range, title, benefits and category.
              </small> */}
            </div>
            {/* <Link to={"add"}>
            <p className="performance__bagde2">Add Job Role/Grade</p>
          </Link> */}
          </div>
          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center">
              <div className="circle__bagde fw-bold">{req_show.length}</div>
              <h6 className="mb-0"> Requests</h6>
            </div>
          </div>

          <div
            className="row"
            style={{
              padding: "1rem",
            }}
          >
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : req_show.length ? (
              <>
                <Table
                  columns={columns}
                  dataSource={req_show}
                  bordered
                  scroll={{ x: "768px" }}
                  pagination={false}
                />

                <div className="mt-3 d-flex justify-content-end">
                  {req?.payload?.meta?.last_page > 1 ? (
                    <Pagination
                      total={req?.payload?.meta?.last_page}
                      pageSize={1}
                      onChange={handlePaginationEmp}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <NoContent width={"400px"} message="No request available" />
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default AllIDRequest;
