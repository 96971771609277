import React from "react";

function Employment() {
  return (
    <div className="px-3">
      <header className="onboarding-header d-flex align-items-center justify-content-between mb-5">
        <h2>Employment Management</h2>
      </header>
    </div>
  );
}

export default Employment;
