import { notification } from "antd";
import { dehashData } from "./hash";
import { getSubdomain } from "tldts";

function notificationError(description, duration = 5) {
  notification.error({
    message: "Oops! something went wrong",
    description: description,
    duration,
    placement: "top",
  });
}

function notificationSuccess(description, duration = 5) {
  notification.success({
    message: "Success",
    description: description,
    duration,
    placement: "top",
  });
}

function formatNumbers(number) {
  if (number.toString().length > 0) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

function convertToCapitalize(sentence) {
  if (typeof sentence === 'string') {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  } else {
    return "";
  }
}

function canAccess(permission) {
  // const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;
  // const { abilities } = dehashData(AUTH_DETAILS);

  const abilities = userAbilities();

  // return abilities.includes(permission);
  return true;
}

function getURLSubdomain() {
  const url = document.URL;
  const subdomain = getSubdomain(url);
  const subdomainArray = subdomain !== null && subdomain.split(".");

  let companyURL;

  if (subdomainArray.length === 1) {
    companyURL = null;
  } else {
    companyURL = subdomainArray[0];
  }
  return companyURL;
}

const paginate = (items, pageNumber, pageSize) => {
  const startIndex = (pageNumber - 1) * pageSize;

  return items.slice(startIndex, startIndex + pageSize);
};

function userAbilities() {
  const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;
  const { abilities } = dehashData(AUTH_DETAILS) ?? { abilities: [] };
  return abilities;
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}
const typesArry = [
  { key: "Text", value: "text" },
  { key: "Number", value: "number" },
  { key: "Percentage", value: "percentage" },
];

const colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export {
  notificationError,
  notificationSuccess,
  formatNumbers,
  convertToCapitalize,
  canAccess,
  getURLSubdomain,
  paginate,
  userAbilities,
  colorArray,
  formatMoney,
  typesArry,
};
