import { Button, Modal } from "antd";
import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { updateUserNOKInfo } from "../../../redux/users/userActions";
import data from "../../../util/util.json";
import { useGetUserProfile } from "../../../util/usershookActions";
import NOKCard from "./NOKCard";
import { MdOutlineAdd, MdOutlineFamilyRestroom } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import Warning from "../../ui/Warning";

const { next_of_kin_relationship } = data;

export default function NOK() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  useGetUserProfile(enabled, setEnabled);
  const dispatch = useDispatch();
  const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);
  const next_of_kin = userProfile?.next_of_kin;
  const init_details = {
    name: "",
    email: "",
    relationship: "",
    mobile_number: "",
    telephone: "",
    home_address: "",
  };

  const callbackFn = () => {
    values.user_id = userProfile?.id;

    updateUserNOKInfo(dispatch, values).then((res) => {
      if (res?.status === "success") {
        clearForm();
        setEnabled(true);
        setShowModal(false);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className={`${styles.title} greeting_2`}>
            <div>
              <h4>Next of Kin Information</h4>
              <p>Review and update your next of kin information</p>
            </div>
            <MdOutlineFamilyRestroom />
            {/* <Button onClick={modalHandler} size="large">
              Add Next of Kin
            </Button> */}
          </div>
        </div>
        <Modal
          okText={"Add"}
          open={showModal}
          onCancel={modalHandler}
          footer={null}
          centered
        >
          <h5 className="text-dark mb-4 d-flex align-items-center gap-2">
            {<MdOutlineAdd />} Add Next of Kin
          </h5>
          {Object.keys(utils).length && utils?.others["Approval"].length
            ? utils.others["Approval"].map((item) => {
                if (item.name === "Next Of Kin" && item.deleted_at === null) {
                  return <Warning />;
                } else {
                  return null;
                }
              })
            : null}
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 d-flex flex-column">
                <label htmlFor="name">Name</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.name || (back_errors.name && back_errors.name[0])}
                  </small>
                </div>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  id="name"
                />
              </div>
              <div className="col-12 d-flex flex-column mt-3">
                <label htmlFor="email">Email</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.email ||
                      (back_errors.email && back_errors.email[0])}
                  </small>
                </div>
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  id="email"
                />
              </div>
              <div className="col-12 d-flex flex-column mt-3">
                <label htmlFor="relationship">Relationship</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.relationship ||
                      (back_errors.relationship && back_errors.relationship[0])}
                  </small>
                </div>
                <select
                  name="relationship"
                  onChange={handleChange}
                  value={values.relationship}
                  id="relationship"
                >
                  <option value="">--</option>
                  {next_of_kin_relationship.map((nok) => (
                    <option key={nok.id} value={nok.key}>
                      {nok.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 d-flex flex-column mt-3">
                <label htmlFor="mobile_number">Mobile Number</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.mobile_number ||
                      (back_errors.mobile_number &&
                        back_errors.mobile_number[0])}
                  </small>
                </div>
                <input
                  type="number"
                  name="mobile_number"
                  onChange={handleChange}
                  value={values.mobile_number}
                  id="mobile_number"
                />
              </div>
              <div className="col-12 d-flex flex-column mt-3">
                <label htmlFor="telephone">Telephone</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.telephone ||
                      (back_errors.telephone && back_errors.telephone[0])}
                  </small>
                </div>
                <input
                  type="number"
                  name="telephone"
                  onChange={handleChange}
                  value={values.telephone}
                  id="telephone"
                />
              </div>

              <div className="col-12 d-flex flex-column mt-3">
                <label htmlFor="home_address">Home Address</label>
                <div className="w-100 text-right text-danger">
                  <small className="text-danger">
                    {errors.home_address ||
                      (back_errors.home_address && back_errors.home_address[0])}
                  </small>
                </div>
                <textarea
                  name="home_address"
                  onChange={handleChange}
                  value={values.home_address}
                  id="telephone"
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                loading={userSpinner}
                htmlType="submit"
                className="myBtn mt-3"
              >
                Add
              </Button>
            </div>
          </form>
        </Modal>
        <div className="col-md-12 p-5">
          <div className="row">
            {next_of_kin?.length ? (
              next_of_kin.map((nok) => <NOKCard key={nok.id} info={nok} />)
            ) : (
              <div className="no-content-box">
                <img src="/assets/family.jpg" alt="no content illustration" />
                <p className="my-2">
                  You are yet to add Next of Kin information.
                </p>
                <Button onClick={modalHandler} className={styles.uploadBtn}>
                  <HiUpload />
                  Add Next of Kin
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
