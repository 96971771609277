import React, { useEffect, useState } from "react";
import BasicInfo from "../../hrms/employees/new-employee/BasicInfo";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { createUserPersonalInfo } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import {
  useGetOnboardUserProfile,
  useGetUserAutoID,
  // useGetUserProfile,
} from "../../../util/usershookActions";
import { user_errors } from "../../../util/selectors/userSelectors";
import data from "../../../util/util.json";
import PrefillProfile from "../newHire/PrefillProfile";
import NoContent from "../../ui/NoContent";

const { employment } = data;

const personal_arry = [
  "nationality",
  "state_of_origin",
  "province_lga",
  "religion",
  "gender",
  "date_of_birth",
  "title",
  "first_name",
  "last_name",
  "other_names",
  "marital_status",
  "employee_id",
  "employment_type",
  "employment_date",
  "department",
  "job_role",
];

const contact_arry = [
  "mobile_number",
  "primary_email",
  "secondary_email",
  "home_address",
  "telephone",
];
const grading_arry = ["job_role_id", "step_id", "grade_id", "salary"];

const CreateAccount = ({ record }) => {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [enabledPro, setEnabledPro] = useState(true);
  const [auto_gen, setAutoGen] = useState({});
  const [personal, setPersonal] = useState({});
  const [contact, setContact] = useState({});
  const [grading, setGrading] = useState({});
  const [save_spinner, setSaveSpinner] = useState(false);

  const { data: auto_id, refetch: refetchAuto } = useGetUserAutoID(
    enabled,
    setEnabled
  );

  let { data: hireProfile } = useGetOnboardUserProfile(
    enabledPro,
    setEnabledPro,
    record.id
  );

  // console.log({ hireProfile });
  const back_errors = useShallowEqualSelector(user_errors);

  useEffect(() => {
    if (auto_id?.payload) {
      setAutoGen(auto_id.payload);
    }
  }, [auto_id]);

  const init_details = {
    nationality: "",
    state_of_origin: "",
    province_lga: "",
    religion: "",
    gender: "",
    date_of_birth: "",
    title: "",
    first_name: "",
    last_name: "",
    other_names: "",
    marital_status: "",
    employee_id: "",
    employment_type: "",
    employment_date: "",
    mobile_number: "",
    primary_email: "",
    secondary_email: "",
    home_address: "",
    telephone: "",
    department: "",
    salary: "",
    job_role_id: "",
    step_id: "",
    grade_id: "",
  };

  const callbackFn = () => {
    setSaveSpinner(true);
    personal.employee_id = `${auto_gen?.current_year}-${auto_gen?.next_index}`;

    const creds = {
      personal: [{ ...personal }],
      contact: [{ ...contact }],
      grade_level: [{ ...grading }],
    };
    createUserPersonalInfo(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        clearForm();
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  function firstOnchange(e, action, name, value) {
    // console.log(e.target.name);

    if (action) {
      if (personal_arry.includes(name)) {
        setPersonal((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (contact_arry.includes(name)) {
        setContact((prevState) => {
          return { ...prevState, [name]: value };
        });
      } else if (grading_arry.includes(name)) {
        setGrading((prevState) => {
          return { ...prevState, [name]: value };
        });
      }
    } else {
      if (personal_arry.includes(e.target.name)) {
        setPersonal((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (contact_arry.includes(e.target.name)) {
        setContact((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      } else if (grading_arry.includes(e.target.name)) {
        setGrading((prevState) => {
          return { ...prevState, [e?.target.name]: e?.target.value };
        });
      }
    }

    handleChange(e, action, name, value);
  }
  return (
    <div className="container">
      <div className="container">
        <div className="row">
          <div className="col-md-5 bg-light">
            <h4>Prefill Information</h4>
            {hireProfile?.payload["pre-fill-form"] ? (
              <PrefillProfile hireProfile={hireProfile} />
            ) : (
              <NoContent
                message={"Hire has not submitted prefill information yet."}
                width={400}
              />
            )}
          </div>
          <div className="col-md-7">
            <div
              className="container"
              style={{
                maxHeight: 700,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <h4>Create Employee Account</h4>
              <BasicInfo
                values={values}
                errors={errors}
                handleChange={firstOnchange}
                handleSubmit={handleSubmit}
                clearForm={clearForm}
                back_errors={back_errors}
                save_spinner={save_spinner}
                auto_gen={auto_gen}
                employment={employment}
                onboarding={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
