import { Button, List, Modal } from "antd";
import { useEffect, useState } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { useGetSystemBanks } from "../../../../util/usershookActions";
import {
  addEditBanks,
  uploadMultiple,
} from "../../../../redux/users/userActions";
import {
  sys_banks,
  user_errors,
} from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import BankEachList from "./BankEachList";
import FileUpload from "../../../ui/FileUpload";

const Banks = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [addBank, setAddBank] = useState(false);
  const [values, setValues] = useState({
    name: "",
    code: "",
  });
  const [file, setFile] = useState();

  const { refetch } = useGetSystemBanks(enabled, setEnabled);
  const allBanks = useShallowEqualSelector(sys_banks);
  const back_errors = useShallowEqualSelector(user_errors);

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const handleMultipleModal = (e) => {
    e.preventDefault();
    setShowUploadModal(!showUploadModal);
  };

  const handleAddBank = () => {
    clearSelected();
    setAddBank(!addBank);
  };

  const handleSelected = (item) => {
    // console.log(item);
    setSelected(item);
    setValues(item);
    setAddBank(true);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const clearSelected = () => {
    setSelected({});
    setValues({
      name: "",
      code: "",
    });
  };

  useEffect(() => {
    if (allBanks.length) {
      setData(allBanks);
    } else {
      setData([]);
    }
  }, [allBanks]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(values);
    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditBanks(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          name: "",
          code: "",
        });

        if (Object.keys(selected).length) {
          dispatch({
            type: "UPDATE_TO_BANK",
            payload: res.payload.bank,
          });
        } else {
          dispatch({
            type: "ADD_TO_BANK",
            payload: res.payload.bank,
          });
        }
        setAddBank(false);
      }
    });
  };

  function handleUpload(e) {
    e.preventDefault();
    const url = "hrm/preferences/bank/excel/upload";
    setLoading(true);
    const creds = { file };
    uploadMultiple(dispatch, url, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        setFile(null);
      }
    });
  }

  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Banks</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div className="category">
        <div className="row">
          <div className="col-md-5">
            <small>Create, view and edit your banks information </small>
          </div>
          <div className="col-md-7 gap-1 d-flex align-items-center justify-content-end">
            <Button
              type="text"
              style={{ width: "fit-content" }}
              onClick={handleModal}
            >
              Show All Banks
            </Button>
            <Button
              type="text"
              style={{ width: "fit-content" }}
              onClick={handleMultipleModal}
            >
              Upload Multiple
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        footer={null}
        centered
        onCancel={handleModal}
        width={700}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3> All Banks</h3>

            {addBank ? (
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="name">Name</label>
                        <div className="w-100 text-right text-danger d-flex justify-content-end">
                          <small>
                            {back_errors.name && back_errors.name[0]}
                          </small>
                        </div>
                      </div>

                      <input
                        placeholder="Bank Name"
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="code" className="w-100">
                          Bank Code
                        </label>
                        <div className="w-100 text-right text-danger d-flex justify-content-end">
                          <small>
                            {back_errors.code && back_errors.code[0]}
                          </small>
                        </div>
                      </div>

                      <input
                        type="text"
                        id="code"
                        name="code"
                        value={values.code || ""}
                        onChange={handleChange}
                        placeholder="Bank's code"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <div>
                    <Button
                      className="myBtn mt-3 mx-2"
                      onClick={() => {
                        clearSelected();
                        handleAddBank();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="myBtn  mt-3"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            ) : (
              <div
                style={{
                  maxHeight: 500,
                  overflowY: "auto",
                }}
                className="my-3"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <BankEachList
                      item={item}
                      handleSelected={handleSelected}
                      refetch={refetch}
                    />
                  )}
                />
              </div>
            )}
            <div className="d-flex justify-content-end">
              {!addBank && (
                <Button className="myBtn mt-3" onClick={handleAddBank}>
                  Add Bank
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showUploadModal}
        footer={null}
        centered
        onCancel={handleMultipleModal}
        width={700}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3> Upload Multiple Banks</h3>
            <div className="mt-5">
              <FileUpload
                file={file}
                setFile={setFile}
                accept={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                supportedFormats={"xls, xlsx"}
                type={"excel"}
              />
              <div className="d-flex gap-2 justify-content-end my-3">
                <a href="/assets/bank_excel.xlsx" download={true}>
                  <Button className="myBtn ">Download Template</Button>
                </a>
                <Button
                  className="myBtn "
                  onClick={handleUpload}
                  disabled={!file}
                  loading={loading}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Banks;
