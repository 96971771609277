import React, { useState, useEffect } from "react";
import { Modal, List, Switch, Button } from "antd";
import { useGetSystemSettingsUtils } from "../../../../util/usershookActions";
import { settings_util } from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { deleteFromCategory } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

function Category() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [enabled_uti, setEnabledUtil] = useState(true);
  const [loading, setLoading] = useState(false);
  const { refetch } = useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  function handleDelete(item) {
    setLoading(true);
    deleteFromCategory(dispatch, { id: item.id }).then(() => {
      setLoading(false);
      // refetch();
    });
  }

  return (
    <div className="category">
      <div className="row justify-content-between">
        <div className="col-md-5">
          <h4>Category</h4>
          <small>Select categories of HRIS that should require approvals</small>
        </div>
        <div className="col-md-5 align-self-center">
          <div className="d-flex justify-content-end w-100">
            <Button type="text" className="w-50" onClick={handleModal}>
              Show All
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={showModal}
        footer={null}
        centered
        onCancel={handleModal}
        // width={700}
      >
        <div className="row">
          <div className={` col-lg-12`}>
            <div className="d-flex gap-2">
              <h3> Category list</h3>
              {loading ? (
                <LoadingOutlined
                  style={{ fontSize: "20px", marginBottom: "7px" }}
                />
              ) : null}
            </div>
            <div>
              <ParentList handleDelete={handleDelete} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Category;

function ParentList({ handleDelete }) {
  const settingsUtil = useShallowEqualSelector(settings_util);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (settingsUtil?.others.Approval.length) {
      setData(settingsUtil?.others?.Approval);
    }
  }, [settingsUtil]);
  function handleChange(checked, e, item) {
    // console.log(check, item);
    if (!checked) {
      const new_arry = [...data];
      const index = new_arry.findIndex((itm) => itm.id === item.id);
      new_arry[index].deleted_at = "deleted";
      setData(new_arry);
    } else {
      const new_arry = [...data];
      const index = new_arry.findIndex((itm) => itm.id === item.id);
      new_arry[index].deleted_at = null;
      setData(new_arry);
    }

    handleDelete(item);
  }

  // console.log(data);
  return (
    <div
      style={{
        height: 500,
        overflowY: "scroll",
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <span key="list-loadmore-more" className="text-info">
                <Switch
                  checked={item.deleted_at === null}
                  onChange={(check, e) => handleChange(check, e, item)}
                />
              </span>,
            ]}
          >
            <List.Item.Meta title={item.name} />
          </List.Item>
        )}
      />
    </div>
  );
}
