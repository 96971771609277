import React, { useState } from "react";
import styles from "../../styles/permissions/CreateGroup.module.css";
import Permission from "./Permission";
import { useGetClientPermissions } from "../../util/preferenceshookActions";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import {
  client_permissions,
  permissions_to_add,
  preference_spinner,
  preference_errors,
} from "../../util/selectors/preferenceSelectors";

import {
  useShallowEqualSelector,
  usePreferenceCleanUp,
} from "../../util/hooks";
import { ThreeDots } from "react-loader-spinner";
import { addEmployeeGroup } from "../../redux/preference/preferenceActions";
import { canAccess } from "../../util/helpers";
import HocHeader from "../ui/HocHeader";

function CreateGroup() {
  usePreferenceCleanUp();
  const dispatch = useDispatch();
  const [enable_perm, setEnabledPrem] = useState(true);
  const [name, setName] = useState("");

  useGetClientPermissions(enable_perm, setEnabledPrem);

  const clientPermissions = useShallowEqualSelector(client_permissions);
  const permissionsToAdd = useShallowEqualSelector(permissions_to_add);
  const preferenceSpinner = useShallowEqualSelector(preference_spinner);
  const back_errors = useShallowEqualSelector(preference_errors);

  function addGroup(e) {
    e.preventDefault();
    const creds = { permission_ids: permissionsToAdd, name };

    addEmployeeGroup(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        setName("");
      }
    });
  }

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Permissions Management"
        module_name="Create User Group"
        module_description="
        HR Managers and privileged administrators can view all your new
        hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />
      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <div className="app__section py-4 ">
            <div className="greeting">
              <h3>Create User Group</h3>
            </div>
            <div className="">
              <header className={styles.header}>
                <div className={styles.groupName}>
                  <label htmlFor="group-name" className="mb-2">
                    Name
                  </label>
                  <div className="w-100 text-right text-danger">
                    <small>{back_errors.name && back_errors.name[0]}</small>
                  </div>
                  <input
                    type="text"
                    name="group-name"
                    id=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter a group name"
                  />
                </div>
              </header>
              <main>
                <h3 className="mb-3">Permissions</h3>
                {clientPermissions?.length ? (
                  <ul>
                    {clientPermissions.map((permission, index) => (
                      <Permission
                        key={index}
                        title={permission.module}
                        options={permission.menus}
                        index={index}
                        initialShowContent={0} 
                      />
                    ))}

                    {canAccess(
                      "create-group-human-resource-information-system-preferences"
                    ) && (
                      <Button
                        className="mt-5"
                        size="large"
                        onClick={addGroup}
                        loading={preferenceSpinner}
                      >
                        Create
                      </Button>
                    )}
                  </ul>
                ) : (
                  <div className=" d-flex justify-content-center align-items-center ">
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color={"var(--clr-primary)"}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
              </main>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default CreateGroup;
