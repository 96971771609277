import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";


function MedicalHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          plan: em.hmo_plan,
          hmo_id: em.hmo_id_number || "--",
          hmo_primary: em.hmo_primary_number || "--",
          hmo_secondary: em.hmo_secondary_number || "--",
          hospital: em.primary_hospital || "--",
          hospital_address: em.primary_hospital_address || "--",
          genotype: em.genotype || "--",
          blood_group: em.blood_group || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "HMO PLAN",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "HMO ID",
      dataIndex: "hmo_id",
      key: "hmo_id",
    },
    {
      title: "HMO NO. 1",
      dataIndex: "hmo_primary",
      key: "hmo_primary",
    },
    {
      title: "HMO NO. 2",
      dataIndex: "hmo_secondary",
      key: "hmo_secondary",
    },
    {
      title: "PRIMARY HOSPITAL",
      dataIndex: "hospital",
      key: "hospital",
    },
    {
      title: "HOSPITAL NO.",
      dataIndex: "hospital_address",
      key: "hospital_address",
    },
    {
      title: "GENOTYPE",
      dataIndex: "genotype",
      key: "genotype",
    },
    {
      title: "BLOOD GROUP",
      dataIndex: "blood_group",
      key: "blood_group",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} scroll={{ x: 1440 }} />;
}

export default MedicalHistory;
