import { useState } from "react";
import { Button, Modal } from "antd";
import styles from "../../../styles/employee/Profile.module.css";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { updateUserEmploymentInfo } from "../../../redux/users/userActions";
import { useGetUserEmploymentHistory } from "../../../util/usershookActions";
import EmploymentCard from "./EmploymentCard";
import { MdAddCircleOutline, MdOutlineAdd, MdWork } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import Warning from "../../ui/Warning";

export default function EmploymentDetails() {
  useUserCleanUp();
  const [showModal, setShowModal] = useState(false);

  const [enabled, setEnabled] = useState(true);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const dispatch = useDispatch();

  const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);

  const { data: employment_info } = useGetUserEmploymentHistory(
    enabled,
    setEnabled,
    userProfile?.id
  );

  const init_details = {
    company_name: "",
    position: "",
    job_description: "",
    start_date: "",
    end_date: "",
  };

  const callbackFn = () => {
    values.user_id = userProfile?.id;
    // values.id = userProfile?.id;

    updateUserEmploymentInfo(dispatch, values).then((res) => {
      if (res?.status === "success") {
        clearForm();
        setShowModal(false);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`${styles.title} greeting_2`}>
          <div>
            <h4 className="m-0">Employments History</h4>
            <p>Review and update your employment information</p>
          </div>
          <MdWork />
        </div>
      </div>
      <Modal
        okText={"Add"}
        open={showModal}
        onCancel={modalHandler}
        footer={null}
        centered
      >
        <h5 className="text-dark mb-4 d-flex align-items-center gap-2">
          {<MdOutlineAdd />} Add Employment History
        </h5>
        {Object.keys(utils).length && utils?.others["Approval"].length
          ? utils.others["Approval"].map((item) => {
              if (item.name === "Employment" && item.deleted_at === null) {
                return <Warning />;
              } else {
                return null;
              }
            })
          : null}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label htmlFor="company_name">Company name</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.company_name ||
                    (back_errors.company_name && back_errors.company_name[0])}
                </small>
              </div>
              <input
                type="text"
                name="company_name"
                onChange={handleChange}
                value={values.company_name}
                id="company_name"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="position">Position</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.position ||
                    (back_errors.position && back_errors.position[0])}
                </small>
              </div>
              <input
                type="text"
                name="position"
                onChange={handleChange}
                value={values.position}
                id="position"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="job_description">Job Description</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.job_description ||
                    (back_errors.job_description &&
                      back_errors.job_description[0])}
                </small>
              </div>
              <input
                type="text"
                name="job_description"
                onChange={handleChange}
                value={values.job_description}
                id="job_description"
              />
            </div>

            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="start_date">Start Date</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.start_date ||
                    (back_errors.start_date && back_errors.start_date[0])}
                </small>
              </div>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={values.start_date}
                id="start_date"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="end_date">End Date</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.end_date ||
                    (back_errors.end_date && back_errors.end_date[0])}
                </small>
              </div>
              <input
                type="date"
                name="end_date"
                onChange={handleChange}
                value={values.end_date}
                id="end_date"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button className="myBtn mt-3" loading={userSpinner} htmlType="submit">
              Add
            </Button>
          </div>
        </form>
      </Modal>
      <div className="col-md-12 p-5">
        <div className="row gap-3">
          {employment_info?.payload?.length ? (
            employment_info?.payload.map((employment) => (
              <EmploymentCard
                key={employment.id}
                info={employment}
                setEnabled={setEnabled}
              />
            ))
          ) : (
            <div className="no-content-box">
              <img
                src="/assets/Wavy_Bus-08_Single-02.jpg"
                alt="no content illustration"
              />
              <p className="mb-2">No employment uploaded!</p>
              <Button onClick={modalHandler} className={styles.uploadBtn}>
                <HiUpload />
                Add Employment
              </Button>
            </div>
          )}
          {employment_info?.payload?.length ? (
            <div className="col-md-3">
              <div
                className="card bg-white h-100"
                style={{ cursor: "pointer" }}
                onClick={modalHandler}
              >
                <div className="card-body d-flex justify-content-center align-items-center">
                  <MdAddCircleOutline className={styles.addEntryBtn} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
