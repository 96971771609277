import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input, Space, Tag } from "antd";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { new_hire_del } from "../../../util/selectors/userSelectors";
import { useGetNewHireDeleteList } from "../../../util/usershookActions";
import NoContent from "../../ui/NoContent";
import EachRender from "./EachRender";

import "moment-timezone";


function PreEmploymentDeletedList() {
  const navigate = useNavigate();
  const columns = [
    {
      title: "S/N",
      dataIndex: "index",
      width: 70,
      fixed: "left",
    },
    {
      title: "Fullname",
      dataIndex: "name",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div className="p-1">
              <Input
                autoFocus
                placeholder="Filter Action"
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
              ></Input>
              <Space className="my-1">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm({ closeDropdown: false });
                  }}
                  icon={<RedoOutlined />}
                >
                  Reset
                </Button>
              </Space>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
      width: 200,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Batch",
      dataIndex: "batch",
    },
    {
      title: "Creation Time",
      dataIndex: "creation_time",
      render: (value, record) => {
        return (
          <Moment tz="Africa/Lagos" format="MMMM Do YYYY, h:mm:ss a">
            {value}
          </Moment>
        );
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",

      render: (value, record) => {
        return (
          <Space wrap>
            <Tag color="blue">
              {`Pre-fill link sent : ${
                record.analysis.pre_fill_link_sent ? "Yes" : "No"
              }`}
            </Tag>
            <Tag color="green">
              {`Pre-fill form filled : ${
                record.analysis["pre-fill-form_filled"] ? "Yes" : "No"
              }`}
            </Tag>
            <Tag color="red">
              {`Provisional offer sent : ${
                record.analysis["mail_offer_link_sent"] ? "Yes" : "No"
              }`}
            </Tag>
          </Space>
        );
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, record) => {
        return (
          <EachRender
            value={value}
            record={record}
            refetch={refetch}
            deleted={true}
          />
        );
      },
      fixed: "right",
    },
  ];

  useUserCleanUp();

  const new_hire_del_list = useShallowEqualSelector(new_hire_del);

  const [data, setData] = useState([]);
  const [enabled, setEnabled] = useState(true);

  const { refetch } = useGetNewHireDeleteList(enabled, setEnabled);

  useEffect(() => {
    if (new_hire_del_list?.length >= 0) {

      const new_arry = new_hire_del_list.map((item, index) => {
        return {
          ...item,
          key: item.id,
          index: index + 1,
          department: item?.department?.name,
          designation_role: item?.designation_role?.name,
          name: item.name,
          batch: item.batch,
          email: item.email,
          creation_time: item.creation_time,
          status: item.status,
        };
      });

      setData(new_arry);
    }
  }, [new_hire_del_list]);

  return (
    <div className="container-fluid">
      {data.length ? (
        <div className="row">
          <div className="col-md-12">
            <Button
              type="text"
              className="d-flex justify-content-end mb-3"
              onClick={() => navigate(-1)}
            >
              Return to Hires List
            </Button>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              refetch={refetch}
            />
          </div>
        </div>
      ) : (
        <>
          <NoContent width={400} message={`Archive is Empty`} />
          <Button
            type="text"
            className="mx-auto d-block mt-3"
            onClick={() => navigate(-1)}
          >
            Return to Hire List
          </Button>
        </>
      )}
    </div>
  );
}

export default PreEmploymentDeletedList;
