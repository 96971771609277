import React, { useState } from "react";
import { Modal } from "antd";
import {
  moveToStage,
  onboardingAction,
  onboardingActionRes,
} from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import HireProfile from "../newHire/HireProfile";
import CreateAccount from "./CreateAccount";
import AntDropdown from "../AntDropdown";

function EachRender({ value, record, refetch, deleted }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingdel, setLoadingDel] = useState(false);
  const [loadingmove, setLoadingMove] = useState(false);
  const [loadingmoveOB, setLoadingMoveOB] = useState(false);
  const [loadingprovisional, setLoadingMail] = useState(false);
  const [loadingres, setLoadingRes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalmal, setShowModalmal] = useState(false);
  const [showModaldel, setShowModaldel] = useState(false);
  const [showModalres, setShowModalres] = useState(false);
  const [showModalMove, setShowModalMove] = useState(false);
  const [showModalMoveOB, setShowModalMoveOB] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const profileModalHandler = () => {
    setShowProfileModal(!showProfileModal);
  };

  const creationModalHandler = () => {
    setShowCreationModal(!showCreationModal);
  };

  const confirm = (id) => {
    setLoading(true);
    Modal.destroyAll();
    const creds = {
      type: "send-pre-fill-form",
      id: record.id,
      action: "get",
    };
    onboardingAction(dispatch, creds).then((res) => {
      setLoading(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        refetch(true);
      }
    });
  };
  const alertPrefill = () => {
    Modal.confirm({
      title: "Send prefill form",
      content: "Do you wish to proceed?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const modalHandlerMail = () => {
    setShowModalmal(!showModalmal);
  };

  const confirmMail = (id) => {
    setLoadingMail(true);
    Modal.destroyAll();
    const creds = {
      type: "send-provisional-offer",
      id: record.id,
      action: "get",
    };
    onboardingAction(dispatch, creds).then((res) => {
      setLoadingMail(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        refetch(true);
      }
    });
  };
  const alertMailOffer = () => {
    Modal.confirm({
      title: "Send Provisional offer",
      content: "Do you wish to proceed?",
      onOk: confirmMail,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerMail,
      open: showModalmal,
      closable: true,
    });
  };

  const modalHandlerDel = () => {
    setShowModaldel(!showModaldel);
  };

  const confirmDelete = (id) => {
    setLoadingDel(true);
    Modal.destroyAll();
    const creds = {
      type: "delete",
      id: record.id,
      action: "delete",
    };
    onboardingAction(dispatch, creds).then((res) => {
      setLoadingDel(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_MAN", payload: record.id });
        refetch(true);
      }
    });
  };
  const alertDelete = () => {
    Modal.confirm({
      title: "Archive record",
      content: "Do you wish to proceed?",
      onOk: confirmDelete,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerDel,
      open: showModaldel,
      closable: true,
    });
  };
  const alertMove = () => {
    Modal.confirm({
      title: "Move to Induction",
      content: "Do you wish to proceed?",
      onOk: confirmMove,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerMove,
      open: showModalMove,
      closable: true,
    });
  };

  const modalHandlerMove = () => {
    setShowModalMove(!showModalMove);
  };

  const confirmMove = (id) => {
    setLoadingMove(true);
    Modal.destroyAll();
    const creds = {
      type: "INDUCTION",
      id: record.id,
    };
    moveToStage(dispatch, creds).then((res) => {
      setLoadingMove(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_MAN", payload: record.id });
        refetch(true);
      }
    });
  };

  const alertMoveOB = () => {
    Modal.confirm({
      title: "Move to onboarding",
      content: "Do you wish to proceed?",
      onOk: confirmMoveOB,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerMoveOB,
      open: showModalMove,
      closable: true,
    });
  };

  const modalHandlerMoveOB = () => {
    setShowModalMoveOB(!showModalMoveOB);
  };

  const confirmMoveOB = (id) => {
    setLoadingMoveOB(true);
    Modal.destroyAll();
    const creds = {
      type: "ONBOARDING",
      id: record.id,
    };
    moveToStage(dispatch, creds).then((res) => {
      setLoadingMoveOB(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_MAN", payload: record.id });
        refetch(true);
      }
    });
  };

  const modalHandlerRes = () => {
    setShowModalres(!showModalres);
  };

  const confirmRestore = (id) => {
    setLoadingRes(true);
    Modal.destroyAll();
    const creds = {
      type: "restore",
      id: record.id,
      action: "get",
    };
    onboardingActionRes(dispatch, creds).then((res) => {
      setLoadingRes(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_DEL_MAN", payload: record.id });
        refetch(true);
      }
    });
  };
  const alertRestore = () => {
    Modal.confirm({
      title: "Unarchive hire",
      content: "Do you wish to proceed?",
      onOk: confirmRestore,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerRes,
      open: showModalres,
      closable: true,
    });
  };

  const dropdownDeletedItems = [
    {
      key: "1",
      label: <p onClick={alertRestore}>Restore</p>,
    },
  ];

  const dropdownItems = [
    {
      key: "1",
      label: <p onClick={alertPrefill}>Send pre-fill form</p>,
    },
    {
      key: "2",
      label: <p onClick={alertMailOffer}>Send Provisional offer</p>,
    },
    {
      key: "3",
      label: <p onClick={profileModalHandler}>Show Profile</p>,
    },
    {
      key: "4",
      label: <p onClick={creationModalHandler}>Create Employee Account</p>,
    },
    {
      key: "5",
      label: <p onClick={alertMove}>Move to Induction</p>,
    },
    {
      key: "6",
      label: <p onClick={alertMoveOB}>Move to Onboarding</p>,
    },
    {
      key: "7",
      label: <p onClick={alertDelete}>Archive</p>,
      danger: true,
    },
  ];

  return (
    <div>
      <AntDropdown
        items={deleted ? dropdownDeletedItems : dropdownItems}
        loading={
          loadingres ||
          loading ||
          loadingprovisional ||
          loadingmove ||
          loadingmoveOB ||
          loadingdel
        }
      />
      <Modal
        open={showProfileModal}
        onCancel={profileModalHandler}
        centered
        width={1000}
        footer={null}
      >
        <HireProfile id={record.id} admin={true} />
      </Modal>
      <Modal
        open={showCreationModal}
        onCancel={creationModalHandler}
        centered
        width={1200}
        footer={null}
      >
        <CreateAccount record={record} />
      </Modal>
    </div>
  );
}

export default EachRender;
