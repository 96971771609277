import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";

import {
  //   user_spinner,
  user_errors,
} from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserEduInfo,
  rejectUserInfo,
  rejectUserInfo1,
} from "../../../../redux/users/userActions";
import { useOutletContext } from "react-router-dom";
import data from "../../../../util/util.json";

export default function ApproveEduHistory() {
  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();
  const { degree, institutionType } = data;

  //   const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_re, setLoadingRe] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);
  const [details, setEnabled] = useOutletContext();

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserEduInfo(dispatch, { ...values, notify: false }, exempt).then(
        (res) => {
          setLoadingAppN(false);
          if (res?.status === "success") {
            setEnabled(true);
          }
        }
      );
    }
  }

  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  if (details[0]) {
    init_details = {
      ...details[0],
      except: [],
      notify: true,
    };
  }

  const callbackFn = () => {
    if (details[0]) {
      setLoading(true);
      approveUserEduInfo(dispatch, values).then((res) => {
        setLoading(false);
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const confirm = (id) => {
    setLoadingRe(true);
    rejectUserInfo(dispatch, {
      ...values,
      info_type: "educational_history",
    }).then((res) => {
      setLoadingRe(false);
      setShowModal(false);
      
      if (res.status === "success") {
        setEnabled(true);
      }
    });
  };

  return (
    <DashboardCard
      title={"Educational Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6 approval_side_checkbox d-flex flex-column">
            <label htmlFor="institution_name">Institution Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.institution_name ||
                  (back_errors.institution_name &&
                    back_errors.institution_name[0])}
              </small>
            </div>
            <input
              type="text"
              name="institution_name"
              onChange={handleChange}
              value={values.institution_name}
              id="institution_name"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="institution_name"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox d-flex flex-column">
            <label htmlFor="course">Course of Study</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.course || (back_errors.course && back_errors.course[0])}
              </small>
            </div>
            <input
              type="text"
              name="course"
              onChange={handleChange}
              value={values.course}
              id="course"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="course"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6 approval_side_checkbox d-flex flex-column">
            <label htmlFor="institution_category">Institution Category</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.institution_category ||
                  (back_errors.institution_category &&
                    back_errors.institution_category[0])}
              </small>
            </div>
            <select
              name="institution_category"
              onChange={handleChange}
              value={values.institution_category}
              id="institution_category"
            >
              <option value="">--</option>
              {institutionType.map((deg) => (
                <option value={deg.name} key={deg.id}>
                  {deg.name}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="institution_category"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox d-flex flex-column">
            <label htmlFor="degree_obtained">Degree Obtained</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.degree_obtained ||
                  (back_errors.degree_obtained &&
                    back_errors.degree_obtained[0])}
              </small>
            </div>
            <select
              name="degree_obtained"
              onChange={handleChange}
              value={values.degree_obtained}
              id="degree_obtained"
            >
              <option value="">--</option>
              {degree.map((deg) => (
                <option value={deg.name} key={deg.id}>
                  {deg.name}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="degree_obtained"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6 approval_side_checkbox mt-2 d-flex flex-column">
            <label htmlFor="start_date">Start Date</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.start_date ||
                  (back_errors.start_date && back_errors.start_date[0])}
              </small>
            </div>
            <input
              type="date"
              name="start_date"
              onChange={handleChange}
              value={values.start_date}
              id="start_date"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="start_date"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn"
            loading={loading}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>
          <Button
            size="large"
            className="myBtn"
            loading={loadingAppN}
            onClick={handleApproveOnly}
            disabled={disabled}
          >
            Approve
          </Button>

          <Button
            size="large"
            className="myBtn"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={modalHandler}
            loading={loading_re}
          >
            Reject
          </Button>
          <Modal
            title={`Reject request`}
            centered
            onOk={() => confirm(values?.id)}
            onCancel={modalHandler}
            open={showModal}
            cancelText="No"
            okText="Yes"
          >
            <p className="h6">Are you sure to reject this request?</p>
          </Modal>
        </div>
      </form>
    </DashboardCard>
  );
}
