import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function CompesationPayRoll() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  // console.log({ childPath });

  return (
    <>
      <div>
        <HocHeader
          module_title="Compesation & Benefits"
          module_class="performance-header"
          module_name={
            childPath === "deductions-master"
              ? "Deductions Master"
              : childPath === "payment-template"
              ? "Payment Template"
              : childPath === "batch-payment"
              ? "Batch Payment"
              : childPath === "authorize-payment"
              ? "Authorize Payment"
              : childPath === "payslip"
              ? "Send Payslip"
              : childPath === "employee-payslip"
              ? "Employee Payslip"
              : "Wage Item settings"
          }
          module_description={
            childPath === "deductions-master"
              ? "Set up deduction template for paye calculations"
              : childPath === "payment-template"
              ? "Generate employee's payment master schedule for the selected grade level."
              : childPath === "batch-payment"
              ? "Process multiple salary transactions simultaneously, streamline the disbursement of wages to a group of employees within your ogranization."
              : childPath === "authorize-payment"
              ? "Authorize the payment of employee salaries for the selected pay period for pay slip generation."
              : childPath === "payslip"
              ? " Send/Download monthly payslip for employee, detailing their earnings, deductions, and net pay for the current pay period."
              : childPath === "employee-payslip"
              ? "Generate your monthly payslip, detailing your earnings, deduction, and net pay for the current pay period."
              : "Create components that makes up employee pay-slip"
          }
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default CompesationPayRoll;
