import AddTaskButton from "./AddTaskButton";

import { Input, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchAddTask = () => {
  return (
    <div className="mt-3 todo__tasks__container">
      <Flex justify="space-between" gap={"7rem"}>
        <AddTaskButton noBackground={false} />

        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search or type a command..."
        />
      </Flex>
    </div>
  );
};

export default SearchAddTask;
