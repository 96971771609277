import React, { useState, useEffect } from "react";
import { Button, Table, Drawer, Space, Popconfirm } from "antd";

import NoContent from "../../../ui/NoContent";
import SkeletonUI from "../../../ui/Skeleton";
import ReadOnlyTemplateRenderer from "../../../request-management/make-request/request-history/ReadOnlyTemplateRenderer";
import dayjs from "dayjs";
import ExternalSurveyReadOnly from "../../externalsurveyui/ExternalSurveyReadOnly";

const ActiveSurveys = () => {
  const [isLoading, setIsLoading] = useState();
  const [tableData, setTableData] = useState([]);
  const [templates, setTemplates] = useState();
  const [change, setChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `http://localhost:8000/survey?status=active`
        );
        const data = await response.json();
        setTemplates(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [change]);

  useEffect(() => {
    if (templates) {
      const new_array = templates?.map((survey, index) => {
        return {
          key: survey.id,
          survey_name: survey.surveyName,
          date_created: survey.dateCreated,
          survey_type: survey.surveyType,
          survey,
        };
      });
      setTableData(new_array);
    }
  }, [templates]);

  const columns = [
    {
      title: "Survey Name",
      dataIndex: "survey_name",
      align: "center",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      align: "center",
    },
    {
      title: "Survey Type",
      dataIndex: "survey_type",
      align: "center",
      render: (_, record) => (
        <span>
          {record.survey_type === "internal" ? "Internal" : "External"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <EachAction selectedTemplateObject={record} setChange={setChange} />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3" id="create__template__body">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Survey</h3>
            <p>Actions can be taken on all created surveys</p>
          </div>
        </div>

        {isLoading ? (
          <SkeletonUI />
        ) : !isLoading && templates?.length ? (
          <div className="monitoring__individual__content__table">
            <Table
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 500 }}
              bordered
              pagination={false}
            />
          </div>
        ) : (
          <NoContent width={"400px"} />
        )}
      </div>
    </div>
  );
};

function EachAction({ selectedTemplateObject, setChange }) {
  const [internalSurveyOpen, setInternalSurveyOpen] = useState(false);
  const [externalSurveyOpen, setExternalSurveyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const showInternalSurveyDrawer = () => {
    setInternalSurveyOpen(true);
  };

  const showExternalSurveyDrawer = () => {
    setExternalSurveyOpen(true);
  };

  const onInternalSurveyClose = () => {
    setInternalSurveyOpen(false);
  };

  const onExternalSurveyClose = () => {
    setExternalSurveyOpen(false);
  };

  const updateTemplate = async (id, template) => {
    const response = await fetch(`http://localhost:8000/survey/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(template),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleEndSurvey = async () => {
    setIsLoading(true);

    try {
      const result = await updateTemplate(selectedTemplateObject.survey.id, {
        ...selectedTemplateObject.survey,
        status: "ended",
      });
      console.log("Success:", result);
      setChange(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelEndSurvey = (e) => {
    setInternalSurveyOpen(false);
    setExternalSurveyOpen(false);
  };

  return (
    <>
      <Space>
        <Popconfirm
          title="End this survey"
          description="Are you sure you want to End this survey?"
          onConfirm={handleEndSurvey}
          onCancel={cancelEndSurvey}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            className=""
            style={{
              backgroundColor: "red",
            }}
            loading={isLoading}
          >
            End Survey
          </Button>
        </Popconfirm>
        <Button
          type="primary"
          shape="round"
          className=""
          onClick={
            selectedTemplateObject?.survey?.surveyType === "internal"
              ? showInternalSurveyDrawer
              : showExternalSurveyDrawer
          }
        >
          View
        </Button>
      </Space>

      <Drawer
        title={selectedTemplateObject?.survey_name}
        width={"60%"}
        onClose={onInternalSurveyClose}
        open={internalSurveyOpen}
        extra={
          <Space>
            <Button danger size="large" onClick={onInternalSurveyClose}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <div className="make__request__form">
          <div className="make__request__form__title">
            <h4 className="h4">{selectedTemplateObject?.survey_name}</h4>
          </div>

          {selectedTemplateObject?.survey?.questionsItems
            ? selectedTemplateObject?.survey?.questionsItems.map(
                (question, index) => (
                  <ReadOnlyTemplateRenderer question={question} key={index} />
                )
              )
            : null}

          <Popconfirm
            title="End this survey"
            description="Are you sure you want to End this survey?"
            onConfirm={handleEndSurvey}
            onCancel={cancelEndSurvey}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              shape="round"
              className=""
              style={{
                backgroundColor: "red",
                width: "fit-content",
              }}
            >
              End Survey
            </Button>
          </Popconfirm>
        </div>
      </Drawer>

      <Drawer
        title={selectedTemplateObject?.survey_name}
        width={"80%"}
        onClose={onExternalSurveyClose}
        open={externalSurveyOpen}
        extra={
          <Space>
            <Button danger size="large" onClick={onExternalSurveyClose}>
              Close
            </Button>
          </Space>
        }
      >
        <div className=" d-flex flex-column gap-4">
          <div>
            <ExternalSurveyReadOnly survey={selectedTemplateObject.survey} />
          </div>

          <div>
            <Popconfirm
              title="End this survey"
              description="Are you sure you want to End this survey?"
              onConfirm={handleEndSurvey}
              onCancel={cancelEndSurvey}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                shape="round"
                className=""
                style={{
                  backgroundColor: "red",
                  width: "fit-content",
                }}
              >
                End Survey
              </Button>
            </Popconfirm>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ActiveSurveys;
