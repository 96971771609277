import { useState } from "react";
import DashboardCard from "../../dashboard/DashboardCard";
import { Button } from "antd";
import styles from "../../../styles/employee/Profile.module.css";
import { MdEmergency } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import Warning from "../../ui/Warning";
import { useShallowEqualSelector, useForm } from "../../../util/hooks";
import {
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { saveUserEmergencyContact } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import data from "../../../util/util.json";

const { next_of_kin_relationship } = data;

function EmergencyContact({ init_det }) {
  let init_details;

  const [editMode, setEditMode] = useState(false);
  const utils = useShallowEqualSelector(settings_util);

  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const [save_spinner, setSaveSpinner] = useState(false);

  const dispatch = useDispatch();

  if (
    userProfile?.emergency_contact &&
    Object.keys(userProfile?.emergency_contact).length
  ) {
    const { id, ...rest } = userProfile.emergency_contact[0];
    init_details = rest;
  } else {
    init_details = {
      name: "",
      email: "",
      relationship: "",
      date_of_birth: "",
      telephone: "",
      mobile_number: "",
      home_address: "",
    };
  }

  const callbackFn = () => {
    values.user_id = userProfile?.id;

    setSaveSpinner(true);
    saveUserEmergencyContact(dispatch, values).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        setEditMode(!editMode);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  console.log("values", values);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Emergency Contact Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Biodata"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Name</label>
            <p>{userProfile?.emergency_contact?.[0]?.name || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Email</label>
            <p>{userProfile?.emergency_contact?.[0]?.email || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Relationship</label>
            <p>
              {userProfile?.emergency_contact?.[0]?.relationship.toUpperCase() ||
                "N/A"}
            </p>
          </div>
          <div className="col-md-4">
            <label>Date of Birth</label>
            <p>{userProfile?.emergency_contact?.[0]?.date_of_birth || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <p>{userProfile?.emergency_contact?.[0]?.mobile_number || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Telephone</label>
            <p>{userProfile?.emergency_contact?.[0]?.telephone || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Home Address</label>
            <p>{userProfile?.emergency_contact?.[0]?.home_address || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard title={"Edit Emergency Contact"} icon={<MdEmergency />}>
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Emergency Contact" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="name">
              Name <span className="text-danger">*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.name || (back_errors.name && back_errors.name[0])}
              </small>
            </div>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              id="name"
            />
          </div>
          <div className="col-4">
            <label htmlFor="email">Email</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.email || (back_errors.email && back_errors.email[0])}
              </small>
            </div>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
              id="email"
            />
          </div>
          <div className="col-4">
            <label htmlFor="relationship">
              Relationship <span className="text-danger">*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.relationship ||
                  (back_errors.relationship && back_errors.relationship[0])}
              </small>
            </div>
            <select
              name="relationship"
              onChange={handleChange}
              value={values.relationship}
              id="relationship"
            >
              <option value="">--</option>
              {next_of_kin_relationship.map((nok) => (
                <option key={nok.id} value={nok.key}>
                  {nok.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="date_of_birth">Date of Birth</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.date_of_birth ||
                  (back_errors.date_of_birth && back_errors.date_of_birth[0])}
              </small>
            </div>
            <input
              type="date"
              name="date_of_birth"
              onChange={handleChange}
              value={values.date_of_birth}
              id="date_of_birth"
            />
          </div>
          <div className="col-4">
            <label htmlFor="mobile_number">Mobile Number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.mobile_number ||
                  (back_errors.mobile_number && back_errors.mobile_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="mobile_number"
              onChange={handleChange}
              value={values.mobile_number}
              id="mobile_number"
            />
          </div>
          <div className="col-4">
            <label htmlFor="telephone">Telephone</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.telephone ||
                  (back_errors.telephone && back_errors.telephone[0])}
              </small>
            </div>
            <input
              type="number"
              name="telephone"
              onChange={handleChange}
              value={values.telephone}
              id="telephone"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <label htmlFor="home_address">Home Address</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.home_address ||
                  (back_errors.home_address && back_errors.home_address[0])}
              </small>
            </div>
            <textarea
              name="home_address"
              onChange={handleChange}
              id="home_address"
              value={values.home_address}
            ></textarea>
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger"
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn" loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}

export default EmergencyContact;
