import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import Calendar from 'reactjs-availability-calendar'
import "../../../styles/annualLeave.css";
// import { formatDate } from "react-calendar/dist/cjs/shared/dateFormatter";
import dayjs from "dayjs";
import moment from "moment";
import { Button, Tag, Space } from "antd";
import { updateAnnualLeave } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  annual_leave_plan,
  get_leave_plan,
  holiday,
} from "../../../util/selectors/userSelectors";

import {
  useGetAnnualLeavePlan,
  useGetSystemHolidays,
  useGetUserAnnualPlanAny,
  useGetUserProfileAny,
} from "../../../util/usershookActions";
import { MdFreeCancellation } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";

export default function LeavePlan({ record, users, employee }) {
  useUserCleanUp();
  const [year, setYear] = useState(2023);
  const [hol, setHol] = useState([]);
  const [animeLoadSave, setAnimeLoadSave] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [viewPlan, setViewPlan] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [enabledhol, setEnabledhol] = useState(true);
  const [enabledpro, setEnabledpro] = useState(false);
  const [enabledplan, setEnabledplan] = useState(false);
  const [user_data, setUserData] = useState([]);
  const [current_record, setCurrentRecord] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [dateVal, setDateVal] = useState({
    january: [],
    february: [],
    march: [],
    april: [],
    may: [],
    june: [],
    july: [],
    august: [],
    september: [],
    october: [],
    november: [],
    december: [],
  });
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  useGetSystemHolidays(enabledhol, setEnabledhol);
  const holidays = useShallowEqualSelector(holiday);

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    setDates((prev) => [...prev, ...dateArray]);
    return dateArray;
  }

  useEffect(() => {
    setUserData(users);
  }, [users]);

  useEffect(() => {
    setUniqueId(
      Date.now().toString(36) + Math.random().toString(36).substring(2, 10)
    );
    setYear(new Date().getFullYear());
    setSelectedUser(record?.user?.id);
  }, []);

  useEffect(() => {
    if (holidays.length) {
      const newholidays = holidays.map((holiday) =>
        dayjs(holiday.date).format("MM/DD")
      );
      setHol(newholidays);
    }
  }, [holidays.length]);

  // useGetAnnualLeavePlan(enabled, setEnabled);
  // const annual_leave = useShallowEqualSelector(annual_leave_plan);
  // const get_annual_leave_plan = useShallowEqualSelector(get_leave_plan);
  // console.log({ get_annual_leave_plan });
  // console.log({ annual_leave });

  // function equalityCheck(date) {
  //   let no = 0;
  //   while (get_annual_leave_plan?.length > no) {
  //     if (get_annual_leave_plan[no] === dayjs(date).format("YYYY-MM-DD")) {
  //       return true;
  //     }
  //     no++;
  //   }
  // }
  const { data: user_profile, isLoading: isLoading_pro } = useGetUserProfileAny(
    enabledpro,
    setEnabledpro,
    selectedUser
  );
  const { data: user_plan, isLoading: isLoading_plan } =
    useGetUserAnnualPlanAny(enabledplan, setEnabledplan, selectedUser);

  const creds = {
    dates,
  };

  const handleSubmit = (e, batch, user_id) => {
    e.preventDefault();

    let creds = [
      ...dateVal.january,
      ...dateVal.february,
      ...dateVal.march,
      ...dateVal.may,
      ...dateVal.june,
      ...dateVal.july,
      ...dateVal.august,
      ...dateVal.september,
      ...dateVal.october,
      ...dateVal.november,
      ...dateVal.december,
    ];

    const fist = creds.filter((date) => {
      // console.log({ d: new Date(date).getDay() });
      return !hol.includes(dayjs(date).format("MM/DD"));
    });
    const second = fist.filter((date) => {
      console.log({ d: new Date(date).getDay() });
      return new Date(date).getDay() !== 0;
    });

    const third = second.filter((date) => {
      console.log({ d: new Date(date).getDay() });
      return new Date(date).getDay() !== 6;
    });

    // console.log({ creds, fist, second, third });
    setAnimeLoadSave(true);
    updateAnnualLeave(dispatch, third, batch, user_id).then((res) => {
      setAnimeLoadSave(false);
      setDates([]);
      dispatch({
        type: "ADD_ANNUAL_LEAVE",
        payload: res,
      });
    });
  };

  const handleDate = (value) => {
    console.log({ value });
    const allDate = getDates(value[0], value[1]);
    const getDate = value[0];
    switch (getDate.getMonth()) {
      case 0:
        return setDateVal((prev) => ({
          ...prev,
          january: allDate,
        }));
      case 1:
        return setDateVal((prev) => ({
          ...prev,
          february: allDate,
        }));
      case 2:
        return setDateVal((prev) => ({
          ...prev,
          march: allDate,
        }));
      case 3:
        return setDateVal((prev) => ({
          ...prev,
          april: allDate,
        }));
      case 4:
        return setDateVal((prev) => ({
          ...prev,
          may: allDate,
        }));
      case 5:
        return setDateVal((prev) => ({
          ...prev,
          june: allDate,
        }));
      case 6:
        return setDateVal((prev) => ({
          ...prev,
          july: allDate,
        }));
      case 7:
        return setDateVal((prev) => ({
          ...prev,
          august: allDate,
        }));
      case 8:
        return setDateVal((prev) => ({
          ...prev,
          september: allDate,
        }));
      case 9:
        return setDateVal((prev) => ({
          ...prev,
          october: allDate,
        }));
      case 10:
        return setDateVal((prev) => ({
          ...prev,
          november: allDate,
        }));
      case 11:
        return setDateVal((prev) => ({
          ...prev,
          december: allDate,
        }));
    }
  };

  const disabledDate = ({ date }) => {
    // return date.getDay() === 1 || date.getDay() === 2;
    // Check if the day is Saturday or Sunday
    return (
      date.getDay() === 0 ||
      date.getDay() === 6 ||
      hol.includes(dayjs(date).format("MM/DD"))
    );
  };

  const tileClassName = ({ date }) => {
    // Add your custom class for disabled tiles
    if (disabledDate({ date })) {
      return "disabled-tile";
    }
    return "";
  };

  useEffect(() => {
    if (selectedUser) {
      setEnabledpro(true);
      setEnabledplan(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (user_profile) {
      setCurrentRecord(user_profile);
    }
  }, [user_profile]);

  useEffect(() => {
    if (user_plan) {
      const result = groupDatesByMonth(user_plan.payload);

      setDateVal(result);
    }
  }, [user_plan]);

  function groupDatesByMonth(dates) {
    const months = {
      january: [],
      february: [],
      march: [],
      april: [],
      may: [],
      june: [],
      july: [],
      august: [],
      september: [],
      october: [],
      november: [],
      december: [],
    };
    dates.forEach((month) => {
      const getDate = new Date(month);
      switch (getDate.getMonth()) {
        case 0:
          return months.january.push(month);
        case 1:
          return months.february.push(month);
        case 2:
          return months.march.push(month);
        case 3:
          return months.april.push(month);
        case 4:
          return months.may.push(month);
        case 5:
          return months.june.push(month);
        case 6:
          return months.july.push(month);
        case 7:
          return months.august.push(month);
        case 8:
          return months.september.push(month);
        case 9:
          return months.october.push(month);
        case 10:
          return months.november.push(month);
        case 11:
          return months.december.push(month);
      }
    });

    return months;
  }

  return (
    <main className="annualLeave">
      <section className="annualLeaveMain">
        <article className="annualTop">
          <div>
            <h6>
              {current_record
                ? current_record.data.personal.fullname
                : `${record.user.first_name} ${record.user.last_name}`}{" "}
              {isLoading_pro ? <LoadingOutlined /> : null}
            </h6>
            <Space wrap>
              {user_data.map((user) => (
                <Tag
                  color={selectedUser === user.id ? "#108ee9" : ""}
                  onClick={() => setSelectedUser(user.id)}
                  className="pointer"
                >
                  {" "}
                  {user.fullname}
                </Tag>
              ))}
            </Space>
          </div>

          {!employee ? (
            <button
              className="annualButton button"
              onClick={() => setViewPlan(!viewPlan)}
            >
              {viewPlan ? "View leave plan" : "Update leave plan"}
            </button>
          ) : null}
        </article>
        <hr />
      </section>
      {viewPlan ? (
        <section className="annual_body">
          <section className="Calendar">
            <article className="annualMain">
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      january: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>

                <Calendar
                  activeStartDate={new Date(year, 0, 1)}
                  minDate={new Date(year, 0, 1)}
                  maxDate={new Date(year, 0, 31)}
                  selectRange="true"
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.january[0],
                    dateVal.january[dateVal.january.length - 1],
                  ]}
                />
              </div>
              {/* Function: ({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null */}
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      february: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 1, 1)}
                  selectRange={true}
                  minDate={new Date(year, 1, 1)}
                  maxDate={new Date(year, 1, 28)}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  // tileContent={handleDate}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.february[0],
                    dateVal.february[dateVal.february.length - 1],
                  ]}
                  className="calendar"
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      march: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 2, 1)}
                  minDate={new Date(year, 2, 1)}
                  maxDate={new Date(year, 2, 31)}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.march[0],
                    dateVal.march[dateVal.march.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      april: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 3, 1)}
                  minDate={new Date(year, 3, 1)}
                  maxDate={new Date(year, 3, 30)}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.april[0],
                    dateVal.april[dateVal.april.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      may: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 4, 1)}
                  minDate={new Date(year, 4, 1)}
                  maxDate={new Date(year, 4, 31)}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[dateVal.may[0], dateVal.may[dateVal.may.length - 1]]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      june: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 5, 1)}
                  minDate={new Date(year, 5, 1)}
                  maxDate={new Date(year, 6, 30)}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.june[0],
                    dateVal.june[dateVal.june.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      july: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 6, 1)}
                  minDate={new Date(year, 6, 1)}
                  maxDate={new Date(year, 6, 31)}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.july[0],
                    dateVal.july[dateVal.july.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      august: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 7, 1)}
                  minDate={new Date(year, 7, 1)}
                  maxDate={new Date(year, 7, 31)}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.august[0],
                    dateVal.august[dateVal.august.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      september: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 8, 1)}
                  minDate={new Date(year, 8, 1)}
                  maxDate={new Date(year, 8, 31)}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.september[0],
                    dateVal.september[dateVal.september.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      october: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 9, 1)}
                  minDate={new Date(year, 9, 1)}
                  maxDate={new Date(year, 9, 31)}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.october[0],
                    dateVal.october[dateVal.october.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      november: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 10, 1)}
                  minDate={new Date(year, 10, 1)}
                  maxDate={new Date(year, 10, 30)}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.november[0],
                    dateVal.november[dateVal.november.length - 1],
                  ]}
                />
              </div>
              <div className="calendar_wrapper">
                <span
                  className="clear_calendar"
                  onClick={() =>
                    setDateVal((prev) => ({
                      ...prev,
                      december: [],
                    }))
                  }
                >
                  <MdFreeCancellation title="Cancel selected date" />
                </span>
                <Calendar
                  activeStartDate={new Date(year, 11, 1)}
                  minDate={new Date(year, 11, 1)}
                  maxDate={new Date(year, 11, 31)}
                  tileDisabled={disabledDate}
                  tileClassName={tileClassName}
                  selectRange={true}
                  nextLabel={null}
                  next2Label={null}
                  prevLabel={null}
                  prev2Label={null}
                  minDetail="month"
                  className="calendar"
                  onChange={(value, event) => handleDate(value)}
                  value={[
                    dateVal.december[0],
                    dateVal.december[dateVal.december.length - 1],
                  ]}
                />
              </div>
            </article>
          </section>
          <div className="leave_submit_div w-100 d-flex justify-content-end">
            {/* <button className="leave_submit_button"> Submit</button> */}
            <Button
              className="myBtn py-4 mb-3 px-5 "
              htmlType="submit"
              loading={animeLoadSave}
              onClick={(e) => handleSubmit(e, record.batch, selectedUser)}
            >
              Submit
            </Button>
          </div>
        </section>
      ) : (
        <>
          {!isLoading_plan ? (
            user_plan?.payload?.length ? (
              <section className="Calender">
                <article className="annualMain">
                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 0, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange="true"
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      value={[
                        dateVal.january[0],
                        dateVal.january[dateVal.january.length - 1],
                      ]}
                      // tileDisabled={({ date }) => equalityCheck(date)}
                    />
                  </div>
                  {/* Function: ({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null */}

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 1, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      value={[
                        dateVal.february[0],
                        dateVal.february[dateVal.february.length - 1],
                      ]}
                      className="calendar"
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 2, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.march[0],
                        dateVal.march[dateVal.march.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 3, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.april[0],
                        dateVal.april[dateVal.april.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 4, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.may[0],
                        dateVal.may[dateVal.may.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 5, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.june[0],
                        dateVal.june[dateVal.june.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 6, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.july[0],
                        dateVal.july[dateVal.july.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 7, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.august[0],
                        dateVal.august[dateVal.august.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 8, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.september[0],
                        dateVal.september[dateVal.september.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 9, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.october[0],
                        dateVal.october[dateVal.october.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 10, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.november[0],
                        dateVal.november[dateVal.november.length - 1],
                      ]}
                    />
                  </div>

                  <div className="calendar_wrapper">
                    <Calendar
                      activeStartDate={new Date(year, 11, 1)}
                      tileDisabled={disabledDate}
                      tileClassName={tileClassName}
                      selectRange={true}
                      nextLabel={null}
                      next2Label={null}
                      prevLabel={null}
                      prev2Label={null}
                      minDetail="month"
                      className="calendar"
                      // tileDisabled={({ date }) => equalityCheck(date)}
                      value={[
                        dateVal.december[0],
                        dateVal.december[dateVal.december.length - 1],
                      ]}
                    />
                  </div>
                </article>
              </section>
            ) : (
              <>
                <NoContent width={"400px"} />
                <div className="d-flex justify-content-center my-3">
                  {!employee ? (
                    <Button
                      className="myBtn"
                      onClick={() => setViewPlan(!viewPlan)}
                    >
                      {" "}
                      Update plan
                    </Button>
                  ) : null}
                </div>
              </>
            )
          ) : (
            <SkeletonUI />
          )}
        </>
      )}
      {/* <div><Calendar bookings={bookings} className='______'/></div> */}
    </main>
  );
}
