import { useState } from "react";
import dayjs from "dayjs";

import { useGetPerformanceSettings } from "../../../util/usershookActions";

const CurrentPerformanceYear = () => {
  const [enabled, setEnabled] = useState(true);

  const { data } = useGetPerformanceSettings(enabled, setEnabled);
  return (
    <span
      style={{
        opacity: 0.9,
        fontSize: "1.2rem",
      }}
    >
      {data?.payload.performance_planning_window_from
        ? `: for (${dayjs(
            data?.payload.performance_planning_window_from
          ).format("MMM D, YYYY")} - ${dayjs(
            data?.payload.performance_planning_window_to
          ).format("MMM D, YYYY")})`
        : ""}
    </span>
  );
};

export default CurrentPerformanceYear;
