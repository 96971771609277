import { useState, useEffect } from "react";
import { Select, Tree } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  useGetPerformancePlanningHistory,
  useGetPerformanceSettingsHistory,
  useGetPerformancePlans
} from "../../../../util/usershookActions";
import SkeletonUI from "../../../ui/Skeleton";
import dayjs from "dayjs";

const { TreeNode } = Tree;

const TreeNodeData = ({ title, description }) => (
  <div>
    <strong>{title}</strong>
    <p dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

const targetTreeNode = (node, index) => {
  const { name, description } = node;

  return (
    <TreeNode
      key={`${name}-${index}`}
      title={
        <TreeNodeData title={`(Target) - ${name}`} description={description} />
      }
    ></TreeNode>
  );
};

const kpisTreeNode = (node, index) => {
  const { targets, name, description } = node;
  console.log("index", index);

  return (
    <TreeNode
      key={`${name}-${index}`}
      title={
        <TreeNodeData title={`(KPI) - ${name}`} description={description} />
      }
    >
      {targets && targets.map(targetTreeNode)}
    </TreeNode>
  );
};

const objectivesTreeNode = (node, index) => {
  const { name, description, kpi } = node;
  // console.log("index", index);

  return (
    <TreeNode
      key={`${name}-${index}`}
      title={
        <TreeNodeData
          title={`(Objective) - ${name}`}
          description={description}
        />
      }
    >
      {kpi && kpi.map(kpisTreeNode)}
    </TreeNode>
  );
};

const goalTreeNode = (node) => {
  const { objectives, name, description } = node;

  return (
    <TreeNode
      key={name}
      title={
        <TreeNodeData title={`(Goal) - ${name}`} description={description} />
      }
    >
      {objectives && objectives.map(objectivesTreeNode)}
    </TreeNode>
  );
};

const renderTree = (treeData) => {
  return treeData
    ?.map((node, index) => ({
      ...node,
      key: `${index}`,
    }))
    .map(goalTreeNode);
};

const PlanningHistory = ({ treeData, onSelect }) => {
  const [historyEnabled, setHistoryEnabled] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [planID, setPlanID] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();

  const {
    data: performanceSettingsHistory,
    isLoading: performanceSettingsHistoryLoading,
  } = useGetPerformanceSettingsHistory(enabled, setEnabled);

  const {
    data: performancePlans,
    isLoading: performancePlansLoading,
  } = useGetPerformancePlans(enabled, setEnabled);

  const { data, isLoading } = useGetPerformancePlanningHistory(
    historyEnabled,
    setHistoryEnabled,
    start_date,
    end_date
  );

  useEffect(() => {
    if (start_date && end_date) {
      setHistoryEnabled(true);
    }
  }, [start_date, end_date]);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  console.log("planID", planID);
  console.log("start_date", start_date);
  console.log("end_date", end_date);
  console.log("performanceSettingsHistory", performanceSettingsHistory);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="planning__history__header">
          <div className="">
            <h3 className="h4">View Plan History</h3>
            <p>You can see your set plan history here and filter by year</p>
          </div>

          <div className="">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">Select Year</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a plan year"
                optionFilterProp="children"
                value={planID}
                onChange={(value, option) => {
                  setPlanID(value);
                  setStartDate(dayjs(option?.period?.start_date).format("YYYY-MM-DD"));
                  setEndDate(dayjs(option?.period?.end_date).format("YYYY-MM-DD"));
                }}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={performancePlans?.payload?.map((plan) => ({
                  value: plan?.id,
                  label: `${dayjs(
                    plan?.start_date
                  ).format("YYYY-MM-DD")} — ${dayjs(
                    plan?.end_date
                  ).format("YYYY-MM-DD")} (${plan?.name})`,
                  period: plan
                }))}
              />
            </div>
          </div>
        </div>

        <div className="planning__history__tree">
          {isLoading ? (
            <SkeletonUI />
          ) : (
            <Tree
              showLine
              switcherIcon={<CaretRightOutlined />}
              defaultExpandedKeys={["0-0-0"]}
              onSelect={onSelect}
            >
              {renderTree(data?.payload)}
            </Tree>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanningHistory;
