import React from "react";

const HireActivity = ({ hireProfile }) => {
  return (
    <div>
      <div className="my-3">
        <h4>Applicant's Activity</h4>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <label>Pre Employment</label>

          {hireProfile?.payload?.hire_activities?.completed_actions
            ?.pre_employment ? (
            <div
              className="profile-badge mt-3"
              style={{ width: 100, backgroundColor: "green", fontSize: 14 }}
            >
              Completed
            </div>
          ) : (
            <div
              className="profile-badge mt-3"
              style={{
                width: 100,
                backgroundColor: "orange",
                fontSize: 14,
              }}
            >
              Pending
            </div>
          )}
        </div>
        <div className="col-4">
          <label>Induction</label>

          {hireProfile?.payload?.hire_activities.completed_actions
            ?.induction ? (
            <div
              className="profile-badge mt-3"
              style={{ width: 100, backgroundColor: "green", fontSize: 14 }}
            >
              Completed
            </div>
          ) : (
            <div
              className="profile-badge mt-3"
              style={{
                width: 100,
                backgroundColor: "orange",
                fontSize: 14,
              }}
            >
              Pending
            </div>
          )}
        </div>
        <div className="col-4">
          <label>Onboarding</label>

          {hireProfile?.payload?.hire_activities.completed_actions
            ?.onboarding ? (
            <div
              className="profile-badge mt-3"
              style={{ width: 100, backgroundColor: "green", fontSize: 14 }}
            >
              Completed
            </div>
          ) : (
            <div
              className="profile-badge mt-3"
              style={{
                width: 100,
                backgroundColor: "orange",
                fontSize: 14,
              }}
            >
              Pending
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HireActivity;
