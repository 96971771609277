import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function CompesationPayStructures() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  console.log({ childPath });

  return (
    <>
      <div>
        <HocHeader
          module_title="Compesation & Benefits"
          module_class="performance-header"
          module_name={
            childPath === "job-grading" ? "Job Grading" : "Page Structure"
          }
          module_description="Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default CompesationPayStructures;
