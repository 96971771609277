import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/Approval.module.css";
import DashboardCard from "../../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { Button, Modal } from "antd";

import {
  // user_spinner,
  user_errors,
} from "../../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserMedicalInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import { useOutletContext } from "react-router-dom";
import medicalData from "../../../../util/util.json";
const { genotype, blood_group } = medicalData.medical;

export default function ApproveMedical() {
  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loadingAppN, setLoadingAppN] = useState(false);
  const [exempt, setExempt] = useState([]);

  const [details, setEnabled] = useOutletContext();

  function handleApproveOnly(e) {
    if (details[0]) {
      setLoadingAppN(true);
      approveUserMedicalInfo(
        dispatch,
        { ...values, notify: false, expect: exempt },
        exempt
      ).then((res) => {
        setLoadingAppN(false);
        Modal.destroyAll();
        if (res?.status === "success") {
          setEnabled(true);
        }
      });
    }
  }

  function handleApproveNotify() {
    setLoadingApp(true);
    approveUserMedicalInfo(dispatch, values).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  }

  function handleCheckBox(e) {
    const field = e.target.value;
    const n_arry = [...exempt];
    if (!e.target.checked) {
      if (!n_arry.includes(field)) {
        n_arry.push(field);
        setExempt(n_arry);
      }
    } else {
      if (n_arry.includes(field)) {
        const arry = n_arry.filter((item) => item !== field);
        setExempt(arry);
      }
    }
  }

  if (details[0]) {
    init_details = { ...details[0], except: [], notify: true };
  } else {
    init_details = {
      hmo_plan: "",
      hmo_id_number: "",
      hmo_primary_number: "",
      hmo_secondary_number: "",
      primary_hospital: "",
      primary_hospital_address: "",
      genotype: "",
      blood_group: "",
    };
  }

  const callbackFn = () => {
    if (details[0]) {
      approveandnotify();
    }
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };

  const confirm = (id) => {
    setLoading(true);
    rejectUserInfo(dispatch, { ...values, info_type: "medical" }).then((res) => {
      setLoading(false);
      setShowModal(false);
      Modal.destroyAll();
      
      if (res.status === "success") {
        setEnabled(true);
      }
    });
  };

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    // alert("here");
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <DashboardCard
      title={"Medical Information"}
      icon={<MdPermContactCalendar />}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6 approval_side_checkbox  ">
            <label htmlFor="blood_group">Blood Group</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.blood_group ||
                  (back_errors.blood_group && back_errors.blood_group[0])}
              </small>
            </div>
            <select
              name="blood_group"
              onChange={handleChange}
              value={values.blood_group}
              id="blood_group"
            >
              <option value="">--</option>
              {blood_group.map((b) => (
                <option key={b.id} value={b.key}>
                  {b.name}
                </option>
              ))}
            </select>

            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="blood_group"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="genotype">Genotype</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.genotype ||
                  (back_errors.genotype && back_errors.genotype[0])}
              </small>
            </div>
            <select
              name="genotype"
              onChange={handleChange}
              value={values.genotype}
              id="genotype"
            >
              <option value="">--</option>
              {genotype.map((g) => (
                <option key={g.id} value={g.key}>
                  {g.name}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="genotype"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="hmo_plan">
              HMO Plan <span>*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_plan ||
                  (back_errors.hmo_plan && back_errors.hmo_plan[0])}
              </small>
            </div>
            <input
              type="text"
              name="hmo_plan"
              onChange={handleChange}
              value={values.hmo_plan}
              id="hmo_plan"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="hmo_plan"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="hmo_id_number">
              HMO ID Number <span>*</span>
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_id_number ||
                  (back_errors.hmo_id_number && back_errors.hmo_id_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="hmo_id_number"
              onChange={handleChange}
              value={values.hmo_id_number}
              id="hmo_id_number"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="hmo_id_number"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="hmo_primary_number">HMO Contact Number 1</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_primary_number ||
                  (back_errors.hmo_primary_number &&
                    back_errors.hmo_primary_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="hmo_primary_number"
              onChange={handleChange}
              value={values.hmo_primary_number}
              id="hmo_primary_number"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="hmo_primary_number"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="hmo_secondary_number">HMO Contact Number 2</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_secondary_number ||
                  (back_errors.hmo_secondary_number &&
                    back_errors.hmo_secondary_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="hmo_secondary_number"
              onChange={handleChange}
              value={values.hmo_secondary_number}
              id="hmo_secondary_number"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="hmo_secondary_number"
              onChange={handleCheckBox}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="primary_hospital">Primary Hospital</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.primary_hospital ||
                  (back_errors.primary_hospital &&
                    back_errors.primary_hospital[0])}
              </small>
            </div>

            <input
              type="text"
              name="primary_hospital"
              onChange={handleChange}
              value={values.primary_hospital}
              id="primary_hospital"
            />
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="primary_hospital"
              onChange={handleCheckBox}
            />
          </div>
          <div className="col-6 approval_side_checkbox">
            <label htmlFor="primary_hospital_address">Hospital Address</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.primary_hospital_address ||
                  (back_errors.primary_hospital_address &&
                    back_errors.primary_hospital_address[0])}
              </small>
            </div>
            <textarea
              name="primary_hospital_address"
              onChange={handleChange}
              id="primary_hospital_address"
              value={values.primary_hospital_address}
            ></textarea>
            <input
              type="checkbox"
              className="side_check"
              defaultChecked
              title="Uncheck to exempt this record from being approved"
              value="primary_hospital_address"
              onChange={handleCheckBox}
            />
          </div>
        </div>
        <div className={`${styles.cta}  mt-3`}>
          <Button
            size="large"
            className="myBtn "
            loading={loadingApp}
            htmlType="submit"
            disabled={disabled}
          >
            Approve and notify
          </Button>

          <Button
            size="large"
            className="myBtn "
            loading={loadingAppN}
            onClick={approve}
            disabled={disabled}
          >
            Approve
          </Button>
          <Button
            size="large"
            className="myBtn "
            style={{ backgroundColor: "red", color: "white" }}
            onClick={warning}
            loading={loading}
          >
            Reject
          </Button>
        </div>

        {/* <Modal
          title={`Reject request`}
          centered
          onOk={() => confirm(values?.id)}
          onCancel={modalHandler}
          open={showModal}
          cancelText="No"
          okText="Yes"
        >
          <p className="h6">Are you sure to reject this request?</p>
        </Modal> */}
      </form>
    </DashboardCard>
  );
}
