import React from "react";
import { Routes, Route } from "react-router";
import AnimationLayout from "../ui/AnimationLayout";
import Landing from "./Landing";

function SubscriptionsPage() {
  return (
    <Routes>
      <Route element={<AnimationLayout />}>
        <Route index element={<Landing />} />
      </Route>
    </Routes>
  );
}

export default SubscriptionsPage;
