import { useRouteError } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router";

function ErrorComponent() {
  const error = useRouteError();

  // Determine the error status code (if available)
  let status = error.status;
  if (!status && error.data && error.data.status) {
    status = error.data.status;
  }
  const navigate = useNavigate();

  return (
    <div
      className="expand__reset w-100"
      style={{
        height: "150vh",
        paddingTop: "5rem",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div className="container not-found w-100">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="d-flex flex-column gap-5">
                <h1>Oops! Something went wrong!</h1>
                <p>
                  {status ? (
                    <p>
                      Error {status}: {error.statusText || error.message}
                    </p>
                  ) : (
                    <p>
                      An unexpected error occurred. Please try refreshing the
                      page or contact support.
                    </p>
                  )}

                  {/* Additional error details (optional) */}
                  {error.data && error.data.detail && (
                    <pre>{JSON.stringify(error.data.detail, null, 2)}</pre>
                  )}
                </p>
                <div>
                  <Button className="myBtn" onClick={() => navigate(-1)}>
                    Go Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <img src="/assets/errorboundary.jpg" alt="Something went wrong" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorComponent;
