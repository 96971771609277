import { useState, useEffect, useMemo } from "react";
import { Select, Button, Table } from "antd";
import styles from "../../styles/permissions/AssignGroup.module.css";
import {
  usePreferenceCleanUp,
  useShallowEqualSelector,
} from "../../util/hooks";
import {
  useGetAllClientGroup,
  useGetAllEmployee,
} from "../../util/preferenceshookActions";
import { canAccess, convertToCapitalize } from "../../util/helpers";
import { addEmployeeToGroup } from "../../redux/preference/preferenceActions";
import { useDispatch } from "react-redux";
import {
  preference_spinner,
  preference_errors,
} from "../../util/selectors/preferenceSelectors";
import Pagination from "../ui/Pagination";
import Avatar from "react-avatar";
import HocHeader from "../ui/HocHeader";
import SkeletonUI from "../ui/Skeleton";

function AssignGroup() {
  usePreferenceCleanUp();
  const dispatch = useDispatch();
  const [enabled_group, setEnabledGroup] = useState(true);
  const [page_group] = useState(1);
  const [group_show, setgroupShow] = useState([]);

  const [enabled_emp, setEnabledEmp] = useState(true);
  const [page_emp] = useState(1);
  const [emp_show, setEmpShow] = useState([]);

  const [group_ids, setGroupId] = useState([]);
  const [user_ids, setUserId] = useState([]);
  const preferenceSpinner = useShallowEqualSelector(preference_spinner);
  const back_errors = useShallowEqualSelector(preference_errors);
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // Search and filter
  const [query, setQuery] = useState("");

  const pageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const { data: group } = useGetAllClientGroup(
    enabled_group,
    setEnabledGroup,
    page_group
  );
  const { data: emp, isLoading } = useGetAllEmployee(
    enabled_emp,
    setEnabledEmp,
    page_emp
  );

  const group_data = useMemo(() => group?.payload?.data, [group]);
  const emp_data = useMemo(() => emp?.payload?.all_employees?.data, [emp]);

  useEffect(() => {
    if (group_data?.length) {
      const new_arry = group_data.map((grp) => {
        return {
          label: convertToCapitalize(grp.role),
          value: grp.id,
        };
      });
      setgroupShow(new_arry);
    } else if (!group_data?.length) {
      setgroupShow([]);
    }
  }, [group_data]);

  useEffect(() => {
    if (emp_data?.length) {
      setEmpShow(emp_data);
    } else if (!emp_data?.length) {
      setEmpShow([]);
    }
  }, [emp_data]);

  function handleChangeGroup(id) {
    setGroupId(id);
  }
  function handleChangeEmp(e, id) {
    e.preventDefault();

    if (user_ids.includes(id)) {
      const new_arry = [...user_ids];

      const filtered = new_arry.filter((emp_id) => emp_id !== id);

      setUserId([...filtered]);
    }
    if (!user_ids.includes(id)) {
      setUserId([...user_ids, id]);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const creds = { user_ids, role_ids: group_ids };
    addEmployeeToGroup(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        setGroupId([]);
        setUserId([]);
      }
    });
  }

  const handleSearch = (e) => {
    setQuery(e.target.value.toLowerCase()); // Store the search query in lowercase
  };

  const filteredEmpShow = useMemo(() => {
    if (!query) return emp_show;

    return emp_show.filter((emp) =>
      Object.values(emp).some((value) =>
        String(value).toLowerCase().includes(query)
      )
    );
  }, [emp_show, query]);

  useEffect(() => {
    if (filteredEmpShow) {
      const new_array = filteredEmpShow.map((emp, index) => {
        return {
          key: emp.e_id,
          employee: emp.fullname,
          department: emp?.department?.name || "N/A",
          emp,
        };
      });
      setTableData(new_array);
    }
  }, [filteredEmpShow]);

  const usersToDisplay = emp_show;

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
      render: (_, records) => (
        <EachAction
          item={records}
          handleChangeEmp={handleChangeEmp}
          user_ids={user_ids}
        />
      ),
    },
  ];

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Permissions Management"
        module_name="Assign User(s) Group"
        module_description="
      HR Managers and privileged administrators can view all your new
      hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />
      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <div className="app__section py-4">
            <h2 className="greeting">Assign User(s) Group</h2>
            <div className="container-fluid">
              <form className={styles.main} onSubmit={handleSubmit}>
                <div className={styles.group}>
                  <label htmlFor="user-groups">Select Group</label>
                  <div className="w-100 text-right text-danger">
                    <small>
                      {back_errors.role_ids && back_errors.role_ids[0]}
                    </small>
                  </div>
                  <Select
                    placeholder="Please select group"
                    mode="multiple"
                    className={styles.select}
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    options={group_show}
                    onChange={handleChangeGroup}
                    value={group_ids}
                  />
                </div>

                {canAccess(
                  "assign-users-to-group-human-resource-information-system-preferences"
                ) && (
                  <Button
                    disabled={!user_ids.length}
                    loading={preferenceSpinner}
                    htmlType="submit"
                    style={{
                      backgroundColor: "var(--clr-primary)",
                      color: "white",
                    }}
                    size="large"
                  >
                    Assign User(s)
                  </Button>
                )}
              </form>
              {user_ids.length > 0 && (
                <>
                  <h5>Selected Users</h5>
                  <div className={styles.users}>
                    {user_ids.map((id) => {
                      const newAllUser = [...emp_show];
                      const foundId = newAllUser.find(
                        (user) => user.e_id === id
                      );
                      return (
                        <div
                          className={styles.selectedUser}
                          key={foundId?.e_id}
                        >
                          <Avatar
                            name={`${foundId?.first_name} ${
                              foundId?.last_name || ""
                            }`}
                            size={25}
                            round
                          />
                          {foundId?.fullname}
                          <button
                            onClick={(e) => {
                              handleChangeEmp(e, foundId?.e_id);
                            }}
                          >
                            &#10005;
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              <div className={styles.users_section}>
                <input
                  type="text"
                  name="search_users"
                  placeholder="Search for users"
                  onChange={handleSearch}
                  value={query}
                />
                <div className="w-100">
                  {isLoading ? (
                    <SkeletonUI number={2} />
                  ) : usersToDisplay.length ? (
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      bordered
                      pagination={false}
                    />
                  ) : (
                    <p>No User Found!</p>
                  )}
                </div>
                <Pagination
                  data={emp_show.length}
                  currentPage={currentPage}
                  pageSize={21}
                  onPageChange={pageChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function EachAction({ item, handleChangeEmp, user_ids }) {
  return (
    <>
      <div className="flex justify-content-center">
        <Button
          shape="round"
          type="primary"
          size="medium"
          onClick={(e) => {
            handleChangeEmp(e, item?.emp?.e_id);
          }}
          // id={item?.emp?.e_id}
          danger={user_ids?.includes(item?.emp?.e_id) ? true : false}
        >
          {user_ids?.includes(item?.emp?.e_id) ? "Remove" : "Add"}
        </Button>
      </div>
    </>
  );
}

export default AssignGroup;
