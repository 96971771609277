import React from "react";

function NoContent({ illustration, message, width }) {
  // console.log(illustration);
  return (
    <div
      className="d-flex flex-column align-items-center text-center"
      style={{
        marginTop: "0rem",
      }}
    >
      <img
        src={illustration || "/assets/empty.png"}
        alt="404"
        width={width || 200}
      />
      {message ? (
        <p className="mt-3 h5" style={{ maxWidth: "50%" }}>
          {message}
        </p>
      ) : null}
    </div>
  );
}

export default NoContent;
