import React, { useState } from "react";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";

import HocHeader from "../ui/HocHeader";
import { Doughnut, Bar } from "react-chartjs-2";

import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";

import {
  useGetPerformanceManagementPersonalOverview,
  useGetPerformanceManagementSummary,
  useGetPerformanceMgtDepartmentAnalytics,
  useGetPerformanceMgtOrganisationAnalytics,
} from "../../util/usershookActions";

import { colorArray } from "../../util/helpers";

import dayjs from "dayjs";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CanAccess from "../hoc/CanAccess";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Overview() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);

  const { data: personal_overview, isLoading: isLoadingPersonalOverview } =
    useGetPerformanceManagementPersonalOverview(enabled, setEnabled);

  const { data: summary, isLoading: isLoadingSummary } =
    useGetPerformanceManagementSummary(enabled, setEnabled);

  const { data: departmentAnalytics, isLoading: isLoadingDeptAnalytics } =
    useGetPerformanceMgtDepartmentAnalytics(enabled, setEnabled);

  const { data: organisationAnalytics, isLoading: isLoadingOrgAnalytics } =
    useGetPerformanceMgtOrganisationAnalytics(enabled, setEnabled);

  const cardBgColors = [
    "#D7FFEC94",
    "#CD602330",
    "#62D9FE38",
    "#21231E14",
    "#FE626238",
    "#D7E2FF",
    "#E8F5FF",
    "#FFECEC",
  ];

  const data = {
    labels: [
      `Previous Year: ${organisationAnalytics?.payload?.scores?.previous_year}`,
      `Current Year: ${organisationAnalytics?.payload?.scores?.current_year}`,
    ],
    datasets: [
      {
        label: "Scores",
        data: [
          organisationAnalytics?.payload?.scores?.previous_year,
          organisationAnalytics?.payload?.scores?.current_year,
        ],
        backgroundColor: ["#f53f1b", "#3a54e8"],
        borderColor: ["#ffffff", "#ffffff"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
      },
    },
  };

  const labels =
    departmentAnalytics?.payload?.data?.map((dept) => dept.department) || [];

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Previous Year",
        data: labels.map((dept) => {
          const entry = departmentAnalytics?.payload?.data.find(
            (d) => d.department === dept
          );
          return entry ? entry.previous_year_score : 0;
        }),
        backgroundColor: colorArray[0],
        borderColor: colorArray[0],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
      {
        label: "Current Year",
        data: labels.map((dept) => {
          const entry = departmentAnalytics?.payload?.data.find(
            (d) => d.department === dept
          );
          return entry ? entry.current_year_score : 0;
        }),
        backgroundColor: colorArray[1],
        borderColor: colorArray[1],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_name="Performance Management"
        module_description=" You can initiate your organizational goal, set strategic
              initiatives and key performance indicators for your departments
              and employees."
        module_img="/assets/landing-page/Payroll managementpng.png"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4 mb-4">
            <h5>Personal Overview</h5>

            {isLoadingPersonalOverview ? (
              <SkeletonUI />
            ) : (
              <div className="row mt-4">
                {personal_overview?.payload && Object.keys(personal_overview?.payload)?.map((key, index) => {
                  const randomIndex = Math.floor(
                    Math.random() * cardBgColors.length
                  );
                  const randomColor = cardBgColors[randomIndex];
                  return (
                    <div className="col-md-3 mb-4" key={index}>
                      <div
                        className="insight"
                        style={{ backgroundColor: randomColor }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiPulseLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h6 className="text-capitalize">
                                {key.split("_").join(" ")}
                              </h6>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                                {personal_overview?.payload[key]}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </section>

          <CanAccess
            isRoute={false}
            can_access={"view-performance-management-overview"}
          >
            <section className="app__section py-4 mb-4">
              <h5>Summary</h5>
              {isLoadingSummary ? (
                <SkeletonUI />
              ) : (
                <div className="row mt-4">
                  {summary?.payload &&
                    Object.keys(summary?.payload).map((key, index) => {
                      const randomIndex = Math.floor(
                        Math.random() * cardBgColors.length
                      );
                      const randomColor = cardBgColors[randomIndex];

                      return (
                        <div className="col-md-3 mb-4" key={index}>
                          <div
                            className="insight"
                            style={{ backgroundColor: randomColor }}
                          >
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <RiPulseLine />
                              </div>
                              <div>
                                <div className="insight-title">
                                  <h6 className="text-capitalize">
                                    {key.split("_").join(" ")}
                                  </h6>
                                </div>
                                <div className="insight-content">
                                  <h2 className="mb-0">
                                    {summary?.payload[key]}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </section>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    TARGET:{" "}
                    {`${dayjs(departmentAnalytics?.payload?.start_date).format(
                      "DD MMMM, YYYY"
                    )} - ${dayjs(departmentAnalytics?.payload?.end_date).format(
                      "DD MMMM, YYYY"
                    )}`}
                  </h5>

                  <span>
                    Comparison between goals achieved between departments in two
                    consecutive years
                  </span>

                  {isLoadingDeptAnalytics ? (
                    <SkeletonUI />
                  ) : departmentAnalytics?.payload?.data ? (
                    <Bar options={optionsBar} data={dataBar} />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">
                    TARGET:{" "}
                    {`${dayjs(
                      organisationAnalytics?.payload?.start_date
                    ).format("DD MMMM, YYYY")} - ${dayjs(
                      organisationAnalytics?.payload?.end_date
                    ).format("DD MMMM, YYYY")}`}
                  </h5>

                  <span>
                    Comparison between goals achieved in the organisation
                    between two consecutive years
                  </span>
                  {isLoadingOrgAnalytics ? (
                    <SkeletonUI />
                  ) : organisationAnalytics?.payload?.scores?.previous_year >
                      0 ||
                    organisationAnalytics?.payload?.scores?.current_year > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30rem",
                        }}
                      >
                        <Doughnut options={options} data={data} />
                      </div>
                    </div>
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
        </div>
      </main>
    </>
  );
}

export default Overview;
