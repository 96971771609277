import React, { useState, useEffect } from "react";
import { Button, Steps } from "antd";
import UploadLogo from "../dashboard/UploadLogo";
import CompanyDetails from "../dashboard/CompanyDetails";
import SetPassword from "../dashboard/SetPassword";
import UploadEmployee from "../dashboard/UploadEmployee";
import CustomizeDisplay from "../dashboard/CustomizeDisplay";
import Confirmation from "../dashboard/Confirmation";
import { useDispatch } from "react-redux";
import {
  user_current_quick_setup,
  // user_errors,
  user_spinner,
} from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import {
  callQuickSetupEndpoint,
  sendNewEmployeeMail,
} from "../../redux/users/userActions";
import { hashData, dehashData } from "../../util/hash";
import { storage_type } from "../../config";

const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;

const steps = [
  {
    title: "Logo Upload",
    content: <UploadLogo />,
  },
  {
    title: "Company Details",
    content: <CompanyDetails />,
  },
  {
    title: "Password Settings",
    content: <SetPassword />,
  },

  {
    title: "Employees Profile",
    content: <UploadEmployee />,
  },

  {
    title: "Display Settings",
    content: <CustomizeDisplay />,
  },

  {
    title: "Confirmation",
    content: <Confirmation />,
  },
];

const Levels = ({ showModal, finalStep }) => {
  const [current, setCurrent] = useState(0);
  const [back, setBack] = useState(false);
  const dispatch = useDispatch();
  const current_quick_setup = useShallowEqualSelector(user_current_quick_setup);
  const spinner = useShallowEqualSelector(user_spinner);

  const next = () => {
    let new_field = {};

    const { endpoint, field, name } = current_quick_setup;

    for (var key in field) {
      if (field.hasOwnProperty(key)) {
        if (field[key] !== null) {
          new_field[key] = field[key];
        }
      }
    }

    if (Object.keys(new_field).length > 0) {
      callQuickSetupEndpoint(dispatch, endpoint, new_field, name).then(
        (result) => {
          if (result?.status === "success") {
            if (name === "Employees Profile") {
              sendNewEmployeeMail(dispatch).then((res) => {
                if (res?.status === "success") {
                  setCurrent(current + 1);
                }
              });
            } else {
              setCurrent(current + 1);
            }
          }
        }
      );
    } else {
      setCurrent(current + 1);
      setBack(false);
      setBack(false);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setBack(true);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    dispatch({ type: "SET_CURRENT_SETUP", payload: current, back });
  }, [current, back, dispatch]);

  const finishSetup = () => {
    finalStep();
    showModal(false);

    const auth_details = dehashData(AUTH_DETAILS);

    const new_auth_details = {
      ...auth_details,
      show_quick_set: false,
    };

    const hash_auth_details = hashData(new_auth_details);
    storage_type.setItem(AUTH_DETAILS, hash_auth_details);
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div className="steps-content mt-5 mx-0 mx-auto">
        {steps[current].content}
      </div>
      <div className="steps-action d-flex justify-content-between align-items-center text-success">
        <small>* These settings can be changed later</small>
        <div>
          {current > 0 && (
            <Button
              size="large"
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              size="large"
              onClick={() => next()}
              className="nextStep"
              disabled={current_quick_setup.required}
              loading={spinner}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button size="large" className="finalStep" onClick={finishSetup}>
              Finish
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default Levels;
