const Template = ({ id, title, desc, activity_count, handleSelect }) => {
  const templateStyle = {};
  return (
    <div
      className="template"
      onClick={() => {
        handleSelect(id);
      }}
      style={templateStyle}
    >
      <h4>{title}</h4>
      <p>
        {desc ||
          "Template is to be used for hires who are in the professional management trainee and other related categories only"}
      </p>
      <div className="d-flex align-items-center gap-2">
        <span className="activity_count">{activity_count}</span> Activities
      </div>
    </div>
  );
};

export default Template;
