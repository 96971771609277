import { createSelector } from "reselect";

const preference_spinner = createSelector(
  (state) => state.preference,
  (preference) => preference.spinner
);
const preference_errors = createSelector(
  (state) => state.preference,
  (preference) => preference.errors
);
const preference_message = createSelector(
  (state) => state.preference,
  (preference) => preference.message
);

const preference_status = createSelector(
  (state) => state.preference,
  (preference) => preference.status
);
const client_permissions = createSelector(
  (state) => state.preference,
  (preference) => preference.client_permissions
);
const permissions_to_add = createSelector(
  (state) => state.preference,
  (preference) => preference.permissions_to_add
);
const single_group = createSelector(
  (state) => state.preference,
  (preference) => preference.single_group
);
const permission_in_group = createSelector(
  (state) => state.preference,
  (preference) => preference.permission_in_group
);
const system_banks = createSelector(
  (state) => state.preference,
  (preference) => preference.system_banks
);
const all_groups = createSelector(
  (state) => state.preference,
  (preference) => preference.all_groups
);

export {
  preference_spinner,
  preference_errors,
  preference_message,
  preference_status,
  client_permissions,
  permissions_to_add,
  single_group,
  permission_in_group,
  system_banks,
  all_groups,
};
