import { Checkbox } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbCalendarStats } from "react-icons/tb";
import { IoIosFlag } from "react-icons/io";
import { GiBullseye } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";


const Task = () => {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <div
      className="d-flex gap-2"
      style={{
        borderBottom: "1px solid #d9d9d9",
        paddingBottom: "1rem",
      }}
    >
      <div className="task__checkbox">
        <Checkbox onChange={onChange}></Checkbox>
      </div>
      <div className="d-flex flex-column w-100  gap-3">
        <div className="d-flex justify-content-between">
          <div className="">
            <h5 className="h6">Task Title</h5>
            <span className="text-muted">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </div>

          <div className="d-flex gap-2">
            <CiEdit size={20} />
            <RiDeleteBin6Line size={20} />
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            <TbCalendarStats size={20} />
            <span>5 PM / Plan</span>
          </div>
          <div className="">
            <span>
              My Work <GiBullseye size={20} />
            </span>
            {" / "}
            <span>
              <IoIosFlag size={20} /> Priority 3 #
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
