import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function LeaveGroup() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Leave Management"
          module_class="performance-header"
          module_name={
            childPath === "upload-leave-group"
              ? "Upload Leave Type"
              : "Leave Type List"
          }
          module_description="Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
          module_img="/assets/landing-page/Leave Management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default LeaveGroup;
