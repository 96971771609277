import React from "react";
import PendingTable from "./PendingTable";

import { useParams } from "react-router-dom";

function PendingApprovalTable() {
  const { id, fname, lname } = useParams();

  // console.log(useParams());

  return (
    <>
      <h5>
        Pending Approvals ({fname} {lname === "Admin" ? "" : lname})
      </h5>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <PendingTable emp_id={id} />
        </div>
      </div>
    </>
  );
}

export default PendingApprovalTable;
