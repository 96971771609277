import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import { Input, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DatePicker } from "antd";
import { useState } from "react";
import UnitOfMeasure from "../../settings/UnitOfMeasure";
import dayjs from "dayjs";

import {
  useGetSystemDepartments,
  useGetPerformanceMeasurementUnits,
} from "../../../../util/usershookActions";

const TargetForm = ({
  TargetNumber,
  handleAddForm,
  handleRemoveForm,
  id,
  index,
  target,
  unitID,
  startDate,
  endDate,
  departmentID,
  weight,
  description,
  availableWeight,
  onChange,
  onWeightChange,
  exceedsMax,
}) => {
  const [enabled, setEnabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: departmentsList } = useGetSystemDepartments(
    enabled,
    setEnabled
  );

  const { data: unitsList } = useGetPerformanceMeasurementUnits(
    enabled,
    setEnabled
  );

  const handleInputChange = (name, value) => {
    onChange(id, name, value);
  };

  const handleQuillChange = (content, delta, source, editor) => {
    handleInputChange("description", content);
  };

  const handleWeightInputChange = (e) => {
    const newWeight = parseInt(e.target.value, 10) || "";
    console.log("newWeight", newWeight);
    onWeightChange(id, newWeight);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleViewModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="planning__form__wrapper">
      <div className="planning__form__top__buttons">
        <span>
          <AiFillPlusCircle
            size={32}
            color={"green"}
            style={{
              cursor: "pointer",
            }}
            onClick={handleAddForm}
          />
          <IoIosRemoveCircle
            size={32}
            color={"red"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleRemoveForm(TargetNumber)}
          />
        </span>
      </div>
      <div className="planning__form">
        <h5 className="planning__form__title">{`Target ${index + 1}`}</h5>
        <div className="planning__form__content">
          <div className="d-flex flex-column gap-2 planning__form__item__first__half ">
            <label className="fw-bold fs-6">Target</label>
            <div className="target__form__group">
              <input
                type="number"
                size="large"
                id="target"
                value={target}
                style={{
                  border: "none",
                  width: "100%",
                }}
                onChange={(e) => onChange(id, "target", e.target.value)}
                onWheel={(e) => e.target.blur()}
                required
              />
              <Select
                showSearch
                size="large"
                placeholder="Select unit"
                optionFilterProp="children"
                value={unitID && Number(unitID)}
                onChange={(value) => onChange(id, "unit_id", value)}
                filterOption={filterOption}
                options={unitsList?.payload?.map((unit) => ({
                  value: unit?.id,
                  label: unit?.name,
                }))}
                bordered={false}
                style={{
                  width: "100%",
                  minWidth: '8rem'
                }}
              />
              <span className="d-flex align-items-center px-2">
                <AiFillPlusCircle
                  size={25}
                  color={"green"}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleViewModal}
                />
              </span>
            </div>
          </div>
          <UnitOfMeasure
            isModalOpen={isModalOpen}
            handleViewModal={handleViewModal}
          />

          <div className="d-flex flex-column gap-2 planning__form__item__second__half">
            <label className="fw-bold fs-6 d-flex justify-content-between">
              <span className="">Weight</span>
              {exceedsMax && (
                <small className="text-danger">
                  {`Weight cannot exceed available weight: ${availableWeight}`}
                </small>
              )}
            </label>
            <input
              type="number"
              min={1}
              max={availableWeight}
              size="large"
              id="weight"
              value={weight}
              style={{
                border: "2px solid black",
                width: "100%",
              }}
              onChange={handleWeightInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item__first__half">
            <label className="fw-bold fs-6">Start Date</label>
            <DatePicker
              size="large"
              onChange={(val, dateSelected) =>
                onChange(id, "start_date", dateSelected)
              }
              value={startDate !== "" ? dayjs(startDate, "YYYY-MM-DD") : null}
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item__second__half">
            <label className="fw-bold fs-6">End Date</label>{" "}
            <DatePicker
              size="large"
              onChange={(val, dateSelected) =>
                onChange(id, "end_date", dateSelected)
              }
              value={endDate !== "" ? dayjs(endDate, "YYYY-MM-DD") : null}
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item">
            <label className="fw-bold fs-6">Department</label>
            <Select
              showSearch
              size="large"
              placeholder="Select a department"
              optionFilterProp="children"
              value={departmentID}
              onChange={(value) => onChange(id, "department_id", value)}
              filterOption={filterOption}
              style={{
                border: "2px solid black",
                borderRadius: "10px",
              }}
              options={departmentsList?.payload?.map((department) => ({
                value: department?.id,
                label: department?.name,
              }))}
            />
          </div>

          <div className="d-flex flex-column gap-2 planning__form__item">
            <label className="fw-bold fs-6">Description</label>
            <ReactQuill
              theme="snow"
              id="body"
              placeholder=""
              value={description}
              onChange={handleQuillChange}
              style={{
                border: "2px solid black",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetForm;
