import React from "react";

import { NavLink } from "react-router-dom";

function DepartmentDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"department"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">List Departments</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"department/upload-departments"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload Departments</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default DepartmentDropdown;
