import React, { useState, useEffect } from "react";

import { Button, Modal, List, Select } from "antd";
import { useDispatch } from "react-redux";
import {
  useGetSystemSettingsUtils,
  useGetSystemDepartments,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { department, user_errors } from "../../../util/selectors/userSelectors";
import { addEditDeptment } from "../../../redux/users/userActions";

import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import DepartmentEachList from "../settings/department/DepartmentEachList";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import InputCategories from "../settings/input_entries/InputCategories";

import { AiFillPlusCircle } from "react-icons/ai";

function DepartmentList() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [enableddet, setEnabledDet] = useState(true);
  const [addDept, setAddDept] = useState(false);
  const [users, setUsers] = useState([]);
  const [showCatpage, setShowCatpage] = useState(false);
  const [selectedCat, setSelectedCat] = useState({});

  const [values, setValues] = useState({
    name: "",
    code: "",
    description: "",
    hod_id: "",
    category: "",
  });

  const {
    refetch: refetchUtils,
    isLoading: isLoadingUtils,
    data: utils,
  } = useGetSystemSettingsUtils(enabled, setEnabled);

  const { refetch, isLoading } = useGetSystemDepartments(enabled, setEnabled);

  useGetAllEmployee(enableddet, setEnabledDet, "all");

  const all_users = useShallowEqualSelector(all_groups);
  const back_errors = useShallowEqualSelector(user_errors);

  const sys_departments = useShallowEqualSelector(department);

  const clearSelected = () => {
    setSelected({});
    setValues({
      name: "",
      code: "",
      description: "",
      hod_id: "",
      category: "",
    });
  };

  const handleAddDept = () => {
    clearSelected();
    setAddDept(!addDept);
  };

  const handleSelected = (item) => {
    setSelected(item);
    setValues(item);
    setAddDept(true);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(values);
    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditDeptment(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          name: "",
          code: "",
          description: "",
          hod_id: "",
          category: "",
        });

        if (Object.keys(selected).length) {
          dispatch({
            type: "UPDATE_TO_DEPARTMENT",
            payload: res.payload.department,
          });
        } else {
          dispatch({
            type: "ADD_TO_DEPARTMENT",
            payload: res.payload.department,
          });
        }
        setAddDept(false);
      }
    });
  };

  useEffect(() => {
    if (sys_departments.length) {
      setData(sys_departments);
    } else {
      setData([]);
    }
  }, [sys_departments]);

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  const handleCatpage = () => {
    setShowCatpage(!showCatpage);
  };

  const handleCatSelected = (item) => {
    console.log(item);
    setSelectedCat(item);
    setShowCatpage(true);
  };

  // console.log("values", values);

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4 ">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available Departments</h5>
              <small className="w-75 d-none d-sm-block">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                magnam qui perspiciatis animi doloribus velit aperiam excepturi
                tempora culpa consequatur laboriosam pariatur dicta eius totam,
                minus natus praesentium. Exercitationem, non?
              </small>
            </div>
          </div>

          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center mb-4">
              <div className="circle__bagde fw-bold">{data?.length}</div>
              <h6 className="mb-0"> Departments</h6>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}
                {addDept ? (
                  <form className="onboarding-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="name">Name</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.name && back_errors.name[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Department name"
                            type="text"
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="code">Code</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.code && back_errors.code[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            type="text"
                            id="code"
                            name="code"
                            value={values.code || ""}
                            onChange={handleChange}
                            placeholder="Departmental code"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="description">Description</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.description &&
                                  back_errors.description[0]}
                              </small>
                            </div>
                          </div>
                          <input
                            type="text"
                            placeholder="Department's description"
                            id="description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="hod_id">HOD</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.hod_id && back_errors.hod_id[0]}
                              </small>
                            </div>
                          </div>
                          <select
                            class="form-control form-control-sm "
                            id="hod_id"
                            name="hod_id"
                            value={values.hod_id}
                            onChange={handleChange}
                          >
                            <option value="">--</option>
                            {users.map((user) => (
                              <option value={user.id} key={user.id}>
                                {user.fullname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="category">Category</label>
                              <div className="w-100 text-right text-danger d-flex justify-content-end">
                                <small>
                                  {back_errors.category &&
                                    back_errors.category[0]}
                                </small>
                              </div>
                            </div>
                            <div className="hris_form_group">
                              <select
                                class="form-control form-control-sm "
                                id="category"
                                name="category"
                                value={values.category}
                                onChange={handleChange}
                              >
                                <option value="">--</option>
                                {Object.keys(utils?.payload).length &&
                                utils?.payload?.system_inputs[
                                  "Department Category"
                                ].length
                                  ? utils?.payload?.system_inputs[
                                      "Department Category"
                                    ].map((item) => {
                                      return (
                                        <option key={item.id} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>

                              <span className="d-flex align-items-center px-2">
                                <AiFillPlusCircle
                                  size={25}
                                  color={"green"}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleCatSelected({
                                      title: "Department Category",
                                      input:
                                        utils?.payload?.system_inputs?.[
                                          "Department Category"
                                        ],
                                    })
                                  }
                                />
                              </span>
                            </div>
                          </div>

                          <Modal
                            open={showCatpage}
                            footer={null}
                            centered
                            onCancel={handleCatpage}
                          >
                            <InputCategories
                              handleCatpage={handleCatpage}
                              selected={selectedCat}
                              refetch={refetch}
                            />
                          </Modal>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div>
                        <Button
                          className="myBtn mt-3 mx-2"
                          onClick={() => {
                            clearSelected();
                            handleAddDept();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="myBtn  mt-3"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div
                    style={{
                      maxHeight: 500,
                      overflowY: "auto",
                    }}
                    className="my-3"
                  >
                    {isLoading ? (
                      <SkeletonUI number={2} />
                    ) : data.length ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                          <DepartmentEachList
                            item={item}
                            handleSelected={handleSelected}
                            refetch={refetch}
                          />
                        )}
                      />
                    ) : (
                      <NoContent
                        message={"No system department available."}
                        width={"400px"}
                      />
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  {!addDept && (
                    <Button className="myBtn  mt-3" onClick={handleAddDept}>
                      Add Department
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DepartmentList;
