import { useState, useEffect } from "react";
import { Table, Button, Input, Modal, Checkbox, Alert } from "antd";
import { FaComment } from "react-icons/fa";
import {
  useGetEmployeeScoreCard,
  useGetLineManagerAppraisal,
  useGetAppraisalSettings,
} from "../../../../util/usershookActions";
import {
  setEmployeeScore,
  addEmployeeDeliverableComment,
} from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import { useParams } from "react-router-dom";

const EmployeeAppraisalTable = () => {
  const [enabled, setEnabled] = useState(true);
  const [employeeScoreLoading, setEmployeeScoreLoading] = useState(false);

  const { TextArea } = Input;

  const { teamMemberID } = useParams();

  const [tableData, setTableData] = useState();
  const dispatch = useDispatch();

  const { data: appraisalSettings } = useGetAppraisalSettings(
    enabled,
    setEnabled
  );

  const {
    data: lineManagerAppraisal,
    isLoading: isLoadingLineManagerAppraisal,
    refetch: refetchLineManagerAppraisal,
    error: errorLineManagerAppraisal,
  } = useGetLineManagerAppraisal(enabled, setEnabled, teamMemberID);

  const {
    data: employeeScoreCard,
    isLoading,
    refetch,
  } = useGetEmployeeScoreCard(enabled, setEnabled);

  const [cascade_ids, setCascadeIds] = useState([]);
  const [employee_score, setEmployeeScores] = useState([]);
  const [summary_comment, setSummaryComment] = useState();
  const [checkboxValues, setCheckboxValues] = useState({
    training: false,
    promotion: false,
    transfer: false,
    salary_adjustment: false,
    extension_of_probationary_period: false,
    review_of_continued_employment: false,
    confirmation_of_appointment: false,
  });

  console.log("lineManagerAppraisal", lineManagerAppraisal?.payload == null);

  useEffect(() => {
    if (lineManagerAppraisal?.payload == null) {
      return;
    } else {
      setSummaryComment(
        lineManagerAppraisal.payload.summary_comments.employee_comment
      );
      // Initialize a new state object
      let newState = {
        training: lineManagerAppraisal.payload.training === 1,
        promotion: lineManagerAppraisal.payload.promotion === 1,
        transfer: lineManagerAppraisal.payload.transfer === 1,
        salary_adjustment: lineManagerAppraisal.payload.salary_adjustment === 1,
        extension_of_probationary_period:
          lineManagerAppraisal.payload.extension_of_probationary_period === 1,
        review_of_continued_employment:
          lineManagerAppraisal.payload.review_of_continued_employment === 1,
        confirmation_of_appointment:
          lineManagerAppraisal.payload.confirmation_of_appointment === 1,
      };

      setCheckboxValues(newState);
    }
  }, [lineManagerAppraisal]);

  useEffect(() => {
    if (employeeScoreCard) {
      const newCascadeIds = employeeScoreCard.payload.map(
        (scoreCard) => scoreCard.id
      );
      const newEmployeeScores = employeeScoreCard.payload.map(
        (scoreCard) => scoreCard.achieved.employee_score
      );
      setCascadeIds(newCascadeIds);
      setEmployeeScores(newEmployeeScores);
    }
  }, [employeeScoreCard]);

  const handleScoreChange = (index, value) => {
    const updatedScores = [...employee_score];
    updatedScores[index] = value;
    setEmployeeScores(updatedScores);
  };

  useEffect(() => {
    if (employeeScoreCard) {
      const new_array = employeeScoreCard?.payload?.map((scoreCard, index) => {
        // Calculate the actual score based on user input
        const user_input = Number(employee_score[index]);
        const actual_score = parseFloat(
          (
            (user_input / scoreCard.individual_target) *
            scoreCard.weight
          ).toFixed(2)
        );

        return {
          key: scoreCard.id,
          section: scoreCard.section,
          goal: scoreCard.goal,
          objective: scoreCard.objective,
          kpi: scoreCard.kpi,
          target: `${scoreCard.individual_target} (${scoreCard.unit})`,
          weight: scoreCard.weight,
          ...Object.keys(scoreCard.achieved).reduce((acc, level) => {
            if (level !== "employee_score") {
              acc[level] = scoreCard.achieved[level];
            } else {
              acc[level] = (
                <Input
                  size="large"
                  id={`score-${scoreCard.id}`}
                  defaultValue={scoreCard.achieved[level]}
                  placeholder="Type score here"
                  style={{
                    backgroundColor: scoreCard.achieved[level]
                      ? "#fff"
                      : "transparent",
                    cursor: scoreCard.achieved[level] ? "text" : "pointer",
                    border: "1px solid black",
                    borderColor: scoreCard.achieved[level]
                      ? "transparent"
                      : "#d9d9d9",
                    borderRadius: "6px",
                  }}
                  bordered={Boolean(scoreCard.achieved[level])}
                  addonAfter={scoreCard.unit}
                  // value={employee_score[index]}
                  onChange={(e) => {
                    handleScoreChange(index, Number(e.target.value));
                    // Trigger a re-render to update the actual score
                    setTableData((prev) => [...prev]);
                  }}
                />
              );
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.actual_score).reduce((acc, level) => {
            if (level === "employee_actual_score") {
              acc[level] = isNaN(actual_score)
                ? scoreCard.actual_score[level]
                : actual_score;
            } else {
              acc[level] = scoreCard.actual_score[level];
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.score).reduce((acc, period) => {
            acc[period] = scoreCard.score[period];
            return acc;
          }, {}),
          scoreCard,
        };
      });
      setTableData(new_array);
    }
  }, [employeeScoreCard, employee_score]);

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "center",
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "KPI",
      dataIndex: "kpi",
      align: "center",
    },
    {
      title: "Target",
      dataIndex: "target",
      align: "center",
    },
    {
      title: "Achieved Score",
      align: "center",
      children: Object.keys(
        employeeScoreCard?.payload?.[0]?.achieved || {}
      ).map((level) => ({
        width: level === "employee_score" ? "15rem" : "auto",
        title: level
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        dataIndex: level,
        align: "center",
        render: (text) => (text === null ? "N/A" : text),
      })),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      align: "center",
    },
    {
      title: "Actual Score",
      children: Object.keys(
        employeeScoreCard?.payload?.[0]?.actual_score || {}
      ).map((level) => ({
        title: `${level} (%)`
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        dataIndex: level,
        align: "center",
        render: (text) => (text === null ? "N/A" : text),
      })),
    },
    {
      title: "Score",
      align: "center",
      children: Object.keys(employeeScoreCard?.payload?.[0]?.score || {}).map(
        (period) => ({
          title: period.toUpperCase(),
          dataIndex: period,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Deliverable Comment",
      dataIndex: "sucomment",
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <EachAction
          item={record}
          refetch={refetch}
          deliverableCommentAbility={
            appraisalSettings?.payload?.first_appraiser?.comment_on_deliverable
          }
        />
      ),
    },
  ];

  const handleSetEmployeeScore = (e) => {
    const creds = {
      cascade_ids,
      employee_score,
      summary_comment,
    };
    e.preventDefault();
    setEmployeeScoreLoading(true);
    setEmployeeScore(dispatch, creds).then(() => {
      setEmployeeScoreLoading(false);
      refetch();
      refetchLineManagerAppraisal();
    });
  };

  const options = [
    { label: "Training", value: "training" },
    { label: "Promotion", value: "promotion" },
    { label: "Transfer", value: "transfer" },
    { label: "Salary Adjustment", value: "salary_adjustment" },
    {
      label: "Extension of Probationary Period",
      value: "extension_of_probationary_period",
    },
    {
      label: "Review of Continued Employment",
      value: "review_of_continued_employment",
    },
    {
      label: "Confirmation of Appointment",
      value: "confirmation_of_appointment",
    },
  ];

  return (
    <>
      {isLoading ? (
        <SkeletonUI />
      ) : (
        <form className="begin__appraisal__content__form">
          <div className="begin__appraisal__content__table ">
            <Table
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 2200 }}
              bordered
              pagination={false}
            />
          </div>

          <div className="">
            <div className="manage__team__member__textareas">
              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">Employee's Strength</label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.employee_strength}
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">Employee's Weakness</label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.employee_weakness}
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">
                  Identify Employee Performance Gap
                </label>
                <TextArea
                  rows={6}
                  value={
                    lineManagerAppraisal?.payload?.employee_performance_gap
                  }
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">
                  State Remedial action relevant over the next FY
                </label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.remedial_action}
                  disabled
                />
              </div>
            </div>

            <div className="">
              <div className="d-flex flex-column align-items-center gap-4 ">
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">
                    Medical Fitness and Alertness
                  </label>
                  <span className="">
                    State any factor for example health, personal difficulties,
                    organizational problems (e.g. stock out, working tools,
                    logistics, leadership style e.t.c) or any other
                    internal/external/extraneous factors which might have
                    affected the staff job performance during the period under
                    review.
                  </span>
                </div>
                <TextArea
                  rows={6}
                  value={
                    lineManagerAppraisal?.payload?.medical_fitness_alertness
                  }
                  disabled
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-4 py-5 manage__team__checkbox">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Recommended For</label>
                <span className="">
                  Recommend employee based on performance and ratings
                </span>
              </div>

              <Checkbox.Group
                options={options}
                value={Object.keys(checkboxValues).filter(
                  (key) => checkboxValues[key]
                )}
                disabled
              />
            </div>

            <div className="manage__team__member__textareas">
              {lineManagerAppraisal?.payload != null &&
                Object.entries(
                  lineManagerAppraisal?.payload?.summary_comments
                ).map(([key, value]) => {
                  if (key !== "employee_comment") {
                    return (
                      <div className="d-flex flex-column align-items-center gap-2 ">
                        <label className="fw-bold fs-6">
                          {key
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </label>
                        <TextArea
                          rows={6}
                          value={value?.comment || "N/A"}
                          disabled
                        />
                      </div>
                    );
                  }
                })}
              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">
                  Employee's Summary Comment
                </label>
                <TextArea
                  rows={6}
                  value={summary_comment}
                  onChange={(e) => setSummaryComment(e.target.value)}
                  disabled={
                    !appraisalSettings?.payload?.first_appraiser
                      ?.summary_comment
                  }
                />
              </div>
            </div>
          </div>

          {appraisalSettings?.payload?.enforcement?.employee_comment && (
            <Alert
              message="Warning"
              description="Note that you are required to submit deliverable comments before submitting your appraisal."
              type="warning"
              showIcon
            />
          )}

          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
                marginTop: "2rem",
              }}
              onClick={handleSetEmployeeScore}
              disabled={
                !employee_score ||
                employee_score.length === 0 ||
                employee_score === undefined
              }
              loading={employeeScoreLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

function EachAction({ item, refetch, deliverableCommentAbility }) {
  const { TextArea } = Input;
  const [comment, setEmployeeComment] = useState(
    item.scoreCard.comments.employee_comment
  );
  const [employeeCommentLoading, setEmployeeCommentLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleModalOpen() {
    setIsModalOpen(!isModalOpen);
  }
  const dispatch = useDispatch();

  // console.log("item", item);

  const handleAddEmployeeDeliverableComment = (e) => {
    const creds = {
      cascade_id: item.key,
      comment,
    };
    e.preventDefault();
    setEmployeeCommentLoading(true);
    addEmployeeDeliverableComment(dispatch, creds).then(() => {
      setEmployeeCommentLoading(false);
      refetch();
    });
  };

  return (
    <>
      <div
        className="begin__appraisal__content__table__comment"
        onClick={handleModalOpen}
      >
        <FaComment
          size={30}
          color={
            Object.values(item.scoreCard.comments).some(Boolean)
              ? "blue"
              : "gray"
          }
        />
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleModalOpen}
        centered
        width={"auto"}
      >
        <div
          className="d-flex justify-content-bewteen gap-5"
          style={{
            // maxWidth: "900px",
            overflow: "auto",
            padding: "1.5rem",
          }}
        >
          <div className="w-50">
            <h6>Employee can see comments on deliverable by superiors here</h6>

            {Object.entries(item.scoreCard.comments).map(([key, value]) => {
              if (key !== "employee_comment") {
                return (
                  <div
                    key={key}
                    className="d-flex flex-column align-items-start gap-2"
                  >
                    <label className="fw-bold fs-6">
                      {key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                    </label>
                    <div>{value?.comment || "N/A"}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div className="begin__appraisal__content__table__comment__divider"></div>

          <div className="w-50">
            <h6>
              Employee can comment on deliverable here and send to the Line
              manager
            </h6>

            <div className="d-flex flex-column align-items-start gap-2 ">
              <label className="fw-bold fs-6">Employee's Comment</label>
              <TextArea
                rows={6}
                value={comment}
                onChange={(e) => setEmployeeComment(e.target.value)}
                disabled={!deliverableCommentAbility}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                  marginTop: "2rem",
                }}
                onClick={handleAddEmployeeDeliverableComment}
                loading={employeeCommentLoading}
                disabled={comment === "" || !deliverableCommentAbility}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmployeeAppraisalTable;
