function Confirmation() {
  return (
    <>
      <h3>Data Protection</h3>
      <div className="confirmation-message">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae magni
          totam officia accusamus consequatur dolor, tempora laborum esse,
          deserunt neque, aliquam dolore? Magnam alias excepturi accusantium
          odit pariatur quisquam minus? Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Officia, vel.
        </p>
        <p>Cheers!</p>
      </div>
    </>
  );
}

export default Confirmation;
