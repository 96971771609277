import { useState, useEffect } from "react";
import Notification from "./Notifications";
import {
  useShallowEqualSelector,
  useUserCleanUp,
  useUsersNotification,
} from "../../util/hooks";
import {
  company_details,
  settings,
  team_member,
  user_profile,
} from "../../util/selectors/userSelectors";
import Modal from "../ui/Modal";
import { Button } from "antd";
import styles from "../../styles/Dashboard.module.css";
import Levels from "../ui/Levels";
import { logoutClient } from "./../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { dehashData } from "../../util/hash";
import FinalStepDispaly from "./FinalStepDispaly";
import Holidays from "./Holidays";
import TeamMembers from "./TeamMembers";
import Sidebar from "./Sidebar";
import {
  useGetSystemSettings,
  useGetUserProfile,
  useMyTeamMembers,
  useGetUserPermissions,
} from "../../util/usershookActions";
import Birthdays from "./Birthdays";
import { Route, Routes } from "react-router-dom";
import AllEmployeeGrid from "./AllEmployeeGrid";
import AllDisciplinaryGrid from "./AllDisciplinaryGrid";
import AllProbationGrid from "./AllProbationGrid";
import "../../styles/dashboard.css";
import TeamMembersOnLeave from "./TeamMembersOnLeave";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import CanAccess from "../hoc/CanAccess";
import { userAbilities } from "../../util/helpers";

const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;

function Dashboard() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [final_step, setShowFinalStep] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [logoutSpinner, setLogoutSpinner] = useState(false);
  const [enabled_settings, setEnabledSettings] = useState(false);
  const [display, setDisplay] = useState(false);
  const [enabledteam, setEnabledTeam] = useState(false);

  const abilities = userAbilities();

  useMyTeamMembers(enabledteam, setEnabledTeam);

  useEffect(() => {
    if (!abilities.includes("view-team-members")) {
      setEnabledTeam(false);
    } else {
      setEnabledTeam(true);
    }
  }, [enabledteam, abilities]);

  const team_members = useShallowEqualSelector(team_member);

  useGetUserProfile(enabled, setEnabled);

  const time = new Date().getHours();
  useGetSystemSettings(enabled_settings, setEnabledSettings);

  const all_settings = useShallowEqualSelector(settings);

  const timeOfDay = time < 12 ? "Morning" : time < 16 ? "Afternoon" : "Evening";
  useUsersNotification();

  const { CompanyDetail, logoUploaded, company_color, show_quick_set } =
    dehashData(AUTH_DETAILS) ?? {
      CompanyDetail: {},
      logoUploaded: false,
      company_color: [],
      show_quick_set: false,
    };

  const companyDetails = useShallowEqualSelector(company_details);
  const profile = useShallowEqualSelector(user_profile);

  // console.log({ profile });

  const { personal, contact, service_years } = profile;

  let companyColor;
  let color;

  if (company_color) {
    color = company_color?.[0]?.primary_color;
  }

  if (color) {
    switch (color) {
      case "#0065AB":
        companyColor = "blue";
        break;
      case "#008000":
        companyColor = "green";
        break;
      case "#CD0404":
        companyColor = "red";
        break;
      case "#6F1AB6":
        companyColor = "purple";
        break;
      case "#E14D2A":
        companyColor = "orange";
        break;
      case "#1C315E":
        companyColor = "navy";
        break;
      case "#000000":
        companyColor = "black";
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    show_quick_set && !CompanyDetail && !logoUploaded && setShowModal(true);
    // setShowModal(true);

    document.body.classList.add(companyColor);
  }, [CompanyDetail, companyColor, logoUploaded, show_quick_set]);

  const logoutHandler = () => {
    setLogoutSpinner(true);
    logoutClient(dispatch);
  };

  const showFinalStep = () => {
    setShowFinalStep(true);
    setTimeout(() => {
      setShowFinalStep(false);
    }, 9500);
  };


  const { id } = profile;
  const [permEnabled, setPermEnabled] = useState(false);
  useGetUserPermissions(permEnabled, setPermEnabled, id);
  
  const auth_details = dehashData(AUTH_DETAILS);
  // console.log("reloading layout...", auth_details);

  useEffect(() => {
    if (id) {
      setPermEnabled(true);
    }
  }, [id])


  return (
    <>
      {final_step && <FinalStepDispaly />}
      {showModal && (
        <Modal className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.modalTop}>
              <Button
                size="large"
                type="primary"
                onClick={logoutHandler}
                className="myBtn "
                loading={logoutSpinner}
              >
                Logout
              </Button>
              <p className={styles.welcome}>
                Welcome,{" "}
                <span>
                  {personal?.first_name} @{" "}
                  {companyDetails?.business.company_name}
                </span>
              </p>
            </div>
            <Levels showModal={setShowModal} finalStep={showFinalStep} />
          </div>
        </Modal>
      )}
      <main className={styles.main}>
        <div className={styles.layout}>
          <Sidebar data={profile} />
          <div className={styles.content}>
            <div>
              <div className="greeting">
                <h2>
                  Good {timeOfDay}, {personal?.first_name} {personal?.last_name}
                </h2>
                <h6 className="">Last login: {personal?.last_login}</h6>
              </div>
              <div
                className="container"
                style={{
                  // backgroundColor: "#1c315e0d",
                  paddingBottom: "40px",
                }}
              >
                <div className="row">
                  {/* <div className="col-md-6 px-3 mt-5">
                    <PersonalDetails personal={personal} contact={contact} />
                  </div> */}

                  <div className="col-md-6 px-3 mt-5">
                    <TeamMembersOnLeave />
                  </div>
                  <div className="col-md-6 px-3 mt-5">
                    <Notification />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-6 px-3 mt-5">
                    {/* <Designation data={personal} /> */}
                  </div>
                  <div className="col-md-6 px-3 mt-5">
                    <CanAccess can_access={"view-team-members"} isRoute={false}>
                      <TeamMembers data={team_members} />
                    </CanAccess>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 px-3 mt-5">
                    <CanAccess can_access={"view-birthdays"} isRoute={false}>
                      <Birthdays />
                    </CanAccess>
                  </div>
                  <div className="col-md-6 px-3 mt-5">
                    <CanAccess can_access={"view-holidays"} isRoute={false}>
                      <Holidays />
                    </CanAccess>
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-md-6 px-3 mt-5">
                    {Array.isArray(
                      all_settings?.other_settings?.display?.value
                    ) &&
                    all_settings?.other_settings?.display?.value.includes(
                      "service_year_chart"
                    ) ? (
                      <UserStayDuration data={service_years} />
                    ) : null}
                  </div>
                  <div className="col-md-6 px-3 mt-5">
                    <Notes />
                  </div> */}
                  {/* <div className="col-md-6 px-3 mt-5">
                    {Array.isArray(
                      all_settings?.other_settings?.display?.value
                    ) &&
                    all_settings?.other_settings?.display?.value.includes(
                      "daily_user_login"
                    ) ? (
                      <DailyLoginChart />
                    ) : null}
                  </div> */}
                </div>

                <CanAccess can_access={"view-all-employees"} isRoute={false}>
                  <div
                    className="row emp__area"
                    style={{
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Routes>
                      <Route end index element={<AllEmployeeGrid />} />
                      <Route
                        end
                        path={"disciplinary-list"}
                        element={<AllDisciplinaryGrid />}
                      />

                      <Route
                        end
                        path={"probation-list"}
                        element={<AllProbationGrid />}
                      />
                    </Routes>
                  </div>
                </CanAccess>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
