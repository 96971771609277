import { useState, useEffect, useRef } from "react";
import { uploadProfilePicEmployee } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import Avatar from "react-avatar";
import { Button, Modal } from "antd";
import { MdOutlineZoomOutMap, MdPhotoCamera } from "react-icons/md";
import styles from "../../../styles/employee/Profile.module.css";

function ProfilePicture({ data }) {
  const [file, setFile] = useState(null);
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const fileUploadRef = useRef();

  const handleFileChange = (e) => {
    e.preventDefault();
    setEditMode(true);
    const { files } = e.target;

    setFile(files[0]);
  };

  const handleCancel = () => {
    setFile("");
    setEditMode(false);
  };

  useEffect(() => {
    const profilePic = document.getElementById("profile_pic");
    const newImageContainer = document.getElementById("new_image_container");
    const newProfilePic = document.getElementById("profile_pic_new");
    const avatar = document.getElementById("user_avatar");

    if (!data?.avatar) {
      newImageContainer.style.display = "none";
      if (file) {
        newImageContainer.style.display = "block";
        newProfilePic.src = URL.createObjectURL(file);
        // data.avatar = URL.createObjectURL(file);
        avatar.style.display = "none";
      } else if (file === "") {
        avatar.style.display = "block";
        newImageContainer.style.display = "none";
      }
    } else {
      if (file) {
        profilePic.src = URL.createObjectURL(file);
      } else if (file === "") {
        profilePic.src = data?.avatar;
      }
    }
  }, [data, data?.avatar, file]);

  function handleUploadPic() {
    setSaveSpinner(true);
    const creds = {
      channel: "Cloudinary",
      file,
    };

    uploadProfilePicEmployee(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      setEditMode(false);
    });
  }

  const handleClick = (e) => {
    if (showModal) {
      setShowModal(false);
    }
    fileUploadRef.current.click();
  };

  return (
    <div className={styles.profilePhoto}>
      {data?.avatar ? (
        <div className={styles.imageContainer}>
          <img
            src={data?.avatar}
            alt={data?.fullname}
            id="profile_pic"
            className={styles.image}
          />
        </div>
      ) : (
        <div className={styles.newUpload}>
          <div id="user_avatar">
            <Avatar
              name={`${data?.first_name} ${data?.last_name}`}
              size={250}
              round={"8"}
              className={styles.avatar}
            />
          </div>
          <div className={styles.imageContainer} id="new_image_container">
            <img src="" alt="" id="profile_pic_new" className={styles.image} />
          </div>
        </div>
      )}
      <Button
        className={styles.pencil}
        onClick={data?.avatar ? handleModal : handleClick}
      >
        {data?.avatar ? <MdOutlineZoomOutMap /> : <MdPhotoCamera />}
      </Button>
      <input
        type="file"
        name="profile-picture"
        ref={fileUploadRef}
        className={styles.upload}
        onChange={handleFileChange}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Modal open={showModal} footer={null} centered onCancel={handleModal}>
        <img
          src={data?.avatar}
          alt={data?.fullname}
          className={styles.modalImage}
        />
        <div className="d-flex justify-content-end mt-3">
          <Button size="large" className="myBtn" onClick={handleClick}>
            Edit
          </Button>
        </div>
      </Modal>
      {editMode && (
        <div className="mt-5 d-flex justify-content-center gap-3">
          <Button className="myBtn-danger" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="myBtn"
            onClick={handleUploadPic}
            loading={save_spinner}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProfilePicture;
