import React from "react";

import { NavLink, useLocation } from "react-router-dom";



const EmployeeDropdown = () => {
  const location = useLocation();

  const isEmployeeChild = () => {
    return location.pathname.startsWith('/modules/hris/employee/view/');
  };

  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"employee"}
          end
          className={({ isActive }) => (isActive || isEmployeeChild() ? "active" : "")}
        >
          <span className="sidebar_text">Employee list</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"employee/employee-management-new-employee"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">New Employee</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"employee/employee-management-upload-employee"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload Employees</span>
        </NavLink>
      </li>
    </ul>
  );
};

export default EmployeeDropdown;
