import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function EmergencyContactHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: em.name,
          email: em.email || "--",
          relationship: em.relationship || "--",
          telephone: em.telephone || "--",
          mobile: em.mobile_number || "--",
          address: em.home_address || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "RELATIONSHIP",
      dataIndex: "relationship",
      key: "relationship",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "TELEPHONE",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "MOBILE",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={emp_history}
      scroll={{ x: "max-content" }}
    />
  );
}

export default EmergencyContactHistory;
