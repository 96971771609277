import { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useGetAppraisalsApproved,
  useGetAllEmployees,
} from "../../../../util/usershookActions";
import { restartEmployeeAppraisal } from "../../../../redux/users/userActions";
import { MdOutlineArrowBack } from "react-icons/md";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import NoContent from "../../../ui/NoContent";

const RestartAppraisal = () => {
  const [enabled, setEnabled] = useState(true);
  const [restartEmployeeAppraisalLoading, setRestartEmployeeAppraisalLoading] =
    useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [appraisalId, setAppraisalId] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: allEmployees } = useGetAllEmployees(enabled, setEnabled);

  const { data: inProgressAppraisal, isLoading: isLoadingInProgressAppraisal } =
    useGetAppraisalsApproved(enabled, setEnabled);

  let inProgressReview;

  if (inProgressAppraisal && inProgressAppraisal.payload?.[0]) {
    inProgressReview = inProgressAppraisal?.payload?.filter(
      (appraisal) => appraisal.status === "in-progress"
    );
  }

  const handleRestartEmployeeAppraisal = (e) => {
    e.preventDefault();

    setRestartEmployeeAppraisalLoading(true);

    restartEmployeeAppraisal(dispatch, appraisalId, employeeId).then(() => {
      setRestartEmployeeAppraisalLoading(false);
    });
  };

  const onChange = (value) => {
    setEmployeeId(value);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (inProgressReview) {
      setAppraisalId(inProgressReview?.[0]?.id);
    }
  }, [inProgressReview]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "2rem",
          }}
        >
          <div className="">
            <h3 className="h4">Restart Appraisal</h3>
            <p>Restart employee's appraisal</p>
          </div>

          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
            className="myBtn d-flex align-items-center gap-2"
          >
            <MdOutlineArrowBack />
            Back
          </Button>
        </div>

        {inProgressAppraisal && !inProgressAppraisal.payload?.length ? (
          <NoContent
            width={"400px"}
            message={"There is no appraisal going on currently"}
          />
        ) : isLoadingInProgressAppraisal ? (
          <SkeletonUI />
        ) : (
          <>
            <div className="begin__appraisal__details">
              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Appraisal Name</label>
                <Input
                  size="large"
                  id="appraisalName"
                  value={inProgressReview?.[0]?.appraisal_name}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Review Period</label>
                <Input
                  size="large"
                  id="reviewPeriod"
                  value={`${dayjs(
                    inProgressReview?.[0]?.review_period_date?.from
                  ).format("DD/MM/YYYY")} - ${dayjs(
                    inProgressReview?.[0]?.review_period_date?.to
                  ).format("DD/MM/YYYY")}`}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2 ">
                <label className="fw-bold fs-6">Employee Name</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select employee"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                  options={allEmployees?.payload?.all_employees?.data?.map(
                    (employee) => ({
                      value: employee?.id,
                      label: employee?.fullname,
                    })
                  )}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                  marginTop: "1rem",
                }}
                onClick={handleRestartEmployeeAppraisal}
                loading={restartEmployeeAppraisalLoading}
              >
                Restart
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RestartAppraisal;
