import { useState } from "react";

import { Table } from "antd";
import { useGetPerformanceKPITargets } from "../../../../util/usershookActions";
import SkeletonUI from "../../../ui/Skeleton";

const HalfYearApprovalList = () => {
  const [enabled, setEnabled] = useState(true);

  const { data: targetsData, isLoading } = useGetPerformanceKPITargets(
    enabled,
    setEnabled,
    "half_yearly"
  );

  const groupedData = {};

  // Group the data by department
  targetsData?.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Target (unit)",
      dataIndex: "target",
      key: "target",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Timeline",
      dataIndex: "timeline",
      render: (text, record) => (
        <span>
          {record.start_date} - {record.end_date}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
      ),
    },
  ];

  return (
    <div className="monthly__approval__list performance_table">
      {isLoading ? (
        <SkeletonUI />
      ) : (
        <Table
          columns={columns}
          dataSource={transformedData}
          bordered
          pagination={false}
        />
      )}
    </div>
  );
};

export default HalfYearApprovalList;
