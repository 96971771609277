import { Link } from "react-router-dom";
import { Input, Button } from "antd";
import { loginValidator } from "../../util/formValidations";
import { useForm } from "../../util/hooks";
import Sidebar from "../ui/Sidebar";
import { loginClient } from "../../redux/users/userActions";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  company_details,
} from "../../util/selectors/userSelectors";
import {
  useShallowEqualSelector,
  useUsersNotification,
  useUserCleanUp,
} from "../../util/hooks";
import { useState } from "react";
import "../../styles/login.css";
import Logo from "../Logo";

const Login = () => {
  // const initLoginUser = {
  //   email: "",
  //   password: "",
  // };
  // const initLoginUser2 = {
  //   employee_id: "",
  //   password: "",
  // };
  const [initLoginUser, setInitLoginUser] = useState({
    email: "",
    password: "",
  });
  const [with_email, setWithEmail] = useState(true);
  const [reinit, setReinit] = useState(false);
  useUsersNotification();
  useUserCleanUp();
  const spinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const companyDetails = useShallowEqualSelector(company_details);

  const dispatch = useDispatch();

  const logo = companyDetails?.settings?.filter((setting) => setting?.setting === "CompLogo");


  const callbackFn = () => {
    loginClient(dispatch, values);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    initLoginUser,
    loginValidator,
    reinit,
    setReinit
  );

  function handleSwitch(e) {
    setWithEmail((prev) => {
      console.log(!prev);
      if (!prev === false) {
        setInitLoginUser({
          employee_id: "",
          password: "",
        });
        setReinit(true);
      } else {
        setInitLoginUser({
          email: "",
          password: "",
        });
        setReinit(true);
      }
      return !prev;
    });
    // console.log(with_email);
  }
  // console.log({ values });
  return (
    <div className="row bgLogin">
      <Sidebar />
      <div className="col-12  col-md-7 p-0">
        <div className="d-flex align-items-center px-0 auth">
          <div className="row w-100 mx-0">
            <div
              className="col col-lg-7 mx-auto d-flex flex-column align-items-center p-4"
              style={{ maxWidth: 500 }}
            >
              <div className="d-flex gap-3 align-items-end d-md-none ">
                <img
                  src={logo[0]?.value || logo[0]?.default}
                  alt="logo"
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 5,
                    objectFit: "cover",
                  }}
                />
                <h4
                  className="d-md-none"
                  style={{ fontSize: "27px", fontWeight: "300" }}
                >
                  {companyDetails?.business.company_name}
                </h4>
              </div>
              <div
                className="text-left py-4 py-lg-5 mt-4 px-3 px-lg-4 mx-lg-3 w-100 form-border"
                style={{ background: "#FFF" }}
              >
                <div>
                  <h4 className="text-left text-dark">Login</h4>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    {with_email ? (
                      <div className="form-group mb-3">
                        <div className="d-flex align-items-center w-100">
                          <label
                            htmlFor="Email"
                            className="d-block w-100 mb-2 text-dark"
                          >
                            Email Address
                          </label>
                          <div className="w-100 text-right text-danger">
                            <small>
                              {errors.email ||
                                (back_errors.email && back_errors.email[0])}
                            </small>
                          </div>
                        </div>
                        <Input
                          allowClear
                          type="text"
                          size="large"
                          placeholder="Email Address "
                          onChange={handleChange}
                          name="email"
                          value={values.email}
                          // required
                        />
                      </div>
                    ) : (
                      <div className="form-group mb-2 ">
                        <div className="d-flex align-items-center mb-2 w-100">
                          <label
                            htmlFor="employee_id"
                            className="d-block w-100 mb-0 text-dark"
                          >
                            Employee ID
                          </label>
                          <div className="w-100 text-right text-danger">
                            <small>
                              {errors.employee_id ||
                                (back_errors.employee_id &&
                                  back_errors.employee_id[0])}
                            </small>
                          </div>
                        </div>
                        <Input
                          allowClear
                          type="text"
                          size="large"
                          placeholder=" Employee ID"
                          onChange={handleChange}
                          name="employee_id"
                          value={values.employee_id}
                          // required
                        />
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center mb-2 w-100">
                        <label
                          htmlFor="Password"
                          className="d-block w-100 mb-0 text-dark"
                        >
                          Password
                        </label>
                        <div className="w-100 text-right text-danger">
                          <small>
                            {errors.password ||
                              (back_errors.password && back_errors.password[0])}
                          </small>
                        </div>
                      </div>
                      <Input.Password
                        allowClear
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="large"
                        onChange={handleChange}
                        value={values.password}
                      />
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <span
                        className="auth-link text-black"
                        style={{ cursor: "pointer" }}
                        onClick={handleSwitch}
                      >
                        {with_email ? `Login with ID?` : `Login with email?`}
                      </span>

                      <Link
                        to="/forgot-password"
                        className="auth-link text-black"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Button
                        htmlType="submit"
                        size="large"
                        block
                        className="myBtn "
                        loading={spinner}
                      >
                        LOGIN
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-md-none">
                <Logo size={20} />
              </div>
              <small className="mt-3">Privacy | Terms & conditions</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
