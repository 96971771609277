import React, { useEffect, useState } from "react";
import "../../../styles/pre-fill.css";
import { getURLSubdomain } from "../../../util/helpers";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
  useUsersNotification,
} from "../../../util/hooks";
import {
  company_details,
  user_errors,
} from "../../../util/selectors/userSelectors";
import {
  useGetCompanyDetails,
  useGetOnboardUserProfile,
} from "../../../util/usershookActions";
import { Button, Modal, Skeleton } from "antd";
import { submitPrefillform } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function Prefill() {
  const dispatch = useDispatch();
  useUserCleanUp();
  useUsersNotification();
  const [enabled, setEnabled] = useState(true);
  const [enabledpr, setEnabledpr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const company_url = getURLSubdomain();
  const [submit, setSubmit] = useState(false);

  const params = queryString.parse(useLocation().search);

  const back_errors = useShallowEqualSelector(user_errors);

  useGetCompanyDetails(enabled, setEnabled, company_url);
  let { data: hireProfile, isLoading } = useGetOnboardUserProfile(
    enabledpr,
    setEnabledpr,
    params.hire_id
  );

  const companyDetails = useShallowEqualSelector(company_details);

  const company_logo = companyDetails?.settings.filter(
    (setting) => setting.setting === "CompLogo"
  )[0].value;
  const company_name = companyDetails?.business?.company_name;

  const init_details = {
    home_address: "",
    email: "",
    state: "",
    last_name: "",
    other_names: "",
    phone_number: "",
    title: "",
    first_name: "",
  };

  const modalHandler = () => {
    setShowModal(!showModal);
  };
  const callbackFn = () => {
    submitPrefill();
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const confirm = () => {
    setLoading(true);
    submitPrefillform(dispatch, { ...values, hire_id: params?.hire_id }).then(
      (res) => {
        setLoading(false);
        if (res?.status === "success") {
          clearForm();
          setSubmit(true);
          // refetch();
        }
      }
    );
    // console.log(values);
  };

  useEffect(() => {
    if (params?.hire_id) {
      setEnabledpr(true);
    }
  }, [params?.hire_id]);

  const submitPrefill = () => {
    Modal.confirm({
      title: "Submit request",
      content: "Do you want to submit this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  return (
    <div className="prefill">
      {!submit ? (
        <div className="prefill-container">
          <div className="d-flex gap-3 align-items-center prefill-header">
            <img src={company_logo} alt={company_name} />
            <h2>{company_name}</h2>
          </div>
          {isLoading ? (
            <div>
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
                className="mt-3"
              />
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
                className="mt-3"
              />
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
                className="mt-3"
              />
            </div>
          ) : (
            <>
              <div className="form-desc">
                <h3>Hi {hireProfile?.payload?.name},</h3>
                <p>
                  Thank you for your interest in applying for{" "}
                  <b>{hireProfile?.payload?.designation_role?.role}</b> at{" "}
                  <b>{company_name}</b>.
                </p>
                <p>
                  To continue with your application, Kindly provide us with the
                  necessary information that would be used to prepare your offer
                  letter.
                </p>
                <p>Please provide us with accurate information.</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-section">
                  <h4 className="section-title">Basic Information</h4>
                  <div className="input-group">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.title}
                      id="title"
                      name="title"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="last_name">Last Name</label>
                    <small className="text-danger">
                      {errors.last_name ||
                        (back_errors.last_name && back_errors.last_name[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.last_name}
                      id="last_name"
                      name="last_name"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.first_name}
                      id="first_name"
                      name="first_name"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="other_names">Other Names</label>
                    <small className="text-danger">
                      {errors.other_names ||
                        (back_errors.other_names && back_errors.other_names[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.other_names}
                      id="other_names"
                      name="other_names"
                    />
                  </div>
                </div>
                <div className="form-section">
                  <h4 className="section-title">Contact Information</h4>
                  <div className="input-group">
                    <label htmlFor="phone_number">Phone Number</label>
                    <small className="text-danger">
                      {errors.phone_number ||
                        (back_errors.phone_number &&
                          back_errors.phone_number[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.phone_number}
                      id="phone_number"
                      name="phone_number"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="email">Email Address</label>
                    <small className="text-danger">
                      {errors.email ||
                        (back_errors.email && back_errors.email[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.email}
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="home_address">Home Address</label>
                    <small className="text-danger">
                      {errors.home_address ||
                        (back_errors.home_address &&
                          back_errors.home_address[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.home_address}
                      id="home_address"
                      name="home_address"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="state">State of Residence</label>
                    <small className="text-danger">
                      {errors.state ||
                        (back_errors.state && back_errors.state[0])}
                    </small>
                    <input
                      type="text"
                      placeholder="Your answer here"
                      onChange={handleChange}
                      value={values.state}
                      id="state"
                      name="state"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    size="large"
                    className="myBtn"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      ) : (
        <div className="offer-letter d-flex justify-content-center align-items-center">
          <div className="offer-letter-content bg-white">
            <div className=" d-flex flex-column justify-content-center align-items-center h-100">
              <img
                src="/assets/okay.png"
                alt="ok"
                className="mb-5"
                width={300}
              />
              <h3>Response Submitted Successfully </h3>
              <p> Our HR team will get back to you soon.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Prefill;
