import { useState } from "react";
import { Switch } from "antd";
import styles from "../../styles/SetPassword.module.css";
import { useDispatch } from "react-redux";
import { quick_setup, user_errors } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
function SetPassword() {
  const dispatch = useDispatch();

  const company = useShallowEqualSelector(quick_setup);

  const companyPassword = company.find(
    (details) => details.name === "Password Settings"
  );
  const { field } = companyPassword;
  const initPasswordOptions = {
    minimum_length: field.minimum_length,
    lock_after_retry: field.lock_after_retry,
    password_history: field.password_history,
    password_strength: field.password_strength,
    allow_self_recovery: field.allow_self_recovery,
    allow_self_unlock: field.allow_self_unlock,
  };

  const [values, setValues] = useState(initPasswordOptions);
  // const [errors, setErrors] = useState({});
  const errors = useShallowEqualSelector(user_errors);

  const handleChange = (e) => {
    setValues((prevState) => {
      dispatch({
        type: "QUICK_SETUP_PASS_SETTINGS",
        payload: { [e?.target.name]: e?.target.value },
      });
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const selfRecoveryHandler = (checked) => {
    dispatch({
      type: "QUICK_SETUP_PASS_SETTINGS",
      payload: { allow_self_recovery: checked === true ? "yes" : "no" },
    });
  };

  const selfUnlockHandler = (checked) => {
    dispatch({
      type: "QUICK_SETUP_PASS_SETTINGS",
      payload: { allow_self_unlock: checked === true ? "yes" : "no" },
    });
  };

  return (
    <>
      <h3>Password Setting</h3>
      <small>
        Set your company password rules{" "}
        <span>
          (<em>optional</em>)
        </span>
      </small>
      <div className="row">
        <div className={`${styles.content} col-lg-7`}>
          <form className={styles.form}>
            <div className={styles.inputGroup}>
              <label htmlFor="minimum_length">Minimum Length</label>
              <small className="text-danger">{errors.minimum_length}</small>
              <select
                name="minimum_length"
                value={values.minimum_length}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="4">4 Characters</option>
                <option value="5">5 Characters</option>
                <option value="6">6 Characters</option>
                <option value="7">7 Characters</option>
                <option value="8">8 Characters</option>
                <option value="9">9 Characters</option>
                <option value="10">10 Characters</option>
              </select>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="lock_after_retry">Lock After Retry</label>
              <small className="text-danger">{errors.lock_after_retry}</small>
              <select
                name="lock_after_retry"
                value={values.lock_after_retry}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="0">Never</option>
                <option value="2">2 retries</option>
                <option value="3">3 retries</option>
                <option value="4">4 retries</option>
                <option value="5">5 retries</option>
                <option value="6">6 retries</option>
              </select>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="password_history">Password History</label>
              <small className="text-danger">{errors.password_history}</small>
              <select
                name="password_history"
                value={values.password_history}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="1">Activated</option>
                <option value="0">Deactivated</option>
              </select>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="password_strength">Password Strength</label>
              <small className="text-danger">{errors.password_strength}</small>
              <select
                name="password_strength"
                value={values.password_strength}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="weak">Weak</option>
                <option value="moderate">Moderate</option>
                <option value="acceptable">Acceptable</option>
                <option value="strong">Strong</option>
                <option value="very strong">Very Strong</option>
              </select>
            </div>
            {/* <div className="d-flex justify-content-between mt-3">
              <div className={styles.inputToggle}>
                <label htmlFor="allow_self_recovery">Allow Self Recovery</label>
                <span>{errors.allow_self_recovery}</span>
                <Switch
                  onChange={selfRecoveryHandler}
                  name="allow_self_recovery"
                />
              </div>
              <div className={styles.inputToggle}>
                <label htmlFor="allow_self_unlock">Allow Self Unlock</label>
                <span>{errors.allow_self_unlock}</span>
                <Switch onChange={selfUnlockHandler} name="allow_self_unlock" />
              </div>
            </div> */}
          </form>
        </div>
        <div className="d-none d-lg-block col-lg-5 d-flex align-items-center">
          <div className={styles.placeholderImage}>
            <img
              src="/assets/landing-page/Human Resource Management.png"
              alt="setup"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SetPassword;
