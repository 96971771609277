import { Checkbox } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbCalendarStats } from "react-icons/tb";
import { IoIosFlag } from "react-icons/io";
import { GiBullseye } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";

const UpcomingTask = () => {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <div
      className="d-flex gap-2"
      style={{
        border: "1px solid #d9d9d9",
        padding: "0.7rem",
        borderRadius: "10px",
      }}
    >
      <div className="task__checkbox task__checkbox__upcoming">
        <Checkbox onChange={onChange}></Checkbox>
      </div>
      <div className="d-flex flex-column w-100 gap-2">
        <div className="d-flex justify-content-between">
          <div className="">
            <h5 className="h6">Task Title</h5>
          </div>

          <div className="d-flex gap-2">
            <CiEdit size={17} cursor={"pointer"} />
            <RiDeleteBin6Line size={17} cursor={"pointer"} />
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <TbCalendarStats size={17} cursor={"pointer"} />
            <span>5 PM / Plan</span>
          </div>
          <div className="">
            <span>
              My Work <GiBullseye size={17} />
            </span>
            {" / "}
            <span>
              <IoIosFlag size={17} /> Priority 3 #
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingTask;
