import React from "react";
import { useCloseMobileSibar } from "../../util/hooks";
import IdleContainer from "../IdleContainer";

function HocHeader({
  module_name,
  module_description,
  module_img,
  module_class,
  module_title,
}) {
  useCloseMobileSibar();

  return (
    <>
      <IdleContainer />
      <div
        className={`${module_class} app__section py-4 mt-3 d-flex flex-column `}
      >
        <div className="w-100 w-sm-50">
          <small>{module_title || "Welcome to"}</small>
          <h2 className="my-1">{module_name}</h2>
          <small className="">{module_description}</small>
        </div>

        <img
          className=""
          src={
            module_img ? module_img : "/assets/landing-page/careerladder.png"
          }
          alt="set-goals"
        />
      </div>
    </>
  );
}

export default HocHeader;
