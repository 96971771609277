import React, { useState, useRef, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Button, Modal } from "antd";

import { updateClientLogo } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { dehashData } from "../../../util/hash";
import { useUserCleanUp } from "../../../util/hooks";

function AppLogo() {
  useUserCleanUp();
  const { REACT_APP_AUTH_DETAILS: AUTH_DETAILS } = process.env;

  const { logo } = dehashData(AUTH_DETAILS);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const [save_spinner, setSaveSpinner] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const fileUploadRef = useRef();

  const handleCancel = () => {
    setFile("");
    setEditMode(false);

    const newLogoPic = document.getElementById("logo_pic");
    const newModalImg = document.getElementById("modal_img");
    newLogoPic.src = logo;
    if (newModalImg) {
      newModalImg.src = logo;
    }
  };

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };
  const handleClick = (e) => {
    if (showModal) {
      setShowModal(false);
    }
    fileUploadRef.current.click();
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    setEditMode(true);
    const { files } = e.target;

    setFile(files[0]);
  };
  function handleUploadPic() {
    setSaveSpinner(true);
    const creds = {
      channel: "Cloudinary",
      file,
    };

    updateClientLogo(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      setEditMode(false);
    });
  }
  useEffect(() => {
    if (file) {
      const newLogoPic = document.getElementById("logo_pic");
      const newModalImg = document.getElementById("modal_img");
      newLogoPic.src = URL.createObjectURL(file);
      if (newModalImg) {
        newModalImg.src = URL.createObjectURL(file);
      }
    }
  }, [file]);

  // console.log({ file, logo });
  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Company Logo</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row">
            <div className="col-md-5">
              <small>
                Upload or Update your company logo to personalize your
                application
              </small>
            </div>
            <div className="col-md-7 pull-right">
              <div className="d-flex justify-content-end ">
                <div className="img_parent">
                  <img
                    src={`${logo ? logo : "/assets/office-building.png"}`}
                    alt="logo"
                    className="img_res"
                    id="logo_pic"
                  />
                  <div className="overlay">
                    <AiOutlineCloudUpload
                      className="hover_effect"
                      onClick={handleModal}
                    />
                  </div>
                </div>
              </div>
              {editMode && (
                <div className="mt-5 d-flex justify-content-center gap-3">
                  <Button onClick={handleCancel} className="res_btn">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleUploadPic}
                    className="res_btn"
                    loading={save_spinner}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <input
              type="file"
              name="app_logo"
              ref={fileUploadRef}
              hidden
              onChange={handleFileChange}
              accept="image/png, image/jpeg, image/jpg"
            />

            <Modal
              open={showModal}
              footer={null}
              centered
              onCancel={handleModal}
            >
              <img
                src={`${logo ? logo : "/assets/office-building.png"}`}
                alt="app_logo"
                className="modalImage"
                id="modal_img"
              />
              <div className="d-flex justify-content-end">
                <Button size="large" className="myBtn " onClick={handleClick}>
                  Change Company Logo
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLogo;
