import { Button } from "antd";
import React from "react";
import FileUpload from "../../ui/FileUpload";

const UploadExcel = ({
  handleUpload,
  loading,
  file,
  setFile,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="mt-3">
          <FileUpload
            file={file}
            setFile={setFile}
            accept={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            }
            supportedFormats={"xls, xlsx"}
            type={"excel"}
          />
          <div className="d-flex gap-2 justify-content-end mt-5 mb-3">
            <a href="/assets/bank_excel.xlsx" download={true}>
              <Button className="myBtn ">Download Template</Button>
            </a>
            <Button
              className="myBtn "
              onClick={handleUpload}
              disabled={!file}
              loading={loading}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadExcel;
