import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

const PerformanceMonitoring = () => {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Performance Management"
          module_class="performance-header"
          module_name={
            childPath === "dept-score-card"
              ? "Department Score Card"
              : childPath === "org-score-card"
              ? "Organization Score Card"
              : childPath === "team-score-card"
              ? "Team Score Card"
              : childPath === "all-employees-score-card"
              ? "All Employees Score Card"
              : childPath === "all-dept-score-card"
              ? "All Department Score Card"
              : "Individual Score Card"
          }
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/landing-page/Payroll managementpng.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default PerformanceMonitoring;
