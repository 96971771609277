import { useState, useEffect } from "react";
import { Select, DatePicker, Input, Button, Modal, Table } from "antd";

import HocHeader from "../../ui/HocHeader";
import dayjs from "dayjs";
import SurveyReportRenderer from "./SurveyReportRenderer";

const { TextArea } = Input;

const SurveyReports = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [selectedTemplateObject, setSelectedTemplateObject] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetch("http://localhost:8000/responses")
      .then((response) => response.json())
      .then((data) => setSurveys(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  console.log("setSurvey", surveys);

  const onChange = (value, option) => {
    setSelectedTemplateId(value);
    setSelectedTemplateObject(option.template);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
  ];

  const data = [
    {
      key: "1",
      name: "John Brown",
      department: "Enterprise Unit",
    },
    {
      key: "2",
      name: "Jim Green",
      department: "Enterprise Unit",
    },
    {
      key: "3",
      name: "Brown Sigma",
      department: "Finance Unit",
    }
  ];

  return (
    <>
      <div>
        <HocHeader
          module_title="Survey Management"
          module_class="performance-header"
          module_name={"Survey Reports"}
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "2rem",
              }}
            >
              <div className="">
                <h3 className="h4">Reports</h3>
                <p>View reports of preferred survey choice</p>
                <div className="d-flex gap-5 justify-content-center">
                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">From</label>
                    <DatePicker size="large" value={dayjs()} disabled />
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <label className="fw-bold fs-6">To</label>{" "}
                    <DatePicker size="large" value={dayjs()} disabled />
                  </div>
                </div>
              </div>

              <div className="d-flex gap-2">
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a Survey"
                  optionFilterProp="children"
                  value={selectedTemplateId}
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    width: 250,
                  }}
                  options={surveys?.map((survey) => ({
                    label: survey.surveyName,
                    value: survey.id,
                    survey,
                  }))}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
              {selectedTemplateObject ? (
                <div className="make__request__form">
                  <div className="make__request__form__title">
                    <h4 className="h4">Worldwide Plutomania</h4>
                    <div className="fw-6">
                      Total Respondents:{" "}
                      <span
                        onClick={showModal}
                        style={{
                          color: "var(--clr-primary)",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        8
                      </span>
                    </div>
                  </div>

                  <SurveyReportRenderer />
                  <div className="d-flex gap-5 mt-5">
                    <div className="d-flex flex-column gap-2 w-100 ">
                      <label className="fw-bold fs-6">Key Findings</label>
                      <TextArea rows={9} placeholder="Type in this field" />
                    </div>

                    <div className="d-flex flex-column gap-2 w-100">
                      <label className="fw-bold fs-6">Action Plan</label>
                      <TextArea rows={9} placeholder="Type in this field" />
                    </div>

                    <div className="d-flex flex-column gap-2 w-100">
                      <label className="fw-bold fs-6">Recommendations</label>
                      <TextArea rows={9} placeholder="Type in this field" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        backgroundColor: "var(--clr-primary)",
                        marginTop: "2rem",
                      }}
                    >
                      Submit
                    </Button>
                  </div>

                  <Modal
                    title="Worldwide PlutoMania"
                    centered
                    open={isModalOpen}
                    onOk={showModal}
                    onCancel={showModal}
                    footer={null}
                    width={600}
                  >
                    <div className="d-flex flex-column gap-5 py-4">
                      <p className="fw-5 text-center">
                        A more detailed report on Worldwide PlutoMania Survey
                      </p>
                      <div>
                        <p className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <label>Total Responses</label>
                          <span
                            className="fw-bold fs-6"
                            style={{
                              backgroundColor: "var(--clr-primary)",
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                            8
                          </span>
                        </p>
                        <div className="d-flex gap-2 justify-content-between">
                          <p className="d-flex flex-column gap-1 justify-content-center align-items-center">
                            <span>3</span>
                            <label>Anonymous Responses</label>
                          </p>
                          <p className="d-flex flex-column gap-1 justify-content-center align-items-center">
                            <span>10</span>
                            <label>Expected Responses</label>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Table
                          columns={columns}
                          dataSource={data}
                          scroll={{ x: 600 }}
                          pagination={false}
                          bordered
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center gap-2"
                  style={{
                    height: "70vh",
                  }}
                >
                  <img src="/assets/survey.png" alt="fillasurvey" width="200" />
                  <h4 className="h5">Welcome to Survey Reports</h4>
                  <p>Select and view reports of preferred survey choice</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SurveyReports;
