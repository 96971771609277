import React, { useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "../../styles/permissions/ViewGroups.module.css";
import { Button, Modal, Space } from "antd";
import { useGetAllClientGroup } from "../../util/preferenceshookActions";
import { Pagination } from "antd";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { deleteEmployeeGroup } from "../../redux/preference/preferenceActions";
import { usePreferenceCleanUp } from "../../util/hooks";
import { canAccess, convertToCapitalize } from "../../util/helpers";
import HocHeader from "../ui/HocHeader";

function ViewGroups() {
  usePreferenceCleanUp();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);

  const { data } = useGetAllClientGroup(enabled, setEnabled, page);

  function handlePagination(page) {
    setPage(page);

    setEnabled(true);
  }

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const confirm = (id) => {
    deleteEmployeeGroup(dispatch, id).then((res) => {
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Permissions Management"
        module_name="View User Groups"
        module_description="
      HR Managers and privileged administrators can view all your new
      hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <div className="app__section py-4">
            <div className={`${styles.header} `}>
              <h2>View User Groups</h2>
              <Button size="large">
                <Link to="/modules/permissions/create-group">
                  <BsPlusCircleFill />
                  NEW GROUP
                </Link>
              </Button>
            </div>
            <div className="container-fluid">
              <main className={styles.main}>
                <div className="row">
                  {data?.payload?.data ? (
                    data?.payload?.data.map(
                      (
                        {
                          id,
                          role: groupName,
                          no_of_attached_users: users,
                          slug,
                        },
                        index
                      ) => (
                        <div className="col-md-4" key={index}>
                          <div className={`${styles.card}`} key={index}>
                            <div>
                              <h4>{convertToCapitalize(groupName)}</h4>
                              <p>
                                Users: <span>{users}</span>
                              </p>
                              <hr />
                            </div>
                            <div className={styles.btns}>
                              <Space>
                                {canAccess(
                                  "update-group-human-resource-information-system-preferences"
                                ) && (
                                  <Button>
                                    <Link
                                      to={`/modules/permissions/view-groups/edit-groups/${id}`}
                                    >
                                      Edit
                                    </Link>
                                  </Button>
                                )}

                                {canAccess(
                                  "view-group-details-human-resource-information-system-preferences"
                                ) && (
                                  <Button>
                                    <Link to={`${slug}/${id}`}>
                                      See Details
                                    </Link>
                                  </Button>
                                )}

                                {/* {canAccess(
                                "delete-group-human-resource-information-system-preferences"
                              ) && (
                                <Button
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  onClick={modalHandler}
                                >
                                  Delete
                                </Button>
                              )} */}

                                <Modal
                                  title={`Delete ${convertToCapitalize(
                                    groupName
                                  )}`}
                                  centered
                                  onOk={() => confirm(id)}
                                  onCancel={modalHandler}
                                  open={showModal}
                                  cancelText="No"
                                  okText="Yes"
                                >
                                  <p className="h6">
                                    Are you sure to delete this group?
                                  </p>
                                </Modal>
                              </Space>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className=" d-flex justify-content-center align-items-center ">
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color={"var(--clr-primary)"}
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              </main>
              <div className="mt-3 d-flex justify-content-between">
                <Pagination
                  total={data?.payload?.meta?.last_page}
                  pageSize={1}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ViewGroups;
