import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function EmploymentHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          name: em.company_name,
          position: em.position || "--",
          job: em.job_description || "--",
          start: em.start_date.split("T")?.[0] || "--",
          end: em.end_date.split("T")?.[0] || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "COMPANY NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "POSITION",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "JOB DESCRIPTION",
      dataIndex: "job",
      key: "job",
    },
    {
      title: "START DATE",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "END DATE",
      dataIndex: "end",
      key: "end",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} />;
}

export default EmploymentHistory;
