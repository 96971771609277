import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { setSettings } from "../../../redux/users/userActions";
import { useGetSystemSettings } from "../../../util/usershookActions";
import { settings } from "../../../util/selectors/userSelectors";

const loop = [
  { name: "Daily User Login", key: "daily_user_login" },
  { name: "Biometric Capture", key: "biometric_capture" },
  { name: "Service Year Chart", key: "service_year_chart" },
];

function DisplayDashboard() {
  useUserCleanUp();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState([]);
  const [enabled_settings, setEnabledSettings] = useState(true);

  const { refetch } = useGetSystemSettings(
    enabled_settings,
    setEnabledSettings
  );

  const all_settings = useShallowEqualSelector(settings);
  const onChange = (check, e, type) => {
    if (check) {
      const new_arry = [...current];
      if (!new_arry.includes(type)) {
        setCurrent([...new_arry, type]);
        const to_update = [...new_arry, type];
        setCurrent(to_update);
        handleupdate(to_update);
      }
    } else {
      const to_update = [...current].filter((item) => item !== type);
      setCurrent(to_update);
      handleupdate(to_update);
    }
  };

  function handleupdate(to_update) {
    setLoading(true);
    const creds = {
      type: "display",
      value: JSON.stringify(to_update),
    };
    setSettings(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
      // if (res?.status === "success") {
      // }
    });
  }

  useEffect(() => {
    if (Array.isArray(all_settings?.other_settings?.display?.value)) {
      setCurrent(all_settings?.other_settings?.display?.value);
    }
  }, [all_settings]);
  return (
    <div className="category">
      <div className="row justify-content-between">
        <div className="col-md-5">
          <div>
            <div className="d-flex justify-content-between">
              <h4>Display</h4>
              {loading ? <LoadingOutlined /> : null}
            </div>
            <small>Select what should be rendered on the dashboard</small>
          </div>
        </div>
        {/* <div className="col-md-7 align-self-end">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex gap-1">
              <button>Daily User Login</button>
              <button>Biometric Capture</button>
            </div>
            <div>
              <button className="w-100">Service Year Chart</button>
            </div>
          </div>
        </div> */}

        <div className="col-md-4 align-self-center">
          {loop?.map((item) => (
            <div
              key={item.key}
              className="d-flex gap-1 justify-content-between mb-3"
            >
              <p className="fonnt">{item.name}</p>
              <div className="d-flex justify-content-end">
                <Switch
                  checked={current.includes(item.key)}
                  onChange={(check, e) => onChange(check, e, item.key)}
                  className="w-50"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DisplayDashboard;
