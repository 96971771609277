import React, { useState, useEffect } from "react";
import { Table, Drawer, Space, Button } from "antd";

import ReadOnlyTemplateRenderer from "./ReadOnlyTemplateRenderer";
import { useGetRequestAnswersForHistory } from "../../../../util/usershookActions";
import SkeletonUI from "../../../ui/Skeleton";
import NoContent from "../../../ui/NoContent";
import ReadOnlyExpenseReportTemplate from "./../../predefined-template/expense-report-template/ReadOnlyExpenseReportTemplate";

const RequestTemplateHistoryTable = ({ employeeRequestHistory }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (employeeRequestHistory) {
      const new_array = employeeRequestHistory?.payload?.submissions?.map(
        (request, index) => {
          return {
            key: request.submission,
            employee: request.employee,
            department: request.department,
            date_of_request: request.date_of_request,
            approvals: request.approvals,
            request,
          };
        }
      );
      setTableData(new_array);
    }
  }, [employeeRequestHistory]);

  // Function to get ordinal suffix
  const getOrdinalSuffix = (i) => {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  let columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      title: "Date of Request",
      dataIndex: "date_of_request",
      align: "center",
    },
  ];

  let maxLevels = 0;
  employeeRequestHistory?.payload?.submissions?.forEach((submission) => {
    const levels = Object.keys(submission.approvals).length;
    if (levels > maxLevels) {
      maxLevels = levels;
    }
  });

  for (let i = 1; i <= maxLevels; i++) {
    columns.push(
      {
        title: `${getOrdinalSuffix(i)} Approver`,
        dataIndex: [`approvals`, `level${i}`, "status"],
        align: "center",
        render: (text) => text?.toUpperCase(),
      },
      {
        title: `Date`,
        dataIndex: [`approvals`, `level${i}`, "date"],
        align: "center",
        render: (text) => text || "N/A",
      }
    );
  }

  // Add action column
  columns.push({
    title: "Action",
    key: "action",
    render: (text, record) => (
      <EachAction
        selectedRequest={record.request}
        getOrdinalSuffix={getOrdinalSuffix}
        templateName={employeeRequestHistory?.payload?.template_name}
        submissionID={record.key}
      />
    ),
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 1200 }}
        bordered
        pagination={false}
      />
    </>
  );
};

function EachAction({
  selectedRequest,
  getOrdinalSuffix,
  templateName,
  submissionID,
}) {
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, refetch, isLoading } = useGetRequestAnswersForHistory(
    enabled,
    setEnabled,
    submissionID
  );

  useEffect(() => {
    if (submissionID) {
      setEnabled(true);
    }
  }, [submissionID]);

  const expenseReportAnswers =
    data?.payload?.template_type === "expense-report"
      ? data?.payload?.answers?.[0]?.answer
      : null;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function getStatus(approvals) {
    let statusCount = {
      approved: 0,
      rejected: 0,
      pending: 0,
    };

    approvals.forEach((approval) => {
      statusCount[approval.status]++;
    });

    if (statusCount["rejected"] > 0) {
      return "REJECTED";
    } else if (statusCount["approved"] === approvals.length) {
      return "APPROVED";
    } else {
      return "PENDING";
    }
  }

  return (
    <>
      <div
        className=""
        onClick={showDrawer}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        View
      </div>
      <Drawer
        title={templateName}
        width={"60%"}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        {isLoading ? (
          <SkeletonUI number={5} />
        ) : data?.payload ? (
          <>
            <div className="make__request__form">
              <div className="make__request__form__title">
                <h4 className="h4">{templateName}</h4>
              </div>

              {data?.payload?.template_type === null ? (
                data?.payload?.answers?.map((answer, index) => (
                  <ReadOnlyTemplateRenderer question={answer} key={index} />
                ))
              ) : data?.payload?.template_type === "expense-report" ? (
                <div
                  style={{
                    maxWidth: "80rem",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <ReadOnlyExpenseReportTemplate
                    expenseReportAnswers={expenseReportAnswers}
                    templatePreview={false}
                  />
                </div>
              ) : null}
            </div>
            <div
              className="d-flex flex-column gap-2"
              style={{
                borderTop: "1px solid #d9d9d9",
                paddingTop: "1.5rem",
                marginTop: "2rem",
              }}
            >
              <div className="d-flex flex-column gap-2 mt-3">
                <h5 className="mb-3">
                  STATUS: {getStatus(data?.payload?.approvals)}
                </h5>
                <div className="create__request__form__grid">
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Employee Name:</label>
                    <p className="fs-6">{data?.payload?.employee || "N/A"}</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Department:</label>
                    <p className="fs-6">{data?.payload?.department || "N/A"}</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Date of Request:</label>
                    <p className="fs-6">
                      {data?.payload?.date_of_request || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="create__request__form__grid">
                  {data?.payload?.approvals?.map((level, index) => (
                    <div className="d-flex flex-column gap-2" key={index}>
                      <span className="fs-6">
                        {`${getOrdinalSuffix(level?.level)} Approver`} (
                        {level?.status?.toUpperCase()})
                      </span>
                      <span className="fs-6">{level?.approver || "N/A"}</span>
                      <div className="d-flex flex-column gap-2">
                        <label className="fs-6">Date Approved/Rejected:</label>
                        <span className="fs-6">
                          {level?.aproved_at || "N/A"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoContent
            width={"400px"}
            message={"No Answers Found"}
            illustration={"/assets/selected.png"}
          />
        )}
      </Drawer>
    </>
  );
}

export default RequestTemplateHistoryTable;
