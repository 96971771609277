import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { setPrivateRequest } from "./requestMethods";

const useGetClientPermissions = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["client_perm"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/preferences`);
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      console.log("clientPermission",data);
      dispatch({ type: "CLIENT_PERMISSIONS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["client_perm"]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "CLIENT_PERMISSIONS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};
const useGetSystemBanks = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_banks"],
    async () => {
      let result = await setPrivateRequest().get(`/hrm/preferences/bank`);
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      // console.log(data);
      dispatch({ type: "FETCH_BANKS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_banks"]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "FETCH_BANKS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetAllClientGroup = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["client_group", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/preferences/role?page=${page}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "CLIENT_GROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["client_group", page]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "CLIENT_GROUP_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch };
};
const useGetUserInGroup = (enabled, setEnabled, page, groupId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["users_in_group", page, groupId],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/preferences/role/${groupId}/users?page=${page}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "USERS_IN_GROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["users_in_group", page, groupId]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "USERS_IN_GROUP_ERROR", payload: resMessage });
    }
  }, [
    dispatch,
    isLoading,
    data,
    error,
    setEnabled,
    queryClient,
    page,
    groupId,
  ]);

  return { data, refetch };
};
const useGetPermissionsInGroup = (enabled, setEnabled, groupId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["permissons_in_group", groupId],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/preferences/role/${groupId}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "PERMISSIONS_IN_GROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["permissons_in_group", groupId]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "PERMISSIONS_IN_GROUP_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, groupId]);

  return { data, refetch };
};

const useGetOneClientGroup = (enabled, setEnabled, groupId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["one_group", groupId],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/preferences/role/${groupId}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      console.log("groupPermissions",data);
      dispatch({ type: "SINGLE_GROUP_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["one_group", groupId]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "SINGLE_GROUP_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, groupId]);

  return { data, refetch };
};

const useGetAllEmployee = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["all_emp_pref", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/employees/all${page ? `?page=${page}` : ""}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_PREFRENCE_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      // console.log(data);
      dispatch({ type: "ALL_EMPLOYEE_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["all_emp_pref", page]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      if (error?.response?.status === 403) {
        return;
      }
      dispatch({ type: "STOP_PREFRENCE_SPINNER" });
      dispatch({ type: "ALL_EMPLOYEE_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch, isLoading };
};

const useGetClientSubscriptions = (enabled, setEnabled, page) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["client_sub", page],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/dashboard/subscriptions?page=${page}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CLIENT_SUBCRIPTION_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["client_sub", page]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CLIENT_SUBCRIPTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page]);

  return { data, refetch };
};
const useGetPerformanceApprovals = (enabled, setEnabled, page, year) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["perf_approvals", page, year],
    async () => {
      let result = await setPrivateRequest().get(
        `/hrm/performance/plan/approvals?page=${page}&year=${year}`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      // dispatch({ type: "CLIENT_SUBCRIPTION_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["perf_approvals", page, year]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "GENERIC_ACTION_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient, page, year]);

  return { data, refetch, isLoading };
};

export {
  useGetClientPermissions,
  useGetAllClientGroup,
  useGetOneClientGroup,
  useGetAllEmployee,
  useGetUserInGroup,
  useGetPermissionsInGroup,
  useGetClientSubscriptions,
  useGetSystemBanks,
  useGetPerformanceApprovals,
};
