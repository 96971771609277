import React, { useEffect, useState } from "react";
import Template from "./Template";
import { useDispatch } from "react-redux";
import { SAVE_ACTIVITIES, SAVE_TEMPLATE } from "../../../redux/users/userTypes";
import { useShallowEqualSelector } from "../../../util/hooks";
import { selected_template } from "../../../util/selectors/userSelectors";

const SelectTemplate = ({ template }) => {
  const selectedTemp = useShallowEqualSelector(selected_template);
  console.log({ template });

  const [templates, setTemplates] = useState([]);
  const [active, setActive] = useState(selectedTemp?.id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (template && template?.data?.length) {
      setTemplates(template.data);
    } else {
      setTemplates([]);
    }
  }, [template]);

  const handleSelected = (id) => {
    const selected = templates.find((temp) => temp.id === id);
    // console.log({ selected });

    setActive(id);
    dispatch({ type: SAVE_TEMPLATE, payload: selected });
    dispatch({
      type: SAVE_ACTIVITIES,
      payload: selected.activities.map((item) => {
        return {
          ...item,
          start_time: null,
          end_time: null,
          start_date: null,
          end_date: null,
        };
      }),
    });
  };

  return (
    <div className="templates">
      {templates
        .filter((temp) => temp.activities.length)
        .map((temp) => (
          <div
            key={temp.id}
            style={{
              border: `${
                temp.id === active ? "2px solid var(--clr-primary)" : ""
              }`,
            }}
          >
            <Template
              id={temp.id}
              title={temp.name}
              desc={temp.description}
              activity_count={temp.activities.length}
              handleSelect={handleSelected}
            />
          </div>
        ))}
    </div>
  );
};

export default SelectTemplate;
