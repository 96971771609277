import { useState, useEffect } from "react";
import { Select, Table } from "antd";
import SkeletonUI from "../../../ui/Skeleton";

import {
  useGetSystemDepartments,
  useGetDepartmentScoreCard,
  useGetPerformanceYear,
  useGetAppraisalsApproved,
} from "../../../../util/usershookActions";
import NoContent from "../../../ui/NoContent";

import dayjs from "dayjs";

const AllDepartmentScoreCard = () => {
  const [enabled, setEnabled] = useState(true);
  const [scoreCardEnabled, setScoreCardEnabled] = useState(false);

  const [tableData, setTableData] = useState();
  const [department_id, setDepartmentId] = useState("");

  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [review_period_id, setReviewPeriodId] = useState();

  const { data: systemsDepartments } = useGetSystemDepartments(
    enabled,
    setEnabled
  );

  const { data: performanceYear, isLoading: isLoadingPerformanceYear } =
    useGetPerformanceYear(enabled, setEnabled);

  const {
    data: appraisalReviewPeriods,
    refetch: appraisalReviewPeriodsRefetch,
  } = useGetAppraisalsApproved(enabled, setEnabled);

  const { data: departmentScoreCard, isLoading: isLoadingDepartmentScoreCard, refetch } =
    useGetDepartmentScoreCard(
      scoreCardEnabled,
      setScoreCardEnabled,
      department_id,
      start_date,
      end_date,
      review_period_id
    );

  useEffect(() => {
    if (department_id && start_date && end_date) {
      setScoreCardEnabled(true);
    }
  }, [department_id, end_date, start_date]);

  useEffect(() => {
    if (review_period_id) {
      refetch();
    }
  }, [review_period_id, refetch]);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeYear = (value) => {
    setStartDate(value.split("_")[0]);
    setEndDate(value.split("_")[1]);
  };

  useEffect(() => {
    if (departmentScoreCard) {
      const new_array = departmentScoreCard?.payload?.map(
        (scoreCard, index) => {
          return {
            key: scoreCard.id,
            goal: scoreCard.goal,
            objective: scoreCard.objective,
            kpi: scoreCard.kpi,
            target: `${scoreCard.target} (${scoreCard.unit})`,
            weight: scoreCard.weight,
            ...Object.keys(scoreCard.score).reduce((acc, period) => {
              acc[period] = scoreCard.score[period];
              return acc;
            }, {}),
          };
        }
      );
      setTableData(new_array);
    }
  }, [departmentScoreCard]);

  const columns = [
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "KPI",
      dataIndex: "kpi",
      align: "center",
    },
    {
      title: "Target",
      dataIndex: "target",
      align: "center",
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      align: "center",
    },
    {
      title: "Score",
      align: "center",
      children: Object.keys(departmentScoreCard?.payload?.[0]?.score || {}).map(
        (period) => ({
          title: period.toUpperCase(),
          dataIndex: period,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="">
          <h3 className="h4">All Department Score Card</h3>
          <p>This displays user department current score card information</p>
        </div>

        <div className="monitoring__individual__content__header">
          <div className="d-flex flex-column gap-2">
            <label className="fw-bold fs-6">Department Category</label>
            <Select
              showSearch
              size="large"
              placeholder="Select a department"
              optionFilterProp="children"
              filterOption={filterOption}
              style={{
                border: "1px solid black",
                borderRadius: "10px",
              }}
              onChange={(value) => setDepartmentId(value)}
              options={systemsDepartments?.payload?.map((department) => ({
                value: department?.id,
                label: department?.name,
              }))}
            />
          </div>
        </div>

        <div className="monitoring__individual__content">
          <div className="monitoring__individual__content__header">
            <div className="d-flex flex-column gap-2 planning__form__item">
              <label className="fw-bold fs-6">Performance Planning Year</label>
              <Select
                showSearch
                size="large"
                placeholder="Select performance year"
                optionFilterProp="children"
                onChange={onChangeYear}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={performanceYear?.payload?.map((year) => ({
                  value: `${year.start_date}_${year.end_date}`,
                  label: `${dayjs(year.start_date).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(year.end_date).format("DD/MM/YYYY")}`,
                }))}
              />
            </div>
            <div className="d-flex flex-column gap-2 ">
              <label className="fw-bold fs-6">Review Period</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a period"
                optionFilterProp="children"
                value={review_period_id}
                onChange={(value) => setReviewPeriodId(value)}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={appraisalReviewPeriods?.payload?.map((period) => ({
                  value: period.id,
                  label: period.appraisal_name,
                }))}
              />
            </div>
          </div>

          {isLoadingDepartmentScoreCard ? (
            <SkeletonUI />
          ) : !isLoadingDepartmentScoreCard &&
            departmentScoreCard?.payload?.length ? (
            <div className="monitoring__individual__content__table">
              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 1000 }}
                bordered
                pagination={false}
              />
            </div>
          ) : (
            <NoContent width={"400px"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllDepartmentScoreCard;
