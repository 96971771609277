import React from "react";
import styles from "../../styles/Management.module.css";

function Management({ hod, line }) {
  // console.log("🚀 ~ file: Management.js:5 ~ Management ~ line:", line)
  // console.log("🚀 ~ file: Management.js:5 ~ Management ~ hod:", hod)
  return (
    <div className={styles.management}>
      <div className={styles.entry}>
        <p>Line Manager</p>
        <h4>{line?.fullname || "N/A"}</h4>
      </div>
      <div className={styles.entry}>
        <p>Head of Department</p>
        <h4>{`${hod || "N/A"}`}</h4>
      </div>
    </div>
  );
}

export default Management;
