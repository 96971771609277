import React from "react";
import styles from "../../styles/TableSkeleton.module.css";

function TableSkeleton() {
  return (
    <div className={styles.row}>
      <div className={styles.skeleton}></div>
      <div className={styles.skeleton}></div>
      <div className={styles.skeleton}></div>
      <div className={styles.skeleton}></div>
      <div className={styles.skeleton}></div>
    </div>
  );
}

export default TableSkeleton;
