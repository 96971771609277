import React, { useState, useEffect } from "react";

import { Button, List, Popconfirm, Table, Input, Space, Select } from "antd";

import { useDispatch } from "react-redux";

import HocHeader from "../../ui/HocHeader";
import {
  useGetAllLeaveGroup,
  useGetAllLeaveGroupDistribution,
  useGetSystemGrade,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  leave_group,
  leave_group_distribution,
  user_errors,
} from "../../../util/selectors/userSelectors";
import {
  addEditLeaveGroupDistribution,
  deleteLeaveGroupDistribution,
} from "../../../redux/users/userActions";

import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { AiOutlineSearch } from "react-icons/ai";

function GradeLeaveDistributionList() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [sys_grade, setSysGrade] = useState([]);
  const [enabled_grade, setEnabledGrade] = useState(true);

  const [enabled_group, setEnabledgroup] = useState(true);

  const [addDept, setAddDept] = useState(false);

  const [values, setValues] = useState({
    grade_id: [],
    leave_group_id: "",
    available: "",
    transferrable_amount: "",
  });

  const { refetch, isLoading } = useGetAllLeaveGroupDistribution(
    enabled,
    setEnabled
  );

  const { data: system_grade_details } = useGetSystemGrade(
    enabled_grade,
    setEnabledGrade
  );

  useGetAllLeaveGroup(enabled_group, setEnabledgroup);

  const leave_gp = useShallowEqualSelector(leave_group);

  const back_errors = useShallowEqualSelector(user_errors);

  const sys_leave_group = useShallowEqualSelector(leave_group_distribution);

  useEffect(() => {
    if (system_grade_details && system_grade_details?.data?.length) {
      setSysGrade(system_grade_details.data);
    }
  }, [system_grade_details]);

  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Leave Type",
      dataIndex: "leave_group",
      key: "leave_group",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("leave_group", "Leave Type"),
    },
    {
      title: "Grade Level",
      dataIndex: "grade_level",
      key: "grade_level",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("grade_level", "Grade Level"),
    },
    {
      title: "Available ",
      dataIndex: "available",
      key: "available",
      render: (value) => {
        return value ? value : "N/A";
      },
      ...getColumnSearchProps("available", "Avaliable"),
    },
    {
      title: "Transferable",
      dataIndex: "transferrable_amount",
      key: "transferrable_amount",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Action",

      render: (_, item) => {
        return (
          <GroupEachList
            item={item}
            handleSelected={handleSelected}
            refetch={refetch}
          />
        );
      },
    },
  ];

  const clearSelected = () => {
    setSelected({});
    setValues({
      grade_id: "",
      leave_group_id: "",
      available: "",
      transferrable_amount: "",
    });
  };

  const handleAddDept = () => {
    clearSelected();
    setAddDept(!addDept);
    document.querySelector(".scrollMe").scrollIntoView({
      behavior: "smooth",
    });
  };

  function handleSelected(item) {
    // console.log(item);
    setSelected(item);
    setValues(item);
    setAddDept(true);
    document.querySelector(".scrollMe").scrollIntoView({
      behavior: "smooth",
    });
  }

  console.log("valuees", values);

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };
  const handleSelect = (value, name) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditLeaveGroupDistribution(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          grade_id: [],
          leave_group_id: "",
          available: "",
          transferrable_amount: "",
        });

        if (Object.keys(selected).length) {
          // change steps to grades later when it has been corrected form the back end
          //   dispatch({
          //     type: "UPDATE_TO_LEAVE_GROUP",
          //     payload: res.payload.grade,
          //   });
          dispatch({
            type: "UPDATE_TO_LEAVE_GROUP",
            payload: {
              ...values,
              id: Object.keys(selected).length ? selected.id : null,
            },
          });
        } else {
          //   dispatch({
          //     type: "ADD_TO_LEAVE_GROUP",
          //     payload: res.payload.grade,
          //   });
          dispatch({
            type: "ADD_TO_LEAVE_GROUP",
            payload: values,
          });
        }
        setAddDept(false);
      }
      // console.log({ back_errors });
    });
  };

  useEffect(() => {
    if (sys_leave_group.length) {
      const improved = sys_leave_group.map((item, ind) => {
        return {
          ...item,
          key: item.id,
          index: ind + 1,
          grade_level: item?.grade?.name,
          leave_group: item?.leave_group?.title,
          leave_group_id: item?.leave_group?.id,
          grade_id: item?.grade_id,
        };
      });
      setData(improved);
    } else {
      setData([]);
    }
  }, [sys_leave_group]);

  // console.log({ data });

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4 ">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0 scrollMe">
                Available leave grade distribution
              </h5>
              <small className="w-75 d-none d-sm-block">
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                magnam qui perspiciatis animi doloribus velit aperiam excepturi
                tempora culpa consequatur laboriosam pariatur dicta eius totam,
                minus natus praesentium. Exercitationem, non? */}
              </small>
            </div>
          </div>

          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center mb-4">
              <div className="circle__bagde fw-bold">{data?.length}</div>
              <h6 className="mb-0">Leave grade distribution</h6>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}
                {addDept ? (
                  <>
                    <form className="onboarding-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="name" className="mb-1">
                                Grade Level
                              </label>
                              <div className=" text-right text-danger d-flex justify-content-end">
                                <small>
                                  {back_errors.grade_id &&
                                    back_errors.grade_id[0]}
                                </small>
                              </div>
                            </div>

                            <Select
                              mode="multiple"
                              placeholder="Select level"
                              id="grade_id"
                              name="grade_id"
                              value={values.grade_id ? values.grade_id : []}
                              className="w-100"
                              onChange={(value) =>
                                handleSelect(value, "grade_id")
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (option?.children ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            >
                              {sys_grade?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="leave_group_id">Leave Type</label>
                              <div className=" text-right text-danger d-flex justify-content-end">
                                <small>
                                  {back_errors.leave_group_id &&
                                    back_errors.leave_group_id[0]}
                                </small>
                              </div>
                            </div>

                            <select
                              type="text"
                              id="leave_group_id"
                              name="leave_group_id"
                              value={values.leave_group_id || ""}
                              onChange={handleChange}
                              placeholder=" leave group"
                            >
                              <option value="">--</option>
                              {leave_gp?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="available">
                                Available Amount
                              </label>
                              <div className=" text-right text-danger d-flex justify-content-end">
                                <small>
                                  {back_errors.available &&
                                    back_errors.available[0]}
                                </small>
                              </div>
                            </div>

                            <input
                              type="text"
                              id="available"
                              name="available"
                              value={values.available || ""}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="transferrable_amount">
                                Transferable Amount
                              </label>
                              <div className=" text-right text-danger d-flex justify-content-end">
                                <small>
                                  {back_errors.transferrable_amount &&
                                    back_errors.transferrable_amount[0]}
                                </small>
                              </div>
                            </div>

                            <input
                              type="text"
                              id="transferrable_amount"
                              name="transferrable_amount"
                              value={values.transferrable_amount || ""}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end mt-2">
                        {/* {Object.keys(selected).length ? (
                    
                  ) : null} */}
                        <div>
                          <Button
                            className="myBtn mt-3 mx-2"
                            onClick={() => {
                              clearSelected();
                              handleAddDept();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div>
                          <Button
                            className="myBtn  mt-3"
                            htmlType="submit"
                            loading={loading}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </form>

                    <div
                      // style={{
                      //   maxHeight: 500,
                      //   overflowY: "auto",
                      // }}
                      className="my-3"
                    >
                      {isLoading ? (
                        <SkeletonUI number={2} />
                      ) : data.length ? (
                        //   <List
                        //     itemLayout="horizontal"
                        //     dataSource={data}
                        //     renderItem={(item) => (
                        //       <GroupEachList
                        //         item={item}
                        //         handleSelected={handleSelected}
                        //         refetch={refetch}
                        //       />
                        //     )}
                        //   />

                        <Table
                          columns={columns}
                          dataSource={data}
                          bordered
                          scroll={{ x: "768px" }}
                          pagination={data.length > 10 ? true : false}
                        />
                      ) : (
                        <NoContent
                          message={"No leave grade distribution available."}
                          width={"400px"}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    // style={{
                    //   maxHeight: 500,
                    //   overflowY: "auto",
                    // }}
                    className="my-3"
                  >
                    {isLoading ? (
                      <SkeletonUI number={2} />
                    ) : data.length ? (
                      //   <List
                      //     itemLayout="horizontal"
                      //     dataSource={data}
                      //     renderItem={(item) => (
                      //       <GroupEachList
                      //         item={item}
                      //         handleSelected={handleSelected}
                      //         refetch={refetch}
                      //       />
                      //     )}
                      //   />

                      <Table
                        columns={columns}
                        dataSource={data}
                        bordered
                        scroll={{ x: "768px" }}
                        pagination={data.length > 10 ? true : false}
                      />
                    ) : (
                      <NoContent
                        message={"No leave grade distribution available."}
                        width={"400px"}
                      />
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  {!addDept && (
                    <Button className="myBtn  mt-3" onClick={handleAddDept}>
                      Add New
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const GroupEachList = ({ item, handleSelected, refetch }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const confirm = (item) => {
    setLoading(true);
    deleteLeaveGroupDistribution(dispatch, item).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        dispatch({ type: "REMOVE_FROM_LEAVE_GROUP_DIS", payload: item.id });
        refetch();
      }
    });
  };
  return (
    <Space>
      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-warning`}
        onClick={() => handleSelected(item)}
      >
        Edit
      </Button>
      <Popconfirm
        title="Delete entries"
        description="Do you what to delete this entry?"
        onConfirm={() => confirm(item)}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
          loading={loading}
        >
          Delete
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default GradeLeaveDistributionList;
