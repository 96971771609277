import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { useCloseMobileSibar } from "../util/hooks";

function PageNotFound() {
  const navigate = useNavigate();
  useCloseMobileSibar();
  return (
    <div className="expand__reset w-100">
      <div className="container not-found w-100">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="d-flex flex-column gap-5">
              <h1>Page Not Found</h1>
              <p>
                The page you are looking for is not available. You may have
                entered a wrong address or it could be from our end. We
                apologize
              </p>
              <div>
                <Button className="myBtn" onClick={() => navigate(-1)}>
                  Go Back
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <img src="/assets/404-not-found.jpg" alt="Page not found" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
