import { Button, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";
import { addNewBenefits } from "../../../redux/users/userActions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { typesArry } from "../../../util/helpers";

function AddBenefits() {
  useUserCleanUp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);
  const [type_arry, setTypeArr] = useState([
    {
      type: "",
    },
  ]);

  const [benefit, setBenefit] = useState("");
  const [type, setType] = useState("text");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(1);
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  function addNewType(e) {
    const new_arry = [...type_arry];
    new_arry.splice(new_arry.length, 1, {
      type: "",
    });
    setTypeArr(new_arry.reverse());
  }

  function removeType(e, index) {
    const new_arry = [...type_arry];
    new_arry.splice(index, 1);
    setTypeArr(new_arry);
  }

  function handleChangeType(e, index) {
    const new_arry = [...type_arry];
    const affected = new_arry[index];
    affected.type = e.target.value;
    setTypeArr(new_arry);
  }

  useEffect(() => {
    if (benefit.length && description.length) {
      setDisabled(false);
      type_arry.forEach((item) => {
        if (item.type === "") {
          setDisabled(true);
        }
      });
    } else {
      setDisabled(true);
    }
  }, [benefit, description, type_arry]);

  function addBenefitFunc(e) {
    e.preventDefault();

    Modal.confirm({
      title: "Add benefit",
      content: "Do you want to perform this action?",
      onOk: submitBenefit,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function submitBenefit() {
    const creds = {
      benefit,
      description,
      type,
      value,
      status,
      compare_with: "GROSS",
    };

    setLoading(true);
    addNewBenefits(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        clearForm();
        navigate("/compensation/list");
      }
    });
  }

  function clearForm() {
    setBenefit("");
    setDescription("");
    setValue("");
    setStatus(1);
    setType("text");
  }

  function handleCheck(val) {
    if (val) {
      setStatus(1);
    } else {
      setStatus(0);
    }
  }

  function getTypeField() {
    if (type === "text") {
      return (
        <input
          type="text"
          name="value"
          id="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Toyota car"
        />
      );
    } else if (type === "number") {
      return (
        <input
          type="number"
          name="value"
          id="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="123.45"
        />
      );
    } else if (type === "percentage") {
      return (
        <input
          type="number"
          name="value"
          id="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="12%"
        />
      );
    }
  }

  return (
    <>
      {/* <nav className=" d-flex justify-content-end align-items-center">
        <ul className="d-flex align-items-center mb-0 gap-5 goal__nav__links">
          <li>
            <Link
              to={`/compensation/list`}
              className="d-flex align-items-center gap-2 back"
            >
              <MdOutlineArrowBack />
              Back to list
            </Link>
          </li>
        </ul>
      </nav> */}

      <div className="row ">
        <div className="col-md-12">
          <section className="app__section py-4">
            <h5 className="">
              Add benefit
              <Link to={`/modules/compensation/list`}>
                <p
                  className="performance__bagde2 backBtn"
                  style={{
                    float: "right",
                  }}
                >
                  <MdOutlineArrowBack />
                  Back to list
                </p>
              </Link>
            </h5>
            <small className="w-75 d-none d-sm-block">
              If you are unable to find the benefit that suit you, you can add
              them to the human resouece management system easily.
            </small>
            <div className="row mt-3">
              <form className="performance-form" onSubmit={addBenefitFunc}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap">
                      <label htmlFor="benefit">Benefit</label>

                      <small className="text-danger">
                        {back_errors.benefit && back_errors.benefit[0]}
                      </small>
                    </div>

                    <input
                      type="text"
                      name="benefit"
                      id="benefit"
                      value={benefit}
                      onChange={(e) => setBenefit(e.target.value)}
                      placeholder="Official Car"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap">
                      <label htmlFor="benefit">Type</label>

                      <small className="text-danger">
                        {back_errors.type && back_errors.type[0]}
                      </small>
                    </div>

                    <select
                      type="text"
                      name="benefit"
                      id="benefit"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {typesArry.map((item) => (
                        <option value={item.value} key={item.key}>
                          {item.key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap">
                      <label htmlFor="benefit">Value</label>

                      <small className="text-danger">
                        {back_errors.value && back_errors.value[0]}
                      </small>
                    </div>

                    {getTypeField()}
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                      <label htmlFor="benefit">Active Status</label>

                      <small className="text-danger">
                        {back_errors.status && back_errors.status[0]}
                      </small>
                    </div>

                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={status}
                      onChange={handleCheck}
                    />
                  </div>

                  <div className="col-md-12 mb-5">
                    <div className="d-flex justify-content-between flex-wrap">
                      <label htmlFor="description">Description</label>
                      <small className="text-danger">
                        {back_errors.description && back_errors.description[0]}
                      </small>
                    </div>

                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="This is for senior managers in the company"
                      rows={"6"}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end  ">
                  <Button
                    htmlType="submit"
                    className="myBtn d-flex align-items-center"
                    loading={loading}
                  >
                    Add Benefit
                  </Button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddBenefits;
