import { useState, useEffect } from "react";
import { Select, Table } from "antd";
import SkeletonUI from "../../../ui/Skeleton";

import {
  useGetOrganizationScoreCard,
  useGetPerformanceYear,
  useGetAppraisalsApproved,
} from "../../../../util/usershookActions";

import dayjs from "dayjs";
import NoContent from "../../../ui/NoContent";

const OrganizationScoreCard = () => {
  const [enabled, setEnabled] = useState(true);
  const [scoreCardEnabled, setScoreCardEnabled] = useState(false);

  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [review_period_id, setReviewPeriodId] = useState();

  const { data: performanceYear, isLoading: isLoadingPerformanceYear } =
    useGetPerformanceYear(enabled, setEnabled);

  const {
    data: appraisalReviewPeriods,
    refetch: appraisalReviewPeriodsRefetch,
  } = useGetAppraisalsApproved(enabled, setEnabled);

  const {
    data: organizationScoreCard,
    isLoading: isLoadingOrganizationScoreCard,
    refetch
  } = useGetOrganizationScoreCard(
    scoreCardEnabled,
    setScoreCardEnabled,
    start_date,
    end_date,
    review_period_id
  );

  useEffect(() => {
    if (start_date && end_date) {
      setScoreCardEnabled(true);
    }
  }, [end_date, start_date]);

  useEffect(() => {
    if (review_period_id) {
      refetch();
    }
  }, [review_period_id, refetch]);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeYear = (value) => {
    setStartDate(value.split("_")[0]);
    setEndDate(value.split("_")[1]);
  };

  const groupedData = {};

  // Group the data by department
  organizationScoreCard?.payload?.forEach((item) => {
    const { department } = item;

    if (!groupedData[department]) {
      groupedData[department] = [];
    }
    groupedData[department].push(item);
  });

  // Transform the grouped data into an array
  const transformedData = Object.keys(groupedData).reduce((acc, department) => {
    const departmentData = groupedData[department].map((item, index) => ({
      ...item,
      rowSpan: index === 0 ? groupedData[department].length : 0,
    }));
    return [...acc, ...departmentData];
  }, []);

  const columns = [
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
      render: (text, record) => ({
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      }),
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
      render: (text, record) => <span>{record.goal}</span>,
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
      render: (text, record) => <span>{record.objective}</span>,
    },
    {
      title: "KPI",
      dataIndex: "kpi",
      align: "center",
      render: (text, record) => <span>{record.kpi}</span>,
    },
    {
      title: "Target",
      dataIndex: "target",
      align: "center",
      render: (text, record) => (
        <span>
          {record.target} ({record.unit})
        </span>
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      align: "center",
      render: (text, record) => <span>{record.weight}</span>,
    },
    {
      title: "Score",
      align: "center",
      children: Object.keys(organizationScoreCard?.payload?.[0]?.score || {}).map((period) => ({
        title: period.toUpperCase(),
        dataIndex: ['score', period],
        align: "center",
        render: (text) => (text === null ? "N/A" : text),
      })),
    }
  ];

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="">
          <h3 className="h4">Organization Score Card</h3>
          <p>This displays user department current score card information</p>
        </div>
        <div className="monitoring__individual__content">
          <div className="monitoring__individual__content__header">
            <div className="d-flex flex-column gap-2 planning__form__item">
              <label className="fw-bold fs-6">Performance Planning Year</label>
              <Select
                showSearch
                size="large"
                placeholder="Select performance year"
                optionFilterProp="children"
                onChange={onChangeYear}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={performanceYear?.payload?.map((year) => ({
                  value: `${year.start_date}_${year.end_date}`,
                  label: `${dayjs(year.start_date).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(year.end_date).format("DD/MM/YYYY")}`,
                }))}
              />
            </div>
            <div className="d-flex flex-column gap-2 ">
              <label className="fw-bold fs-6">Review Period</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a period"
                optionFilterProp="children"
                value={review_period_id}
                onChange={(value) => setReviewPeriodId(value)}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                }}
                options={appraisalReviewPeriods?.payload?.map((period) => ({
                  value: period.id,
                  label: period.appraisal_name,
                }))}
              />
            </div>
          </div>

          {isLoadingOrganizationScoreCard ? (
            <SkeletonUI />
          ) : !isLoadingOrganizationScoreCard &&
            organizationScoreCard?.payload?.length ? (
            <div className="monitoring__individual__content__table">
              <Table
                columns={columns}
                dataSource={transformedData}
                scroll={{ x: 1000 }}
                bordered
                pagination={false}
              />
            </div>
          ) : (
            <NoContent width={"400px"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationScoreCard;
