import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  moveToStage,
  onboardingAction,
} from "../../../redux/users/userActions";
import HireProfile from "../newHire/HireProfile";
import AssignHire from "../AssignHire";
import { Modal } from "antd";
import AntDropdown from "../AntDropdown";
import NoContent from "../../ui/NoContent";
import { Link } from "react-router-dom";
import CreateAccount from "../hire-stage/CreateAccount";

function EachAction({
  template_show,
  users,
  record,
  refetchList,
  isLoading_tem,
  handlepagination,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showModalMove, setShowModalMove] = useState(false);
  const [loadingmove, setLoadingMove] = useState(false);
  const [loadingdel, setLoadingDel] = useState(false);
  const [showModaldel, setShowModaldel] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);

  const profileModalHandler = () => {
    setShowProfileModal(!showProfileModal);
  };

  const creationModalHandler = () => {
    setShowCreationModal(!showCreationModal);
  };

  const modalHandlerDel = () => {
    setShowModaldel(!showModaldel);
  };

  const confirmDelete = (id) => {
    setLoadingDel(true);
    Modal.destroyAll();
    const creds = {
      type: "delete",
      id: record.id,
      action: "delete",
    };

    onboardingAction(dispatch, creds).then((res) => {
      setLoadingDel(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_MAN", payload: record.id });
        refetchList(true);
      }
    });
  };
  const alertDelete = () => {
    Modal.confirm({
      title: "Archive record",
      content: "Do you wish to proceed?",
      onOk: confirmDelete,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerDel,
      open: showModaldel,
      closable: true,
    });
  };
  const modalHandler = () => {
    setShowModal((prev) => {
      if (prev) {
        dispatch({ type: "RESET_SAVE_TEMPLATE" });
      }
      return !prev;
    });
  };

  const alertMove = () => {
    Modal.confirm({
      title: "Move to Onboarding",
      content: "Do you wish to proceed?",
      onOk: confirmMove,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerMove,
      open: showModalMove,
      closable: true,
    });
  };

  const modalHandlerMove = () => {
    setShowModalMove(!showModalMove);
  };

  const confirmMove = (id) => {
    setLoadingMove(true);
    Modal.destroyAll();
    const creds = {
      type: "ONBOARDING",
      id: record.id,
    };
    moveToStage(dispatch, creds).then((res) => {
      setLoadingMove(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        dispatch({ type: "DEL_HIRE_LIST_MAN", payload: record.id });
        refetchList(true);
      }
    });
  };

  const dropdownItems = [
    {
      key: "1",
      label: <p onClick={modalHandler}>Assign Induction</p>,
    },
    {
      key: "2",
      label: <p onClick={profileModalHandler}>Show Profile</p>,
    },
    {
      key: "3",
      label: <p onClick={creationModalHandler}>Create Employee Account</p>,
    },
    {
      key: "4",
      label: <p onClick={alertMove}>Move to Onboarding</p>,
    },
    {
      key: "5",
      label: <p onClick={alertDelete}>Archive</p>,
      danger: true,
    },
  ];

  return (
    <div>
      <AntDropdown items={dropdownItems} loading={loadingmove || loadingdel} />
      <Modal
        open={showModal}
        centered
        footer={null}
        onCancel={modalHandler}
        width={1000}
      >
        {template_show?.data?.length ? (
          <AssignHire
            template_show={template_show}
            users={users}
            hire_id={record.id}
            modalHandler={modalHandler}
            refetchList={refetchList}
            type={"induction"}
            stage={"induction"}
            handlepagination={handlepagination}
            isLoading_tem={isLoading_tem}
          />
        ) : (
          <>
            <NoContent />
            <p className="text-center w-50 mx-auto">
              You do not have any template. Kindly create one in the{" "}
              <Link to={"templates"}>Manage Templates</Link> menu.
            </p>
          </>
        )}
      </Modal>

      <Modal
        open={showProfileModal}
        onCancel={profileModalHandler}
        centered
        width={1000}
        footer={null}
      >
        <HireProfile id={record.id} admin={true} />
      </Modal>
      <Modal
        open={showCreationModal}
        onCancel={creationModalHandler}
        centered
        width={1200}
        footer={null}
      >
        <CreateAccount record={record} />
      </Modal>
    </div>
  );
}

export default EachAction;
