import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { quick_setup, user_errors } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import countries from "../../util/countries.json";

import styles from "../../styles/CompanyDetails.module.css";

function CompanyDetails() {
  const dispatch = useDispatch();

  const company = useShallowEqualSelector(quick_setup);

  const companyDetails = company.find(
    (details) => details.name === "Company Details"
  );
  const { field } = companyDetails;

  // console.log(field);

  const initCompanyDetails = {
    address: field.address,
    city: field.city,
    state: field.state,
    country: field.country,
    rc_number: field.rc_number,
    phone_number: field.phone_number,
    mobile_number: field.mobile_number,
  };

  const errors = useShallowEqualSelector(user_errors);

  const [values, setValues] = useState(initCompanyDetails);

  const handleChange = (e) => {
    setValues((prevState) => {
      dispatch({
        type: "QUICK_SETUP_COMP_DETAILS",
        payload: { [e?.target.name]: e?.target.value },
      });
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  console.log("values", values);

  return (
    <>
      <h3>Company Details</h3>
      <small>
        Set up your company information{" "}
        <span>
          (<em>required</em>)
        </span>
      </small>
      <div className="row">
        <div className={`${styles.content} col-lg-9`}>
          <form className={styles.form}>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="address">Address</label>
                <span>{errors.address}</span>
              </div>
              <input
                type="text"
                name="address"
                placeholder="12, Abc rd"
                value={values.address}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="city">City</label>
                <span>{errors.city}</span>
              </div>
              <input
                type="text"
                name="city"
                placeholder="Gbagada"
                value={values.city}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="state">State/Province/Region</label>
                <span>{errors.state}</span>
              </div>
              <input
                type="text"
                name="state"
                placeholder="Lagos"
                value={values.state}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="country">Country</label>
                <span>{errors.country}</span>
              </div>
              <select
                name="country"
                value={values.country}
                onChange={handleChange}
              >
                <option value="">--</option>
                {countries
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="rc_number">RC Number</label>
                <span>{errors.rc_number}</span>
              </div>

              <input
                type="text"
                name="rc_number"
                placeholder="NG-12KD9HNS"
                value={values.rc_number}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="phone_number">Phone Number</label>
                <span>{errors.phone_number}</span>
              </div>

              <input
                type="number"
                name="phone_number"
                placeholder="+234 901 234 5678"
                value={values.phone_number}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.top}>
                <label htmlFor="mobile_number">Mobile Number</label>
              </div>
              <input
                type="number"
                name="mobile_number"
                placeholder="+1 234 5678"
                value={values.mobile_number}
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
        <div className="d-none d-lg-block col-lg-3 d-flex align-items-center">
          <div className={styles.placeholderImage}>
            <img
              src="/assets/landing-page/Human Resource Management.png"
              alt="setup"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
