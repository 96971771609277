import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";

const SupervisorChart = ({
  users,
  primary_supervisor,
  secondary_supervisor,
}) => {
  const [primary, setPrimary] = useState(null);
  const [secondary, setSecondary] = useState(null);

  console.log({ primary, secondary });

  useEffect(() => {
    if (primary_supervisor) {
      const filtered = users.find(
        (item) => parseInt(item.id) === parseInt(primary_supervisor)
      );
      setPrimary(filtered);
    } else {
      setPrimary(null);
    }
  }, [primary_supervisor, users]);

  useEffect(() => {
    if (secondary_supervisor) {
      const filtered = users.find(
        (item) => parseInt(item.id) === parseInt(secondary_supervisor)
      );
      setSecondary(filtered);
    } else {
      setSecondary(null);
    }
  }, [secondary_supervisor, users]);

  return (
    <div className="d-flex flex-column gap-5 w-100">
      <div className="d-flex justify-content-around">
        {primary ? (
          <div className="super-image">
            <img
              src={
                primary && primary.avatar ? primary.avatar : "/assets/man.png"
              }
              alt="supervisor1"
              style={{
                border: "1px solid red",
                borderRadius: "50%",
                width: 100,
                height: 100,
              }}
            />
          </div>
        ) : null}

        {secondary ? (
          <div className="super-image">
            <img
              src={
                secondary && secondary.avatar
                  ? secondary.avatar
                  : "/assets/man.png"
              }
              alt="supervisor1"
              style={{
                border: "1px solid red",
                borderRadius: "50%",
                width: 100,
                height: 100,
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-center mt-5">
        <div className="super-image">
          <img
            src={"/assets/man.png"}
            alt="supervisor1"
            style={{
              border: "1px solid red",
              borderRadius: "50%",
              width: 100,
              height: 100,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SupervisorChart;
