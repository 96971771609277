import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { uploadSignatureEmployee } from "../../../redux/users/userActions";
import { Button } from "antd";
import { FaPlusCircle } from "react-icons/fa";
import { FaRegEdit, FaSignature } from "react-icons/fa";
import styles from "../../../styles/employee/Profile.module.css";
import DashboardCard from "../../dashboard/DashboardCard";
import NoContent from "../../ui/NoContent";
import { useGetSystemSettings, useGetUserProfile } from "../../../util/usershookActions";
import { useUserCleanUp } from "../../../util/hooks";

function ManageSignature({ signature, first_name, last_name }) {
  useUserCleanUp();
  const [file, setFile] = useState(null);
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [enabled_settings, setEnabledSettings] = useState(true);
  const [enabled, setEnabled] = useState(true);

  const dispatch = useDispatch();

  useGetSystemSettings(enabled_settings, setEnabledSettings);

  const { refetch } = useGetUserProfile(enabled, setEnabled);

  const fileUploadRef = useRef();

  const handleFileChange = (e) => {
    e.preventDefault();
    const { files } = e.target;

    setFile(files[0]);
  };

  useEffect(() => {
    const userSignature = document.getElementById("user-signature");

    if (file && userSignature) {
      userSignature.src = URL.createObjectURL(file);
    }
  }, [editMode, file, signature]);
  // console.log("sige", signature);

  function handleUploadSignature() {
    setSaveSpinner(true);
    const creds = {
      channel: "Cloudinary",
      file,
    };

    uploadSignatureEmployee(dispatch, creds).then((res) => {
      setSaveSpinner(false);
      setEditMode(!editMode);
      refetch();
    });
  }

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleCancel = () => {
    setFile("");
    setEditMode(false);
  };

  return !editMode ? (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Others</h5>
        <FaRegEdit
          className="h5"
          title="Edit Signature"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-6">
            <label>Signature</label>
            <div className="signature">
              {!signature ? (
                <>
                  <NoContent
                    illustration={"/assets/20943752.jpg"}
                    message={"No Signature Uploaded"}
                  />
                  <h3>
                    {first_name} {last_name}
                  </h3>
                </>
              ) : (
                <img src={signature} alt="user signature" />
              )}
            </div>
          </div>
          {/* {Array.isArray(all_settings?.other_settings?.display?.value) &&
          all_settings?.other_settings?.display?.value.includes(
            "biometric_capture"
          ) ? (
            // <DailyLoginChart />
            <div className="col-md-6">
              <label>Biometrics</label>
              <div className="fingerprint mt-4">
                <img
                  src="/assets/biometric-technology.jpg"
                  alt=""
                  width={200}
                />
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard title={"Signature"} icon={<FaSignature />}>
      <div className={styles.sign}>
        <div className={styles.signature}>
          <img
            src={signature || "/assets/JK-Rowling-signature.png"}
            alt="user-signature"
            id="user-signature"
          />
          <label className={styles.cloudBtn}>
            <FaPlusCircle id="add" />
            <input
              type="file"
              name="signature-picture"
              ref={fileUploadRef}
              className={styles.upload}
              onChange={handleFileChange}
              accept="image/png, image/jpeg, image/jpg"
            />
          </label>
        </div>

        <div className="mt-3 d-flex justify-content-end gap-3">
          <Button className="myBtn-danger" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="myBtn "
            onClick={handleUploadSignature}
            loading={save_spinner}
          >
            Upload
          </Button>
        </div>
      </div>
    </DashboardCard>
  );
}

export default ManageSignature;
