import React, { useState, useRef } from "react";
import { Button, Modal } from "antd";
import { FaFileAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { reportRequestAction } from "../../../redux/users/userActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";
import "../../../styles/newLeave.css";
import { useOutletContext } from "react-router-dom";

function ReplacementRequest() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [local_spinner, setLocalSpinner] = useState(false);
  const fileUploadRef = useRef();
  const [date_of_loss, setDateOfLoss] = useState("");
  const [reason_for_request, setReasonForRequest] = useState("");
  const [request_replacement, setRequestReplacement] = useState("yes");

  const [profile] = useOutletContext();

  const back_errors = useShallowEqualSelector(user_errors);

  function confirmRequest(e) {
    e.preventDefault();

    Modal.confirm({
      title: `${
        request_replacement === "yes" ? "Replacement" : "Report"
      } Request`,
      content: "Do you want to perform this action?",
      onOk: requestReportReplace,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function handleCheck(e) {
    setRequestReplacement(e.target.value);
  }

  function requestReportReplace() {
    const creds = {
      user_id: profile.id,
      date_of_loss,
      reason_for_request,
      // comment,
      request_replacement,
      has_police_report: file ? "yes" : "no",
      police_report: file,
      reported_by: profile.id,
    };
    setLocalSpinner(true);
    reportRequestAction(dispatch, creds).then((res) => {
      setLocalSpinner(false);
      if (res?.status === "success") {
        setFile(null);
        // setComment("");
        setReasonForRequest("");
        setRequestReplacement("yes");
        setDateOfLoss("");
      }
    });
  }

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const { files } = e.dataTransfer;
    setFile(files[0]);
  };

  const handleChange = (e) => {
    e.preventDefault();

    const { files } = e.target;

    if (files[0]) {
      setFile(files[0]);
    }
  };

  const buttonHandler = () => {
    fileUploadRef.current.click();
    fileUploadRef.current.value = "";
  };

  function handleClear(e) {
    e.preventDefault();
    e.stopPropagation();
    setFile(null);
  }

  return (
    <main className="leaveMain">
      <section className="body mx-auto">
        <div className="leaveContainer mx-auto shadow pt-4">
          <h2 className="newLeaveHead mb-5">Report & Replacement Request</h2>
          <section className="input-forms">
            <form
              action=""
              className="performance-form leaveForm"
              onSubmit={confirmRequest}
            >
              <div className="Bemployee mb-4">
                <label htmlFor="bEmploy" id="label__leave">
                  Employee name
                </label>
                <input
                  name="bEmploy"
                  type="text"
                  className="input_leave"
                  value={profile?.personal?.fullname}
                  disabled
                />
              </div>
              <div className="leaveImage mb-4">
                <span className="leaveImageText">
                  {profile?.personal?.department?.name ||
                    "Employee's department"}
                </span>
                <span className="avatar">
                  <img src={profile?.personal?.avatar} />
                </span>
              </div>
              <div className="mb-4">
                <label htmlFor="eId" className="label__leave" id="label__leave">
                  Employee ID
                </label>
                <input
                  name="eId"
                  type="text"
                  className="input_leave"
                  value={profile?.personal?.employee_id}
                  disabled
                />
              </div>
              <div className="mb-4">
                <div className="d-flex justify-content-between flex-wrap">
                  <label htmlFor="date_of_loss" className="" id="label__leave">
                    Date Of misplacement
                  </label>
                  <small className="text-danger error">
                    {back_errors.date_of_loss && back_errors.date_of_loss[0]}
                  </small>
                </div>
                <input
                  name="date_of_loss"
                  type="date"
                  value={date_of_loss}
                  className="input_leave"
                  onChange={(e) => {
                    setDateOfLoss(e.target.value);
                  }}
                />
              </div>

              {/* <div className="mb-4">
                <div className="d-flex justify-content-between flex-wrap">
                  <label
                    htmlFor="date_of_loss"
                    className="mb-2"
                    id="label__leave"
                  >
                    Request Replacement
                  </label>

                  <small className="text-danger">
                    {back_errors.request_replacement &&
                      back_errors.request_replacement[0]}
                  </small>
                </div>
                <div className="d-flex gap-5 ">
                  <div className="d-flex  gap-5 align-items-center">
                    <label htmlFor="yes">Yes</label>{" "}
                    <input
                      type="radio"
                      value={"yes"}
                      className="mt-0"
                      id="yes"
                      name="request_replacement"
                      checked={request_replacement === "yes"}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>
                  <div className="d-flex gap-5 align-items-center ">
                    <label htmlFor="no">No</label>{" "}
                    <input
                      type="radio"
                      value={"no"}
                      className="mt-0"
                      id="no"
                      name="request_replacement"
                      checked={request_replacement === "no"}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>
                </div>
              </div> */}
              <div className="mb-4">
                <div className="d-flex justify-content-between flex-wrap">
                  <label
                    htmlFor="reason_for_request"
                    className=""
                    id="label__leave"
                  >
                    Reason for reporting{" "}
                    {/* {request_replacement === "yes" ? "Replacement" : " Report"}{" "} */}
                    Request
                  </label>

                  <small className="text-danger error">
                    {back_errors.request_replacement &&
                      back_errors.request_replacement[0]}
                  </small>
                </div>
                <select
                  name="reason_for_request"
                  value={reason_for_request}
                  onChange={(e) => setReasonForRequest(e.target.value)}
                >
                  <option value="">Select Reason</option>
                  <option value="stolen">Stolen</option>
                  <option value="damaged">Damaged</option>
                  <option value="promotion">Promotion</option>
                </select>
              </div>

              <div
                className="leaveAttachFile mb-4"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onClick={buttonHandler}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <h4>Attachment</h4>

                  <small className="text-danger error">
                    {back_errors.police_report && back_errors.police_report[0]}
                  </small>
                </div>

                <p>
                  You can import evidences or files that would help your request
                  better
                </p>
                <div className="leaveInputFile">
                  <img src="/assets/uploadToCloud.png" alt="upload" />
                  <p className="leaveInputeFileText">upload file</p>
                  <input
                    type="file"
                    className="leaveFileUpload"
                    id="input-file-upload"
                    ref={fileUploadRef}
                    onChange={handleChange}
                    hidden
                  />
                  <p className="w-100 text-start">
                    {" "}
                    {file && (
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <FaFileAlt
                            // className="h1"
                            style={{ color: "var(--clr-primary)" }}
                          />
                          <p>{file.name}</p>
                        </div>

                        <small
                          className="pointer text-danger"
                          onClick={(e) => handleClear(e)}
                        >
                          cancel
                        </small>
                      </div>
                    )}
                  </p>
                </div>
              </div>

              <div className="mb-4">
                <div className="d-flex justify-content-between flex-wrap">
                  <label
                    htmlFor="date_of_loss"
                    className="mb-2"
                    id="label__leave"
                  >
                    Request re-issuance
                  </label>

                  <small className="text-danger error">
                    {back_errors.request_replacement &&
                      back_errors.request_replacement[0]}
                  </small>
                </div>
                <div className="d-flex gap-5 ">
                  <div className="d-flex  gap-5 align-items-center">
                    <label htmlFor="yes">Yes</label>{" "}
                    <input
                      type="radio"
                      value={"yes"}
                      className="mt-0"
                      id="yes"
                      name="request_replacement"
                      checked={request_replacement === "yes"}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>
                  <div className="d-flex gap-5 align-items-center ">
                    <label htmlFor="no">No</label>{" "}
                    <input
                      type="radio"
                      value={"no"}
                      className="mt-0"
                      id="no"
                      name="request_replacement"
                      checked={request_replacement === "no"}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="leaveDescription mb-4">
                <label htmlFor="description" id="label__leave">
                  Comment
                </label>
                <p className="descriptionText">
                  {" "}
                  You can provide information to help your request better
                </p>
                <textarea
                  rows="4"
                  placeholder="I lost my ID card"
                  className="input_leave"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div> */}

              <div className="d-flex justify-content-end mb-5">
                <Button
                  className="myBtn mb-4"
                  htmlType="submit"
                  loading={local_spinner}
                >
                  Submit
                </Button>
              </div>
            </form>
          </section>
        </div>
      </section>
    </main>
  );
}

export default ReplacementRequest;
