import { useLocation } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";
import { Outlet } from "react-router-dom";

function PayGrades() {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Compesation & Benefits"
          module_class="performance-header"
          module_name={
            childPath === "create-step"
              ? "System Step List"
              : childPath === "list-pay-grade"
              ? "List Pay Grades"
              : childPath === "pay-grades-with-notches"
              ? "Pay Grades With Bands/Notches"
              : childPath === "pay-grades-without-notches"
              ? "Pay Grades Without Bands/Notches"
              : "System Grade List"
          }
          module_description="Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
        />
      </div>

      <main
        className="main__wrapper"
      >
        <div className="container-fluid py-3 ">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default PayGrades;
