import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { useGetAllEmployeesList } from "../../util/usershookActions";
import { useDebounce } from "../../util/hooks";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";
import "../../styles/allEmployeeGrid.css";
import { FaUserClock, FaUsers } from "react-icons/fa";

function FullListEmpGrid() {
  const [enabled, setEnabled] = useState(true);
  const [users, setUsers] = useState([]);
  const [text, setText] = useState("");
  const { data: all_users, isLoading } = useGetAllEmployeesList(
    enabled,
    setEnabled
  );

  useDebounce(() => filterUsers(text), 1000, [text]);
  function filterUsers(text) {
    const filtered =
      users &&
      users.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(text.toLowerCase()) ||
          item.last_name.toLowerCase().includes(text.toLowerCase())
        );
      });

    if (text === "") {
      setUsers(all_users?.payload?.all_employees?.data);
    } else if (filtered.length) {
      setUsers(filtered);
    } else {
      setUsers([]);
    }
  }

  useEffect(() => {
    if (
      all_users?.payload?.all_employees &&
      Object.keys(all_users?.payload?.all_employees).length
    ) {
      setUsers(all_users?.payload?.all_employees?.data);
    }
  }, [all_users?.payload?.all_employees]);

  // console.log({ users });

  return (
    <main className="allEmployee_main">
      <nav className="title__area">
        <div className="title__area_left">
          <h4 className="mb-0">Employee Directory</h4>
        </div>

        <ul className="title__area_right">
          <li className="pointer">
            <NavLink to={"/dashboard/probation-list"} className="okk">
              <FaUserClock className="svg_icon" /> Probation
            </NavLink>
          </li>
          {/* <li className="pointer">
            <NavLink to="/dashboard/disciplinary-list" className="okk">
              <FaUserAltSlash className="svg_icon" /> Disciplinary
            </NavLink>
          </li> */}
          <li className="pointer">
            <NavLink to={"/dashboard/dashboard-full-list"} className="okk">
              <FaUsers className="svg_icon" /> All
            </NavLink>
          </li>
          <li>
            <div className="input__box">
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </li>
        </ul>
      </nav>

      {isLoading ? (
        <SkeletonUI number={2} />
      ) : users.length ? (
        <div className="card__grid_fullemploy">
          {users?.map((item) => (
            <div className="emp__card_fullemploy card" key={item.id}>
              <div className="emp__img_fullemploy">
                <img src={item.avatar} alt="" height={123} />
              </div>

              <div className="emp__info__area_fullemploy px-2">
                <span className="emp__name card-title">
                  {item.first_name + " " + item.last_name}
                </span>
                <div className="emp_desc">
                  <span className="emp__job_role_desc card-text">
                    <span className="emp__job_role">
                      {item.job_role && Object.keys(item?.job_role).length
                        ? item?.job_role.job_role?.role || "N/A"
                        : "N/A"}
                    </span>
                    <span className="emp__category">
                      {" "}
                      {item.job_role && Object.keys(item?.job_role).length
                        ? item?.job_role.job_role?.category || "N/A"
                        : "N/A"}
                    </span>
                  </span>
                  <span className="chats">
                    <img src="/assets/Messaging.png" alt="message" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoContent
          message={"No  user list yet"}
          width={"200px"}
          illustration={"/assets/searching.png"}
        />
      )}
      {/* <div className="allEmployee_foot">
        <h3>Employee Directory</h3>
        <section className="allEmployee_head_filter">
          <div className="allEmployee_select">
            <select name="allEmployee" id="allEmployee">
              <option value="Department filter">All department</option>
              <option value="Engineering">Engineering</option>
              <option value="Accounting">Accounting</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </section>
      </div> */}
    </main>
  );
}

export default FullListEmpGrid;
