import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useGetPerformanceSetup } from "../../../util/usershookActions";

function AppraisalsDropdown() {
  const [enabled_set, setEnabledSet] = useState(true);
  useGetPerformanceSetup(enabled_set, setEnabledSet);

  const location = useLocation();

  const isBeginAppraisalChild = () => {
    return location.pathname.startsWith('/modules/performance/appraisals/begin-appraisal/');
  };

  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"appraisals"}
          className={({ isActive }) => (isActive || isBeginAppraisalChild() ? "active" : "")}
          end
        >
          <span className="sidebar_text">Begin Appraisal</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/manage-team-appraisal"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Manage Team Appraisal</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/next-level-appraisal"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Next Level Appraisal</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/restart-appraisal"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Restart Appraisal</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/track-appraisal"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Track Appraisal</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/appraisal-settings"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Appraisal Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"appraisals/create-appraisal"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Create Appraisal</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(AppraisalsDropdown);
