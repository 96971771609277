import React, { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
// import { FaGraduationCap } from "react-icons/fa";
import { rejectUserInfo } from "../../../redux/users/userActions";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { GoPrimitiveDot } from "react-icons/go";
import { useUserCleanUp } from "../../../util/hooks";
import { MdOutlineClose } from "react-icons/md";

function EducationCard({ info, setEnabled }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const confirm = () => {
    setLoading(true);
    setShowModal(false);
    rejectUserInfo(dispatch, {
      ...info,
      info_type: "educational_history",
    }).then((res) => {
      setLoading(false);
      setShowModal(false);

      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  };

  const warning = () => {
    Modal.confirm({
      title: "Remove Eduation History",
      content: "Are you sure you want to remove this information?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: modalHandler,
    });
  };

  return (
    <div className="col-md-4">
      <div className="card bg-white">
        <div className="card-body position-relative">
          <div className={styles.cardDetails}>
            <div className="d-flex align-items-center gap-2">
              <h5>{info.institution_name}</h5>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <p className="d-flex align-items-center">
                  {info.institution_category}
                </p>
                <p>
                  {info.degree_obtained} {info.course}
                </p>
              </div>
              <div>
                <p className="d-flex align-items-center gap-2">
                  <GoPrimitiveDot className="text-success" />
                  {info.start_date}
                </p>
                <p className="d-flex align-items-center gap-2">
                  <GoPrimitiveDot className="text-danger" />
                  {info.end_date}
                </p>
              </div>
            </div>
          </div>
          <button className="myBtn-close" loading={loading} onClick={warning}>
            <MdOutlineClose />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EducationCard;
