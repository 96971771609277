import { Input, Button } from "antd";
import Logo from "../Logo";
import { setupValidator } from "../../util/formValidations";
import {
  useForm,
  useUserCleanUp,
  useUsersNotification,
  useShallowEqualSelector,
} from "../../util/hooks";

import Sidebar from "../ui/Sidebar";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { setupClientLogin } from "./../../redux/users/userActions";

import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  company_details,
} from "../../util/selectors/userSelectors";

const Setup = () => {
  useUserCleanUp();
  useUsersNotification();
  const params = queryString.parse(useLocation().search);
  const dispatch = useDispatch();
  const initResetUser = {
    new_password: "",
    confirm_password: "",
  };

  const spinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const companyDetails = useShallowEqualSelector(company_details);
  const callbackFn = () => {
    const creds = { ...values, EmpID: params.EmpID };
    setupClientLogin(dispatch, creds);
  };

  const { errors, values, handleChange, handleSubmit } = useForm(
    callbackFn,
    initResetUser,
    setupValidator
  );

  return (
    <div className="row bgLogin">
      <Sidebar />
      <div className="col-12 col-md-7 p-0">
        <div className="d-flex align-items-center px-0 auth">
          <div className="row w-100 mx-0">
            <div
              className="col col-lg-7 mx-auto d-flex flex-column align-items-center p-4"
              style={{ maxWidth: 500 }}
            >
              <h4
                className="d-md-none"
                style={{ fontSize: "27px", fontWeight: "300" }}
              >
                {companyDetails?.business.company_name}
              </h4>
              <div className="text-left py-4 py-lg-5 mt-4 px-3 px-lg-4 mx-lg-3 w-100 form-border bg-white">
                <div>
                  <h4 className="text-left"> Setup password</h4>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center mb-2 w-100">
                        <label
                          htmlFor="Password"
                          className="d-block w-100 mb-0"
                        >
                          Password
                        </label>
                        <div className="w-100 text-right text-danger">
                          <small>
                            {errors.new_password ||
                              (back_errors.new_password &&
                                back_errors.new_password[0])}
                          </small>
                        </div>
                      </div>
                      <Input.Password
                        allowClear
                        type="password"
                        placeholder="Password"
                        size="large"
                        onChange={handleChange}
                        value={values.new_password}
                        name="new_password"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center mb-2 w-100">
                        <label
                          htmlFor="confirm password"
                          className="d-block w-100 mb-0"
                        >
                          Confirm Password
                        </label>
                        <div className="w-100 text-right text-danger">
                          <small>
                            {errors.confirm_password ||
                              (back_errors.confirm_password &&
                                back_errors.confirm_password[0])}
                          </small>
                        </div>
                      </div>
                      <Input.Password
                        allowClear
                        type="password"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        size="large"
                        onChange={handleChange}
                        value={values.confirm_password}
                      />
                    </div>
                    <div className="mt-3">
                      <Button
                        htmlType="submit"
                        size="large"
                        block
                        className="myBtn "
                        loading={spinner}
                      >
                        SET PASSWORD
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-md-none">
                <Logo size={20} />
              </div>
              <small className="mt-3">Privacy | Terms & conditions</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;
