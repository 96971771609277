import React, { useState } from "react";
import "../../styles/hrms/Layout.css";
import IdleContainer from "../IdleContainer";
import AllEventCalendar from "../dashboard/AllEventCalendar";
import { GoCalendar } from "react-icons/go";
import styles from "../../styles/Dashboard.module.css";
import { Button, Drawer, Space } from "antd";

function AdminDashboardLayout({ sidebar, children }) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="admin_layout">
      <span onClick={showDrawer} className={styles.twack}>
        <GoCalendar />
      </span>

      <Drawer
        onClose={onClose}
        open={open}
        width={"95%"}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        keyboard="true"
      >
        <AllEventCalendar />
      </Drawer>

      <IdleContainer />
      {sidebar}
      {/* <p>hello</p> */}
      <main className="admin_layout_content">{children}</main>
    </div>
  );
}

export default AdminDashboardLayout;
