import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

const PerformanceAppraisals = () => {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Performance Management"
          module_class="performance-header"
          module_name={
            childPath === "manage-team-appraisal"
              ? "Manage Team Appraisal"
              : childPath === "appraisal-settings"
              ? "Appraisal Settings"
              : childPath === "begin-appraisal"
              ? "Begin Appraisal"
              : childPath === "restart-appraisal"
              ? "Restart Appraisal"
              : childPath === "track-appraisal"
              ? "Track Appraisal"
              : "Create Appraisal"
          }
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/landing-page/Payroll managementpng.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default PerformanceAppraisals;
