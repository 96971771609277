import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Drawer, Space } from "antd";

import ReadOnlyTemplateRenderer from "../make-request/request-history/ReadOnlyTemplateRenderer";

import { useGetRequestAnswersForHistory } from "../../../util/usershookActions";

import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";

import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import ReadOnlyExpenseReportTemplate from "./../predefined-template/expense-report-template/ReadOnlyExpenseReportTemplate";

const AllRequestsTemplateHistoryTable = ({ allEmployeesRequestHistory }) => {
  const [tableData, setTableData] = useState([]);
  const [csvTableData, setCsvTableData] = useState([]);

  useEffect(() => {
    if (allEmployeesRequestHistory) {
      const new_array = allEmployeesRequestHistory?.payload?.submissions?.map(
        (request, index) => {
          return {
            key: request.submission,
            employee: request.employee,
            department: request.department,
            date_of_request: request.date_of_request,
            approvals: request.approvals,
            request,
          };
        }
      );
      setTableData(new_array);
    }
  }, [allEmployeesRequestHistory]);

  // Function to get ordinal suffix
  const getOrdinalSuffix = (i) => {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  let columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      title: "Date of Request",
      dataIndex: "date_of_request",
      align: "center",
    },
  ];

  let maxLevels = 0;
  allEmployeesRequestHistory?.payload?.submissions?.forEach((submission) => {
    const levels = Object.keys(submission.approvals).length;
    if (levels > maxLevels) {
      maxLevels = levels;
    }
  });

  for (let i = 1; i <= maxLevels; i++) {
    columns.push(
      {
        title: `${getOrdinalSuffix(i)} Approver`,
        dataIndex: [`approvals`, `level${i}`, "status"],
        align: "center",
        render: (text) => text?.toUpperCase(),
      },
      {
        title: `Date`,
        dataIndex: [`approvals`, `level${i}`, "date"],
        align: "center",
        render: (text) => text || "N/A",
      }
    );
  }

  // Add action column
  columns.push({
    title: "Action",
    key: "action",
    render: (text, record) => (
      <EachAction
        selectedRequest={record.request}
        getOrdinalSuffix={getOrdinalSuffix}
        templateName={allEmployeesRequestHistory?.payload?.template_name}
        submissionID={record.key}
      />
    ),
  });

  useEffect(() => {
    if (tableData) {
      const new_array = tableData.map((request) => {
        const approvalData = {};
        for (const [level, approval] of Object.entries(request.approvals)) {
          approvalData[`level${level.slice(-1)} status`] = approval.status;
          approvalData[`level${level.slice(-1)} date`] = approval.date || "N/A";
        }
        return {
          key: request.key,
          employee: request.employee,
          department: request.department,
          date_of_request: request.date_of_request,
          ...approvalData,
        };
      });
      setCsvTableData(new_array);
    }
  }, [tableData]);

  return (
    <div className="d-flex flex-column gap-3">
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 1200 }}
        bordered
        pagination={false}
      />

      <CSVLink
        data={csvTableData}
        filename={`${allEmployeesRequestHistory?.payload?.template_name}history.csv`}
        target="_blank"
      >
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          style={{
            backgroundColor: "var(--clr-primary)",
            width: "fit-content",
          }}
        >
          Export
        </Button>
      </CSVLink>
    </div>
  );
};

function EachAction({
  selectedRequest,
  getOrdinalSuffix,
  templateName,
  submissionID,
}) {
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, refetch, isLoading } = useGetRequestAnswersForHistory(
    enabled,
    setEnabled,
    submissionID
  );

  useEffect(() => {
    if (submissionID) {
      setEnabled(true);
    }
  }, [submissionID]);

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: templateName,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const expenseReportAnswers =
    data?.payload?.template_type === "expense-report"
      ? data?.payload?.answers?.[0]?.answer
      : null;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function getStatus(approvals) {
    let statusCount = {
      Approved: 0,
      Rejected: 0,
      Pending: 0,
    };

    approvals.forEach((approval) => {
      statusCount[approval.status]++;
    });

    if (statusCount["Rejected"] > 0) {
      return "REJECTED";
    } else if (statusCount["Approved"] === approvals.length) {
      return "APPROVED";
    } else {
      return "PENDING";
    }
  }

  return (
    <>
      <div
        className=""
        onClick={showDrawer}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        View
      </div>
      <Drawer
        title={templateName}
        width={"60%"}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        {isLoading ? (
          <SkeletonUI number={5} />
        ) : data?.payload ? (
          <div ref={contentToPrint}>
            <div className="make__request__form">
              <div className="make__request__form__title">
                <h4 className="h4">{templateName}</h4>
              </div>

              {data?.payload?.template_type === null ? (
                data?.payload?.answers?.map((answer, index) => (
                  <ReadOnlyTemplateRenderer question={answer} key={index} />
                ))
              ) : data?.payload?.template_type === "expense-report" ? (
                <div
                  style={{
                    maxWidth: "80rem",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <ReadOnlyExpenseReportTemplate
                    expenseReportAnswers={expenseReportAnswers}
                    templatePreview={false}
                  />
                </div>
              ) : null}
            </div>
            <div
              className="d-flex flex-column gap-2"
              style={{
                borderTop: "1px solid #d9d9d9",
                paddingTop: "1.5rem",
                marginTop: "2rem",
              }}
            >
              <div className="d-flex flex-column gap-2 mt-3 px-3">
                <h5 className="mb-3">
                  STATUS: {getStatus(data?.payload?.approvals)}
                </h5>
                <div className="create__request__form__grid">
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Employee Name:</label>
                    <p className="fs-6">{data?.payload?.employee || "N/A"}</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Department:</label>
                    <p className="fs-6">{data?.payload?.department || "N/A"}</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <label className="fs-6">Date of Request:</label>
                    <p className="fs-6">
                      {data?.payload?.date_of_request || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="create__request__form__grid">
                  {data?.payload?.approvals?.map((level, index) => (
                    <div className="d-flex flex-column gap-2" key={index}>
                      <span className="fs-6">
                        {`${getOrdinalSuffix(level?.level)} Approver`} (
                        {level?.status?.toUpperCase()})
                      </span>
                      <span className="fs-6">{level?.approver || "N/A"}</span>
                      <div className="d-flex flex-column gap-2">
                        <label className="fs-6">Date Approved/Rejected:</label>
                        <span className="fs-6">
                          {level?.aproved_at || "N/A"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Space>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                >
                  Print
                </Button>
                {/* <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                    width: "fit-content",
                  }}
                >
                  Download
                </Button> */}
              </Space>
            </div>
          </div>
        ) : (
          <NoContent
            width={"400px"}
            message={"No Answers Found"}
            illustration={"/assets/selected.png"}
          />
        )}
      </Drawer>
    </>
  );
}

export default AllRequestsTemplateHistoryTable;
