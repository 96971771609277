import React from "react";
import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import { useUsersNotification } from "../../util/hooks";
import Sidebar from "./Sidebar";

import { Outlet } from "react-router-dom";

export default function HRModule() {
  useUsersNotification();

  return (
    <AdminDashboardLayout sidebar={<Sidebar />}>
      <Outlet />
    </AdminDashboardLayout>
  );
}
