import styles from "../../../../styles/hrms/NewEmployee.module.css";
import { Button } from "antd";
import Biodata from "./Biodata";
import Contact from "./Contact";
import Designation from "./Designation";
import Employment from "./Employment";
import JobGrading from "./JobGrading";

export default function BasicInfo({
  values,
  errors,
  handleChange,
  handleSubmit,
  back_errors,
  auto_gen,
  employment,
  save_spinner,
  onboarding,
  view,
}) {
  console.log("bevalues" ,{ values });

  return (
    <form className={styles.infoForm} onSubmit={handleSubmit}>
      <div className="row">
        <Biodata
          values={values}
          errors={errors}
          handleChange={handleChange}
          back_errors={back_errors}
          onboarding={onboarding}
          view={view}
        />
        <Employment
          auto_gen={auto_gen}
          employment={employment}
          values={values}
          errors={errors}
          handleChange={handleChange}
          back_errors={back_errors}
          onboarding={onboarding}
          view={view}
        />
      </div>
      <div className="row">
        <Designation
          values={values}
          errors={errors}
          handleChange={handleChange}
          back_errors={back_errors}
          onboarding={onboarding}
          view={view}
        />
        <JobGrading
          values={values}
          errors={errors}
          handleChange={handleChange}
          back_errors={back_errors}
          onboarding={onboarding}
          view={view}
        />
      </div>
      <div className="row">
        <Contact
          values={values}
          errors={errors}
          handleChange={handleChange}
          back_errors={back_errors}
          onboarding={onboarding}
          view={view}
        />
      </div>

      {!view ? (
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="myBtn"
            loading={save_spinner}
            htmlType="submit"
            onClick={handleSubmit}
          >
            Update Employee
          </Button>
        </div>
      ) : null}
    </form>
  );
}
