import { useState, useEffect } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import DashboardCard from "../../dashboard/DashboardCard";
import data from "../../../util/nigeria-state-and-lgas.json";
import { FaUserEdit, FaRegEdit } from "react-icons/fa";
import { Button, Select } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { updateUserPersonalInfo } from "../../../redux/users/userActions";
import countries from "../../../util/countries.json";
import { convertToCapitalize } from "../../../util/helpers";
import Warning from "../../ui/Warning";
import { useGetSystemSettingsUtils } from "../../../util/usershookActions";

function Biodata({ personal }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState("");
  const [lgasInState, setLgasInState] = useState("");
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [enabled_uti, setEnabledUtil] = useState(true);
  useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);

  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);

  let initState;

  if (personal && Object.keys(personal).length) {
    initState = { ...personal };
  } else {
    initState = {
      nationality: "",
      state_of_origin: "",
      province_lga: "",
      religion: "",
      gender: "",
      date_of_birth: "",
      title: "",
      first_name: "",
      last_name: "",
      other_names: "",
      marital_status: "",
    };
  }

  const callbackFn = () => {
    setSaveSpinner(true);
    values.user_id = userProfile?.id;

    const dataToSend = {
      nationality: values.nationality,
      state_of_origin: values.state_of_origin,
      province_lga: values.province_lga,
      religion: values.religion,
      gender: values.gender,
      date_of_birth: values.date_of_birth,
      title: values.title,
      first_name: values.first_name,
      last_name: values.last_name,
      other_names: values.other_names,
      marital_status: values.marital_status,
      user_id: values.user_id,
    };

    updateUserPersonalInfo(dispatch, dataToSend).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        clearForm();
        setEditMode(!editMode);
      }
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    initState,
    dependant_details_validator
  );

  useEffect(() => {
    if (values.state_of_origin) {
      setSelectedState(values.state_of_origin);
    }
  }, [values]);

  useEffect(() => {
    if (selectedState) {
      const lgaToFind = data.filter((state) => state.alias === selectedState);
      setLgasInState(lgaToFind[0]?.lgas);
    }
  }, [selectedState]);

  function handleFirstChange(e) {
    setSelectedState(e.target.value);
    handleChange(e);
  }

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Biodata Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Biodata"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Title</label>
            <p>{personal?.title || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Last Name</label>
            <p>{personal?.last_name || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>First Name</label>
            <p>{personal?.first_name || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>Other Names</label>
            <p>{personal?.other_names || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Marital Status</label>
            <p>
              {personal?.marital_status
                ? convertToCapitalize(personal?.marital_status)
                : "N/A"}
            </p>
          </div>
          <div className="col-md-4">
            <label>Nationality</label>
            <p>{personal?.nationality || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>State of Origin</label>
            <p>
              {personal?.state_of_origin
                ? convertToCapitalize(personal?.state_of_origin)
                : "N/A"}
            </p>
          </div>
          <div className="col-md-4">
            <label>Province/LGA</label>
            <p>{personal?.province_lga || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Religion</label>
            <p>
              {personal?.religion
                ? convertToCapitalize(personal?.religion)
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard title={"Edit Biodata Information"} icon={<FaUserEdit />}>
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Personal" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="last_name">Last Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.last_name ||
                  (back_errors.last_name && back_errors.last_name[0])}
              </small>
            </div>
            <input
              type="text"
              name="last_name"
              id="last_name"
              onChange={handleChange}
              value={values?.last_name}
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="first_name">First Name</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.first_name ||
                  (back_errors.first_name && back_errors.first_name[0])}
              </small>
            </div>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              value={values?.first_name}
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="other_names">Other Names</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.other_names ||
                  (back_errors.other_names && back_errors.other_names[0])}
              </small>
            </div>
            <input
              type="text"
              name="other_names"
              id="other_names"
              onChange={handleChange}
              value={values?.other_names}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="title">Title</label>
            <small className="text-danger">
              {errors.title || (back_errors.title && back_errors.title[0])}
            </small>
            <select
              class="form-control form-control-sm "
              id="title"
              name="title"
              value={values?.title}
              onChange={handleChange}
            >
              <option value="">--</option>
              {Object.keys(utils).length && utils?.system_inputs["Title"].length
                ? utils?.system_inputs["Title"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="marital_status">Marital status</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.religion ||
                  (back_errors.religion && back_errors.religion[0])}
              </small>
            </div>
            <select
              name="marital_status"
              id="marital_status"
              onChange={handleChange}
              value={values?.marital_status}
            >
              <option value="">--</option>
              {Object.keys(utils).length &&
              utils?.system_inputs["Marital Status"].length
                ? utils.system_inputs["Marital Status"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="nationality">Nationality</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.nationality ||
                  (back_errors.nationality && back_errors.nationality[0])}
              </small>
            </div>
            <select
              name="nationality"
              id="nationality"
              value={values?.nationality}
              onChange={handleChange}
            >
              {/* {console.log("dedda", values.nationality)} */}
              <option value="">--</option>
              {countries
                ?.sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="state_of_origin">State of Origin</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.state_of_origin ||
                  (back_errors.state_of_origin &&
                    back_errors.state_of_origin[0])}
              </small>
            </div>
            <select
              name="state_of_origin"
              onChange={handleFirstChange}
              value={values.state_of_origin}
              id="state_of_origin"
            >
              {data?.map((state, index) => (
                <option key={index} value={state.alias}>
                  {state.state.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="province_lga">Province/LGA</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.province_lga ||
                  (back_errors.province_lga && back_errors.province_lga[0])}
              </small>
            </div>

            <select
              name="province_lga"
              id="province_lga"
              value={values?.province_lga?.toUpperCase()}
              onChange={handleChange}
            >
              {/* {console.log("dead", lgasInState)} */}
              {lgasInState ? (
                <>
                  <option value="--">--</option>
                  {lgasInState?.map((lga, index) => (
                    <option key={index} value={lga.toUpperCase()}>
                      {lga?.toUpperCase()}
                    </option>
                  ))}
                </>
              ) : (
                <option value="--">--</option>
              )}
            </select>
          </div>

          <div className="col-md-4 d-flex flex-column">
            <label htmlFor="religion">Religion</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.religion ||
                  (back_errors.religion && back_errors.religion[0])}
              </small>
            </div>
            <select
              name="religion"
              id="religion"
              onChange={handleChange}
              value={values?.religion}
            >
              <option value="">--</option>
              {Object.keys(utils).length &&
              utils?.system_inputs["Religion"].length
                ? utils.system_inputs["Religion"].map((item) => {
                    return (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger"
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn" loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}

export default Biodata;
