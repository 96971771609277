import { useState } from "react";
import { List, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { deleteEventType } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";

const EventTypeList = ({ item, handleSelected, refetch }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const confirm = (item) => {
    setLoading(true);
    deleteEventType(dispatch, item).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        // dispatch({ type: "REMOVE_FROM_HOLIDAYS", payload: item.id });
        refetch();
      }
    });
  };
  return (
    <List.Item
      actions={[
        <Link
          key="list-loadmore-more"
          className="text-info res-btn"
          onClick={() => handleSelected(item)}
        >
          Edit
        </Link>,
        <Popconfirm
          title="Delete Entry"
          description="Do you what to delete this entry?"
          onConfirm={() => confirm(item)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="list-loadmore-edit"
            className="text-danger res-btn"
            loading={loading}
            type="text"
          >
            Delete
          </Button>
        </Popconfirm>,
      ]}
    >
      <List.Item.Meta title={item.name} description={item.date} />
    </List.Item>
  );
};

export default EventTypeList;
