import React, { useEffect, useState } from "react";
import HocHeader from "../../ui/HocHeader";
import {
  useGetTeamAnnualLeavePlan,
  useGetTeamAnnualLeavePlanHr,
} from "../../../util/usershookActions";
import {
  Button,
  DatePicker,
  Input,
  Pagination,
  Space,
  Table,
  Modal,
  Drawer,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import NoContent from "../../ui/NoContent";
import dayjs from "dayjs";
import SkeletonUI from "../../ui/Skeleton";
import { approveRejectAction, approveRejectActionPlan } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useUserCleanUp } from "../../../util/hooks";
import LeavePlan from "../leaveplan/LeavePlan";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";

export default function TeamLeavePlanHr() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [enabledemp, setEnabledemp] = useState(true);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [teamLeavehistory, setTeamLeaveHistory] = useState([]);
  const [len_data, setLenData] = useState(0);
  // const [pageTeamHistory, setPageTeamHistory] = useState(1);
  const dispatch = useDispatch();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const {
    data: team_leave_plan_hr,
    isLoading,
    refetch,
  } = useGetTeamAnnualLeavePlanHr(enabled, setEnabled, page, from, to);
  console.log({ team_leave_plan_hr });

  const { data: emp } = useGetAllEmployee(enabledemp, setEnabledemp, "all");

  useEffect(() => {
    console.log({ emp });

    if (emp && Object.keys(emp).length) {
      setUsers(emp.payload.all_employees.data);
    }
  }, [emp]);

  const onStart = (date) => {
    console.log({ date });
    console.log(dayjs(date.$d).format("YYYY-MM-DD"));
    setDates(() => ({
      ...dates,
      from: dayjs(date?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ from });
  };
  const onEnd = (date_end) => {
    console.log({ date_end });
    setDates(() => ({
      ...dates,
      to: dayjs(date_end?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ to, from });
  };
  useEffect(() => {
    if (dates.from && dates.to) {
      setFrom(dates.from);
      setTo(dates.to);
    }
  }, [dates]);

  useEffect(() => {
    if (from && to) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
    console.log({ to, from });
  }, [from, to]);

  useEffect(() => {
    if (team_leave_plan_hr) {
      const new_array = team_leave_plan_hr?.payload?.data.map((leave) => {
        return {
          ...leave,
          key: leave.id,
          name: `${leave.user.first_name} ${leave.user.last_name}`,

          ["start date"]: leave?.start_date,
          ["end date"]: leave?.end_date,
          status: leave.line_manager_approval,
          ["approval date"]: leave.approval_date || "N/A",
          id: leave.id,
          batch: leave.batch,
        };
      });
      setTeamLeaveHistory(new_array);
      setLenData(new_array.length);
      // console.log({ new_array });
    }

    // console.log({leaveHistoryData})
  }, [team_leave_plan_hr]);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${dataIndex}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  const teamLeavePlanHr = [...teamLeavehistory];

  const teamLeavePlanHrColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      ...getColumnSearchProps("duration"),
    },
    {
      title: "Start date",
      dataIndex: "start date",
      key: "start date",
      ...getColumnSearchProps("start date"),
    },
    {
      title: "End date",
      dataIndex: "end date",
      key: "end date",
      ...getColumnSearchProps("end date"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (value) => {
        return value === "PENDING" ? (
          <span className="badge bg-warning p-2">{value}</span>
        ) : (
          <span className="badge bg-success p-2">{value}</span>
        );
      },
    },
    {
      title: "Approval date",
      dataIndex: "approval date",
      key: "approval date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, record) => (
        <EachAction
          record={record}
          ApproveregetRequest={ApproveregetRequest}
          refetch={refetch}
          users={users}
        />
      ),
    },
  ];

  function ApproveregetRequest(creds) {
    // console.log(creds);

    return approveRejectActionPlan(dispatch, creds);
  }

  function handlePaginationTeamHistory(page) {
    setPage(page);
    setEnabled(true);
  }

  function handleTable(
    pagination,
    filters,
    sorter,
    { currentDataSource, action }
  ) {
    if (action === "filter") {
      console.log({ currentDataSource });
      setLenData(currentDataSource?.length);
    }
  }

  return (
    <div className="container-fluid w-100">
      <section className="app__section py-4">
        <div className="d-flex align-items-center padding_2rem w-100">
          <div className="w-100">
            <h5 className="mb-4">Annual leave plan</h5>

            <div
              className="d-flex gap-3 align-items-center my-2 justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center">
                <div className="circle__bagde fw-bold">{len_data}</div>
                <h6 className="mb-0 ms-3"> All annual leave plan</h6>
              </div>
              {teamLeavehistory.length > 0 ? (
                <div className="ms-3">
                  <DatePicker
                    onChange={onStart}
                    className="me-2"
                    allowClear={true}
                  />
                  <DatePicker onChange={onEnd} allowClear={true} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <SkeletonUI />
          ) : teamLeavehistory.length > 0 ? (
            <>
              <Table
                dataSource={teamLeavePlanHr}
                columns={teamLeavePlanHrColumns}
                // pagination={
                //   teamLeavehistory.length > 12 ? { pageSize: 12 } : false
                // }
                onChange={handleTable}
                pagination={false}
              />
              <div className="mt-3 d-flex justify-content-end">
                {team_leave_plan_hr?.payload?.meta?.last_page > 1 ? (
                  <Pagination
                    total={team_leave_plan_hr?.payload?.meta?.last_page}
                    pageSize={1}
                    onChange={handlePaginationTeamHistory}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className="w-100 mx-auto">
              <NoContent
                width={"250px"}
                message={"No annual leave plan found"}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

function EachAction({
  record,
  // NugdeNext,
  ApproveregetRequest,
  refetch,
  users,
}) {
  const [loading_app, setLoadingApp] = useState(false);
  const [loading_reg, setLoadingReg] = useState(false);

  const [showModalApp, setShowModalApp] = useState(false);
  const [showModalReg, setShowModalReg] = useState(false);
  const [showModalPlan, setShowModalPlan] = useState(false);

  const [comment, setComment] = useState("");
  const [comment_reg, setCommentReg] = useState(false);

  console.log({ record });

  const handleModalApp = () => {
    setShowModalApp(!showModalApp);
    setComment("");
  };

  const handleModalReg = () => {
    setShowModalReg(!showModalReg);
    setCommentReg("");
  };
  const handleModalplan = () => {
    setShowModalPlan(!showModalPlan);
  };

  function handleApprove(e) {
    e.preventDefault();
    setLoadingApp(true);
    const creds = {
      id: record.batch,
      comment: comment,
      action: "APPROVED",
      // batch: Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    };
    ApproveregetRequest(creds).then((res) => {
      console.log(res);
      setLoadingApp(false);

      if (res?.status === "success") {
        refetch();
        handleModalApp();
      }
    });
  }
  function handleReject(e) {
    e.preventDefault();
    setLoadingReg(true);
    const creds = {
      id: record.batch,
      comment: comment_reg,
      action: "REJECTED",
      // batch: Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    };
    ApproveregetRequest(creds).then((res) => {
      console.log(res);
      setLoadingReg(false);

      if (res?.status === "success") {
        refetch();
        handleModalReg();
      }
    });
  }

  return (
    <>
      <Space wrap>
        {record.line_manager_approval !== "APPROVED" ? (
          <>
            <Button
              className={`rounded p-1 px-2 border border-1 text-white me-1 bg-success`}
              onClick={handleModalApp}
            >
              Approve
            </Button>
            <Button
              className={`rounded p-1 px-2 border border-1 text-white me-1 bg-danger`}
              onClick={handleModalReg}
            >
              Decline
            </Button>
          </>
        ) : null}

        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-success`}
          onClick={handleModalplan}
        >
          View
        </Button>
      </Space>
      {showModalApp ? (
        <Modal
          open={showModalApp}
          footer={null}
          onCancel={handleModalApp}
          maskClosable={false}
        >
          <h5>Approve Request</h5>
          <hr />

          <form className="performance-form" onSubmit={handleApprove}>
            <label>
              {" "}
              Comment <span className="text-danger"> *</span> :
            </label>
            <textarea
              onChange={(e) => {
                setComment(e.target.value);
              }}
              value={comment}
            />
            <div className="d-flex justify-content-end  mt-3 ">
              <Button
                className="myBtn"
                // disabled={comment.length ? false : true}
                loading={loading_app}
                htmlType="submit"
              >
                {" "}
                Approve{" "}
              </Button>
            </div>
          </form>
        </Modal>
      ) : null}
      {showModalReg ? (
        <Modal
          open={showModalReg}
          footer={null}
          onCancel={handleModalReg}
          maskClosable={false}
        >
          <h5>Decline Request</h5>
          <hr />

          <form className="performance-form" onSubmit={handleReject}>
            <label>
              {" "}
              Comment <span className="text-danger"> *</span> :
            </label>
            <textarea
              onChange={(e) => {
                setCommentReg(e.target.value);
              }}
              value={comment_reg}
            />
            <div className="d-flex justify-content-end  mt-3 ">
              <Button
                className="myBtn"
                disabled={comment_reg.length ? false : true}
                loading={loading_reg}
                htmlType="submit"
              >
                {" "}
                Decline{" "}
              </Button>
            </div>
          </form>
        </Modal>
      ) : null}

      <Drawer
        title="Annual Leave Plan Details"
        onClose={handleModalplan}
        open={showModalPlan}
        destroyOnClose={true}
        width={"90%"}
        extra={
          <Space>
            <Button danger size="large" onClick={handleModalplan}>
              Cancel
            </Button>
          </Space>
        }
      >
        <LeavePlan record={record} users={users} />
      </Drawer>
    </>
  );
}
