import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SAVE_ACTIVITY } from "../../../redux/users/userTypes";
import SupervisorChart from "./SupervisorChart";
import { Select } from "antd";

const EachActivity = ({ activity, users, index, handleApply }) => {
  // console.log({ activity });
  const initAct = { ...activity };
  const [values, setValues] = useState(initAct);
  const [primary_supervisor, setPrimarySup] = useState(
    initAct.primary_supervisor_id
  );
  const [secondary_supervisor, setSecondSup] = useState(
    initAct.secondary_supervisor_id
  );
  // console.log({ values });

  const dispatch = useDispatch();

  const handleSelect = (value, name) => {
    handleChange("_", true, name, value);

    // // optional
    // if (name === "secondary_supervisor_id") {
    //   setSecondSup(value);
    // } else if (name === "primary_supervisor_id") {
    //   setPrimarySup(value);
    // }
  };

  const handleChange = (e, action, inname, invalue) => {
    let name, value;
    if (action) {
      name = inname;
      value = invalue;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setValues((prevValues) => {
      dispatch({
        type: SAVE_ACTIVITY,
        payload: { ...prevValues, [name]: value },
      });

      return { ...prevValues, [name]: value };
    });
  };

  const handleIgnore = () => {
    dispatch({ type: "INGORE_ACT", payload: activity?.id });
  };

  useEffect(() => {
    setPrimarySup(values.primary_supervisor_id);
    setSecondSup(values.secondary_supervisor_id);
  }, [values]);

  return (
    <div className="row">
      <div className="col-md-7 my-3">
        <h4>{activity?.activity}</h4>
        <h6>{activity?.description}</h6>
        <h6>{activity?.duration}</h6>
        <p
          className="text-danger"
          style={{ cursor: "pointer" }}
          onClick={handleIgnore}
        >
          Ignore activity
        </p>
        {index === 0 && (
          <div className="d-flex justify-content-end">
            <p style={{ cursor: "pointer" }} onClick={() => handleApply(index)}>
              Apply to all
            </p>
          </div>
        )}
        <form className="onboarding-form mt-0">
          <div className="row">
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="start_date">Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  id="start_date"
                  onChange={handleChange}
                  value={values.start_date}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="end_date">End Date</label>
                <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  onChange={handleChange}
                  value={values.end_date}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="start_time">Start Time</label>
                <input
                  type="time"
                  placeholder={`${activity} start time`}
                  name="start_time"
                  id="start_time"
                  onChange={handleChange}
                  value={values.start_time}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="end_time">End Time</label>
                <input
                  type="time"
                  placeholder={`${activity} end time`}
                  name="end_time"
                  id="end_time"
                  onChange={handleChange}
                  value={values.end_time}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="primary_supervisor_id">
                  Primary Supervisor
                </label>
                <Select
                  name="primary_supervisor_id"
                  id="primary_supervisor_id"
                  value={values.primary_supervisor_id}
                  onChange={(value) =>
                    handleSelect(value, "primary_supervisor_id")
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  className="w-100"
                >
                  <option value="">--</option>
                  {users &&
                    users?.length &&
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.fullname}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mt-3">
                <label htmlFor="secondary_supervisor_id">
                  Secondary Supervisor
                </label>
                <Select
                  name="secondary_supervisor_id"
                  id="secondary_supervisor_id"
                  value={values.secondary_supervisor_id}
                  onChange={(value) =>
                    handleSelect(value, "secondary_supervisor_id")
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  className="w-100"
                >
                  <option value="">--</option>
                  {users &&
                    users?.length &&
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.fullname}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-5 d-flex align-items-center">
        <SupervisorChart
          primary_supervisor={primary_supervisor}
          secondary_supervisor={secondary_supervisor}
          users={users}
        />
      </div>
    </div>
  );
};

export default EachActivity;
