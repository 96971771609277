import React from "react";

import { NavLink } from "react-router-dom";

function LeaveGroupDown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"leave-group"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text"> Leave Type List</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"leave-group/upload-leave-group"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload Leave Type</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default LeaveGroupDown;
