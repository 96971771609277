import React, { useState } from "react";
import { useShallowEqualSelector, useUserCleanUp } from "../../util/hooks";
import { assignTemple } from "../../redux/users/userActions";
import { useDispatch } from "react-redux";
import AssignToTemplate from "./assign-to-template";
import ModifyActivities from "./assign-to-template/ModifyActivities";
import SelectTemplate from "./assign-to-template/SelectTemplate";
import Preview from "./assign-to-template/Preview";
import { selected_template_activities } from "../../util/selectors/userSelectors";
import { convertToCapitalize } from "../../util/helpers";
import { SAVE_ACTIVITIES } from "../../redux/users/userTypes";

const AssignHire = ({
  template_show,
  users,
  hire_id,
  modalHandler,
  refetchList,
  type,
  stage,
  handlepagination,
  isLoading_tem,
}) => {
  useUserCleanUp();
  const [loading, setLoading] = useState(false);
  const [loadingnot, setLoadingNot] = useState(false);

  const dispatch = useDispatch();
  const activities = useShallowEqualSelector(selected_template_activities);

  const handleSubmit = (e) => {
    const creds = {
      notify: false,
      activities: activities.map((act) => {
        return {
          activity_id: act.id,
          hire_id,
          start_date: act.start_date,
          end_date: act.end_date,
          start_time: act.start_time,
          end_time: act.end_time,
          primary_supervisor_id: act.primary_supervisor_id,
          secondary_supervisor_id: act.secondary_supervisor_id,
        };
      }),
      type,
    };

    setLoading(true);
    assignTemple(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        modalHandler();
        refetchList();
      }
    });
  };

  const handleSubmitNotify = (e) => {
    const creds = {
      notify: true,
      activities: activities.map((act) => {
        return {
          activity_id: act.id,
          hire_id,
          start_date: act.start_date,
          end_date: act.end_date,
          start_time: act.start_time,
          end_time: act.end_time,
          primary_supervisor_id: act.primary_supervisor_id,
          secondary_supervisor_id: act.secondary_supervisor_id,
        };
      }),
      type,
    };

    setLoadingNot(true);
    assignTemple(dispatch, creds).then((res) => {
      setLoadingNot(false);
      if (res?.status === "success") {
        modalHandler();
        refetchList();
      }
    });
  };

  const handleApply = (index) => {
    const new_arry = [...activities];

    // console.log({ new_arry });

    new_arry.forEach((item) => {
      item.primary_supervisor_id = new_arry[index].primary_supervisor_id;
      item.secondary_supervisor_id = new_arry[index].secondary_supervisor_id;
      item.start_date = new_arry[index].start_date;
      item.end_date = new_arry[index].end_date;
      item.start_time = new_arry[index].start_time;
      item.end_time = new_arry[index].end_time;
    });
    dispatch({ type: SAVE_ACTIVITIES, payload: new_arry });
  };

  const steps = [
    {
      title: "Choose Template",
      content: <SelectTemplate template={template_show} />,
    },
    {
      title: "Modify Activities",
      content: (
        <ModifyActivities
          users={users}
          activities={activities}
          handleApply={handleApply}
        />
      ),
    },
    {
      title: "Preview",
      content: <Preview activities={activities} />,
    },
  ];

  return (
    <div>
      <AssignToTemplate
        steps={steps}
        title={`Assign Hire to ${convertToCapitalize(stage)}`}
        submit={handleSubmit}
        submitNotify={handleSubmitNotify}
        loading={loading}
        notifyLoading={loadingnot}
        template_show={template_show}
        isLoading_tem={isLoading_tem}
        handlepagination={handlepagination}
      />
    </div>
  );
};

export default AssignHire;
