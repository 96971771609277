import React from "react";
import { useParams } from "react-router-dom";
import HistoryTable from "./HistoryTable";

function ApprovalHistory() {
  const { id, fname, lname } = useParams();
  return (
    <>
      <h5>
        Approval History for {fname} {lname === "Admin" ? "" : lname}
      </h5>
      <div className="row">
        <div className="col-md-12">
          <HistoryTable emp_id={id} />
        </div>
      </div>
    </>
  );
}

export default ApprovalHistory;
