import styles from "../../styles/ModuleCard.module.css";
import { PlusCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const { REACT_APP_PURCHASES_URL: purchases_url } = process.env;

function DetachedModules({ image, name, url, short_name, description }) {
  return (
    <div className={`${styles.module} bg-light`}>
      <button className={styles.button}>
        <Tooltip
          title={description}
        >
          <InfoCircleFilled style={{ color: "#c0c0c0" }} />
        </Tooltip>
        <a
          href={purchases_url + url + `&short_name=${short_name}`}
          target="_blank"
          rel="noreferrer"
        >
          <PlusCircleFilled />
        </a>
      </button>
      <div className={styles.moduleImage}>
        <img src={image} alt={name} />
      </div>
      <div className={styles.moduleInfo}>
        <h4 className={styles.heading}>{name}</h4>
      </div>
    </div>
  );
}

export default DetachedModules;
