import DashboardCard from "./DashboardCard";
import { GoCalendar } from "react-icons/go";
import styles from "../../styles/Dashboard.module.css";
import { useState } from "react";
import { useGetSystemHolidays } from "../../util/usershookActions";
import NoContent from "../ui/NoContent";
import { useShallowEqualSelector } from "../../util/hooks";
import { holiday } from "../../util/selectors/userSelectors";
import Moment from "react-moment";
import "moment-timezone";

function Holidays() {
  const [enabledhol, setEnabledhol] = useState(true);
  useGetSystemHolidays(enabledhol, setEnabledhol);

  const data = useShallowEqualSelector(holiday);

  return (
    <div className="mb-3">
      <DashboardCard title={"Holidays"} icon={<GoCalendar />}>
        <div className="row">
          {data.length ? (
            <>
              <div className="col-md-12">
                <div className={styles.birthdays}>
                  <ul>
                    {data.map((item) => {
                      return (
                        <li key={item.id} className={styles.bCard}>
                          <div className="d-flex align-items-center gap-3">
                            <div>
                              <h3>{`${item.name}`}</h3>
                              <span className={{ fontSize: 10 }}>
                                <Moment tz="Africa/Lagos" format="MMMM Do ">
                                  {item.date}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <div className="col-md-12">
              <NoContent
                illustration="/assets/3071335.jpg"
                message={"No Holidays"}
                width="150"
              />
            </div>
          )}
        </div>
      </DashboardCard>
    </div>
  );
}

export default Holidays;
