import styles from "../../styles/Pagination.module.css"

function Pagination({ data, currentPage, pageSize, onPageChange }) {
    const pageCount = Math.ceil(data / pageSize);
  
    if (!data || pageCount <= 1) return null;
  
    const pages = Array.from({ length: pageCount }, (_, i) => i + 1);
  
    const prevPage = () => {
      if (currentPage === 1) {
        return;
      } else {
        onPageChange(currentPage - 1);
      }
    };
  
    const nextPage = () => {
      if (currentPage === pages.length) {
        return;
      } else {
        onPageChange(currentPage + 1);
      }
    };
  
    return (
      <div>
        <ul className={styles.pagination}>
          <button
            className={styles.btn}
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage ? styles.pageItemActive : styles.pageItem
              }
            >
              <p className={styles.pageLink} onClick={() => onPageChange(page)}>
                {page}
              </p>
            </li>
          ))}
          <button
            className={styles.btn}
            onClick={nextPage}
            disabled={currentPage === pages.length}
          >
            Next
          </button>
        </ul>
      </div>
    );
  }
  
  export default Pagination;