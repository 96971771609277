import React, { useState, useEffect } from "react";
import FileUpload from "../../ui/FileUpload";
import NoContent from "../../ui/NoContent";
import { useDispatch } from "react-redux";
import { uploadOnboardingSetup } from "../../../redux/users/userActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { cat_to_onboarding } from "../../../util/selectors/userSelectors";
import { useGetUploadsToCategory } from "../../../util/usershookActions";
import { Button } from "antd";
import UploadFile from "./UploadFile";

function UploadPage({ item }) {
  useUserCleanUp();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [cat_type, setCatType] = useState(null);
  const [cat_info, setCatInfo] = useState(item?.name);

  const cat = useShallowEqualSelector(cat_to_onboarding);

  const { refetch } = useGetUploadsToCategory(enabled, setEnabled);

  const handleSubmit = () => {
    if (file) {
      const creds = {
        name: fileName,
        channel: "Cloudinary",
        file,
        category: cat_info,
      };
      setLoading(true);
      uploadOnboardingSetup(dispatch, creds).then((res) => {
        if (res?.status === "success") {
          setFile(null);
          setFileName("");
          dispatch({ type: "DOCUMENT_UPLOAD_MAN", payload: res.payload });
          refetch();
        }
        setLoading(false);
      });
    } else {
      dispatch({ type: "NO_FILE_UPLOAD" });
    }
  };

  useEffect(() => {
    if (Object.keys(cat)?.length) {
      setCatType(cat[item.name]);
    }
  }, [cat_type, cat, item]);

  useEffect(() => {
    if (item) {
      setCatInfo(item.name);
      setCatType(cat[item.name]);
    }
  }, [cat, item]);

  return (
    <>
      <div className="row justify-content-around">
        <div className="col-md-6">
          <div className="d-flex flex-column justify-content-center w-100 h-100">
            <FileUpload
              file={file}
              setFile={setFile}
              supportedFormats={"PDF"}
              accept={"application/pdf"}
              type={"pdf"}
            />
          </div>
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="File name"
            style={{
              display: "block",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "100%",
              padding: "0.2rem 0.5rem",
              outline: "none",
            }}
            className="mb-3"
          />
          <div className="d-flex justify-content-end">
            <Button
              className="myBtn "
              loading={loading}
              onClick={handleSubmit}
              disabled={!fileName}
            >
              Upload
            </Button>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            {cat_type?.length ? (
              cat_type.map((item) => (
                <div className="col-6 mb-3">
                  <UploadFile
                    key={item.id}
                    src={item.value}
                    title={item.name || "Document name"}
                    id={item.id}
                    cat_info={cat_info}
                    refetch={refetch}
                  />
                </div>
              ))
            ) : (
              <NoContent width={400} message={"No Document Uploaded"} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadPage;
