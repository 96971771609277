import { useEffect, useRef, useState } from "react";
import React from "react";
import "../../../styles/newLeave.css";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  holiday,
  user_errors,
  user_profile,
} from "../../../util/selectors/userSelectors";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import { Button, DatePicker, Select } from "antd";
import {
  useGetSystemHolidays,
  useGetUserLeaveGroup,
  useGetUserProfile,
} from "../../../util/usershookActions";
import { addUserLeaveRequest } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import NoContent from "../../ui/NoContent";
import { FaFileAlt } from "react-icons/fa";
import dayjs from "dayjs";

export default function NewLeaveRequest() {
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  useUserCleanUp();

  const [enabled, setEnabled] = useState(true);
  const [enabledPro, setEnabledPro] = useState(true);
  const [enabledhol, setEnabledhol] = useState(true);
  const [enabledLeave, setEnabledLeave] = useState(false);
  const [animeLoadSave, setAnimeLoadSave] = useState(false);
  const [backupSelectImage, setBackupSelectImage] = useState("");
  const [activeText, setActiveText] = useState("");
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
    file: "",
    comment: "",
    backup_employee_id: "",
    leave_group_id: "",
  });
  const [enableLeaveGroup, setEnableLeaveGroup] = useState(true);
  const [userId, setUserId] = useState("");
  const [BackUpDept, setBackUpDept] = useState("");
  const [id, setId] = useState("");
  const [users, setUsers] = useState([]);
  const [hol, setHol] = useState([]);
  const [backUp, setBackUp] = useState({
    name: "",
    value: "",
  });
  const [fileName, setFileName] = useState("");
  useGetUserProfile(enabledPro, setEnabledPro);
  const profile = useShallowEqualSelector(user_profile);
  useGetSystemHolidays(enabledhol, setEnabledhol);
  const holidays = useShallowEqualSelector(holiday);

  useEffect(() => {
    if (profile) {
      setId(profile?.id);
    }
    if (id) {
      setEnabledLeave(true);
      refetch();
    }
  }, [profile, id]);

  useGetAllEmployee(enabled, setEnabled, "all");
  const all_users = useShallowEqualSelector(all_groups);

  const {
    data: userLeaveGroup,
    isLoading,
    refetch,
  } = useGetUserLeaveGroup(enabledLeave, setEnabledLeave, profile.id);
  console.log({ userLeaveGroup });

  const back_errors = useShallowEqualSelector(user_errors);
  console.log({ back_errors });

  useEffect(() => {
    if (all_users) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  useEffect(() => {
    const selectedBackUp = users.filter((user) => user.id === backUp.value);
    setBackupSelectImage(selectedBackUp[0]?.avatar);
    console.log({ selectedBackUp });
    setBackUpDept(selectedBackUp[0]?.department?.name || "");
  }, [backUp]);

  const handleSelect = (value, name) => {
    setBackUp({ name, value });
    setData((prev) => ({
      ...prev,
      backup_employee_id: parseInt(value),
    }));
  };

  const handleDate = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleDateAnt = (date, dateString, name) => {
    // console.log({ date, dateString, name });

    setData((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  };
  const handleFile = (e) => {
    e.preventDefault();
    const { files } = e.target;
    setData((prev) => ({
      ...prev,
      file: files[0],
    }));
    setFileName(files[0].name);
    console.log({ fileName });
  };
  const handleClear = () => {
    setData((prev) => ({
      ...prev,
      file: null,
    }));
    setFileName("");
  };
  const handleComent = (e) => {
    const comment = e.target.value;
    setData((prev) => ({
      ...prev,
      comment,
    }));
  };
  const handleLeaveType = (leavetype) => {
    setActiveText(leavetype?.leave_group?.title);
    setData((prev) => ({
      ...prev,
      leave_group_id: parseInt(leavetype?.leave_group_id),
    }));
  };

  const buttonHandler = () => {
    uploadRef.current.click();
    uploadRef.current.value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAnimeLoadSave(true);
    addUserLeaveRequest(dispatch, {
      ...data,
      batch:
        Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    }).then((res) => {
      setAnimeLoadSave(false);
      if (res?.status === "success") {
        setData({
          start_date: "",
          end_date: "",
          file: "",
          comment: "",
          leave_group_id: "",
          backup_employee_id: "",
        });
        setBackUp({
          name: "",
          value: "",
        });
        setFileName("");
        dispatch({
          type: "ADD_LEAVE_REQUEST",
          payload: res,
        });
      }
    });
  };
  useEffect(() => {
    console.log({ data });
  }, [data]);

  const disabledDate = (current) => {
    // Check if the day is Saturday or Sunday

    return (
      (current && current.day() === 0) ||
      current.day() === 6 ||
      current < dayjs().startOf("day") ||
      hol.includes(current.format("MM/DD"))
    );
  };

  useEffect(() => {
    if (holidays.length) {
      const newholidays = holidays.map((holiday) =>
        dayjs(holiday.date).format("MM/DD")
      );
      setHol(newholidays);
    }
  }, [holidays.length]);

  return (
    <div className="container-fluid">
      <section className="app__section py-4">
        <main className="leaveMain">
          <section className="body mx-auto">
            <div className="">
              <h4 className="h2 mb-5">New Leave Request Form</h4>
              <section className="input-forms">
                <form action="" className="performance-form leaveForm">
                  <div className="d-flex justify-content-between gap-3">
                    <div className="mb-3 w-100">
                      <label htmlFor="eName" className="" id="label__leave">
                        Employee Name
                      </label>
                      <input
                        name="eName"
                        type="text"
                        className="input_leave"
                        value={profile ? profile?.personal?.fullname : ""}
                        readOnly
                      />
                    </div>
                    <div className="mb-3 w-100">
                      <label
                        htmlFor="eId"
                        className="label__leave"
                        id="label__leave"
                      >
                        Employee ID
                      </label>
                      <input
                        name="eId"
                        type="text"
                        className="input_leave"
                        value={profile ? profile?.personal?.employee_id : ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <hr className="line" />
                  <section className="newLeaveCat ">
                    <div className="d-flex justify-content-between flex-md-wrap">
                      <h4>Select a leave category </h4>
                      <small className="text-danger error">
                        {back_errors?.leave_group_id &&
                          back_errors?.leave_group_id[0]}
                      </small>
                    </div>

                    <div className="newLeaveCatWrapper w-100">
                      {userLeaveGroup &&
                        userLeaveGroup?.payload[0]?.map((leavetype) => {
                          return (
                            <article
                              className={`${
                                activeText === leavetype?.leave_group?.title
                                  ? "active__box"
                                  : ""
                              }`}
                              key={leavetype?.leave_group?.id}
                              onClick={() => handleLeaveType(leavetype)}
                            >
                              <p>
                                <span className="newrequest_no">
                                  <span>{leavetype.used}</span>/
                                  {leavetype.available}
                                </span>
                                {leavetype?.leave_group?.title}{" "}
                                <span className="newLeaveCattext">leave</span>
                              </p>
                              <span
                                className={`newLeaveCatBox ${
                                  activeText === leavetype?.leave_group?.title
                                    ? "active_box"
                                    : ""
                                }`}
                              ></span>
                            </article>
                          );
                        })}
                    </div>
                    {!userLeaveGroup && (
                      <div className="w-100 mx-auto d-block">
                        <NoContent
                          width={"150px"}
                          message={"No leave grade distribution found."}
                        />
                      </div>
                    )}
                  </section>
                  <div className="Bemployee mb-3">
                    <div className="d-flex justify-content-between flex-lg-wrap">
                      <label htmlFor="bEmploy" id="label__leave">
                        Back up Employee
                      </label>
                      <small className="text-danger error">
                        {back_errors?.backup_employee_id &&
                          back_errors?.backup_employee_id[0]}
                      </small>
                    </div>
                    <Select
                      name="backup_employee"
                      id="backup_employee"
                      value={backUp.value}
                      className="backup_select"
                      showSearch
                      onChange={(value) =>
                        handleSelect(value, "backup_employee")
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      <option value="">--</option>
                      {users?.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullname}
                        </option>
                      ))}
                    </Select>
                  </div>
                  {backupSelectImage ? (
                    <div className="leaveImage mb-3">
                      <span className="leaveImageText">{BackUpDept}</span>
                      <span className="avatar">
                        <img
                          src={
                            backupSelectImage
                              ? backupSelectImage
                              : "/assets/woman-placeholder.png"
                          }
                          alt="profile"
                        />
                      </span>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-between gap-3 mb-3">
                    <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center flex-wrap ">
                        <label
                          htmlFor="start_date"
                          className="label__leave"
                          id="label__leave"
                        >
                          Start date
                        </label>
                        <small className="text-danger error">
                          {back_errors?.start_date &&
                            back_errors?.start_date[0]}
                        </small>
                      </div>

                      <DatePicker
                        disabledDate={disabledDate}
                        className="input_leave data-my w-100"
                        onChange={(date, dateString) =>
                          handleDateAnt(date, dateString, "start_date")
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div className="w-100">
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                          <label
                            htmlFor="end_date"
                            className=""
                            id="label__leave"
                          >
                            End date
                          </label>
                          <small className="text-danger error">
                            {back_errors?.end_date && back_errors?.end_date[0]}
                          </small>
                        </div>

                        <DatePicker
                          disabledDate={disabledDate}
                          className="input_leave data-my w-100"
                          onChange={(date, dateString) =>
                            handleDateAnt(date, dateString, "end_date")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="leaveAttachFile">
                    <h4>Attachment</h4>
                    <p>
                      You can import evidences or files that would help your
                      request better
                    </p>
                    <small className="text-danger error">
                      {back_errors?.file && back_errors?.file[0]}
                    </small>
                    <div className="leaveInputFile">
                      <img
                        src="/assets/uploadToCloud.png"
                        onClick={buttonHandler}
                        alt="upload"
                      />
                      <p className="leaveInputeFileText">upload file</p>
                      <p className="w-100 text-start">
                        {fileName && (
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <FaFileAlt
                                // className="h1"
                                style={{ color: "var(--clr-primary)" }}
                              />
                              <span>{fileName}</span>
                            </div>
                            <small
                              className="pointer text-danger"
                              onClick={handleClear}
                            >
                              cancel
                            </small>
                          </div>
                        )}
                      </p>
                      <input
                        type="file"
                        className="leaveFileUpload"
                        ref={uploadRef}
                        onChange={(e) => handleFile(e)}
                      />
                    </div>
                  </div>
                  <div className="leaveDescription mb-3">
                    <label htmlFor="description" id="label__leave">
                      Comment
                    </label>
                    <p className="descriptionText">
                      {" "}
                      You can provide information to help your request better
                    </p>
                    <textarea
                      rows="4"
                      placeholder="i am not feeling fine"
                      value={data.comment}
                      className="input_leave"
                      onChange={(e) => handleComent(e)}
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    <Button
                      className="myBtn  mb-3 px-3"
                      htmlType="submit"
                      loading={animeLoadSave}
                      onClick={(e) => handleSubmit(e)}
                    >
                      Request Leave
                    </Button>
                  </div>
                </form>
              </section>
            </div>
          </section>
        </main>
      </section>
    </div>
  );
}
