import React, { useEffect, useMemo } from "react";
import DashboardCard from "./DashboardCard";
import { GoCalendar } from "react-icons/go";
import { Calendar, Badge, Modal } from "antd";
import { useState } from "react";
import {
  useGetSystemAnniversary,
  useGetSystemHolidays,
} from "../../util/usershookActions";
import { useShallowEqualSelector } from "../../util/hooks";
import { anniversaries, holiday } from "../../util/selectors/userSelectors";
import dayjs from "dayjs";
// import Anniversary from "../hrms/settings/anniversaries";

function AllEventCalendar() {
  const [data, setData] = useState([]);
  const [enabledhol, setEnabledhol] = useState(true);
  const [enabledann, setEnabledann] = useState(true);
  const [selectHols, setSelectHols] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [panelMode, setPanelMode] = useState("month")
  useGetSystemHolidays(enabledhol, setEnabledhol);
  useGetSystemAnniversary(enabledann, setEnabledann);

  const holidays = useShallowEqualSelector(holiday);
  const anniversary = useShallowEqualSelector(anniversaries);

  const new_anniversary = useMemo(() => anniversary, [anniversary]);

  const handleSelect = (date, info) => {
    if(panelMode==='month'){
   const selectedDate = data.filter(
      (day) =>
        new Date(date).getMonth() === new Date(day.date).getMonth() &&
        new Date(date).getDate() === new Date(day.date).getDate()
    );
    if (selectedDate) {
      setSelectHols(selectedDate);
      setModalOpen(true);
    } else {
      setSelectHols("");
      setModalOpen(false);
    }
  }else{
    const selectedDate = data.filter(
        (day) =>
          new Date(date).getMonth() === new Date(day.date).getMonth() 
      );
      if (selectedDate) {
        setSelectHols(selectedDate);
        setModalOpen(true);
      } else {
        setSelectHols("");
        setModalOpen(false);
      }
    }
  };
  const handleCancel = () => {
    setSelectHols("");
    setModalOpen(false);
  };

  useEffect(() => {
    if (holidays.length) {
      // alert("here");
      const new_arry = holidays.map((item) => {
        return {
          ...item,
          type: item.event_type || "Holidays",
          badge: item?.code || "error",
        };
      });

      setData([...new_arry]);
    }
  }, [holidays]);

  // useEffect(() => {
  //   if (anniversary.length) {
  //     const new_arry = anniversary.map((item) => {
  //       return { ...item, type: "anniversary", badge: "success" };
  //     });

  //     setData((prev) => [...prev, ...new_arry]);
  //   }
  // }, [anniversary, new_anniversary]);

  // console.log({ data });

  const toShow = (date) => {
    return data.map((item) =>
      new Date(date).getMonth() === new Date(item.date).getMonth() &&
      new Date(date).getDate() === new Date(item.date).getDate() ? (
        <Badge status={item.badge} text={item.name} />
      ) : null
    );
  };

  const toShowMonth=(date)=>{
    return (
      <ul>
     { data.map((item) =>
    new Date(date).getMonth() === new Date(item.date).getMonth()? (

       <li className="eventList mb-0"><Badge status={item.badge} text={item.name} /></li> 
    ) : null
  )}
  </ul>
    )
  }

  return (
    <>
      <DashboardCard title={"Event calendar"} icon={<GoCalendar />}>
        <div className="row">
          <div className="col-md-9">
            <Calendar
              dateCellRender={(date) => toShow(date)}
              monthCellRender={(date) => toShowMonth(date)}
              onSelect={(date, info) => handleSelect(date, info)}
              validRange={[
                dayjs(new Date(new Date().getFullYear(), 0, 1)),
                dayjs(new Date(new Date().getFullYear(), 11, 31)),
              ]}
              onPanelChange={(date, mode) => setPanelMode(mode)}
            />
          </div>
          <div
            className="col-md-3 mb-5"
            style={{
              height: "80vh",
              overflowY: "auto",
            }}
          >
            {/* <h5>Upcoming Events</h5> */}
            <ul className="holiday-list ">
              {data.length
                ? data.map((item) => (
                    <li className="d-flex justify-content-between">
                      <p>
                        {item.name}
                        <br/>
                        <Badge status={item.badge} text={item.type} />
                      </p>
                    </li>
                  ))
                : "No Upcoming Events"}
            </ul>
          </div>
        </div>
      </DashboardCard>
      <Modal
        title="Holidays & Events"
        open={selectHols.length > 0}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="w-50 d-flex flex-column mx-auto">
          {selectHols
            ? selectHols.map((hols) => {
                return (
                  <div className="w-100 d-flex align-items-center">
                    <p className="text-danger">{hols?.date} </p>
                    <p>
                      {" - "}
                      {hols?.name}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </Modal>
    </>
  );
}

export default AllEventCalendar;
