import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import { Button } from "antd";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import {
  updateUserMedicalInfo,
  approveUserMedicalInfo,
} from "../../../redux/users/userActions";
import DashboardCard from "../../dashboard/DashboardCard";
import { FaClinicMedical, FaRegEdit } from "react-icons/fa";
import medicalData from "../../../util/util.json";
import Warning from "../../ui/Warning";

const { genotype, blood_group } = medicalData.medical;

export default function MedicalDetails({ init_det }) {
  useUserCleanUp();
  let init_details;

  const dispatch = useDispatch();

  const utils = useShallowEqualSelector(settings_util);

  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const [save_spinner, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);

  if (userProfile?.medical && Object.keys(userProfile?.medical).length) {
    const { id, ...rest } = userProfile.medical;
    init_details = rest;
  } else {
    init_details = {
      hmo_plan: "",
      hmo_id_number: "",
      hmo_primary_number: "",
      hmo_secondary_number: "",
      primary_hospital: "",
      primary_hospital_address: "",
      genotype: "",
      blood_group: "",
    };
  }

  const callbackFn = () => {
    if (init_det) {
      approveUserMedicalInfo(dispatch, values).then((res) => {
        if (res?.status === "success") {
          // clearForm();
        }
        setEditMode(!editMode);
      });
    } else {
      setSaveSpinner(true);
      values.user_id = userProfile?.id;
      updateUserMedicalInfo(dispatch, values).then((res) => {
        setSaveSpinner(false);
        if (res?.status === "success") {
          clearForm();
        }
        setEditMode(!editMode);
      });
    }
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Medical Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Biodata"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-4">
            <label>Blood Group</label>
            <p>{userProfile?.medical?.blood_group || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>Genotype</label>
            <p>{userProfile?.medical?.genotype || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>HMO Plan</label>
            <p>{userProfile?.medical?.hmo_plan || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>HMO ID Number</label>
            <p>{userProfile?.medical?.hmo_id_number || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>HMO Contact Number 1</label>
            <p>{userProfile?.medical?.hmo_primary_number || "N/A"}</p>
          </div>
          <div className="col-md-4">
            <label>HMO Contact Number 2</label>
            <p>{userProfile?.medical?.hmo_secondary_number || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>Primary Hospital</label>
            <p>{userProfile?.medical?.primary_hospital || "N/A"}</p>
          </div>
          <div className="col-md-8">
            <label>Primary Hospital Address</label>
            <p>{userProfile?.medical?.primary_hospital_address || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard
      title={"Edit Medical Information"}
      icon={<FaClinicMedical />}
    >
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Medicals" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4">
            <label htmlFor="blood_group">Blood Group</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.blood_group ||
                  (back_errors.blood_group && back_errors.blood_group[0])}
              </small>
            </div>
            <select
              name="blood_group"
              onChange={handleChange}
              value={values.blood_group}
              id="blood_group"
            >
              <option value="">--</option>
              {blood_group.map((b) => (
                <option key={b.id} value={b.key}>
                  {b.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4">
            <label htmlFor="genotype">Genotype</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.genotype ||
                  (back_errors.genotype && back_errors.genotype[0])}
              </small>
            </div>
            <select
              name="genotype"
              onChange={handleChange}
              value={values.genotype}
              id="genotype"
            >
              <option value="">--</option>
              {genotype.map((g) => (
                <option key={g.id} value={g.key}>
                  {g.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4">
            <label htmlFor="hmo_plan">HMO Plan</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_plan ||
                  (back_errors.hmo_plan && back_errors.hmo_plan[0])}
              </small>
            </div>
            <input
              type="text"
              name="hmo_plan"
              onChange={handleChange}
              value={values.hmo_plan}
              id="hmo_plan"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="hmo_id_number">HMO ID Number</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_id_number ||
                  (back_errors.hmo_id_number && back_errors.hmo_id_number[0])}
              </small>
            </div>
            <input
              type="text"
              name="hmo_id_number"
              onChange={handleChange}
              value={values.hmo_id_number}
              id="hmo_id_number"
            />
          </div>
          <div className="col-4">
            <label htmlFor="hmo_primary_number">HMO Contact Number 1</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_primary_number ||
                  (back_errors.hmo_primary_number &&
                    back_errors.hmo_primary_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="hmo_primary_number"
              onChange={handleChange}
              value={values.hmo_primary_number}
              id="hmo_primary_number"
            />
          </div>
          <div className="col-4">
            <label htmlFor="hmo_secondary_number">HMO Contact Number 2</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.hmo_secondary_number ||
                  (back_errors.hmo_secondary_number &&
                    back_errors.hmo_secondary_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="hmo_secondary_number"
              onChange={handleChange}
              value={values.hmo_secondary_number}
              id="hmo_secondary_number"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <label htmlFor="primary_hospital">Primary Hospital</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.primary_hospital ||
                  (back_errors.primary_hospital &&
                    back_errors.primary_hospital[0])}
              </small>
            </div>
            <input
              type="text"
              name="primary_hospital"
              onChange={handleChange}
              value={values.primary_hospital}
              id="primary_hospital"
            />
          </div>
          <div className="col-8">
            <label htmlFor="primary_hospital_address">Hospital Address</label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.primary_hospital_address ||
                  (back_errors.primary_hospital_address &&
                    back_errors.primary_hospital_address[0])}
              </small>
            </div>
            <textarea
              name="primary_hospital_address"
              onChange={handleChange}
              id="primary_hospital_address"
              value={values.primary_hospital_address}
            ></textarea>
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger "
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn " loading={save_spinner} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}
