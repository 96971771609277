import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import HocHeader from "../../ui/HocHeader";

const Planning = () => {
  const location = useLocation();

  const childPath = location.pathname.split("/")[4];

  return (
    <>
      <div>
        <HocHeader
          module_title="Performance Management"
          module_class="performance-header"
          module_name={
            childPath === "objectives"
              ? "Goal Objectives"
              : childPath === "kpi"
              ? "Key Performance Indicator"
              : childPath === "target"
              ? "Targets"
              : childPath === "approval"
              ? "Approval List"
              : childPath === "cascade-team"
              ? "Cascade for Teams"
              : childPath === "cascade-all"
              ? "Cascade For All"
              : childPath === "history"
              ? "History"
              : "Goal Settings"
          }
          module_description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                molestiae quas vel sint commodi repudiandae consequuntur voluptatum"
          module_img="/assets/landing-page/Payroll managementpng.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default Planning;
