import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Button } from "antd";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ManageTeamAppraisal = () => {
  const { teamMember } = useParams();
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: teamMember ? "none" : "1px solid #d9d9d9",
            paddingBottom: "2rem",
          }}
        >
          <div className="">
            <h3 className="h4">Manage my team’s Appaisal</h3>
            <p>Score and rate Employee based on performance</p>
          </div>
          <Button
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
            className="myBtn d-flex align-items-center gap-2"
          >
            <MdOutlineArrowBack />
            Back
          </Button>
        </div>

        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ManageTeamAppraisal;
