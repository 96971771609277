import { convertToCapitalize } from "../../../../../util/helpers";
import { FileFilled } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";

function DocumentHistory() {
  const [details] = useOutletContext();
  return (
    <div className="col-md-12 py-3">
      <div className="row">
        {details.map((emp) => (
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body d-flex align-items-center gap-4">
                <div className="h1">
                  <FileFilled />
                </div>
                <div>
                  <h5 className="mb-0">{emp.name}</h5>
                  <p>{emp.category}</p>
                  <p>{convertToCapitalize(emp.approval)}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DocumentHistory;
