import React from "react";

const PrefillProfile = ({ hireProfile }) => {
  return (
    <div className="mt-3">
      <div className="row mt-3">
        <div className="col-6">
          <label>Title:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.title || "N/A"}
          </p>
        </div>
        <div className="col-6 ">
          <label>Last name:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.last_name || "N/A"}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label>First name:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.first_name || "N/A"}
          </p>
        </div>
        <div className="col-6 ">
          <label>Other names:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.other_names || "N/A"}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <label>Phone number:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.phone_number || "N/A"}
          </p>
        </div>
        <div className="col-6">
          <label>Email:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.email || "N/A"}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 ">
          <label>State:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.state || "N/A"}
          </p>
        </div>
        <div className="col-6 ">
          <label>Home address:</label>
          <p className="h6">
            {hireProfile?.payload["pre-fill-form"]?.home_address || "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrefillProfile;
