import React from "react";

import { NavLink } from "react-router-dom";

function SystemStepsDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"system-steps"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">List all steps</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"system-steps/upload-system-steps"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload steps</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default SystemStepsDropdown;
