import React, { useState } from "react";
import HocHeader from "../ui/HocHeader";
import { RiPulseLine } from "react-icons/ri";
import { useGetIDCardOverview } from "../../util/usershookActions";
import { useShallowEqualSelector } from "../../util/hooks";
import { idcard_overview } from "../../util/selectors/userSelectors";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";

function Overview() {
  const [enabled, setEnabled] = useState(true);

  const { isLoading } = useGetIDCardOverview(enabled, setEnabled);
  const overvieiw_data = useShallowEqualSelector(idcard_overview);



  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="(ID) Identity Card Management"
          module_description="lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip"
          // module_img="/assets/benefits.png"
        />
      </div>

      <div className="container-fluid py-3 padding_3rem ">
        <section className="app__section_no_border mt-5 py-4 mb-4">
          <h5>Summary</h5>

          {isLoading ? (
            <SkeletonUI number={2} />
          ) : Object.keys(overvieiw_data).length ? (
            <div className="row mt-4">
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(7, 27, 187, 0.12)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">ID Card Request</h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_identity_card_request || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(35, 113, 205, 0.19)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">Report Request</h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_identity_card_report || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(7, 187, 68, 0.19)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">Approved Request</h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_approved_request || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(205, 35, 127, 0.19)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">Pending ID card </h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_pending_request || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(7, 187, 68, 0.19)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">Resolved Report</h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_resolved_report || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="insight"
                  style={{ backgroundColor: "rgba(7, 27, 187, 0.12)" }}
                >
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <RiPulseLine />
                    </div>
                    <div>
                      <div className="insight-title">
                        <h6 className="text-capitalize">Pending Report</h6>
                      </div>
                      <div className="insight-content">
                        <h2 className="mb-0">
                          {overvieiw_data?.total_pending_report || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoContent message={"No overview data available"} />
          )}
        </section>
      </div>
    </>
  );
}

export default Overview;

// sample chart row

// <div className="row">
//   <div className="col-md-6 mt-3">
//     <div className="card">
//       <div className="card-body">
//         <h5 className="mb-2">Subscription usage</h5>

//         <small className="text-muted">
//           {/* The gender categories based on a departmental basis */}
//         </small>
//         <hr />
//         <div className="my-chart">
//           {Object.keys(overview_stats).length && !isLoading ? (
//             <Line options={options} data={dataLine} />
//           ) : (
//             <SkeletonUI number={2} />
//           )}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>;
