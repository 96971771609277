import validator from "validator";

// Validation for login page

export const loginValidator = (values) => {
  let errors = {};

  if (values.hasOwnProperty("email") && validator.isEmpty(values.email)) {
    errors.email = "Email must not be empty";
  } else if (
    values.hasOwnProperty("email") &&
    !validator.isEmail(values.email)
  ) {
    errors.email = "Please enter a valid email address";
  }

  if (values.hasOwnProperty("password") && validator.isEmpty(values.password)) {
    errors.password = "Password must not be empty";
  }
  if (
    values.hasOwnProperty("employee_id") &&
    validator.isEmpty(values.employee_id)
  ) {
    errors.employee_id = "Employee ID must not be empty";
  }

  return errors;
};

// Validation for forgot password page

export const forgotValidator = (values) => {
  let errors = {};

  if (values.hasOwnProperty("EmpID") && validator.isEmpty(values.EmpID)) {
    errors.EmpID = "Employee ID cannot be empty";
  }

  return errors;
};

// Validation for reset page

export const resetValidator = (values) => {
  let errors = {};

  if (
    values.hasOwnProperty("new_password") &&
    values.new_password.trim() === ""
  ) {
    errors.new_password = "Password must not be empty";
  }

  if (values.hasOwnProperty("otp") && values.otp.trim() === "") {
    errors.otp = "OTP must not be empty";
  }

  if (
    values.hasOwnProperty("confirm_password") &&
    values.confirm_password.trim() === ""
  ) {
    errors.confirm_password = "Password must not be empty";
  }

  if (
    Object.keys(errors).length === 0 &&
    values.hasOwnProperty("confirm_password") &&
    values.hasOwnProperty("new_password") &&
    values.new_password !== values.confirm_password
  ) {
    errors.confirm_password = "Passwords do not match!";
  }

  return errors;
};
export const setupValidator = (values) => {
  let errors = {};

  if (
    values.hasOwnProperty("new_password") &&
    values.new_password.trim() === ""
  ) {
    errors.new_password = "Password must not be empty";
  }

  if (
    values.hasOwnProperty("confirm_password") &&
    values.confirm_password.trim() === ""
  ) {
    errors.confirm_password = "Password must not be empty";
  }

  if (
    Object.keys(errors).length === 0 &&
    values.hasOwnProperty("confirm_password") &&
    values.hasOwnProperty("new_password") &&
    values.new_password !== values.confirm_password
  ) {
    errors.confirm_password = "Passwords do not match!";
  }

  return errors;
};

// validation for company setup
export const companySetupValidator = (values) => {
  let errors = {};

  if (
    values.hasOwnProperty("office_address") &&
    validator.isEmpty(values.office_address)
  ) {
    errors.office_address = "Office address must not be empty";
  }

  if (
    values.hasOwnProperty("rc_number") &&
    validator.isEmpty(values.rc_number)
  ) {
    errors.rc_number = "RC Number must not be empty";
  }

  if (
    values.hasOwnProperty("phone_number") &&
    validator.isEmpty(values.phone_number)
  ) {
    errors.phone_number = "Phone Number must not be empty";
  }

  return errors;
};

// validation for change password
export const changePasswordValidator = (values) => {
  let errors = {};

  if (
    values.hasOwnProperty("new_password") &&
    values.new_password.trim() === ""
  ) {
    errors.new_password = "New password must not be empty";
  }

  if (
    values.hasOwnProperty("confirm_new_password") &&
    values.confirm_new_password.trim() === ""
  ) {
    errors.confirm_new_password = "Confirm password must not be empty";
  }

  if (
    Object.keys(errors).length === 0 &&
    values.hasOwnProperty("confirm_new_password") &&
    values.hasOwnProperty("new_password") &&
    values.new_password !== values.confirm_new_password
  ) {
    errors.confirm_new_password = "Passwords do not match!";
  }

  return errors;
};
