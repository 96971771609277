import React from "react";
import styles from "../../../styles/employee/Profile.module.css";

function DocumentLanding() {
  return (
    <div className="d-flex align-items-center" style={{ height: "100%" }}>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src="/assets/documents.jpg"
              alt=""
              className={styles.noDocumentImage}
            />
            <p className="h5 text-center mt-5">Welcome to the Documents hub.</p>
            <small>Select a category to see the corresponding documents</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentLanding;
