import React, { useState, useEffect } from "react";
import { Input, Space, Table, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { useGradeWitBands } from "../../../util/usershookActions";
import { useUserCleanUp } from "../../../util/hooks";

import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { AiOutlineSearch } from "react-icons/ai";

import HocHeader from "../../ui/HocHeader";
import { formatMoney } from "../../../util/helpers";

function GradeWithBand() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);

  const [graded_data, setGradedData] = useState([]);
  const [heading, setHeading] = useState([]);
  const [dyn_column, setDynColumn] = useState([]);
  const [dyn_data, setDynData] = useState([]);

  const { data, isLoading } = useGradeWitBands(enabled, setEnabled);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  useEffect(() => {
    if (data && Object.keys(data)?.length) {
      //   setGradedData(modData);
      const head = data.payload.heading;
      setHeading(data.payload.heading);

      console.log({ res: data?.payload });

      const modData = data?.payload.breakdown.map((item, index) => ({
        ...item,
        key: index + 1,
        [head[index]]: item[head[index]],
      }));

      console.log({ modData });
      setDynData(modData);
    }
  }, [data]);

  useEffect(() => {
    if (heading?.length) {
      const col = heading.map((item) => {
        return {
          title: item?.toUpperCase(),
          dataIndex: item,
          key: item,
          render: (value) => <>{value || "N/A"}</>,
          ...getColumnSearchProps(item),
        };
      });

      //   setHeading(data.payload.heading)
      console.log({
        col: [
          {
            title: "S/N",
            dataIndex: "key",
            key: "key",
            width: 100,
          },
          ...col,
        ],
      });

      setDynColumn([
        {
          title: "S/N",
          dataIndex: "key",
          key: "key",
          width: 100,
        },
        ...col,
      ]);
    }
  }, [heading]);

  useEffect(() => {
    if (heading?.length) {
      const col = heading.map((item) => {
        return {
          title: item?.toUpperCase(),
          dataIndex: item,
          key: item,
          render: (value) => <>{value || "N/A"}</>,
          ...getColumnSearchProps(item),
        };
      });

      //   setHeading(data.payload.heading)
      console.log({
        col: [
          {
            title: "S/N",
            dataIndex: "key",
            key: "key",
            width: 100,
          },
          ...col,
        ],
      });
    }
  }, [heading]);

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "1rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available Grades</h5>
              {/* <small className=" d-none d-sm-block">
                All benefits-in-kind profiled and available for job roles or
                grades.
              </small> */}
            </div>
            {/* <Link to={"/modules/compensation/pay-grades"}>
              <p className="performance__bagde2"> Add new grade</p>
            </Link> */}
          </div>
          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center">
              <div className="circle__bagde fw-bold">{dyn_data.length}</div>
              <h6 className="mb-0"> Grade(s)</h6>
            </div>
          </div>

          <div
            className="row"
            style={{
              padding: "1rem",
            }}
          >
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : dyn_data.length ? (
              <Table
                columns={dyn_column}
                dataSource={dyn_data}
                // bordered
                scroll={{ x: "768px" }}
                pagination={dyn_data.length > 10 ? true : false}
              />
            ) : (
              // <NoContent message="No available benefits yet" width={"400px"} />
              <>
                <NoContent width={"400px"} />
                <div className="d-flex justify-content-center mt-3">
                  <Link to={"/modules/compensation/pay-grades"}>
                    <Button className="myBtn">Add new grade</Button>
                  </Link>
                </div>
              </>
              // <p>No data yet</p>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default GradeWithBand;
