import React, { useState, useEffect } from "react";
import NotificationCard from "../ui/NotificationCard";
import { useShallowEqualSelector } from "../../util/hooks";
import { in_messages as getMessages } from "../../util/selectors/userSelectors";
import { markMessages } from "../../redux/users/userActions";
import { useDispatch } from "react-redux";

import { useGetCompanyNotifications } from "../../util/usershookActions";
import DashboardCard from "./DashboardCard";
import { Skeleton } from "antd";

import { AiFillBell } from "react-icons/ai";

function Notification() {
  const in_messages = useShallowEqualSelector(getMessages);
  const [formatted_messages, setFormattedMessages] = useState([]);
  const [enabled_cop_noti, setEnabledComNoti] = useState(true);
  const [showPreloader, setShowPreloader] = useState(true);

  const dispatch = useDispatch();

  useGetCompanyNotifications(enabled_cop_noti, setEnabledComNoti);

  function markAsRead(e, id) {
    e.preventDefault();
    markMessages(dispatch, id).then((data) => {
      if (data?.status === "success") {
        setEnabledComNoti(true);
      }
    });
  }

  useEffect(() => {
    if (in_messages.length) {
      let arry = [];

      in_messages.forEach((mesg) => {
        const d = new Date(mesg.created_at);

        let date =
          ("0" + d.getDate()).slice(-2) +
          "/" +
          ("0" + (d.getMonth() + 1)).slice(-2);
        let year = d.getFullYear();
        arry.push({
          id: mesg.id,
          title: `${mesg.subject} -  ${mesg.from}`,
          year,
          date,
          message: mesg.message,
        });
      });

      setFormattedMessages([...arry]);
    }
  }, [in_messages]);

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader(false);
    }, 3000);
  }, []);

  return (
    <div className="mb-3">
      <DashboardCard title={"Notifications"} icon={<AiFillBell />}>
        {showPreloader ? (
          <Skeleton active />
        ) : formatted_messages.length ? (
          formatted_messages.map(({ id, title, date, year, message }) => (
            <NotificationCard
              key={id}
              title={title}
              date={date}
              year={year}
              message={message}
              markAsRead={markAsRead}
              id={id}
            />
          ))
        ) : (
          <div className="d-flex flex-column align-items-center py-5">
            <img src="assets/silent.png" alt="" width={80} />
            <p className="mt-3">No Notification(s)</p>
          </div>
        )}
      </DashboardCard>
    </div>
  );
}

export default Notification;
