import React from "react";
import { useEffect, useState } from "react";
import { Input, Select, Button } from "antd";
import "../../../../styles/Planning.css";
import {
  useGetPerformanceKPITargets,
  useGetEmployeesByDepartment,
  useGetUserProfile,
  useGetCascade,
} from "../../../../util/usershookActions";
import { storeCascade } from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import SkeletonUI from "../../../ui/Skeleton";
import CascadeTable from "./CascadeTable";
import CurrentPerformanceYear from "../currentPerformanceYear";

const CascadeTeams = () => {
  const [enabled, setEnabled] = useState(true);
  const [userEnabled, setUserEnabled] = useState(false);
  const [exceedsMax, setExceedsMax] = useState(false);
  const [cascadeLoading, setCascadeLoading] = useState(false);
  const [employeesDataState, setEmployeesDataState] = useState([]);
  const [targetsDataState, setTargetsDataState] = useState([]);
  const [targetUnit, setTargetUnit] = useState();

  const dispatch = useDispatch();
  const { data: userProfile } = useGetUserProfile(enabled, setEnabled);

  const [goalName, setGoalName] = useState("");
  const [objectiveName, setObjectiveName] = useState("");
  const [kpiName, setKPIName] = useState("");
  const [maxValue, setMaxValue] = useState("");

  const [formData, setFormData] = useState({
    department_id: null,
    target_id: null,
    kpi_id: null,
    objective_id: null,
    goal_id: null,
    employee_id: null,
    weight: "",
    individual_target: "",
  });

  const {
    data: cascadeTableData,
    isLoading,
    refetch,
  } = useGetCascade(enabled, setEnabled);

  const { data: targetsData, refetch: refetchTargets } =
    useGetPerformanceKPITargets(enabled, setEnabled);

  const { data: employeesData } = useGetEmployeesByDepartment(
    userEnabled,
    setUserEnabled,
    formData.department_id
  );

  // const maxValue = 100; // Set maximum value

  const handleWeightChange = (name, value) => {
    if (value <= maxValue) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setExceedsMax(false);
    } else {
      setExceedsMax(true);
    }
  };

  useEffect(() => {
    if (formData.department_id) {
      setUserEnabled(true);
    } else {
      setUserEnabled(false);
    }
  }, [formData.department_id]);

  useEffect(() => {
    if (employeesData?.payload) {
      setEmployeesDataState(employeesData?.payload);
    }
  }, [employeesData?.payload]);

  useEffect(() => {
    if (targetsData) {
      setTargetsDataState(targetsData);
    }
  }, [targetsData, formData?.department_id]);

  const handleFormChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTargetChange = (
    name,
    value,
    option,
    goal_id,
    objective_id,
    kpi_id
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      [goal_id]: option?.items?.goal_id || "",
      [objective_id]: option?.items?.objective_id || "",
      [kpi_id]: option?.items?.kpi_id || "",
    }));
    setGoalName(option?.items?.goal);
    setObjectiveName(option?.items?.objective);
    setKPIName(option?.items?.kpi);
    setMaxValue(option?.items?.available_weight);
    setTargetUnit(option?.items?.unit);
  };

  useEffect(() => {
    const name = "department_id";
    if (userProfile?.data?.personal?.is_hod?.length === 1) {
      setFormData((prev) => ({
        ...prev,
        [name]: userProfile?.data?.personal?.is_hod[0]?.id,
      }));
    }
  }, [userProfile?.data?.personal?.is_hod]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      target_id: null,
    }));
  }, [formData?.department_id]);

  useEffect(() => {
    if (formData?.target_id === null) {
      setFormData((prev) => ({
        ...prev,
        individual_target: "",
        weight: "",
        employee_id: null,
      }));
      setGoalName("");
      setObjectiveName("");
      setKPIName("");
      setMaxValue("");
    }
  }, [formData?.target_id]);

  // console.log("selected", formData);

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleStoreCascade = (e) => {
    e.preventDefault();
    setCascadeLoading(true);
    const creds = formData;
    storeCascade(dispatch, creds).then(() => {
      setCascadeLoading(false);
      setFormData([
        {
          department_id: null,
          target_id: null,
          kpi_id: "",
          objective_id: "",
          goal_id: "",
          employee_id: null,
          weight: "",
          individual_target: "",
        },
      ]);
      setGoalName("");
      setObjectiveName("");
      setKPIName("");
      setMaxValue("");
      setEmployeesDataState([]);
      setTargetsDataState([]);
      refetch();
      refetchTargets();
    });
  };

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="planning__cascade__header">
          <div className="">
            <h3 className="h4">
              Cascade
              <CurrentPerformanceYear />
            </h3>
            <p>
              User can distribute goals to departmental units and individuals
            </p>
          </div>
        </div>

        <div className="py-4">
          <div className="">
            {userProfile?.data?.personal?.is_hod?.length ? (
              userProfile?.data?.personal?.is_hod?.length > 1 ? (
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">Department Category</label>
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select a department"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      width: "20rem",
                    }}
                    value={formData?.department_id}
                    onChange={(value) =>
                      handleFormChange("department_id", value)
                    }
                    options={userProfile?.data?.personal?.is_hod?.map(
                      (department) => ({
                        value: department?.id,
                        label: department?.name,
                      })
                    )}
                  />
                </div>
              ) : (
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">Department Category</label>
                  <Input
                    size="large"
                    defaultValue={userProfile?.data?.personal?.is_hod[0]?.name}
                    className="disabled__input"
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      width: "20rem",
                    }}
                    disabled
                  />
                </div>
              )
            ) : null}
          </div>

          <form
            className="d-flex flex-column gap-3"
            style={{
              borderBottom: "1px solid #d9d9d9",
              paddingBottom: "2rem",
            }}
          >
            <div className="planning__cascade__form">
              <div className="d-flex flex-column gap-2 planning__form__item">
                <label className="fw-bold fs-6">Targets</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a target"
                  optionFilterProp="children"
                  value={formData?.target_id}
                  onChange={(value, option) =>
                    handleTargetChange(
                      "target_id",
                      value,
                      option,
                      "goal_id",
                      "objective_id",
                      "kpi_id"
                    )
                  }
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                  options={targetsDataState
                    ?.filter(
                      (target) =>
                        target.department_id === formData.department_id
                    )
                    .map((target) => ({
                      value: target?.id,
                      label: `${target?.target} ${target.unit}`,
                      items: target,
                    }))}
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item__second__half">
                <label className="fw-bold fs-6">Goal</label>
                <Input
                  size="large"
                  id="goals"
                  value={goalName}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                  required
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item__first__half">
                <label className="fw-bold fs-6">Objective</label>
                <Input
                  size="large"
                  id="objectives"
                  value={objectiveName}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                  required
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item__second__half">
                <label className="fw-bold fs-6">KPI</label>
                <Input
                  size="large"
                  id="kpis"
                  value={kpiName}
                  className="disabled__input"
                  style={{
                    border: "2px solid black",
                  }}
                  disabled
                  required
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item">
                <label className="fw-bold fs-6">Staff</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select an employee"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                  value={formData?.employee_id}
                  onChange={(value) => handleFormChange("employee_id", value)}
                  options={employeesDataState?.map((employee) => ({
                    value: employee?.id,
                    label: employee?.fullname,
                  }))}
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item__second__half">
                <label className="fw-bold fs-6">Individual Target</label>
                <Input
                  size="large"
                  id="individualTarget"
                  value={formData?.individual_target}
                  style={{
                    border: "2px solid black",
                    borderRadius: "6px",
                  }}
                  onChange={(e) =>
                    handleFormChange("individual_target", e.target.value)
                  }
                  addonAfter={targetUnit}
                  required
                />
              </div>

              <div className="d-flex flex-column gap-2 planning__form__item__first__half">
                <label className="fw-bold fs-6">
                  Weight{" "}
                  <small>
                    {" "}
                    {maxValue && ` (Available Weight: ${maxValue}%)`}
                  </small>
                </label>
                {exceedsMax && (
                  <small className="text-danger">
                    {`Weight cannot be more than ${maxValue}`}
                  </small>
                )}
                <Input
                  size="large"
                  id="weight"
                  value={formData?.weight}
                  onChange={(e) => handleWeightChange("weight", e.target.value)}
                  style={{
                    border: "2px solid black",
                  }}
                  required
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                }}
                onClick={handleStoreCascade}
                loading={cascadeLoading}
                disabled={
                  Object.values(formData).some((value) => value === "") ||
                  goalName === "" ||
                  objectiveName === "" ||
                  kpiName === ""
                }
              >
                Submit
              </Button>
            </div>
          </form>

          <div className="planning__cascade__table">
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : (
              <CascadeTable cascadeTableData={cascadeTableData} refetch={refetch} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CascadeTeams;
