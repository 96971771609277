import React from "react";
import { Routes, Route } from "react-router-dom";
import Overview from "./Overview";
import Sidebar from "./Sidebar";
import { useUsersNotification } from "../../util/hooks";

import { useMediaQuery } from "react-responsive";

import "../../styles/onboarding.css";
import PageNotFound from "../PageNotFound";
import AdminDashboardLayout from "../ui/AdminDashboardLayout";
import PendingRequest from "./id-card/PendingRequest";
import RejectedRequest from "./id-card/RejectedRequest";
import ApprovedRequest from "./id-card/ApprovedRequest";
import AllIDRequest from "./id-card/AllIDRequest";
import PendingReportRequest from "./report/PendingReportRequest";
import ResolvedReportRequest from "./report/ResolvedReportRequest";
import AllReport from "./report/AllReport";
import PrintIDCard from "./id-card/PrintIDCard";
import CanAccess from "../hoc/CanAccess";

function IdentityCardManagement() {
  useUsersNotification();
  const is_mobible = useMediaQuery({ query: "(max-width: 768px)" });
  function handleToggle(e) {
    if (is_mobible) {
      const sidebar = document.querySelector(".performance__nav");
      if (sidebar) {
        sidebar.classList.remove("mobile-show-sidebar");
      }
    } else {
      const sidebar = document.querySelector(".admin_layout");

      sidebar.classList.toggle("small__sidebar");
    }
  }
  return (
    <AdminDashboardLayout sidebar={<Sidebar handleToggle={handleToggle} />}>
      <main>
        <Routes>
          <Route
            index
            element={
              <CanAccess
                can_access={"view-identity-card-overview"}
                isRoute={false}
                navigateTo={"/identity-card-management/pending-id-card"}
              >
                <Overview />
              </CanAccess>
            }
          />
          <Route path="pending-id-card" element={<PendingRequest />} />
          <Route path="rejected-id-card" element={<RejectedRequest />} />
          <Route path="approved-id-card" element={<ApprovedRequest />} />
          <Route path="all-id-card" element={<AllIDRequest />} />
          <Route path="pending-report" element={<PendingReportRequest />} />
          <Route path="resolved-report" element={<ResolvedReportRequest />} />
          <Route path="all-reports" element={<AllReport />} />
          <Route path="print-id-card" element={<PrintIDCard />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
    </AdminDashboardLayout>
  );
}

export default IdentityCardManagement;
