import React, { useState, useEffect } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Modal,
  Card,
  InputNumber,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import {
  useGetAllBenefitsInKind,
  useGetAllGradedGrades,
  useGetAllSystemSteps,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { gradeGrade } from "../../../redux/users/userActions";
import HocHeader from "../../ui/HocHeader";
import { formatMoney } from "../../../util/helpers";

function GradedGrades() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [enabled_ben, setEnabledBen] = useState(true);
  const [enabled_stp, setEnabledStp] = useState(true);
  const [graded_data, setGradedData] = useState([]);

  const { data: ben_data } = useGetAllBenefitsInKind(
    enabled_ben,
    setEnabledBen,
    ""
  );
  const { data: stp_data } = useGetAllSystemSteps(enabled_stp, setEnabledStp);

  const { isLoading, refetch, data } = useGetAllGradedGrades(
    enabled,
    setEnabled
  );

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  function getColumnSearchPropsObj(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]["name"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      render: (value) => <>{value?.name || "N/A"}</>,
      ...getColumnSearchPropsObj("grade"),
    },
    {
      title: "Step",
      dataIndex: "step",
      key: "step",
      render: (value) => <>{value?.name || "N/A"}</>,
      ...getColumnSearchPropsObj("step"),
    },
    Table.EXPAND_COLUMN,
    {
      title: "Benefit",
      dataIndex: "benefit_count",
      key: "benefit_count",
      render: (value) => <>{value || "0"}</>,
      ...getColumnSearchProps("benefit_count"),
    },

    {
      title: "Gross Salary",
      dataIndex: "gross_salary",
      key: "gross_salary",
      ...getColumnSearchProps("gross_salary"),
      render: (value) => <>{formatMoney(value) || "N/A"}</>,
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space wrap>
          <GradeAction
            record={record}
            refetch={refetch}
            ben_data={ben_data}
            stp_data={stp_data}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (data && Object.keys(data)?.length) {
      const modData = data?.payload.map((item, index) => ({
        ...item,
        key: index + 1,
        benefit_count: item?.benefit?.length,
      }));
      setGradedData(modData);
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid ">
        <section className="app__section py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "1rem",
            }}
          >
            <div>
              <div className="d-flex gap-3 align-items-center">
                <div className="circle__bagde fw-bold">
                  {graded_data.length}
                </div>
                <h6 className="mb-0"> Pay Grade(s)</h6>
              </div>
            </div>
            {/* <Link to={"/modules/compensation/pay-grades"}>
              <p className="performance__bagde2"> Add new grade</p>
            </Link> */}
          </div>

          <div
            className="row"
            style={{
              padding: "1rem",
            }}
          >
            {isLoading ? (
              <SkeletonUI number={2} />
            ) : graded_data.length ? (
              <Table
                columns={columns}
                dataSource={graded_data}
                // bordered
                scroll={{ x: "768px" }}
                expandable={{
                  expandedRowRender: (record) => (
                    <Card
                      title={`${record.grade.name} benefit details`}
                      bordered={false}
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                      }}
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Benefit</th>
                            <th scope="col">Value</th>
                            <th scope="col">Description</th>
                            <th scope="col">Type</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.benefit.map((item, ind) => (
                            <>
                              <tr>
                                <th scope="row" key={item.id}>
                                  {ind + 1}
                                </th>
                                <td>{item.benefit || "N/A"}</td>
                                <td>{item.value || "N/A"}</td>
                                <td>
                                  {item?.description?.length > 200
                                    ? `${item?.description?.substring(
                                        0,
                                        200
                                      )}...`
                                    : item?.description}
                                </td>
                                <td>{item.type || "N/A"}</td>
                                <td>
                                  {item.status ? (
                                    <p className="text-success">Active</p>
                                  ) : (
                                    <p className="text-danger">Inactive</p>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </Card>
                  ),
                  rowExpandable: (record) => record.benefit_count,
                }}
                pagination={graded_data.length > 10 ? true : false}
              />
            ) : (
              <>
                <NoContent width={"400px"} />
                <div className="d-flex justify-content-center mt-3">
                  <Link to={"/modules/compensation/pay-grades"}>
                    <Button className="myBtn">Add new grade</Button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

function GradeAction({ record, refetch, ben_data, stp_data }) {
  const dispatch = useDispatch();

  const mod_benefit = record?.benefit?.map((item) => item.id) || [];

  console.log({ ben_data });

  const back_errors = useShallowEqualSelector(user_errors);
  const [loading, setLoading] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [step_id, setStepId] = useState(record?.step?.id || "");
  const [salary, setSalary] = useState(record?.gross_salary || "");
  const [benefits, setBenefit] = useState(mod_benefit);
  const [ben_options, setBenOptions] = useState([]);
  const [stp_options, setStpOptions] = useState([]);

  function handleModal() {
    setOpenModal(!open_modal);
  }

  function updateGrade(e) {
    e.preventDefault();
    const creds = {
      grade_id: record.grade.id,
      step_id: step_id,
      benefit_ids: benefits,
      salary: salary,
    };

    setLoading(true);
    gradeGrade(dispatch, { grading: [creds] }).then(() => {
      setLoading(false);
      refetch();
    });
  }

  const handleSelChange = (value) => {
    setBenefit([...value]);
  };

  useEffect(() => {
    if (ben_data && Object.keys(ben_data).length) {
      const opt = ben_data.payload.map((item) => {
        return {
          label: `${item.benefit}[${item.value}]`,
          value: item.id,
        };
      });

      setBenOptions(opt);
    }
  }, [ben_data]);
  useEffect(() => {
    if (stp_data && Object.keys(stp_data).length) {
      setStpOptions(stp_data.payload);
    }
  }, [stp_data]);

  return (
    <>
      <Space>
        <Button type="primary" onClick={handleModal}>
          Edit
        </Button>

        <Button className="bg-danger text-white">Delete</Button>
      </Space>

      <Modal
        open={open_modal}
        footer={null}
        onCancel={handleModal}
        width={700}
        className="over"
        maskClosable={false}
      >
        <h5 className="birthday__title">{record.grade.name}</h5>
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <form className="performance-form" onSubmit={updateGrade}>
            <div className="row px-3 ">
              <div className="col-md-6 mb-3">
                <div className="d-flex justify-content-between flex-wrap">
                  <label htmlFor="step">Step</label>

                  <small className="text-danger">
                    {back_errors.step_id && back_errors.step_id[0]}
                  </small>
                </div>

                <Select
                  className="w-100 mt-2"
                  type="text"
                  size="large"
                  name="step_id"
                  id="step_id"
                  value={step_id}
                  onChange={(val) => setStepId(val)}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value={""}>--</option>
                  {stp_options.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Select>
              </div>

              <div className="col-md-6 mb-3">
                <div className="d-flex justify-content-between flex-wrap">
                  <label htmlFor="salary">Gross Salary</label>

                  <small className="text-danger">
                    {back_errors.salary && back_errors.salary[0]}
                  </small>
                </div>

                <InputNumber
                  className="w-100 ant__number"
                  style={{ border: "none" }}
                  value={salary}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(val) => setSalary(val)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between flex-wrap">
                  <label htmlFor="salary">Benefits</label>

                  <small className="text-danger">
                    {back_errors.salary && back_errors.salary[0]}
                  </small>
                </div>

                <Select
                  value={benefits}
                  className="w-100 mt-2"
                  mode="multiple"
                  options={ben_options}
                  onChange={handleSelChange}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end px-3 mb-4 mt-2">
              <Button htmlType="submit" className="myBtn" loading={loading}>
                Update
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default GradedGrades;
