// import styles from "../../styles/hrms/Sidebar.module.css";

import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";

import EmployeeDropdown from "./dashboard/dropdown/EmployeeDropdown";
import DepartmentDropdown from "./dashboard/dropdown/DepartmentDropdown";
import SystemGradesDropdown from "./dashboard/dropdown/SystemGradesDropdown";
import SystemStepsDropdown from "./dashboard/dropdown/SystemStepsDropdown";
import JobRoleDropdown from "./dashboard/dropdown/JobRoleDropdown";
import { FaHome } from "react-icons/fa";

// const active = styles.active;
function HrDashboardSidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const parentPath = location.pathname.split("/")[3];

  return (
    <div className="d-flex flex-column performance__nav__wrapper">
      <nav
        className="performance__nav d-flex justify-content-between small__sidebar"
        id="sidebar"
      >
        <div className="d-flex pdr-12">
          <Link
            to={"/dashboard"}
            className="d-flex align-items-center gap-2 back  sidebar_text"
            style={{ marginRight: "170px" }}
          >
            <FaHome title="Return Home" />
            <span className="sidebar_text">Return Home</span>
          </Link>

          <Link
            to={"#"}
            onClick={() => navigate(-1)}
            className="d-flex align-items-center gap-2 back  sidebar_text"
          >
            <BsArrowLeftCircleFill title="Go back" />
            <span className="sidebar_text">Back</span>
          </Link>
        </div>

        <ul className="performance__navlinks">
          <li>
            <NavLink
              to={""}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Overview</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"company"}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Company</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"employee"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Employee</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"role"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Job Role</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"department"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Department</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"grades"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">System Grades</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"system-steps"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">System Steps</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"settings"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Settings</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"approval-management"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <span className="sidebar_text">Approvals</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="performance__nav__subnav">
        {parentPath === "employee" ? (
          <EmployeeDropdown />
        ) : parentPath === "role" ? (
          <JobRoleDropdown />
        ) : parentPath === "department" ? (
          <DepartmentDropdown />
        ) : parentPath === "grades" ? (
          <SystemGradesDropdown />
        ) : parentPath === "system-steps" ? (
          <SystemStepsDropdown />
        ) : null}
      </div>
    </div>
  );
}

export default HrDashboardSidebar;
