import React, {useState} from "react";
import { Outlet } from "react-router-dom";
import { useGetUserProfile } from "../../../util/usershookActions";
import { useUsersNotification } from "../../../util/hooks";
import { useShallowEqualSelector } from "../../../util/hooks";
import { user_profile } from "../../../util/selectors/userSelectors";


function IdCardPage() {
  useUsersNotification();
  const [enabled, setEnabled] = useState(true);
  useGetUserProfile(enabled, setEnabled);
  const profile = useShallowEqualSelector(user_profile);


  return (
    <>
      <Outlet context={[profile]} />
    </>
  );
}

export default IdCardPage;
