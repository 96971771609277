import React, { useState, useEffect } from "react";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";

import {
  event_type,
  holiday,
  settings_util,
  user_errors,
} from "../../../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../../../util/hooks";
import { Button, Modal, List, DatePicker } from "antd";
import {
  useGetSystemEventType,
  useGetSystemHolidays,
  useGetSystemSettingsUtils,
} from "../../../../util/usershookActions";
import {
  addEditHoliday,
  uploadMultiple,
} from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import HolidaysEachList from "./HolidaysEachList";
import FileUpload from "../../../ui/FileUpload";
import dayjs from "dayjs";

const Holiday = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [enabled_uti, setEnabledUtil] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [addHoliday, setAddHoliday] = useState(false);
  const [file, setFile] = useState();
  const [enabled_evt, setEnabledEvt] = useState(true);
  const [values, setValues] = useState({
    name: "",
    date: "",
    event_type: null,
  });

  const { refetch } = useGetSystemHolidays(enabled, setEnabled);

  useGetSystemSettingsUtils(enabled_uti, setEnabledUtil);
  useGetSystemEventType(enabled_evt, setEnabledEvt);
  const utils = useShallowEqualSelector(settings_util);
  const back_errors = useShallowEqualSelector(user_errors);
  const sys_holidays = useShallowEqualSelector(holiday);
  const sys_event = useShallowEqualSelector(event_type);

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const handleMultipleModal = (e) => {
    e.preventDefault();
    setShowUploadModal(!showUploadModal);
  };

  const handleAddHoliday = () => {
    clearSelected();
    setAddHoliday(!addHoliday);
  };

  const handleSelected = (item) => {
    // console.log(item);
    setSelected(item);
    setValues(item);
    setAddHoliday(true);
  };
  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const onChange = (date, dateString) => {
    console.log(dateString);
    setValues({ ...values, date: dateString });
  };

  useEffect(() => {
    if (sys_holidays.length) {
      setData(sys_holidays);
    } else {
      setData([]);
    }
  }, [sys_holidays]);

  const clearSelected = () => {
    setSelected({});
    setValues({
      name: "",
      date: "",
      event_type: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const creds = {
      ...values,
      id: Object.keys(selected).length ? selected.id : null,
    };
    addEditHoliday(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        refetch();
        setValues({
          name: "",
          date: "",
          event_type: "",
        });

        if (Object.keys(selected).length) {
          dispatch({
            type: "UPDATE_TO_HOLIDAYS",
            payload: res.payload,
          });
        } else {
          dispatch({
            type: "ADD_TO_HOLIDAYS",
            payload: res.payload,
          });
        }
        setAddHoliday(false);
      }
    });
  };

  function handleUpload(e) {
    e.preventDefault();
    const url = "holiday/excel/upload";
    setLoading(true);
    const creds = { file };
    uploadMultiple(dispatch, url, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        setFile(null);
      }
    });
  }
  // console.log(values);
  return (
    <div className="setting">
      <div className="d-flex justify-content-between">
        <h3>Holidays</h3>
        <TfiLayoutListThumbAlt className="svg_icons" />
      </div>
      <div>
        <div className="category">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <small>Create, view and edit your company's holidays </small>
            </div>
            <div className="col-md-5 gap-1 d-flex align-items-center justify-content-end">
              <Button type="text" className="w-50" onClick={handleModal}>
                Show All Holidays
              </Button>
              <Button
                type="text"
                style={{ width: "fit-content" }}
                onClick={handleMultipleModal}
              >
                Upload Multiple
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={showModal}
        footer={null}
        centered
        onCancel={handleModal}
        width={700}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3> All Holidays</h3>

            {addHoliday ? (
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="name">Name</label>
                        <div className="w-100 text-right text-danger d-flex justify-content-end">
                          <small>
                            {back_errors.name && back_errors.name[0]}
                          </small>
                        </div>
                      </div>

                      <input
                        placeholder="Holiday name"
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="date">Date</label>
                        <div className="w-100 text-right text-danger d-flex justify-content-end">
                          <small>
                            {back_errors.date && back_errors.date[0]}
                          </small>
                        </div>
                      </div>
                      {/* <input
                        type="date"
                        id="date"
                        name="date"
                        value={values.date || ""}
                        onChange={handleChange}
                        placeholder="Holiday date"
                      /> */}

                      {values.date ? (
                        <DatePicker
                          onChange={onChange}
                          format="MMM D"
                          value={
                            dayjs(values.date, "MMM D") ||
                            dayjs(values.date, "MMM D")
                          }
                          className="w-100"
                          allowClear={false}
                        />
                      ) : (
                        <DatePicker
                          onChange={onChange}
                          format="MMM D"
                          // value={
                          //   dayjs(values.date, "MMMM D") ||
                          //   dayjs(values.date, "MMMM D")
                          // }
                          className="w-100"
                          allowClear={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="event_type">Event</label>
                        <div className="w-100 text-right text-danger d-flex justify-content-end">
                          <small>
                            {back_errors.event_type &&
                              back_errors.event_type[0]}
                          </small>
                        </div>
                      </div>

                      <select
                        name="event_type"
                        id="event_type"
                        value={values.event_type}
                        onChange={handleChange}
                      >
                        <option value="">--</option>
                        {sys_event.length
                          ? sys_event.map((item) => {
                              return (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <div>
                    <Button
                      className="myBtn  mt-3 mx-2"
                      onClick={() => {
                        clearSelected();
                        handleAddHoliday();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="myBtn  mt-3"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            ) : (
              <div
                style={{
                  maxHeight: 500,
                  overflowY: "auto",
                }}
                className="my-3"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <HolidaysEachList
                      item={item}
                      handleSelected={handleSelected}
                      refetch={refetch}
                    />
                  )}
                />
              </div>
            )}
            <div className="d-flex justify-content-end">
              {!addHoliday && (
                <Button className="myBtn  mt-3" onClick={handleAddHoliday}>
                  Add Holiday
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showUploadModal}
        footer={null}
        centered
        onCancel={handleMultipleModal}
        width={700}
      >
        <div className="row">
          <div className="col-lg-12">
            <h3> Upload Multiple Holidays</h3>
            <div className="mt-5">
              <FileUpload
                file={file}
                setFile={setFile}
                accept={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                supportedFormats={"xls, xlsx"}
                type={"excel"}
              />
              <div className="d-flex gap-2 justify-content-end my-3">
                <a href="/assets/bank_excel.xlsx" download={true}>
                  <Button className="myBtn ">Download Template</Button>
                </a>
                <Button
                  className="myBtn"
                  onClick={handleUpload}
                  disabled={!file}
                  loading={loading}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Holiday;
