import "swiper/css/bundle";
import { useShallowEqualSelector } from "../../util/hooks";
import { company_details } from "../../util/selectors/userSelectors";
import Logo from "../Logo";

function Sidebar() {
  const companyDetails = useShallowEqualSelector(company_details);
  const logo = companyDetails?.settings?.filter(
    (setting) => setting?.setting === "CompLogo"
  );

  return (
    <aside className={`d-none d-md-block col-md-5 `}>
      <div className=" sidebar__anchor  w-75">
        <div className="">
          <div className="d-flex gap-3 align-items-center ">
            <img
              src={logo[0]?.value || logo[0]?.default}
              alt="logo"
              style={{
                width: 100,
                height: 100,
                borderRadius: 10,
                objectFit: "cover",
              }}
            />
            <h1
              style={{
                fontSize: 45,
                fontWeight: "300",
              }}
            >
              {companyDetails?.business.company_name}
            </h1>
          </div>
          <h3
            className="mt-4"
            style={{
              fontSize: "22px",
              fontWeight: "100",
              lineHeight: "37px",
            }}
          >
            Administrative and self-service actions carefully designed and
            developed to manage your workforce efficiently.
          </h3>
          <Logo size={20} />
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
