import { useState, useEffect } from "react";
import { Table, Button, Modal } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  authorizeOrUnautorizeAppraisal,
  endAppraisal,
  restartLastAppraisal,
} from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import NoContent from "./../../../ui/NoContent";
import SkeletonUI from "./../../../ui/Skeleton";

dayjs.extend(utc);

const AppraisalApprovedTable = ({
  appraisalApprovedTableData,
  appraisalApprovedRefetch,
  appraisalPendingRefetch,
  isLoading,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const [endAppraisalLoading, setEndAppraisalLoading] = useState(false);
  const [restartLastAppraisalLoading, setRestartLastAppraisalLoading] = useState(
    false
  )

  const dispatch = useDispatch();

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys?.length > 0;

  useEffect(() => {
    if (appraisalApprovedTableData) {
      const new_array = appraisalApprovedTableData?.payload?.map(
        (appraisal, index) => {
          return {
            key: appraisal.id,
            appraisalName: appraisal.appraisal_name,
            planTitle: appraisal.plan_title,
            appraisalStartDate: dayjs
              .utc(appraisal.start_date)
              .format("YYYY-MM-DD"),
            appraisalEndDate: dayjs
              .utc(appraisal.end_date)
              .format("YYYY-MM-DD"),
            reviewPeriod: `${appraisal.review_period_date.from} - ${appraisal.review_period_date.to}`,
            reviewPeriodName: appraisal.review_period_date.review_period,
            // iteration: appraisal.iteration,
            status: appraisal.status,
            appraisal,
          };
        }
      );
      setTableData(new_array);
    }
  }, [appraisalApprovedTableData]);

  const columns = [
    {
      title: "Appraisal Name",
      dataIndex: "appraisalName",
    },
    {
      title: "Plan Title",
      dataIndex: "planTitle",
    },
    {
      title: "Appraisal Start Date",
      dataIndex: "appraisalStartDate",
    },
    {
      title: "Appraisal End Date",
      dataIndex: "appraisalEndDate",
    },
    {
      title: "Review Period",
      dataIndex: "reviewPeriod",
    },
    {
      title: "Review Period Name",
      dataIndex: "reviewPeriodName",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, records) => <EachAction item={records} />,
    },
  ];

  const handleUnAuthorizeAppraisal = (e) => {
    e.preventDefault();
    setLoading(true);
    const creds = {
      appraisal_ids: selectedRowKeys,
      authorize: false,
    };
    authorizeOrUnautorizeAppraisal(dispatch, creds).then(() => {
      setLoading(false);
      setSelectedRowKeys([]);
      appraisalApprovedRefetch();
      appraisalPendingRefetch();
    });
  };

  const handleEndAppraisal = (e) => {
    e.preventDefault();
    setEndAppraisalLoading(true);
    const creds = {
      appraisal_ids: selectedRowKeys,
      status: "done",
    };
    endAppraisal(dispatch, creds).then(() => {
      setEndAppraisalLoading(false);
      setSelectedRowKeys([]);
      appraisalApprovedRefetch();
      appraisalPendingRefetch();
    });
  };

  const handleRestartLastAppraisal = (e) => {
    e.preventDefault();
    setRestartLastAppraisalLoading(true);
    restartLastAppraisal(dispatch).then(() => {
      setRestartLastAppraisalLoading(false);
      appraisalApprovedRefetch();
      appraisalPendingRefetch();
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonUI />
      ) : appraisalApprovedTableData?.payload?.length ? (
        <div className="performance_table">
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: 1500 }}
          />

          <div className="d-flex justify-content-center gap-3 mt-5">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={handleUnAuthorizeAppraisal}
              loading={loading}
              disabled={!hasSelected}
            >
              Unauthorize Selection
            </Button>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={handleEndAppraisal}
              loading={endAppraisalLoading}
              disabled={!hasSelected}
            >
              End Appraisal
            </Button>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
              }}
              onClick={handleRestartLastAppraisal}
              loading={restartLastAppraisalLoading}
            >
              Reopen Last Appraisal
            </Button>
          </div>
        </div>
      ) : (
        <NoContent />
      )}
    </>
  );
};

function EachAction({ item }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="filter">
        <Button
          className="d-flex justify-content-center text-white "
          shape="round"
          type="primary"
          size="medium"
          onClick={handleModalOpen}
        >
          View More
        </Button>
      </div>
      <Modal
        title={item.appraisal.appraisal_name}
        open={isModalOpen}
        onCancel={handleModalOpen}
        footer={null}
        centered={true}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <span className="fw-bold">Plan Title:</span>
            <span className="">{item.appraisal.plan_title}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Status:</span>
            <span className="">{item.appraisal.status}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Appraisal Start Date:</span>
            <span className="">
              {dayjs.utc(item.appraisal.start_date).format("YYYY-MM-DD")}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Appraisal End Date:</span>
            <span className="">
              {dayjs.utc(item.appraisal.end_date).format("YYYY-MM-DD")}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">
              No of Days for advance notice before START of appraisal:
            </span>
            <span className="">{item.appraisal.notice_before_start}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">
              No of Days for advance notice before END of appraisal:
            </span>
            <span className="">{item.appraisal.notice_before_end}</span>
          </div>
          <div className="d-flex gap-2">
            <span className="fw-bold">Review Period:</span>
            <span className="">
              {`${dayjs
                .utc(item.appraisal.review_period_date.from)
                .format("YYYY-MM-DD")} -
                ${dayjs
                  .utc(item.appraisal.review_period_date.to)
                  .format("YYYY-MM-DD")}`}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AppraisalApprovedTable;
