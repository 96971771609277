import React, { useEffect, useState } from "react";
import { Button, Modal, List, Select, Popconfirm, Space, Table } from "antd";
import HocHeader from "../../ui/HocHeader";
import { useGetAllSystemSteps } from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  all_system_step,
  user_errors,
} from "../../../util/selectors/userSelectors";
import SkeletonUI from "../../ui/Skeleton";
import { Link } from "react-router-dom";
import {
  addEditSystemSteps,
  deleteSystemSteps,
} from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import NoContent from "../../ui/NoContent";

export default function AllSystemStep({ module_title }) {
  useUserCleanUp();
  const [addRoleDesg, setAddRoleDesg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [animeLoadSave, setAnimeLoadSave] = useState(false);
  const [selected, setSelected] = useState({});
  const [enabled, setEnabled] = useState(true);
  const [data, setDate] = useState([]);
  const [values, setValues] = useState({
    name: "",
    code: "",
  });
  const dispatch = useDispatch();

  const { refetch, isLoading } = useGetAllSystemSteps(enabled, setEnabled);
  const back_errors = useShallowEqualSelector(user_errors);
  const system_step_data = useShallowEqualSelector(all_system_step);

  useEffect(() => {
    if (system_step_data.length > 0) {
      const modData = system_step_data.map((item, index) => {
        return {
          ...item,
          key: index + 1,
        };
      });
      setDate(modData);
      setLoading(!loading);
    }
    setLoading(false);
  }, [system_step_data]);

  const creds = {
    ...values,
    id: selected.id,
  };

  const handleSave = (e) => {
    e.preventDefault();
    setAnimeLoadSave(true);
    addEditSystemSteps(dispatch, creds).then((res) => {
      setAnimeLoadSave(false);
      if (res?.status === "success") {
        refetch();
        setAddRoleDesg(false);
        setLoading(false);
        setValues({
          name: "",
          code: "",
        });
      }
      dispatch({
        type: "ADD_EDIT_SYSTEM_STEPS",
        payload: res,
      });
      setAddRoleDesg(!addRoleDesg);
    });

    // addEditSystemSteps(dispatch, selected )
  };
  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    {
      title: "Grade",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Action",
      dataIndex: "code",
      key: "code",
      render: (_, item) => {
        return (
          <EachRender
            item={item}
            handleSelected={handleSelected}
            refetch={refetch}
          />
        );
      },
    },
  ];
  const handleSelected = (items) => {
    setSelected(items);
    setValues(items);
    setAddRoleDesg(true);
  };
  const clearSelected = () => {
    setSelected({});
    setValues({
      name: "",
      code: "",
    });
  };

  const handleAddRoleDesg = () => {
    clearSelected();
    setAddRoleDesg(!addRoleDesg);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log("values", values);
  };

  return (
    <>
      <div className="container-fluid">
        <section className="app__section py-4">
          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center mb-4">
              <div className="circle__bagde fw-bold">{data?.length}</div>
              <h6 className="mb-0"> System step(s)</h6>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <h3>All Roles & Designations</h3> */}

                {addRoleDesg ? (
                  // onSubmit={handleSubmit}
                  <form className="onboarding-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="role">Name</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.name && back_errors.name[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            placeholder="Role Name"
                            type="text"
                            id="role"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="code">Code</label>
                            <div className="w-100 text-right text-danger d-flex justify-content-end">
                              <small>
                                {back_errors.code && back_errors.code[0]}
                              </small>
                            </div>
                          </div>

                          <input
                            type="text"
                            id="code"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            placeholder="Role code"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div>
                        <Button
                          className="myBtn py-0 mt-3 mx-2"
                          onClick={() => {
                            clearSelected();
                            handleAddRoleDesg();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="myBtn py-0 mt-3"
                          htmlType="submit"
                          loading={animeLoadSave}
                          onClick={(e) => handleSave(e)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : isLoading ? (
                  <SkeletonUI />
                ) : system_step_data ? (
                  <div
                    // style={{
                    //   maxHeight: 500,
                    //   overflowY: "auto",
                    // }}
                    className="my-3"
                  >
                    <Table
                      dataSource={data}
                      columns={columns}
                      pagination={data.length > 10 ? true : false}
                    />
                  </div>
                ) : (
                  <NoContent
                    message={"No system step available."}
                    width={"400px"}
                  />
                )}
                <div className="d-flex justify-content-end">
                  {!addRoleDesg && (
                    <Button
                      className="myBtn py-0 mt-3"
                      onClick={handleAddRoleDesg}
                    >
                      Add Step
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

function EachRender({ item, handleSelected, refetch }) {
  const dispatch = useDispatch();
  const [animeLoadDel, setAnimeLoadDel] = useState(false);
  const confirm = (item) => {
    setAnimeLoadDel(true);
    deleteSystemSteps(dispatch, item).then((res) => {
      setAnimeLoadDel(false);
      if (res?.status === "success") {
        dispatch({ type: "REMOVE_FROM_SYSTEM_STEP", payload: item.id });
        refetch();
      }
    });
  };
  return (
    <>
      <Space wrap>
        <Button
          className="text-info"
          onClick={() => handleSelected(item)}
          type="text"
        >
          Edit
        </Button>
        <Popconfirm
          title="Delete entries"
          description="Do you what to delete this entry?"
          onConfirm={() => confirm(item)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="list-loadmore-edit"
            className="text-danger"
            loading={animeLoadDel}
            type="text"
          >
            Delete
          </Button>
        </Popconfirm>
      </Space>
    </>
  );
}
