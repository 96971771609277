import styles from "../../../styles/employee/Profile.module.css";
import { Button } from "antd";

export default function References() {
  return (
    <div className="row">
      <div className="col-md-9">
        <h4>References</h4>
      </div>
      <div className="col-md-3 mb-5">
        <h5>Add Reference</h5>
        <form className={styles.form}>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label htmlFor="referee_name">Referee Name</label>
              <input type="text" name="referee_name" />
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_mobile">Referee Mobile Number</label>
              <input type="number" name="referee_mobile" />
            </div>

            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_relationship">Relationship</label>
              <select name="referee_relationship"></select>
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_email">Email</label>
              <input type="email" name="referee_email" />
            </div>

            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_type">Referee Type</label>
              <select name="referee_type"></select>
            </div>
            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_document">Attach Document</label>
              <input type="file" name="referee_document" />
            </div>

            <div className="col-12 d-flex flex-column mt-2">
              <label htmlFor="referee_address">Address</label>
              <textarea name="referee_address"></textarea>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              size="large"
              onClick={(e) => e.preventDefault()}
              className="myBtn"
            >
              Add
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
