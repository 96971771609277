const initState = {
  message: null,
  status: null,
  spinner: false,
  errors: {},
  client_permissions: [],
  permissions_to_add: [],
  all_groups: {},
  single_group: [],
  all_employee: {},
  users_in_group: {},
  permission_in_group: [],
  system_banks: [],
};

const userReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CLEAR_PREFRENCE_ERRORS":
      return {
        ...state,
        message: null,
        status: null,
        errors: {},
      };
    case "USER_OFFLINE_PREFRENCE":
      return {
        ...state,
        message:
          "Seem you are offline! Kindly check your internet connection and try again.",
        status: "error",
      };
    case "START_PREFRENCE_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "STOP_PREFRENCE_SPINNER":
      return {
        ...state,
        spinner: false,
      };

    case "CLIENT_PERMISSIONS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        // permissions_to_add: [],
        client_permissions: payload.payload,
      };
    case "CLIENT_PERMISSIONS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "PERMISSIONS_IN_GROUP_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        // permissions_to_add: [],
        permission_in_group: payload.payload,
      };
    case "PERMISSIONS_IN_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "SINGLE_GROUP_SUCCESS": {
      let to_arry = [];

      const permissions = [...payload?.payload[0].permissions];
      permissions.forEach((permission) => {
        permission?.menus.forEach((menu) => {
          menu.permissions.forEach((perm) => {
            if (perm.active_on_role) {
              to_arry.push(perm.id);
            }
          });
        });
      });

      return {
        ...state,
        single_group: payload.payload,
        permissions_to_add: [...to_arry],
      };
    }
    case "SINGLE_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CLIENT_GROUP_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_groups: payload.payload,
      };
    case "CLIENT_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };

    case "FETCH_BANKS_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        system_banks: payload,
      };
    case "FETCH_BANKS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USERS_IN_GROUP_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        users_in_group: payload.payload,
      };
    case "USERS_IN_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ALL_EMPLOYEE_SUCCESS":
      return {
        ...state,
        // message: payload.message,
        // status: payload.status,
        all_groups: payload.payload,
      };
    case "ALL_EMPLOYEE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "DELETE_GROUP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "DELETE_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };

    case "ADD_GROUP_EMP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "ADD_GROUP_EMP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "REMOVE_GROUP_EMP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "REMOVE_GROUP_EMP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_GROUP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // client_permissions: payload.payload,
        permissions_to_add: [],
      };
    case "ADD_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "EDIT_GROUP_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // client_permissions: payload.payload,
        // permissions_to_add: [],
      };
    case "EDIT_GROUP_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "ADD_PERMISSION": {
      const permissions_to_add = [
        ...state.permissions_to_add,
        parseInt(payload),
      ];

      return {
        ...state,
        permissions_to_add: [...new Set(permissions_to_add)],
      };
    }
    case "REMOVE_PERMISSION": {
      let permissions_to_add = [...state.permissions_to_add];
      if (permissions_to_add.includes(parseInt(payload))) {
        permissions_to_add = permissions_to_add.filter(
          (item) => item !== parseInt(payload)
        );
      }

      return {
        ...state,
        permissions_to_add: [...new Set(permissions_to_add)],
      };
    }

    default:
      return state;
  }
};

export default userReducer;
