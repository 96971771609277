import React from "react";
import styles from "../../styles/DashboardCard.module.css";
import { BiExpand } from "react-icons/bi";

function DashboardCard({ children, title, icon, team, showModal }) {
  return (
    <div>
      <div className={styles.heading}>
        <h4>
          {icon} {title}
        </h4>
        {team?.length ? (
          <BiExpand
            className="pointer"
            onClick={showModal}
            style={{ color: "#fff" }}
          />
        ) : null}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
}

export default DashboardCard;
