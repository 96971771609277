import { NavLink, Route, Routes } from "react-router-dom";
import InductionHome from "./InductionHome";
import InductionTemplates from "./InductionTemplates";
import InsightsBar from "../InsightsBar";
import PageNotFound from "../../PageNotFound";

function Induction() {
  return (
    <div>
      <header
        className="onboarding-header d-flex align-items-center justify-content-between py-5"
        style={{ backgroundColor: "rgba(215, 226, 255, 1)", color: "black" }}
      >
        <div className="w-50">
          <small>Welcome to</small>
          <h2>Induction Stage</h2>
          <p>
            HR Managers and privileged administrators can view all your new
            hires, monitor their stages and manage their activities here.
          </p>
        </div>
        <img src="/assets/image 11 (2).png" alt="onboarding and induction" />
      </header>
      <nav style={{ backgroundColor: "rgba(215, 226, 255, 1)" }}>
        <ul className="onboarding-nav" style={{marginBottom: 0}}>
          <li>
            <NavLink
              to={""}
              className={({ isActive }) => (isActive ? "active" : null)}
              end
              style={{color: "var(--clr-primary)"}}
            >
              Show All
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"templates"}
              className={({ isActive }) => (isActive ? "active" : null)}
              style={{color: "var(--clr-primary)"}}
            >
              Manage Templates
            </NavLink>
          </li>
        </ul>
      </nav>
      <InsightsBar />
      <Routes>
        <Route index element={<InductionHome />} />
        <Route path="templates" element={<InductionTemplates />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default Induction;
