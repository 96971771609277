import { NavLink, Route, Routes } from "react-router-dom";
import { useGetSystemSettingsUtils } from "../../../util/usershookActions";
import { useEffect, useState } from "react";
import { useShallowEqualSelector } from "../../../util/hooks";
import { settings_util } from "../../../util/selectors/userSelectors";
import AllUploads from "./AllUploads";
import CreateUpload from "./CreateUpload";

function Setup() {
  // useUsersNotification();
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState([]);

  useGetSystemSettingsUtils(enabled, setEnabled);
  const settingsUtil = useShallowEqualSelector(settings_util);

  useEffect(() => {
    if (settingsUtil?.others?.Onboarding?.length) {
      setData([...settingsUtil?.others.Onboarding]);
    }
  }, [settingsUtil]);

  return (
    <div>
      <header className="onboarding-header d-flex align-items-center justify-content-between">
        <h2>Manage your Onboarding Uploads</h2>
      </header>
      <nav style={{ backgroundColor: "var(--clr-primary)" }}>
        <ul className="onboarding-nav" style={{ marginBottom: 0 }}>
          <li>
            <NavLink
              to={""}
              className={({ isActive }) => (isActive ? "active" : null)}
              end
            >
              Show All
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"uploads"}
              className={({ isActive }) => (isActive ? "active" : null)}
            >
              Manage Uploads
            </NavLink>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route index element={<AllUploads data={data} />} />
        <Route path="uploads" element={<CreateUpload />} />
      </Routes>
    </div>
  );
}

export default Setup;
