import React from "react";
import General from "./General";
import "../../../styles/settings.css";
import Notifications from "./Notifications";
import Theme from "./Theme";
import Approvals from "./Approvals";
import FileAccess from "./FileAccess";
import AppLogo from "./AppLogo";
// import IDCardTemplate from "./IDCardTemplate";
import CompanyDetails from "./CompanyDetails";
import PasswordSettings from "./PasswordSettings";
import Department from "./department";
import Holiday from "./holiday";
import Anniversary from "./anniversaries";
import RoleDesignation from "./designation-role";
import Banks from "./banks";
import EventType from "./event_types";
import HocHeader from "./../../ui/HocHeader";
import SystemHr from "./system-hr";

export default function Settings() {
  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Settings"
          module_description="Manage the requests of employees for modifications to their data."
          module_img="/assets/landing-page/Human Resource Management.png"
        />
      </div>
      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <div className=" app__section ">
            <div className="row py-3">
              <div className="col-md-6 d-flex flex-column gap-4 py-3">
                <General />
                <Notifications />
                <Theme />
                <CompanyDetails />
                <Banks />
                <Holiday />
              </div>
              <div className="col-md-6 d-flex flex-column gap-4 py-3">
                <Approvals />
                {/* <FileAccess /> */}
                <AppLogo />
                <PasswordSettings />
                <EventType />
                <SystemHr />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
