import AddBranch from "./AddBranch";
import AddCompany from "./AddCompany";
import HocHeader from "../../ui/HocHeader";
import { Tabs } from "antd";
import CanAccess from "../../hoc/CanAccess";

export default function Company() {
  const items = [
    {
      key: "1",
      label: <h5>Company</h5>,
      children: (
        <CanAccess can_access={"view-menu-hris-view-companies"} isRoute={false}>
          <AddCompany />
        </CanAccess>
      ),
    },
    {
      key: "2",
      label: <h5>Branch</h5>,
      children: (
        <CanAccess can_access={"view-menu-hris-view-branches"} isRoute={false}>
          <AddBranch />
        </CanAccess>
      ),
    },
  ];

  return (
    <>
      <HocHeader
        module_title="Human Resource Information System"
        module_class="performance-header"
        module_name="Company"
        module_description="Add more companies under your company and Add branches to them if available."
        module_img="/assets/landing-page/Human Resource Management.png"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3 ">
          <section className="app__section py-3 mb-4">
            <Tabs items={items} />
          </section>
        </div>
      </main>
    </>
  );
}
