import React, { useEffect, useState, useMemo } from "react";

import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import {
  Button,
  Space,
  Table,
  Input,
  Pagination,
  Modal,
  DatePicker,
} from "antd";
import {
  useGetDepartmentOnLeave,
  useGetUserProfile,
} from "../../../util/usershookActions";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import { AiOutlineSearch } from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import { useDispatch } from "react-redux";
import {
  recallLeaveAction,
  approveRejectAction,
} from "../../../redux/users/userActions";
import { user_profile } from "../../../util/selectors/userSelectors";
import dayjs from "dayjs";
import { FaFileAlt } from "react-icons/fa";

export default function TeamOnLeave() {
  useUserCleanUp();
  const dispatch = useDispatch();
  const profile = useShallowEqualSelector(user_profile);
  const [show_record, setShow_record] = useState("");
  const [len_data, setLenData] = useState(0);
  // console.log({ profile });

  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        console.log({ fir: record[dataIndex], sec: value });
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "S/N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Leave type",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type", "Leave type"),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      ...getColumnSearchProps("duration", "Duration"),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Used Days",
      dataIndex: "get_used_leave_days",
      key: "get_used_leave_days",
      ...getColumnSearchProps("get_used_leave_days", "Used Days"),
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      render: (value) => {
        return value ? value : "N/A";
      },
    },
    {
      title: "Backup employee",
      dataIndex: "backup_employee",
      key: "backup_employee",
      ...getColumnSearchProps("backup_employee", "Backup employee"),
    },
    // {
    //   title: "Attachment",
    //   dataIndex: "attachment",
    //   key: "attachment",
    //   render: (value) => {
    //     return <EachAttachment value={value} />;
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (value) => (
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 ${
            value === "Approved"
              ? "bg-success"
              : value === "Rejected"
              ? "bg-danger"
              : "bg-warning"
          }`}
        >
          {value}
        </Button>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, record) => (
        <EachAction
          record={record}
          recallLeave={recallLeave}
          refetch={refetch}
          setShow_record={setShow_record}
        />
      ),
    },
  ];
  const [enabled_req, setEnabledReq] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [page_req, setPageReq] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [req_show, setReqShow] = useState([]);

  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const [dept_id, setDept_id] = useState(null);

  const {
    data: req,
    isLoading,
    refetch,
  } = useGetDepartmentOnLeave(
    enabled_req,
    setEnabledReq,
    page_req,
    from,
    to,
    dept_id
  );
  useGetUserProfile(enabled, setEnabled);

  const req_data = useMemo(() => req?.payload, [req]);

  useEffect(() => {
    if (req_data?.length) {
      const new_arry = req_data.map((re, ind) => {
        return {
          ...re,
          key: re.user_id,
          index: ind + 1,
          name: re.user
            ? `${re.user?.first_name || ""} ${re.user?.last_name || ""} [${
                re.user?.employee_id
              }]`
            : "N/A",
          backup_employee: re.backup_employee
            ? `${re.backup_employee?.first_name || ""} ${
                re.backup_employee?.last_name || ""
              } [${re.backup_employee?.employee_id}]`
            : "N/A",
          status:
            re.status === "PENDING"
              ? `Awaiting ${re?.awaiting?.actor?.toLowerCase()}`
              : re.status,
        };
      });

      setReqShow(new_arry);
      setLenData(new_arry?.length);
    } else {
      setReqShow([]);
    }
  }, [req_data, req]);

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      setDept_id(profile?.personal?.department?.id);
      setEnabledReq(true);
    }
  }, [profile]);

  function handlePaginationEmp(page) {
    setPageReq(page);
    setEnabledReq(true);
  }

  function recallLeave(creds) {
    // console.log(creds.id);

    return recallLeaveAction(dispatch, creds);
  }

  function ApproveregetRequest(creds) {
    // console.log(creds);

    return approveRejectAction(dispatch, creds);
  }

  const onStart = (date) => {
    console.log({ date });
    console.log(dayjs(date.Sd).format("YYYY-MM-DD"));
    setDates(() => ({
      ...dates,
      from: dayjs(date?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ from });
  };
  const onEnd = (date_end) => {
    console.log({ date_end });
    setDates(() => ({
      ...dates,
      to: dayjs(date_end?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ to, from });
  };
  useEffect(() => {
    if (dates.from && dates.to) {
      setFrom(dates.from);
      setTo(dates.to);
    }
  }, [dates]);

  useEffect(() => {
    if (from && to) {
      setEnabledReq(true);
    } else {
      setEnabledReq(false);
    }
    console.log({ to, from });
  }, [from, to]);

  function handleTable(
    pagination,
    filters,
    sorter,
    { currentDataSource, action }
  ) {
    if (action === "filter") {
      console.log({ currentDataSource });
      setLenData(currentDataSource?.length);
    }
  }

  return (
    <>
      {Object.keys(show_record).length ? (
        <LeaveApproval
          record={show_record}
          ApproveregetRequest={ApproveregetRequest}
          refetch={refetch}
          setShow_record={setShow_record}
        />
      ) : (
        <>
          <div className="container_fluid">
            <section className="app__section py-2">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  padding: "2rem",
                }}
              >
                <div>
                  <h5 className="mb-0">Team member(s) on leave</h5>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "1rem",
                }}
              >
                <div className="d-flex gap-3 align-items-center justify-content-between">
                  <div className="d-flex align-items-center ">
                    <div className="circle__bagde fw-bold">{len_data}</div>
                    <h6 className="mb-0 ms-2" style={{ width: "300px" }}>
                      Leave request
                    </h6>
                  </div>

                  <div
                    className="mb-2 text-end d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <DatePicker
                      onChange={onStart}
                      className="me-2"
                      allowClear={true}
                    />
                    <DatePicker onChange={onEnd} allowClear={true} />
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{
                  padding: "1rem",
                }}
              >
                {isLoading ? (
                  <SkeletonUI number={2} />
                ) : req_show.length ? (
                  <>
                    <Table
                      columns={columns}
                      dataSource={req_show}
                      bordered
                      scroll={{ x: "768px" }}
                      pagination={false}
                      onChange={handleTable}
                    />

                    <div className="mt-3 d-flex justify-content-end">
                      {req?.payload?.meta?.last_page > 1 ? (
                        <Pagination
                          total={req?.payload?.meta?.last_page}
                          pageSize={1}
                          onChange={handlePaginationEmp}
                        />
                      ) : null}
                    </div>
                  </>
                ) : (
                  <NoContent
                    width={"400px"}
                    message="No team member currently on leave"
                  />
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

function EachAttachment({ value }) {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  return (
    <>
      {value ? (
        <Button type="default" onClick={handleModal}>
          View
        </Button>
      ) : (
        "N/A"
      )}
      <Modal open={showModal} footer={null} onCancel={handleModal}>
        <img src={value} alt={"Attachments picture"} className="rpt_img" />
        {/* <div className="d-flex justify-content-end">
          <Button className="myBtn">Download</Button>
        </div> */}
      </Modal>
    </>
  );
}

function EachAction({ record, refetch, setShow_record, recallLeave }) {
  const [loading_nugde, setLoadingNudge] = useState(false);
  const handleView = () => {
    setShow_record(record);
  };

  function confirmRequestNudge() {
    Modal.confirm({
      title: `Recall Leave`,
      content: "Do you want to perform this action?",
      onOk: okNugde,
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function okNugde() {
    setLoadingNudge(true);
    const creds = {
      id: record.user.id,
      batch: record.batch,
    };

    recallLeave(creds).then((res) => {
      setLoadingNudge(false);
      if (res?.status === "success") {
        refetch();
      }
    });
  }

  return (
    <>
      <Space wrap>
        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-success`}
          onClick={handleView}
        >
          View
        </Button>

        <Button
          className={`rounded p-1 px-2 border border-1 text-white me-1 bg-secondary`}
          onClick={confirmRequestNudge}
          loading={loading_nugde}
        >
          Recall Leave
        </Button>
      </Space>
    </>
  );
}

function LeaveApproval({
  record,
  ApproveregetRequest,
  refetch,
  setShow_record,
}) {
  const [showModalApp, setShowModalApp] = useState(false);
  const [showModalReg, setShowModalReg] = useState(false);
  const [loading_app, setLoadingApp] = useState(false);
  const [loading_reg, setLoadingReg] = useState(false);

  const [comment, setComment] = useState("");
  const [comment_reg, setCommentReg] = useState(false);

  const handleCancel = () => {
    setShow_record("");
  };

  function handleApprove(e) {
    console.log({ e });
    e.preventDefault();
    setLoadingApp(true);
    const creds = {
      id: record.batch,
      comment: comment,
      action: "APPROVED",
      // batch: Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    };
    ApproveregetRequest(creds).then((res) => {
      console.log(res);
      setLoadingApp(false);

      if (res?.status === "success") {
        refetch();
        handleModalApp();
      }
    });
  }
  function handleReject(e) {
    e.preventDefault();
    setLoadingReg(true);
    const creds = {
      id: record.batch,
      comment: comment_reg,
      action: "REJECTED",
      // batch: Date.now().toString(36) + Math.random().toString(36).substring(2, 10),
    };
    ApproveregetRequest(creds).then((res) => {
      console.log(res);
      setLoadingReg(false);

      if (res?.status === "success") {
        refetch();
        handleModalReg();
      }
    });
  }
  const handleModalApp = () => {
    setShowModalApp(!showModalApp);
    setComment("");
  };

  const handleModalReg = () => {
    setShowModalReg(!showModalReg);
    setCommentReg("");
  };

  const {
    user: { first_name, last_name, employee_id },
    backup_employee,
    comment: leaveComment,
    start_date,
    end_date,
    attachment,
  } = record;
  return (
    <main className="w-50 mx-auto my-5 border border-1 shadow-lg p-4 px-5">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Leave Approval</h2>
        <span className="fs-3" onClick={handleCancel}>
          <FcCancel />
        </span>
      </div>
      <hr />
      <section className="my-4 d-flex justify-content-between align-items-center">
        <aside>
          <h4 className="text-decoration-underline mb-2">User</h4>
          <div className="my-2">
            <p className="fw-bold">Employee name:</p>
            <p className="fw-light">
              {first_name} {last_name}
            </p>
          </div>
          <div className="mt-2">
            <p className="fw-bold">Employee ID:</p>
            <p className="fw-light">{employee_id}</p>
          </div>
        </aside>
        <aside style={{ width: "130px", height: "130px" }}>
          <img
            src="/assets/female.png"
            alt="leaveimage"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </aside>
      </section>
      <section className="my-3">
        <h4 className="text-decoration-underline mb-2">Leave Details</h4>
        <div className="mt-2">
          <p className="fw-bold">Backup employee</p>
          <p className="fw-light">{backup_employee}</p>
        </div>
        <div className="mt-2">
          <p className="fw-bold">Reason for leave</p>
          <p className="fw-light">{leaveComment || "N/A"}</p>
        </div>
        <div className="mt-2">
          <p className="fw-bold">Date of leave</p>
          <p className="fw-light">
            {start_date} to {end_date}
          </p>
        </div>
      </section>
      <section className="my-4 ">
        <h4 className="text-decoration-underline mb-2">Attachments</h4>
        {attachment ? (
          <aside className="d-flex justify-content-between w-100">
            {/* {
          attachment.map(file => {
            return(
              <>
              <div className="d-flex align-items-center">
                <span className="me-1"><FaFileAlt/></span>
                <a href={file.name} download> {file.name}</a>
              </div>
              
              </>
            )
          })
        } */}
            {
              <div className="d-flex align-items-center flex-column">
                <span className="me-1">
                  <FaFileAlt />
                </span>
                <a href={attachment} target="_blank" download>
                  {" "}
                  link/filedocument
                </a>
              </div>
            }
          </aside>
        ) : (
          <p className="fw-light">No attachment</p>
        )}
      </section>
      {/* <section className="w-100 mx-auto d-flex justify-content-center mb-3">
        <Space wrap className="">
          <Button
            className={`rounded border border-1 text-white text-center me-3 bg-success`}
            onClick={handleModalApp}
            style={{ width: "150px", height: "40px" }}
          >
            Approve
          </Button>
          <Button
            className={`rounded border border-1 text-white text-center bg-danger`}
            onClick={handleModalReg}
            style={{ width: "150px", height: "40px" }}
          >
            Reject
          </Button>
        </Space>
      </section> */}
      <>
        <Modal
          open={showModalApp}
          footer={null}
          onCancel={handleModalApp}
          maskClosable={false}
        >
          <h5>Approve Request</h5>
          <hr />

          <form className="performance-form" onSubmit={handleApprove}>
            <label>
              {" "}
              Comment <span className="text-danger"> *</span> :
            </label>
            <textarea
              onChange={(e) => {
                setComment(e.target.value);
              }}
              value={comment}
            />
            <div className="d-flex justify-content-end  mt-3 ">
              <Button
                className="myBtn"
                // disabled={comment.length ? false : true}
                loading={loading_app}
                htmlType="submit"
              >
                {" "}
                Approve{" "}
              </Button>
            </div>
          </form>
        </Modal>
        <Modal
          open={showModalReg}
          footer={null}
          onCancel={handleModalReg}
          maskClosable={false}
        >
          <h5>Reject Request</h5>
          <hr />

          <form className="performance-form" onSubmit={handleReject}>
            <label>
              {" "}
              Comment <span className="text-danger"> *</span> :
            </label>
            <textarea
              onChange={(e) => {
                setCommentReg(e.target.value);
              }}
              value={comment_reg}
            />
            <div className="d-flex justify-content-end  mt-3 ">
              <Button
                className="myBtn"
                disabled={comment_reg.length ? false : true}
                loading={loading_reg}
                htmlType="submit"
              >
                {" "}
                Reject{" "}
              </Button>
            </div>
          </form>
        </Modal>
      </>
    </main>
  );
}
