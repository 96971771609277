import { useState, useEffect, useMemo } from "react";

import { Button, Space, Table, Input, Pagination, QRCode } from "antd";

import TemplateA from "../../hrms/id-card/id-templates/TemplateA";
import HocHeader from "../../ui/HocHeader";
import { useGetIDCardApproved } from "../../../util/usershookActions";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { useScript, useUserCleanUp } from "../../../util/hooks";
import { AiOutlineQrcode, AiOutlineSearch } from "react-icons/ai";
import "../../../styles/id.css";
// import TemplateA from "./id-templates/TemplateA";

function PrintIDCard() {
  useUserCleanUp();
  const [checked, setChecked] = useState(false);
  const { loading, error } = useScript("/html2pdf/dist/html2pdf.bundle.js");

  console.log({ test: window.html2pdf, loading: loading, error: error });
  function getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${title}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const [enabled_req, setEnabledReq] = useState(true);
  const [page_req, setPageReq] = useState(1);
  const [req_show, setReqShow] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState({});
  const { data: req, isLoading } = useGetIDCardApproved(
    enabled_req,
    setEnabledReq,
    page_req
  );

  const req_data = useMemo(() => req?.payload?.data, [req]);
  console.log({ req_show });

  useEffect(() => {
    if (req_data?.length) {
      const new_arry = req_data.map((re, ind) => {
        return {
          ...re,
          key: re.user_id,
          index: ind + 1,
        };
      });

      setReqShow(new_arry);
    } else {
      setReqShow([]);
    }
  }, [req_data]);

  function handlePaginationEmp(page) {
    setPageReq(page);
    setEnabledReq(true);
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      key: "index",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value ? value : "N/A";
      },

      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
      render: (value) => {
        return value ? value : "N/A";
      },
      ...getColumnSearchProps("employee_id", "Employee ID"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (value) => {
        return value ? value : "N/A";
      },
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <Button onClick={() => setSelectedEmp(record)}>Generate</Button>
      ),
    },
  ];

  // console.log({ selectedEmp });

  function downloadIdCard() {
    const invoice = document.getElementById("id__card");
    const opt = {
      html2canvas: { scale: 4 },
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "cm", format: [8, 5], orientation: "l" },
    };

    window.html2pdf().from(invoice).set(opt).save();
  }
  return (
    <>
      <div>
        <HocHeader
          module_class="performance-header"
          module_name="Print ID Card"
          module_title="(ID) Identity Card Management"
          module_description="lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip"
        />
      </div>
      <div className="container-fluid py-3 padding_3rem">
        <section className="app__section mt-3 py-4">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "2rem",
            }}
          >
            <div>
              <h5 className="mb-0">Available approved requests</h5>
              {/* <small className="w-75 d-none d-sm-block">
                Every employee belongs to at least one job role/grade. You can
                add and view all job roles with other details like gross salary
                range, title, benefits and category.
              </small> */}
            </div>
            {/* <Link to={"add"}>
            <p className="performance__bagde2">Add Job Role/Grade</p>
          </Link> */}
          </div>

          <div
            className="row my-4"
            style={{
              padding: "1rem",
            }}
          >
            <div className="d-flex gap-3 align-items-center">
              <div className="circle__bagde fw-bold">{req_show.length}</div>
              <h6 className="mb-0"> Requests</h6>
            </div>
          </div>

          {isLoading ? (
            <SkeletonUI number={2} />
          ) : req_show.length ? (
            <div className="row">
              <div className="col-md-8">
                {/* <h3 className="h4 mb-3">Approved Employees</h3> */}
                <Table
                  columns={columns}
                  dataSource={req_show}
                  bordered
                  scroll={{ x: "768px" }}
                  pagination={false}
                />

                <div className="mt-3 d-flex justify-content-end">
                  {req?.payload?.meta?.last_page > 1 ? (
                    <Pagination
                      total={req?.payload?.meta?.last_page}
                      pageSize={1}
                      onChange={handlePaginationEmp}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <h3
                  className="h4 mb-3 text-center w-100 py-3"
                  style={{
                    backgroundColor: "var(--clr-primary",
                    color: "white",
                  }}
                >
                  Preview{" "}
                  {Object.keys(selectedEmp).length
                    ? `for ${selectedEmp?.employee_id}`
                    : ""}{" "}
                </h3>

                <div className="text-end d-flex align-items-center justify-content-end radio_qr w-100">
                  <input
                    type="checkbox"
                    id="Qrcode"
                    name="Qrcode"
                    value={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <label for="Qrcode" className="ms-2 qr_label">
                    Attach Qr code{" "}
                    <span>
                      <AiOutlineQrcode />
                    </span>
                  </label>
                </div>
                <div id="id__card mx-auto w-100 border border-2">
                  <TemplateA selectedEmp={selectedEmp} checked={checked} />
                </div>
                {Object.keys(selectedEmp).length ? (
                  <Button
                    className="mt-3 w-50 mx-auto d-flex justify-content-center text-white align-items-center"
                    onClick={downloadIdCard}
                    style={{ backgroundColor: "var(--clr-primary", height: 45 }}
                  >
                    {/* Print */}
                    Re-issue
                  </Button>
                ) : null}
              </div>
            </div>
          ) : (
            <NoContent
              width={"400px"}
              message="No approved request available"
            />
          )}
        </section>
      </div>
    </>
  );
}

export default PrintIDCard;
