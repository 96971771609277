import React, { useState, useEffect } from "react";
import { Input, Space, Table, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { useGetAllBenefitsInKind } from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { all_benefits } from "../../../util/selectors/userSelectors";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { deleteBenefits } from "../../../redux/users/userActions";

function AvailableBenefits() {
  useUserCleanUp();
  const [search] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [benefits_data, setBenefitData] = useState([]);
  const [list_count, setListCount] = useState(0);

  const { isLoading, refetch } = useGetAllBenefitsInKind(
    enabled,
    setEnabled,
    search
  );

  const allBenefits = useShallowEqualSelector(all_benefits);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter column`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }

  const columns = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      width: 100,
    },
    {
      title: "Benefit",
      dataIndex: "benefit",
      key: "benefit",
      render: (value, record) => (
        <>
          <Link to={`view/${record.id}`} style={{ fontWeight: "bold" }}>
            {value}
          </Link>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Filter Benefits"
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record.benefit.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value) => (
        <>
          {/* <Link to={`view/${value}`} style={{ fontWeight: "bold" }}> */}
          {value}
          {/* </Link> */}
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Filter Benefits"
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record.value.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value) => {
        const reduced =
          value?.length > 200 ? `${value?.substring(0, 200)}...` : value;

        return reduced || "N/A";
      },
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      width: 120,
      ...getColumnSearchProps("type"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (value) => {
        return value ? (
          <p className="text-success">Active</p>
        ) : (
          <p className="text-danger">Inactive</p>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space wrap>
          <Link to={`view/${record.id}`}>
            <Button type="primary">View</Button>
          </Link>
          <Link to={`edit/${record.id}`}>
            <Button type="default">Edit</Button>
          </Link>
          <DeleteAction record={record} refetch={refetch} />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (allBenefits?.length) {
      const modData = allBenefits?.map((item, index) => ({
        ...item,
        key: index + 1,
      }));
      setBenefitData(modData);
      setListCount(modData.length);
    }
  }, [allBenefits]);
  function handleTable(
    pagination,
    filters,
    sorter,
    { currentDataSource, action }
  ) {
    if (action === "filter") {
      setListCount(currentDataSource?.length);
    }
  }
  return (
    <>
      <section className="app__section py-4">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            padding: "1rem",
          }}
        >
          <div>
            <h5 className="mb-0">Available benefits</h5>
            <small className=" d-none d-sm-block">
              All benefits-in-kind profiled and available for job roles or
              grades.
            </small>
          </div>
          <Link to={"add"}>
            <p className="performance__bagde2">Add Benefit</p>
          </Link>
        </div>
        <div
          className="row my-4"
          style={{
            padding: "1rem",
          }}
        >
          <div className="d-flex gap-3 align-items-center">
            <div className="circle__bagde fw-bold">{list_count}</div>
            <h6 className="mb-0"> Benefits</h6>
          </div>
        </div>

        <div
          className="row"
          style={{
            padding: "1rem",
          }}
        >
          {isLoading ? (
            <SkeletonUI number={2} />
          ) : benefits_data.length ? (
            <Table
              columns={columns}
              dataSource={benefits_data}
              bordered
              scroll={{ x: "768px" }}
              pagination={benefits_data.length > 10 ? true : false}
              onChange={handleTable}
            />
          ) : (
            // <NoContent message="No available benefits yet" width={"400px"} />
            <NoContent width={"400px"} />
            // <p>No data yet</p>
          )}
        </div>
      </section>
    </>
  );
}

function DeleteAction({ record, refetch }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function confirmDelete(e, benefit) {
    Modal.confirm({
      title: "Delete benefit",
      content: "Do you want to perform this action?",
      onOk: () => deleteBenefitfunc(benefit),
      centered: true,
      closable: true,
      cancelText: "No",
      okText: "Yes",
    });
  }

  function deleteBenefitfunc(benefit) {
    setLoading(true);
    deleteBenefits(dispatch, { benefit }).then((res) => {
      setLoading(false);
      refetch();
    });

    Modal.destroyAll();
  }

  return (
    <Button
      type="primary"
      onClick={(e) => confirmDelete(e, record.id)}
      loading={loading}
      danger
    >
      Delete
    </Button>
  );
}

export default AvailableBenefits;
