import "antd/dist/reset.css";
import { ThreeDots } from "react-loader-spinner";
import { getURLSubdomain } from "./util/helpers";
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import Reset from "./components/auth/Reset";
import Setup from "./components/auth/Setup";
import AlreadyAuth from "./components/hoc/AlreadyAuth";
import Dashboard from "./components/dashboard";
import HRMS from "./components/hrms";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  company_details,
  company_url,
  user_is_Loggedin,
  user_message,
  user_status,
} from "./util/selectors/userSelectors";
import { useOnlineStatus, useShallowEqualSelector } from "./util/hooks";
import { message, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useGetCompanyDetails } from "./util/usershookActions";
import Permissions from "./components/permissions";
import Layout from "./components/ui/Layout";
import SubscriptionsPage from "./components/subscriptions";
import IdentityCardManagement from "./components/identity-card-management";
import Onboarding from "./components/onboarding";
import Prefill from "./components/onboarding/newHire/Prefill";
import ProvisionalOffer from "./components/onboarding/newHire/ProvisionalOffer";
import PageNotFound from "./components/PageNotFound";
import TrackHire from "./components/onboarding/newHire/TrackHire";
import Performance from "./components/Performance";
import Benefits from "./components/compensations-benefits";
import LeaveManagement from "./components/leave-management";
import FullListEmpGrid from "./components/dashboard/FullListEmpGrid";
import SubscriptionLayout from "./components/ui/SubscriptionLayout";

import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import Overview from "./components/Performance/Overview";
import Planning from "./components/Performance/planning";
import HRMOverview from "./components/hrms/dashboard";
import Company from "./components/hrms/company";
import HRMEmployee from "./components/hrms/employees";
import AllEmployees from "./components/hrms/employees/AllEmployees";
import NewEmployee from "./components/hrms/employees/new-employee";
import UploadEmployees from "./components/hrms/employees/UploadEmployees";
import ViewEmployee from "./components/hrms/employees/new-employee-action";
import JobRoleList from "./components/hrms/job-role/JobRoleList";
import UploadJobRole from "./components/hrms/job-role/UploadJobRole";
import HRMJobRole from "./components/hrms/job-role";
import DepartmentList from "./components/hrms/department/DepartmentList";
import UploadDepartment from "./components/hrms/department/UploadDepartment";
import HRMDepartment from "./components/hrms/department";
import GradesList from "./components/hrms/system-grades/GradesList";
import UploadGrades from "./components/hrms/system-grades/UploadGrades";
import HRMGrades from "./components/hrms/system-grades/index";
import AllSystemStep from "./components/hrms/systemStep/allSystemStep";
import UploadSystemStep from "./components/hrms/systemStep/uploadSystemStep";
import HRMSystemSteps from "./components/hrms/systemStep";
import Settings from "./components/hrms/settings";
import ApprovalPending from "./components/hrms/approvals/ApprovalPending";
import HRMApproval from "./components/hrms/approvals";
import DefaultApproval from "./components/hrms/approvals/default-approvals";
import PendingApprovalTable from "./components/hrms/approvals/pending-approvals";
import ApprovalHistory from "./components/hrms/approvals/history";
import ApprovePersonal from "./components/hrms/approvals/categories/ApprovePersonal";
import ApproveMedical from "./components/hrms/approvals/categories/ApproveMedical";
import ApproveContact from "./components/hrms/approvals/categories/ApproveContact";
import ApproveDependant from "./components/hrms/approvals/categories/ApproveDependant";
import ApproveNOK from "./components/hrms/approvals/categories/ApproveNOK";
import ApproveIdentity from "./components/hrms/approvals/categories/ApproveIdentity";
import ApproveEduHistory from "./components/hrms/approvals/categories/ApproveEduHistory";
import ApproveEmploymentHis from "./components/hrms/approvals/categories/ApproveEmploymentHis";
import ApproveDocument from "./components/hrms/approvals/categories/ApproveDocument";
import ApproveFinancials from "./components/hrms/approvals/categories/ApproveFinancials";
import PersonalHistory from "./components/hrms/approvals/history/history-categories/PersonalHistory";
import MedicalHistory from "./components/hrms/approvals/history/history-categories/MedicalHistory";
import ContactHistory from "./components/hrms/approvals/history/history-categories/ContactHistory";
import DependantHistory from "./components/hrms/approvals/history/history-categories/DependantHistory";
import NOKHistory from "./components/hrms/approvals/history/history-categories/NOKHistory";
import IdentityHistory from "./components/hrms/approvals/history/history-categories/IdentityHistory";
import EduHistory from "./components/hrms/approvals/history/history-categories/EduHistory";
import EmploymentHistory from "./components/hrms/approvals/history/history-categories/EmploymentHistory";
import DocumentHistory from "./components/hrms/approvals/history/history-categories/DocumentHistory";
import LeaveOverview from "./components/leave-management/overview";
import LeaveGroup from "./components/leave-management/leave-group";
import GroupList from "./components/leave-management/leave-group/GroupList";
import UploadGroup from "./components/leave-management/leave-group/UploadGroup";
import LeaveGrade from "./components/leave-management/leave-distribution";
import GradeLeaveDistributionList from "./components/leave-management/leave-distribution/GradeLeaveDistributionList";
import UploadDistribution from "./components/leave-management/leave-distribution/UploadDistribution";
import TeamHistoryHR from "./components/leave-management/hr/TeamHistoryHR";
import TeamLeavePlanHr from "./components/leave-management/teamLeavePlan/teamLeavePlan";
import LeaveHr from "./components/leave-management/hr";
import RequestForEmployee from "./components/leave-management/requestForEmployee/requestForEmployee";
import RequestForEmployeeTeam from "./components/leave-management/requestForEmployeeTeam/requestForEmployeeTeam";
import TeamHistory from "./components/leave-management/line-manager/TeamHistory";
import LeaveTeam from "./components/leave-management/line-manager";
import TeamLeavePlanTeam from "./components/leave-management/teamLeavePlanTeam/teamLeavePlanTeam";
import CompensationOverview from "./components/compensations-benefits/Overview";
import BenefitList from "./components/compensations-benefits/benefits-list";
import AvailableBenefits from "./components/compensations-benefits/benefits-list/AvailableBenefits";
import AddBenefits from "./components/compensations-benefits/benefits-list/AddBenefits";
import EditBenefits from "./components/compensations-benefits/benefits-list/EditBenefits";
import ViewBenefits from "./components/compensations-benefits/benefits-list/ViewBenefits";
import GradedGrades from "./components/compensations-benefits/pay-grades/GradedGrades";
import PayGrades from "./components/compensations-benefits/pay-grades";
import GradeWithBand from "./components/compensations-benefits/pay-grades/GradeWithBand";
import GradeWithoutNotches from "./components/compensations-benefits/pay-grades/GradeWithoutNotches";
import PayStructure from "./components/compensations-benefits/pay-structures/PayStructure";
import CompesationJobGrading from "./components/compensations-benefits/job-grade";
import AvailableJobs from "./components/compensations-benefits/job-grade/AvailableJobs";
import AddJobGrades from "./components/compensations-benefits/job-grade/AddJobGrades";
import EditJobGreades from "./components/compensations-benefits/job-grade/EditJobGreades";
import ViewJobGreades from "./components/compensations-benefits/job-grade/ViewJobGreades";
import CompesationPayStructures from "./components/compensations-benefits/pay-structures";
import Salary from "./components/compensations-benefits/salary/index";
import CompesationPayRoll from "./components/compensations-benefits/pay-roll";
import SalaryComponent from "./components/compensations-benefits/salary/SalaryComponent";
import EditSalaryComponent from "./components/compensations-benefits/salary/EditSalaryComponent";
import OtherItemsEdit from "./components/compensations-benefits/salary/OtherItemsEdit";
import CompesationSettings from "./components/compensations-benefits/Settings";
import CreateGroup from "./components/permissions/CreateGroup";
import Welcome from "./components/permissions/Welcome";
import AssignGroup from "./components/permissions/AssignGroup";
import ViewGroups from "./components/permissions/ViewGroups";
import EditGroup from "./components/permissions/EditGroup";
import Group from "./components/permissions/Group";
import ProfilePage from "./components/employee/profile";
import EditProfile from "./components/employee/profile/EditProfile";
import NOK from "./components/employee/profile/NOK";
import EducationalDetails from "./components/employee/profile/EducationalDetails";
import MedicalPage from "./components/employee/profile/MedicalPage";
import FinancialPage from "./components/employee/profile/FinancialPage";
import Dependants from "./components/employee/profile/Dependants";
import EmploymentDetails from "./components/employee/profile/EmploymentDetails";
import References from "./components/employee/profile/References";
import Documents from "./components/employee/profile/Documents";
import LeaveActions from "./components/leave-management/leave";
import IdCardPage from "./components/employee/id-card/index";
import LoanPage from "./components/employee/loan";
import AppraisalPage from "./components/employee/appraisal";
import NewLeaveRequest from "./components/leave-management/leave/newLeaveRequest";
import LeaveHistory from "./components/leave-management/leave/leaveHistory";
import AnnualLeave from "./components/leave-management/leave/annualLeave";
import TeamAnnualLeave from "./components/leave-management/leave/teamAnnualLeave";
import TeamLeavePlan from "./components/leave-management/leave/teamLeavePlan";
import MyIDCard from "./components/employee/id-card/MyIDCard";
import ReplacementRequest from "./components/employee/id-card/ReplacementRequest";
import RequestHistory from "./components/employee/id-card/RequestHistory";
import MyIDCardPage from "./components/employee/id-card/MyIDCardPage";
import GoalSettings from "./components/Performance/planning/goal-settings";
import ObjectiveSettings from "./components/Performance/planning/objective-settings";
import KPISettings from "./components/Performance/planning/kpi-settings";
import TargetSettings from "./components/Performance/planning/targets";
import PlanningApproval from "./components/Performance/planning/approvals";
import MonthlyApprovalList from "./components/Performance/planning/approvals/MonthlyApprovalList";
import QuarterlyApprovalList from "./components/Performance/planning/approvals/QuarterlyApprovalList";
import HalfYearApprovalList from "./components/Performance/planning/approvals/HalfYearApprovalList";
import PlanningHistory from "./components/Performance/planning/history";
import PerformanceMonitoring from "./components/Performance/monitoring";
import PerformanceAppraisals from "./components/Performance/appraisals";
import PerformanceReports from "./components/Performance/reports";
import IndividualScoreCard from "./components/Performance/monitoring/individual-score-cards";
import DepartmentScoreCard from "./components/Performance/monitoring/department-score-card";
import OrganizationScoreCard from "./components/Performance/monitoring/organization-score-card";
import CreatePerformanceAppraisal from "./components/Performance/appraisals/create-appraisal";
import ManageTeamAppraisal from "./components/Performance/appraisals/manage-team-appraisal";
import SelectTeamMember from "./components/Performance/appraisals/manage-team-appraisal/SelectTeamMember";
import ManageTeamMember from "./components/Performance/appraisals/manage-team-appraisal/ManageTeamMember";
import AppraisalSettings from "./components/Performance/appraisals/appraisal-settings";
import RestartAppraisal from "./components/Performance/appraisals/restart-appraisal";
import BeginAppraisal from "./components/Performance/appraisals/begin-appraisal";
import EmployeeAppraisalTable from "./components/Performance/appraisals/begin-appraisal/EmployeeAppraisalTable";
import PerformanceSettings from "./components/Performance/settings";
import ApprovalSettings from "./components/leave-management/approval-settings";
import AllProbationGrid from "./components/dashboard/AllProbationGrid";
import AllDisciplinaryGrid from "./components/dashboard/AllDisciplinaryGrid";
import AnnualApprovalList from "./components/Performance/planning/approvals/AnnualApprovalList";
import CascadeTeams from "./components/Performance/planning/cascade/CascadeTeams";
import CascadeAll from "./components/Performance/planning/cascade/CascadeAll";
import ApproveRequest from "./components/leave-management/approve-request";
import NextLevelAppraisal from "./components/Performance/appraisals/next-level-appraisal";
import SelectEmployee from "./components/Performance/appraisals/next-level-appraisal/SelectEmployee";
import ManageEmployee from "./components/Performance/appraisals/next-level-appraisal/ManageEmployee";
import DeductionsMaster from "./components/compensations-benefits/deductions-master";
import GenerateTemplate from "./components/compensations-benefits/payment-template/GenerateTemplate";
import TeamOnLeave from "./components/leave-management/employee-on-leave/TeamOnLeave";
import AllEmployeeOnLeave from "./components/leave-management/employee-on-leave/AllEmployeeOnLeave";
import BatchPayment from "./components/compensations-benefits/batch-payment/BatchPayment";
import AuthorizePayment from "./components/compensations-benefits/authorize-payment/AuthorizePayment";
import SendPaySlip from "./components/compensations-benefits/send-payslip/SendPaySlip";
import CanAccess from "./components/hoc/CanAccess";
import TeamScoreCard from "./components/Performance/monitoring/team-score-cards";
import AllEmployeesScoreCard from "./components/Performance/monitoring/all-employees-score-card";
import AllDepartmentScoreCard from "./components/Performance/monitoring/all-department-score-card";
import RequestManagement from "./components/request-management";
import RequestMgtOverview from "./components/request-management/Overview";
import NewRequestTemplate from "./components/request-management/new-request-template";
import CreateNewRequest from "./components/request-management/new-request-template/create-new-request";
import EditRequest from "./components/request-management/new-request-template/edit-request";
import MakeRequest from "./components/request-management/make-request";
import MakeNewRequest from "./components/request-management/make-request/make-new-request";
import EmployeeRequestHistory from "./components/request-management/make-request/request-history";
import TrackMyRequest from "./components/request-management/track-my-request";
import AllRequestsHistory from "./components/request-management/all-requests-history";
import ApproveRequests from "./components/request-management/approve-requests";
import SurveyManagement from "./components/survey-management";
import SurveyMgtOverview from "./components/survey-management/Overview";
import NewSurveyTemplate from "./components/survey-management/new-survey-template";
import CreateNewSurvey from "./components/survey-management/new-survey-template/create-new-survey";
import EditSurvey from "./components/survey-management/new-survey-template/edit-survey";
import FillSurvey from "./components/survey-management/fill-a-survey";
import ActiveSurveys from "./components/survey-management/survey-section/active-surveys";
import InactiveSurveys from "./components/survey-management/survey-section/inactive-surveys";
import SurveySection from "./components/survey-management/survey-section/index";
import ExternalSurveyRenderer from "./components/survey-management/externalsurveyui";
import SurveyReports from "./components/survey-management/reports";
import Todo from "./components/to-do";
import TodoOverview from "./components/to-do/Overview";
import Today from "./components/to-do/today";
import Inbox from "./components/to-do/inbox";
import EmployeeSendPayslip from "./components/employee/payroll/EmployeeSendPayslip";
import ErrorComponent from "./components/ErrorComponent";
import DocumentLanding from "./components/employee/profile/DocumentLanding";
import DocumentLists from "./components/employee/profile/DocumentLists";
import TrackAppraisal from "./components/Performance/appraisals/track-appraisal";
import ApproveEmergencyContact from "./components/hrms/approvals/categories/ApproveEmergencyContact";
import EmergencyContactHistory from "./components/hrms/approvals/history/history-categories/EmergencyContactHistory";
import Upcoming from "./components/to-do/upcoming";
const PURCHASES = process.env.REACT_APP_PURCHASES;
console.log({ PURCHASES });
const theme_list = [
  "blue",
  "green",
  "red",
  "purple",
  "orange",
  "orange",
  "navy",
  "black",
];

function App() {
  const [enabled_cop_det, setEnabledComDet] = useState(false);
  const is_Loggedin = useSelector(user_is_Loggedin);
  const companyUrl = useSelector(company_url);
  const online = useOnlineStatus();
  const first_load = useRef(true);
  const companyDetails = useShallowEqualSelector(company_details);
  const userMessage = useShallowEqualSelector(user_message);
  const userStatus = useShallowEqualSelector(user_status);
  useGetCompanyDetails(enabled_cop_det, setEnabledComDet, companyUrl);

  const colorTheme = companyDetails?.settings.filter(
    (setting) => setting.setting === "PrimaryColor"
  );

  let color = companyDetails && colorTheme[0]?.value;
  let theme;

  if (color) {
    switch (color) {
      case "#0065AB":
        theme = "blue";
        break;
      case "#008000":
        theme = "green";
        break;
      case "#CD0404":
        theme = "red";
        break;
      case "#6F1AB6":
        theme = "purple";
        break;
      case "#E14D2A":
        theme = "orange";
        break;
      case "#000000":
        theme = "black";
        break;
      case "#1C315E":
        theme = "navy";
        break;
      default:
        break;
    }
  }

  // console.log(error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (first_load.current === false) {
      if (!online) {
        message.warning(
          "Offline! Make sure your device has an active Internet connection."
        );
      }

      if (online) {
        message.success("Back online! Device connected");
      }
    }
    first_load.current = false;
  }, [online]);

  useEffect(() => {
    const companyURL = getURLSubdomain();

    if (companyURL) {
      dispatch({ type: "SAVE_COMPANY_URL", payload: companyURL });
      setEnabledComDet(true);
      document.body.classList.remove(...theme_list);
      document.body.classList.add(theme);
    } else {
      window.location.replace(PURCHASES);
    }
  }, [dispatch, theme]);

  useEffect(() => {
    if (companyDetails) {
      document.title = `${companyDetails?.business?.company_name} - WorkWaveHR`;
    }
  }, [companyDetails]);

  useEffect(() => {
    if (userMessage === "Invalid account" && userStatus === "error") {
      notification.error({
        message: "Oops! something went wrong",
        description: "Invalid account",
        duration: 6,
      });
      window.location.replace(PURCHASES);
    }
  }, [userMessage, userStatus]);

  const router = createBrowserRouter([
    // AUTHENTICATION ROUTES
    {
      path: "/",
      errorElement: <ErrorComponent />,
      element: (
        <AlreadyAuth isAuthenticated={is_Loggedin}>
          <Login />
        </AlreadyAuth>
      ),
    },

    {
      path: "/forgot-password",
      errorElement: <ErrorComponent />,
      element: (
        <AlreadyAuth isAuthenticated={is_Loggedin}>
          <Forgot />
        </AlreadyAuth>
      ),
    },

    {
      path: "/setup",
      errorElement: <ErrorComponent />,
      element: (
        <AlreadyAuth isAuthenticated={is_Loggedin}>
          <Setup />
        </AlreadyAuth>
      ),
    },

    {
      path: "/reset-password",
      errorElement: <ErrorComponent />,
      element: (
        <AlreadyAuth isAuthenticated={is_Loggedin}>
          <Reset />
        </AlreadyAuth>
      ),
    },

    // EXTERNAL SURVEY ROUTE
    {
      path: "/public/:survey_id/:survey_title",
      element: <ExternalSurveyRenderer />,
    },

    {
      path: "/*",
      element: <PageNotFound />,
    },

    // DASHBOARD ROUTES
    {
      path: "/dashboard",
      element: <Layout />,
      loader: () => {
        if (!is_Loggedin) {
          return redirect("/");
        }
        return null;
      },
      errorElement: <ErrorComponent />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "dashboard-full-list",
          element: (
            <CanAccess can_access={"view-all-employees"} isRoute={true}>
              <FullListEmpGrid />
            </CanAccess>
          ),
        },
        {
          path: "probation-list",
          element: (
            <CanAccess can_access={"view-all-employees"} isRoute={true}>
              <AllProbationGrid />
            </CanAccess>
          ),
        },
        {
          path: "disciplinary-list",
          element: (
            <CanAccess can_access={"view-all-employees"} isRoute={true}>
              <AllDisciplinaryGrid />
            </CanAccess>
          ),
        },
        {
          path: "subscriptions",
          element: <SubscriptionsPage />,
        },
        // ID-CARD BY EMPLOYEES
        {
          path: "/dashboard/id-card",
          element: <IdCardPage />,
          children: [
            {
              path: "/dashboard/id-card/new-request",
              element: <MyIDCard />,
            },
            {
              path: "/dashboard/id-card/replacement-request",
              element: <ReplacementRequest />,
            },
            {
              path: "/dashboard/id-card/request-history",
              element: <RequestHistory />,
            },
            {
              path: "/dashboard/id-card/my-id-card",
              element: <MyIDCardPage />,
            },
          ],
        },
        // LOAN BY EMPLOYEES
        {
          path: "/dashboard/loan",
          element: <LoanPage />,
        },
        // APPRAISAL BY EMPLOYEES
        {
          path: "/dashboard/appraisal",
          element: <AppraisalPage />,
        },
        // 404 PAGE
        {
          path: "dashboard/*",
          element: <PageNotFound />,
        },
      ],
    },
    // EMPLOYEE PROFILE ROUTES
    {
      path: "/profile",
      element: <ProfilePage />,
      errorElement: <ErrorComponent />,
      loader: () => {
        if (!is_Loggedin) {
          return redirect("/");
        }
        return null;
      },
      children: [
        {
          index: true,
          element: <EditProfile />,
        },
        {
          path: "/profile/next-of-kin",
          element: <NOK />,
        },
        {
          path: "/profile/educational-history",
          element: <EducationalDetails />,
        },
        {
          path: "/profile/medical",
          element: <MedicalPage />,
        },
        {
          path: "/profile/financial",
          element: <FinancialPage />,
        },
        {
          path: "/profile/dependants",
          element: <Dependants />,
        },
        {
          path: "/profile/employment-history",
          element: <EmploymentDetails />,
        },
        {
          path: "/profile/references",
          element: <References />,
        },
        {
          path: "/profile/documents",
          element: <Documents />,
          children: [
            {
              index: true,
              element: <DocumentLanding />,
            },
            {
              path: "/profile/documents/:category",
              element: <DocumentLists />,
            },
          ],
        },
      ],
    },

    // TOOLs ROUTES
    {
      path: "/tool",
      errorElement: <ErrorComponent />,
      element: <SubscriptionLayout />,
      loader: () => {
        if (!is_Loggedin) {
          return redirect("/");
        }
        return null;
      },
      children: [
        // SURVEY MANAGEMENT ROUTES
        {
          path: "/tool/todo",
          element: <Todo />,
          children: [
            {
              index: true,
              element: <TodoOverview />,
            },
            {
              path: "/tool/todo/today",
              element: <Today />,
            },
            {
              path: "/tool/todo/inbox",
              element: <Inbox />,
            },
            {
              path: "/tool/todo/upcoming",
              element: <Upcoming />,
            },
          ],
        },
      ],
    },
    // MODULES ROUTES
    {
      path: "/modules",
      errorElement: <ErrorComponent />,
      element: <SubscriptionLayout />,
      loader: () => {
        if (!is_Loggedin) {
          return redirect("/");
        }
        return null;
      },
      children: [
        // HRIS ROUTES
        {
          path: "/modules/hris",
          element: <HRMS />,
          children: [
            {
              index: true,
              element: <HRMOverview />,
            },
            {
              path: "/modules/hris/company",
              element: (
                <CanAccess can_access={"view-menu-hris-company"} isRoute={true}>
                  <Company />
                </CanAccess>
              ),
            },
            {
              path: "/modules/hris/employee",
              element: <HRMEmployee />,
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess
                      can_access={"view-menu-hris-employee-list"}
                      isRoute={true}
                    >
                      <AllEmployees />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/hris/employee/employee-management-new-employee",
                  element: <NewEmployee />,
                },
                {
                  path: "/modules/hris/employee/employee-management-upload-employee",
                  element: <UploadEmployees />,
                },
              ],
            },
            {
              path: "/modules/hris/employee/view/:id",
              element: <ViewEmployee />,
            },
            {
              path: "/modules/hris/role",
              element: <HRMJobRole />,
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess
                      can_access={"view-menu-hris-list-jobs"}
                      isRoute={true}
                    >
                      <JobRoleList />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/hris/role/upload-role",
                  element: <UploadJobRole />,
                },
              ],
            },
            {
              path: "/modules/hris/department",
              element: <HRMDepartment />,
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess
                      can_access={"view-menu-hris-list-department"}
                      isRoute={true}
                    >
                      <DepartmentList />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/hris/department/upload-departments",
                  element: <UploadDepartment />,
                },
              ],
            },
            {
              path: "/modules/hris/grades",
              element: <HRMGrades />,
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess can_access={"view-list-grades"} isRoute={true}>
                      <GradesList />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/hris/grades/upload-grades",
                  element: <UploadGrades />,
                },
              ],
            },
            {
              path: "/modules/hris/system-steps",
              element: <HRMSystemSteps />,
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess can_access={"view-list-steps"} isRoute={true}>
                      <AllSystemStep />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/hris/system-steps/upload-system-steps",
                  element: <UploadSystemStep />,
                },
              ],
            },
            {
              path: "/modules/hris/settings",
              element: (
                <CanAccess
                  can_access={"view-menu-hris-settings"}
                  isRoute={true}
                >
                  <Settings />
                </CanAccess>
              ),
            },
            {
              path: "/modules/hris/approval-management",
              element: (
                <CanAccess
                  can_access={"view-menu-hris-approvals"}
                  isRoute={true}
                >
                  <HRMApproval />
                </CanAccess>
              ),
            },
            {
              path: "/modules/hris/approval-management/:id/:fname/:lname",
              element: <ApprovalPending />,
              children: [
                // {
                //   path: "/modules/hris/approval-management/:id/:fname/:lname/default",
                //   element: <DefaultApproval />,
                //   children: [
                //     {
                //       index: true,
                //       element: <ApprovePersonal />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/medical",
                //       element: <ApproveMedical />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/contact",
                //       element: <ApproveContact />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/dependant",
                //       element: <ApproveDependant />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/nok",
                //       element: <ApproveNOK />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/identity",
                //       element: <ApproveIdentity />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/edu_history",
                //       element: <ApproveEduHistory />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/employment_history",
                //       element: <ApproveEmploymentHis />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/document",
                //       element: <ApproveDocument />,
                //     },
                //     {
                //       path: "/modules/hris/approval-management/:id/:fname/:lname/default/financials",
                //       element: <ApproveFinancials />,
                //     },
                //   ],
                // },
                {
                  path: "/modules/hris/approval-management/:id/:fname/:lname/pending",
                  element: <PendingApprovalTable />,
                  children: [
                    {
                      index: true,
                      element: <ApprovePersonal />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/medical",
                      element: <ApproveMedical />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/emergency_contact",
                      element: <ApproveEmergencyContact />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/contact",
                      element: <ApproveContact />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/dependant",
                      element: <ApproveDependant />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/nok",
                      element: <ApproveNOK />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/identity",
                      element: <ApproveIdentity />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/edu_history",
                      element: <ApproveEduHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/employment_history",
                      element: <ApproveEmploymentHis />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/document",
                      element: <ApproveDocument />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/pending/financials",
                      element: <ApproveFinancials />,
                    },
                  ],
                },
                {
                  path: "/modules/hris/approval-management/:id/:fname/:lname/history",
                  element: <ApprovalHistory />,
                  children: [
                    {
                      index: true,
                      element: <PersonalHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/medical",
                      element: <MedicalHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/emergency_contact",
                      element: <EmergencyContactHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/contact",
                      element: <ContactHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/dependant",
                      element: <DependantHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/nok",
                      element: <NOKHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/identity",
                      element: <IdentityHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/edu_history",
                      element: <EduHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/employment_history",
                      element: <EmploymentHistory />,
                    },
                    {
                      path: "/modules/hris/approval-management/:id/:fname/:lname/history/document",
                      element: <DocumentHistory />,
                    },
                    // {
                    //   path: "/modules/hris/approval-management/:id/:fname/:lname/history/financials",
                    //   element: <ApproveFinancials />,
                    // },
                  ],
                },
              ],
            },
            {
              path: "/modules/hris/*",
              element: <PageNotFound />,
            },
          ],
        },
        // PERMISSIONS MODULES
        {
          path: "/modules/permissions",
          element: <Permissions />,
          children: [
            {
              index: true,
              element: <Welcome />,
            },
            {
              path: "/modules/permissions/create-group",
              element: <CreateGroup />,
            },
            {
              path: "/modules/permissions/assign-group",
              element: <AssignGroup />,
            },
            {
              path: "/modules/permissions/view-groups",
              element: <ViewGroups />,
            },
            {
              path: "/modules/permissions/view-groups/edit-groups/:groupId",
              element: <EditGroup />,
            },
            {
              path: "/modules/permissions/view-groups/:groupName/:groupId",
              element: <Group />,
            },
          ],
        },
        {
          path: "/modules/onboarding",
          element: <Onboarding />,
        },
        {
          path: "/modules/identity-card-management",
          element: <IdentityCardManagement />,
        },
        // PERFORMANCE MODULES
        {
          path: "/modules/performance",
          element: <Performance />,
          children: [
            {
              index: true,
              element: <Overview />,
            },
            {
              path: "/modules/performance/planning",
              element: (
                <CanAccess
                  can_access={"view-performance-management-planning"}
                  isRoute={true}
                >
                  <Planning />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess
                      can_access={"view-planning-goal-settings"}
                      isRoute={true}
                    >
                      <GoalSettings />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/objectives",
                  element: (
                    <CanAccess
                      can_access={"view-planning-objective"}
                      isRoute={true}
                    >
                      <ObjectiveSettings />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/kpi",
                  element: (
                    <CanAccess
                      can_access={"view-planning-set-kpi"}
                      isRoute={true}
                    >
                      <KPISettings />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/target",
                  element: (
                    <CanAccess
                      can_access={"view-planning-target"}
                      isRoute={true}
                    >
                      <TargetSettings />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/approval",
                  element: (
                    <CanAccess
                      can_access={"view-planning-target"}
                      isRoute={true}
                    >
                      <PlanningApproval />
                    </CanAccess>
                  ),
                  children: [
                    {
                      index: true,
                      element: <AnnualApprovalList />,
                    },
                    {
                      path: "/modules/performance/planning/approval/halfyear",
                      element: <HalfYearApprovalList />,
                    },
                    {
                      path: "/modules/performance/planning/approval/quarter",
                      element: <QuarterlyApprovalList />,
                    },

                    {
                      path: "/modules/performance/planning/approval/monthly",
                      element: <MonthlyApprovalList />,
                    },
                  ],
                },
                {
                  path: "/modules/performance/planning/cascade-team",
                  element: (
                    <CanAccess
                      can_access={"view-planning-cascade"}
                      isRoute={true}
                    >
                      <CascadeTeams />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/cascade-all",
                  element: (
                    <CanAccess
                      can_access={"view-planning-cascade"}
                      isRoute={true}
                    >
                      <CascadeAll />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/planning/history",
                  element: (
                    <CanAccess
                      can_access={"view-planning-history"}
                      isRoute={true}
                    >
                      <PlanningHistory />
                    </CanAccess>
                  ),
                },
              ],
            },
            {
              path: "/modules/performance/monitoring",
              element: (
                <CanAccess
                  can_access={"view-performance-management-monitoring"}
                  isRoute={true}
                >
                  <PerformanceMonitoring />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-individual-score-card"}
                      isRoute={true}
                    >
                      <IndividualScoreCard />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/monitoring/team-score-card",
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-individual-score-card"}
                      isRoute={true}
                    >
                      <TeamScoreCard />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/monitoring/all-employees-score-card",
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-individual-score-card"}
                      isRoute={true}
                    >
                      <AllEmployeesScoreCard />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/monitoring/dept-score-card",
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-department-score-card"}
                      isRoute={true}
                    >
                      <DepartmentScoreCard />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/monitoring/all-dept-score-card",
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-department-score-card"}
                      isRoute={true}
                    >
                      <AllDepartmentScoreCard />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/monitoring/org-score-card",
                  element: (
                    <CanAccess
                      can_access={"view-monitoring-organization-score-card"}
                      isRoute={true}
                    >
                      <OrganizationScoreCard />
                    </CanAccess>
                  ),
                },
              ],
            },
            // PERFORMANCE APPRAISAL ROUTES
            {
              path: "/modules/performance/appraisals",
              element: (
                <CanAccess
                  can_access={"view-performance-management-appraisals"}
                  isRoute={true}
                >
                  <PerformanceAppraisals />
                </CanAccess>
              ),
              children: [
                {
                  path: "/modules/performance/appraisals",
                  element: (
                    <CanAccess
                      can_access={"view-begin-appraisal"}
                      isRoute={true}
                    >
                      <BeginAppraisal />
                    </CanAccess>
                  ),
                  children: [
                    {
                      path: "/modules/performance/appraisals/begin-appraisal/:teamMemberID",
                      element: <EmployeeAppraisalTable />,
                    },
                  ],
                },
                {
                  path: "/modules/performance/appraisals/create-appraisal",
                  element: (
                    <CanAccess
                      can_access={"view-create-appraisal"}
                      isRoute={true}
                    >
                      <CreatePerformanceAppraisal />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/appraisals/manage-team-appraisal",
                  element: (
                    <CanAccess
                      can_access={"view-next-level-appraisal"}
                      isRoute={true}
                    >
                      <ManageTeamAppraisal />
                    </CanAccess>
                  ),
                  children: [
                    {
                      index: true,
                      element: <SelectTeamMember />,
                    },
                    {
                      path: "/modules/performance/appraisals/manage-team-appraisal/:teamMemberID",
                      element: <ManageTeamMember />,
                    },
                  ],
                },
                {
                  path: "/modules/performance/appraisals/next-level-appraisal",
                  element: (
                    <CanAccess
                      can_access={"view-next-level-appraisal"}
                      isRoute={true}
                    >
                      <NextLevelAppraisal />
                    </CanAccess>
                  ),
                  children: [
                    {
                      index: true,
                      element: <SelectEmployee />,
                    },
                    {
                      path: "/modules/performance/appraisals/next-level-appraisal/:teamMemberID",
                      element: <ManageEmployee />,
                    },
                  ],
                },
                {
                  path: "/modules/performance/appraisals/appraisal-settings",
                  element: (
                    <CanAccess
                      can_access={"view-appraisal-settings"}
                      isRoute={true}
                    >
                      <AppraisalSettings />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/performance/appraisals/restart-appraisal",
                  element: <RestartAppraisal />,
                },
                {
                  path: "/modules/performance/appraisals/track-appraisal",
                  element: <TrackAppraisal />,
                },
                {
                  path: "/modules/performance/appraisals/begin-appraisal",
                  element: (
                    <CanAccess
                      can_access={"view-begin-appraisal"}
                      isRoute={true}
                    >
                      <BeginAppraisal />
                    </CanAccess>
                  ),
                  children: [
                    {
                      path: "/modules/performance/appraisals/begin-appraisal/:teamMemberID",
                      element: <EmployeeAppraisalTable />,
                    },
                  ],
                },
              ],
            },
            // PERFORMANCE REPORT ROUTES
            {
              path: "/modules/performance/reports",
              element: <PerformanceReports />,
            },
            // PERFORMANCE SETTINGS ROUTES
            {
              path: "/modules/performance/settings",
              element: (
                <CanAccess
                  can_access={"view-performance-management-settings"}
                  isRoute={true}
                >
                  <PerformanceSettings />
                </CanAccess>
              ),
            },
            {
              path: "/modules/performance/*",
              element: <PageNotFound />,
            },
          ],
        },
        // REQUEST MANAGEMENT ROUTES
        {
          path: "/modules/request",
          element: <RequestManagement />,
          errorElement: <ErrorComponent />,
          children: [
            {
              index: true,
              element: <RequestMgtOverview />,
            },
            {
              path: "/modules/request/new-request-template",
              element: (
                <CanAccess
                  can_access={"view-request-management-new-request"}
                  isRoute={true}
                >
                  <NewRequestTemplate />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: <CreateNewRequest />,
                },
                {
                  path: "/modules/request/new-request-template/edit-request",
                  element: <EditRequest />,
                },
              ],
            },
            {
              path: "/modules/request/make-request",
              element: (
                <CanAccess
                  can_access={"view-request-management-make-request"}
                  isRoute={true}
                >
                  <MakeRequest />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: <MakeNewRequest />,
                },
                {
                  path: "/modules/request/make-request/request-history",
                  element: <EmployeeRequestHistory />,
                },
              ],
            },
            {
              path: "/modules/request/track-request",
              element: (
                <CanAccess
                  can_access={"view-request-management-track-request"}
                  isRoute={true}
                >
                  <TrackMyRequest />
                </CanAccess>
              ),
            },
            {
              path: "/modules/request/approve-requests",
              element: (
                <CanAccess
                  can_access={"view-request-management-approve-request"}
                  isRoute={true}
                >
                  <ApproveRequests />
                </CanAccess>
              ),
            },
            {
              path: "/modules/request/history",
              element: (
                <CanAccess
                  can_access={"view-request-management-history"}
                  isRoute={true}
                >
                  <AllRequestsHistory />
                </CanAccess>
              ),
            },
          ],
        },
        // SURVEY MANAGEMENT ROUTES
        {
          path: "/modules/survey",
          element: <SurveyManagement />,
          children: [
            {
              index: true,
              element: <SurveyMgtOverview />,
            },
            {
              path: "/modules/survey/new-survey-template",
              element: <NewSurveyTemplate />,
              children: [
                {
                  index: true,
                  element: <CreateNewSurvey />,
                },
                {
                  path: "/modules/survey/new-survey-template/edit-survey",
                  element: <EditSurvey />,
                },
              ],
            },
            {
              path: "/modules/survey/fill-survey",
              element: <FillSurvey />,
            },
            {
              path: "/modules/survey/survey-section",
              element: <SurveySection />,
              children: [
                {
                  index: true,
                  element: <ActiveSurveys />,
                },
                {
                  path: "/modules/survey/survey-section/inactive-surveys",
                  element: <InactiveSurveys />,
                },
              ],
            },
            {
              path: "/modules/survey/survey-reports",
              element: <SurveyReports />,
            },
          ],
        },
        // COMPESATION ROUTES
        {
          path: "/modules/compensation",
          element: (
            <CanAccess
              can_access={"view-compensation-and-benefits-payroll"}
              isRoute={true}
            >
              <Benefits />
            </CanAccess>
          ),
          children: [
            {
              index: true,
              element: <CompensationOverview />,
            },
            {
              path: "/modules/compensation/list",
              element: <BenefitList />,
              children: [
                {
                  index: true,
                  element: <AvailableBenefits />,
                },
                {
                  path: "/modules/compensation/list/add",
                  element: <AddBenefits />,
                },
                {
                  path: "/modules/compensation/list/edit/:id",
                  element: <EditBenefits />,
                },
                {
                  path: "/modules/compensation/list/view/:id",
                  element: <ViewBenefits />,
                },
              ],
            },
            // PAY GRADES ROUTES
            {
              path: "/modules/compensation/pay-grades",
              element: (
                <CanAccess
                  can_access={"view-menu-hris-system-grades"}
                  isRoute={true}
                >
                  <PayGrades />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: <GradesList />,
                },
                {
                  path: "/modules/compensation/pay-grades/create-step",
                  element: <AllSystemStep />,
                },
                {
                  path: "/modules/compensation/pay-grades/list-pay-grade",
                  element: <GradedGrades />,
                },
                {
                  path: "/modules/compensation/pay-grades/pay-grades-with-notches",
                  element: <GradeWithBand />,
                },
                {
                  path: "/modules/compensation/pay-grades/pay-grades-without-notches",
                  element: <GradeWithoutNotches />,
                },
              ],
            },
            // PAY STRUCTURE ROUTES
            {
              path: "/modules/compensation/pay-structure",
              element: (
                <CanAccess
                  can_access={"view-compensation-and-benefits-paystructure"}
                  isRoute={true}
                >
                  <CompesationPayStructures />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: <PayStructure />,
                },
                {
                  path: "/modules/compensation/pay-structure/job-grading",
                  element: <CompesationJobGrading />,
                  children: [
                    {
                      index: true,
                      element: <AvailableJobs />,
                    },
                    {
                      path: "/modules/compensation/pay-structure/job-grading/add",
                      element: <AddJobGrades />,
                    },
                    {
                      path: "/modules/compensation/pay-structure/job-grading/edit/:id",
                      element: <EditJobGreades />,
                    },
                    {
                      path: "/modules/compensation/pay-structure/job-grading/view/:id",
                      element: <ViewJobGreades />,
                    },
                  ],
                },
              ],
            },
            // PAY ROLL ROUTES
            {
              path: "/modules/compensation/pay-roll",
              element: (
                <CanAccess
                  can_access={"view-payroll-deduction-master"}
                  isRoute={true}
                >
                  <CompesationPayRoll />
                </CanAccess>
              ),
              children: [
                {
                  path: "/modules/compensation/pay-roll",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-wage-item-settings"}
                      isRoute={true}
                    >
                      <Salary />
                    </CanAccess>
                  ),
                  children: [
                    {
                      index: true,
                      element: <SalaryComponent />,
                    },
                    {
                      path: "/modules/compensation/pay-roll/edit",
                      element: <EditSalaryComponent />,
                    },
                    {
                      path: "/modules/compensation/pay-roll/other-items-edit",
                      element: <OtherItemsEdit />,
                    },
                  ],
                },
                {
                  path: "/modules/compensation/pay-roll/deductions-master",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-deduction-master"}
                      isRoute={true}
                    >
                      <DeductionsMaster />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/compensation/pay-roll/payment-template",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-payment-template"}
                      isRoute={true}
                    >
                      <GenerateTemplate />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/compensation/pay-roll/batch-payment",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-batch-payment"}
                      isRoute={true}
                    >
                      <BatchPayment />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/compensation/pay-roll/authorize-payment",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-authorize-batch-payment"}
                      isRoute={true}
                    >
                      <AuthorizePayment />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/compensation/pay-roll/payslip",
                  element: (
                    <CanAccess
                      can_access={"view-payroll-authorize-batch-payment"}
                      isRoute={true}
                    >
                      <SendPaySlip />
                    </CanAccess>
                  ),
                },
                {
                  path: "/modules/compensation/pay-roll/employee-payslip",
                  element: <EmployeeSendPayslip />,
                },
              ],
            },
            // COMPENSATION SETTINGS
            {
              path: "/modules/compensation/settings",
              element: (
                <CanAccess
                  can_access={"view-compensation-and-benefits-settings"}
                  isRoute={true}
                >
                  <CompesationSettings />
                </CanAccess>
              ),
            },

            {
              path: "/modules/compensation/*",
              element: <PageNotFound />,
            },
          ],
        },
        // LEAVE MGT ROUTES
        {
          path: "/modules/leave",
          element: <LeaveManagement />,
          children: [
            {
              index: true,
              element: <LeaveOverview />,
            },
            {
              path: "/modules/leave/leave-group",
              element: (
                <CanAccess
                  can_access={"view-leave-management-leave-group"}
                  isRoute={true}
                >
                  <LeaveGroup />
                </CanAccess>
              ),

              children: [
                {
                  index: true,
                  element: <GroupList />,
                },
                {
                  path: "/modules/leave/leave-group/upload-leave-group",
                  element: <UploadGroup />,
                },
              ],
            },
            {
              path: "/modules/leave/leave-grade",
              element: (
                <CanAccess
                  can_access={"view-leave-management-leave-grade-distribution"}
                  isRoute={true}
                >
                  <LeaveGrade />
                </CanAccess>
              ),

              children: [
                {
                  index: true,
                  element: <GradeLeaveDistributionList />,
                },
                {
                  path: "/modules/leave/leave-grade/upload-grade-distribution",
                  element: <UploadDistribution />,
                },
              ],
            },
            {
              path: "/modules/leave/approve-request",
              element: (
                <CanAccess
                  can_access={"view-leave-management-approve-leave-request"}
                  isRoute={true}
                >
                  <ApproveRequest />
                </CanAccess>
              ),
            },
            {
              path: "/modules/leave/request-hr",
              element: (
                <CanAccess
                  can_access={"view-leave-management-request-hr"}
                  isRoute={true}
                >
                  <LeaveHr />
                </CanAccess>
              ),

              children: [
                {
                  index: true,
                  element: <TeamHistoryHR />,
                },
                {
                  path: "/modules/leave/request-hr/teamannualleave_hr",
                  element: <TeamLeavePlanHr />,
                },
                {
                  path: "/modules/leave/request-hr/requestforemployee_hr",
                  element: <RequestForEmployee />,
                },
                {
                  path: "/modules/leave/request-hr/all_employee_on_leave",
                  element: <AllEmployeeOnLeave />,
                },
              ],
            },
            {
              path: "/modules/leave/request-team",
              element: (
                <CanAccess
                  can_access={"view-leave-management-request-team"}
                  isRoute={true}
                >
                  <LeaveTeam />
                </CanAccess>
              ),
              children: [
                {
                  index: true,
                  element: <TeamHistory />,
                },
                {
                  path: "/modules/leave/request-team/teamannualleave_team",
                  element: <TeamLeavePlanTeam />,
                },
                {
                  path: "/modules/leave/request-team/requestforemployee_team",
                  element: <RequestForEmployeeTeam />,
                },
                {
                  path: "/modules/leave/request-team/employee_on_leave",
                  element: <TeamOnLeave />,
                },
              ],
            },
            {
              path: "/modules/leave/leave-actions",
              element: (
                <CanAccess
                  can_access={"view-leave-mgt-on-employee-dashboard"}
                  isRoute={true}
                >
                  <LeaveActions />
                </CanAccess>
              ),

              children: [
                {
                  index: true,
                  element: <NewLeaveRequest />,
                },
                {
                  path: "/modules/leave/leave-actions/annualLeave",
                  element: <AnnualLeave />,
                },
                {
                  path: "/modules/leave/leave-actions/leaveHistory",
                  element: <LeaveHistory />,
                },
                {
                  path: "/modules/leave/leave-actions/teamAnnual",
                  element: <TeamAnnualLeave />,
                },
                {
                  path: "/modules/leave/leave-actions/teamleaveplan",
                  element: <TeamLeavePlan />,
                },
              ],
            },
            {
              path: "/modules/leave/settings",
              element: (
                <CanAccess
                  can_access={"view-leave-management-leave-approval-setting"}
                  isRoute={true}
                >
                  <ApprovalSettings />
                </CanAccess>
              ),
            },
            {
              path: "/modules/leave/*",
              element: <PageNotFound />,
            },
          ],
        },
      ],
    },

    {
      path: "/track",
      errorElement: <ErrorComponent />,
      element: <TrackHire />,
    },

    {
      path: "/new-hire-form",
      errorElement: <ErrorComponent />,
      element: <Prefill />,
    },

    {
      path: "/provisional-offer",
      errorElement: <ErrorComponent />,
      element: <ProvisionalOffer />,
    },
  ]);

  return !companyDetails ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
      className="d-flex justify-content-center align-items-center h-100"
    >
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color={"var(--clr-primary)"}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  ) : (
    <RouterProvider router={router} />
  );
}

export default App;
