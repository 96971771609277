import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const ProfileCompletenessBar = ({ value, maxValue, title, color, dashboard }) => {
  return (
    <div className={dashboard ? "col-6 mb-3" :"col-2 mb-4"}>
      <div
        style={{ width: dashboard ? 80 : 100, height: dashboard ? 80 : 100 }}
        className="d-flex flex-column justify-content-center align-items-center gap-4"
      >
        <CircularProgressbar
          value={value}
          maxValue={maxValue}
          text={maxValue}
          strokeWidth={10}
          styles={buildStyles({
            pathColor: color,
            textColor: color,
            textSize: 18
          })}
        />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default ProfileCompletenessBar;
