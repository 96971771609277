import React from "react";
import { FaInfoCircle } from "react-icons/fa";

function Warning() {
  return (
    <div className="card my-3" style={{ backgroundColor: "yellow" }}>
      <div className="card-body p-2">
        <div className="d-flex align-items-center gap-2">
          <small className="text-dark font-bold w-100">
            Changes made would reflect on your dashboard after the administrator
            approves.
          </small>
          <FaInfoCircle className="h3 m-0" />
        </div>
      </div>
    </div>
  );
}

export default Warning;
