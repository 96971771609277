import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useShallowEqualSelector } from "../../../util/hooks";
import { performance_setup } from "../../../util/selectors/userSelectors";
import { useGetPerformanceSetup } from "../../../util/usershookActions";

function MonitoringDropdown() {
  const [enabled_set, setEnabledSet] = useState(true);
  useGetPerformanceSetup(enabled_set, setEnabledSet);
  const performance_settings = useShallowEqualSelector(performance_setup);

  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"monitoring"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Individual Score Card</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"monitoring/team-score-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Team Score Card</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"monitoring/all-employees-score-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">All Employees Score Card</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"monitoring/dept-score-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Department Score Card</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"monitoring/all-dept-score-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">All Department Score Card</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"monitoring/org-score-card"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Organization Score Card</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(MonitoringDropdown);
