import { useState, useEffect } from "react";
import { useGetAllEmployee } from "../../../util/preferenceshookActions";
import { all_groups } from "../../../util/selectors/preferenceSelectors";
import { useShallowEqualSelector } from "../../../util/hooks";
import { Button } from "antd";
import {
  addActivityTemple,
  updateActivityTemple,
} from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

const InductionActivity = ({
  template,
  refetchTem,
  activity,
  closeModal,
  edit,
}) => {
  const dispatch = useDispatch();
  let initialDetails;
  if (activity) {
    initialDetails = {
      activity: activity.activity,
      description: activity.description,
      duration: activity.duration,
      primary_supervisor_id: activity.primary_supervisor_id,
      secondary_supervisor_id: activity.secondary_supervisor_id,
      induction_template_id: template.id,
    };
  } else {
    initialDetails = {
      activity: "",
      description: "",
      duration: "",
      primary_supervisor_id: "",
      secondary_supervisor_id: "",
      induction_template_id: template.id,
    };
  }

  const [values, setValues] = useState(initialDetails);
  const [users, setUsers] = useState([]);
  const [enabled, setEnabled] = useState(true);

  const [loading, setLoading] = useState(false);
  useGetAllEmployee(enabled, setEnabled, "all");

  const all_users = useShallowEqualSelector(all_groups);

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e?.target.name]: e?.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (activity) {
      const creds = {
        ...values,
        type: "induction",
        act_id: activity.id,
      };
      setLoading(true);
      updateActivityTemple(dispatch, creds).then((res) => {
        setLoading(false);
        if (res?.status === "success") {
          // setValues(initialDetails);
          closeModal();
          refetchTem();
        }
      });
    } else {
      const creds = {
        ...values,
        type: "induction",
      };
      setLoading(true);
      addActivityTemple(dispatch, creds).then((res) => {
        setLoading(false);
        if (res?.status === "success") {
          setValues(initialDetails);
          closeModal();
          refetchTem();
        }
      });
    }
  };

  useEffect(() => {
    if (Object.keys(all_users).length) {
      setUsers(all_users?.all_employees?.data);
    }
  }, [all_users]);

  return (
    <div>
      <form className="onboarding-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="activity">Activity</label>
          <input
            type="text"
            placeholder="Induction Activity Name"
            name="activity"
            id="activity"
            onChange={handleChange}
            value={values.activity}
          />
        </div>
        <div className="mt-3">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            value={values.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mt-3">
          <label htmlFor="duration">Duration</label>
          <input
            type="text"
            placeholder="Induction Duration (hrs)"
            name="duration"
            id="duration"
            onChange={handleChange}
            value={values.duration}
          />
        </div>
        <div className="mt-3">
          <label htmlFor="primary_supervisor_id">Primary Supervisor</label>
          <select
            name="primary_supervisor_id"
            id="primary_supervisor_id"
            value={values.primary_supervisor_id}
            onChange={handleChange}
          >
            <option value="">--</option>
            {users.map((user) => (
              <option value={user.id} key={user.id}>
                {user.fullname}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          <label htmlFor="secondary_supervisor_id">Secondary Supervisor</label>
          <select
            name="secondary_supervisor_id"
            id="secondary_supervisor_id"
            value={values.secondary_supervisor_id}
            onChange={handleChange}
          >
            <option value="">--</option>
            {users.map((user) => (
              <option value={user.id} key={user.id}>
                {user.fullname}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex mt-5 gap-2 justify-content-end">
          <Button
            htmlType="button"
            type="text"
            onClick={() => closeModal(false)}
          >
            Close
          </Button>
          <Button
            htmlType="submit"
            className="myBtn "
            disabled={!values.activity}
            loading={loading}
          >
            {edit ? "Save Changes" : "Add Activity"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InductionActivity;
