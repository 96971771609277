import React from "react";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";
import { MdDoDisturb } from "react-icons/md";
import {
  RiArrowUpCircleFill,
  RiAwardLine,
  RiBuilding3Line,
  RiHandCoinLine,
} from "react-icons/ri";

import HocHeader from "../ui/HocHeader";

function SurveyMgtOverview() {
  useUserCleanUp();


  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_name="Survey Management"
        module_description="Employees can make facilities work order requests and maintenance requests to the facilities management team, or requests to the IT service management team."
        module_img="/assets/request-management.png"
      />

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div className="bg-white__reset">
              <div className="container-fluid py-3 ">
                <div className="how-it-works">
                  <p className="mb-3">What you can do</p>
                  <div className="row" style={{ overflow: "hidden" }}>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number">1</div>
                        <p>Start</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number">2</div>
                        <p>Create Request</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number">3</div>
                        <p>Make a Request</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number">4</div>
                        <p>Submit Request</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number">5</div>
                        <p>Approve Request</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-between gap-4">
                      <div>
                        <div className="number last">6</div>
                        <p>Finish</p>
                      </div>
                    </div>
                  </div>
                </div>
                <main className="mt-5">
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(205, 96, 35, 0.19)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiPulseLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Total Requests</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                               0
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(33, 35, 30, 0.08)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <MdDoDisturb />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Approved Requests</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">0</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(98, 217, 254, 0.22)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiArrowUpCircleFill />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Declined Requests</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                                 0
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(254, 98, 98, 0.22)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiAwardLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Pending Requests</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">0
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(215, 226, 255, 1)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiBuilding3Line />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Request Submission Date</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0 fs-3">N/A
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="insight"
                        style={{ backgroundColor: "rgba(215, 255, 236, 0.58)" }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiHandCoinLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h4>Completed Requests</h4>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">4
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SurveyMgtOverview;
