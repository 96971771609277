import { useEffect, useState } from "react";
import { Input, Table, Checkbox, Button, Modal } from "antd";
import { useLocation } from "react-router-dom";
import {
  useGetAppraisalsApproved,
  useGetTeamScoreCard,
  useGetLineManagerAppraisal,
  useGetAppraisalLevel,
  useGetAppraisalSettings,
} from "../../../../util/usershookActions";
import { FaComment } from "react-icons/fa";
import {
  nextLevelAppraiserDeliverableComment,
  nextLevelAppraiser,
} from "../../../../redux/users/userActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SkeletonUI from "../../../ui/Skeleton";
import dayjs from "dayjs";

const ManageEmployee = () => {
  const { TextArea } = Input;
  const [enabled, setEnabled] = useState(true);
  const [teamEnabled, setTeamEnabled] = useState(false);
  const [employee_id, setEmployeeId] = useState("");
  const [tableData, setTableData] = useState();
  const [manageEmployeeLoading, setmanageEmployeeLoading] = useState(false);

  const { teamMemberID } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const selectedEmployeeDetails = location.state?.selectedEmployeeDetails;

  useEffect(() => {
    if (selectedEmployeeDetails && selectedEmployeeDetails[0]) {
      setEmployeeId(selectedEmployeeDetails[0]?.id);
    }
  }, [selectedEmployeeDetails]);

  useEffect(() => {
    if (employee_id) {
      setTeamEnabled(true);
    }
  }, [employee_id]);

  const { data: appraisalSettings } = useGetAppraisalSettings(
    enabled,
    setEnabled
  );

  const {
    data: appraiserLevel,
    isLoading: isLoadingAppraiserLevel,
    refetch: refetchAppraiserLevel,
  } = useGetAppraisalLevel(enabled, setEnabled, teamMemberID);

  console.log("appraiserLevel", appraiserLevel);

  const {
    data: lineManagerAppraisal,
    isLoading: isLoadingLineManagerAppraisal,
    refetch: refetchLineManagerAppraisal,
  } = useGetLineManagerAppraisal(enabled, setEnabled, teamMemberID);

  const { data: inProgressAppraisal, isLoading: isLoadingInProgressAppraisal } =
    useGetAppraisalsApproved(enabled, setEnabled);

  const {
    data: teamScoreCard,
    isLoading: isLoadingTeamScoreCard,
    refetch,
  } = useGetTeamScoreCard(teamEnabled, setTeamEnabled, teamMemberID);

  const appraiserLevelSlice = appraiserLevel?.payload?.level.split("_")[0];

  let inProgressReview;

  if (inProgressAppraisal && inProgressAppraisal.payload[0]) {
    inProgressReview = inProgressAppraisal?.payload?.filter(
      (appraisal) => appraisal.status === "in-progress"
    );
  }

  const [cascade_ids, setCascadeIds] = useState([]);
  const [employee_score, setEmployeeScores] = useState([]);
  const [summary_comment, setSummaryComment] = useState();
  const [checkboxValues, setCheckboxValues] = useState({
    training: false,
    promotion: false,
    transfer: false,
    salary_adjustment: false,
    extension_of_probationary_period: false,
    review_of_continued_employment: false,
    confirmation_of_appointment: false,
  });

  useEffect(() => {
    if (lineManagerAppraisal?.payload == null) {
      return;
    } else {
      setSummaryComment(
        lineManagerAppraisal.payload?.summary_comments?.[
          `${appraiserLevel?.payload?.level}_comment`
        ]?.comment
      );
      // Initialize a new state object
      let newState = {
        training: lineManagerAppraisal.payload.training === 1,
        promotion: lineManagerAppraisal.payload.promotion === 1,
        transfer: lineManagerAppraisal.payload.transfer === 1,
        salary_adjustment: lineManagerAppraisal.payload.salary_adjustment === 1,
        extension_of_probationary_period:
          lineManagerAppraisal.payload.extension_of_probationary_period === 1,
        review_of_continued_employment:
          lineManagerAppraisal.payload.review_of_continued_employment === 1,
        confirmation_of_appointment:
          lineManagerAppraisal.payload.confirmation_of_appointment === 1,
      };

      setCheckboxValues(newState);
    }
  }, [lineManagerAppraisal]);

  useEffect(() => {
    if (teamScoreCard) {
      const newCascadeIds = teamScoreCard.payload.map(
        (scoreCard) => scoreCard.id
      );
      const newEmployeeScores = teamScoreCard.payload.map(
        (scoreCard) => scoreCard.achieved?.appraisalLevel?.payload?.level
      );
      setCascadeIds(newCascadeIds);
      setEmployeeScores(newEmployeeScores);
    }
  }, [teamScoreCard]);

  const handleScoreChange = (index, value) => {
    const updatedScores = [...employee_score];
    updatedScores[index] = value;
    setEmployeeScores(updatedScores);
  };

  useEffect(() => {
    if (teamScoreCard) {
      const new_array = teamScoreCard?.payload?.map((scoreCard, index) => {
        // Calculate the actual score based on user input
        const user_input = Number(employee_score[index]);
        const actual_score =
          (user_input / scoreCard.individual_target) * scoreCard.weight;

        return {
          key: scoreCard.id,
          section: scoreCard.section,
          goal: scoreCard.goal,
          objective: scoreCard.objective,
          kpi: scoreCard.kpi,
          target: `${scoreCard.individual_target} (${scoreCard.unit})`,
          weight: scoreCard.weight,
          ...Object.keys(scoreCard.achieved).reduce((acc, level) => {
            if (level !== appraiserLevel?.payload?.level) {
              acc[level] = scoreCard.achieved[level];
            } else {
              acc[level] = (
                <Input
                  size="large"
                  id={`score-${scoreCard.id}`}
                  defaultValue={scoreCard.achieved[level]}
                  placeholder="Type score here"
                  style={{
                    backgroundColor: scoreCard.achieved[level]
                      ? "#fff"
                      : "transparent",
                    cursor: scoreCard.achieved[level] ? "text" : "pointer",
                    border: "1px solid black",
                    borderColor: scoreCard.achieved[level]
                      ? "transparent"
                      : "#d9d9d9",
                    borderRadius: "6px",
                  }}
                  bordered={Boolean(scoreCard.achieved[level])}
                  addonAfter={scoreCard.unit}
                  value={employee_score[index]}
                  onChange={(e) => {
                    handleScoreChange(index, Number(e.target.value));
                    // Trigger a re-render to update the actual score
                    setTableData((prev) => [...prev]);
                  }}
                  disabled={
                    !appraisalSettings?.payload?.[
                      `${appraiserLevelSlice}_appraiser`
                    ]?.appraise_employee
                  }
                />
              );
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.actual_score).reduce((acc, level) => {
            if (level === `${appraiserLevel?.payload?.level}_actual_score`) {
              acc[level] = isNaN(actual_score)
                ? scoreCard.actual_score[level]
                : actual_score;
            } else {
              acc[level] = scoreCard.actual_score[level];
            }
            return acc;
          }, {}),
          ...Object.keys(scoreCard.score).reduce((acc, period) => {
            acc[period] = scoreCard.score[period];
            return acc;
          }, {}),
          ...Object.keys(scoreCard.comments).reduce((acc, level) => {
            if (level !== "employee_comment") {
              acc[level] = scoreCard.comments[level]?.comment;
            } else {
              acc[level] = scoreCard.comments[level];
            }
            return acc;
          }, {}),
          scoreCard,
        };
      });
      setTableData(new_array);
    }
  }, [employee_score, teamScoreCard]);

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "center",
    },
    {
      title: "Goal",
      dataIndex: "goal",
      align: "center",
    },
    {
      title: "Objective",
      dataIndex: "objective",
      align: "center",
    },
    {
      title: "KPI",
      dataIndex: "kpi",
      align: "center",
    },
    {
      title: "Target",
      dataIndex: "target",
      align: "center",
    },
    {
      title: "Achieved Score",
      align: "center",
      children: Object.keys(teamScoreCard?.payload?.[0]?.achieved || {}).map(
        (level) => ({
          width: level === appraiserLevel?.payload?.level ? "15rem" : "auto",
          title: level
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          dataIndex: level,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Weight (%)",
      dataIndex: "weight",
      align: "center",
    },
    {
      title: "Actual Score",
      children: Object.keys(
        teamScoreCard?.payload?.[0]?.actual_score || {}
      ).map((level) => ({
        title: `${level} (%)`
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase()),
        dataIndex: level,
        align: "center",
        render: (text) => (text === null ? "N/A" : text),
      })),
    },
    {
      title: "Score",
      align: "center",
      children: Object.keys(teamScoreCard?.payload?.[0]?.score || {}).map(
        (period) => ({
          title: period.toUpperCase(),
          dataIndex: period,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Deliverable Comments",
      children: Object.keys(teamScoreCard?.payload?.[0]?.comments || {}).map(
        (level) => ({
          title: level
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          dataIndex: level,
          align: "center",
          render: (text) => (text === null ? "N/A" : text),
        })
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <EachAction
          item={record}
          refetch={refetch}
          teamMemberID={teamMemberID}
          appraiserLevel={appraiserLevel?.payload?.level}
          deliverableCommentAbility={
            appraisalSettings?.payload?.[`${appraiserLevelSlice}_appraiser`]
              ?.comment_on_deliverable
          }
        />
      ),
    },
  ];

  const options = [
    { label: "Training", value: "training" },
    { label: "Promotion", value: "promotion" },
    { label: "Transfer", value: "transfer" },
    { label: "Salary Adjustment", value: "salary_adjustment" },
    {
      label: "Extension of Probationary Period",
      value: "extension_of_probationary_period",
    },
    {
      label: "Review of Continued Employment",
      value: "review_of_continued_employment",
    },
    {
      label: "Confirmation of Appointment",
      value: "confirmation_of_appointment",
    },
  ];

  const handleManageEmployee = (e) => {
    const creds = {
      cascade_ids,
      employee_achievement: employee_score,
      employee_id,
      summary_comment,
    };
    e.preventDefault();
    setmanageEmployeeLoading(true);
    nextLevelAppraiser(dispatch, creds).then(() => {
      setmanageEmployeeLoading(false);
      refetch();
      refetchLineManagerAppraisal();
    });
  };

  return (
    <div className="">
      {isLoadingInProgressAppraisal ? (
        <SkeletonUI number={2} />
      ) : (
        <div className="monitoring__individual__details">
          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Employee Name</label>
            <Input
              size="large"
              id="employeeName"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.name
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Position</label>
            <Input
              size="large"
              id="position"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.job_role
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Department</label>
            <Input
              size="large"
              id="department"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.department
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Supervisor Name</label>
            <Input
              size="large"
              id="supervisorName"
              value={
                selectedEmployeeDetails &&
                selectedEmployeeDetails[0] &&
                selectedEmployeeDetails[0]?.primary_line_manager
              }
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Review Period</label>
            <Input
              size="large"
              id="reviewPeriod"
              value={`${dayjs(
                inProgressReview[0]?.review_period_date?.from
              ).format("DD/MM/YYYY")} - ${dayjs(
                inProgressReview[0]?.review_period_date?.to
              ).format("DD/MM/YYYY")}`}
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>

          <div className="d-flex flex-column gap-2 ">
            <label className="fw-bold fs-6">Date of Performance Review</label>
            <Input
              size="large"
              id="dateOfReview"
              value={`${dayjs(inProgressReview[0]?.start_date).format(
                "DD/MM/YYYY"
              )} - ${dayjs(inProgressReview[0]?.end_date).format(
                "DD/MM/YYYY"
              )}`}
              className="disabled__input"
              style={{
                border: "2px solid black",
              }}
              disabled
            />
          </div>
        </div>
      )}

      {isLoadingTeamScoreCard || isLoadingLineManagerAppraisal ? (
        <SkeletonUI number={2} />
      ) : (
        <form className="begin__appraisal__content__form">
          <div className="monitoring__individual__content__table">
            <Table
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 3200 }}
              bordered
              pagination={false}
            />
          </div>

          <div className="">
            <div className="manage__team__member__textareas">
              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">Employee's Strength</label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.employee_strength}
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">Employee's Weakness</label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.employee_weakness}
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">
                  Identify Employee Performance Gap
                </label>
                <TextArea
                  rows={6}
                  value={
                    lineManagerAppraisal?.payload?.employee_performance_gap
                  }
                  disabled
                />
              </div>

              <div className="d-flex flex-column align-items-center gap-2 ">
                <label className="fw-bold fs-6">
                  State Remedial action relevant over the next FY
                </label>
                <TextArea
                  rows={6}
                  value={lineManagerAppraisal?.payload?.remedial_action}
                  disabled
                />
              </div>
            </div>

            <div className="">
              <div className="d-flex flex-column align-items-center gap-4 ">
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">
                    Medical Fitness and Alertness
                  </label>
                  <span className="">
                    State any factor for example health, personal difficulties,
                    organizational problems (e.g. stock out, working tools,
                    logistics, leadership style e.t.c) or any other
                    internal/external/extraneous factors which might have
                    affected the staff job performance during the period under
                    review.
                  </span>
                </div>
                <TextArea
                  rows={6}
                  value={
                    lineManagerAppraisal?.payload?.medical_fitness_alertness
                  }
                  disabled
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-4 py-5 manage__team__checkbox">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Recommended For</label>
                <span className="">
                  Recommend employee based on performance and ratings
                </span>
              </div>

              <Checkbox.Group
                options={options}
                value={Object.keys(checkboxValues).filter(
                  (key) => checkboxValues[key]
                )}
                disabled
              />
            </div>

            <div className="manage__team__member__textareas">
              {lineManagerAppraisal?.payload != null &&
                Object.entries(
                  lineManagerAppraisal?.payload?.summary_comments
                ).map(([key, value]) => {
                  if (key !== `${appraiserLevel?.payload?.level}_comment`) {
                    if (key === "employee_comment") {
                      return (
                        <div className="d-flex flex-column align-items-center gap-2 ">
                          <label className="fw-bold fs-6">
                            {key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (l) => l.toUpperCase())}
                          </label>
                          <TextArea rows={6} value={value || "N/A"} disabled />
                        </div>
                      );
                    } else {
                      return (
                        <div className="d-flex flex-column align-items-center gap-2 ">
                          <label className="fw-bold fs-6">
                            {key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (l) => l.toUpperCase())}
                          </label>
                          <TextArea
                            rows={6}
                            value={value?.comment || "N/A"}
                            disabled
                          />
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div className="d-flex flex-column align-items-center gap-2 ">
                        <label className="fw-bold fs-6">
                          {appraiserLevel?.payload?.level
                            ?.replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase()) +
                            " Comment"}
                        </label>
                        <TextArea
                          rows={6}
                          value={summary_comment}
                          onChange={(e) => setSummaryComment(e.target.value)}
                          disabled={
                            !appraisalSettings?.payload?.[
                              `${appraiserLevelSlice}_appraiser`
                            ]?.summary_comment
                          }
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "var(--clr-primary)",
                marginTop: "2rem",
              }}
              onClick={handleManageEmployee}
              disabled={
                !employee_score ||
                employee_score.length === 0 ||
                employee_score === undefined
              }
              loading={manageEmployeeLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

function EachAction({
  item,
  refetch,
  teamMemberID,
  appraiserLevel,
  deliverableCommentAbility,
}) {
  const { TextArea } = Input;
  const [nextLevelAppraiserComment, setNextLevelAppraiserComment] = useState(
    item.scoreCard.comments?.[`${appraiserLevel}_comment`]?.comment || ""
  );
  const [
    nextLevelAppraiserCommentLoading,
    setNextLevelAppraiserCommentLoading,
  ] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleModalOpen() {
    setIsModalOpen(!isModalOpen);
  }
  const dispatch = useDispatch();

  // console.log("item", item);

  const handleAddNextLevelAppraiserComment = (e) => {
    const creds = {
      cascade_id: item.key,
      employee_id: teamMemberID,
      comment: nextLevelAppraiserComment,
    };
    e.preventDefault();
    setNextLevelAppraiserCommentLoading(true);
    nextLevelAppraiserDeliverableComment(dispatch, creds).then(() => {
      setNextLevelAppraiserCommentLoading(false);
      refetch();
    });
  };

  return (
    <>
      <div
        className="begin__appraisal__content__table__comment"
        onClick={handleModalOpen}
      >
        <FaComment
          size={30}
          color={
            Object.values(item.scoreCard.comments).some(Boolean)
              ? "blue"
              : "gray"
          }
        />
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleModalOpen}
        centered
        width={"auto"}
      >
        <div
          className="d-flex justify-content-bewteen gap-5"
          style={{
            // maxWidth: "900px",
            overflow: "auto",
            padding: "1.5rem",
          }}
        >
          <div className="w-50">
            <h6>
              Next Level Appraiser can see comments on deliverable by other
              appraisers here
            </h6>

            {Object.entries(item.scoreCard.comments).map(([key, value]) => {
              if (key !== `${appraiserLevel}_comment`) {
                if (key === "employee_comment") {
                  return (
                    <div
                      key={key}
                      className="d-flex flex-column align-items-start gap-2 mb-3"
                    >
                      <label className="fw-bold fs-6">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </label>
                      <div>{value || "N/A"}</div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={key}
                      className="d-flex flex-column align-items-start gap-2 mb-3"
                    >
                      <label className="fw-bold fs-6">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </label>
                      <div>{value?.comment || "N/A"}</div>
                    </div>
                  );
                }
              }
              return null;
            })}
          </div>

          <div className="begin__appraisal__content__table__comment__divider"></div>

          <div className="w-50">
            <h6>
              Next Level Appraisal can comment on deliverable here and send to
              the employee and other appraisers
            </h6>

            <div className="d-flex flex-column align-items-start gap-2 ">
              <label className="fw-bold fs-6">
                {appraiserLevel
                  ?.replace(/_/g, " ")
                  .replace(/\b\w/g, (l) => l.toUpperCase()) + " Comment"}
              </label>
              <TextArea
                rows={6}
                value={nextLevelAppraiserComment}
                onChange={(e) => setNextLevelAppraiserComment(e.target.value)}
                disabled={!deliverableCommentAbility}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                  marginTop: "2rem",
                }}
                onClick={handleAddNextLevelAppraiserComment}
                loading={nextLevelAppraiserCommentLoading}
                disabled={
                  nextLevelAppraiserComment === "" || !deliverableCommentAbility
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ManageEmployee;
