import { useState } from "react";
import styles from "../../../styles/employee/Profile.module.css";
import DashboardCard from "../../dashboard/DashboardCard";
import { MdPermContactCalendar } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { Button } from "antd";
import {
  // user_spinner,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { updateUserContactInfo } from "../../../redux/users/userActions";
import Warning from "../../ui/Warning";

export default function ContactDetails({ contactData }) {
  useUserCleanUp();
  let init_details;
  const dispatch = useDispatch();
  // const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);
  const [save_spinners, setSaveSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);

  if (contactData && Object.keys(contactData).length) {
    init_details = { ...contactData };
  } else {
    init_details = {
      mobile_number: "",
      primary_email: "",
      secondary_email: "",
      home_address: "",
      telephone: "",
    };
  }

  const callbackFn = () => {
    values.user_id = userProfile?.id;
    setSaveSpinner(true);
    updateUserContactInfo(dispatch, values).then((res) => {
      setSaveSpinner(false);
      if (res?.status === "success") {
        // clearForm();
        setEditMode(!editMode);
      }
    });
  };

  const contact_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    init_details,
    contact_details_validator
  );

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return !editMode ? (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Contact Information</h5>
        <FaRegEdit
          className="h5"
          title="Edit Contact"
          onClick={handleEdit}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.contentBody}>
        <div className="row">
          <div className="col-md-6">
            <label>Primary Email</label>
            <p>{contactData?.primary_email || "N/A"}</p>
          </div>
          <div className="col-md-6">
            <label>Secondary Email</label>
            <p>{contactData?.secondary_email || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label>Mobile Number</label>
            <p>{contactData?.mobile_number || "N/A"}</p>
          </div>
          <div className="col-md-6">
            <label>Phone Number</label>
            <p>{contactData?.telephone || "N/A"}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label>Home Address</label>
            <p>{contactData?.home_address || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DashboardCard
      title={"Edit Contact Information"}
      icon={<MdPermContactCalendar />}
    >
      {Object.keys(utils).length && utils?.others["Approval"].length
        ? utils.others["Approval"].map((item) => {
            if (item.name === "Contact" && item.deleted_at === null) {
              return <Warning />;
            } else {
              return null;
            }
          })
        : null}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6">
            <label htmlFor="primary_email" className="d-block">
              Primary Email
            </label>
            <small className="text-danger">
              {errors.primary_email ||
                (back_errors.primary_email && back_errors.primary_email[0])}
            </small>
            <input
              type="email"
              name="primary_email"
              onChange={handleChange}
              value={values.primary_email}
              id="primary_email"
            />
          </div>
          <div className="col-6">
            <label htmlFor="secondary_email" className="d-block">
              Secondary Email
            </label>
            <small className="text-danger">
              {errors.secondary_email ||
                (back_errors.secondary_email && back_errors.secondary_email[0])}
            </small>
            <input
              type="email"
              name="secondary_email"
              onChange={handleChange}
              value={values.secondary_email}
              id="secondary_email"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <label htmlFor="mobile_number" className="d-block">
              Mobile Number
            </label>
            <div className="w-100 text-right text-danger">
              <small className="text-danger">
                {errors.mobile_number ||
                  (back_errors.mobile_number && back_errors.mobile_number[0])}
              </small>
            </div>
            <input
              type="number"
              name="mobile_number"
              onChange={handleChange}
              value={values.mobile_number}
              id="mobile_number"
            />
          </div>
          <div className="col-6">
            <label htmlFor="telephone" className="d-block">
              Phone Number
            </label>
            <small className="text-danger">
              {errors.telephone ||
                (back_errors.telephone && back_errors.telephone[0])}
            </small>
            <input
              type="number"
              name="telephone"
              onChange={handleChange}
              value={values.telephone}
              id="telephone"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <label htmlFor="home_address" className="d-block">
              Home Address
            </label>
            <small className="text-danger">
              {errors.home_address ||
                (back_errors.home_address && back_errors.home_address[0])}
            </small>
            <textarea
              name="home_address"
              onChange={handleChange}
              id="home_address"
              value={values.home_address}
            ></textarea>
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end gap-2">
          <Button
            className="myBtn-danger "
            htmlType="Button"
            onClick={handleEdit}
          >
            Cancel
          </Button>
          <Button className="myBtn" loading={save_spinners} htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}
