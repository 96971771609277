import { Tabs } from "antd";
import React from "react";
import AuthorizedPay from "./AuthorizedPay";
import UauthorizedPay from "./UnauthorizedPay";

function AuthorizePayment() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Authorized Payments",
      children: <AuthorizedPay />,
    },
    {
      key: "2",
      label: "Unauthorized Payments",
      children: <UauthorizedPay />,
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 min-vh-100">
            <section className="app__section py-4">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  padding: "1rem",
                }}
              >
                <div>
                  <h5 className="mb-0">Authorize Payment</h5>
                  <small className=" d-none d-sm-block">
                    Authorize the payment of employee salaries for the selected
                    pay period.
                  </small>
                </div>
                <div className="d-flex gap-2"></div>
              </div>
              <div className="my-3">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthorizePayment;
