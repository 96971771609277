import React from "react";

import { Navigate } from "react-router-dom";
import { notificationError, userAbilities } from "../../util/helpers";


function CanAccess({ can_access, isRoute, navigateTo, children }) {

  if (Array.isArray(can_access)) {
    const abilities = userAbilities();

    const chk_arry = can_access.map((perm) => {
      return abilities.includes(perm);
    });

    // only show notification if isRoute and can_access is present in the abilties array
    if (!chk_arry.includes(true) && isRoute) {
      notificationError(
        "You are not authorized to proceed. kindly see the administrative team"
      );
    }
    return chk_arry.includes(true) ? (
      children
    ) : (
      <>
        {isRoute ? (
          <Navigate to={navigateTo ? navigateTo : "/dashboard"} replace />
        ) : null}
      </>
    );
  } else {
    const abilities = userAbilities();
    // console.log("abilities", abilities);
    // only show notification if isRoute and can_access is present in the abilties array
    if (!abilities.includes(can_access) && isRoute) {
      notificationError(
        "You are not authorized to proceed. kindly see the administrative team"
      );
    } 
    return abilities.includes(can_access) ? (
      children
    ) : (
      <>
        {isRoute ? (
          <Navigate to={navigateTo ? navigateTo : "/dashboard"} replace />
        ) : null}
      </>
    );
  }
}

export default CanAccess;
