import { useEffect, useState } from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function ContactHistory() {
  const [emp_history, setEmpHistory] = useState([]);
  const [details] = useOutletContext();

  useEffect(() => {
    if (details?.length) {
      const new_array = details.map((em) => {
        return {
          key: em.id,
          telephone: em.telephone || "--",
          mobile: em.mobile_number || "--",
          primary_email: em.primary_email || "--",
          secondary_email: em.hmo_secondary_email || "--",
          home_address: em.home_address || "--",
        };
      });
      setEmpHistory(new_array);
    } else if (!details?.length) {
      setEmpHistory([]);
    }
  }, [details]);

  const columns = [
    {
      title: "TELEPHONE NUMBER",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "PRIMARY EMAIL",
      dataIndex: "primary_email",
      key: "primary_email",
    },
    {
      title: "SECONDARY EMAIL",
      dataIndex: "secondary_email",
      key: "secondary_email",
    },
    {
      title: "HOME ADDRESS",
      dataIndex: "home_address",
      key: "home_address",
    },
  ];
  return <Table columns={columns} dataSource={emp_history} scroll={{ x: 1440 }} />;
}

export default ContactHistory;
