import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Table,
  Drawer,
  Space,
  Popconfirm,
  Radio,
  DatePicker,
  Tag,
  Select,
  Input,
} from "antd";

import NoContent from "../../../ui/NoContent";
import SkeletonUI from "../../../ui/Skeleton";
import ReadOnlyTemplateRenderer from "../../../request-management/make-request/request-history/ReadOnlyTemplateRenderer";
import dayjs from "dayjs";
import ExternalSurveyReadOnly from "../../externalsurveyui/ExternalSurveyReadOnly";

const InactiveSurveys = () => {
  const [isLoading, setIsLoading] = useState();
  const [tableData, setTableData] = useState([]);
  const [templates, setTemplates] = useState();
  const [change, setChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `http://localhost:8000/survey?status=inactive`
        );
        const data = await response.json();
        setTemplates(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (templates) {
      const new_array = templates?.map((survey, index) => {
        return {
          key: survey.id,
          survey_name: survey.surveyName,
          date_created: survey.dateCreated,
          survey_type: survey.surveyType,
          survey,
        };
      });
      setTableData(new_array);
    }
  }, [templates]);

  const columns = [
    {
      title: "Survey Name",
      dataIndex: "survey_name",
      align: "center",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      align: "center",
    },
    {
      title: "Survey Type",
      dataIndex: "survey_type",
      align: "center",
      render: (_, record) => (
        <span>
          {record.survey_type === "internal" ? "Internal" : "External"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <EachAction selectedTemplateObject={record} setChange={setChange} />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3" id="create__template__body">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #d9d9d9",
            paddingBottom: "1.5rem",
          }}
        >
          <div className="">
            <h3 className="h4">Survey</h3>
            <p>Actions can be taken on all created surveys</p>
          </div>
        </div>

        {isLoading ? (
          <SkeletonUI />
        ) : !isLoading && templates?.length ? (
          <div className="monitoring__individual__content__table">
            <Table
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 500 }}
              bordered
              pagination={false}
            />
          </div>
        ) : (
          <NoContent width={"400px"} />
        )}
      </div>
    </div>
  );
};

function EachAction({ selectedTemplateObject, setChange }) {
  const [internalSurveyOpen, setInternalSurveyOpen] = useState(false);
  const [externalSurveyOpen, setExternalSurveyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isInternalModalOpen, setIsIntervalModalOpen] = useState(false);
  const [isExternalModalOpen, setIsExternalModalOpen] = useState(false);
  const [start_date, setStartDate] = useState(
    new Date().toLocaleDateString("en-GB")
  );
  const [end_date, setEndDate] = useState();
  const [recipient, setRecipient] = useState();
  const [departmentsSelected, setDepartmentsSelected] = useState([]);
  const [employeesSelected, setEmployeesSelected] = useState([]);
  const [surveyUrl, setSurveyUrl] = useState();
  const [hostname, setHostName] = useState();

  const departmentsList = [
    {
      value: 1,
      label: "Enterprise Unit",
    },
    {
      value: 2,
      label: "Acounting Unit",
    },
    {
      value: 3,
      label: "Core Infrastructure",
    },
    {
      value: 4,
      label: "Human Resource Unit",
    },
    {
      value: 5,
      label: "Finance Unit",
    },
  ];

  const employeesList = [
    {
      value: 1,
      label: "Asin Quatz",
    },
    {
      value: 2,
      label: "Rebel Sarks",
    },
    {
      value: 3,
      label: "Jabberwocky Woozy",
    },
    {
      value: 4,
      label: "Ibu Gliders",
    },
    {
      value: 5,
      label: "Stairwell Abija",
    },
  ];

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, padding: "0 5px" }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    setEndDate(selectedTemplateObject.survey.endDate);
  }, [selectedTemplateObject.survey]);

  const showInternalSurveyDrawer = () => {
    setInternalSurveyOpen(true);
  };

  const showExternalSurveyDrawer = () => {
    setExternalSurveyOpen(true);
  };

  const onInternalSurveyClose = () => {
    setInternalSurveyOpen(false);
  };

  const onExternalSurveyClose = () => {
    setExternalSurveyOpen(false);
  };

  const updateTemplate = async (id, template) => {
    const response = await fetch(`http://localhost:8000/survey/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(template),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleStartInternalSurvey = async () => {
    setIsLoading(true);

    try {
      const result = await updateTemplate(selectedTemplateObject.survey.id, {
        ...selectedTemplateObject.survey,
        status: "active",
        destination: {
          start_date: start_date,
          end_date: end_date,
          recipient: recipient,
          selected_departments: departmentsSelected,
          selected_employees: employeesSelected,
        },
      });
      console.log("Success:", result);
      setChange(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartExternalSurvey = async () => {
    setIsLoading(true);

    try {
      const result = await updateTemplate(selectedTemplateObject.survey.id, {
        ...selectedTemplateObject.survey,
        status: "active",
        surveyUrl,
      });
      console.log("Success:", result);
      setChange(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelStartSurvey = (e) => {
    setInternalSurveyOpen(false);
    setExternalSurveyOpen(false);
  };

  const handleViewIntervalModal = () => {
    setIsIntervalModalOpen(!isInternalModalOpen);
  };

  const handleViewExternalModal = () => {
    setIsExternalModalOpen(!isExternalModalOpen);
  };

  useEffect(() => {
    setHostName(window.location.hostname);
  }, []);

  const CopyToClipboardButton = ({ id, surveytitle }) => {
    const [copied, setCopied] = useState(false);

    const formattedSurveyTitle = surveytitle.replace(/ /g, "-").toLowerCase();

    const linkToCopy = `http://${hostname}:3000/public/${id}/${formattedSurveyTitle}`;

    useEffect(() => {
      if (id && surveytitle) {
        setSurveyUrl(linkToCopy);
      }
    }, [id, linkToCopy, surveytitle]);

    useEffect(() => {
      if (surveyUrl) {
        handleStartExternalSurvey();
      }
    }, []);

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(linkToCopy);
        setCopied(true);
      } catch (err) {
        setCopied(false);
      }
    };

    useEffect(() => {
      if (copied) {
        setTimeout(() => {
          setCopied(false);
        }, 10000);
      }
    }, [copied]);

    return (
      <div
        style={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Space.Compact
          style={{
            width: "100%",
          }}
        >
          <Input value={linkToCopy} readOnly />
          <Button
            type="primary"
            size="large"
            onClick={copyToClipboard}
            style={{
              backgroundColor: "var(--clr-primary)",
            }}
          >
            {copied ? "Copied" : "Copy Link"}
          </Button>
        </Space.Compact>
      </div>
    );
  };

  return (
    <>
      <Space>
        <Button
          type="primary"
          shape="round"
          className=""
          style={{
            backgroundColor: "green",
          }}
          onClick={
            selectedTemplateObject.survey_type === "internal"
              ? handleViewIntervalModal
              : handleViewExternalModal
          }
        >
          Start Survey
        </Button>
        <Button
          type="primary"
          shape="round"
          className=""
          onClick={
            selectedTemplateObject?.survey?.surveyType === "internal"
              ? showInternalSurveyDrawer
              : showExternalSurveyDrawer
          }
        >
          View
        </Button>
      </Space>

      <Modal
        centered
        open={isExternalModalOpen}
        onOk={handleViewExternalModal}
        onCancel={handleViewExternalModal}
        footer={null}
        width={600}
      >
        <div className="unit__measure__wrapper p-2">
          <img
            src={"/assets/checkmarkimg.png"}
            alt="Checkmark"
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
            }}
          />
          <p className="fw-bold fs-6 text-center">
            Link to {selectedTemplateObject?.survey_name} Generated
          </p>
          <CopyToClipboardButton
            id={selectedTemplateObject.survey?.id}
            surveytitle={selectedTemplateObject?.survey_name}
          />
        </div>
      </Modal>

      <Modal
        title="Select Survey Destination"
        centered
        open={isInternalModalOpen}
        onOk={handleViewIntervalModal}
        onCancel={handleViewIntervalModal}
        footer={null}
        width={600}
      >
        <div className="unit__measure__wrapper">
          <form
            className="unit__measure__form"
            onSubmit={handleStartInternalSurvey}
          >
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">Select Survey End Date</label>
              <DatePicker
                size="large"
                value={end_date ? dayjs(end_date, "D-M-YYYY") : null}
                onChange={(date, dateString) =>
                  setEndDate(date.format("D-M-YYYY"))
                }
              />
            </div>

            <div className="d-flex flex-column gap-2 ">
              <label className="fw-bold fs-6">Select an Option:</label>
              <Radio.Group
                onChange={(e) => setRecipient(e.target.value)}
                value={recipient}
              >
                <Radio value={"all"}>
                  <span className="fs-6">All</span>
                </Radio>
                <Radio value={"individuals"}>
                  <span className="fs-6">Individuals</span>
                </Radio>
                <Radio value={"department"}>
                  <span className="fs-6">Departmant</span>
                </Radio>
              </Radio.Group>
            </div>

            {(recipient === "individuals" || recipient === "department") && (
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">
                  You can multi-select your preferred picks from your above
                  selections
                </label>
                <Select
                  mode="multiple"
                  size="large"
                  placeholder={
                    recipient === "department"
                      ? "Select departments"
                      : "Select individuals"
                  }
                  tagRender={tagRender}
                  value={
                    recipient === "department"
                      ? departmentsSelected
                      : employeesSelected
                  }
                  onChange={(value) => {
                    if (recipient === "department") {
                      setDepartmentsSelected(value);
                    } else {
                      setEmployeesSelected(value);
                    }
                  }}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  options={
                    recipient === "department" ? departmentsList : employeesList
                  }
                />
              </div>
            )}
            <Button
              className="d-flex justify-content-center text-white "
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              Start Survey
            </Button>
          </form>
        </div>
      </Modal>

      <Drawer
        title={selectedTemplateObject?.survey_name}
        width={"60%"}
        onClose={onInternalSurveyClose}
        open={internalSurveyOpen}
        extra={
          <Space>
            <Button danger size="large" onClick={onInternalSurveyClose}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <div className="make__request__form">
          <div className="make__request__form__title">
            <h4 className="h4">{selectedTemplateObject?.survey_name}</h4>
          </div>

          {selectedTemplateObject?.survey?.questionsItems
            ? selectedTemplateObject?.survey?.questionsItems.map(
                (question, index) => (
                  <ReadOnlyTemplateRenderer question={question} key={index} />
                )
              )
            : null}

          <Popconfirm
            title="End this survey"
            description="Are you sure you want to End this survey?"
            onConfirm={handleStartInternalSurvey}
            onCancel={cancelStartSurvey}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              shape="round"
              className=""
              style={{
                backgroundColor: "green",
                width: "fit-content",
              }}
            >
              Start Survey
            </Button>
          </Popconfirm>
        </div>
      </Drawer>

      <Drawer
        title={selectedTemplateObject?.survey_name}
        width={"80%"}
        onClose={onExternalSurveyClose}
        open={externalSurveyOpen}
        extra={
          <Space>
            <Button danger size="large" onClick={onExternalSurveyClose}>
              Close
            </Button>
          </Space>
        }
      >
        <div className=" d-flex flex-column gap-4">
          <div>
            <ExternalSurveyReadOnly survey={selectedTemplateObject.survey} />
          </div>

          <Button
            type="primary"
            shape="round"
            className=""
            style={{
              backgroundColor: "green",
              width: "fit-content",
            }}
            onClick={
              selectedTemplateObject.survey_type === "internal"
                ? handleViewIntervalModal
                : handleViewExternalModal
            }
          >
            Start Survey
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default InactiveSurveys;
