import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/CustomizeDisplay.module.css";
import { quick_setup } from "../../util/selectors/userSelectors";
import { useShallowEqualSelector } from "../../util/hooks";
import { Button } from "antd";

function CustomizeDisplay() {
  const company = useShallowEqualSelector(quick_setup);

  const companyDisplay = company.find(
    (details) => details.name === "Display Settings"
  );

  const { field } = companyDisplay;
  const initPrimaryColor = { primary_color: field?.primary_color };

  const [color, setColor] = useState(initPrimaryColor);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setColor((prevState) => {
      dispatch({
        type: "QUICK_SETUP_THEME",
        payload: { [e?.target.name]: e?.target.value },
      });
      return { ...prevState, primary_color: e?.target.value };
    });
  };

  useEffect(
    () =>
      setColor((prevState) => ({
        ...prevState,
        primary_color: field.primary_color,
      })),
    [field.primary_color]
  );

  return (
    <div className={styles.customization}>
      <div className="row">
        <div className="col-md-4">
          <h4 className="mb-3">Select Theme</h4>

          <div className={styles.selectColors}>
            <div
              className={styles.colorPreview}
              style={{ backgroundColor: color.primary_color }}
            >
              <h3 style={{ color: "white" }}>{color.primary_color}</h3>
            </div>
            <div className={styles.palette}>
              <form className={styles.colorOptions}>
                <label htmlFor="blue">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#0065AB"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#0065AB" }}></div>
                </label>
                <label htmlFor="green">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#008000"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#008000" }}></div>
                </label>
                <label htmlFor="red">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#CD0404"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#CD0404" }}></div>
                </label>

                <label htmlFor="purple">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#6F1AB6"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#6F1AB6" }}></div>
                </label>
                <label htmlFor="orange">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#E14D2A"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#E14D2A" }}></div>
                </label>
                <label htmlFor="navy_blue">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#1C315E"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#1C315E" }}></div>
                </label>
                <label htmlFor="black">
                  <input
                    type="radio"
                    name="primary_color"
                    value={"#000000"}
                    onChange={handleChange}
                  />
                  <div style={{ backgroundColor: "#000000" }}></div>
                </label>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3 className="mb-3">Preview</h3>
          <div className={styles.preview}>
            <div
              className={styles.header}
              style={{ background: color.primary_color }}
            >
              <h2 style={{ color: "white" }}>Hello World!</h2>
              <small>This is your dashboard header</small>
            </div>
            <div className={styles.body}>
              <h4>This is a heading</h4>
              <p style={{ color: color.primary_color }}>This is a text</p>
              <Button
                className="myBtn"
                style={{ background: color.primary_color }}
                htmlType="button"
              >
                Click me
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomizeDisplay;
