import React, { useState } from "react";
import { Button, Modal } from "antd";
import styles from "../../../../styles/hrms/Approval.module.css";
import { useUserCleanUp } from "../../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  approveUserDocInfo,
  rejectUserInfo,
} from "../../../../redux/users/userActions";
import { useOutletContext } from "react-router-dom";

function ApproveDocument() {
  useUserCleanUp();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalApproveOnly, setShowModalApproveOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);
  const [loadingAppN, setLoadingAppN] = useState(false);
  const [details, setEnabled] = useOutletContext();

  let init_det = details[0];
  console.log({ init_det });

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const modalHandlerApprove = () => {
    setShowModalApprove(!showModalApprove);
  };
  const modalHandlerApproveOnly = () => {
    setShowModalApproveOnly(!showModalApproveOnly);
  };

  const confirm = () => {
    setLoading(true);
    rejectUserInfo(dispatch, {
      id: init_det.id,
      info_type: "upload-document",
    }).then((res) => {
      setLoading(false);
      setShowModal(false);
      Modal.destroyAll();

      if (res.status === "success") {
        setEnabled(true);
      }
    });
  };

  const handleApproveOnly = () => {
    const creds = {
      id: init_det.id,
      notify: false,
    };
    setLoadingApp(true);
    approveUserDocInfo(dispatch, creds).then((res) => {
      setLoadingApp(false);
      Modal.destroyAll();
      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  };

  const handleApproveNotify = () => {
    const creds = {
      id: init_det.id,
      notify: true,
    };
    setLoadingAppN(true);
    approveUserDocInfo(dispatch, creds).then((res) => {
      setLoadingAppN(false);
      Modal.destroyAll();

      if (res?.status === "success") {
        setEnabled(true);
      }
    });
  };

  const warning = () => {
    Modal.confirm({
      title: "Reject request",
      content: "Do you want to reject this request?",
      onOk: confirm,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandler,
      open: showModal,
      closable: true,
    });
  };
  const approve = () => {
    Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveOnly,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApproveOnly,
      open: showModalApproveOnly,
      closable: true,
    });
  };
  function approveandnotify() {
    return Modal.confirm({
      title: "Approve request",
      content: "Do you want to approve this request?",
      onOk: handleApproveNotify,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onCancel: modalHandlerApprove,
      open: showModalApprove,
      closable: true,
    });
  }

  return (
    <div className={styles.card}>
      <div>
        <h4>{init_det.category}</h4>
      </div>
      <div className={`${styles.cta}  mt-3`}>
        <a href={init_det.document} download target="_blank" rel="noreferrer">
          <Button size="large" className="myBtn " style={{ color: "#fff" }}>
            Download
          </Button>
        </a>
        <Button
          size="large"
          className="myBtn "
          loading={loadingAppN}
          onClick={approveandnotify}
          style={{ color: "#fff" }}
        >
          Approve and notify
        </Button>
        <Button
          size="large"
          className="myBtn "
          loading={loadingApp}
          onClick={approve}
          style={{ color: "#fff" }}
        >
          Approve
        </Button>
        <Button
          className="myBtn "
          style={{ backgroundColor: "red", color: "white" }}
          onClick={warning}
          loading={loading}
          size="large"
        >
          Reject
        </Button>
      </div>
    </div>
  );
}

export default ApproveDocument;
