import { Link } from "react-router-dom";

function Logo({ light, size = 40 }) {
  return (
    <div className="brand-logo mb-0 mt-4">
      <Link to="/">
        <h1 className={
          `${light && "logo-dark"} logo`}>
          <span className="company-name multicolortext " style={{
            "fontSize" : size
          }}>WorkWave</span>
          <sup>HR</sup>
        </h1>
      </Link>
    </div>
  );
}

export default Logo;
