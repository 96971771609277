import React, { useState } from "react";
import { NavLink } from "react-router-dom";


function MakeRequestDropdown() {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"make-request"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Make A New Request</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"make-request/request-history"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Request History</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default React.memo(MakeRequestDropdown);
