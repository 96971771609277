import { Button } from "antd";
import { toggleActivies } from "../../../redux/users/userActions";
import { useState } from "react";
import { useDispatch } from "react-redux";

function OnboardAct({ act, hire_id, refetch, setEnabled }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setLoading(true);
    const creds = {
      type: "onboarding",
      hire_id,
      activity_id: act.id,
    };

    toggleActivies(dispatch, creds).then((res) => {
      setLoading(false);
      if (res?.status === "success") {
        setEnabled(true);
        refetch();
      }
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <p>{act.hire_onboarding_activity[0].status}</p>

      <Button size="small" type="text" loading={loading} onClick={handleToggle}>
        Mark as{" "}
        {act.hire_onboarding_activity[0].status === "PENDING"
          ? " Completed"
          : " Pending"}
      </Button>
    </div>
  );
}

export default OnboardAct;
