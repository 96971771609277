import React from "react";

import { NavLink } from "react-router-dom";

const JobRoleDropdown = () => {
  return (
    <ul className="dropdown_links px-0">
      <li>
        <NavLink
          to={"role"}
          end
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Job Roles</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"role/upload-role"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <span className="sidebar_text">Upload jobs</span>
        </NavLink>
      </li>
    </ul>
  );
};

export default JobRoleDropdown;
