import React from "react";
import { Skeleton } from "antd";

function SkeletonUI({ number }) {
  return (
    <div>
      <Skeleton
        active
        paragraph={{
          rows: number || 4,
        }}
        className="mt-3"
      />
      <Skeleton
        active
        paragraph={{
          rows: number || 4,
        }}
        className="mt-3"
      />
    </div>
  );
}

export default SkeletonUI;
