import React from "react";
import { MutatingDots } from "react-loader-spinner";
// import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import styles from "../../styles/Dashboard.module.css";
// import ChangingProgressProvider from "../ui/progressbar/ChangingProgressProvider";

function FinalStepDispaly() {
  return (
    <div className={styles.finishSetup}>
      <MutatingDots
        height="100"
        width="100"
        color="var(--clr-primary)"
        secondaryColor="var(--clr-primary)"
        radius="15"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{
          color: "var(--clr-primary) !important",
        }}
        wrapperClass=""
        visible={true}
      />
      <h3 className="mt-5">Preparing your workspace...</h3>
    </div>
  );
}

export default FinalStepDispaly;
