import { useState } from "react";
import { Button, Modal } from "antd";
import styles from "../../../styles/employee/Profile.module.css";
import {
  useForm,
  useShallowEqualSelector,
  useUserCleanUp,
} from "../../../util/hooks";
import { useDispatch } from "react-redux";
import {
  user_spinner,
  user_errors,
  user_profile,
  settings_util,
} from "../../../util/selectors/userSelectors";
import { updateUserEduInfo } from "../../../redux/users/userActions";
import { FaGraduationCap } from "react-icons/fa";
import { useGetUserEducationalHistory } from "../../../util/usershookActions";
import EducationCard from "./EducationCard";
import { MdAddCircleOutline, MdOutlineAdd } from "react-icons/md";
import data from "../../../util/util.json";
import { HiUpload } from "react-icons/hi";
import Warning from "../../ui/Warning";

export default function EducationalDetails() {
  useUserCleanUp();

  const { degree, institutionType } = data;

  // console.log(data)

  const [showModal, setShowModal] = useState(false);

  const [enabled, setEnabled] = useState(true);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  // };

  const dispatch = useDispatch();
  const userSpinner = useShallowEqualSelector(user_spinner);
  const back_errors = useShallowEqualSelector(user_errors);
  const userProfile = useShallowEqualSelector(user_profile);
  const utils = useShallowEqualSelector(settings_util);

  const { data: education_info } = useGetUserEducationalHistory(
    enabled,
    setEnabled,
    userProfile?.id
  );

  const init_details = {
    institution_name: "",
    institution_category: "",
    course: "",
    degree_obtained: "",
    start_date: "",
    end_date: "",
  };

  const callbackFn = () => {
    values.user_id = userProfile?.id;
    // values.id = userProfile?.id;

    updateUserEduInfo(dispatch, values).then((res) => {
      if (res?.status === "success") {
        clearForm();
      }
      setShowModal(false);
    });
  };

  const dependant_details_validator = (values) => {
    let errors = {};

    return errors;
  };

  const { values, errors, handleChange, handleSubmit, clearForm } = useForm(
    callbackFn,
    init_details,
    dependant_details_validator
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`${styles.title} greeting_2`}>
          <div>
            <h4>Educational Qualifications</h4>
            <p>Review and update your educational information</p>
          </div>
          <FaGraduationCap />
        </div>
      </div>
      <Modal
        okText={"Add"}
        open={showModal}
        onCancel={modalHandler}
        footer={null}
        centered
      >
        <h5 className="text-dark mb-4 d-flex align-items-center gap-2">
          {<MdOutlineAdd />} Add Educational Qualification
        </h5>
        {Object.keys(utils).length && utils?.others["Approval"].length
          ? utils.others["Approval"].map((item) => {
              if (item.name === "Education" && item.deleted_at === null) {
                return <Warning />;
              } else {
                return null;
              }
            })
          : null}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <label htmlFor="institution_name">Institution Name</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.institution_name ||
                    (back_errors.institution_name &&
                      back_errors.institution_name[0])}
                </small>
              </div>
              <input
                type="text"
                name="institution_name"
                onChange={handleChange}
                value={values.institution_name}
                id="institution_name"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="course">Course of Study</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.course ||
                    (back_errors.course && back_errors.course[0])}
                </small>
              </div>
              <input
                type="text"
                name="course"
                onChange={handleChange}
                value={values.course}
                id="course"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="institution_category">Institution Category</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.institution_category ||
                    (back_errors.institution_category &&
                      back_errors.institution_category[0])}
                </small>
              </div>
              <select
                name="institution_category"
                onChange={handleChange}
                value={values.institution_category}
                id="institution_category"
              >
                <option value="">--</option>
                {institutionType.map((deg) => (
                  <option value={deg.name} key={deg.id}>
                    {deg.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="degree_obtained">Degree Obtained</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.degree_obtained ||
                    (back_errors.degree_obtained &&
                      back_errors.degree_obtained[0])}
                </small>
              </div>
              <select
                name="degree_obtained"
                onChange={handleChange}
                value={values.degree_obtained}
                id="degree_obtained"
              >
                <option value="">--</option>
                {degree.map((deg) => (
                  <option value={deg.name} key={deg.id}>
                    {deg.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="start_date">Start Date</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.start_date ||
                    (back_errors.start_date && back_errors.start_date[0])}
                </small>
              </div>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={values.start_date}
                id="start_date"
              />
            </div>
            <div className="col-12 mt-2 d-flex flex-column">
              <label htmlFor="end_date">End Date</label>
              <div className="w-100 text-right text-danger">
                <small className="text-danger">
                  {errors.end_date ||
                    (back_errors.end_date && back_errors.end_date[0])}
                </small>
              </div>
              <input
                type="date"
                name="end_date"
                onChange={handleChange}
                value={values.end_date}
                id="end_date"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              // onClick={submitHandler}
              className="myBtn mt-3"
              loading={userSpinner}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>
      <div className="col-md-12 p-5">
        <div className="row gap-3">
          {education_info?.payload?.length ? (
            education_info?.payload.map((eduction) => (
              <EducationCard
                key={eduction.id}
                info={eduction}
                setEnabled={setEnabled}
              />
            ))
          ) : (
            <div className="no-content-box">
              <img
                src="/assets/edu-illustration.jpg"
                alt="no content illustration"
              />
              <p className="mb-3">No Educational Qualification uploaded!</p>
              <Button onClick={modalHandler} className={styles.uploadBtn}>
                <HiUpload />
                Add Qualification
              </Button>
            </div>
          )}
          {education_info?.payload?.length ? (
            <div className="col-md-3">
              <div
                className="card bg-white h-100"
                style={{ cursor: "pointer" }}
                onClick={modalHandler}
              >
                <div className="card-body d-flex justify-content-center align-items-center">
                  <MdAddCircleOutline className={styles.addEntryBtn} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
