import React, { useState, useEffect } from "react";
import { Input, Button, Table, Drawer, Space, Popconfirm } from "antd";

import ReadOnlyTemplateRenderer from "../make-request/request-history/ReadOnlyTemplateRenderer";
import { useGetRequestAnswersForHistory } from "../../../util/usershookActions";

import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { useDispatch } from "react-redux";

import { updateRequestStatus } from "../../../redux/users/userActions";
import ReadOnlyExpenseReportTemplate from "../predefined-template/expense-report-template/ReadOnlyExpenseReportTemplate";

const ApproveRequestsTable = ({
  allEmployeesRequestHistory,
  userID,
  refetchAllEmployeesRequestHistory,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (allEmployeesRequestHistory) {
      const new_array = allEmployeesRequestHistory?.payload?.submissions?.map(
        (request, index) => {
          return {
            key: request.submission,
            employee: request.employee,
            department: request.department,
            date_of_request: request.date_of_request,
            approvals: request.approvals,
            request,
          };
        }
      );
      setTableData(new_array);
    }
  }, [allEmployeesRequestHistory]);

  // Function to get ordinal suffix
  const getOrdinalSuffix = (i) => {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  let columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      title: "Date of Request",
      dataIndex: "date_of_request",
      align: "center",
    },
  ];

  let maxLevels = 0;
  allEmployeesRequestHistory?.payload?.submissions?.forEach((submission) => {
    const levels = Object.keys(submission.approvals).length;
    if (levels > maxLevels) {
      maxLevels = levels;
    }
  });

  for (let i = 1; i <= maxLevels; i++) {
    columns.push(
      {
        title: `${getOrdinalSuffix(i)} Approver`,
        dataIndex: [`approvals`, `level${i}`, "status"],
        align: "center",
        render: (text, record) => {
          if (userID) {
            const levelApproverId = record?.approvals[`level${i}`]?.approver_id;
            // This is the ID for to be used for approving a request by an approver
            // It's different from the above
            const levelApprovalId = record?.approvals[`level${i}`]?.approval_id;
            if (levelApproverId === userID) {
              return (
                <EachAction
                  selectedRequest={record.request}
                  previousLevelStatus={
                    record?.approvals[`level${i - 1}`]?.status
                  }
                  currentLevelStatus={record?.approvals[`level${i}`]?.status}
                  approvalID={levelApprovalId}
                  templateName={
                    allEmployeesRequestHistory?.payload?.template_name
                  }
                  submissionID={record.key}
                  refetchAllEmployeesRequestHistory={
                    refetchAllEmployeesRequestHistory
                  }
                />
              );
            } else {
              return text?.toUpperCase();
            }
          }
        },
      },
      {
        title: `Date`,
        dataIndex: [`approvals`, `level${i}`, "date"],
        align: "center",
        render: (text) => text || "N/A",
      }
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 1200 }}
        bordered
        pagination={false}
      />
    </div>
  );
};

function EachAction({
  selectedRequest,
  previousLevelStatus,
  currentLevelStatus,
  approvalID,
  templateName,
  submissionID,
  refetchAllEmployeesRequestHistory,
}) {
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const dispatch = useDispatch();

  const { data, refetch, isLoading } = useGetRequestAnswersForHistory(
    enabled,
    setEnabled,
    submissionID
  );

  useEffect(() => {
    if (submissionID) {
      setEnabled(true);
    }
  }, [submissionID]);

  const expenseReportAnswers =
    data?.payload?.template_type === "expense-report"
      ? data?.payload?.answers?.[0]?.answer
      : null;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [isDeclining, setIsDeclining] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const confirmReject = () => {
    setRejectLoading(true);
    console.log("Decline confirmed:", declineReason);
    const creds = {
      status: "rejected",
      reason: declineReason,
    };

    updateRequestStatus(dispatch, creds, approvalID).then((res) => {
      setRejectLoading(false);
      if (res?.status === "success") {
        console.log("resLoad", res);
        setIsDeclining(false);
        refetchAllEmployeesRequestHistory();
      }
    });
  };

  const cancelReject = () => {
    setIsDeclining(false);
  };

  const confirmApprove = () => {
    setApproveLoading(true);

    const creds = {
      status: "approved",
    };

    updateRequestStatus(dispatch, creds, approvalID).then((res) => {
      setApproveLoading(false);
      if (res?.status === "success") {
        console.log("resLoad", res);
        refetchAllEmployeesRequestHistory();
      }
    });
  };
  const cancelApprove = (e) => {
    console.log(e);
  };

  const content = (
    <div>
      <p>Are you sure you want to decline the request?</p>
      <Input.TextArea
        value={declineReason}
        onChange={(e) => setDeclineReason(e.target.value)}
        placeholder="Enter reason for decline"
        rows={6}
      />
    </div>
  );

  return (
    <>
      <Space>
        <Button type="primary" shape="round" className="" onClick={showDrawer}>
          View
        </Button>

        <Popconfirm
          title="Approve this request"
          description="Are you sure you want to Approve this request?"
          onConfirm={confirmApprove}
          onCancel={cancelApprove}
          okText="Yes"
          cancelText="No"
          disabled={
            previousLevelStatus === "pending" ||
            previousLevelStatus === "rejected" ||
            currentLevelStatus === "approved" ||
            currentLevelStatus === "rejected"
          }
        >
          <Button
            type="primary"
            shape="round"
            className=""
            style={{
              backgroundColor: "green",
            }}
            disabled={
              previousLevelStatus === "pending" ||
              previousLevelStatus === "rejected" ||
              currentLevelStatus === "approved" ||
              currentLevelStatus === "rejected"
            }
            loading={approveLoading}
          >
            {currentLevelStatus === "pending"
              ? "Approve"
              : currentLevelStatus === "approved"
              ? "Approved"
              : "Approve"}
          </Button>
        </Popconfirm>

        <Popconfirm
          title={content}
          onConfirm={confirmReject}
          onCancel={cancelReject}
          okText="Decline"
          cancelText="No"
          open={isDeclining}
          disabled={
            previousLevelStatus === "pending" ||
            previousLevelStatus === "rejected" ||
            currentLevelStatus === "approved" ||
            currentLevelStatus === "rejected"
          }
        >
          <Button
            onClick={() => setIsDeclining(true)}
            type="primary"
            shape="round"
            style={{
              backgroundColor: "red",
            }}
            disabled={
              previousLevelStatus === "pending" ||
              previousLevelStatus === "rejected" ||
              currentLevelStatus === "approved" ||
              currentLevelStatus === "rejected"
            }
            loading={rejectLoading}
          >
            {currentLevelStatus === "rejected" ? "Rejected" : "Reject"}
          </Button>
        </Popconfirm>
      </Space>

      <Drawer
        title={templateName}
        width={"60%"}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        {isLoading ? (
          <SkeletonUI number={5} />
        ) : data?.payload ? (
          <>
            <div className="make__request__form">
              <div className="make__request__form__title">
                <h4 className="h4">{templateName}</h4>
              </div>

              {data?.payload?.template_type === null ? (
                data?.payload?.answers?.map((answer, index) => (
                  <ReadOnlyTemplateRenderer question={answer} key={index} />
                ))
              ) : data?.payload?.template_type === "expense-report" ? (
                <div
                  style={{
                    maxWidth: "80rem",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <ReadOnlyExpenseReportTemplate
                    expenseReportAnswers={expenseReportAnswers}
                    templatePreview={false}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <NoContent
            width={"400px"}
            message={"No Answers Found"}
            illustration={"/assets/selected.png"}
          />
        )}
      </Drawer>
    </>
  );
}

export default ApproveRequestsTable;
