import { LoadingOutlined } from "@ant-design/icons";
import { Select, Drawer, Card, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetPaymentTemplate,
  useGetAllSystemGrades,
  useGetUserPaymentTemplate,
} from "../../../util/usershookActions";
import { Space, Table, Button } from "antd";
import SkeletonUI from "../../ui/Skeleton";
import NoContent from "../../ui/NoContent";
import { formatMoney } from "../../../util/helpers";
import { deletePaymentTemplate } from "../../../redux/users/userActions";
import { useDispatch } from "react-redux";

function GenerateTemplate() {
  const [enabled, setEnabled] = useState(true);
  const [paymentTemplateEnabled, setPaymentTemplateEnabled] = useState(false);
  const [grade_id, setGradeId] = useState(null);
  const [tableData, setTableData] = useState([]);

  const { data: sys_grade, isLoading } = useGetAllSystemGrades(
    enabled,
    setEnabled
  );

  const {
    data: data_template,
    isLoading: loading_template,
    refetch,
  } = useGetPaymentTemplate(
    paymentTemplateEnabled,
    setPaymentTemplateEnabled,
    grade_id
  );

  console.log({ sys_grade });

  useEffect(() => {
    if (data_template) {
      const new_array = data_template?.payload?.map((template, index) => {
        return {
          key: template.id,
          user_name: template.user_name,
          ...template.payroll_items.reduce((acc, item) => {
            acc[item.payroll_item_name] = item.amount;
            return acc;
          }, {}),
          paye: template.paye,
          pension_employee: template.pension_employee,
          pension_employer: template.pension_employer,
          template,
        };
      });
      setTableData(new_array);
    }
  }, [data_template]);

  const columns = [
    {
      title: "Employee name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Payroll Items",
      children: data_template?.payload?.[0]?.payroll_items.map((item) => ({
        title: item.payroll_item_name,
        dataIndex: item.payroll_item_name,
        align: "center",
        render: (value) => (value === null ? "N/A" : formatMoney(value)),
      })),
    },
    {
      title: "Paye",
      dataIndex: "paye",
      key: "paye",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employee",
      dataIndex: "pension_employee",
      key: "pension_employee",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employer",
      dataIndex: "pension_employer",
      key: "pension_employer",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => <EachRecord record={record} refetch={refetch} />,
    },
  ];

  useEffect(() => {
    if (grade_id) {
      setPaymentTemplateEnabled(true);
    }
  }, [grade_id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 min-vh-100">
            <section className="app__section py-4">
              <div
                className="d-flex align-items-center justify-content-between mb-3"
                style={{
                  padding: "1rem",
                }}
              >
                <div>
                  <h5 className="mb-0">Create Payment Template</h5>
                  <small className=" d-none d-sm-block">
                    Generate employee's payment master schedule for the selected
                    grade/level.
                  </small>
                </div>

                <div
                  style={{ width: "450px" }}
                  className="d-flex gap-5 align-items-center justify-content-end"
                >
                  Grade/Level
                  <Select
                    className="w-50"
                    onChange={(val) => setGradeId(val)}
                    value={grade_id}
                  >
                    <option value={""}> select </option>
                    {sys_grade &&
                      sys_grade.payload.map((grade) => (
                        <option key={grade.id} value={grade.id}>
                          {" "}
                          {grade?.name}
                        </option>
                      ))}
                  </Select>
                  {""}
                  {isLoading ? <LoadingOutlined /> : null}
                </div>
              </div>

              <div
                style={{
                  padding: "1rem",
                }}
              >
                {loading_template ? (
                  <SkeletonUI number={2} />
                ) : data_template?.payload.length ? (
                  <>
                    <h6 className="mb-3">
                      Generated payment template{" "}
                      <span className="">{`Grade / Level: ${
                        grade_id - 1
                      }`}</span>
                    </h6>
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      scroll={{ x: "max-content" }}
                      bordered
                    />
                  </>
                ) : (
                  <>
                    <NoContent
                      width={"400px"}
                      message={
                        "Kindly select a grade/level to generate payment template for all employee in the selected grade/level"
                      }
                      illustration={"/assets/selected.png"}
                    />

                    <div className="d-flex gap-5 align-items-center justify-content-center mt-3  shadow-sm p-3  w-50 mx-auto bg-light">
                      Grade/Level
                      <Select
                        className="w-25"
                        onChange={(val) => setGradeId(val)}
                        value={grade_id}
                      >
                        <option value={""}> select </option>
                        {sys_grade &&
                          sys_grade.payload.map((grade) => (
                            <option key={grade.id} value={grade.id}>
                              {" "}
                              {grade?.name}
                            </option>
                          ))}
                      </Select>
                      {""}
                      {isLoading ? <LoadingOutlined /> : null}
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

function EachRecord({ record, refetch }) {
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (record?.template?.user_id && open) {
      setEnabled(true);
    }
  }, [open, record?.template?.user_id]);

  const {
    data: user_payment_template,
    isLoading: user_payment_template_loading,
  } = useGetUserPaymentTemplate(enabled, setEnabled, record?.template?.id);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  console.log("user_payment_template", user_payment_template);

  const formatSubKey = (subKey) => {
    if (subKey.includes("_")) {
      return subKey
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    } else {
      // If the string is not camel cased, just capitalize it
      return subKey.charAt(0).toUpperCase() + subKey.slice(1);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    setLoading(true);
    deletePaymentTemplate(dispatch, record?.template?.id).then(() => {
      setLoading(false);
      refetch();
    });
  };

  console.log("record", record?.template);

  return (
    <Space wrap>
      <Button
        onClick={showDrawer}
        className="rounded p-1 px-2 border border-1 text-white me-1 bg-success"
      >
        View
      </Button>
      {/* delete payment template */}
      <Popconfirm
        title="Are you sure to refresh this template?"
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          className=" rounded p-1 px-2 border border-1 text-white me-1"
          loading={loading}
        >
          Refetch
        </Button>
      </Popconfirm>

      <Drawer
        title={record.user_name}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        destroyOnClose={true}
        width={"90%"}
      >
        <div>
          {/* <Space wrap className="my-2 mb-5">
            {selected_data.map((data) => (
              <span
                class="badge bg-primary p-2 pointer"
                key={data.key}
                onClick={() => handleSelected(data)}
              >
                {data.name}
              </span>
            ))}
          </Space> */}
          <div className="row">
            <h5>Payment Template for {record.user_name}</h5>

            <div className="d-flex gap-2  flex-wrap row  mx-auto justify-content-between shadow-lg mt-3 p-3 bg-light">
              {user_payment_template_loading && <SkeletonUI number={2} />}
              {user_payment_template?.payload
                ? Object.keys(user_payment_template?.payload).map((key) => (
                    <Card
                      title={key}
                      key={key}
                      className="mb-2"
                      bordered={false}
                      style={{
                        width: "49%",
                      }}
                    >
                      {Array.isArray(user_payment_template?.payload[key]) &&
                      user_payment_template?.payload[key].length > 0 ? (
                        user_payment_template?.payload[key].map((item) => (
                          <div className="d-flex justify-content-between flex-nowrap">
                            <p>{item.payroll_item_name}:</p>
                            <p>{formatMoney(item.amount)} (NGN)</p>
                          </div>
                        ))
                      ) : Object.keys(user_payment_template?.payload[key])
                          .length > 0 ? (
                        Object.keys(user_payment_template?.payload[key]).map(
                          (subKey) => (
                            <div className="d-flex justify-content-between flex-nowrap">
                              <p>{formatSubKey(subKey)}:</p>
                              <p>
                                {formatMoney(
                                  user_payment_template?.payload[key][subKey]
                                )}{" "}
                                (NGN)
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <p>No item available</p>
                      )}
                    </Card>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Drawer>
    </Space>
  );
}

export default GenerateTemplate;
