import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "../../../../styles/hrms/Approval.module.css";
import { useGetPendingSlaveUpdatePending } from "../../../../util/usershookActions";
import { Badge } from "antd";
import NoContent from "../../../ui/NoContent";
import { Outlet, useLocation } from "react-router-dom";

function PendingTable({ emp_id }) {
  const active = styles.active;
  const [details, setDetails] = useState();
  const [enabled, setEnabled] = useState(false);
  const { data } = useGetPendingSlaveUpdatePending(enabled, setEnabled, emp_id);

  const location = useLocation();

  const segments = location.pathname.split("/");
  const activePath = segments[segments.length - 1];
  useEffect(() => {
    if (emp_id) {
      setEnabled(true);
    }
  }, [emp_id]);

  const med_details = data?.payload[0].medical;
  const emergency_contact = data?.payload[0].emergency_contact;
  const contact_details = data?.payload[0].contact;
  const dependant_details = data?.payload[0].dependant;
  const next_of_kin = data?.payload[0].next_of_kin;
  const personal_details = data?.payload[0].personal;
  const identity = data?.payload[0].identity;
  const edu_history = data?.payload[0].educational;
  const emp_history = data?.payload[0].employment;
  const document_details = data?.payload[0].document;
  const financial_details = data?.payload[0].financial;

  useEffect(() => {
    if (data) {
      switch (activePath) {
        case "medical":
          setDetails(med_details);
          break;
        case "emergency_contact":
          setDetails(emergency_contact);
          break;
        case "financials":
          setDetails(financial_details);
          break;
        case "nok":
          setDetails(next_of_kin);
          break;
        case "identity":
          setDetails(identity);
          break;
        case "edu_history":
          setDetails(edu_history);
          break;
        case "employment_history":
          setDetails(emp_history);
          break;
        case "document":
          setDetails(document_details);
          break;
        case "dependant":
          setDetails(dependant_details);
          break;
        case "contact":
          setDetails(contact_details);
          break;
        default:
          setDetails(personal_details);
          break;
      }
    }
  }, [
    activePath,
    contact_details,
    data,
    dependant_details,
    document_details,
    edu_history,
    emp_history,
    financial_details,
    identity,
    med_details,
    emergency_contact,
    next_of_kin,
    personal_details,
  ]);


  return (
    <div className={styles.content}>
      <ul className={styles.navigation}>
        <li>
          <NavLink
            to={""}
            end
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Personal
            <Badge count={personal_details?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"medical"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Medical
            <Badge count={med_details?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"emergency_contact"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Emergency Contact
            <Badge count={emergency_contact?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"contact"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Contact
            <Badge count={contact_details?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"dependant"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Dependant
            <Badge count={dependant_details?.length} />
          </NavLink>
        </li>

        <li>
          <NavLink
            to={"nok"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Next of kin
            <Badge count={next_of_kin?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"identity"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Identity
            <Badge count={identity?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"financials"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Financials
            <Badge count={financial_details?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"edu_history"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Educational
            <Badge count={edu_history?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"employment_history"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Employment
            <Badge count={emp_history?.length} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"document"}
            className={({ isActive }) => (isActive ? active : undefined)}
          >
            Documents
            <Badge count={document_details?.length} />
          </NavLink>
        </li>
      </ul>
      <div className={styles.details}>
        <div className="container">
          {data && details && details.length > 0 ? (
            <Outlet context={[details, setEnabled]} />
          ) : (
            <NoContent message="No Pending Approval" width={400} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PendingTable;
