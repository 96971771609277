import React, { useState, useEffect } from "react";
import { useGetAllEmployeesList } from "../../util/usershookActions";
import { useDebounce } from "../../util/hooks";
import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";
import { NavLink } from "react-router-dom";
import { FaUserClock, FaUsers } from "react-icons/fa";

function AllEmployeeGrid() {
  const [enabled, setEnabled] = useState(true);
  const [users, setUsers] = useState([]);
  const [text, setText] = useState("");
  const { data: all_users, isLoading } = useGetAllEmployeesList(
    enabled,
    setEnabled
  );

  useDebounce(() => filterUsers(text), 1000, [text]);
  function filterUsers(text) {
    const filtered =
      users &&
      users.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(text.toLowerCase()) ||
          item.last_name.toLowerCase().includes(text.toLowerCase())
        );
      });

    if (text === "") {
      setUsers(all_users?.payload?.all_employees?.data.slice(0, 6));
    } else if (filtered?.length) {
      setUsers(filtered.slice(0, 6));
    } else {
      setUsers([]);
    }
  }

  useEffect(() => {
    if (
      all_users?.payload?.all_employees &&
      Object.keys(all_users?.payload?.all_employees)?.length
    ) {
      setUsers(all_users?.payload?.all_employees?.data.slice(0, 6));
    }
  }, [all_users?.payload?.all_employees]);

  return (
    <>
      {/* <div className="row emp__area"> */}
      <nav
        className="title__area"
        style={{
          color: "",
        }}
      >
        <div className="title__area_left">
          <h4 className="mb-0">All employees</h4>
        </div>

        <ul className="title__area_right">
          <li className="pointer">
            <NavLink to={"/dashboard/probation-list"} className="okk">
              <FaUserClock className="svg_icon" /> Probation
            </NavLink>
          </li>
          {/* <li className="pointer">
            <NavLink to="/dashboard/disciplinary-list" className="okk">
              <FaUserAltSlash className="svg_icon" /> Disciplinary
            </NavLink>
          </li> */}
          <li className="pointer">
            <NavLink to={"/dashboard/dashboard-full-list"} className="okk">
              <FaUsers className="svg_icon" /> All
            </NavLink>
          </li>
          <li>
            <div className="input__box">
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </li>
        </ul>
      </nav>

      {isLoading ? (
        <SkeletonUI number={2} />
      ) : users?.length ? (
        <div className="card__grid">
          {users?.map((item) => (
            <div
              className="emp__card card"
              key={item.id}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="emp__img">
                <img
                  className="card-img-top card__img"
                  src={item.avatar}
                  alt=""
                  height={123}
                />
              </div>

              <div className="emp__info__area card-body">
                <span className="emp__name card-title">
                  {item.first_name + " " + item.last_name}
                </span>
                <span className="emp__job_role card_text">
                  {item.job_role && Object.keys(item?.job_role)?.length
                    ? item?.job_role.job_role?.role || "N/A"
                    : "N/A"}
                </span>
                <span className="emp__category">
                  {" "}
                  {item.job_role && Object.keys(item?.job_role)?.length
                    ? item?.job_role.job_role?.category || "N/A"
                    : "N/A"}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoContent width={"200px"} />
      )}
      {/* </div> */}
    </>
  );
}

export default AllEmployeeGrid;
