import React, { useState } from "react";
import { useUserCleanUp } from "../../util/hooks";
import { RiPulseLine } from "react-icons/ri";

import HocHeader from "../ui/HocHeader";
import { Bar } from "react-chartjs-2";

import SkeletonUI from "../ui/Skeleton";
import NoContent from "../ui/NoContent";

import {
  useGetCompensationPersonalOverview,
  useGetCompensationSummary,
} from "../../util/usershookActions";

import { colorArray } from "../../util/helpers";


import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CanAccess from "../hoc/CanAccess";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function CompensationOverview() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);

  const { data: personal_overview, isLoading: isLoadingPersonalOverview } =
    useGetCompensationPersonalOverview(enabled, setEnabled);

  const { data: summary, isLoading: isLoadingSummary } =
    useGetCompensationSummary(enabled, setEnabled);

  const cardBgColors = [
    "#D7FFEC94",
    "#CD602330",
    "#62D9FE38",
    "#21231E14",
    "#FE626238",
    "#D7E2FF",
    "#E8F5FF",
    "#FFECEC",
  ];

  const benefitData = {
    labels:
      summary?.payload?.chart?.benefit_distribution?.map(
        (level) => level.name
      ) || [],
    datasets: [
      {
        label: "Grade Level Distribution",
        data:
          summary?.payload?.chart?.benefit_distribution?.map(
            (total) => total.user_count
          ) || [],
        backgroundColor: colorArray[0],
        borderColor: colorArray[0],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  const benefitOptions = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };


  const gradeLevelData = {
    labels:
      summary?.payload?.chart?.grade_level_distribution?.map(
        (level) => level.name
      ) || [],
    datasets: [
      {
        label: "Grade Level Distribution",
        data:
          summary?.payload?.chart?.grade_level_distribution?.map(
            (total) => total.total_users
          ) || [],
        backgroundColor: colorArray[0],
        borderColor: colorArray[0],
        borderWidth: 2,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  const gradeLevelOptions = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_name="Compensation & Benefits"
        module_description=" Evaluate and assign grades to different jobs based on different factors such as skills, knowledge, responsibility, and experience"
      />

      <main className="main__wrapper">
        <div className="container-fluid py-3">
          <section className="app__section py-4 mb-4">
            <h5>Personal Overview</h5>

            {isLoadingPersonalOverview ? (
              <SkeletonUI />
            ) : (
              <div className="row mt-4">
                {personal_overview?.payload && Object.keys(personal_overview?.payload)?.map((key, index) => {
                  const randomIndex = Math.floor(
                    Math.random() * cardBgColors.length
                  );
                  const randomColor = cardBgColors[randomIndex];
                  return (
                    <div className="col-md-3 mb-4" key={index}>
                      <div
                        className="insight"
                        style={{ backgroundColor: randomColor }}
                      >
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <RiPulseLine />
                          </div>
                          <div>
                            <div className="insight-title">
                              <h6 className="text-capitalize">
                                {key.split("_").join(" ")}
                              </h6>
                            </div>
                            <div className="insight-content">
                              <h2 className="mb-0">
                                {personal_overview?.payload[key]  || "N/A"}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </section>

          <CanAccess
            isRoute={false}
            can_access={"view-compensation-benefit-overview"}
          >
            <section className="app__section py-4 mb-4">
              <h5>Summary</h5>
              {isLoadingSummary ? (
                <SkeletonUI />
              ) : (
                <div className="row mt-4">
                  {summary?.payload &&
                    Object.keys(summary?.payload?.cards)?.map((key, index) => {
                      const randomIndex = Math.floor(
                        Math.random() * cardBgColors.length
                      );
                      const randomColor = cardBgColors[randomIndex];

                      return (
                        <div className="col-md-3 mb-4" key={index}>
                          <div
                            className="insight"
                            style={{ backgroundColor: randomColor }}
                          >
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <RiPulseLine />
                              </div>
                              <div>
                                <div className="insight-title">
                                  <h6 className="text-capitalize">
                                    {key.split("_").join(" ")}
                                  </h6>
                                </div>
                                <div className="insight-content">
                                  <h2 className="mb-0">
                                    {summary?.payload?.cards[key]}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </section>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Benefits Distribution</h5>

                  <span>Total benefits in the system and its distribution</span>

                  
                  {isLoadingSummary ? (
                    <SkeletonUI />
                  ) : summary?.payload?.chart?.benefit_distribution?.length ? (
                    <Bar data={benefitData} options={benefitOptions} />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="collasape__card__light app__section py-4">
                  <h5 className="text-start">Grade Levels Distribution</h5>

                  <span>Total number of employees in each levels</span>

                  {isLoadingSummary ? (
                    <SkeletonUI />
                  ) : summary?.payload?.chart?.grade_level_distribution?.length ? (
                    <Bar data={gradeLevelData} options={gradeLevelOptions} />
                  ) : (
                    <NoContent
                      width={"400px"}
                      message="No overview summary available"
                    />
                  )}
                </div>
              </div>
            </div>
          </CanAccess>
        </div>
      </main>
    </>
  );
}

export default CompensationOverview;
