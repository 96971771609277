import React from "react";
import { FaList } from "react-icons/fa";

import {
  MdCancelPresentation,
  MdOutlineFactCheck,
  MdPendingActions,
} from "react-icons/md";
import { NavLink } from "react-router-dom";

function ReplacementDropdown({ showDropdown }) {
  return showDropdown ? (
    <ul className="performance__navlinks dropdown_links mt-3 px-0">
      <li>
        <NavLink
          to={"pending-report"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MdPendingActions title="Pending report" />

          <span className="sidebar_text">Pending report</span>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={"resolved-report"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MdOutlineFactCheck title="Resolved report" />

          <span className="sidebar_text">Resolved report</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"all-reports"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <FaList title="All report" />

          <span className="sidebar_text">All report</span>
        </NavLink>
      </li>
    </ul>
  ) : null;
}

export default ReplacementDropdown;
