import React, { useState, useEffect } from "react";
import styles from "../../../../styles/hrms/NewEmployee.module.css";
import {
  useGetSystemGrade,
  useGetSystemJobRole,
  useGetSystemStep,
} from "../../../../util/usershookActions";
import { Select } from "antd";

function JobGrading({ handleChange, values, errors, back_errors, onboarding }) {
  const [sys_job_role, setSysJobRole] = useState([]);
  const [sys_grade, setSysGrade] = useState([]);
  const [sys_step, setSysStep] = useState([]);
  const [enabled_rol, setEnabledRol] = useState(true);
  const [enabled_grade, setEnabledGrade] = useState(true);
  const [enabled_step, setEnabledStep] = useState(true);

  const { data: job_role_details } = useGetSystemJobRole(
    enabled_rol,
    setEnabledRol
  );
  const { data: system_grade_details } = useGetSystemGrade(
    enabled_grade,
    setEnabledGrade
  );
  const { data: system_step_details } = useGetSystemStep(
    enabled_step,
    setEnabledStep
  );

  useEffect(() => {
    if (job_role_details && job_role_details?.length) {
      setSysJobRole(job_role_details);
    }
  }, [job_role_details]);

  useEffect(() => {
    if (system_grade_details?.data && system_grade_details?.data?.length) {
      setSysGrade(system_grade_details.data);
    }
  }, [system_grade_details]);

  useEffect(() => {
    if (system_step_details && system_step_details?.length) {
      setSysStep(system_step_details);
    }
  }, [system_step_details]);

  const handleSelect = (value, name) => {
    handleChange("_", true, name, value);
  };

  // console.log({ system_grade_details, sys_grade });
  const err_text = "grade_level.0.";
  return (
    <div className={onboarding ? "col-md-12 mt-5" : "col-md-6"}>
      <div className="card b0">
        <div className="card-body py-4">
          <h4 className={styles.subheading4}>Job Grading Information</h4>
          <div className={styles.hr}></div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="job_role_id" className="d-block">
                    Job Role <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.job_role_id ||
                      (back_errors[`${err_text}job_role_id`] &&
                        back_errors[`${err_text}job_role_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="job_role_id"
                  value={values.job_role_id}
                  id="job_role_id"
                  showSearch
                  onChange={(value) => handleSelect(value, "job_role_id")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {sys_job_role?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.role}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="salary" className="d-block">
                    Salary <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.salary ||
                      (back_errors[`${err_text}salary`] &&
                        back_errors[`${err_text}salary`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <input
                  type="number"
                  name="salary"
                  onChange={handleChange}
                  value={values.salary}
                  id="salary"
                  placeholder="20,000,000,000"
                />
              </div>
            </div> */}
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="grade_id" className="d-block">
                    Job grade <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.grade_id ||
                      (back_errors[`${err_text}grade_id`] &&
                        back_errors[`${err_text}grade_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>
                <Select
                  name="grade_id"
                  value={values.grade_id}
                  id="grade_id"
                  placeholder=""
                  showSearch
                  onChange={(value) => handleSelect(value, "grade_id")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {sys_grade?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.inputGroup}>
                <div className="d-flex justify-content-between flex-wrap ">
                  <label htmlFor="step_id" className="d-block">
                    Step/level/notch <span className="text-danger">*</span>
                  </label>
                  <small className="text-danger">
                    {errors.step_id ||
                      (back_errors[`${err_text}step_id`] &&
                        back_errors[`${err_text}step_id`][0]?.replace(
                          err_text,
                          " "
                        ))}
                  </small>
                </div>

                <Select
                  name="step_id"
                  value={values.step_id}
                  id="step_id"
                  showSearch
                  onChange={(value) => handleSelect(value, "step_id")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  <option value="">--</option>
                  {sys_step?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobGrading;
