import { createSelector } from "reselect";

const company_url = createSelector(
  (state) => state.user,
  (user) => user.company_url
);

const user_message = createSelector(
  (state) => state.user,
  (user) => user.message
);

const user_status = createSelector(
  (state) => state.user,
  (user) => user.status
);

const user_spinner = createSelector(
  (state) => state.user,
  (user) => user.spinner
);
const user_errors = createSelector(
  (state) => state.user,
  (user) => user.errors
);
const active_modules = createSelector(
  (state) => state.user,
  (user) => user.active_modules
);
const detached_modules = createSelector(
  (state) => state.user,
  (user) => user.detached_modules
);
const company_details = createSelector(
  (state) => state.user,
  (user) => user.company_details
);
const user_is_Loggedin = createSelector(
  (state) => state.user,
  (user) => user.is_Loggedin
);
const quick_setup = createSelector(
  (state) => state.user,
  (user) => user.quick_setup
);
const user_current_quick_setup = createSelector(
  (state) => state.user,
  (user) => user.current_quick_setup
);
const in_messages = createSelector(
  (state) => state.user,
  (user) => user.in_nessages
);
const client_permissions = createSelector(
  (state) => state.user,
  (user) => user.client_permissions
);
const user_profile = createSelector(
  (state) => state.user,
  (user) => user.user_profile
);
const client_subcription = createSelector(
  (state) => state.user,
  (user) => user.client_subcription
);
const user_profile_pending_request = createSelector(
  (state) => state.user,
  (user) => user.user_profile_pending_request
);
const employee_count_det = createSelector(
  (state) => state.user,
  (user) => user.employee_count_det
);
const settings_util = createSelector(
  (state) => state.user,
  (user) => user.settings_util
);
const settings = createSelector(
  (state) => state.user,
  (user) => user.settings
);
const cat_to_onboarding = createSelector(
  (state) => state.user,
  (user) => user.cat_to_onboarding
);
const new_hire = createSelector(
  (state) => state.user,
  (user) => user.new_hire
);
const new_hire_del = createSelector(
  (state) => state.user,
  (user) => user.new_hire_del
);
const department = createSelector(
  (state) => state.user,
  (user) => user.departments
);
const rol_del = createSelector(
  (state) => state.user,
  (user) => user.rol_del
);
const holiday = createSelector(
  (state) => state.user,
  (user) => user.holidays
);
const event_type = createSelector(
  (state) => state.user,
  (user) => user.event_type
);
const combined_events = createSelector(
  (state) => state.user,
  (user) => user.combined_events
);
const anniversaries = createSelector(
  (state) => state.user,
  (user) => user.anniversaries
);
const birthdays = createSelector(
  (state) => state.user,
  (user) => user.birthdays
);
const designation_role = createSelector(
  (state) => state.user,
  (user) => user.designation_role
);
const hire_profile = createSelector(
  (state) => state.user,
  (user) => user.hire_profile
);
const selected_template = createSelector(
  (state) => state.user,
  (user) => user.sel_temp
);
const selected_template_activities = createSelector(
  (state) => state.user,
  (user) => user.temp_activities
);
const sys_banks = createSelector(
  (state) => state.user,
  (user) => user.system_banks
);
const onb_step = createSelector(
  (state) => state.user,
  (user) => user.onb_step
);
const sys_scheduled_event = createSelector(
  (state) => state.user,
  (user) => user.sys_scheduled_event
);

const create_goals = createSelector(
  (state) => state.user,
  (user) => user.create_goals
);
const create_goals_bsc = createSelector(
  (state) => state.user,
  (user) => user.create_goals_bsc
);
const get_create_goals = createSelector(
  (state) => state.user,
  (user) => user.get_create_goals
);
const performance_stats = createSelector(
  (state) => state.user,
  (user) => user.performance_stats
);
const performance_setup = createSelector(
  (state) => state.user,
  (user) => user.performance_setup
);
const financial_year = createSelector(
  (state) => state.user,
  (user) => user.financial_year
);
const performance_unit = createSelector(
  (state) => state.user,
  (user) => user.performance_unit
);
const performance_dept_plan = createSelector(
  (state) => state.user,
  (user) => user.performance_dept_plan
);
const performance_employee_plan = createSelector(
  (state) => state.user,
  (user) => user.performance_employee_plan
);
const performance_preview = createSelector(
  (state) => state.user,
  (user) => user.performance_preview
);
const performance_list_work = createSelector(
  (state) => state.user,
  (user) => user.performance_list_work
);
const performance_number = createSelector(
  (state) => state.user,
  (user) => user.performance_number
);
const goals_breakdown = createSelector(
  (state) => state.user,
  (user) => user.goals_breakdown
);
const selected_kpi_goal = createSelector(
  (state) => state.user,
  (user) => user.selected_kpi_goal
);
const sel_sheet_year = createSelector(
  (state) => state.user,
  (user) => user.sel_sheet_year
);
const user_nudge = createSelector(
  (state) => state.user,
  (user) => user.user_nudge
);

// compensations
const compensations_overview = createSelector(
  (state) => state.user,
  (user) => user.compensations_overview
);
const all_benefits = createSelector(
  (state) => state.user,
  (user) => user.all_benefits
);
const one_benefit = createSelector(
  (state) => state.user,
  (user) => user.one_benefit
);
const com_settings = createSelector(
  (state) => state.user,
  (user) => user.com_settings
);
const avail_job_grade = createSelector(
  (state) => state.user,
  (user) => user.avail_job_grade
);
const one_avail_job = createSelector(
  (state) => state.user,
  (user) => user.one_avail_job
);
const all_system_step = createSelector(
  (state) => state.user,
  (user) => user.all_system_step
);
const all_system_grades = createSelector(
  (state) => state.user,
  (user) => user.all_system_grades
);
const idcard_overview = createSelector(
  (state) => state.user,
  (user) => user.idcard_overview
);
const all_leavegroups = createSelector(
  (state) => state.user,
  (user) => user.all_leavegroups
);
const leave_history_employee = createSelector(
  (state) => state.user,
  (user) => user.leave_history_employee
);
const annual_leave_history_employee = createSelector(
  (state) => state.user,
  (user) => user.annual_leave_history_employee
);
const team_leave_history = createSelector(
  (state) => state.user,
  (user) => user.team_leave_history
);
const annual_leave_plan = createSelector(
  (state) => state.user,
  (user) => user.annual_leave_plan
);
const get_leave_plan = createSelector(
  (state) => state.user,
  (user) => user.get_leave_plan
);
const team_annual_leave_plan = createSelector(
  (state) => state.user,
  (user) => user.team_annual_leave_plan
);
const team_annual_leave_plan_team = createSelector(
  (state) => state.user,
  (user) => user.team_annual_leave_plan_team
);
const leave_group = createSelector(
  (state) => state.user,
  (user) => user.leave_group
);
const leave_group_distribution = createSelector(
  (state) => state.user,
  (user) => user.leave_group_distribution
);
const team_member = createSelector(
  (state) => state.user,
  (user) => user.team_member
);
export {
  team_member,
  leave_group_distribution,
  leave_group,
  team_annual_leave_plan_team,
  team_annual_leave_plan,
  get_leave_plan,
  annual_leave_plan,
  team_leave_history,
  annual_leave_history_employee,
  leave_history_employee,
  all_leavegroups,
  idcard_overview,
  all_system_grades,
  all_system_step,
  one_avail_job,
  avail_job_grade,
  com_settings,
  one_benefit,
  all_benefits,
  compensations_overview,
  user_message,
  user_nudge,
  sel_sheet_year,
  selected_kpi_goal,
  goals_breakdown,
  performance_number,
  performance_list_work,
  financial_year,
  performance_preview,
  performance_employee_plan,
  performance_dept_plan,
  user_status,
  user_spinner,
  user_errors,
  active_modules,
  detached_modules,
  company_details,
  user_is_Loggedin,
  quick_setup,
  user_current_quick_setup,
  in_messages,
  company_url,
  client_permissions,
  user_profile,
  client_subcription,
  user_profile_pending_request,
  employee_count_det,
  settings_util,
  settings,
  cat_to_onboarding,
  new_hire,
  new_hire_del,
  department,
  hire_profile,
  rol_del,
  holiday,
  anniversaries,
  designation_role,
  selected_template,
  selected_template_activities,
  birthdays,
  sys_banks,
  combined_events,
  onb_step,
  sys_scheduled_event,
  create_goals,
  create_goals_bsc,
  get_create_goals,
  performance_stats,
  performance_setup,
  performance_unit,
  event_type,
};
